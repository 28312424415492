export class Wholesale {
    id: number;
    customer : string;
    contact_person: string;
    email: string;
    phone: string;
    country: string;
    province: string;
    city: string;
    address: string;
    post_code: string;
    same_address:number;
    shipping_country: string;
    shipping_province: string;
    shipping_city: string;
    shipping_address: string;
    shipping_post_code: string;
}
