<div class="team-new-orders-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'TEAMNEWORDERS' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered"matSort (matSortChange)="sortData($event)">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th mat-sort-header="id" scope="col">{{'MEMBERID' | translate}}</th>
                          <th mat-sort-header="first_name" scope="col">{{'FIRSTNAME' | translate}}</th>
                          <th mat-sort-header="last_name" scope="col">{{'LASTNAME' | translate}}</th>
                          <th mat-sort-header="orders" scope="col">{{'ORDERS' | translate}}</th>
                          <th mat-sort-header="orderAmt" scope="col">{{'AMOUNTUSD' | translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <ng-container *ngFor="let order of sortedTeamNewOrders; index as i;">
                          <tr *ngIf="order.orders > 0 && (downlineRanks.includes(order.id) || order.id == user.id)">
                            <!-- <th scope="row">{{ transaction.id }}</th> -->
                            <td>{{ order.id }}</td>
                            <td>{{ order.first_name }}</td>
                            <td>{{ order.last_name }}</td>
                            <td>{{ order.orders }}</td>
                            <td>{{ order.orderAmt | currency:'' }}</td>
                          </tr>
                        </ng-container>
                        
                      </tbody>
                    </table>
              </div>

            </div>
          </div>
        </div>
    </div>
</div>