import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { local } from 'd3';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlInput, SlideControlComponent, VertifyQuery } from 'ng-spc';
import { SecretQuestionComponent } from '../secret-question/secret-question.component';
import { CompleteInfoComponent } from '../complete-info/complete-info.component';
import { AccountLockedComponent } from '../account-locked/account-locked.component';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { RegisterComponent } from 'src/app/modules/register/register.component';
import { CartComponent } from '../cart/cart.component';
import { OnExecuteData, OnExecuteErrorData, ReCaptchaV3Service } from "ng-recaptcha";
import { environment } from 'src/environments/environment';

/**
      const modalRef = this.ngDialog.open(RegisterComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
 * 
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() parameters : string=null;

  public user : User;
  public loginForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  public captchaValidated : boolean = true;
  public mode : string = 'login';
  public resetLinkSent : boolean = false;
  public questions = [];
  public isLoggingIn = false;
  private _unsubscribe$ = new Subject<any>();
  fieldTextType: boolean=false;
  

  @ViewChild(SlideControlComponent, {static: true})
  
  slide: SlideControlComponent;
  public controlInput: ControlInput;
  private query: VertifyQuery;

  public maintenanceInfo : any = {m_flag : 0, message : ''};
  // for reCaptcha start
  public recentToken = "";
  siteKey = environment.recaptcha.siteKey;//"6LcMpTUaAAAAAP0XIwJTL3XS4otHPdhi-eUVV9ml";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recentError?: { error: any };
  public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];
  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;
 // end for reCaptcha


  constructor(
    public activeModal : NgbActiveModal, 
    private modalDialog: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private recaptchaV3Service: ReCaptchaV3Service,
    private sharedUtilService : SharedUtilService

  ) { }


  resolved(captchaResponse: string) {
    this.captchaValidated = true;
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
    });

    this.evgService.getSecretQuestions().subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.questions = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

    this.getMaintenanceInfo();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }

  }

  getMaintenanceInfo()
  {
    this.evgService.getMaintainInfo({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          
          this.maintenanceInfo = resp.data[0].results;
          if(!this.maintenanceInfo ){
            this.maintenanceInfo = {
              m_flag : 0,
              message: ''
            };
          }
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  signIn() {

    if(this.loginForm.status === 'VALID'){
      this.isLoggingIn = true;
      this.evgService.userLogin( this.loginForm.value ).subscribe(resp => {
        if (resp) {
          //console.log('signIn resp:',resp);
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            if(resp.data[0]){
              this.isLoggingIn = false;
              localStorage.setItem(this.shareinfoService.userTokenKey, resp.data[0].auth_token);
              this.evgService.setHeaderToken(resp.data[0].auth_token);
              localStorage.setItem("lastActionSec",JSON.stringify(0));
              this.evgService.getAdminUsersConfig({}).subscribe( adminConfig => {
                if (adminConfig.success) {
                  if(adminConfig.data[0].results.admin) localStorage.setItem('admins', JSON.stringify(adminConfig.data[0].results.admin));
                  if(adminConfig.data[0].results.accounting) localStorage.setItem('accounting', JSON.stringify(adminConfig.data[0].results.accounting));
                  if(adminConfig.data[0].results.supporter) localStorage.setItem('supports', JSON.stringify(adminConfig.data[0].results.supporter));
                  var userType = this.sharedUtilService.checkUserPermissions(resp.data[0].results.id);
                  this.shareinfoService.setMode(userType);
                  if(!resp.data[0].results.isLocked){
                    if(resp.data[0].results.askSecretQ){
                      const modalRef = this.ngDialog.open(SecretQuestionComponent, { windowClass: 'secret-question-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                      modalRef.componentInstance.translate = this.translate;
                      modalRef.componentInstance.userType = userType;
                      this.activeModal.close('Close click');
                    }else{
                      this.shareinfoService.setUser(resp.data[0].results);
                      // this.shareinfoService.reloadTaxRate();
                      if(userType == 'admin'){
                        this.router.navigate(['backmain/admin/daily-sales-report']);
                      }else if(userType == 'accounting'){
                        this.router.navigate(['backmain/admin/daily-sales-report']);
                      }else if(userType == 'support'){
                        this.router.navigate(['backmain/admin/customer-requests']);
                      }else{
                        let go_cart=0;
                        let myCart = localStorage.getItem("myByteCart");
                        console.log("login localStorage.getItem(myByteCart):", myCart);
                        if (myCart) {
                          myCart = JSON.parse(myCart);
                          if (myCart.length>0) {
                            go_cart=1;
                            this.evgService.modifyCartBatch(myCart).subscribe( cartdResult => {
                              console.log("login this.evgService.modifyCartBatch cartdResult:", cartdResult);
                              if (cartdResult.success) {
                                this.shareinfoService.refreshCart();
                                localStorage.removeItem("myByteCart");
                                const modalRef = this.ngDialog.open(CartComponent, { windowClass: 'activate-account-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                                modalRef.componentInstance.translate = this.translate;
                                this.activeModal.close(true);
                              }
                            });
                          }
                        }
                        let pre_sponsor=null;
                        if (resp.data[0].results.pTimes==0) {
                          if(localStorage.getItem('guest')){
                            let personal_webpage_data = localStorage.getItem('guest').toString().split(',');
                            pre_sponsor= personal_webpage_data.length>0 ? personal_webpage_data[0] : null;
                            //console.log("login personal_webpage_data: pre_sponsor:", personal_webpage_data,pre_sponsor);
                            resp.data[0].results["pre_sponsor"]=pre_sponsor;
                            if (pre_sponsor != null) {
                              this.shareinfoService.setUser(resp.data[0].results);
                              this.evgService.updatedPreSponsor({pre_sponsor: pre_sponsor}).subscribe( updatedResult => {
                                //console.log("login updatedPreSponsor: updatedResult:", updatedResult);
                              });
                            }
                            //let myCart = localStorage.setItem("myByteCart",JSON.stringify(this.cart_list));
                          }
                        }
  
                        let hasRedirect =0;
                        if (this.parameters !== null && go_cart===0 ) {
                          if (this.parameters.indexOf( "redirection")>=0 ){
                            let index = this.parameters.lastIndexOf("=");
                            if (index >=0) {
                              hasRedirect = 1;
                              let pId = this.parameters.substring(index + 1);
                              //console.log('signIn parameters pId:',pId);
                              this.router.navigate([('backmain/'+pId)]);
                            }
                          }
                        }
                        if (hasRedirect===0  && go_cart===0  ) {
                          this.router.navigate(['backmain/dashboard']);
                        }
                      }
                      this.activeModal.close(true);
                      if( userType == 'user'){
                          let loggedinUser  = resp.data[0].results;
                          if(!loggedinUser.Sex || !loggedinUser.Birthday || !loggedinUser.City || !loggedinUser.Province || !loggedinUser.Country || !loggedinUser.PostCode || !loggedinUser.Tel){
                            const modalRef = this.ngDialog.open(CompleteInfoComponent, { windowClass: 'complete-info-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                            modalRef.componentInstance.translate = this.translate;
                          } else {
                            if (this.parameters !== null) {
                              if (this.parameters.indexOf( "redirection")>0 ){
                                let index = this.parameters.lastIndexOf("=");
                                if (index >0) {
                                  let pId = this.parameters.substring(index + 1);
                                  //console.log('signIn parameters pId:',pId);
                                  this.router.navigate([('backmain/'+pId)]);
                                }
                              }
                            }
                          }
                      }
                    }
                  }else{
                      const modalRef = this.ngDialog.open(AccountLockedComponent, { windowClass: 'account-locked-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                      modalRef.componentInstance.translate = this.translate;
                      this.activeModal.close('Close click');
                      this.evgService.signOut();
                      this.shareinfoService.logout();
                  }
                }
              },(errorResult)=>{
                console.log("System Error", errorResult);
                this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
              });
            }else{
              this.isLoggingIn = false;
              console.log("login result empty:", resp);
            }
          }else{
            this.isLoggingIn = false;
            console.log("login Error", resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  resetPassword(){
   
    if(this.forgotPasswordForm.status == "VALID"){
      this.evgService.resetPassword( this.forgotPasswordForm.value ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            this.resetLinkSent = true;
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  private reset() {
    this.query.move = undefined;
    this.query.action = [];
    this.slide.reset();
    this.captchaValidated = false;
  }

  successMatch( query: VertifyQuery) {   
    //console.log("QUERY",query);
    this.query = query;
    this.captchaValidated = true;
  }
  
  openRegister() {
    //this.activeModal.close('Close click');

    const modalRef = this.ngDialog.open(RegisterComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.openLogin = false;

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  
  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action).subscribe(
      (token) => {
        this.recentToken = token;
        this.recentError = undefined;
        this.signIn();
      },
      (error) => {
        this.recentToken = "";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.recentError = { error };
      },
    );
  }


}
