<div class="security-questions-setting-container">

    <div class="row gutters-sm">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="secretQuestionForm" (keydown.enter)="$event.preventDefault()" class="secret-form" autocomplete="sq-form">
                        <div class="p-3">
                            <h3 ngbAutofocus class="title">{{'SECRETQUESTIONS' | translate}}</h3>
                        </div>
                        <div class="row">
                        <div class="col-lg-12" *ngFor="let question of questions; index as i;">
                            <div class="form-group">
                                <mat-label>{{ (i+1) + '. ' + question.question }}</mat-label>
                            </div>

                            <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'ANSWER' | translate}}</mat-label>
                                    <input matInput  [formControlName]="question.id" type="text" class="form-control" [id]="question.id" 
                                    placeholder="{{ 'ANSWER' | translate }}" [name]="question.id" maxlength="60"
                                    title = "{{ 'ANSWER' | translate }}" required autocomplete="off" >
                                    <mat-error class="error-message" *ngIf="secretQuestionForm.get(question.id + '').hasError('required')">{{'ANSWERREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        </div>
                        <hr>
                
                
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div style="text-align: center; margin: auto;">
                            <button mat-stroked-button color="primary" type="submit" class="btn submit-button" [disabled]="isLoading" (click)="onSubmit()" >{{ "SUBMIT" | translate}}</button>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>

    </div>

</div>