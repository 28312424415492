import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA, Output, EventEmitter } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest,NgxPayPalModule } from 'ngx-paypal';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { render } from 'creditcardpayments/creditCardPayments';
//import { EventEmitter } from 'stream';

@Component({
  selector: 'app-byte-paypal',
  templateUrl: './byte-paypal.component.html',
  styleUrls: ['./byte-paypal.component.scss'],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BytePaypalComponent implements OnInit {

  @Input() amount:string='0.0';
  @Input() currency:string="USD";
  @Input() shippingInfo:any=null;
  @Output() paypalProcessEvent = new EventEmitter<any>();

  public payPalConfig: any;
  public showPaypalButtons: boolean=true;
  showSuccess: boolean;
  payParameters :any;

  constructor(
    private ngDialog : NgbModal,

  ) {}

  ngOnInit() {
    /*
    render(
      {
        id : "#myPayPalbuttons",
        currency: this.currency,
        value : this.amount,
        onApprove: (detail) =>{
          console.log(" onApprove ! paypal success ");
        }

      });
      */

    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: "USD", //this.currency,
      clientId: environment.paypal_client_id, 
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        payer : {
          name : {
            given_name: this.shippingInfo? this.shippingInfo.first_name : '',
            surname: this.shippingInfo? this.shippingInfo.last_name : ''
          },
          email_address : this.shippingInfo? this.shippingInfo.email : ''
        },        
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: this.amount,
            },
          }
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        tagline: false,
      },
      onApprove: (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          //console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
        this.processEvent({status:'onApprove',info : actions});
      },
      onClientAuthorization: (data) => {
        //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
        this.processEvent({status:'success',info : data});
      },
      onCancel: (data, actions) => {
        //console.log('OnCancel', data, actions);
        this.processEvent({status:'onCancel',info : actions});
      },
      onError: err => {
        //console.log('OnError', err);
        this.processEvent({status:'OnError',error : err});
      },
      onClick: (data, actions) => {
        //console.log('onClick', data, actions);
        this.processEvent({status:'onClick'});
      },
    };
  }

  public processEvent(postData){
    //console.log('processEvent postData:',postData)
    this.paypalProcessEvent.emit(JSON.stringify(postData));
  }
}
