<div class="application-forms-container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form  [formGroup]="changeEmailForm"  #changeEmailF="ngForm" (keydown.enter)="$event.preventDefault()" autocomplete="ce-form">
                        <div class="p-3">
                            <h2>{{ 'CHANGEEMAILLABEL' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="pre_first_name"  type="text" class="form-control text-box" id="pre_first_name"
                                name="pre_first_name"  placeholder="{{ 'FIRSTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('pre_first_name').hasError('required')">{{'FIRSTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="pre_last_name"  type="text" class="form-control text-box" id="pre_last_name"
                                name="pre_last_name"  placeholder="{{ 'LASTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('pre_last_name').hasError('required')">{{'LASTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="account_number"  type="number" class="form-control text-box" id="account_number"
                                name="account_number"  placeholder="{{ 'ACCOUNTNUMBER' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('account_number').hasError('required')">{{'ACCOUNTNUMBERREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="pre_email"  type="email" class="form-control text-box" id="pre_email"
                                name="pre_email"  placeholder="{{ 'EMAIL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('pre_email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('pre_email').hasError('email')">{{'EMAILISVALID' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="change_to_email"  type="email" class="form-control text-box" id="change_to_email"
                                name="change_to_email"  placeholder="{{ 'NEWEMAIL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('change_to_email').hasError('required')">{{'NEWEMAILREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('change_to_email').hasError('email')">{{'EMAILISVALID' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="Address"  type="text" class="form-control text-box" id="Address"
                                name="Address"  placeholder="{{ 'ADDRESSLABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('Address').hasError('required')">{{'ADDRESSREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="City"  type="text" class="form-control text-box" id="City"
                                name="City"  placeholder="{{ 'CITYLABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('City').hasError('required')">{{'CITYREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                                placeholder="{{'COUNTRYLABEL' | translate}}" name="country">
                                    <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                    {{ country.country }}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                                placeholder="{{'PROVINCELABEL' | translate}}"  name="province">
                                <ng-container *ngFor="let province of provinces_1, let i = index">
                                    <mat-option [value]="province.state_name">
                                    {{ province.state_name }}
                                    </mat-option>
                                </ng-container>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field style="width: 100%;">
                                <input matInput formControlName="Province"  type="text" class="form-control text-box" 
                                name="Province"  placeholder="{{ 'PROVINCELABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                            </mat-form-field> -->
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="PostCode"  type="text" class="form-control text-box" id="PostCode"
                                name="PostCode"  placeholder="{{ 'POSTALCODELABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('PostCode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'IDTYPE' | translate}}</mat-label>
                                    <mat-select formControlName="personal_id_type"  type="text" class="form-control" id="personal_id_type" aria-describedby="personal_id_type"
                                    autocomplete="off">
                                        <mat-option  value=0>{{ "DRIVERLICENSE" | translate }}</mat-option>
                                        <mat-option  value=1>{{ "PASSPORT" | translate }}</mat-option>
                                        <mat-option  value=2>{{ "OGOVISSUEDID" | translate }}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="changeEmailForm.get('personal_id_type').hasError('required')">{{'IDTYPEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="personalId"  type="number" class="form-control text-box" id="personalId"
                                name="personalId"  placeholder="{{ 'IDNUMBER' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('personalId').hasError('required')">{{'IDNUMBERREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="issued_place"  type="text" class="form-control text-box" id="issued_place"
                                name="issued_place"  placeholder="{{ 'ISSUEDPLACE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('issued_place').hasError('required')">{{'ISSUEDPLACEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="issued_date"  type="date" class="form-control text-box" id="issued_date"
                                name="issued_date"  placeholder="{{ 'ISSUEDDATE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('issued_date').hasError('required')">{{'ISSUEDDATEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="expired_date"  type="date" class="form-control text-box" id="expired_date"
                                name="expired_date"  placeholder="{{ 'EXPIREDDATE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeEmailForm.get('expired_date').hasError('required')">{{'EXPIREDDATEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!changeEmailForm.valid || isProcessing1" (click)="changeEmail(changeEmailF)" >{{ "SUBMIT" | translate}}</button>
                            </div>
                        </div> 
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form  [formGroup]="changeFirstLastForm" #changeFirstLastF="ngForm" (keydown.enter)="$event.preventDefault()" autocomplete="cfl-form">
                        <div class="p-3">
                            <h2>{{ 'CHANGEFIRSTLASTLABEL' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="pre_first_name"  type="text" class="form-control text-box" 
                                name="pre_first_name"  placeholder="{{ 'FIRSTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('pre_first_name').hasError('required')">{{'FIRSTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="pre_last_name"  type="text" class="form-control text-box" 
                                name="pre_last_name"  placeholder="{{ 'LASTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('pre_last_name').hasError('required')">{{'LASTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="chang_to_firstName"  type="text" class="form-control text-box"
                                name="chang_to_firstName"  placeholder="{{ 'NEWFIRSTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('chang_to_firstName').hasError('required')">{{'NEWFIRSTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="change_to_lastName"  type="text" class="form-control text-box" 
                                name="change_to_lastName"  placeholder="{{ 'NEWLASTNAME' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('change_to_lastName').hasError('required')">{{'NEWLASTNAMEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="account_number"  type="number" class="form-control text-box" 
                                name="account_number"  placeholder="{{ 'ACCOUNTNUMBER' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('account_number').hasError('required')">{{'ACCOUNTNUMBERREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="Address"  type="text" class="form-control text-box" 
                                name="Address"  placeholder="{{ 'ADDRESSLABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Address').hasError('required')">{{'ADDRESSREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="City"  type="text" class="form-control text-box"
                                name="City"  placeholder="{{ 'CITYLABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('City').hasError('required')">{{'CITYREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                                placeholder="{{'COUNTRYLABEL' | translate}}" name="country">
                                    <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                    {{ country.country }}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                                placeholder="{{'PROVINCELABEL' | translate}}"  name="province">
                                <ng-container *ngFor="let province of provinces_2, let i = index">
                                    <mat-option [value]="province.state_name">
                                    {{ province.state_name }}
                                    </mat-option>
                                </ng-container>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="PostCode"  type="text" class="form-control text-box" 
                                name="PostCode"  placeholder="{{ 'POSTALCODELABEL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeFirstLastForm.get('PostCode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!changeFirstLastForm.valid || isProcessing2" (click)="changeFirstLast(changeFirstLastF)" >{{ "SUBMIT" | translate}}</button>
                            </div>
                        </div> 
                        
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>