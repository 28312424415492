<div class="personal-sponsored-chart-container">
   
    <!-- <div class="text-center json-container">
        <h3>Temporary Display For Data</h3>
        
        <ngx-json-viewer [json]="treeData" [expanded]="false"></ngx-json-viewer>
    </div> -->


    <div class="rectangle">
        <h1>Color Legend</h1>
        <div class ="member">Dealer</div>
        <div class ="consultant">Consultant</div>        
        <div class ="distributor">Director</div>
        <div class ="executive">Executive</div>
        <div class ="sapphire">Sapphire</div>
        <div class ="diamond">Diamond</div>
        <div class ="crown">Crown</div>
    </div>


    <ngx-org-chart class="org-chart" *ngIf="treeNodes.length > 0" [nodes]="treeNodes" [direction]="treeMode" (itemClick)="test($event)"></ngx-org-chart>

    <div *ngIf="treeNodes.length <= 0" class="text-center">
        <h1>Tree Data is not yet available for your account. Refer someone to generate your tree.</h1>
    </div>
    <!-- <app-tree-diagram [treeModel] = "treeModel"></app-tree-diagram> -->

    
</div>