import { PercentPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { User } from '../common/User';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-credit-card-info',
  templateUrl: './credit-card-info.component.html',
  styleUrls: ['./credit-card-info.component.scss']
})
export class CreditCardInfoComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() selectedCreditCard : number = -1;
  public user : User;
  public checkoutForm : FormGroup;
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public creditCards : Array<any>=[];
  public preferredCreditCard : number = 0;
  public shippingAddresses : any[] = [];
  //public selectedCreditCard : number = -1;
  private _unsubscribe$ = new Subject<any>();
  public selectedCountry : {
    name : '',
    code : ''
  };
  public cardTypes = [{ name : 'VISA', type :0},{ name : 'MASTERCARD', type :1},{ name : 'DEBITCARD', type :2}];
  public edit_credit_card_id : number=null;
  public in_edit_credit_card : boolean=false;
  public expiryDateValue : string='';

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private percentPipe : PercentPipe
  ) { }


  ngOnInit(): void {
    this.initializeForms();
    this.getUser();
    this.getCardsInfo();
    //this.getCountryList();
    //this.getUserShippingAddresses();

  }
  initializeForms(){
    this.checkoutForm = this.formBuilder.group({
      cardType : [0, Validators.required],
      cardNo : ['',Validators.required],
      cardHolder : [''],
      expireDate : ['',Validators.required],
      cvCode : ['',Validators.required],
      firstName : ['',Validators.required],
      lastName : ['',Validators.required],
      postCode : ['',Validators.required],
      address : ['',Validators.required],
    });

  }

  onKeydown(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  modifyInput(ele) {
    if (this.expiryDateValue.length==2) {
      if (! (ele.key =='Backspace' || ele.key =='Delete')) {
        this.expiryDateValue += '/';
      }
    }
  }


  selectedCreditCardChanged()
  {
    this.in_edit_credit_card=false;
    if (this.selectedCreditCard>=0) {

    }
    if(this.shippingAddresses[this.selectedCreditCard]){

    }
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.checkoutForm.patchValue({
                name : this.user.first_name + " " + this.user.last_name,
                email : this.user.email,
                firstName : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                address : this.user.Address
              });

              this.cdref.detectChanges();

          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getCardsInfo(){
    this.evgService.getCardsInfo({}).subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.creditCards = resp.data[0].results;
          if (this.creditCards && this.creditCards.length<=0) {
                this.preferredCreditCard = 1;
                this.preferredCreditCardChanged();
                this.checkoutForm.patchValue({
                  firstName : this.user.first_name,
                  lastName : this.user.last_name,
                  address : this.user.Address,
                  postCode : this.user.PostCode,
                  cardHolder : this.user.first_name? (this.user.first_name + ' ' + this.user.first_name) : ''
                });
          } else {
            this.preferredCreditCard = 0;
            this.selectedCreditCard = 0;
            this.preferredCreditCardChanged();
          }
        } else {
          this.responseHandlerService.handleResponse(resp);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getCountryList(){
    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }


  getProvinceList(country)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        this.provinces = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  preferredCreditCardChanged()
  {

    if(this.preferredCreditCard == 1){
      
      
      if(this.shippingAddresses.length > 0){
        this.checkoutForm.patchValue({
          cardType : null,
          cardNo : null,
          expireDate : null,
          cvCode : null,
          firstName : null,
          lastName : null,
          address : null,
          postCode : null,
        });
      }
      
      this.checkoutForm.get('cardType').setValidators(Validators.required);
      this.checkoutForm.get('cardNo').setValidators(Validators.required);
      this.checkoutForm.get('expireDate').setValidators(Validators.required);
      this.checkoutForm.get('cvCode').setValidators(Validators.required);
      this.checkoutForm.get('firstName').setValidators(Validators.required);
      this.checkoutForm.get('lastName').setValidators(Validators.required);
      this.checkoutForm.get('address').setValidators(Validators.required);
      this.checkoutForm.get('postCode').setValidators(Validators.required);

  
      this.checkoutForm.markAsPristine();
      this.checkoutForm.get('cardType').updateValueAndValidity();
      this.checkoutForm.get('cardNo').updateValueAndValidity();
      this.checkoutForm.get('expireDate').updateValueAndValidity();
      this.checkoutForm.get('cvCode').updateValueAndValidity();
      this.checkoutForm.get('firstName').updateValueAndValidity();
      this.checkoutForm.get('lastName').updateValueAndValidity();
      this.checkoutForm.get('address').updateValueAndValidity();
      this.checkoutForm.get('postCode').updateValueAndValidity();

      this.selectedCreditCard = -1;
      this.selectedCreditCardChanged();
      
      this.cdref.detectChanges();
    }else{
      this.checkoutForm.get('cardType').clearValidators();
      this.checkoutForm.get('cardNo').clearValidators();
      this.checkoutForm.get('expireDate').clearValidators();
      this.checkoutForm.get('cvCode').clearValidators();
      this.checkoutForm.get('firstName').clearValidators();
      this.checkoutForm.get('lastName').clearValidators();
      this.checkoutForm.get('address').clearValidators();
      this.checkoutForm.get('postCode').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['cardType'].updateValueAndValidity();
      this.checkoutForm.controls['cardNo'].updateValueAndValidity();
      this.checkoutForm.controls['expireDate'].updateValueAndValidity();
      this.checkoutForm.controls['cvCode'].updateValueAndValidity();
      this.checkoutForm.controls['firstName'].updateValueAndValidity();
      this.checkoutForm.controls['lastName'].updateValueAndValidity();
      this.checkoutForm.controls['postCode'].updateValueAndValidity();

      this.cdref.detectChanges();
      this.selectedCreditCard = -1;
      this.selectedCreditCardChanged();

    }
  }
  
  getYYYYMM(MMYY:string){
    if (MMYY && MMYY.length==4) {
      //return '20'+MMYY.substring(2,4) + '-'+ MMYY.substring(0,2);
      return MMYY.substring(0,2) + '/'+ MMYY.substring(2,4);
    } else {
      return MMYY;
    }

  }

  editcreditCard(creditcard) {
    if(creditcard){
      this.expiryDateValue = this.getYYYYMM(creditcard.expireDate);
      this.checkoutForm.patchValue({
        cardType : creditcard.cardType,
        cardNo : creditcard.cardNo,
        expireDate : this.expiryDateValue,
        cvCode : creditcard.cvCode,
        firstName : creditcard.firstName,
        lastName : creditcard.lastName,
        address : creditcard.address,
        postCode : creditcard.postCode,
      });
      this.preferredCreditCard = 1;
      this.in_edit_credit_card = true;
      this.edit_credit_card_id = creditcard.id;
    }
  }

  deletecreditCard(creditCardId){
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('DELETECONFIRM');
      modalRef.result.then((result) => {
        if(result){
        //console.log('call todoSubmit(data) data:',credit_card);
          this.evgService.deleteCardById({id : creditCardId}).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.getCardsInfo();
              //this.activeModal.close(true);
            }
          }
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          this.shareinfoService.setCreditProcess(false);
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
        }
      });
  }

  showCardNo(inCardNumber) {
    if (inCardNumber && inCardNumber.length>4) {
      let stars ="********************";
      return (stars.substring(0,inCardNumber.length-5)+inCardNumber.substring(inCardNumber.length-4,inCardNumber.length));
    } else {
      return inCardNumber;
    }

  }

  convertExpireDate(setYYYYMM) {
    if (setYYYYMM && setYYYYMM.length>6) {
      return setYYYYMM.substr(-2) + setYYYYMM.substr(2,2);
      //console.log('expireDate :',expireDate);
    } else {
      return setYYYYMM;
    }
  }

  todoSubmit() {
    if(this.checkoutForm.valid){
      //if (this.shareinfoService.validateExpiry(this.convertExpireDate(this.checkoutForm.get('expireDate').value))) {
      if (this.shareinfoService.validateExpiryMMYY(this.checkoutForm.get('expireDate').value)) {
        let credit_card =  {
          cardType : this.checkoutForm.get('cardType').value,
          cardNo : this.checkoutForm.get('cardNo').value,
          expireDate : this.checkoutForm.get('expireDate').value,
          cvCode : this.checkoutForm.get('cvCode').value,
          firstName : this.checkoutForm.get('firstName').value,
          lastName : this.checkoutForm.get('lastName').value,
          postCode : this.checkoutForm.get('postCode').value,
          address : this.checkoutForm.get('address').value,
        };
        if (this.in_edit_credit_card && this.edit_credit_card_id>0) {
            credit_card["id"] = this.edit_credit_card_id;
            credit_card["default_card"] =1;
        }
        //console.log('call todoSubmit(data) data:',credit_card);
        this.evgService.updatedCreditCard(credit_card).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.activeModal.close(true);
            }
          }
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          this.shareinfoService.setCreditProcess(false);
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
        this.responseHandlerService.handleResponse({ success : false, data : [{error : "expired date"}],  message : "UNUSECARD" });
      }
    }
  }
  saveSelectedCard(){
    if (this.selectedCreditCard >=0 && this.creditCards.length>this.selectedCreditCard) {
        let credit_card = {
                            id : this.creditCards[this.selectedCreditCard].id,
                            default_card : 1,
                          };
        console.log('call saveSelectedCard(data) data:',credit_card);
        this.evgService.updatedDefaultCreditCard(credit_card).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.activeModal.close(true);
            }
          }
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          this.shareinfoService.setCreditProcess(false);
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    }
    this.activeModal.close(false);
  }
}
