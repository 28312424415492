<div class="personal-new-orders-container">
    
  <div class="row gutters-sm">
    
    <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
              
            <h3 class="title">{{'PERSNEWORDER' | translate}}</h3>
            <div class="text-right mb-2" *ngIf="selectedOrderDetails">
              <a mat-stroked-button color="primary" (click)="unSelectOrder()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered" *ngIf="!selectedOrderDetails">
                    <thead>
                      <tr>
                        <th scope="col">{{'GROUPID' | translate}}</th>
                        <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                        <th scope="col">{{'QTY' | translate}}</th>
                        <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                        <th scope="col">{{'CANCELLED' | translate}}</th>
                        <th scope="col">{{'CANCELDATE' | translate}}</th>
                        <th scope="col">{{'PURCHDATE' | translate}}</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of newOrders; index as i;">
                        <td><a (click)="selectOrder(order.groupID)" style="cursor: pointer; color: blue;" >{{ order.groupID }}</a></td>
                        <td>{{ order.passedDay }}</td>
                        <td>{{ order.pNums }}</td>
                        <td>{{ order.amount | currency:'' }}</td>
                        <td>{{ order.cancelFlag ? ('YES' | translate) : ('') }}</td>
                        <td>{{ order.cancelDate }}</td>
                        <td>{{ order.addtime }}</td>
                        
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered" *ngIf="selectedOrderDetails">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                          <th scope="col">{{'PRODUCTDESC' | translate}}</th>
                          <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let selectedOrderDetail of selectedOrderDetails; index as i;">
                          <td>{{ selectedOrderDetail.MemberNo}}</td>
                          <td>{{ selectedOrderDetail.pTitle }}</td>
                          <td>{{ selectedOrderDetail.pContent }}</td>
                          <td>{{ selectedOrderDetail.amount | currency:''}}</td>
                          <td>{{ selectedOrderDetail.addtime }}</td>
                        </tr>
                      </tbody>
                    </table>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>