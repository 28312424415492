import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-profile-updated-history',
  templateUrl: './profile-updated-history.component.html',
  styleUrls: ['./profile-updated-history.component.scss']
})
export class ProfileUpdatedHistoryComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() userId : number=null;

  public historyList : any[] = [];

  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;

  constructor(
    public activeModal : NgbActiveModal,
    private modalService: NgbModal,
    public router : Router,
    public ngDialog : NgbModal,
    public route: ActivatedRoute,
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getHistoryList();
  }

  getHistoryList()
  {
    this.isProcessing = true;
    this.evgService.getProfileUpdateHistory( {userId :this.userId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.historyList = resp.data[0].results;
          
          //console.log("getRequestlist",this.requests );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
