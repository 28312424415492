<div class="container">
    <div class="page-banner" >
        <div>
            <h1 translate>NEWS</h1>
        </div>
    </div>

    <div class="news-container">
        <div class="row">
            <div class="col-lg-2">
                
            </div>
            <div class="col-lg-8">
                <div class="main">
                    <div class="news-box">
                        <hr>
                        <h4>March 2, 2021</h4>
                        <h1 class="news-title m-3">HIGH AMOUNTS OF LEAD FOUND IN DRINKING WATER</h1>
                        <div class="news-desc">
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/YYO-EfQWjOY?wmode=opaque&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <p class="text-center">Source : https://www.youtube.com/watch?v=YYO-EfQWjOY</p>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>March 2, 2021</h4>
                        <h1 class="news-title m-3">Dr. Oz Issues A State Of Emergency With Our Drinking Water</h1>
                        <div class="news-desc">
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/5T7yIbZRFmU?wmode=opaque&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <p class="text-center">Source : https://www.youtube.com/watch?v=5T7yIbZRFmU</p>
                    </div>

                    <div class="news-box">
                        <hr>
                        <h4>January 17, 2020</h4>
                        <h1 class="news-title m-3">Health Risks of Drinking Demineralized Water.</h1>
                        <div class="news-desc">
                           <p>This is a must read study that dives into Revere Osmosis water and demineralized drinking water. For a long time this source of water or purification has been deemed as the go-to water purification method.</p>
                           <p>Summized points:</p>
                           <p>The possible adverse consequences of low mineral content water consumption are discussed in the following categories: </p>
                           <ul>
                               <li>Direct effects on the intestinal mucous membrane, metabolism and mineral homeostasis or other body functions.</li>
                               <li>Little or no in take of calciumand magnesium from low-mineral water. </li>
                               <li>Low in take of other essential elements and micro elements. </li>
                               <li>Loss of calcium, magnesium and other essential elements in prepared food. Possible increased dietary in take of toxic metals.</li>
                           </ul>
                           <p>Read the full report here conducted by Frantisek Kozisek</p>
                           <p>National Institute of Public Health Czech Republic and World Health Organization __________________________________________</p>
                           <p><a href="https://www.who.int/water_sanitation_health/dwq/nutrientschap12.pdf">https://www.who.int/water_sanitation_health/dwq/nutrientschap12.pdf</a></p>

                        </div>
                    </div>

                    <div class="news-box">
                        <hr>
                        <h4>January 16, 2020</h4>
                        <h1 class="news-title m-3">6 Ways your Drinking Water can be harmful!</h1>
                        <div class="news-desc">
                            <p>When you feel thirsty, you may not think twice about reaching for a glass or a bottle of water. But you might want to think about it, because the water you’re drinking may be robbing you of your health. Bottled water, often promoted with claims of purity and healthfulness, can contain harmful BPA, and is often little more than filtered tap water anyways. In fact, for health purposes, you are better off filtering your tap water yourself. Healthy drinking water doesn’t necessarily come in plastic bottles, it can be had at the tap if you use common sense.</p>
                            <p>1. Bottled Water – Bisphenol A and other toxins</p>
                            <p>Your drinking water may be killing you</p>
                            <p>Research has shown that plastic bottles can leach Bisphenol-A, (BPA) a potent hormone disruptor. BPA has been banned in some countries due to health concerns, but bottled water industry lobbying has kept it legal in the US. The amount of BPA that leaches into water depends on two factors: Heat and time. The longer the water sits in the bottle, and the hotter the bottle gets, the more likely BPA contamination becomes. Antimony is also one of the main contaminants found in bottled water, which is a toxic chemical that slowly leaches out overtime. The expiry date on the bottle is actually for the bottle and not the water contained within. </p>
                            <p>Tip: Never leave a plastic water bottle in a car because it can get very hot. Women who are pregnant should consider avoiding bottled water entirely. As it is very harmful to their pregnancy and traces of contaminants can be found in the amniotic fluid. </p>
                            <p>2. Dirty water bottles</p>
                            <p>Got a reusable water bottle? Good for you, it’s better for the environment and cheaper to boot! Just be sure to wash it thoroughly every evening when you are done drinking from it. Bacteria like to grow in warm, wet places – like under the lid of your water bottle. Then leave it to dry out.</p>
                            <p>Tip: Washing your bottle at night gives it time to dry out</p>
                            <p>3. Unfiltered tap water</p>
                            <p>Chlorine is used in virtually 100% of water supplies to disinfect the water, but chlorine is not good for you. Research has shown that a byproduct of chlorine that is found in drinking water, dichlorophenol is associated with a much higher risk of developing food allergies. Also many tap waters contain Trihalomethanes that can explode free radicals in the body and cause cancer. </p>
                            <p>You can effectively filter chlorine, chloramines,  and other toxins from your drinking water using the EV Green. The best way to ensure your water is safe to drink is to find out which toxins are in it by evaluating your local water quality report. </p>
                            <p>4. Drinking water from the hose or shower</p>
                            <p>Drinking water from a hose or shower head is a definite no-no. Both of these water sources are places where bacteria like to gather, and neither source is filtered for your safety. Cleaning your shower head from time to time is a good idea, but it is never safe to drink from it! A garden hose is made of plastic and can leach chemicals into the water you drink.</p>
                            <p>5. Artificial flavors and sweeteners</p>
                            <p>Choosing to drink water over soda or other harmful beverages is a healthy choice – unless you add harmful additives to it! Read the label on any flavorings you are thinking of adding to your water. Keep in mind that “natural” can mean anything! Arsenic, for example, is found in nature. So anything that contains arsenic could technically be considered natural!</p>
                            <p>You are much better off flavoring your water with a slice of fruit, and perhaps a bit of stevia or organic unbleached sugar. If you are fortunate enough to have alkaline water, a slice of lemon or orange unlocks the antioxidant potential of alkaline water.</p>
                            <p>Tip: Read the ingredients of that flavor packet out loud. If you can’t pronounce the ingredients, don’t put it in your water!</p>
                            <p>6. Drinking too much water</p>
                            <p>Most people don’t need to worry about this, water is pretty hard to overdo. Drinking too much water can cause hyponatremia, a condition where sodium levels in the body drop to dangerously low levels. This is hard to do, because most people have too much sodium in their diets, and are actually dehydrated as a result. Marathon runners and other endurance athletes are most at risk for hyponatremia.</p>
                            <p>Tip: If you are working out for more than an hour, be sure to replace the electrolytes that you lose to sweat.</p>
                            <p>The Solution:</p>
                            <p>Alkaline water is best for health! Cancer can not live in an alkaline body. - Dr. Otto Warburg (Nobel Peace Prize Winner)</p>
                            <p>Alkaline water is considered to be functional water, because of what it does for you. Alkaline water has the ability to neutralize acid, and it hydrates better than plain water. Another benefit of alkaline water is nutrition, alkaline water gets its alkalinity from the minerals in it, which are easier for your body to absorb than they are from supplements or food. In fact, the World Health Organization reports that your body absorbs minerals from water 30% faster and easier than it does from food!</p>
                            <p>Tip: If alkaline water comes from any other source other than minerals its not alkaline water!</p>
                        </div>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">Elevated levels of lead found at three of 15 homes tested in Vancouver.</h1>
                        <div class="news-desc">
                            <p class="font-weight-bold">BY V. S. WELLS, NANCY WU, AND LAUREN DONNELLY, UNIVERSITY OF BRITISH COLUMBIA; AINSLIE CRUICKSHANK, STAR VANCOUVER; AARON MCARTHUR, GLOBAL NEWS GLOBAL NEWS</p>
                            <p class="font-weight-bold">THE CITY OF VANCOUVER WANTS TO HAVE “THE BEST DRINKING WATER OF ANY CITY IN THE WORLD” BY 2020. BUT SOME HOMES IN VANCOUVER DO NOT MEET HEALTH CANADA’S GUIDELINES FOR LEAD IN DRINKING WATER.</p>
                            <p class="font-weight-bold">Editor’s note: This article was updated at 7:34 p.m. PT on Nov. 5, 2019 to note that the lead levels found in Flint were significantly higher than those found in Vancouver.</p>
                            <p>VANCOUVER—Lead levels that exceed federal guidelines were detected in drinking water at three of 15 Vancouver homes tested as part of a nationwide investigation, which found drinking water contaminated with the toxic metal from British Columbia to Nova Scotia.</p>
                            <p>As part of its Greenest City Action Plan, the City of Vancouver is aiming to have “the best drinking water of any city in the world” by 2020.</p>
                            <p>One of its targets is to “meet or beat the strongest of British Columbian, Canadian and appropriate international drinking water quality standards and guidelines.”</p>
                            <p>Yet, some homes in Vancouver do not meet Health Canada’s <a href="https://www.canada.ca/content/dam/hc-sc/healthy-canadians/migration/health-system-systeme-sante/consultations/lead-drinking-water-plomb-eau-potable/alt/lead-drinking-water-plomb-eau-potable-03-01-2017-eng.pdf">guidelines</a> for lead in drinking water, which set the maximum acceptable concentration at five parts per billion (ppb).</p>
                            <p class="font-weight-bold">WATCH: <a href="https://globalnews.ca/news/6114854/canada-tapwater-high-lead-levels-investigation/"> Is Canada’s tap water safe? Thousands of test results show high lead levels across the country</a></p>
                            <p>“Water has to be clean and we have our part to play in that, so we’ll make sure we do our part,” Mayor Kennedy Stewart said Tuesday.</p>
                            <p>Stewart said he will ask city staff to get people who have concerns information about how they can deal with lead in drinking water and how the city can help.</p>
                            <p class="font-weight-bold">2:23 Investigation leads to questions about safety of children’s drinking water in Alberta</p>
                            <p>Investigation leads to questions about safety of children’s drinking water in Alberta</p>
                            <p>Every city deals with older infrastructure, he said. Vancouver, as a relatively younger city, “is in pretty good shape here, and the thing is to stay on top of it.”</p>
                            <p>Reporters from the University of British Columbia, as part of an investigation by a national consortium of universities and media companies, including Concordia University’s Institute for Investigative Journalism, <a href="https://globalnews.ca/tag/safe-water/">Global News</a>  and <a href="https://www.thestar.com/news/investigations/lead-in-water.html">Star Vancouver</a>, collected water samples from Vancouver homes in Strathcona, Hastings-Sunrise, Renfrew-Collingwood and Sunset in November 2018 and February of this year and had them tested by an accredited lab.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/6107982/ontario-schools-daycares-lead-levels/"> Drinking water at thousands of Ontario schools, daycares have dangerous lead levels	</a></p>
                            <p>Lead levels of 7.46 ppb were detected in water samples from the 75-year-old home Trula O’Haire rents in the South Vancouver neighbourhood of Sunset.</p>
                            <p>“Of course, I’m concerned. I didn’t even know about it until last year and I’ve raised two sons and one of them just moved out about five years ago, so that means for his whole university career he was drinking leaded water, too,” she said. “And I have a puppy.”</p>
                            <p>“I never, ever thought about it. You know, I was so empathic with Flint, Mich., and ‘Oh my God, how could that happen, bad U.S.A.,’ and it never occurred to me that here I am, drinking water that’s not so good as well,” she said. (Flint made international headlines in 2015 after elevated levels of lead were found in residents’ tap water. Lead levels detected by testing by journalists in Vancouver were significantly lower than those found in Flint.)</p>
                            <p class="font-weight-bold">1:49 BC falling short in testing for lead in water</p>
                            <p>BC falling short in testing for lead in water</p>
                            <p>“At least I don’t have small children, but it’s such a health hazard,” she said. “It’s frightening.”</p>
                            <p>“Something definitely needs to be done.”</p>
                            <p>Reporters also found elevated lead levels in drinking water at homes in Prince Rupert, B.C., where 84 per cent of the 25 homes tested as part of this investigation exceeded Health Canada’s guidelines in at least one sample.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/5482287/quebec-children-lead-exposure-water-report/">Quebec children at risk of lead exposure from water in schools and daycares: report</a> 	</p>
                            <p>Health bodies, such as the World Health Organization, <a href="https://www.who.int/news-room/fact-sheets/detail/lead-poisoning-and-health">say there is no safe level of lead.</a> </p>
                            <p>The metal is a potent neurotoxin that accumulates in the body overtime. Babies, young children, pregnant women and developing fetuses are among the most vulnerable to its health effects. Even at low levels, previously considered to be safe, chronic exposure can affect developing brains, lowering IQs and increasing the risk for learning disabilities. Lead is also a risk factor for heart disease, the leading cause of death worldwide.</p>
                            <p>It’s unclear how many people in Vancouver may be at risk from elevated levels of lead in their drinking water, because the city doesn’t regularly test at the tap in private homes — and there are no provincial rules that require them to do so.</p>
                            <p>“I find that really frustrating, that the city isn’t being more careful,” O’Haire said.</p>
                            <p class="font-weight-bold">3:00 Canadian kids likely exposed to lead in water</p>
                            <p>Canadian kids likely exposed to lead in water</p>
                            <p>Vancouver resident Kate Webb, who lives in Hastings-Sunrise, is four months pregnant with her first child. She said she was “shocked” when she read about the issues with lead in drinking water in communities across Canada.</p>
                            <p>“An unborn baby is just so much more sensitive to even small concentrations of heavy metals,” she said. “So, if we find out that there is lead in the drinking water, as a pregnant person, that’s pretty disturbing.”</p>
                            <p>Webb said she will start flushing her taps immediately and plans to test her water for lead, as well.</p>
                            <p>“This is not a joke. This is really serious, and everyone in Canada should be paying attention.”</p>
                            <p>The city does occasional testing for metals in areas with turbidity issues, according to spokesperson HenryTye Glazebrook. Otherwise, he said, residents can contact water-quality testing labs to conduct lead tests.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/6109550/edmonton-water-lead-levels">Edmonton residents exposed to high lead levels in water over last decade, investigation finds</a> 	</p>
                            <p>Without proper testing, lead is difficult to detect in water because it is colourless, scentless and tasteless.</p>
                            <p>A spokesperson for Vancouver Coastal Health said, “There have been very few instances of people identified with elevated lead levels in our region, and none of the cases we have identified have ever been due to drinking water. We have no reported cases of children being adversely affected by lead in drinking water in the Vancouver Coastal Health region.”</p>
                            <p>He added, it’s important to keep lead exposure as low as possible and noted Vancouver Coastal Health provides <a href="http://www.vch.ca/Documents/Lead-in-drinking-water-protecting-yourself.pdf">advice</a>  on how residents can protect themselves from lead in drinking water.</p>
                            <p class="font-weight-bold">2:56 Tainted Water: Are the fountains safe?</p>
                            <p>Tainted Water: Are the fountains safe?</p>
                            <p>In Vancouver, the responsibility for delivering clean drinking water to homes and businesses is shared by the city and Metro Vancouver.</p>
                            <p>Metro Vancouver manages three watersheds, treats drinking water at two local plants and distributes water to local governments, explained Greg Valou, a spokesperson for the regional body in a statement.</p>
                            <p>The City of Vancouver, meanwhile, is responsible for operating the systems of pipes that deliver water to homes, businesses, schools, hospitals and other buildings.</p>
                            <p>Both Metro Vancouver and the city say there are no lead pipes in their distribution systems.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/2496027/avoiding-lead-exposure-what-you-need-to-know/">Avoiding lead exposure: what you need to know	</a> </p>
                            <p>Valou said lead levels are too low to be detected in both the source water and water moving through Metro Vancouver’s transmission system. Lead levels were also well below Health Canada’s guidelines in water samples from Vancouver’s distribution system, according to the city’s 2018 annual water report, and are sometimes too low to be detected.</p>
                            <p>There is a risk of contamination however, when water comes into contact with plumbing fixtures, including brass faucets, or solder, which is used to join pipe, that may contain lead in older homes. That risk is heightened if the water is acidic.</p>
                            <p>In B.C., and particularly in coastal municipalities such as Vancouver, rainwater and snowmelt contribute to water that’s naturally acidic.</p>
                            <p>Acidic water, which has a pH below 7, is a major factor in the corrosion of metal pipes and solder.</p>
                            <p class="font-weight-bold">2:48 Some Edmonton homes have 5 to 10 times the safe amount of lead in their water, investigation finds</p>
                            <p>Some Edmonton homes have 5 to 10 times the safe amount of lead in their water, investigation finds</p>
                            <p>To reduce the risks of lead leaching, Metro Vancouver incorporated corrosion controls into its water treatment process in 1998 and still adjusts its water’s pH to make it less acidic.</p>
                            <p>While these measures can significantly reduce the risks of lead leaching into drinking water, it may not eliminate them entirely.</p>
                            <p>Metro Vancouver spokesperson Valou said, “It is up to individual home and building owners, industries, commercial businesses and institutions to ensure that their piping systems are in good repair once water enters their property.”</p>
                            <p>In Prince Rupert, corrosive water is contributing to widespread issues with lead in drinking water.</p>
                            <p>The city says its new water treatment plant will help reduce corrosion, but Prince Rupert Mayor Lee Brain said it won’t solve the problem entirely.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/6041423/quebec-review-water-investigation/">Quebec to review how it tests drinking water following investigative report</a> 	</p>
                            <p>“If there weren’t sources of lead in the residential plumbing, it wouldn’t get into the water, which is why we encourage people where possible to consider replacing all plumbing components containing lead or conduct flushing,” he said, in a video posted by the City of Prince Rupert in August.</p>
                            <p>Flushing the taps by letting the water run until it’s noticeably colder before drinking can help reduce the risk of exposure to lead.</p>
                            <p>Lead levels dropped to 0.58 ppb in samples taken from O’Haire’s tap after the water had run for 45 seconds and then again to 0.51 ppb in samples taken after the water had been running for two minutes.</p>
                            <p class="font-weight-bold">3:10 Investigation shows lead is a very real threat in Canadian water</p>
                            <p>Investigation shows lead is a very real threat in Canadian water</p>
                            <p>Bruce Lanphear, a health sciences professor at Simon Fraser University and an expert on lead toxicity, said flushing is a reasonable solution in the short-term.</p>
                            <p>“But if you’ve ever sat and waited two minutes for water to flush before you use it … it’s extraordinarily long,” he said. “It’s not a viable solution. We really need to get some filters in place to protect people.”</p>
                            <p>Flushing may not be effective in every household though. A 118-year-old house in Strathcona showed 9.2 parts per billion in the first sample taken in the morning. That dropped to 4.87 ppb after a two minute flush — just barely under the federal guideline.</p>
                            <p>Lanphear also noted that flushing is a waste of water. And, widespread flushing is inconsistent with the City of Vancouver’s target to reduce water consumption.</p>
                            <p>The city does not subsidize filters or plumbing replacement, Glazebrook said.</p>
                            <p>By not testing at tap, Lanphear said, the city doesn’t know how well its corrosion control is working.</p>
                            <p>“From a public health perspective … one of the responsibilities of a local government is to make sure that the citizens are protected from things in their home,” said Lanphear.</p>
                            <p class="font-weight-bold">READ MORE: <a href="https://globalnews.ca/news/6113641/calgary-water-lead-levels">Calgary doesn’t know how many homes have lead pipes that could make water unsafe</a> 	</p>
                            <p>“And, so right now they’re failing to ensure that their citizens are protected from lead at the tap. They don’t know what’s going on,” he said.</p>
                            <p>Webb said she wants the city to offer free lead tests for concerned residents and better public education about the risks of lead in drinking water and the steps residents can take to protect themselves.</p>
                            <p>“Some people are fine, but you’re not going to know that unless you do some digging,” she said.</p>
                            <p>Vanessa Namuhje, a mother to three young children, also said the city could do a better job educating the public.</p>
                            <p>Lead levels of 4.58 ppb were found in her tap water, which was tested as part of this investigation. While that’s below Health Canada’s guideline, she said she’s still concerned.</p>
                            <p class="font-weight-bold">3:23 Is Montreal’s lead pipe problem worse than Flint Michigan’s?</p>
                            <p>Is Montreal’s lead pipe problem worse than Flint Michigan’s?</p>
                            <p>“Every time I open that faucet, I always think about ‘OK, does this have something in it?’ I don’t know,” she said.</p>
                            <p>Namuhje said she finds it hard to believe that Vancouver will come close to having the world’s cleanest water by 2020.</p>
                            <p>“I’m sure if they do their testing and if they give their due diligence, then they would find that it’s kind of a joke to say ‘cleanest water,’” she said. “You have all these houses that (could) have lead in them.”</p>
                            <p>Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p>
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/YYO-EfQWjOY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>Toronto Star investigative reporter Robert Cribb discusses the study that looked at water samples in 11 different Canadian cities.</p>
                    
                        </div>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">SHOCKING DETAILS ABOUT CANADIANS WATER! - #TAINTEDWATER</h1>
                        <div class="news-desc">
                            <p>We at EV Green will keep you up to date as possible on this year long investigation involving Canada’s drinking water. This is a major breakthrough and global collective spanning the whole country on bringing to light Canada’s water crisis. #taintedwater</p>
                            <p>For the Headquarters page for the campaign click the link below. Please share this article! </p>
                            <p>https://globalnews.ca/tag/safe-water/</p>
                            <p class="font-weight-bold">Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p>
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/0KsB8TDPvl8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>Tainted Water, a year-long investigation by more than 120 journalists from nine universities and 10 media organizations, found hundreds of thousands of Canadians are consuming tap water laced with high levels of lead leaching from aging and deteriorating infrastructure.</p>
                        </div>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">Tainted Water: Are the fountains a safe? Global News, Toronto Star</h1>
                        <div class="news-desc">
                            <p>More than 2,400 Ontario schools and daycares exceeded the current federal guideline for lead in drinking water in the past two years, a <a href="https://globalnews.ca/tag/safe-water/">joint investigation</a> between Global News, The Toronto Star, the Ryerson School of Journalism and Concordia University’s Institute for Investigative Journalism has found.</p>
                            <p>The figure marks a 275 per cent jump from two years ago due to more frequent testing and tougher federal lead limits that reveal a dramatically larger problem than was previously known.</p>
                            <p>While Ontario’s lead threshold has been at 10 parts per billion (ppb) — and remains there as the province adapts to the new national guideline — the Health Canada level of five ppb announced earlier this year captures what experts agree is a level more reflective of the neurotoxin’s health risks.</p>
                            <p>Full Report</p>
                            <p>https://globalnews.ca/news/6107982/ontario-schools-daycares-lead-levels/</p>
                            <p>Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p>
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/quRujVsrHXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>While hundreds of thousands of Canadians may be drinking water with high levels of lead, Torontonians don't often worry about the water coming out of the tap at home. However, after a yearlong investigation, in partnership with the Ryerson School of Journalism, data shows we may need to think twice about the drinking water at our schools and daycares.</p>
                        </div>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">Secrets of Canada’s Tap Water, Explained</h1>
                        <div class="news-desc">
                            <p>Would it surprise you to know drinking water in some Canadian cities contains unsafe levels of lead? </p>
                            <p>A year-long investigation by more than 120 journalists from nine universities and 10 media organizations found some disturbing answers.</p>
                            <p>Hundreds of thousands of Canadians could be consuming tap water laced with high levels of lead leaching from aging infrastructure and plumbing, a large collection of newly-released data and documents reveals.</p>
                            <p>For more info, please go to https://globalnews.ca/news/6114854/canada-tapwater-high-lead-levels-investigation/</p>
                            <p>Subscribe to Global News Channel HERE: http://bit.ly/20fcXDc
                                <br>Like Global News on Facebook HERE: http://bit.ly/255GMJQ
                                <br>Follow Global News on Twitter HERE: http://bit.ly/1Toz8mt
                                <br>Follow Global News on Instagram HERE: https://bit.ly/2QZaZIB
                                <br>#GlobalNews #TaintedWater</p>
                            <p>FULL REPORT</p>
                            <p>https://globalnews.ca/news/6114854/canada-tapwater-high-lead-levels-investigation/</p>
                            <p>Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p>
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/l0h55NoUBmg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>Would it surprise you to know drinking water in some Canadian cities contains unsafe levels of lead? A year-long investigation by more than 120 journalists from nine universities and 10 media organizations found some disturbing answers.</p>


                        </div>
                    </div>
                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">What do you do if you find lead in your tap water in Canada? BY GLOBAL NEWS, INSTITUTE FOR INVESTIGATIVE JOURNALISM GLOBAL NEWS</h1>
                        <div class="news-desc">
                            <p>As many municipal and provincial leaders are rushing to defend their tap water as safe, some levels of government across the country are already taking action to get the lead out.</p>
                            <p>The explanations about what municipal, provincial and federal officials have or haven’t done are flooding out of city halls and legislatures across the country in the wake of a <a href="https://globalnews.ca/news/6114854/canada-tapwater-high-lead-levels-investigation/">year-long investigation</a> by Global News, Concordia University’s Institute for Investigative Journalism and other media partners, including journalism students from across the country, that analyzed thousands of tap water test results. </p>
                            <p>The newly-released data, released by cities through freedom of information legislation, revealed that 33 per cent of about 12,000 samples, collected by cities since 2014, exceeded Health Canada’s current recommended limit of five parts per billion (ppb). - Global News, Toronto Star</p>
                            <p>Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p>
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/3RRfA-M8X8g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>Many Canadian cities are facing the problem of dangerous lead levels in drinking water, revealed a year-long collaborative investigation by more than 120 journalists from nine universities and 10 media organizations, including Global News and Concordia University's Institute for Investigative Journalism. Heather Yourex-West reports.</p>
                        </div>
                    </div>

                    <div class="news-box">
                        <hr>
                        <h4>November 6, 2019</h4>
                        <h1 class="news-title m-3">'Last Week Tonight' explores the dangers of PFAS, and recommends Reverse Osmosis systems to reduce the risk of "forever chemicals" in drinking water.</h1>
                        <div class="news-desc">
                            <p>The award-winning Last Week Tonight show on HBO recently discussed the lack of regulation against PFAS, a class of "forever chemicals" found in common household items.</p>
                            <p>The group of chemicals, with strong carbon-fluorine bonds, do not degrade in the environment, and have been linked to health issues such as high cholesterol, ulcerative colitis, pregnancy-induced hypertension, thyroid disease, testicular cancer, kidney cancer and decreased response to vaccines.</p>
                            <p>The show singles out one PFAS - PFOA. "Also known as C8 because of its eight carbon molecules. 3M started selling C8 back in 1951 to the chemical giant Dupont, which then used it to make teflon", Oliver explains. "A CDC study has found that C8 is now in the blood of 99.7% of Americans".</p>
                            <p>He continues by saying, "Now, if you’re worried about what the water is like where you live, you can go to this address, <a href="https://www.ewg.org/interactive-maps/pfas_contamination/map/?_kx=7F_eIOUzyjGuk-zHyBjXPwwJDp0GMHmE537pgcHjuSz_ygZJ6oaBJ0qJaoflZgLY.XgjRXP">ewg.org/PFASmap</a>, and if you don’t like what you see, you can consider solutions like ... using a reverse osmosis filter."</p>
                            <!-- <p>Source:</p>
                            <p class="font-italic">Credits: Faculty Supervisor: Charles Berret, University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Institute for Investigative Journalism, Concordia University: Series Producer: Patti Sonntag Research Coordinator: Michael Wrobel; Project Coordinator: Colleen Kimmett; Investigative Reporting Fellow: Lauren Donnelly</p>
                            <p class="font-italic">Institutional Credits: University of British Columbia, Graduate School of Journalism</p>
                            <p class="font-italic">Produced by the Institute for Investigative Journalism, Concordia University</p>
                            <p class="font-italic">See the full list of Tainted Water series credits here: <a href="http://concordia.ca/watercredits">concordia.ca/watercredits</a>.</p>
                            <p>© 2019 Global News, a division of Corus Entertainment Inc.</p> -->
                            <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/9W74aeuqsiU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <!-- <p>Many Canadian cities are facing the problem of dangerous lead levels in drinking water, revealed a year-long collaborative investigation by more than 120 journalists from nine universities and 10 media organizations, including Global News and Concordia University's Institute for Investigative Journalism. Heather Yourex-West reports.</p> -->
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-2">
    
            </div>
        </div>
    </div>
   
</div>