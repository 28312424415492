import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cart, Product } from 'src/app/modules/common/Cart';
import { Products } from 'src/app/modules/common/Products';
import { User } from 'src/app/modules/common/User';
import { ProductComponent } from 'src/app/modules/product/product.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrls: ['./product-setting.component.scss']
})
export class ProductSettingComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public products: Products[] = [];
  public selectedProduct : Product = new Product();
  public user : User;
  public selectedShowCat: number=0;


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,

  ) { }

  ngOnInit(): void {
    this.getProducts();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getProducts()
  {
    this.evgService.searchSortProducts({sortType: 0,forAll:1})//, show_category : this.selectedShowCat
   // this.evgService.getProducts([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  addNewProduct(){
    const modalRef = this.ngDialog.open(ProductComponent, { windowClass: 'product-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.product = null;
    modalRef.result.then((data) => {
      this.getProducts();
    }, (reason) => {
      this.getProducts();
    });
  }
  modifyProduct(pId){
    this.evgService.getProduct({pId : pId})
    .pipe( takeUntil(this._unsubscribe$) )
    .subscribe((resp) => {
      if (resp) {
        if(resp.data[0].results.length > 0){
          if(resp.data[0].results[0]){
            this.selectedProduct = resp.data[0].results[0];
            const modalRef = this.ngDialog.open(ProductComponent, { windowClass: 'product-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
            modalRef.componentInstance.translate = this.translate;
            modalRef.componentInstance.product = this.selectedProduct;

            modalRef.result.then((data) => {
              this.getProducts();
            }, (reason) => {
              this.getProducts();
            });
            
          }
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  deleteProduct(pId){
    if(confirm("Delete Product?")){
      this.evgService.deleteProduct({pId : pId})
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.getProducts();
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    }
  }

  selectCategory()
  {
    console.log('selectCategory show_category:',this.selectedShowCat);
    this.getProducts();
  }
}
