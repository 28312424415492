import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/modules/common/Order';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-personal-orders-record',
  templateUrl: './personal-orders-record.component.html',
  styleUrls: ['./personal-orders-record.component.scss']
})
export class PersonalOrdersRecordComponent implements OnInit {

  public user: User;
  public myOrders : Order[] =  [];
  private _unsubscribe$ = new Subject<any>();

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getMyOrders();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMyOrders()
  {
    this.evgService.getMyOrders( {} ).subscribe(resp => {
      if(resp.success){
        this.myOrders = resp.data[0].results;
        //console.log(this.myOrders);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
