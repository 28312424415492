
<div class="subscribe d-flex flex-column" *ngIf="!currentRoute.includes('backmain') && !skipSubscribePages.includes(currentRoute)">
    <h1 class="subscribe-head" translate>
        SUBSCRIBE
    </h1>
    <p class="subscribe-text" translate>SUBSCRIBEDESC</p>
    <div class="separator mb-5">

    </div>
    <div class="subscribe-body">
        <form  [formGroup]="subscribeForm" #subscribe="ngForm" (keydown.enter)="$event.preventDefault()" autocomplete="s-form">
            <div class="row">
                <div class="col-sm-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'NAME' | translate }}<span class="required">*</span></mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'NAME' | translate }}">
                        <mat-error class="error-message" *ngIf="subscribeForm.get('name').hasError('required')">{{'NAMEREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'EMAIL' | translate }}<span class="required">*</span></mat-label>
                        <input matInput formControlName="email" type="email" placeholder="{{ 'EMAIL' | translate }}">
                        <mat-error class="error-message" *ngIf="subscribeForm.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                        <mat-error class="error-message" *ngIf="subscribeForm.get('email').hasError('email')">{{'EMAILISVALID' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'PHONENO' | translate }}<span class="required">*</span></mat-label>
                        <input matInput formControlName="phone" type="number" placeholder="{{ 'PHONENO' | translate }}">
                        <mat-error class="error-message" *ngIf="subscribeForm.get('phone').hasError('required')">{{'TELEPHONEREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'MESSAGELABEL' | translate }}<span class="required">*</span></mat-label>
                        <textarea rows="4" formControlName="message" matInput></textarea>
                        <mat-error class="error-message" *ngIf="subscribeForm.get('message').hasError('required')">{{'MESSAGEREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row d-flex">
                <div class="col-sm-12 text-center">
                    <button type="submit"  class="btn subscribe-button" (click)="postSubscribe(subscribe)" [disabled]="isLoading">{{ 'SUBSCRIBEBTN' | translate }}</button>
                </div>
            </div>
        </form>
    </div>  
</div>
<div class="footer-container p-5" *ngIf="!isPrinting">
    <div class="footer-links d-flex justify-content-center flex-wrap flex-md-row flex-column">
        <div><a (click)="openPage('about')"  translate="">ABOUTUS</a></div>
        <div><a (click)="openPage('water-analysis')"  translate="">BOOKAWA</a></div>
        <div><a (click)="openPage('contact')"  translate="">CONTACT</a></div>
        <div><a href="https://drive.google.com/file/d/1S5Cd1ma_FMEVgzlyWMkhrCNkQMa2TEpk/view" translate="">EVGMANU</a></div>
        <div><a href="https://www.bbb.org/ca/bc/port-coquitlam/profile/water-purification-equipment/ev-green-inc-0037-1264160" translate="">BBUSINESSB</a></div>
        <div><a  (click)="openPage('privacy-policy')" translate="">EVPRIVACYP</a></div>
    </div>
    <div class="company-info">
        <p translate>COFFICE</p>
        <p class="mb-2"> <span class="mr-3" translate>EVGADDRESS</span> <span class="mr-3" translate>EVGPHONE</span> <span class="mr-3" translate>EVGEMAIL</span></p>
        <!--
        <p translate>CBOFFICE</p>
        <p translate>CBOFFICEDESC</p>
        -->
    </div>
    <div class="copyright">
        <p translate>EVGCOPYR</p>
    </div>
</div>
