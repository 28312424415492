<div class="card p-3">
    <div class="card-body text-center">
        <form  [formGroup]="inputForm" (keydown.enter)="$event.preventDefault()" autocomplete="ip-form">
        <mat-form-field style="width: 100%;" ngbAutofocus>
            <textarea matInput formControlName="fieldValue"  type="text" class="form-control text-box" id="fieldValue"
            name="fieldValue"  placeholder="{{ fieldName | translate }}"
            autocomplete="off" rows="5"
            ></textarea>
            <mat-error class="error-message" *ngIf="inputForm.get('fieldValue').hasError('required')">{{'FIELDREQ' | translate}}</mat-error>
        </mat-form-field>
        </form>
        <div class="d-flex justify-content-center">
          <button mat-raised-button color="primary" class="mr-2 orange-button" [disabled]="!inputForm.valid" (click)="activeModal.close(inputForm.get('fieldValue').value)">{{ "SUBMIT" | translate }}</button>
          <button mat-raised-button (click)="activeModal.close(false)">{{ "CANCELBTN" | translate }}</button>
        </div>
    </div>
</div>
