import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminMessage } from 'src/app/modules/common/CustomerSupport';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-post-edit-message-to-customer',
  templateUrl: './post-edit-message-to-customer.component.html',
  styleUrls: ['./post-edit-message-to-customer.component.scss']
})
export class PostEditMessageToCustomerComponent implements OnInit {

  public user: User;
  public adminMessages : AdminMessage[] = [];
  public adminMessage : AdminMessage;
  private _unsubscribe$ = new Subject<any>();
  public isCreatingMessage : boolean = false;
  public adminMessageForm : FormGroup;
  public toUserId : number;
  panelOpenState = false;
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
  ) { }

  onCreate(){
    this.isCreatingMessage = true;
  }

  onBack(){
    this.adminMessage = null;
    this.isCreatingMessage = false;
    this.getAdminMessages();
    this.openPage('');
  }

  onEdit(index){
    this.adminMessage = this.adminMessages[index];
    this.isCreatingMessage = false;
    this.openPage(this.adminMessage.id);
  }

  openPage(pId) {
    this.router.navigate(['backmain/admin/post-edit-message-to-customer/' + pId]);
  }

  ngOnInit(): void {
    this.adminMessageForm = this.formBuilder.group({
      topic: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });

    
    this.getUser();
    this.getAdminMessages();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getAdminMessages()
  {
    this.evgService.getAdminMessages({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.adminMessages = resp.data[0].results;
          this.route.params.subscribe((params) => {
            
            if(params.id) {
              var message = this.adminMessages.filter(message => {
                return message.id == params.id
              })

              if(message){
                this.adminMessage = message[0];
                this.adminMessageForm.patchValue({
                  topic : this.adminMessage.topic,
                  content : this.adminMessage.content,
               });
              };
            }
          });
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postAdminMessage()
  {
    //console.log(this.adminMessageForm.value);
    if(this.adminMessageForm.status === "VALID"){
      let data = this.adminMessageForm.value;
      data.toUser = this.toUserId;
      
      this.isProcessing1 = true;
      this.evgService.postAdminMessage(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          if(resp.data[0]){
            //console.log("postAdminMessage",resp.data[0]);
            this.onBack();
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  modifyAdminMessage()
  {
    if(this.adminMessageForm.status === "VALID"){
      let data = this.adminMessageForm.value;
      data.id = this.adminMessage.id;
      this.isProcessing2 = true;
      this.evgService.modifyAdminMessage(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          if(resp.data[0]){
            //console.log("modifyAdminMessage",resp.data[0]);
            this.onBack();
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
      
    }
    
   
  }

}
