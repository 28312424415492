<div class="product-setting-container">
    <section class ="feature-products">
        <div class="container">
            <div class="row">
                <div class="action-buttons">
                    <a class="btn-outline" (click)="addNewProduct()">{{ 'ADDBTN' | translate}}</a>
                    <!--
                    <label class="sort-label">{{ 'SORTBY' | translate}}</label>
                    <select class="form-control  cart-input" [(ngModel)]="sortType" value="{{sortType}}" name="sortType" (change)="changedSortBy($event)" >
                        <option value="0">{{ 'SORTPOPULAR' | translate}}</option>
                        <option value="1">{{ 'SORTNEWEST' | translate}}</option>
                        <option value="2">{{ 'SORTOLDER' | translate}}</option>
                        <option value="3">{{ 'SORTPRICE' | translate}}</option>
                        <option value="4">{{ 'SORTPRICE2' | translate}}</option>
                        <option value="5">{{ 'SORTNAME' | translate}}</option>
                    </select>
                    -->

                    <!--
                    <mat-radio-group
                        aria-labelledby="address-radio-group-label"
                        class="address-radio-group"
                        [(ngModel)]="selectedShowCat"
                        (ngModelChange)="selectCategory()"
                        [ngModelOptions]="{standalone: true}">
                        <mat-radio-button color="primary" class="address-radio-button" [value]="0" >All Products</mat-radio-button>
                        <mat-radio-button color="primary" class="address-radio-button" [value]="1" >Products on Purchase page</mat-radio-button>
                        <mat-radio-button color="primary" class="address-radio-button" [value]="2" >Products on SHOP page</mat-radio-button>
                    </mat-radio-group>
                    -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-6" *ngFor="let product of products; index as i;">
                    <div class="product-grid">
                        <div class="product-image">
                            <a class="image" style="background-color:#F3F3F3;">
                                <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                                <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic">
                            </a>
                            <a class="add-to-cart" href=""> + </a>
                        </div>
                        <div class="product-content text-center">
                            <!-- <a class="title">{{ product.pTitle }}</a> -->
                            <div class="pTitle">
                                {{ product.pTitle }}
                            </div>
                            <div class="pOption">
                                <p>SKU:</p>&nbsp;{{ product.SKU}}
                            </div>
                            <!--
                            <div class="pOption">
                                <p>Item#:</p>&nbsp;{{ product.item_no}}
                            </div>
                            -->

                            <div class="m-2">
                                <span *ngIf="product.sales_name" [ngClass]="'sale-' + product.sales_desc_style">{{ product.sales_desc }}</span>
                            </div>
                             <div class="price">$ {{ product.pPrice }}</div>
                        </div>
                        <div class="action-buttons">
                            <a class="btn-outline" (click)="modifyProduct(product.pId)" title="{{'MODIFYBTN' | translate }}"><i class="bi bi-pen"></i></a> <!--{{ 'MODIFYBTN' | translate}}</a>-->
                            <a *ngIf="!product.invalid" class="btn-outline" (click)="deleteProduct(product.pId)" title="{{'DELETEBTN' | translate }}"><i class="bi bi-trash"></i></a>
                            <span *ngIf="product.invalid">{{ 'PRODUCTDELETED' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>           
    </section>
</div>