
<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="page-banner" >
        <div>
            <h1 translate>OURPRODUCT</h1>
        </div>
    </div>
    <div class="products-container">
        <div class="row">
            <!--
            <div class="col-lg-2">
                <div class="side-nav">
                    <h2 translate>PRODUCTS</h2>
                    <a (click)="openPage('products')" translate>PULTIMATE</a>
                    <a (click)="openPage('washer')" translate>PWASHER</a>
                    <a (click)="openPage('air-purifier')" translate>PPURIFIER</a>
                </div>
                
            </div>
            -->
            <div class="col-12">
                <div class="main">
                    <h1 translate class="p-5">TUUNADWSYS</h1>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic4.jpeg" alt="">
                        </div>
                        <p translate>TEVGUUVTDW</p>
                    </div>
                    <div class="p-3">
                        <iframe width="100%" style="min-height: 30rem" src="https://www.youtube.com/embed/5T7yIbZRFmU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <p translate>WTDOCOZ</p>
                    </div>
                    <div class="p-5">
                        <h2 translate>WATERREDE</h2>
                        <ul class="p-4">
                            <li><p translate>WRPMAPHWHC</p></li>
                            <li><p translate>ULPWASCLSE</p></li>
                            <li><p translate>TSWMCWOD</p></li>
                            <li><p translate>LFBNF</p></li>
                            <li><p translate>DICABITU</p></li>
                        </ul>
                    </div>
                    <hr>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic5.jpeg" alt="">
                        </div>
                        <p class="pb-5" translate>S5FSFCFFLD</p>
                        <h2 translate>PSEDIMENTFILTER</h2>
                        <p translate>PSEDIMENTFILTERDESC</p>
                    </div>
                    <hr>
                    <div>
                        <iframe class="pl-5 pr-5" width="100%" style="min-height: 40rem" src="https://www.youtube.com/embed/YYO-EfQWjOY?wmode=opaque&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <hr>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic6.jpeg" alt="">
                        </div>
                        <p class="pb-5" translate>PSEDIMENTMMF</p>
                        <h2 translate>PMICROMEMB</h2>
                        <p translate>PMICROMEMBDESC</p>
                    </div>
                    <hr>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic7.jpeg" alt="">
                        </div>
                        <p class="pb-5" translate>PPHFILTRATION</p>
                        <h2 translate>PPHMINERALFILTER</h2>
                        <p translate>PPHMINERALFILTERDESC</p>
                    </div>
                    <hr>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic8.jpeg" alt="">
                        </div>
                        <p class="pb-5" translate>PCMFILTRATION</p>
                        <h2 translate>PCARBONMEDIA</h2>
                        <p translate>PCARBONMEDIADESC</p>
                    </div>
                    <hr>
                    <div class="p-5">
                        <div class="text-center">
                            <img width="100%" src="./../../../../assets/images/files/products/products_pic9.jpeg" alt="">
                        </div>
                        <p class="pb-5" translate>PUVFILTRATION</p>
                        <h2 translate>PUVCC</h2>
                        <p translate>PUVCCDESC</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
