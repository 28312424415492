import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountRankDialogComponent } from 'src/app/modules/account-rank-dialog/account-rank-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-change-user-rank',
  templateUrl: './change-user-rank.component.html',
  styleUrls: ['./change-user-rank.component.scss']
})
export class ChangeUserRankComponent implements OnInit {

  public changeRankForm: FormGroup;
  public questions : Array<any>=[];
  public isProcessing = false;
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    public ngDialog : NgbModal
  ) { }

  ngOnInit(): void {
    this.changeRankForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
      setRank: ['', [Validators.required]],
      setFixed: ['', [Validators.required]],
      keepDays: ['', [Validators.required]],
    });

  }

  public updateUserRank(formDirective)
  {
    const modalRef = this.ngDialog.open(AccountRankDialogComponent, { windowClass: 'account-rank-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.userId = this.changeRankForm.get('userId').value;
    modalRef.result.then((data) => {
      if(data){
        this.isProcessing = true;
        this.evgService.updateUserRank( this.changeRankForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.sharedUtilService.resetForm(this.changeRankForm,formDirective);
          }
          this.isProcessing = false
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.isProcessing = false
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    }, (reason) => {
    });
  }

}
