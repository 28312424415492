<div class="container">
    <div class="page-banner" >
        <div>
            <h1 translate>DONATE</h1>
        </div>
    </div>

    <div class="donate-container">
        <div class="row">
            <div class="col-lg-2">
                
            </div>
            <div class="col-lg-8">
                <div class="main">
                    <div class="text-center p-5">
                        <h2 translate>DMAKEDIFF</h2>
                    </div>
                    <div class="text-center  p-5">
                        <div class="row">
                            <div class="col-sm-6">
                                <h2 translate>DDONATE</h2>
                                <p translate>DDONATEDESC</p>
                                <br>
                                <a href="https://www.charitywater.org/donate" class="btn yg-button-adj">{{'DDONATEBTN' | translate}}</a>
                            </div>
                            <div class="col-sm-6">
                                <h2 translate>DSPREAD</h2>
                                <p translate>DSPREADDESC</p>
                                <br>
                                <a href="https://www.charitywater.org/get-involved/fundraise" class="btn yg-button-adj">{{'DSPREADBTN' | translate}}</a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 text-center">
                                <img width="100%" src="./../../../../assets/images/files/donate/donate_pict2.jpeg" alt="">
                            </div>
                            <div class="col-sm-6 text-center">
                                <div class="centered-container">
                                    <div class="centered-text">
                                        <p class="align-middle" translate>DEVSUPPORT</p>
                                        <p class="align-middle" translate>DEVSUPPORTDESC</p>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-2">
    
            </div>
        </div>
    </div>
   
</div>