import { ChangeDetectorRef, Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isByteCard : boolean=false;
  @Input() showSubscribe : boolean=true;
  

  public subscribeForm: FormGroup;
  public isLoading = false;
  public isPrinting = false;
  public currentRoute = '';
  private _unsubscribe$ = new Subject<any>();
  public skipSubscribePages=['/pet-c60','/carbon60','/pet-trace','/hydrating-serum','/firming-cream','/wrinkle-serum','/night-cream','/tinted-pore','/restorative','/fulvic-mucuna-pruriens'];

  constructor(public translate : TranslateConfigService, public router : Router, private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,private responseHandlerService : ResponseHandlerService, public cdr : ChangeDetectorRef,
    private evgService : EvgApiService,private sharedUtilService : SharedUtilService) {
      console.log('FooterComponent isByteCard',this.isByteCard);
    }

  ngOnInit(): void {
    this.subscribeForm = this.formBuilder.group({
      name: ['',[Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      phone: ['',[Validators.required]],
      message: ['',[Validators.required]],
    });

    this.shareinfoService.currentRoute.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.currentRoute = successResponse;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    this.getPrintingStatus();
  }

  getPrintingStatus()
  {
    this.shareinfoService.isPrinting
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.isPrinting = successResponse;
              this.cdr.detectChanges();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

  subscribe()
  {
    if(this.subscribeForm.valid){
        
    }
  }

  postSubscribe(subscribeDirective)
  {
    if(this.subscribeForm.valid){
      this.evgService.postSubscribe( this.subscribeForm.value).subscribe(resp => {
        this.responseHandlerService.handleResponse(resp);
        this.sharedUtilService.resetForm(this.subscribeForm,subscribeDirective);
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }

}
