export class Cart {
    referral : number;
    recommend :number;
    pType : number = 0;
    amount : number = 0;
    taxes : number = 0;
    discount : number = 0;
    products : Product[] = [];
}

export class Product {
    pId : number;
    nums : number;
    price : number;
}