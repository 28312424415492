<div class="purchase-container">
    <section class ="feature-products">
        <div class="container">
            <div class="row col-md-12 ml-1" *ngFor="let product of products; index as i;">
                        <div *ngIf="!product.invalid" class="d-flex justify-content-center" style="width:100%;">
                            <div class="row product-grid" [ngClass]="{'selected-grid': product.pQty>0 }">
                                
                                <div class="col-6 product-image mb-3">
                                    <a class="image" style="background-color:#F3F3F3;">
                                        <img *ngIf="!product.pPic" class="pic-1" src="./../../../../../assets/images/logo/no-image.jpg">
                                        <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic">
                                    </a>
                                    
                                    <!-- <a class="add-to-cart" href=""> + </a> -->
                                </div>
                                <div class="col-6 product-content text-center">
                                    <a class="title" title="{{ product.pContent }}">{{ product.pTitle }}</a>
    <!--Added by Thomas-->                                
                                    <div >
                                        <span *ngIf="product.pTitle.length<20" ><br></span>
                                    </div>
       
                                    <div class="m-2">
                                        <span *ngIf="product.sales_name" [ngClass]="'sale-' + product.sales_desc_style">{{ product.sales_desc }}</span>
                                    </div>
                                    
                                    <div class="price">{{ product.pPrice | currency:''}}&nbsp;{{ 'CURRENCYUNIT' | translate }}</div>
                                   <!--  
                                    <mat-star-rating *ngIf="product.reviews" [rating]="product.reviews.Score" (click)="showCustomerReviews(product.reviews)"></mat-star-rating>
                                    <span *ngIf="!product.reviews" translate>NORATINGSAVAIL</span>
                                    <br>
                                    -->
                                    <br>
                                     <!-- <div class="install-price">{{ 'INSTALLFEE' | translate }} : {{ product.installFree | currency:'' }}</div> -->
                                     <div class="form-group text-center">
                                        <input [(ngModel)]="product.pQty" name="quantity" [value]="product.pQty" (change)="qtyChange($event,i)" class="form-control text-center cart-input" type="number" placeholder="Quantity" [ngClass]="{'selected-prod': product.pQty>0 }" >
                                     </div>
                                    
                                     <a class="add-review" (click)="viewProductDetail(product.page_name)" *ngIf="product.page_name">{{ 'VIEWMOREDETAIL' | translate}}</a>
                                </div>
                                    <!--
                                <div class="action-buttons">
                                    <a class="btn-outline" (click)="onAddToCart(i)">{{ 'ADDTOCARTBTN' | translate}}</a>
                                </div>
                                -->
                            </div>
                    </div>
            </div>
            <div class="flex justify-content-center">Total Amount(USD) :${{totalAmount}}
            </div>
            <div class="d-flex justify-content-center">
                <button mat-raised-button color="primary" class="mr-1 orange-button " (click)="activeModal.close(false)">{{ 'CANCELBTN' | translate}}</button>
                <button mat-raised-button color="primary" class="ml-1 orange-button" (click)="activeModal.close(products)">{{ 'ADDTOCARTBTN' | translate}}</button>
            </div>
        </div>
    </section>
</div>