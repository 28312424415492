import { PercentPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute} from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AlertDialogComponent } from 'src/app/modules/alert-dialog/alert-dialog.component';
import { CartItem } from 'src/app/modules/common/CartItem';
import { User } from 'src/app/modules/common/User';
import { Cart, Product } from 'src/app/modules/common/Cart';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { DistributorTermsComponent } from 'src/app/modules/distributor-terms/distributor-terms.component';
import { VerifyDialogComponent } from 'src/app/modules/verify-dialog/verify-dialog.component';
import { SelectProductsComponent } from 'src/app/components/pages/byte-cart/select-products/select-products.component';
import { Products,ProductInfo } from 'src/app/modules/common/Products';
import { element } from 'protractor';
import { LoginComponent } from '../login/login.component';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['./cart-view.component.scss']
})
export class CartViewComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  //@Input() selectedCreditCard : number = -1;
  @Input() cart_items : CartItem[] = [];

  public selectedProducts : Array<any>=[];
  public recommanded:any=null;
  public offered_pId:any=null;
  public user : User = new User();
  public resetLinkSent : boolean = false;
  public checkoutForm : FormGroup;
  public distrib_form : FormGroup;
  public step : number = 0;
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  //public cart_items : CartItem[] = [];
  public cart_total : number = 0;
  public cart_tax : number = 0;
  public cart_tax_1 : number = 0;
  public cart_tax_2 : number = 0;
  public cart_tax_rate : any = null;
  public cart_subtotal : number = 0;
  public cart_discounts : number = 0;
  public cart_item_selected : number = 0;
  public cart_discount_rate : number =  0;
  public tax_rates : any = [];
  public tax_rate : any = [];
  private _unsubscribe$ = new Subject<any>();
  public selectedCountry : {
    name : '',
    code : ''
  };
  public isMobileMenuOpen:boolean  = false;
  public preferredShipping : number = 1;//new address
  public shippingAddresses : any[] = [];
  public selectedAddress : number = -1;
  public coupon = '';
  public appliedCoupon : any;
  public verified = false;
  public myStoreInfo:any={};
  public storeAD_size=0;
  public background_color : any = "#FFFFFF";
  public setHeaderImg =null;
  public show_header_type =2;
  public slideIndex =0;
  public enteredEmail=0;

  public common_discount:number=0.0;
  public common_discount_rate:number=0.0;
  public autoship_discount:number=0.0;
  public autoship_discount_rate:number=0.0;
  //public termsForAutoship:Array<any>=[];
  public termsForAutoship:any=[];
  /*
  public picFiles=['/assets/public/cart_waterfilter_image.jpg',
                  '/assets/public/cart_airfilter_image.jpg',
                  '/assets/public/cart_washer_image.jpg',
                  '/assets/public/cart_waterfilter_image.jpg',
                ];
                */
  public picFiles=[ '/assets/public/byte_cart_s1.png',
                    '/assets/public/byte_cart_s2.png',
                    '/assets/public/byte_cart_s3.png',
                  ];

  public slidesCount = this.picFiles.length;
  public slide_picture =null;
  public slideShow:Array<boolean>=[true,false,false,true];
  public slide_interval_time:number=4000;
  public pre_ofer_products:number=0;
  public select_product_title = 'SELECTPRODCT';
  validRegexForEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


  constructor(
    //public translate : TranslateConfigService,
    public activeModal : NgbActiveModal, 
    private router: Router,
    public route: ActivatedRoute,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private percentPipe : PercentPipe,
    public eventService: EventService
  ) { 
    this.recommanded = (this.route.snapshot.paramMap.get('recommanded'));
    this.offered_pId = (this.route.snapshot.paramMap.get('pId'));
    //console.log('at ByteCartComponent parameters (recommanded): pId',this.recommanded, this.offered_pId);
  }

  ngOnInit(): void {
    this.initializeForms();
    this.getCart();
  }

  initializeForms(){
    this.checkoutForm = this.formBuilder.group({
      Tel : [''],
      Address : [''],
      City : [''],
      Referrals : [''],
      Recommended : [''],
    });

    this.distrib_form = this.formBuilder.group({
      isDistributor : ['']
    });

  }

  getOferProducts() {
    this.evgService.getOfferedProducts({MemberNo : this.recommanded}).subscribe( resp => {
      if (resp) {
        let oferedProd = resp.data[0].results;
        if ( oferedProd && oferedProd.length()>0) {
          this.pre_ofer_products= oferedProd.length();
          this.getCart();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
    });

  }

  applyCoupon(){
    if(this.coupon){
      this.evgService.submitCoupon({coupon : this.coupon,category: 1}).subscribe( resp => {
        if (resp) {
          //console.log('applyCoupon resp:', resp);
          this.responseHandlerService.handleResponse(resp);
          this.appliedCoupon = resp.data[0].results;
          this.getCart();

          this.cdref.detectChanges();
  
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
      });
    }
  }

  getShowClass(indexId) {
    let setVal = false;
    if ( indexId >= 0 && indexId < this.slideShow.length ) {
      setVal = this.slideShow[indexId];
    }
    let retClass = {
      'show' : setVal,
      'hide' : !setVal,
    }
    return retClass
  }

  getDiscountAmt() {
    if (this.appliedCoupon.category==1 && this.appliedCoupon.detail) {
      let discount=0;
      this.appliedCoupon.detail.forEach((element,i)=>{
        let cartList = this.cart_items.filter(item => item.pId == element.MemberNo && item.selected);
        if (cartList && cartList.length>0) {
          cartList.forEach((e,index)=>{
            if (element.coupon_type==0) {
              discount += e.nums * element.amount;
            } else {
              discount += (e.nums * e.unitPrice) * element.amount/100;
            }
          });
        }
      });
      this.cart_discounts = discount;
      console.log('getDiscountAmt discount:',discount);
    }

  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.checkoutForm.patchValue({
                Referrals : this.user.Referrals,
                Recommended : this.user.Recommended,
                name : this.user.first_name + " " + this.user.last_name,
                email : this.user.email,
                firstName : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                address : this.user.Address
              });

              if(!this.user.isDistributor){
                this.checkoutForm.controls['Referrals'].disable();
                this.checkoutForm.controls['Recommended'].disable();
              }else{
                this.checkoutForm.controls['Referrals'].enable();
                this.checkoutForm.controls['Recommended'].enable();

                if(this.user.Referrals){
                  this.checkoutForm.controls['Referrals'].disable();
                }

                if(this.user.Recommended){
                  this.checkoutForm.controls['Recommended'].disable();
                }
                
                if(this.user.Recommended && this.user.Referrals){
                  this.verified = true;
                }
              }

              // if(this.user.pTimes > 0 && (this.user.Referrals != null || this.user.Recommended != null)){
              //   this.step = 1;
              // }

              this.cdref.detectChanges();

          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  openPage(pageId) {
    //this.activeModal.close('Close click');
    this.router.navigate([pageId]);
  }

  distrib_click(){
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.distributor = this.user.first_name + ' ' + this.user.last_name;

    modalRef.result.then((data) => {
    }, (reason) => {
        this.evgService.updateUserDistributorFlag({isDistributor : 1}).subscribe( resp => {
          this.responseHandlerService.handleResponse(resp);
          this.shareinfoService.reloadUser();
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    });
  }

  get_data_interval(){
    return this.slide_interval_time;
  }

  onSelected(i,event){
    this.cart_items[i].selected = this.cart_items[i].selected == 1 ? 0 : 1;
    this.getCart();
  }
  
  deleteItem(index){
    if (this.cart_items.length>index) {
      // this.cart_items.slice(index,1);
      let cartItems: CartItem[]=[];
      let temp_index=0;
      let del_pId = null;
      this.cart_items.forEach( (element,i) => {
        if (index !== i ) {
            cartItems.push(element);
        } else {
          del_pId = element.pId;
        }
        temp_index++;
      });
      this.cart_items = cartItems;
      let selected=0;
      //console.log('deleteItem index: del_pId:  cartItems :',index,del_pId, cartItems);
      if (del_pId) {
        //this.selectedProducts = this.selectedProducts.filter();
        //this.selectedProducts = this.selectedProducts.filter(item => item.pId !== del_pId);
        this.selectedProducts.forEach((element, i) => {
          if (element.pId == del_pId) {
            element.pQty =0;
          }
          if (element.pQty >0 ) {
            selected++;
          }
        });
        this.select_product_title = (selected > 10 ? 'MOREPRODUCTS' : 'SELECTPRODCT');
      }
      this.getCart();
    }

  }

  addItem(index){
    
    this.cart_items[index].nums++
    this.getCart();

  }

  removeItem(index){
    if(this.cart_items[index].nums >= 2){
      this.cart_items[index].nums--;
      this.getCart();
    }
  }

  clearCart(){
    this.cart_items=[];
    this.selectedProducts.forEach((element, i) => {
        element.pQty =0;
    });
    this.appliedCoupon = null;
    this.cart_discounts = 0;
    this.cart_discount_rate = 0;
    this.step=0;
    this.getCart();
  }


  getCart(){
    this.cart_subtotal = 0;
    this.cart_tax = 0;
    this.cart_tax_1 = 0;
    this.cart_tax_2 = 0;
    this.cart_total = 0;
    this.cart_item_selected = 0;
    this.cart_discount_rate = 0;
    this.cart_items.forEach(cart_item => {
        if(cart_item.selected == 1){
          this.cart_subtotal += cart_item.unitPrice * cart_item.nums;
          this.cart_total += cart_item.amount;
          this.cart_item_selected++;
        }
    });
    
    if(this.appliedCoupon){
      if (this.appliedCoupon.category==0 ){
        if(this.appliedCoupon.coupon_type == '0'){
          this.cart_discounts = this.appliedCoupon.coupon_amt;

          this.cart_discount_rate = this.cart_discounts / this.cart_subtotal;
          
        }else if(this.appliedCoupon.coupon_type == '1'){
          this.cart_discounts = (this.cart_subtotal * (this.appliedCoupon.coupon_amt/100));

          this.cart_discount_rate = this.appliedCoupon.coupon_amt/100;
        }else{
          this.cart_discounts = 0;
          this.cart_discount_rate = 0;
        }
      } else {
        this.getDiscountAmt();
        this.cart_discount_rate = 0;
      }
    }else{
      this.appliedCoupon = null;
      this.cart_discounts = 0;
      this.cart_discount_rate = 0;
    }

    this.cart_items.forEach(cart_item => {
        if(cart_item.selected == 1){
          this.cart_tax += this.calculateProductTax(cart_item);
          this.cart_tax_1 += this.calculateProductTax1(cart_item);
          this.cart_tax_2 += this.calculateProductTax2(cart_item);
          
        }
    });

    this.cart_total = this.cart_subtotal - this.cart_discounts + this.cart_tax;
    this.eventService.emit({cart_items:this.cart_items});
     
    this.cdref.detectChanges();

  }


  calculateProductTaxold(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return (cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return (cart_item.unitPrice * (this.cart_tax_rate.tax_rate2 ? this.cart_tax_rate.tax_rate2 : this.cart_tax_rate.tax_rate))  * cart_item.nums * (1 - this.cart_discount_rate);
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.unitPrice * this.cart_tax_rate.tax_rate) + (cart_item.unitPrice * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate) + (cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return  (cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return  (cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax1(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return 0;
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }

  calculateProductTax2(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return 0;
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }
  renderTaxName(cart_item)
  { 
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return this.cart_tax_rate.tax_name + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + ")";
      }else if(cart_item.tax_type == 2 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name2 + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : this.translate.translate.instant("NOTAX");
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }
    }
    return this.translate.translate.instant('PROCEEDBILLING');
  }
  
  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  changeTermFlagEvent(event,index) {

    //console.log("changeTermFlag selValue,index:", selValue,index);
    //this.cart_items[index].termFlag = selValue;
    this.cart_items[index].termFlag = (event.checked===true) ? 1:0;
    let data ={AwardId:"autoship", AwardValue:this.cart_items[index].pId};
    this.getCart();
    if (event.checked===true) {
      this.evgService.getAwardOptionsMessage(data).subscribe( resp => {
        if (resp) {
          //this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  applyBenefit_click(event,index){
    //this.cart_items[index].apply_benefit = (event.target.checked ? 1:0);
    this.cart_items[index].apply_benefit = (event.checked === true)? 1:0;
    this.getCart();
    
    if (event.checked === true) {
      let data ={AwardId:"firstbuy", AwardValue:''}
      this.evgService.getAwardOptionsMessage(data).subscribe( resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  finishSteps()
  {
      localStorage.setItem("myByteCart",JSON.stringify(this.cart_items));

      const modalRef = this.ngDialog.open(LoginComponent, { windowClass: 'activate-account-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.result.then((result) => {
        this.activeModal.close(true);

      }) 
  }

  closeView(){
    this.activeModal.close(false);
  }
}
