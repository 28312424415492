import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestorativeComponent } from './restorative.component'
import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: '', component: RestorativeComponent }
];

@NgModule({
  declarations: [],
  imports: [CommonModule,RouterModule.forChild(routes)],
  //exports: [RouterModule]
})
export class RestorativeRoutingModule { }
