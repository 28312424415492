import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-verify-balance-detail',
  templateUrl: './verify-balance-detail.component.html',
  styleUrls: ['./verify-balance-detail.component.scss']
})
export class VerifyBalanceDetailComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() history_id=null;
  @Input() userId=null;
  @Input() balanceDetail=null;
  public detailInfo=null;

  constructor(
    public activeModal : NgbActiveModal,
    private modalService: NgbModal,
    public router : Router,
    public ngDialog : NgbModal,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    if (this.balanceDetail) {
      this.detailInfo = (this.balanceDetail);
      this.detailInfo.forEach(element => {
        element.detail_info = JSON.parse(element.detail_info);
        
      });
      console.log('ngOnInit detailInfo:',this.detailInfo);
    }

  }

}
