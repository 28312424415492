<div class="ewallet-overview-container">
    
    <div class="row">
      <div class="col-sm-3">
        <div class="card balance">
          <div class="card-body">
            <h5 class="card-title">{{ 'EWALLETBALANCE' | translate }}</h5>
            <h1 class="ewallet-balance">{{ user.eWallet | currency }}</h1>
            <!-- <div class="d-flex justify-content-around align-items-center">
                <div>
                  <button class="btn orange-button">{{'HISTORY' | translate}}</button>
                </div>
                <div>
                  <h5>{{ 'CURRENTBALANCE' | translate }}</h5>
                  <h5 class="current-balance">{{ user.totalCash | currency }}</h5>
                </div>
            </div> -->
  
          </div>
        </div>
      </div>
      <div class="col-sm-4">
          <div class="card">
              
              <div class="card-body d-flex flex-column">
                  <h5 class="card-title">{{ 'PURCHASEBYTETOKEN' | translate }}</h5>
                  <form #purchaseTokenF="ngForm" [formGroup]="purchaseTokenForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                      
                      <div class="row">
                          <div class="col-sm-12">
                              <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount" 
                                  name="amount" placeholder="{{ 'AMOUNTUSD' | translate }}"
                                  autocomplete="off"
                                  >
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                              </mat-form-field>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-sm-12">
                              <mat-form-field style="width: 100%;">
                                  <mat-label>{{'CARDTYPE' | translate}}</mat-label>
                                  <mat-select formControlName="cardType"  type="text" class="form-control" id="cardType" aria-describedby="autoPay"
                                  autocomplete="off">
                                      <mat-option  [value]="0">{{ 'VISA' | translate }}</mat-option>
                                      <mat-option  [value]="1">{{ 'MASTERCARD' | translate }}</mat-option>
                                      <mat-option  [value]="2">{{ 'AMERICANEXPRESS' | translate }}</mat-option>
                                  </mat-select>
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardType').hasError('required')">{{'CARDTYPEREQ' | translate}}</mat-error>
                              </mat-form-field>
                              <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="firstName" type="text" class="form-control" id="firstName" aria-describedby="firstName"
                                  placeholder="{{ 'FIRSTNAME' | translate }}" 
                                  autocomplete="off">
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('firstName').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                              </mat-form-field>
                              <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="lastName" type="text" class="form-control" id="lastName" aria-describedby="lastName"
                                  placeholder="{{ 'LASTNAME' | translate }}" 
                                  autocomplete="off">
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('lastName').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                              </mat-form-field>
                              <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="postCode" type="text" class="form-control" id="postCode" aria-describedby="postCode"
                                  placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                  autocomplete="off">
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('postCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                              </mat-form-field> <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="address" type="text" class="form-control" id="address" aria-describedby="address"
                                  placeholder="{{ 'ADDRESSLABEL' | translate }}" 
                                  autocomplete="off">
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                              </mat-form-field>
                              <mat-form-field style="width: 100%;">
                                  <input matInput formControlName="cardNo" type="text"  pattern="[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}" class="form-control" id="cardNo" aria-describedby="cardNo"
                                  placeholder="{{ 'CCNUMBER' | translate }}"
                                  (keyup)="cc_format($event)"
                                  [(ngModel)]="creditCardNumber"
                                  autocomplete="off">
                                  <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardNo').hasError('required')" >{{ "CCNUMBERREQ" | translate }} </mat-error>
                              </mat-form-field>
                              <div class="row">
                                  <div class="col-sm-7">
                                      <mat-form-field style="width: 100%;">
                                        <!--
                                          <input matInput formControlName="expireDate" type="month" pattern="[0-9]{4}-[0-9]{2}" class="form-control" id="expireDate" aria-describedby="expireDate"
                                          placeholder="{{ 'CCEXP' | translate }}" 
                                          autocomplete="off">
                                          <mat-error class="error-message" *ngIf="purchaseTokenForm.get('expireDate').hasError('required')" >{{ "CCEXPREQ" | translate }} </mat-error>
                                        -->
                                          <input matInput formControlName="expireDate" type="text" pattern="[0-9]{2}\/[0-9]{2}" class="form-control" id="expireDate" aria-describedby="expireDate"
                                          placeholder="{{ 'CCEXP' | translate }} (MM/YY)"
                                          (keydown)="onKeydown($event)"
                                          (keyup)="modifyInput($event)"
                                          [(ngModel)]="expiryDateValue"
                                            maxlength="5"
                                          autocomplete="off">
                                          <mat-error class="error-message" *ngIf="purchaseTokenForm.get('expireDate').hasError('required')" >{{ "CCEXPREQ" | translate }} </mat-error>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-sm-5">
                                      <mat-form-field style="width: 100%;">
                                          <input matInput formControlName="cvCode" type="number" class="form-control" id="cvCode" aria-describedby="cvCode"
                                          placeholder="{{ 'CCCVC' | translate }}" 
                                          autocomplete="off">
                                          <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cvCode').hasError('required')" >{{ "CCCVCREQ" | translate }} </mat-error>
                                      </mat-form-field>
                                  </div>
                              </div>
                          </div>
                      </div>

                  
                      <div class="col-sm-12 col-xs-12 form-popup-form">
                          <div style="text-align: center; margin: auto;">
                              <button class="btn orange-button" [disabled]="!purchaseTokenForm.valid " (click)="purchaseToken(purchaseTokenF)" >{{ "PURCHASE" | translate}}</button>
                          </div>
                      </div>
                      
                    
                    </form>
              </div>
          </div>
      </div>
      <div class="col-sm-5">
        <div class="card transactions">
          <div class="card-body">
            <h5 class="card-title text-uppercase">{{'TRANSACTIONS' | translate}}</h5>
            <div class="table-responsive">
              <table class="table table-borderless table-sm">
                <tbody>
                  <tr *ngFor="let transaction of transactions; index as i;" [ngClass]="transaction.canceled > 0 ? 'canceled-record' : ''">>
                    <td class="transaction-title" >{{ transaction.comment }}</td>
                    <td class="transaction-amount">{{ transaction.amount | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="d-flex justify-content-center">
              <button class="btn orange-button">{{'EXPORT' | translate}}</button>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-3">
        <div class="card transfer-distrib">
          <div class="card-body">
            <h5 class="card-title text-uppercase">{{ 'TRANSFERTODISTRIB' | translate }}</h5>
            <form #transferDistrib="ngForm" [formGroup]="transferDistributorFrom"  autocomplete="td-form" (ngSubmit)="transferToDistributor(transferDistrib)" validate>
              <div class="form-group">
                <label for="from">{{ 'FROM' | translate }}</label>
                <select class="form-control from-select">
                  <option value="1">{{ 'CURRENTBALANCE' | translate }} {{ user.totalCash | currency  }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="toUserId">{{ 'TOUSERID' | translate }}</label>
                <input formControlName="toUserId"  type="text" class="form-control" id="toUserIdNoAutoFill" name="toUserIdNoAutoFill" autocomplete="toUserIdNoAutoFill"
                                  >
                 <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','toUserId','required')">{{'TOUSERIDREQ' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="stringId">{{ 'IDSTRINGCODE' | translate }}</label><br>
                
                <input formControlName="stringId"  type="text" class="form-control text-box" id="stringId" 
                name="stringId"
                autocomplete="off"
                >
                <mat-hint style="font-size: 0.8rem; line-height: 0.2rem;">{{ 'IDSTRINGCODEHINT' | translate }}</mat-hint>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','stringId','required')">{{'IDSTRINGCODEREQ' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="amount">{{ 'AMOUNT' | translate }}</label>
                <input formControlName="amount"  type="number" class="form-control text-box" id="amount" name="amount" autocomplete="off">
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','amount','required')">{{'AMOUNTREQ' | translate}}</mat-error>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','amount','min')">{{'AMOUNTMIN50' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="amount">{{ 'EMAIL' | translate }}</label>
                <input formControlName="email"  type="text" class="form-control text-box" id="email" name="email" autocomplete="off"
                >
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','email','required')">{{'EMAILREQ' | translate}}</mat-error>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','email','email')">{{'EMAILISVALID' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="tradePassword">{{ 'TRADEPASSWORD' | translate }}</label><br>
                <input formControlName="tradePassword"  type="password" class="form-control text-box" id="questionNoAutoFill" name="questionNoAutoFill" 
                autocomplete="questionNoAutoFill"
                >
                <mat-hint style="font-size: 0.8rem; line-height: 0.2rem;">{{ 'TRADEPASSWORDHINT' | translate }}</mat-hint>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','tradePassword','required')">{{'TRADEPASSWORDREQ' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="question">{{ 'TRANSFERQUESTION' | translate }}</label><br>
                
                <input matInput  formControlName="question" type="text" class="form-control" id="question" name="question" maxlength="60" autocomplete="off" >
                <mat-hint style="font-size: 0.8rem; line-height: 0.2rem;">{{ 'TRANSFERQUESHINT' | translate }}</mat-hint>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','question','required')">{{'SECURITYQUESREQ' | translate}}</mat-error>
              </div>
              <div class="form-group">
                <label for="answer">{{'TRANSFERANSWER' | translate}}</label><br>
                
                <input matInput  formControlName="answer" type="text" class="form-control" id="answer"name="answer" maxlength="60" autocomplete="off" >
                <mat-hint style="font-size: 0.8rem; line-height: 0.2rem;">{{ 'TRANSFERANSHINT' | translate }}</mat-hint>
                <mat-error class="error-message" *ngIf="isInvalidField('transferDistributorFrom','answer','required')">{{'SECURITYANSREQ' | translate}}</mat-error>
              </div>
              <div class="d-flex justify-content-center">
                <button type="submit" class="btn orange-button">TRANSFER</button>
              </div>
            </form>
           
          </div>
        </div>
      </div> -->
     
      <!-- <div class="col-sm-3">
        <div class="card balance-cashout">
          <div class="card-body">
            <h5 class="card-title">BALANCE CASHOUT</h5>
            <form action="">
              <div class="form-group">
                <label for="from">{{ 'FROM' | translate }}</label>
                <select class="form-control from-select">
                  <option value="0">{{ 'EWALLETBALANCE' | translate }} $1000.00</option>
                </select>
              </div>
              <div class="form-group">
                <label for="to">{{ 'TO' | translate }}</label>
                <select class="form-control to-select">
                  <option value="0">1800 1111 0061</option>
                </select>
              </div>
              <div class="form-group">
                <label for="amount">{{ 'AMOUNT' | translate }}</label>
                <input type="number" class="form-control" id="balanceAmount" >
              </div>
              <div class="d-flex justify-content-center">
                <button class="btn orange-button">CASHOUT</button>
              </div>
            </form>
          </div>
        </div>
      </div> -->
    </div>
  </div>