import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-personally-sponsored-list',
  templateUrl: './personally-sponsored-list.component.html',
  styleUrls: ['./personally-sponsored-list.component.scss']
})
export class PersonallySponsoredListComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public user : User = null;
  public sponsoredList = [];
  public sortedSponsoredList = [];


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private shareinfoService : ShareinfoService,
    private cdr : ChangeDetectorRef,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getTeamData();
  }


  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getTeamData()
  {

    this.evgService.getSponsers([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0].teamtree){
            this.sponsoredList = resp.data[0].teamtree;

            //console.log(this.sponsoredList);
            if(this.sponsoredList.length > 0){
              let result = this.sponsoredList.filter(data => data.id != this.user.id);
              // this.sponsoredList = result.filter(data => data.Referrals == this.user.id);
              this.sponsoredList = this.mapData(this.sponsoredList);
              this.sortedSponsoredList = this.sponsoredList.slice();
            }else{
              this.sponsoredList = [];
            }


          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  mapData(data)
  {
    let mappedData = [];


    data.forEach(element => {
      let node = { 
        id : element.id,
        parentid : element.Recommended,
        name : element.Sponsor_firstName + ' ' + element.Sponsor_lastName,
        cssClass : this.sharedUtilService.getCssClass(element.Sponsor_uLevel),
        rank : this.sharedUtilService.getRankByULevel(element.Sponsor_uLevel),
        image : 'assets/images/icons/user.png',
        title: element.id + ' - uLevel : ' + element.Sponsor_uLevel,
      };

      mappedData.push(node);
    });
    return mappedData;
  }

  sortData(sort: Sort) {
    const data = this.sortedSponsoredList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedSponsoredList = data;
      return;
    }

    this.sortedSponsoredList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.sharedUtilService.compare(a.id, b.id, isAsc);
        case 'name': return this.sharedUtilService.compare(a.name, b.name, isAsc);
        case 'rank': return this.sharedUtilService.compare(a.rank, b.rank, isAsc);
        case 'parentid': return this.sharedUtilService.compare(a.parentid, b.parentid, isAsc);
        default: return 0;
      }
    });
  }


}
