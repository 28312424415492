import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss']
})
export class CustomerServiceComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public requestForm : FormGroup;
  public groupIDs : string[] = [];
  public cancelIDs : any[] = [];
  constructor(
    public translate : TranslateConfigService,
    private evgService: EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private sharedUtilService : SharedUtilService
  ) { }


  ngOnInit(): void {
    this.requestForm = this.formBuilder.group({
      topic: ['', [Validators.required]],
      content: ['', [Validators.required]],
      requestType: ['', [Validators.required]],
      groupId: ['', []],
      id : ['',[]]
      
    });

    this.requestForm.get('requestType').valueChanges
    .subscribe(value => {
      if(value != 0 ) {
        if(value == 4){
          this.requestForm.get('id').setValidators(Validators.required);
          this.requestForm.markAsPristine();
          this.onCancelByteToken();
        }else{
          this.requestForm.get('groupId').setValidators(Validators.required);
          this.requestForm.markAsPristine();
  
          this.findNewOrderGroups();
        }
       
      } else {
        this.requestForm.get('id').clearValidators();
        this.requestForm.get('groupId').clearValidators();
      }
      this.requestForm.controls['id'].updateValueAndValidity();
      this.requestForm.controls['groupId'].updateValueAndValidity();
    }
);
  }

  onCancelByteToken()
  {
    this.evgService.getByteTokenForCancel({})
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.cancelIDs = resp.data[0].results;

          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  onSendRequest(formDirective){
    if(this.requestForm.status == "VALID"){
      this.evgService.postAskQuestion(this.requestForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.sharedUtilService.resetForm(this.requestForm,formDirective);
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      
    }
  }

  findNewOrderGroups(){
    this.evgService.findNewOrderGroups({})
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {

            let orders = resp.data[0].results;
            orders.forEach(order => {
            if ( order.cancelFlag == 0 ) {      //Thomas
                  this.groupIDs.push(order.groupID);                  
              }                                 //Thomas
            });
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  

}
