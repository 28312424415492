import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { local } from 'd3';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { VerifyDialogComponent } from '../verify-dialog/verify-dialog.component';
import { ForgotEwalletPasswordComponent } from '../forgot-ewallet-password/forgot-ewallet-password.component';

@Component({
  selector: 'app-ewallet-login',
  templateUrl: './ewallet-login.component.html',
  styleUrls: ['./ewallet-login.component.scss']
})
export class EWalletLoginComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  public user : User;
  public loginForm: FormGroup;
  public resetLinkSent : boolean = false;
  private _unsubscribe$ = new Subject<any>();
  fieldTextType: boolean=false;

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public formatter: NgbDateParserFormatter,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
  ) { }


  ngOnInit(): void {

    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ewalletSignIn() {

    let data ={
      email: this.user.email,
      password: this.loginForm.value.password
    };

    this.evgService.ewalletLogin( data ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        if(resp.success){
          this.shareinfoService.setEWallet(this.user);
          this.activeModal.close('success');
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  openEwalletForgot()
  {
    const modalRef = this.ngDialog.open(ForgotEwalletPasswordComponent, { windowClass: 'forgot-ewallet-password-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
