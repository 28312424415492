<div class="publish-additional-bonus-history-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'PUBLISHADDBONHIS' | translate}}</h3>
              
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'YEARMONTH' | translate}}</th>
                          <th scope="col">{{'OPDESC' | translate}}</th>
                          <th scope="col">{{'OPTYPE' | translate}}</th>
                          <th scope="col">{{'OPUSER' | translate}}</th>
                          <th scope="col">{{'BONUSAMOUNT' | translate}}</th>
                          <th scope="col">{{'SALESAMOUNT' | translate}}</th>
                          <th scope="col">{{'TOUSERID' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let bonusHistory of bonusHistories; index as i;">
                          <td>{{ bonusHistory.forYYYYMM}}</td>
                          <td>{{ bonusHistory.op_desc }}</td>
                          <td>{{ bonusHistory.op_type }}</td>
                          <td>{{ bonusHistory.op_user }}</td>
                          <td>{{ bonusHistory.bonusAmt | currency:'' }}</td>
                          <td>{{ bonusHistory.salesAmt | currency:'' }}</td>
                          <td>{{ bonusHistory.to_user }}</td>
                          <td>{{ bonusHistory.AddTime }}</td>
                        </tr>
                      </tbody>
                    </table>

              </div>
            </div>
          </div>
        </div>
    </div>
</div>