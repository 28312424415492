import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulletinBoard } from 'src/app/modules/common/BulletinBoard';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-post-edit-bulletin-board',
  templateUrl: './post-edit-bulletin-board.component.html',
  styleUrls: ['./post-edit-bulletin-board.component.scss']
})
export class PostEditBulletinBoardComponent implements OnInit {

  public user: User;
  public bulletinBoards : BulletinBoard[] = [];
  public bulletinBoard : BulletinBoard;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public bulletinBoardForm : FormGroup;
  panelOpenState = false;
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
    private sharedUtilService : SharedUtilService
  ) { }

  onCreate(){
    this.isCreating = true;
  }

  onBack(){
    this.bulletinBoard = null;
    this.isCreating = false;
    this.getBulletinBoards();
    this.openPage('');
  }

  onEdit(index){
    this.bulletinBoard = this.bulletinBoards[index];
    this.isCreating = false;
    this.openPage(this.bulletinBoard.id);
  }

  openPage(pId) {
    this.router.navigate(['backmain/admin/post-edit-bulletin-board/' + pId]);
  }

  ngOnInit(): void {
    this.bulletinBoardForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
      startDate : ['', [Validators.required]],
      days : ['', [Validators.required]],
      inactive : ['']
    });

    
    this.getUser();
    this.getBulletinBoards();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }


  getBulletinBoards()
  {
    this.evgService.getBulletinBoards({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.bulletinBoards = resp.data[0].results;
          //console.log(this.bulletinBoards);
          this.route.params.subscribe((params) => {
            
            if(params.id) {
              var board = this.bulletinBoards.filter(board => {
                return board.id == params.id
              })
              //console.log(board);
              if(board){
                this.bulletinBoard = board[0];
                this.bulletinBoardForm.patchValue({
                  title : this.bulletinBoard.title,
                  content : this.bulletinBoard.content,
                  startDate : this.bulletinBoard.startDate ? this.bulletinBoard.startDate.substring(0,10) : this.bulletinBoard.startDate,
                  days : this.bulletinBoard.days,
                  inactive : this.bulletinBoard.inactive,
               });
              };
            }
          });
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postBulletinBoard(bulletinDirective)
  {
    if(this.bulletinBoardForm.status === "VALID"){
      this.isProcessing1 = true;
      let data = this.bulletinBoardForm.value;
      this.evgService.postBulletinBoard(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        this.responseHandlerService.handleResponse(resp);
        if (resp) {
          if(resp.data[0]){
            //console.log("postBulletinBoard",resp.data[0]);
            this.sharedUtilService.resetForm(this.bulletinBoardForm,bulletinDirective);
            this.onBack();
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }

    
  }

  modifyBulletinBoard(bulletinDirective)
  {
    if(this.bulletinBoardForm.status === "VALID"){
      let data = this.bulletinBoardForm.value;
      data.id = this.bulletinBoard.id;
      this.isProcessing2 = true;
      this.evgService.modifyBulletinBoard(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("modifyBulletinBoard",resp.data[0]);
            this.sharedUtilService.resetForm(this.bulletinBoardForm,bulletinDirective);
            this.onBack();
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }

    
  }

}
