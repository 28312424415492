import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { OrderInfor,OrderGroup } from 'src/app/modules/common/Order';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() orderHeader : OrderGroup=null;
  @Input() orderInfor : OrderInfor[]=null;
  @Input() paidOutBounses=[];
  @Input() forCancelOrder:boolean=false;
  public isProcessing1 = false;
  public isProcessing2 = false;
  private _unsubscribe$ = new Subject<any>();
  public cancelForm : FormGroup;

  constructor(
    public activeModal : NgbActiveModal,
    private modalService: NgbModal,
    public router : Router,
    public ngDialog : NgbModal,
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    public route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.cancelForm = this.formBuilder.group({
      cancelReason: ['',this.forCancelOrder? [Validators.required] : ''],
    });

  }
  
  getSummaryBonus(){
    return this.paidOutBounses.reduce((sum, item)=> sum + item.amount,0);
  }

  onCancelOrder(){
    if(confirm("Are you sure you want to cancel this order?")){
      if(this.orderHeader){
        this.isProcessing1 = true;
        this.shareinfoService.setCreditProcess(true);
        this.evgService.cancelOrder({groupId : this.orderHeader.groupID,cancelReason: this.cancelForm.get('cancelReason').value} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.activeModal.close(true);
            }
            this.isProcessing1 = false;
          }else{
            this.isProcessing1 = false;
          }
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.shareinfoService.setCreditProcess(false);
          this.isProcessing1 = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
      
    }
  }

}
