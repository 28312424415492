import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';


@Component({
  selector: 'mat-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input('rating')  rating: number = 3;
  @Input('starCount')  starCount: number = 5;
  @Input('color')  color: string = 'primary';
  @Output() ratingUpdated = new EventEmitter();

  // private snackBarDuration: number = 2000;
  public ratingArr = [];

  constructor() {
  }


  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating:number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index:number) {
    let dif = this.rating - index;
    if( dif >= 0.5 && dif < 1){
      return 'star_half'
    }else if (this.rating >= index + 1) {
      return 'star';
    }else {
      return 'star_border';
    }
  }

}
