import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public translate : TranslateConfigService, public router : Router) { }

  ngOnInit(): void {
  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
