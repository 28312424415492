
    <div class="row purchase-container">
        <div class="toolbar">
            <div class="row" style="float: right; align-items: right;">
                <div class="item">
                    <!--
                    <span matPrefix> </span>
                    <input type="text" matInput placeholder="Search" name= "search" [(ngModel)]= "search_str">
                    -->
                    <input class="form-control py-2 border-right-0 border" type="search" (value)="search_str" id="search" (keyup.enter)="onEnter()" [(ngModel)]= "search_str" placeholder="{{'SEARCH' | translate}}" >
                    <button matSuffix mat-button  (click)="clickSearchFunction()"><mat-icon >search</mat-icon></button>
                </div>
                <div class="item">
                    <label class="sort-label">{{ 'SORTBY' | translate}}</label>
                    <select class="form-control  cart-input" [(ngModel)]="sortType" value="{{sortType}}" name="sortType" (change)="changedSortBy($event)" >
                        <option value="0">{{ 'SORTPOPULAR' | translate}}</option>
                        <option value="1">{{ 'SORTNEWEST' | translate}}</option>
                        <option value="2">{{ 'SORTOLDER' | translate}}</option>
                        <option value="3">{{ 'SORTPRICE' | translate}}</option>
                        <option value="4">{{ 'SORTPRICE2' | translate}}</option>
                        <option value="5">{{ 'SORTNAME' | translate}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class ="feature-products">
            <div class="container">
                <div class="row align-right">
                    <!-- <div class="form-group col-6">
                    <label class="input-label">{{ 'REFFERALIDLABEL' | translate}}</label>
                    <input  [disabled]="isRefferDisabled" [(ngModel)]="user.Referrals"  name="referral" class="form-control text-center cart-input" type="number" placeholder="{{ 'REFFERALUSERIDLABEL' | translate}}" >
                </div>
                <div class="form-group col-6">
                    <label class="input-label">{{ 'RECOMMENDEDIDLABEL' | translate}}</label>
                    <input [disabled]="isRecommendDisabled"  [(ngModel)]="user.Recommended"  name="recommend" class="form-control text-center cart-input" type="number" placeholder="{{ 'RECOMMENDEDUSERIDLABEL' | translate}}" >
                </div> -->
                <!-- <div class="form-group col-4">
                    <label class="input-label">{{ 'PAYMENTTYPELABEL' | translate}}</label>
                    <select class="form-control  cart-input" [(ngModel)]="cart.pType" value="{{cart.pType}}" name="pType">
                        <option value="0">{{ 'CREDITCARDONLY' | translate}}</option>
                        <option value="1">{{ 'EVCOINSLABEL' | translate}}</option>
                    </select>

                </div> -->
            </div>
            <div class="row">
                <ng-container *ngFor="let product of products; index as i;">
                    <div appImgView class="dynamic-popup"></div>
                    <div class="col-md-2 col-sm-6" *ngIf="!product.invalid && (product.limited_flag==0 || (user.pTimes>0 && product.limited_flag ==1) || (user.pTimes==0 && product.limited_flag==2)) && (product.show_category==0 || product.show_category==1)">
                        <div class="product-grid">
                            <div class="product-image mb-3">
                                <a class="image" style="background-color:#F3F3F3;">
                                    <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                                    <!--<img *ngIf="product.pPic" class="pic-1" [src]="product.pPic" (mouseenter)="onProductMouseEnter($event, product)" title="{{product.pContent}}">-->
                                    <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic" (click)="openProductDetail(product,i)" title="{{product.pContent}}">
                                </a>
                            <div class="product-content text-center">
                                <div class="pTitle">
                                    {{ product.pTitle }}
                                </div>
                                <div class="pOption">
                                    <p>SKU:</p>&nbsp;{{ product.SKU}}
                                </div>
                                <!--
                                <div class="pOption">
                                    <p>Item#:</p>&nbsp;{{ product.item_no}}
                                </div>
                                -->
    
                                <!--
                                <a class="title" title="{{ product.pContent }}">{{ product.pTitle }}</a>
                                <div>
                                    <span *ngIf="product.pTitle.length<=20" ><br></span>
                                </div>
                                -->

                                <div class="m-2">
                                    <span *ngIf="product.sales_name" [ngClass]="'sale-' + product.sales_desc_style">{{ product.sales_desc }}</span>
                                </div>
                                
                                <div class="price">{{ product.pPrice | currency:''}}&nbsp;{{ 'CURRENCYUNIT' | translate }}</div>
                            <!--  
                                <mat-star-rating *ngIf="product.reviews" [rating]="product.reviews.Score" (click)="showCustomerReviews(product.reviews)"></mat-star-rating>
                                <span *ngIf="!product.reviews" translate>NORATINGSAVAIL</span>
                                <br>
                                -->
                                <br>
                                <!-- <div class="install-price">{{ 'INSTALLFEE' | translate }} : {{ product.installFree | currency:'' }}</div> -->
                                <div class="form-group text-center">
                                    <input [(ngModel)]="product.pQty" name="quantity" class="form-control text-center cart-input" type="number" placeholder="Quantity" >
                                </div>
                                <!--
                                <a class="add-review" (click)="addProductReview(product)">{{ 'ADDREVIEW' | translate}}</a>
                                -->
                            </div>
                            
                            <div class="action-buttons">
                                <a class="btn-outline" (click)="onAddToCart(i)">{{ 'ADDTOCARTBTN' | translate}}</a>
                            </div>
                        </div>
                    </div>
                    <!----
                    <div class="overlay">
                            <img *ngIf="!product.pPic" src="./../../../../assets/images/logo/no-image.jpg">
                            <img *ngIf="product.pPic" [src]="product.pPic">
                    </div>
                -->
                </div>
            </ng-container>
                
                <!-- <div class="col-md-3 col-sm-6">
                    <div class="product-grid">
                        <div class="product-image">
                            <a href="#" class="image">
                                <img class="pic-1" src="https://picsum.photos/300/300">
                            </a>
                            <a class="add-to-cart" href=""> + </a>
                        </div>
                        <div class="product-content">
                            
                            <h3 class="title"><a href="#">Product Name</a></h3>
                            
                            <div class="price">$21.60</div>
                            <ul class="rating">
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                        </ul>
                        </div>
                        <div class="action-buttons">
                            <a class="btn-outline">ADD TO CART</a>
                        </div>
        
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <div class="product-grid">
                        <div class="product-image">
                            <a href="#" class="image">
                                <img class="pic-1" src="https://picsum.photos/300/300">
                            </a>
                            <a class="add-to-cart" href=""> + </a>
                        </div>
                        <div class="product-content">
                        
        
                            <h3 class="title"><a href="#">Product Name</a></h3>
                            
                            <div class="price">$21.60</div>
                            <ul class="rating">
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                        </ul>
                        </div>
                        <div class="action-buttons">
                            <a class="btn-outline">ADD TO CART</a>
                        </div>
        
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <div class="product-grid">
                        <div class="product-image">
                            <a href="#" class="image">
                                <img class="pic-1" src="https://picsum.photos/300/300">
                            </a>
                            <a class="add-to-cart" href=""> + </a>
                        </div>
                        <div class="product-content">
                        
                            <h3 class="title"><a href="#">Product Name</a></h3>
                            
                            <div class="price">$21.60</div>
                            <ul class="rating">
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                            <li class="fas fa-star"></li>
                        </ul>
                        </div>
        
                        <div class="action-buttons">
                            <a class="btn-outline">ADD TO CART</a>
                        </div>
        
                    </div>
                </div> -->
            </div>
        </div>           
    </div>
</div>
