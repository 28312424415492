
<div class="row purchase-container" [ngStyle]="{'background-color': background_color}">
    <div class ="imgsection" [ngStyle]="{'z-index': background_zindex}">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  *ngIf="show_header_type==2">
            <div class="carousel-inner">
                <div class="carousel-item" data-interval="get_data_interval()" *ngFor="let picfile of picFiles; index as i;" [ngClass]="{'active':i==0}">
                    <img class="carousel-image d-block w-100 h-50" [src]="picfile" >
                </div>
            </div>
        </div>
        <!--<img class="background" [src]="slide_picture" *ngIf="show_header_type==2" > -->
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  *ngIf="show_header_type==1">
            <div class="carousel-inner">
                <img class="carousel-image d-block w-100 h-50" [src]="setHeaderImg" >
            </div>
        </div>
    </div>
    <!--<div class="byte-cart">
        <a class="nav-link cart" data-toggle="collapse" style="color: black;" data-target=".navbar-collapse.show" (click)="showCart()" >
            <i class="fa fa-shopping-cart" style="font-size : 1.5rem; position : relative;color: black;" aria-hidden="true"><span class="cartBadge" *ngIf="cart_quantity > 0">&nbsp;{{ cart_quantity }}</span></i>
        </a>
    </div>-->

    <div class="toolbar">
        <div class="row toobar-fileds"> <!-- style="float: right; align-items: right;">-->
            <div class="item">
                <input class="form-control py-2 border-right-0 border" type="search" (value)="search_str" id="search" (keyup.enter)="onEnter()" [(ngModel)]= "search_str" placeholder="{{'SEARCH' | translate}}" >
                <button matSuffix mat-button  (click)="clickSearchFunction()"><mat-icon >search</mat-icon></button>
            </div>
            <div class="item">
                <label class="sort-label">{{ 'SORTBY' | translate}}</label>
                <select class="form-control  cart-input" [(ngModel)]="sortType" value="{{sortType}}" name="sortType" (change)="changedSortBy($event)" >
                    <option value="0">{{ 'SORTPOPULAR' | translate}}</option>
                    <option value="1">{{ 'SORTNEWEST' | translate}}</option>
                    <option value="2">{{ 'SORTOLDER' | translate}}</option>
                    <option value="3">{{ 'SORTPRICE' | translate}}</option>
                    <option value="4">{{ 'SORTPRICE2' | translate}}</option>
                    <option value="5">{{ 'SORTNAME' | translate}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class ="feature-products">
        <div class="container">
            <div class="row">
                <ng-container *ngFor="let product of products; index as i;">
                    <!--
                    <div appImgView class="dynamic-popup"></div>
                    <div class="col-md-2 col-sm-6" *ngIf="!product.invalid && (product.limited_flag==0 ||  product.limited_flag==2) && (product.show_category==0 || product.show_category==2)">
                    -->
                    <div class="col-md-2 col-sm-6" *ngIf="!product.invalid && (product.show_category==0 || product.show_category==2)">
                        <div class="product-grid">
                            <div class="product-image mb-3">
                                <a class="image" style="background-color:#F3F3F3;">
                                    <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                                    <!--<img *ngIf="product.pPic" class="pic-1" [src]="product.pPic" (mouseenter)="onProductMouseEnter($event, product)" title="{{product.pContent}}">-->
                                    <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic" (click)="openProductDetail(product,i)" title="{{product.pContent}}">
                                </a>
                            <div class="product-content text-center">
                                <div class="pTitle">
                                    {{ product.pTitle }}
                                </div>
                                <div class="pOption">
                                    <p>SKU:</p>&nbsp;{{ product.SKU}}
                                </div>
                                <div class="m-2">
                                    <span *ngIf="product.sales_name" [ngClass]="'sale-' + product.sales_desc_style">{{ product.sales_desc }}</span>
                                </div>
                                
                                <div class="price">{{ product.pPrice | currency:''}}&nbsp;{{ 'CURRENCYUNIT' | translate }}</div>
                                <br>
                                <div class="form-group text-center">
                                    <input [(ngModel)]="product.pQty" name="quantity" class="form-control text-center cart-input" type="number" placeholder="Quantity" >
                                </div>
                            </div>
                            
                            <div class="action-buttons">
                                <a class="btn-outline" (click)="onAddToCart(i)">{{ 'ADDTOCARTBTN' | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>           
</div>
</div>
