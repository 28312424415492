<div class="cancel-user-order-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'CANCELUSERORDER' | translate}}</h3>
              <div class="text-center mb-2">
                <form [formGroup]="searchOrderForm" (keydown.enter)="$event.preventDefault()" autocomplete="so-form">
                    <!-- <mat-form-field style="text-align: center">
                        <input matInput formControlName="groupId"  type="text" class="form-control text-box" id="groupId" aria-describedby="groupId"
                         name="groupId" [placeholder] = "'GROUPID' | translate"
                        autocomplete="off"
                        />
                        <mat-error class="error-message" *ngIf="searchOrderForm.get('groupId').hasError('required')">{{ 'GROUPIDREQ' | translate }}</mat-error>
                      </mat-form-field> -->

                      <mat-form-field style="width: 100%;">
                          <mat-label>{{'GROUPID' | translate}}</mat-label>
                          <mat-select formControlName="groupId"  type="text" class="form-control" id="groupId" aria-describedby="groupId"
                          autocomplete="off">
                              <mat-option  *ngFor="let groupID of groupIDs; index as i;" [value]="groupID.groupID">{{ 'Group ID: ' + groupID.groupID + ' - ' + groupID.user }}</mat-option>
                          </mat-select>
                          <mat-error class="error-message" *ngIf="searchOrderForm.get('groupId').hasError('required')">{{'GROUPIDREQ' | translate}}</mat-error>
                      </mat-form-field>

                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!searchOrderForm.valid || isProcessing1" (click)="onSearchOrder()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="table-responsive mb-3" *ngIf="orderHead">
                  
                 <h3 class="title">{{'ORDERHEAD' | translate}}</h3>
                  <table class="table table-bordered">
                    <thead>
                        <tr>
                          
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'GROUPID' | translate}}</th>
                          <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'DISCOUNTS' | translate}}</th>
                          <th scope="col">{{'TAXES' | translate}}</th>
                          <th scope="col">{{'PAYMENTTYPE' | translate}}</th>
                          <th scope="col">{{'CANCELDATE' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'PURCHDATE' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{{ orderHead.id }}</td>
                          <td>{{ orderHead.MemberNo }}</td>
                          <td>{{ orderHead.groupID }}</td>
                          <td>{{ orderHead.passedDay }}</td>
                          <td>{{ orderHead.amount }}</td>
                          <td>{{ orderHead.discount }}</td>
                          <td>{{ orderHead.taxes }}</td>
                          <td>{{ orderHead.payType ? ('EVCOINLABEL' | translate) : ('CREDITCARDONLY' | translate)  }}</td>
                          <td>{{ orderHead.cancelDate }}</td>
                          <td>{{ orderHead.cancelFlag }}</td>
                          <td>{{ orderHead.addtime }}</td>
                          <td>
                              <button mat-stroked-button color="primary" class="btn submit-button" *ngIf="orderHead.cancelFlag != 1" [disabled]="isProcessing2" (click)="onCancelOrder()" >
                                  {{ "CANCELBTN" | translate}}
                              </button>
                              <span *ngIf="orderHead.cancelFlag == 1">
                                {{ "CANCELLED" | translate }}
                              </span>
                            </td>
                        </tr>
                      </tbody>
                    </table>
              </div>

              <div class="table-responsive"  *ngIf="orderHead">
                  
                 <h3 class="title">{{'ORDERDETAILS' | translate}}</h3>
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">{{'PURCHDATE' | translate}}</th>
                        <th scope="col">{{'ORDERID' | translate}}</th>
                        <!-- <th scope="col">{{'GROUPID' | translate}}</th> -->
                        <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                        <th scope="col">{{'AMOUNT' | translate}}</th>
                        <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                        <th scope="col">{{'PRODUCTDESC' | translate}}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let orderDetail of orderDetails; index as i;">
                        <td>{{ orderDetail.addtime }}</td>
                        <td>{{ orderDetail.orderNo }}</td>
                        <!-- <td>{{ orderDetail.groupID }}</td> -->
                        <td>{{ orderDetail.passedDay }}</td>
                        <td>{{ orderDetail.amount }}</td>
                        <td>{{ orderDetail.pTitle }}</td>
                        <td>{{ orderDetail.pContent }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            </div>
          </div>
        </div>
    </div>
</div>