<div class="detail-container">
    <img class="prod-img" [src]="selected_product.pPic" alt="selected_product.pTitle">

    <div class="row detail-title">
        <h3 style="text-align: center; margin: auto;">{{ selected_product.pTitle }}</h3>
    </div>
    <div class="row detail-content">
        <div class="fancy-border" style="text-align: center; margin: auto;">
            <quill-view [content]="selected_product.description" format="html" theme="snow"></quill-view>
        </div>
    </div>
    <div class="row m-1">
        <div class="col-sm-12 col-xs-12 form-popup-form">
            <div style="text-align: center; margin: auto;">
                <button mat-stroked-button color="primary" class="btn submit-button m-1"  (click)="addProdToCart()" >{{ "ADDTOCARTBTN" | translate}}</button>
                <button mat-stroked-button color="primary" class="btn submit-button m-1"  (click)="goBack()" >{{ "BACKBTN" | translate}}</button>
            </div>
        </div>
    </div>

</div>