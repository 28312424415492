<div class="personal-new-orders-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'CANCELORDER' | translate}}</h3>
              <div class="text-right mb-1">
                <form [formGroup]="dateRangeForm" (keydown.enter)="$event.preventDefault()" autocomplete="dr-form">
                    <mat-form-field class="mr-2">
                        <mat-label>{{ 'USERID' | translate}}</mat-label>
                        <mat-select formControlName="selectedMemberNo" type="text" class="form-control" id="selectedMemberNo" aria-describedby="selectedMemberNo"
                        autocomplete="off">
                          <mat-option [value]=0>{{ 'ALL' | translate }}</mat-option>
                          <mat-option>{{ 'ALL' | translate }}</mat-option>
                          <mat-option  *ngFor="let user of users_ord; index as i;" [value]="user.MemberNo" (click)="onUserIdSelected(i)">{{ user.MemberNo }}&nbsp;({{user.times}})</mat-option>
                        </mat-select>
                        <!----
                        <mat-select formControlName="infoType" type="text" class="form-control" id="groupId" aria-describedby="groupId"
                        autocomplete="off">
                          <mat-option [value]="0" (click)="onUserIdSelected(-1)">{{ 'ALL' | translate }}</mat-option>
                          <mat-option  *ngFor="let user of users_ord; index as i;" [value]="user.MemberNo" (click)="onUserIdSelected(i)">{{ user.MemberNo }}&nbsp;({{user.times}})</mat-option>
                        </mat-select>
                      -->
                  </mat-form-field>
                    <mat-form-field class="mr-2" >
                        <mat-label>{{ 'ORDERTYPE' | translate}}</mat-label>
                        <mat-select formControlName="orderType"  type="text" class="form-control" id="orderType" aria-describedby="orderType"
                        autocomplete="off">
                            <mat-option  [value]="0">{{ 'ALL' | translate}}</mat-option>
                            <mat-option  [value]="1">{{ 'APPROVED' | translate}}</mat-option>
                            <mat-option  [value]="2">{{ 'ORDERCANCELLED' | translate}}</mat-option>
                        </mat-select>
                        <!----
                        <mat-select [(value)]="orderType">
                          <mat-option>None</mat-option>
                          <mat-option value="1">{{ 'APPROVED' | translate }}&nbsp;{{'ORDERS' | translate}}</mat-option>
                          <mat-option value="2">{{'ORDERCANCELLED' | translate}}</mat-option>
                        </mat-select>
                      -->
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button" (click)="getSelectedOrderGroups()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>

              <!----
              <div class="text-right mb-2" *ngIf="selectedOrderDetails">
                <a mat-stroked-button color="primary" (click)="unSelectOrder()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
            -->
              <div class="table-responsive">
                  <table class="table table-bordered" >
                      <thead>
                        <tr>
                          <th scope="col">{{'GROUPID' | translate}}</th>
                          <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                          <th scope="col">{{'USERNAME' | translate}}</th>
                          <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th scope="col">{{'PAYMENTTYPELABEL' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'CANCELDATE' | translate}}</th>
                          <th scope="col">{{'PURCHDATE' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let order of newOrders; index as i;">
                          <!--
                          <td title="{{'GROUPID' | translate}}"><a (click)="getOrderDetail(order.groupID,i)" style="cursor: pointer; color: blue;" *ngIf="!(order.cancelFlag==1)">{{ order.groupID }}</a><span *ngIf="(order.cancelFlag==1)">{{ order.groupID }}</span></td>
                          -->
                          <td title="{{'GROUPID' | translate}}">{{ order.groupID }}</td>
                          <td title="{{'PASSEDDAYS' | translate}}">{{ order.passedDay }}</td>
                          <td title="{{'USERNAME' | translate}}">{{ order.first_name }}&nbsp;{{ order.last_name }}</td>
                          <td title="{{'AMOUNTUSD' | translate}}">{{ order.amount | currency:'' }}</td>
                          <td title="{{'PAYMENTTYPELABEL' | translate}}" translate>{{shareinfoService.getPaymentType(order.payType)}}</td>
                          <td title="{{'CANCELLED' | translate}}">{{ order.cancelFlag ? ('YES' | translate) : ('') }}</td>
                          <td title="{{'CANCELDATE' | translate}}">{{ order.cancelDate }}</td>
                          <td title="{{'PURCHDATE' | translate}}">{{ order.addtime }}</td>
                          <td title="{{'ACTION' | translate}}">
                            <button type="button" class="btn btn-outline-danger" *ngIf="order.cancelFlag != 1" (click)="getOrderDetail(order.groupID,i)" title="{{ 'CANCELORDNOTICE' | translate }}">
                                {{ "CANCELBTN" | translate}}
                            </button>
                            <button type="button" class="btn btn-outline-success" *ngIf="order.cancelFlag == 1" (click)="confimOrderDetail(order.groupID,i)" title="{{ 'CANCELORDNOTICE' | translate }}">
                              {{ "CONFIRM" | translate}}
                            </button>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
  </div>
</div>
  