<div class="growth-bonus-funds-pool-container">
    <div class="row gutters-sm">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <h3 class="account-title">{{'GROWTHBONUSFUP' | translate}}</h3>
            <div class="table-responsive">
              <form  [formGroup]="pgSettingForm" (keydown.enter)="$event.preventDefault()" autocomplete="pg-form">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                          <th scope="col">{{'PGBRATE' | translate}}</th>
                          <th scope="col">{{'SET' | translate}} {{'PGBPOOL' | translate}}</th>
                          <th scope="col">{{'PGBNUMS' | translate}}</th>
                          <th scope="col">{{'STATUS' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="pgSetting">
                          
                          <td>{{ pgSetting.pgb_rate  }}</td>
                          <td>
                            <mat-form-field style="width: 100%; text-align: center;">
                              <input matInput formControlName="pgb_pool"  type="text" class="form-control text-box" id="pgb_pool" aria-describedby="pgb_pool"
                               name="pgb_pool"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="pgSettingForm.get('pgb_pool').hasError('required')">{{'PGBPOOLREQ' | translate}}</mat-error>
                              <mat-error class="error-message" *ngIf="pgSettingForm.get('pgb_pool').hasError('max')">{{'PGBPOOLMAX' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field style="width: 100%; text-align: center">
                              <input matInput formControlName="pgb_nums"  type="text" class="form-control text-box" id="pgb_nums" aria-describedby="pgb_nums"
                               name="pgb_nums"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="pgSettingForm.get('pgb_nums').hasError('required')">{{'PGBNUMSREQ' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>{{ pgSetting.actived ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</td>
                          <td><button mat-stroked-button color="primary" [disabled]="isProcessing"(click)="setBonusPool(pgSetting)" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button></td>
                      

                        </tr>
                        <tr>
                          <td colspan="3" class="text-right">
                            {{ 'PGBPOOL' | translate }}
                          </td>
                          <td colspan="2">
                            {{ bonusPool ? (bonusPool.pgb_pool | currency:'') : '' }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right">
                            {{ 'TOTALSALES' | translate }}
                          </td>
                          <td colspan="2">
                            {{ bonusPool ? (bonusPool.totalSales | currency:'') : '' }}
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>