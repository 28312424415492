import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import {ConfirmDialogComponent} from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() memberNo:number;
  public folderName:string='';
  public del_count=0;

  public imageList:any[]=[];
  private _unsubscribe$ = new Subject<any>();

  constructor(
    public evgService : EvgApiService,
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
    private responseHandlerService : ResponseHandlerService,
    public cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.folderName='/uPhotos/users/' + this.memberNo +'/';
    console.log('ImageListComponent folderName :',this.folderName);
    this.getUploadedImages();
  }
  getUploadedImages() {
    this.evgService.getMyShoppingImages({MemberNo : this.memberNo})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.imageList = resp.data[0].results;
          this.cdr.detectChanges();
          console.log('getUploadedImages resp :',resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        // this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }
  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  deleteImage(deleteImgFile) {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('DELETECONFIRM') ;
    modalRef.result.then((result) => {
      if(result) {
        this.evgService.deleteMyShoppingImage({MemberNo : this.memberNo, imgFile : deleteImgFile}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.imageList = resp.data[0].results;
            this.responseHandlerService.handleResponse(resp);
            this.del_count++;
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    });

  }

  closeReturn(){
    this.activeModal.close((this.del_count>0));
  }
}
