<div class="publish-additional-bonus-container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #sendToken="ngForm"  [formGroup]="sendTokenForm" (keydown.enter)="$event.preventDefault()" autocomplete="st-form">
                        
                        <div class="p-3">
                            <h2>{{ 'SENDTKNSTOUSER' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="toUserId"  type="text" class="form-control text-box" id="toUserId"
                                name="toUserId"  placeholder="{{ 'TOUSERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="sendTokenForm.get('toUserId').hasError('required')">{{'TOUSERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="stringId"  type="text" class="form-control text-box" id="stringId" 
                                name="stringId" placeholder="{{ 'IDSTRINGCODE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="sendTokenForm.get('stringId').hasError('required')">{{'IDSTRINGCODEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount_1"
                                name="amount" placeholder="{{ 'AMOUNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="sendTokenForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="email"  type="text" class="form-control text-box" id="email"
                                name="email" placeholder="{{ 'EMAIL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="sendTokenForm.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!sendTokenForm.valid || isProcessing1" (click)="sendTokensToUserFromAYB(sendToken)" >{{ "SENDBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form #withdrawToken="ngForm"  [formGroup]="withdrawTokenForm" (keydown.enter)="$event.preventDefault()" autocomplete="wt-form">
                        <div class="p-3">
                            <h2>{{ 'WITHDRAWTKNS' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount_2"
                                name="amount" placeholder="{{ 'AMOUNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="withdrawTokenForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!withdrawTokenForm.valid || isProcessing2" (click)="withdrawFromAYB(withdrawToken)" >{{ "WITHDRAWBTN" | translate}}</button>
                            </div>
                        </div> 
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>