import { Component,OnInit, HostListener, ComponentRef, ComponentFactoryResolver, ViewChild,ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddProductReviewComponent } from 'src/app/modules/add-product-review/add-product-review.component';
import { Cart, Product } from 'src/app/modules/common/Cart';
import { CartItem } from 'src/app/modules/common/CartItem';
import { Products } from 'src/app/modules/common/Products';
import { User } from 'src/app/modules/common/User';
import { StarRatingDetailComponent } from 'src/app/modules/star-rating-detail/star-rating-detail.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
//import { PopupHostDirective } from 'src/app/directives//host.directive';
import { ImgViewDirective } from 'src/app/directives/img-view.directive';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public products: Products[] = [];
  public cart : Cart;
  public quantity : number = 1;
  public user : User;
  public isRefferDisabled : boolean = false;
  public isRecommendDisabled : boolean = false;
  public tax_rate : any;

  public cart_items : CartItem[] = [];
  public myAutoship:any=[];

  private calloutRef: ComponentRef<ProductViewComponent>;
  @ViewChild(ImgViewDirective) private popupHost: ImgViewDirective;
  currentProdctElement: any;
  search_str : String ="";
  sortType : number=0;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private cdref : ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.getUser();
    //this.getProducts();
    this.searchSortProducts();
    this.shareinfoService.reloadTaxRate();
    this.getTaxRate();
    this.getCart();
    this.getProductReviews();
    this.getMyAutoshipProducts();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            if(this.user.Referrals) this.isRefferDisabled = true;
            if(this.user.Recommended) this.isRecommendDisabled = true;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getCart(){
    this.shareinfoService.cart.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.cart_items = successResponse;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

  }

  getProducts()
  {
    this.evgService.forPurchase([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
            if (this.products && this.products.length>0) {
                this.products.forEach(async (element,index) => {
                  this.products[index].pQty = 1;
                  this.products[index].reviews = await this.getProductReview(this.products[index].pId);
              });
            } else {
              this.products=[];
            }
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }
  
  searchSortProducts(){
    this.evgService.searchSortProducts({sortType: this.sortType, search: (this.search_str==''? null: this.search_str)})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
            if (this.products && this.products.length>0) {
              this.products.forEach(async (element,index) => {
                  this.products[index].pQty = 1;
                  //this.products[index].reviews = await this.getProductReview(this.products[index].pId);
              });
            } else {
              this.products=[];
            }
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }
  async getProductReview(pId)
  {
    let resp =  await this.evgService.getProductReview({pId : pId}).toPromise();
    return resp.data[0].results[0];
  }

  async getProductReviews()
  {
    let resp =  await this.evgService.getProductReviews({}).toPromise();
    return resp.data[0].results[0];
  }

  addProductReview(product)
  {
      const modalRef = this.ngDialog.open(AddProductReviewComponent, { windowClass: 'add-product-review-modal' ,centered: true, size: 'lg', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.product = product;

      modalRef.result.then((data) => {
        //this.getProducts();
        this.searchSortProducts();
      }, (reason) => {
        //this.getProducts();
        this.searchSortProducts();
      });
  }

  showCustomerReviews(reviews)
  {
    const modalRef = this.ngDialog.open(StarRatingDetailComponent, { windowClass: 'show-star-details-modal' ,centered: true, size: 'sm', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.reviews = reviews;

    modalRef.result.then((data) => {
      
    }, (reason) => {
      
    });
  }

  getTaxRate(){
    this.shareinfoService.tax_rate.subscribe( resp => {
      if (resp) {
        this.tax_rate = resp;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  onAddToCart(pIndex)
  {
    var item = this.cart_items.find(item => {
      return item.pId == this.products[pIndex].pId
    })

    if(item){
      
      item.nums += this.products[pIndex].pQty;
      item.amount = (item.unitPrice) * item.nums + ((item.unitPrice)  * item.nums * this.tax_rate.tax_rate);
      item.taxAmt = (item.unitPrice) * item.nums * this.tax_rate.tax_rate;

      this.evgService.modifyCart( item)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.success){
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.refreshCart();
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });

    }else{
      //let myoffer = this.
      let data = {
        pId: this.products[pIndex].pId,
        pTitle: this.products[pIndex].pTitle,
        unitPrice: this.products[pIndex].pPrice,
        nums: this.products[pIndex].pQty,
        amount : (this.products[pIndex].pPrice ) * this.products[pIndex].pQty + ((this.products[pIndex].pPrice) * this.products[pIndex].pQty * this.tax_rate.tax_rate),
        taxAmt : (this.products[pIndex].pPrice ) * this.products[pIndex].pQty * this.tax_rate.tax_rate,
        limited_flag : this.products[pIndex].limited_flag ? this.products[pIndex].limited_flag: 0
      }

      this.evgService.addCart( data)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.success){
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.refreshCart();
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  onProductMouseEnter(event, on_product) {
    console.log("onProductMouseEnter product:", on_product);

    this.currentProdctElement = event.target;
    const currentPosition = this.offset(event.target);
    this.showCallout(on_product, currentPosition);
  }

  @HostListener('mouseover', ['$event'])
  onSkillMouseOver(event) {
    let hoverComponent = event.target;
    let inside = false;
    do {
      if (this.calloutRef) {
        if (hoverComponent === this.calloutRef.location.nativeElement || hoverComponent === this.currentProdctElement) {
          inside = true;
        }
      }
      hoverComponent = hoverComponent.parentNode;
    } while (hoverComponent);
    if (inside) {
      console.log('inside');
    } else {
      console.log('outside');
      this.hideCallout();
    }
  }

  private createCallout(on_product: any): ComponentRef<ProductViewComponent> {
    const viewContainer = this.popupHost.viewContainerRef;
    viewContainer.clear();
    const calloutComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ProductViewComponent);
    const calloutComponentRef = viewContainer.createComponent(calloutComponentFactory);
    calloutComponentRef.instance.selected_product = on_product;
    return calloutComponentRef;
  }
  showCallout(on_product, currentPosition) {
    this.calloutRef = this.createCallout(on_product);
    this.calloutRef.instance.styleLeft = currentPosition.left + 'px';
    this.calloutRef.instance.styleTop = (currentPosition.top - 27) + 'px';
  }
  hideCallout() {
    if (this.calloutRef) {
      this.calloutRef.destroy();
      this.calloutRef = null;
    }
  }
  // calculate position relative to document for proper popup displaying
  private offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    return { top: scrollTop, left: rect.left + scrollLeft };
  }

  changedSortBy(event:any){
    this.sortType = event.target.value;
    this.searchSortProducts();

  }

  onEnter(){
    console.log('onEnter  search_str:',this.search_str)
    this.searchSortProducts();
  }
  clickSearchFunction() {
    console.log('clickSearchFunction  search_str:',this.search_str)
    this.searchSortProducts();
  }

  getMyAutoshipProducts(){
    this.evgService.getMyAutoshipProducts({})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.myAutoship = resp.data[0].results;
            if (this.myAutoship && this.myAutoship.length>0) {
            } else {
              this.myAutoship=[];
            }
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  openProductDetail(on_product:Product, index){
    const modalRef = this.ngDialog.open(ProductDetailComponent, { windowClass: 'verify-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.selected_product= on_product;
    modalRef.result.then((result) => {
      if(result){
        this.onAddToCart(index);
      }
    });
    this.cdref.detectChanges();
  }
}
