import { PercentPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { User } from '../common/User';

@Component({
  selector: 'app-shipping-addresses',
  templateUrl: './shipping-addresses.component.html',
  styleUrls: ['./shipping-addresses.component.scss']
})
export class ShippingAddressesComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() selectedAddress : number = -1;
  public user : User;
  public checkoutForm : FormGroup;
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public creditCards : Array<any>=[];
  public preferredShipping : number = 0;
  public shippingAddresses : any[] = [];
  //public selectedAddress : number = -1;
  private _unsubscribe$ = new Subject<any>();
  public selectedCountry : {
    name : '',
    code : ''
  };

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private percentPipe : PercentPipe
  ) { }

  ngOnInit(): void {
    this.initializeForms();
    this.getUser();
    //this.getCardsInfo();
    this.getCountryList();
    this.getUserShippingAddresses();

  }

  initializeForms(){
    this.checkoutForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      Tel : [''],
      Address : [''],
      City : [''],
      Province : [''],
      Country : [''],
      PostCode : [''],
      firstName : [''],
      lastName : [''],
      postCode : [''],
      address : [''],
    });

    this.checkoutForm.get('Country').valueChanges
    .subscribe(value => {

      if(value){
        this.getProvinceList(value);
        if(this.shippingAddresses.length > 0){
          this.checkoutForm.patchValue({
            Province : ''
          });
        }
      }
    });

    this.checkoutForm.get('Province').valueChanges
    .subscribe(value => {

    });

  }

  preferredShippingChanged()
  {

    if(this.preferredShipping == 1){
      
      
      if(this.shippingAddresses.length > 0){
        this.checkoutForm.patchValue({
          Tel : null,
          Address : null,
          City : null,
          Province : null,
          Country : null,
          PostCode : null,
        });
      }
      
      this.checkoutForm.get('Tel').setValidators(Validators.required);
      this.checkoutForm.get('Address').setValidators(Validators.required);
      this.checkoutForm.get('City').setValidators(Validators.required);
      this.checkoutForm.get('Province').setValidators(Validators.required);
      this.checkoutForm.get('Country').setValidators(Validators.required);
      this.checkoutForm.get('PostCode').setValidators(Validators.required);

  
      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();

      this.selectedAddress = -1;
      this.selectedAddressChanged();

      
      this.cdref.detectChanges();

      
    }else{
      this.checkoutForm.get('Tel').clearValidators();
      this.checkoutForm.get('Address').clearValidators();
      this.checkoutForm.get('City').clearValidators();
      this.checkoutForm.get('Province').clearValidators();
      this.checkoutForm.get('Country').clearValidators();
      this.checkoutForm.get('PostCode').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();

      this.cdref.detectChanges();
      this.selectedAddress = -1;
      this.selectedAddressChanged();

    }
  }

  selectedAddressChanged()
  {
      if (this.selectedAddress>=0) {

      }
    if(this.shippingAddresses[this.selectedAddress]){
    }
      /*
      this.cart_tax_rate = this.tax_rates.find(rate => {
        return rate.state_name.toUpperCase() === this.shippingAddresses[this.selectedAddress].Province.toUpperCase()
      })
      this.getCart();
    }else{
      this.cart_tax_rate = null;
      this.getCart();
    }
    */
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.checkoutForm.patchValue({
                name : this.user.first_name + " " + this.user.last_name,
                email : this.user.email,
                firstName : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                address : this.user.Address
              });

              this.cdref.detectChanges();

          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getCardsInfo(){
    this.evgService.getCardsInfo({}).subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.creditCards = resp.data[0].results;
        } else {
          this.responseHandlerService.handleResponse(resp);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getCountryList(){
    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }


  getProvinceList(country)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        this.provinces = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserShippingAddresses()
  {
    this.evgService.getUserShippingAddresses({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log("getUserShippingAddresses",successResponse);
              this.shippingAddresses = successResponse.data[0].results;

              if(this.shippingAddresses.length <= 0){
                this.preferredShipping = 1;
                this.preferredShippingChanged();
                this.checkoutForm.patchValue({
                  Tel : this.user.Tel,
                  Address : this.user.Address,
                  City : this.user.City,
                  Province : this.user.Province,
                  Country : this.user.Country,
                  PostCode : this.user.PostCode,
                });
              }else{
                this.preferredShipping = 0;
                this.selectedAddress = 0;
                this.preferredShippingChanged();
                
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  deleteShippingAddress(id)
  {
    this.evgService.deleteShippingAddress({id : id}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.shippingAddresses = successResponse.data[0].results;

              if(this.shippingAddresses.length <= 0){
                this.preferredShipping = 1;
                this.preferredShippingChanged();
                this.checkoutForm.patchValue({
                  Tel : this.user.Tel,
                  Address : this.user.Address,
                  City : this.user.City,
                  Province : this.user.Province,
                  Country : this.user.Country,
                  PostCode : this.user.PostCode,
                });
              }else{
                this.preferredShipping = 0;
                this.selectedAddress = 0;
                this.preferredShippingChanged();
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  saveNewAddress() {
    if(this.preferredShipping == 1){
      let shippingData = {
        Tel : this.checkoutForm.get('Tel').value,
        Address : this.checkoutForm.get('Address').value,
        City : this.checkoutForm.get('City').value,
        Province : this.checkoutForm.get('Province').value,
        Country : this.checkoutForm.get('Country').value,
        PostCode : this.checkoutForm.get('PostCode').value,
        Email : this.checkoutForm.get('email').value,
      }
      this.evgService.postShippingAddress(shippingData).subscribe( resp => {
        if (resp) {
          if(resp.success){
            shippingData["id"] = resp.data[0].shippingId;
            this.activeModal.close(shippingData);
          } else {
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    } else {
      this.activeModal.close(this.selectedAddress>=0 && this.shippingAddresses.length>0 && this.selectedAddress<this.shippingAddresses.length? this.shippingAddresses[this.selectedAddress] : null);
    }

  }

  closeWindow() {
      this.activeModal.close(null);
  }

}
