<div class="transfer-monthly-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'TRANSFERMONTHLYRECORD' | translate}}</h3>
              <div class="text-right mb-2" *ngIf="selectedTransferRecordDetails">
                <a mat-stroked-button color="primary" (click)="unSelectRecord()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered" *ngIf="!selectedTransferRecordDetails">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'SENDER' | translate}}</th>
                          <th scope="col">{{'YEARMONTH' | translate}}</th>
                          <th scope="col">{{'TIMES' | translate}}</th>
                          <th scope="col">{{'TOTALAMOUNT' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let transferRecord of transferRecords; index as i;">
                          <td><a (click)="selectRecord(transferRecord.sender)" style="cursor: pointer; color: blue;">{{ transferRecord.sender}}</a></td>
                          <td>{{ transferRecord.YearMonth}}</td>
                          <td>{{ transferRecord.times }}</td>
                          <td>{{ transferRecord.totalAmount | currency:''}}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="selectedTransferRecordDetails">
                        <thead>
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col">{{'SENDER' | translate}}</th>
                            <th scope="col">{{'RECEIVER' | translate}}</th>
                            <th scope="col">{{'TOPIC' | translate}}</th>
                            <th scope="col">{{'CONTENT' | translate}}</th>
                            <th scope="col">{{'AMOUNT' | translate}}</th>
                            <th scope="col">{{'ADDTIME' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let selectedTransferRecordDetail of selectedTransferRecordDetails; index as i;">
                            <td>{{ selectedTransferRecordDetail.sender}}</td>
                            <td>{{ selectedTransferRecordDetail.receiver}}</td>
                            <td>{{ selectedTransferRecordDetail.topic}}</td>
                            <td>{{ selectedTransferRecordDetail.content }}</td>
                            <td>{{ selectedTransferRecordDetail.amount | currency:''}}</td>
                            <td>{{ selectedTransferRecordDetail.AddTime }}</td>
                          </tr>
                        </tbody>
                      </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>