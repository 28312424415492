<div class="introduce">
    <div class="introduce-body row" [ngStyle]="setWholeStyles()">
        <div class="col-lg-6 col-sm-12" [ngStyle]="setMyStyles()">
            <h1 class="introduce-head">{{contensInfo.IntroduceTitle}}</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{contensInfo.introduceContent}}</div>
        </div>
        <div class="col-lg-6 col-sm-12" [ngStyle]="setMyStyles()">
            <h1 class="introduce-head">{{contensInfo.IngredientTitle}}</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{contensInfo.IngredientContent}}</div>
        </div>
    </div>
</div>
