import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-application-forms',
  templateUrl: './application-forms.component.html',
  styleUrls: ['./application-forms.component.scss']
})
export class ApplicationFormsComponent implements OnInit {

  public changeEmailForm: FormGroup;
  public changeFirstLastForm: FormGroup;

  private _unsubscribe$ = new Subject<any>();
  public countries : Array<any>=[];
  public provinces_1 : Array<any>=[];
  public provinces_2 : Array<any>=[];
  public isProcessing1 = false;
  public isProcessing2 = false;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    public sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.changeEmailForm = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      pre_first_name: ['', [Validators.required]],
      pre_last_name: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      pre_email: ['', [Validators.required, Validators.email]],
      change_to_email: ['', [Validators.required, Validators.email]],
      Address: ['', [Validators.required]],
      City: ['', [Validators.required]],
      Country: ['', [Validators.required]],
      Province: ['', [Validators.required]],
      PostCode: ['', [Validators.required]],
      personal_id_type: ['', [Validators.required]],
      issued_place: ['', [Validators.required]],
      personalId: ['', [Validators.required]],
      issued_date: ['', [Validators.required]],
      expired_date: ['', [Validators.required]],

    });

    this.changeFirstLastForm = this.formBuilder.group({
      pre_first_name: ['', [Validators.required]],
      pre_last_name: ['', [Validators.required]],
      chang_to_firstName: ['', [Validators.required]],
      change_to_lastName: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      Address: ['', [Validators.required]],
      City: ['', [Validators.required]],
      Country: ['', [Validators.required]],
      Province: ['', [Validators.required]],
      PostCode: ['', [Validators.required]],
    });

    this.changeEmailForm.get('Country').valueChanges
    .subscribe(value => {
      if(value){
        this.changeEmailForm.patchValue({
          Province : ''
        });
        this.getProvinceList1(value);
      }
    });

    this.changeFirstLastForm.get('Country').valueChanges
    .subscribe(value => {
      if(value){
        this.changeFirstLastForm.patchValue({
          Province : ''
        });
        this.getProvinceList2(value);
        
      }
    });

    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

  }

  public changeEmail(formDirective)
  {
    if(confirm(this.translate.translate.instant('SUBMITREQUEST'))) {
      let data = this.changeEmailForm.value;
      data.applyType = 1;
      this.isProcessing1 = true;
      this.evgService.postApplyChange(data)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            this.sharedUtilService.resetForm(this.changeEmailForm,formDirective)
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  public changeFirstLast(formDirective)
  {
    if(confirm(this.translate.translate.instant('SUBMITREQUEST'))) {
      let data_fl = this.changeFirstLastForm.value;
      data_fl.applyType = 0;
      this.isProcessing2 = true;
      this.evgService.postApplyChange(data_fl)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            this.sharedUtilService.resetForm(this.changeFirstLastForm,formDirective)
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  getProvinceList1(country)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        this.provinces_1 = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getProvinceList2(country)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        this.provinces_2 = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

}
