<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        <div class="banner-detail2 mb-3"><h2>{{ 'TINTEDPORETIT' | translate }}</h2></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5">&nbsp;</h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>
                TINTEDPORED
            </p>
        </div>
    </div>

    <div class="why-product">
        <div class="row first-row">
            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                    <img src="./../../../../../assets/images/files/skin-care/tinted-pore/1.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T1</span>
                        <span class="why-detail" translate>TINTEDPORE1D1</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/tinted-pore/2.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T2</span>
                        <span class="why-detail" translate>TINTEDPORE1D2</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                    <img src="./../../../../../assets/images/files/skin-care/tinted-pore/3.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T3</span>
                        <span class="why-detail" translate>TINTEDPORE1D3</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/tinted-pore/4.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T4</span>
                        <span class="why-detail" translate>TINTEDPORE1D4</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/tinted-pore/5.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T5</span>
                        <span class="why-detail">{{'TINTEDPORE1D5' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/tinted-pore/6.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE1T6</span>
                        <span class="why-detail">{{'TINTEDPORE1D6' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="why-acid">
        <div class="why-acid-body">
            <h1 class="why-acid-head" translate>TINTEDPORE2T1</h1>
        </div>  
    </div>

    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T1</span>
                        <span class="why-detail" translate>TINTEDPORE3D1</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T2</span>
                        <span class="why-detail">{{'TINTEDPORE3D2' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T3</span>
                        <span class="why-detail" translate>TINTEDPORE3D3</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T4</span>
                        <span class="why-detail">{{'TINTEDPORE3D4' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T5</span>
                        <span class="why-detail">{{'TINTEDPORE3D5' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T6</span>
                        <span class="why-detail">{{'TINTEDPORE3D6' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>TINTEDPORE3T7</span>
                        <span class="why-detail">{{'TINTEDPORE3D7' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-item-benefits">
        <div class="col-lg-6 col-sm-12 item-img">
            <div class="overlay"></div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-title mb-1" translate>TINTEDPORE4T1</p>
                <div class="separator mt-1 mb-3"></div>
                <p class="card-text" translate>TINTEDPORE4D1</p><br>
                <p class="card-text" translate>TINTEDPORE4D2</p><br>
                <p class="card-text" translate>TINTEDPORE4D3</p><br>
                <p class="card-text" translate>TINTEDPORE4D4</p><br>
                <p class="card-text" translate>TINTEDPORE4D5</p><br>
                <p class="card-text" translate>TINTEDPORE4D6</p><br>
                <p class="card-text" translate>TINTEDPORE4D7</p><br>
                <p class="card-text" translate>TINTEDPORE4D8</p><br>
            </div>
        </div>
    </div>
    <app-introduce-part [min_height]="640" [contensInfo]="introduceInfo"></app-introduce-part>

</div>


