import { Component, OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NightCreamModule } from 'src/app/components/pages/skin-care/night-cream/night-cream.module';


@Component({
  selector: 'app-view-night-cream',
  templateUrl: './view-night-cream.component.html',
  styleUrls: ['./view-night-cream.component.scss']
})

export class ViewNightCreamComponent implements OnInit {
  public isMobileMenuOpen:boolean  = false;

  constructor( 
    public activeModal : NgbActiveModal, 
    public router : Router,
  ) { }


  ngOnInit(): void {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = true;
    }

  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }


}
