<div class="card p-3">
    <div class="card-body text-center">
        <mat-label>{{fieldName | translate}}</mat-label>
        <mat-select type="text" class="form-control" id="options" aria-describedby="options"
        autocomplete="off">
            <ng-container *ngFor="let option of options; index as i;">
                <mat-option   [value]="option" (click)="add(option)" *ngIf="!selectedOptions.includes(option)">{{option.userId}}&nbsp;&nbsp;{{option.email}}&nbsp;&nbsp;{{option.first_name}}&nbsp;&nbsp;{{option.last_name}}</mat-option>
            </ng-container>
           
        </mat-select>
        <mat-list style="max-height: 300px; overflow-y: auto; text-align: center;">
            <mat-list-item  *ngFor="let option of selectedOptions; index as i;">{{option.userId}}&nbsp;&nbsp;{{option.email}}&nbsp;&nbsp;{{option.first_name}}&nbsp;&nbsp;{{option.last_name}}</mat-list-item>
        </mat-list>
        <div class="d-flex justify-content-center">
          <button mat-raised-button color="primary" class="mr-2 orange-button" [disabled]="!(selectedOptions.length > 0)" (click)="activeModal.close(selectedOptions)">{{ "SUBMIT" | translate }}</button>
          <button mat-raised-button (click)="activeModal.close(false)">{{ "CANCELBTN" | translate }}</button>
        </div>
    </div>
</div>
