
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FirmingCreamComponent } from './firming-cream.component';

const routes: Routes = [
  { path: '', component: FirmingCreamComponent }
]

@NgModule({
  declarations: [],
  imports: [CommonModule,RouterModule.forChild(routes)]
  //exports: [RouterModule]
})
export class FirmingCreamRoutingModule { }
