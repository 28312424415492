<div class="winners-list-record-by-month-pg-container">
    <div class="row gutters-sm text-center">
      <!-- <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                <div class="row">
                   
            </div>
          </div>
      </div> -->
        <div class="col-lg-12">
            <button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="publishPGB()" class="align-right mr-2">{{ 'PUBLISHPGBBTN' | translate }}</button>
        </div>
        <!-- <div class="col-lg-6">
            <button mat-stroked-button color="primary" (click)="publishGLB()" class="align-right mr-2">{{ 'PUBLISHGLBBTN' | translate }}</button>
        </div> -->
    </div>
</div>