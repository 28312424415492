<div class="card p-3">
    <div class="card-body text-center">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col" translate>CURRENTRANK</th>
            <th scope="col" translate>SETFLAG</th>
            <th scope="col" translate>SETDATE</th>
            <th scope="col" translate>KEEPDAYS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="user">
            <th scope="row">{{ shareinfoService.getULevelName(user.uLevel)}}</th>
            <td>{{ user.isSet == 0 ? ('NO' | translate) : (user.isSet == 1 ? ('MANUALSET' | translate) : ('SYSTEMSET' | translate)) }}</td>
            <td>{{ user.rankSetTime }} - {{ user.passedDys }} </td>
            <td>{{ user.rankKeepDays }}</td>
          </tr>
        </tbody>
      </table>
        <div class="d-flex justify-content-center">
          <button mat-raised-button class="orange-button mr-2" (click)="activeModal.close(true)">{{ "OVERWRITE" | translate }}</button>
          <button mat-raised-button (click)="activeModal.close(false)">{{ "CLOSE" | translate }}</button>
        </div>
    </div>
</div>
