import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
@Component({
  selector: 'app-account-rank-dialog',
  templateUrl: './account-rank-dialog.component.html',
  styleUrls: ['./account-rank-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountRankDialogComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() userId : number;

  public user : any;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef,
    public shareinfoService : ShareinfoService
  ) { }

  ngOnInit(): void {
    this.evgService.getUserRankInfo({userId : this.userId})
    .pipe( takeUntil(this._unsubscribe$) )
    .subscribe((resp) => {
      if (resp) {
        if(resp.data[0].results[0]){
          this.user = resp.data[0].results[0];
          this.cdr.detectChanges();
        }else{
          this.responseHandlerService.handleCustomResponse('User not found', 'error');
          this.activeModal.close(false);
        }
        //console.log(resp.data[0].results[0]);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }
  ngAfterViewInit() : void{
    
  }

  


}
