import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountRankDialogComponent } from 'src/app/modules/account-rank-dialog/account-rank-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
//import { Wholesale } from 'src/app/modules/common/Wholesale';
import { InvoiceLink,InvoiceLinkInfo } from 'src/app/modules/common/InvoiceLink';
import { InvoicePayment } from 'src/app/modules/common/InvoicePayment';
import { Product } from 'src/app/modules/common/Products';
import { InvoiceComponent } from 'src/app/modules/invoice/invoice.component';
import { PintInvoiceComponent } from 'src/app/modules/pdf-reports/pint-invoice/pint-invoice.component';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() access_tokent : string=null;
  @Input() invoiceLink : InvoiceLinkInfo=null;// include wholesale & invoice information

  public paymentFrom: FormGroup;
  public isProcessing = false;
  public invoiceAccessToken = null;
  public invoicePayment : InvoicePayment;
  public product: Product = null;
  public isSameAdr: boolean=true;
  public isPaid: boolean=false;
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public activeModal : NgbActiveModal, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    public router : Router,
    public ngDialog : NgbModal,
    public route: ActivatedRoute
  ) { 

    //console.log('in PaymentComponent constructor invoiceAccessToken:',this.access_tokent);
  }

  ngOnInit(): void {
      //console.log('in PaymentComponent ngOnInit invoiceLink:',this.invoiceLink);
      this.initializeForms();
      this.invoicePayment = new InvoicePayment();
      this.isSameAdr = this.invoiceLink.same_address;
      this.isPaid = this.invoiceLink.paid==1 ? true : false;
      //this.getProduct(this.invoiceLink.pId);
  }

  initializeForms(){
    this.paymentFrom = this.formBuilder.group({
      holder : ['', [Validators.required]],
      card_no: ['', [Validators.required]],
      expiry_date: ['', [Validators.required]],
      cvcCode: ['', [Validators.required]],
      contact_person: [this.invoiceLink.contact_person],
      phone: [this.invoiceLink.phone],
      email: [this.invoiceLink.email],
      country: [this.invoiceLink.country],
      province: [this.invoiceLink.province],
      city: [this.invoiceLink.city],
      address: [this.invoiceLink.address],
      post_code: [this.invoiceLink.post_code],
      same_address: [this.invoiceLink.same_address],
      shipping_country: [this.invoiceLink.shipping_country],
      shipping_province: [this.invoiceLink.shipping_province],
      shipping_city: [this.invoiceLink.shipping_city],
      shipping_address: [this.invoiceLink.shipping_address],
      shipping_post_code: [this.invoiceLink.shipping_post_code],
      unit_price: [this.invoiceLink.unit_price],
      quantity: [this.invoiceLink.quantity],
      tax_gst: [this.invoiceLink.tax_gst],
      tax_pst: [this.invoiceLink.tax_pst],
      tax_amt: [this.invoiceLink.tax_amt],
      amount: [this.invoiceLink.amount],
    });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  showInvoice(){
    const modalRef = this.ngDialog.open(InvoiceComponent, { windowClass: 'cart-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.invoiceLink = this.invoiceLink;

    modalRef.result.then((result) => {
      //console.log(result);
      if(result){
        //console.log('openPaymentWinsdow return result:',result);
      }
    });
  }

  getProduct(productId:number){
    //console.log("getProduct productId: ", productId);
    this.isProcessing = true;
    
    this.evgService.getProduct({pId: productId})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log("getProduct resp: ", resp);
        if (resp) {
          if(resp.data[0] && resp.data[0].results[0]){
            this.product = resp.data[0].results[0];
          }
        }
        this.isProcessing = false;
      },(errorResult) => {
        this.isProcessing = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });

  }

  public postInvoicePayment(formDirective)
  {
      this.isProcessing = true;
      let data = this.paymentFrom.value;
      data.invoice_id = this.invoiceLink.id;
      data.pTitle = this.invoiceLink.pTitle;
      data.customer = this.invoiceLink.customer;
      data.invoice_no = this.invoiceLink.invoice_no;
      //console.log('postInvoicePayment posted data:',data);

      this.evgService.postPayForInvice( data ).subscribe(resp => {
        //console.log('postInvoicePayment posted resp:',resp);
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if (resp.success) {
            this.sharedUtilService.resetForm(this.paymentFrom,formDirective);
            setTimeout(()=>{
              this.activeModal.close(true);
            },500);
          }
        } else {
          this.responseHandlerService.handleCustomResponse("Server response empty", "error");
        }
        this.isProcessing = false
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing = false
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 

  }

  onPrintInvoice(){
    //console.log('onPrintInvoice this.invoiceLink:',this.invoiceLink);
    const modalRef = this.ngDialog.open(PintInvoiceComponent, { windowClass: 'cart-modal' ,centered: true, size: 'xl', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.invoiceInfo = this.invoiceLink;
    //modalRef.componentInstance.downloadAsPDF();

    modalRef.result.then((result) => {
      //console.log(result);
      if(result){
        console.log('openPaymentWinsdow return result:',result);
      }
    });

  }
  onPrReceipt(){
    const modalRef = this.ngDialog.open(PintInvoiceComponent, { windowClass: 'cart-modal' ,centered: true, size: 'xl', keyboard: false, backdrop: "static" });
    //let modalRef = this.ngDialog.open(PintInvoiceComponent);
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.invoiceInfo = this.invoiceLink;

    modalRef.result.then((result) => {
      //console.log(result);
      if(result){
        console.log('openPaymentWinsdow return result:',result);
      }
    });

  }


}
