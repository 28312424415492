import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-introduce-part',
  templateUrl: './introduce-part.component.html',
  styleUrls: ['./introduce-part.component.scss']
})
export class IntroducePartComponent implements OnInit {
  //@Input() translate : TranslateConfigService;
  @Input() top_position:string='64';
  @Input() min_height:string="320";
  //@Input() introduceContent:string=null;
  //@Input() IngredientContent:string=null;
  @Input() contensInfo : any=null;
  isMobileMenuOpen: boolean=false;
  minHeight:string='320';

  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = true;
      this.top_position='16';
      this.minHeight = this.min_height;
    }
  }
  setMyStyles() {
    let styles = {
      'top': this.top_position + 'px',
    };
    return styles;
  }
  setWholeStyles(){
    let styles = {
      'min-height': this.minHeight + 'px',
    };
    return styles;
  }
}
