import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { EditWholesaleComponent } from './edit-wholesale/edit-wholesale.component';
import { InvoiceLink } from 'src/app/modules/common/InvoiceLink';
import { Wholesale } from 'src/app/modules/common/Wholesale';

@Component({
  selector: 'app-wholesale',
  templateUrl: './wholesale.component.html',
  styleUrls: ['./wholesale.component.scss']
})
export class WholesaleComponent implements OnInit {
  public user: User;
  public wholesales : Wholesale[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getWholesales();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getWholesales()
  {
    this.isProcessing = true;
    this.evgService.getWholesales( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          this.wholesales = resp.data[0].results;
        }
        //console.log("getWholesales this.wholesales :",this.wholesales,);
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getAType(type){
    switch(type){
      case 1 : return "PAID";
      default : return "UNPAY";
    }
  }

  onpenWholesale(linkId: number) {
    const modalRef = this.ngDialog.open(EditWholesaleComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: "lg", keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.wholesaleId = linkId;
    modalRef.componentInstance.pageTittle = (linkId ? 'EDITWHOLE' : 'ADDWHOLE');
    //console.log('call onpenInvoiceLink set invoiceLink_id !');
    modalRef.result.then((result) => {
      console.log(result);
      this.getWholesales();
    });
  }

}
