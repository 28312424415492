<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close(null)" alt="close"></span>
        </div>
    </div>
    <div class="row m-1">
        <form [formGroup]="checkoutForm" (keydown.enter)="$event.preventDefault()" class="checkout-form" autocomplete="c-form" id="checkoutFormId">
            <div class="step-header mb-2 bt-2">{{ 'SHIPPADDR' | translate }}</div>
            <div class="row contactarea">
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <mat-radio-group
                        aria-labelledby="address-radio-group-label"
                        class="address-radio-group"
                        [(ngModel)]="preferredShipping"
                        (ngModelChange)="preferredShippingChanged()"
                        [ngModelOptions]="{standalone: true}">
                        <mat-radio-button color="primary" class="address-radio-button" [value]="0" >
                            {{ 'USEEXISTINGADDR' | translate}}
                        </mat-radio-button>
                        <div class="address-list d-flex" *ngIf="preferredShipping == 0">
                            <div class="address-card" *ngFor="let shippingAddress of shippingAddresses; index as i;" [ngClass]="i == selectedAddress ? 'address-card-active' : ''">
                                <div class="d-flex justify-content-between">
                                
                                    <div>
                                        <mat-radio-group
                                        aria-labelledby="address-radio-group-label"
                                        class="address-radio-group"
                                        [(ngModel)]="selectedAddress"
                                        (ngModelChange)="selectedAddressChanged()"
                                        [ngModelOptions]="{standalone: true}">
                                            <mat-radio-button class="address-radio-button" [value]="i" >
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div>
                                        <span>{{ shippingAddress.Address }}</span><br>
                                        <span>{{ shippingAddress.City }}, {{ shippingAddress.Province }}</span><br>
                                        <span>{{ shippingAddress.Country }}, {{ shippingAddress.PostCode }}</span><br>
                                        <span>{{ shippingAddress.Tel }}</span>
                                    </div>
                                    <button class="cart-button mr-1" mat-mini-fab color="warn" aria-label="Delete" (click)="deleteShippingAddress(shippingAddress.id)">
                                        <mat-icon class="cart-icon">delete</mat-icon>
                                    </button>
                                </div>
                                
                            </div>
                            <div class="text-center p-4 d-flex justify-content-center" *ngIf="shippingAddresses.length <= 0">
                                <h2>
                                    {{ "NOSHIPPINGADDR" | translate }}
                                </h2>
                            </div>
                        </div>
                        
                        <mat-radio-button color="primary" class="address-radio-button" [value]="1" >
                            {{ 'ADDNEWADDR' | translate}}
                        </mat-radio-button>

                        <div class="form-group" *ngIf="preferredShipping == 1">
                            <!---
                            <button class="copy-button mr-1" mat-mini-fab color="warn" aria-label="Copy" (click)="copyBillingAddr()">
                                <mat-icon class="copy-icon">{{'SAMEBILLADDR' | translate}}</mat-icon>
                            </button>
                            -->

                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="Tel" type="number" class="form-control" id="Tel" aria-describedby="Tel"
                                placeholder="{{ 'TELEPHONELABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('Tel').hasError('required')" >{{ "TELEPHONEREQ" | translate }} </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="Address" type="text" class="form-control" id="Address" aria-describedby="Address"
                                placeholder="{{ 'ADDRESSLABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('Address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="City" type="text" class="form-control" id="City" aria-describedby="City"
                                placeholder="{{ 'CITYLABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('City').hasError('required')" >{{ "CITYREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                                placeholder="{{'COUNTRYLABEL' | translate}}" name="country">
                                <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                    {{ country.country }}
                                </mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="checkoutForm.get('Country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="Province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                                placeholder="{{'PROVINCELABEL' | translate}}"  name="province">
                                    <ng-container *ngFor="let province of provinces, let i = index">
                                        <mat-option [value]="province.state_name">
                                        {{ province.state_name }}
                                        </mat-option>
                                    </ng-container>
                                
                                </mat-select>
                                <mat-error class="error-message" *ngIf="checkoutForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="PostCode" type="text" class="form-control" id="PostCode" aria-describedby="PostCode"
                                placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('PostCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-radio-group>
                
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 form-popup-form">
                <div style="text-align: center; margin: auto;">
                <button mat-raised-button class="btn submit-button mr-3"  (click)="activeModal.close(null)" >{{ "CANCELBTN" | translate}}</button>
                <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="preferredShipping == 1"
                [disabled]="checkoutForm.get('Tel').hasError('required') || checkoutForm.get('Address').hasError('required') || checkoutForm.get('City').hasError('required') || checkoutForm.get('Province').hasError('required') 
                ||checkoutForm.get('Country').hasError('required') ||checkoutForm.get('PostCode').hasError('required')"  
                (click)="saveNewAddress()" >{{ "SAVE" | translate}}&nbsp;{{"AND" | translate }}&nbsp;{{"RETURN" | translate }}</button> <!-- NEXTSTEP -> MAKEPAYMENT-->

                <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="preferredShipping == 0"
                [disabled]="selectedAddress < 0"  
                (click)="saveNewAddress()" >{{ "SAVE" | translate}}&nbsp;{{"AND" | translate }}&nbsp;{{"RETURN" | translate }}</button> <!-- NEXTSTEP -> MAKEPAYMENT-->
                </div>
            </div>
        </form>
    </div>
</div>