<div class="form-popup-container center">
    <div class="card">
      <div class="card-header">
        <div class="row headrow">
          <div class="col-10 form-popup-header">
            <span></span>
            <!-- <h3> {{ "USER" | translate}}&nbsp;{{ "REGISTER" | translate}}</h3> -->
          </div>
          <div class="col-2 form-popup-header mt-2">
            <span ></span>
            <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                (click)="activeModal.close('Close click')" alt="close"></span>
          </div>
      </div>
  
      </div>
      <div class="card-body p-4">
          <form [formGroup]="registerForm" (keydown.enter)="$event.preventDefault()" class="register-form" autocomplete="reg-form">
            <div class="row">
              <div ngbAutofocus class="col-sm-12 col-xs-12 form-popup-form tatlestyle">{{ "USERINFO" | translate }}</div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                      <input matInput  formControlName="first_name" type="text" class="form-control" id="InputFirstName" aria-describedby="nameHelp"
                      placeholder="{{ 'FIRSTNAME' | translate }}" name="first_name" 
                      maxlength="60"
                      title = "{{ 'FIRSTNAME' | translate }}"
                      required>
                      <mat-error class="error-message" *ngIf="registerForm.get('first_name').hasError('required')">{{ "FIRSTNAMEREQ" | translate }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'LASTNAME' | translate}}</mat-label>
                    <input matInput  formControlName="last_name" type="text" class="form-control" id="InputLastName" 
                    placeholder="{{ 'LASTNAME' | translate }}" name="last_name" maxlength="60"
                    title = "{{ 'LASTNAME' | translate }}" required>
                    <mat-error class="error-message" *ngIf="registerForm.get('last_name').hasError('required')">{{ "LASTNAMEREQ" | translate }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
    
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'EMAILADR' | translate}}</mat-label>
                    <input 
                    matInput  
                    type="email"
                    formControlName="email" 
                    class="form-control text-box"
                    id="email-reg" 
                    aria-describedby="emailHelp" 
                    placeholder="{{ 'EMAILADR' | translate }}" 
                    name="email" 
                    autocomplete="email-reg" 
                    maxlength="60"
                    required>
                    <mat-error class="error-message" *ngIf="registerForm.get('email').hasError('required')">{{ "EMAILISREQ" | translate }}</mat-error>
                    <mat-error class="error-message" *ngIf="registerForm.get('email').hasError('email')">{{ "EMAILISVALID" | translate }}</mat-error>
                    <div class="emailnotice">
                      You are responsible to ensure the email address entered is correct. It will be used to receive future order receipts and confirmation emails.
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'SPONSORINFO' | translate}}</mat-label>
                    <input 
                    matInput  
                    type="text"
                    formControlName="sponsorId" 
                    class="form-control text-box"
                    id="sponsorId" 
                    aria-describedby="sponsorHelp" 
                    placeholder="{{ 'SPONSORINFO' | translate }}" 
                    name="sponsorId" 
                    autocomplete="sponsorId" 
                    maxlength="60"
                    >
                    <div class="emailnotice">{{ 'SPONSORINFOLB' | translate }}</div>
                  </mat-form-field>
                </div>
              </div>
            </div>
    
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <mat-form-field style="width: 95%;">
                      <!--<mat-label>{{'PASSWORD' | translate}}</mat-label>-->
                      <div class="eye-control">
                        <input matInput placeholder="{{'PASSWORD' | translate}}"  
                          [type]="fieldTextType ? 'text' : 'password'"
                           id="password" name="password"
                           formControlName="password"
                           required >
                           <span class="eye-display">
                              <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                (click)="toggleFieldTextType()"
                              ></i>
                              <i class="bi bi-question-circle mx-3" (click)="showNoticePassword(0)"></i>                            
                            </span>
                       </div>
                        <mat-error class="error-message" *ngIf="registerForm.get('password').hasError('required')">{{ "PASSREQ" | translate }}</mat-error>
                        <mat-error class="error-message" *ngIf="registerForm.get('password').hasError('pattern')">{{  "PASSPATTERNREQ" | translate}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
    
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <mat-form-field style="width: 95%;">
                      <!--<mat-label>{{'RPASSWORD' | translate}}</mat-label>-->
                      <div class="eye-control">
                        <input matInput placeholder="{{'RPASSWORD' | translate}}"  
                           [type]="fieldTextTypeConfirm ? 'text' : 'password'"
                           id="confirmPW" name="confirmPW"
                           formControlName="confirm_password"
                           required autocomplete="off">
                           <span class="eye-display">
                              <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextTypeConfirm, 'fa-eye': fieldTextTypeConfirm }"
                              (click)="toggleFieldTextTypeConfirm()"
                              ></i>
                              <i class="bi bi-question-circle mx-3" (click)="showNoticePassword(1)"></i>                            
                            </span>
                        </div>
                      <mat-error class="error-message" *ngIf="registerForm.get('confirm_password').hasError('required')">{{ "CONFIRMPASSREQ" | translate }}</mat-error>
                  </mat-form-field>
                  <div *ngIf="registerForm.value.confirm_password" class="alert" [ngClass] = "passwordMatched ? 'alert-success' : 'alert-danger'" role="alert">
                    {{ passwordMatched ? 'Password matched' : 'Password does not match' }}
                  </div>
                  
                </div>
              </div>
            </div>
    
            <div class="row">
              <div class="col-sm-12">
                <section class="d-flex flex-column m-5">
                  <mat-checkbox formControlName="privacy_terms" (change)="privacy_click()"><span style="color: red;">*</span> {{ "PRIVACYTERMS" | translate}}</mat-checkbox>
                  <mat-checkbox formControlName="user_terms" (change)="user_click()"><span style="color: red">*</span> {{ "USERSERVICETERMS" | translate }}</mat-checkbox>
                  <hr>
                  <!--<mat-label>{{ 'DISTRIBUTORBENEFIT' | translate }}</mat-label>-->
                  <div class="dist-notice">{{ 'DISTRIBUTORBENEFIT' | translate }}</div>
                  <mat-checkbox formControlName="distributor_terms" (change)="distrib_click()">{{ "DISTRIBTERMS" | translate}}</mat-checkbox>
                  <!--<div class="dist-notice">{{ 'DISRECOMMAND2' | translate }}</div>-->
                  <hr>
                  <mat-checkbox  [(ngModel)]="preferred_customer">{{ "DISRECOMMAND2" | translate}}</mat-checkbox>
                </section>
              </div>
            </div>
    
            <div class="row">
             
              <div class="col-sm-12">
                <div class="d-flex justify-content-center bd-highlight mb-3">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" (click)="send(registerForm)">Send</button>
                    <re-captcha
                        (resolved)="resolved($event)"
                        [siteKey]="siteKey"
                    ></re-captcha>
                    <h3 *ngIf="recentToken">Most recent token</h3>
                    <pre *ngIf="recentToken">{{ formatToken(recentToken) }}</pre>
                    
                    <h3 *ngIf="recentError" class="error">Most recent error</h3>
                    <pre *ngIf="recentError" class="error">{{ recentError.error || "Most recent execution resulted in error" }}</pre>
                    
                    <h3>reCAPTCHA execution log</h3>
                    <pre *ngIf="executionLog.length === 0">(empty)</pre>
                    <ol *ngIf="executionLog.length > 0">
                      <li *ngFor="let entry of executionLog">
                        Action: <code>{{ entry.action }}</code
                        >.
                        <ng-template [ngIf]="entry.token">
                          Token: <code>{{ formatToken(entry.token) }}</code>
                        </ng-template>
                        <ng-template [ngIf]="!entry.token">
                          <span class="error">Error</span>:
                          <code>{{ entry.error || "This execution resulted in error" }}</code>
                        </ng-template>
                      </li>
                    </ol>
                    -->
                    
                </div>
              </div>
            </div>
    
            <div class="col-sm-12">
              <div style="text-align: center; margin: auto;">
                <!--
                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!registerForm.valid || !captchaValidated || !passwordMatched || isProcessing" (click)="register()" >{{ "SUBMIT" | translate}}</button>
                -->
                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!registerForm.valid || !passwordMatched || isProcessing" (click)="executeAction('register')" >{{ "SUBMIT" | translate}}</button>
              </div>
            </div>
          </form>
      </div>
    </div>
</div>