import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
//import { AccountRankDialogComponent } from 'src/app/modules/account-rank-dialog/account-rank-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { uploadDoc } from 'src/app/modules/common/uploadDoc';
import { User, UserRequest } from 'src/app/modules/common/User';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-upload-doc',
  templateUrl: './add-upload-doc.component.html',
  styleUrls: ['./add-upload-doc.component.scss']
})
export class AddUploadDocComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() uploadedId : number=null;
  @Input() user : User=null;
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;files  = [];  

  public uploadedFrom: FormGroup;
  public isProcessing = false;
  public uploadedObj :uploadDoc=new uploadDoc();
  private _unsubscribe$ = new Subject<any>();
  public selectedUploadFile = false;
  public uploadedFiles:any[]=[];

  constructor(
    public activeModal : NgbActiveModal, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    public router : Router,
    public ngDialog : NgbModal,
    private http : HttpClient
  ) { }

  ngOnInit(): void {
      //console.log('ngOnInit ! uploadedId :', this.uploadedId);
      this.initializeForms();
      if (this.uploadedId) {
        this.getUploadedDoc(this.uploadedId);
      }
  }

    initializeForms(){
      this.uploadedFrom = this.formBuilder.group({
        title : ['', [Validators.required]],
        description: ['', [Validators.required]],
        file_name: ['', [Validators.required]],
        file_type: ['', [Validators.required]],
        author: [this.user.first_name, [Validators.required]],
        inactive: [0,[]],
        //selectedFile: ['', [Validators.required]],
      });

    }

    ngOnDestroy() {
      this._unsubscribe$.next();
      this._unsubscribe$.complete();
    }
  
  openPage(pageId) {
      this.activeModal.close('Close click');
      this.router.navigate([pageId]);
  }
  
  getUploadedDoc(linkId: number) {
    this.isProcessing = true;
    this.evgService.getUploadedDocs( {id : linkId}).subscribe(resp => {
      if (resp) {
        //console.log('in AddUploadDoc getUploadedDoc resp:', resp);
        if(resp.data[0] && resp.data[0].results){
          let linkSet, respData = resp.data[0].results;
          if(respData instanceof Array){
            linkSet = respData[0];
          } else {
            linkSet = respData;
          }
          this.uploadedObj = linkSet;

          this.uploadedFrom.patchValue({
            title : linkSet.title,
            description : linkSet.description,
            file_name : linkSet.file_name,
            author : linkSet.author,
            inactive : linkSet.inactive,
            //selectedFile: linkSet.path + linkSet.file_name,
           // path : path.dirname(),
            file_type: linkSet.file_type,
          });
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }


  public submitUploadDoc(formDirective)
  {
      this.isProcessing = true;
      let data = this.uploadedFrom.value;

      if (this.uploadedId) {
        data.id = this.uploadedId;
        data.saved_file_name = this.uploadedObj.saved_file_name;

      }
      
      //console.log('this.fileUpload.nativeElement.files: ',this.fileUpload.nativeElement.files);
      if (this.selectedUploadFile == true && this.fileUpload.nativeElement.files.length>0) {

        //let fd :FormData = new FormData();
        let formData = new FormData();
        let fileBlob = this.fileUpload.nativeElement.files[0]
        formData.set('file', fileBlob);

        //console.log('submitUploadDoc formData :',formData);
        this.shareinfoService.setCreditProcess(true);

        this.http.post(environment.url + '/doUploadFile/', formData).subscribe(resp => {
          if (resp && ((resp as any).success)) {
              let linkSet = (resp as any).data[0].results;
              data.saved_file_name = linkSet;

              //console.log('this.doUploadFile. data:  linkSet:',data, linkSet);
              this.evgService.postUploadDoc( data ).subscribe(rresp => {
                if (rresp) {
                  this.responseHandlerService.handleResponse(rresp);
                  this.sharedUtilService.resetForm(this.uploadedFrom,formDirective);
                }
                this.shareinfoService.setCreditProcess(false);
                this.isProcessing = false
              },(errorResult) => {
                console.log("System Error", errorResult);
                this.shareinfoService.setCreditProcess(false);
                this.isProcessing = false
                this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
              });
            } else {
              console.log("System Error, File upload failed !");
              this.shareinfoService.setCreditProcess(false);
              this.isProcessing = false
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.shareinfoService.setCreditProcess(false);
            this.isProcessing = false
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
       if ( this.uploadedId) {
            this.shareinfoService.setCreditProcess(true);
            //console.log('this.doUploadFile. data:  linkSet:',data, linkSet);
            this.evgService.postUploadDoc( data ).subscribe(rresp => {
              if (rresp) {
                this.responseHandlerService.handleResponse(rresp);
                this.sharedUtilService.resetForm(this.uploadedFrom,formDirective);
              }
              this.shareinfoService.setCreditProcess(false);
              this.isProcessing = false
            },(errorResult) => {
              console.log("System Error", errorResult);
              this.shareinfoService.setCreditProcess(false);
              this.isProcessing = false
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            });
        }
      }
  }

  onFileChange(event) {
    //console.log('AddUploadDocComponent onFileChange event:',event);

    if (event && event.target.files.length>0) {
      var file = (event.target.files[0].name).split('/').pop();
      this.uploadedFiles = event.target.files;
      //console.log('AddUploadDocComponent onFileChange file:',file);

      //return [file.substr(0,file.lastIndexOf('.')),file.substr(file.lastIndexOf('.')+1,file.length)]
      if (file && file.length>0) {
        this.uploadedFrom.patchValue({
          file_name : event.target.files[0].name, //file.substr(0,file.lastIndexOf('.')),
          file_type: file.substr(file.lastIndexOf('.')+1,file.length),
        });
      }
      this.selectedUploadFile = true;

    }
  }
}
