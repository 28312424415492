import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AddUploadDocComponent } from './add-upload-doc/add-upload-doc.component';
import { uploadDoc } from 'src/app/modules/common/uploadDoc';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss']
})
export class UploadDocComponent implements OnInit {

  public user: User;
  public uploadedDocs : uploadDoc[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private formBuilder : FormBuilder,
    private clipboardModule : ClipboardModule,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.requestsForm = this.formBuilder.group({
      infoType: ["0", [Validators.required]],
    });
    this.getUser();
    this.getuploadedDocs({infoType :0});
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getuploadedDocs(data : any)
  {
    this.isProcessing = true;
    this.evgService.getUploadedDocs( {infoType :data.infoType} ).subscribe(resp => {
      //console.log("getuploadedDocs resp :", resp );
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          
          this.uploadedDocs = resp.data[0].results ;
         // console.log("getuploadedDocs resp :",resp );
        }
       // console.log("getuploadedDocs  this.uploadedDocs :", this.uploadedDocs );
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onGetUploadedDocs()
  {
    if(this.requestsForm.status == "VALID"){
        this.getuploadedDocs(this.requestsForm.value);
    }
  }

  getAType(type){
    switch(type){
      case 0 : return "Active";
      case 1 : return "Inactive";
      default : return "ALL";
    }
 }

 openUploadDoc(docObj:any=null) {
    let linkId: number = null;
    if (docObj ) {
      linkId = docObj.id;
    }
    //console.log('call openUploadDoc set linkId !',linkId);
    const modalRef = this.ngDialog.open(AddUploadDocComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.uploadedId = linkId;
    modalRef.componentInstance.user = this.user;
    
    modalRef.result.then((result) => {
      console.log(result);
      this.getuploadedDocs(this.requestsForm.value);
    });
  }
  onSelectEvent(event: any){
    console.log('onSelectEvent value:',event);
    //this.getuploadedDocs({infoType : event.value});

  }

  getDocURL(docObj:uploadDoc) {
    return environment.hostname + '/uPhotos/download_centre/' + docObj.file_name;

  }

  deleteUploadedDoc(eventValue, docObj) {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('DELETECONFIRM') + "(" + docObj.title + ' ('+ docObj.file_name + ') ?';
    modalRef.result.then((result) => {
      if(result) {
        this.isProcessing = true;
        this.shareinfoService.setCreditProcess(true);
        this.evgService.deleteUploadedDoc( {id :docObj.id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            //console.log("deleteUploadedDoc resp :", resp);
          }
          this.isProcessing = false;
          this.onGetUploadedDocs();
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.shareinfoService.setCreditProcess(false);
          this.isProcessing = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    });
  }

  onDownloadDoc(docObj : uploadDoc ) {
    //console.log('onDownloadDoc  dicId: fileName:',docObj);
    this.shareinfoService.setCreditProcess(true);

    this.evgService.downloadDocuments({id: docObj.id }).subscribe(
        data => {
          this.shareinfoService.setCreditProcess(false);
          saveAs(data, docObj.file_name);
        },
        error => {
          this.shareinfoService.setCreditProcess(false);
          console.error(error);
        }
    );
  }

  copyDownloadLink(docObj : uploadDoc) {
    let link = environment.hostname + '/downloadDocuments?id=' + docObj.id;
    this.clipboard.copy(link);
    this.responseHandlerService.handleResponse({success:true, message: 'done !'});
  }

  copyDownloadShareLink(docObj : uploadDoc) {
    let link ='<a href=' + environment.hostname + '/downloadDocuments?id=' + docObj.id + ' target="_blank">Link Name</a>';
    this.clipboard.copy(link);
    this.responseHandlerService.handleResponse({success:true, message: 'done !'});
  }
}
