<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        
        <div class="d-flex justify-content-center flex-wrap mt-7">
        </div>
        <div class="banner-detail1">{{ 'CARBON60HSTIT' | translate }}</div>
        <div class="separator mt-1 mb-3">
        </div>

        <div class="banner-detail2 mb-3"><h2>{{ 'CARBON60HTIT' | translate }}</h2></div>
        <div class="banner-detail3 mb-3">{{ 'CARBON60HDETAIL' | translate }}</div>
        <!--
        <div class="arrow_image">
            <img src="./../../../../assets/images/files/carbon60/down_arrow.png" alt="">
        </div>
        -->
    </div>
    <div class="row what-c60">
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-text mb-1" translate>CARBON60STIT</p>
                <div class="separator mt-1 mb-3">
                </div>
                <h2 class="card-title" translate>CARBON60TIT1</h2>
                <p class="card-text mb-1" translate>CARBON60DET11</p><br>
                <p class="card-text mb-1" translate>CARBON60DET12</p><br>
                <p class="card-text mb-1" translate>CARBON60DET13</p><br>
                <p class="card-text mb-1" translate>CARBON60DET14</p><br>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 c60-img">
            <div class="overlay">
            </div>
        </div>
    </div>

    <div class="row why-c60">
        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">&nbsp;
                </div>
                <div class="col-sm-11">
                    <p class="small-title" translate>CARBON60WHY</p>
                    <div class="separator mt-1 mb-3">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">&nbsp;
                </div>
                <div class="col-sm-11">&nbsp;
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">
                <img src="./../../../../assets/images/files/carbon60/check.png">
                </div>
                <div class="col-sm-11">
                    <p class="why-title" translate>CARBON60WHYT1</p>
                    <p class="why-detail" translate>CARBON60WHYD1</p>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">
                <img  src="./../../../../assets/images/files/carbon60/check.png">
                </div>
                <div class="col-sm-11">
                    <div class="why-title" translate>CARBON60WHYT2</div>
                    <div class="why-detail" translate>CARBON60WHYD2</div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">
                <img  src="./../../../../assets/images/files/carbon60/check.png">
                </div>
                <div class="col-sm-11">
                    <div class="why-title" translate>CARBON60WHYT3</div>
                    <div class="why-detail" translate>CARBON60WHYD3</div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">
                <img src="./../../../../assets/images/files/carbon60/check.png">
                </div>
                <div class="col-sm-11">
                    <div class="why-title" translate>CARBON60WHYT4</div>
                    <div class="why-detail" translate>CARBON60WHYD4</div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="row each-secion">
                <div class="col-sm-1">
                    <img src="./../../../../assets/images/files/carbon60/check.png">
                </div>
                <div class="col-sm-11">
                    <div class="why-title" translate>CARBON60WHYT5</div>
                    <div class="why-detail" translate>CARBON60WHYD5</div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
        </div>
    </div>

    <div class="row what-c60-benefits">
        <div class="col-lg-6 col-sm-12 c60-img">
            <div class="overlay">
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-text mb-1" translate>CARBON60STIT</p>
                <div class="separator mt-1 mb-3">
                </div>
                <h2 class="card-title" translate>CARBON60EXPTIT</h2>
                <p class="card-text" translate>CARBON60EXPDET1</p><br>
                <p class="card-text-2" translate>CARBON60EXPSTIT</p>
                <p class="card-text" translate>CARBON60EXPSDET1</p><br>
                <p class="card-text-2 mb-1" translate>CARBON60EXPDET2</p><br>
            </div>
        </div>
    </div>
</div>
