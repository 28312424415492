<div class="personal-webpage-container">
    
    <div class="row gutters-sm">
      
      <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-header d-flex justify-content-center">
                <h2 translate>MYLINKTITLE</h2>
            </div>
            <div class="card-body">
                <form  #personalWebpage="ngForm" [formGroup]="myCartForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                    <div class="area-form flex justify-content-center">
                        <div class="row mt-5" style="max-width: 100%;">
                            <div class="col-md-6">
                                <mat-form-field style="width: 100%;" appearance="none">
                                    <label for="cart_name" class="mb-2" translate>MYLINKNAME</label>
                                    <input matInput formControlName="cart_name" type="text" class="form-control" id="cart_name" aria-describedby="cart_name"
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="myCartForm.get('cart_name').hasError('required')" >{{ "MYLINKNAME" | translate }} </mat-error>
                                </mat-form-field>
                                <label class="mb-2" translate>COPYREFERRALLINK</label>
                                <div class="row">
                                    <!----
                                    <ng-container *ngFor="let product of products; index as i;" class="prod-section">
                                        <div class="col-sm-8 product-image">
                                            <span>{{product.pTitle}}</span>
                                            <div class="image" style="background-color:#F3F3F3;">
                                                <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                                                <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic">
                                            </div>
                                        </div>
                                        <div class="col-sm-4 linkbutton">
                                            <button  class="btn orange-button" (click)="copyOfferedLink(product)">{{ 'COPYLINK' | translate}}</button>
                                        </div>
                                    </ng-container>
                                    -->
                                    <div class="row col-sm-12 prod-section" *ngFor="let product of products; index as i;">
                                        <div class="product-image">
                                            <span>{{product.pTitle}}</span>
                                            <div class="image" style="background-color:#F3F3F3;">
                                                <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                                                <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic">

                                            </div>
                                        </div>
                                        <div class="linkbutton">
                                            <!----
                                            <button  class="btn orange-button" (click)="copyOfferedLink(product)">{{ 'COPYLINK' | translate}}</button>
                                            <button  class="btn orange-button" [cdkCopyToClipboard]="copyOfferedLink(product)">{{ 'COPYLINK' | translate}}</button>
                                            <button  class="btn orange-button" cdkCopyToClipboard="{{currentLink}}" (click)="copyOfferedLink(product)">{{ 'COPYLINK' | translate}}</button>
                                            -->
                                            <button  class="btn orange-button" (click)="copyAchievements(product.pId)">{{ 'COPYLINK' | translate}}</button>
                                        </div>
                                        <div class="benefit-link" *ngIf="product.page_name">
                                            {{ 'PRODBENEFIT' | translate }}
                                            <a class="add-review" (click)="viewProductDetail(product.page_name)">{{product.page_name}}</a>
                                            <!--
                                            <button  class="btn orange-button" (click)="viewProductDetail(product.page_name)">{{ 'VIEWMOREDETAIL' | translate}}</button>
                                            -->
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="form-popup-form mt-5">
                                    <div style="text-align: center; margin: auto;">
                                        <button class="btn orange-button" [disabled]="!myCartForm.valid " (click)="saveMyStore()" >{{ "SAVEMYSTORE" | translate}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 adv-edit">
                                <div>
                                    <div class="m-1"><h4 class="mb-0">{{ 'STOREAD' | translate }}</h4></div>
                                    <div id="editorContainer">
                                        <quill-editor [placeholder]="'COMPOSENEWSDESC' |translate" formControlName="cart_adv">
                                        </quill-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </div>
</div>