<div class="container">
    <div class="page-banner" >
        <div>
            <h1 translate>CONTACT</h1>
        </div>
    </div>

    <div class="contact-container">
        <div class="row">
            <div class="col-lg-2">
                
            </div>
            <div class="col-lg-8">
                <div class="main">
                    <h1 translate class="p-5">CCONTACT</h1>
                    <div class="ml-5 mr-5">
                        <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60484.4930530399!2d-119.45636079635612!3d49.87680852648857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537d8cb19ca3a3b9%3A0x6c273ed06e3d3eb9!2s1855%20Kirschner%20Rd%20%23280%2C%20Kelowna%2C%20BC%20V1Y%204N7!5e0!3m2!1sen!2sca!4v1697692269162!5m2!1sen!2sca"
                                 width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                <!--
                                <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=1708%20Dolphin%20Avenue%20Kelowna,%20BC,%20V1Y%209S4,%20Canada&t=k&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                </iframe>
                                -->
                                <a href="https://git.io/JLbaF"></a><style>.mapouter{position:relative;text-align:right;height:500px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}</style><a href="https://codepen.io/embed-google-map/full/BaLrEGr"></a>
                            </div>
                        </div>
                    </div>
                    
                    <h3 translate class="pl-5 pr-5 pt-5">COFFICE</h3>
                    <div class="pl-5 pr-5 pt-2">
                        <!-- <p translate>COFFICEDESC1</p>
                        <p translate>COFFICEDESC2</p>
                        <p translate>COFFICEDESC3</p>
                        <p translate>COFFICEDESC4</p>
                        <p translate>COFFICEDESC5</p>
                        <p translate>COFFICEDESC6</p>
                        <p translate>COFFICEDESC7</p> -->
                        <p>BYTE HEALTH MANAGEMENT CORP., 280-1855 Kirschner Rd, Kelowna, BC, V1Y 4N7 CANADA </p>
                        <p>info@bytedailywellness.com</p>
                    </div>
                    <!--<h3 translate class="pl-5 pr-5 pt-5">CBOFFICE</h3>-->
                    <div class="pl-5 pr-5 pt-2" style="font-size: 0.8rem;line-height: 2.5rem">
                        <!--<p translate>CBOFFICEDESC</p> -->
                        <p>Monday – Saturday: 9AM – 6PM</p>
                        <p>Sunday: Closed</p>
                        <p>Now Serving: Okanagan, Vancouver, Langley, White Rock, Burnaby, Surrey, Edmonton, Los Angeles</p>
                        <p>Now Expanding: Franchising opportunities available contact below for more information.</p>
                       
                    </div>
                    
                    
                    <div class="p-5">
                        <h3 translate>CEMAIL</h3>
                        <p translate>CEMAILDESC1</p>
                        <a translate>CEMAILDESC2</a>

                        <p translate>CEMAILDESC3</p>
                        <a translate>CEMAILDESC4</a>
                    </div>

                    <form class="p-5">
                        <label translate>WAFORMLABEL1</label>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <input type="text" class="form-control" id="firstName">
                            <small translate>WAFORMLABEL2</small>
                          </div>
                          <div class="form-group col-md-6">
                            <input type="text" class="form-control" id="lastName">
                            <small translate>WAFORMLABEL2</small>
                          </div>
                        </div>
                        <label translate>WAFORMLABEL4</label>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <input type="text" class="form-control mb-2 mr-sm-2" id="phone1">
                                <small>(###)</small>
                            </div>
                            <div class="form-group col-md-4">
                                <input type="text" class="form-control mb-2 mr-sm-2" id="phone2">
                                <small>###</small>
                            </div>
                            <div class="form-group col-md-4">
                                <input type="text" class="form-control mb-2 mr-sm-2" id="phone3">
                                <small>###</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate>WAFORMLABEL5</label>
                            <input type="email" class="form-control" id="email">
                        </div>

                        <div class="form-group">
                            <label translate>WAFORMLABEL10</label>
                            <input type="email" class="form-control" id="subject">
                        </div>

                        <div class="form-group">
                            <label translate>WAFORMLABEL11</label>
                            <textarea rows="4" class="form-control"  id="message"></textarea>
                        </div>

                        <button translate type="submit" class="btn btn-dark">WAFORMLABEL9</button>
                    </form>
                    
                </div>
            </div>
            <div class="col-lg-2">
    
            </div>
        </div>
    </div>
   
</div>