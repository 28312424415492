<div class="infor-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <h2 class="title">{{ 'ORDERDETAILS' | translate }}</h2>
            </div>

            <div id="infocontent">
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'SUBTOTAL' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{(orderHeader.amount - orderHeader.taxes + orderHeader.discount) | currency:'' }}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'DISCOUNTS' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.discount | currency:'' }}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TAXES' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.taxes | currency:''}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'SHIPPINGFEE' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.shipping_fee | currency:''}}</span></div>
                </div>

                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TOTAL' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.amount  | currency:''}}</span></div>
                </div>

                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'RECOMMENDEDIDLABEL' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.Recommended }}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'REFFERALIDLABEL' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{orderHeader.Referrals }}</span></div>
                </div>
                <table class="table table-bordered" >
                    <thead>
                      <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col">{{'BUYER' | translate}}</th>
                        <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                        <th scope="col">{{'PRODUCTDESC' | translate}}</th>
                        <th scope="col">{{'PRICE' | translate}}</th>
                        <th scope="col">{{'QTY' | translate}}</th>
                        <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of orderInfor; index as i;">
                        <td title="{{'BUYER' | translate}}" >{{ order.MemberNo}}</td>
                        <td title="{{'PRODUCTITLE' | translate}}" >{{ order.pTitle }}</td>
                        <td title="{{'PRODUCTDESC' | translate}}" >{{ order.pContent }}</td>
                        <td title="{{'PRICE' | translate}}" >{{ order.unitPrice | currency:'' }}</td>
                        <td title="{{'QTY' | translate}}" >{{ order.pNums }}</td>
                        <td title="{{'AMOUNTUSD' | translate}}" >{{ order.amount | currency:''}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            <div id="infocontent1">
                <div class="p-3">
                    <h2 class="title">{{ 'TTLPAIDBONUS' | translate }}</h2>
                </div>
                   <table class="table table-bordered" >
                        <thead>
                          <tr>
                            <th scope="col">{{'ORDERID' | translate}}</th>
                            <th scope="col">{{'RECEIVER' | translate}}</th>
                            <th scope="col">{{'BONUSTYPE' | translate}}</th>
                            <th scope="col">{{'BONUSAMOUNT' | translate}}</th>
                            <th scope="col">{{'PENDING' | translate}}</th>
                            <th scope="col">{{'PAIDDATE' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let bonus of paidOutBounses; index as i;">
                            <td title="{{'ORDERID' | translate}}" >{{ bonus.OrderId}}</td>
                            <td title="{{'RECEIVER' | translate}}" >{{ bonus.payTo}}</td>
                            <td title="{{'BONUSTYPE' | translate}}" >{{ bonus.dealName }}{{&nbsp;(bonus.MsgInfo=="" || bonus.MsgInfo==null ? (bonus.levels>0? "("+bonus.levels+")" : "") : "(" + bonus.MsgInfo + ")" )}}</td>
                            <td title="{{'BONUSAMOUNT' | translate}}" >{{ bonus.amount | currency:'' }}</td>
                            <td title="{{'PENDING' | translate}}" translate>{{ bonus.inPending ?  "YES" : "NO"}}</td>
                            <td title="{{'PAIDDATE' | translate}}" >{{ bonus.AddTime }}</td>
                          </tr>
                        </tbody>
                    </table>
                    <div class="p-3">
                        <h2 class="title">{{'TOTAL' | translate }}&nbsp;{{ 'TTLPAIDBONUS' | translate }}&nbsp;:&nbsp;{{getSummaryBonus() | currency:'' }}</h2>
                    </div>
            </div>
        </div>
        <div class="col-12 form-popup-form" *ngIf="forCancelOrder">
            <h3 class="title">{{'CANCELUSERORDER' | translate}}&nbsp;{{'REASON' | translate}}</h3>
            <div class="text-center m-2">
              <form [formGroup]="cancelForm" (keydown.enter)="$event.preventDefault()" autocomplete="so-form">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'REASON' | translate}}</mat-label>
                        <textarea matInput formControlName="cancelReason"  type="text" class="form-control input-box" id="cancelReason"
                         aria-describedby="cancelReason"
                         placeholder="{{ 'REASON' | translate }}"  name="cancelReason" 
                         autocomplete="off"
                        ></textarea>
                        <mat-error class="error-message" *ngIf="cancelForm.get('cancelReason').hasError('required')">{{ "REASONREQ" | translate }} </mat-error>
                    </mat-form-field>
                  <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!cancelForm.valid || isProcessing1" (click)="onCancelOrder()" >{{ "CANCELORDER" | translate}}</button>
              </form>
            </div>
        </div>

        <div class="col-12 form-popup-form">
            <div style="text-align: center; margin:2rem auto;">
                <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.close(false)" >{{ "CLOSED" | translate}}</button>
            </div>
        </div>
    </div>
</div>
