import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserApplication } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-user-applications',
  templateUrl: './user-applications.component.html',
  styleUrls: ['./user-applications.component.scss']
})
export class UserApplicationsComponent implements OnInit {

  public user: User;
  public applications : UserApplication[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public isProcessing1 = false;
  public isProcessing2 = false;
  public isProcessing3 = false;
  public applicationsForm : FormGroup;
  public selectedApplication = null;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.applicationsForm = this.formBuilder.group({
      isClosed: [-1, [Validators.required]],
    });
    this.getUser();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  selectApplication(id)
  {
    this.getApplication(id);
  }
  getApplications(data : any)
  {
    this.isProcessing = true;
    var payload;
    //console.log(data.isClosed);
    if(data.isClosed >= 0){
      payload = {isClosed :data.isClosed};
    }else{
      payload = {};
    }
    this.evgService.getApplications( payload ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.applications = resp.data[0].results;
          
          //console.log("getApplications",this.applications );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
    
  }

  getApplication(id)
  {
    this.selectedApplication = this.applications.find(application => application.id === id);
  }

  onBack()
  {
    this.selectedApplication = null;
  }


  onGetApplications()
  {
    if(this.applicationsForm.status == "VALID"){
        this.getApplications(this.applicationsForm.value);
    }

  }

  onApproveApplication(id)
  {
    this.isProcessing1 = true;
    this.evgService.approveApplication( {id : id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.getApplications(this.applicationsForm.value);
      }
      this.isProcessing1 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onRejectApplication(id)
  {
    this.isProcessing2 = true;
    this.evgService.rejectApplication( {id : id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.getApplications(this.applicationsForm.value);
      }
      this.isProcessing2 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing2 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

//   getRequestType(type){
//      switch(type){
//        case 0 : return "Normal Request";
//        case 1 : return "Product Troubleshooting";
//        case 2 : return "Cancel Order";
//        case 3 : return "Change Schedule to Install Product";
//        default : return "Normal Request";
//      }
//   }

//   getAType(type){
//     switch(type){
//       case 1 : return "Withdrawal Amount";
//       case 2 : return "Withrdaw From AYB Pool";
//       default : return "Not Applicable";
//     }
//  }

}
