import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CancelledOrderRecord, CancelledOrderRecordDetail } from 'src/app/modules/common/Accounting';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-cancelled-orders-monthly-record',
  templateUrl: './cancelled-orders-monthly-record.component.html',
  styleUrls: ['./cancelled-orders-monthly-record.component.scss']
})
export class CancelledOrdersMonthlyRecordComponent implements OnInit {

  public user: User;
  public cancelledOrderRecords : CancelledOrderRecord[] = [];
  public selectedCancelledOrderRecordDetails : CancelledOrderRecordDetail[] = null;
  private _unsubscribe$ = new Subject<any>();
  public selectedRecord : string = null;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
   
    this.cancelOrdersReport();

  }

  selectRecord(YearMonth){
    this.monthlyCanceledOrders(YearMonth);
  }

  unSelectRecord()
  {
    this.selectedRecord = null;
    this.selectedCancelledOrderRecordDetails = null;
    this.cancelOrdersReport();
  }

  cancelOrdersReport()
  {
    this.evgService.cancelOrdersReport( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.cancelledOrderRecords = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  monthlyCanceledOrders(YearMonth)
  {
    this.evgService.monthlyCanceledOrders( {YearMonth : YearMonth} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.selectedCancelledOrderRecordDetails = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
