<div class="coupon-setup-container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <div class="title mb-3">
                        <h2>{{ 'COUPONLIST' | translate }}</h2>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">{{'COUPON' | translate}}</th>
                                <th scope="col">{{'AMOUNT' | translate}}</th>
                                <th scope="col">{{'TYPE' | translate}}</th>
                                <th scope="col">{{'SPECIALRANGE' | translate}}</th>
                                <th scope="col">{{'FROMDATE' | translate}}</th>
                                <th scope="col">{{'TODATE' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let coupon of coupons; index as i;">
                                    <td>{{ coupon.coupon }}<br>
                                        <button class="btn btn-success btn-sm rounded-0 mx-2" type="button" data-toggle="tooltip" data-placement="top" title="Edit" (click)="onEditCoupon(coupon)"><i class="fa fa-edit"></i></button>
                                        <button class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Delete" (click)="onDeleteCoupon(coupon)"><i class="fa fa-trash"></i></button>
                                    </td>
                                    <td>{{ coupon.coupon_amt }}</td>
                                    <td>{{ coupon.coupon_type ? 'PERCENTAGE' : 'AMOUNT' }}</td>
                                    <td>{{ coupon.special_range }}</td>
                                    <td>{{ coupon.from_dt ? coupon.from_dt.substring(0,10) : '' }}</td>
                                    <td>{{ coupon.to_dt ? coupon.to_dt.substring(0,10) : ''  }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form #couponDirective="ngForm" [formGroup]="couponForm" (keydown.enter)="$event.preventDefault()" autocomplete="cp-form">
                        <div class="title">
                            <h2>{{ 'ADDCOUPON' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="coupon"  type="text" class="form-control text-box" id="coupon" 
                                name="coupon" placeholder="{{ 'COUPON' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="couponForm.get('coupon').hasError('required')">{{'COUPONREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                            <div class="col-sm-4 d-flex justify-content-center align-items-center">
                                <div>
                                    <a mat-stroked-button color="primary" class="btn" (click)="generateCoupon()">{{ "GENERATE" | translate}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field  style="width: 100%;">
                                <mat-label>{{'COUPONTYPE' | translate}}</mat-label>
                                <mat-select formControlName="coupon_type"  type="text" class="form-control" id="coupon_type" aria-describedby="coupon_type"
                                autocomplete="off">
                                    <mat-option  [value]="0">{{ 'AMOUNT' | translate}}</mat-option>
                                    <mat-option  [value]="1">{{ 'PERCENTAGE' | translate}}</mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="couponForm.get('coupon_type').hasError('required')">{{'COUPONTYPEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field  style="width: 100%;">
                                    <mat-label>{{'CATEGORY' | translate}}</mat-label>
                                    <mat-select formControlName="category"  type="text" class="form-control" id="category" aria-describedby="category"
                                    autocomplete="off" >
                                        <mat-option  [value]=0>{{ 'FORWEBSITE' | translate}}</mat-option>
                                        <mat-option  [value]=1>{{ 'FORREFERLINK' | translate}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="couponForm.get('category').hasError('required')">{{'CATEGORYREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-12 mb-1">
                                <mat-checkbox
                                    class="checbox-margin"
                                    [(ngModel)]="isForCommon"
                                    labelPosition="after"
                                    (change)="onchangeForCommon($event)"
                                    [ngModelOptions]="{standalone: true}"
                                    >
                                    {{ 'FORCOMMON' | translate }}
                                </mat-checkbox>
                            </div>
                            <hr>
                            <div class="col-6 col-sm-12 mt-1">
                                <mat-checkbox
                                    class="checbox-margin"
                                    [(ngModel)]="isInactived"
                                    labelPosition="after"
                                    (change)="onchangeInactived($event)"
                                    [ngModelOptions]="{standalone: true}"
                                    >
                                    {{ 'INACTIVE' | translate }}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="coupon_amt"  type="number" class="form-control text-box" id="coupon_amt" 
                                name="coupon_amt" placeholder="{{ 'AMOUNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="couponForm.get('coupon_amt').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="true || isForCommon">
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field  style="width: 100%;">
                                    <mat-label>{{'SPECIALRANGE' | translate}}</mat-label>
                                    <input matInput formControlName="special_range"  type="text" class="form-control text-box" id="special_range" 
                                    name="special_range" placeholder="{{ 'SPECIALRANGE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="couponForm.get('special_range').hasError('required')">{{'SPECIALRANGEREQ' | translate}}</mat-error>
                                    <mat-hint>*: {{ 'FORALLPRODUCTS' | translate }}, 1: {{ 'FORSPECIFICPRODUCT' | translate }}.</mat-hint>
                                <!----
                                    <mat-select formControlName="special_range"  type="text" class="form-control" id="special_range" aria-describedby="special_range"  autocomplete="off">
                                        <mat-option  [value]="*">{{ 'FORALLPRODUCTS' | translate}}</mat-option>
                                        <mat-option  [value]="1">{{ 'FORSPECIFICPRODUCT' | translate}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="couponForm.get('special_range').hasError('required')">{{'SPECIALRANGE' | translate}}</mat-error>
                                -->
                                </mat-form-field>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="showMemberList || isForCommon">
                            <div class="row">
                                <div class="col-sm-12 d-flex justify-content-center align-items-center">
                                    <h2>Discount for each Product</h2>
                                </div>
                                <div class="col-sm-12">
                                    <div class="list-group" style="min-height : 4rem;overflow-y: auto">
                                        <div *ngFor="let couponProduct of coupon_products; index as i;" class="list-group-item">
                                            <div>{{couponProduct.pTitle}}:</div>
                                            <input type="text" name="coponQty{{i}}" [(ngModel)]="couponProduct.pQty" [value]="couponProduct.pQty" id="coponQty{{i}}"  (change)="onChangeEvent(i,$event)">
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="from_dt"  type="date" class="form-control text-box" id="from_dt" 
                                    name="from_dt" placeholder="{{ 'FROMDATE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="couponForm.get('from_dt').hasError('required')">{{'FROMDATEREQ' | translate}}</mat-error>
                                    
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="to_dt"  type="date" class="form-control text-box" id="to_dt" 
                                    name="to_dt" placeholder="{{ 'TODATE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="couponForm.get('to_dt').hasError('required')">{{'TODATEREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!couponForm.valid || isProcessing1" (click)="addCoupon(couponDirective)" translate>{{ isEdit == true ? "UPDATEBTN" : "ADDBTN" }}</button>
                            </div>
                        </div>
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>