import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { CustomerReviewsComponent } from '../customer-reviews/customer-reviews.component';

@Component({
  selector: 'app-star-rating-detail',
  templateUrl: './star-rating-detail.component.html',
  styleUrls: ['./star-rating-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingDetailComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() reviews : any;
  public starCount: number = 5;
  public totalReviews = 0;

  public ratingArr = [];
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    

    for (const [key, value] of Object.entries(this.reviews.stars)) {
      this.totalReviews += parseInt(String(value)) ;
    }

    //console.log(this.reviews);
  }

  showIcon(index:number) {
    let dif = this.reviews.Score - index;
    if( dif >= 0.5 && dif < 1){
      return 'star_half'
    }else if (this.reviews.Score >= index + 1) {
      return 'star';
    }else {
      return 'star_border';
    }
  }

  openCustomerReviews()
  {
    const modalRef = this.ngDialog.open(CustomerReviewsComponent, { windowClass: 'show-customer-reviews-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.pId = this.reviews.pId;

    modalRef.result.then((data) => {
      
    }, (reason) => {
      
    });
  }

}
