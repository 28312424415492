<div class="transfer-to-current-balance-container">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form  #transferCurrentBalance="ngForm" [formGroup]="transferCurrentBalanceForm" (keydown.enter)="$event.preventDefault()" autocomplete="tcb-form">
                        
                        <div class="p-3">
                            <h2>{{ 'TRANSFERTOCURRENTBALANCE' | translate}}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="current_balance" [value]="'$ ' + user.eWallet"
                                name="current_balance"  placeholder="{{ 'AVAILAMOUNT' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="withdraw_amount"  type="number" class="form-control text-box" id="withdraw_amount"
                                name="withdraw_amount"  placeholder="{{ 'TRANSFERAMNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferCurrentBalanceForm.get('withdraw_amount').hasError('required')">{{'TRANSFERAMNTREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferCurrentBalanceForm.get('withdraw_amount').hasError('min')">{{'AMOUNTMIN1' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferCurrentBalanceForm.get('withdraw_amount').hasError('max')">{{'AMOUNTMAX' | translate}}  {{ user.eWallet | currency }} </mat-error>
                            </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!transferCurrentBalanceForm.valid " (click)="transferToCurrentBalance(transferCurrentBalance)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>