<div class="growth-bonus-top-winners-container">
    <div class="row gutters-sm text-center mb-3">
        <div class="col-lg-12">
            <button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="generateRankBulletin()" class="align-right mr-2">{{ 'GENERATERANKBUL' | translate }}</button>
        </div>
    </div>

    <div class="row gutters-sm">
        <div class="col-md-12">
            <div class="card mb-3">
              <div class="card-body">
                <h3 class="account-title">{{'PERFORMANCEGROWTHRANK' | translate}}</h3>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col">{{'MEMBERNO' | translate}}</th>
                            <th scope="col">{{'BONUSAMOUNT' | translate}}</th>
                            <th scope="col">{{'YEARMONTH' | translate}}</th>
                            <th scope="col">{{'PAIDDATE' | translate}}</th>
                            <th scope="col">{{'RANK' | translate}}</th>
                            <th scope="col">{{'SALESAMOUNT' | translate}}</th>
                            <th scope="col">{{'ULEVEL' | translate}}</th>
                            <th scope="col">{{'WEIGHT' | translate}}</th>
  
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let pgRank of pgRanks; index as i;">
                            <td>{{ pgRank.MemberNo }}</td>
                            <td>{{ pgRank.bonusAmt | currency:'' }}</td>
                            <td>{{ pgRank.forYYYYMM }}</td>
                            <td>{{ pgRank.paid_dt ? pgRank.paid_dt.substring(0,10) : pgRank.paid_dt }}</td>
                            <td>{{ pgRank.rank }}</td>
                            <td>{{ pgRank.salesAmt | currency:'' }}</td>
                            <td>{{ pgRank.uLevel }}</td>
                            <td>{{ pgRank.weighted }}</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
              </div>
            </div>
        </div>
      </div>
</div>