<div class="sales-invoice-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'SALESINVOICE' | translate}}</h3>
              <div class="text-right mb-2"  *ngIf="!selectedInvoices">
                <form [formGroup]="dateRangeForm" (keydown.enter)="$event.preventDefault()" autocomplete="dr-form" >
                    <mat-form-field class="mr-2">
                        <mat-label>{{ 'FROMDATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1
                                        startView="multi-year"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error class="error-message" *ngIf="dateRangeForm.get('fromDate').hasError('required')">{{'FROMDATEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="mr-2" >
                        <mat-label>{{ 'TODATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="toDate">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2
                                        startView="multi-year"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error class="error-message" *ngIf="dateRangeForm.get('toDate').hasError('required')">{{'TODATEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!dateRangeForm.valid || isProcessing" (click)="onGenerateReport()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="text-right mb-2" *ngIf="selectedInvoices">
                <a mat-stroked-button color="primary" (click)="unSelectOrder()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered" *ngIf="!selectedInvoices">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'GROUPID' | translate}}</th>
                          <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                          <th scope="col">{{'QUANTITY' | translate}}</th>
                          <th scope="col">{{'GST' | translate}}</th>
                          <th scope="col">{{'PST' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let salesInvoice of salesInvoices; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td><a (click)="getOrderDetail(salesInvoice.groupID)" style="cursor: pointer; color: blue;" >{{ salesInvoice.groupID }}</a></td>
                          <td>{{ salesInvoice.passedDay }}</td>
                          <td>{{ salesInvoice.pNums }}</td>
                          <td>{{ salesInvoice.GST | currency:'' }}</td>
                          <td>{{ salesInvoice.PST | currency:'' }}</td>
                          <td>{{ salesInvoice.amount | currency:'' }}</td>
                          <td>{{ salesInvoice.addtime}}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="selectedInvoices">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                          <th scope="col">{{'QUANTITY' | translate}}</th>
                          <th scope="col">{{'PRICE' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <!-- <th scope="col">{{'ADDTIME' | translate}}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let selectedInvoice of selectedInvoices; index as i;">
                          <td>{{ selectedInvoice.pId}}</td>
                          <td>{{ selectedInvoice.pTitle }}</td>
                          <td>{{ selectedInvoice.pNums }}</td>
                          <td>{{ selectedInvoice.unitPrice | currency:'' }}</td>
                          <td>{{ selectedInvoice.amount | currency:''}}</td>
                          <!-- <td>{{ selectedInvoice.addtime }}</td> -->
                        </tr>
                        <ng-container *ngIf="selectedHeader">
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>{{ 'SUBTOTAL' | translate }}</th>
                            <td>{{ (selectedHeader.amount - selectedHeader.taxes + selectedHeader.discount) | currency:'' }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>{{ 'TAXES' | translate }}</th>
                            <td>{{ selectedHeader.taxes | currency:'' }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>{{ 'DISCOUNTS' | translate }}</th>
                            <td>{{ selectedHeader.discount | currency:'' }}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>{{ 'TOTAL' | translate }}</th>
                            <td>{{ selectedHeader.amount | currency:'' }}</td>
                          </tr>
                        </ng-container>
                        
                        <!-- <tr *ngIf="selectedInvoices.length == 0">
                            <td colspan="5"></td>
                        </tr> -->
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>