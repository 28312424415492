import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-personally-sponsored-chart',
  templateUrl: './personally-sponsored-chart.component.html',
  styleUrls: ['./personally-sponsored-chart.component.scss']
})
export class PersonallySponsoredChartComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public user : User = null;
  public treeNodes : any = [];
  public treeData = [];
  public treeMode = 'vertical';

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private shareinfoService : ShareinfoService,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getTeamData();

    if(window.innerWidth < 1620){
      this.treeMode = "horizontal";
      this.cdr.detectChanges();
    }else{
      this.treeMode = "vertical";
      this.cdr.detectChanges();
    }
  }


 unflatten(arr) {
  var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for(var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['childs'] = [];
  }


  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parentid) {
        mappedArr[mappedElem['parentid']]['childs'].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

  test(event)
  {
    console.log(event);
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getTeamData()
  {

    this.evgService.getSponsers([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0]){
            this.treeData = resp.data[0].teamtree;
            //console.log(this.treeData)

            if(this.treeData.length > 0){

              let result = this.treeData.filter(data => data.id != this.user.id);
              // result = result.filter(data => data.Referrals == this.user.id);
              this.treeNodes = this.unflatten(this.mapData(result));
            }else{
              this.treeData = [];
            }


          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  mapData(data)
  {
    let mappedData = [];

    let node = { 
      id : this.user.id,
      parentid : 0,
      name : this.user.id + ' ' + this.user.last_name,
      cssClass : this.getCssClass(this.user.uLevel),
      title: this.user.first_name + ' ' + this.encodeLastName(this.user.last_name)
    };

    mappedData.push(node);

    data.forEach(element => {
      let node = { 
        id : element.id,
        parentid : element.Recommended,
        name : element.id,
        cssClass : this.getCssClass(element.Sponsor_uLevel),
        image : this.user.id == element.Recommended ? 'assets/images/icons/heart.png' : '',
        title: element.Sponsor_firstName + ' ' + this.encodeLastName(element.Sponsor_lastName)
      };

      mappedData.push(node);
    });
    return mappedData;
  }

  encodeLastName(lastName){
    //console.log(lastName.length);
    if(lastName.length > 0){
       var modifiedLastName = '***';
      //  for (let index = 0; index < (lastName.length - 1); index++) {
      //    modifiedLastName += '*';
      //  }
       modifiedLastName += lastName.substr(lastName.length - 1);
       return modifiedLastName;
    }
    return '';
  }

  getCssClass(uLevel){
    switch (uLevel) {
      case 0: return 'member';
        break;
      case 1: return 'consultant';
        break;
      case 2: return 'distributor';
        break;
      case 3: return 'executive';
        break;
      case 4: return 'sapphire';
        break;
      case 5: return 'diamond';
        break;   
      case 6: return 'crown';
        break;               
      default: return 'member';
        break;
    }
  }
  

}
