<ngx-paypal 
[config]="payPalConfig">
</ngx-paypal>

<!--
<div id="myPayPalbuttons"></div>
<ngx-paypal 
[config]="payPalConfig">
</ngx-paypal>
<div class="paypal-container">
    <ngx-paypal *ngIf="showPaypalButtons" 
    [config]="payPalConfig">
    </ngx-paypal>
    <button  *ngIf="showPaypalButtons" (click)="back()">Volver atras</button>
</div>
-->