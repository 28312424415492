<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title">
        
        <div class="d-flex justify-content-center flex-wrap mt-7">
        </div>
        <h2 class="mb-5" style="text-transform: uppercase;">{{ 'FULVICTITLE' | translate}}</h2> 
    </div>

    <div class="why-acid">
        <div class="why-acid-body">
            <h1 class="why-acid-head" style="text-transform: uppercase;" translate>
                WHYFULVICTITLE
            </h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3">
                </div>
            </div>
            <div class="row">
                <div class="more-body mb-3" translate>WHYFULVICDETAIL</div>
            </div>
        </div>  
    </div>
    <div class="know-fd-title"><h1 translate>FA2KNOWTIT</h1></div>
    <div class="why-need">
        <div class="item-left">
            <div class="card-block-1">
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD1</p><br>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD2</p><br>
                <p class="card-block-img"><img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/fulvic-acid/mountain-with-rocks.jpg" alt=""></p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD3</p><br>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD4</p><br>
            </div>
        </div>
        <div class="item-right">
            <div class="card-block-1">
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD5</p><br>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6</p><br>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6D1</p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6D2</p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6D3</p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6D4</p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD6D5</p>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD7</p><br>
                <p class="card-text mb-1 mx-1" translate>FA2KNOWD8</p><br>
            </div>
        </div>
    </div>
    <div class="why-take">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title" translate>FA2WHATISHA</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-1" translate>FA2WHATISHAD1</p><br>
                <p class="card-text mb-1" translate>FA2WHATISHAD2</p><br>
                <h2 class="card-title-small" translate>FA2WHATISHAD3T</h2>
                <p class="card-text mb-1" *ngFor="let elKey of keywords; index as i;" translate>{{elKey.keyword}}</p><br>
            </div>
        </div>
        <div class="item-right">
            <img src="./../../../../assets/images/files/fulvic-acid/portrait-man-home-drinking-glass-water.jpg" alt="">
        </div>
    </div>

    <div class="we-care">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title">{{'FA2PRAISETIT' | translate}} ?</h1>
                <div class="separator mb-3"></div>
                <h2 class="card-title-small" translate>FA2PRAISESUBTIT</h2>
                <p class="card-text mb-1" translate>FA2PRAISESUBD1</p>
                <br>
                <p class="card-text mb-1" translate>FA2PRAISESUBD2</p>
            </div>
        </div>
        <div class="item-right">
            <img src="./../../../../assets/images/files/fulvic-acid/pexels-andrea-piacquadio-jump-updated.jpg" alt="">
        </div>
    </div>
</div>