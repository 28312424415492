import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RankHistory } from 'src/app/modules/common/RankHistory';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-account-rank',
  templateUrl: './account-rank.component.html',
  styleUrls: ['./account-rank.component.scss']
})
export class AccountRankComponent implements OnInit {

  public user: User;
  public rankName : string = "Member";
  public rankHistory : RankHistory[] = [];
  public downlines = [];
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    // this.getRankHistory();
    this.getULevelLogs();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.getMyRankName();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMyRankName(){
    this.shareinfoService.rankName.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (rankName: any) => {
            this.rankName = rankName;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getRankHistory()
  {
    this.evgService.getRankHistory( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          //console.log(resp);
          this.rankHistory = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getULevelLogs()
  {
    this.evgService.getULevelLogs( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.rankHistory = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getMyDownLinelist(){
    this.evgService.getMyDownLinelist({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          if(successResponse.success){
            this.downlines = successResponse.data[0].results;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );
  }

}
