

    <div class="d-flex justify-content-end">
        <button class="btn zoom-btn btn-info mr-1" (click)="onZoomIn()">Zoom +</button>
        <button class="btn zoom-btn btn-info mr-1"  (click)="onZoomOut()">Zoom -</button>
        <button class="btn zoom-btn btn-success mr-1"  (click)="onRecenter()">Recenter</button>
        <button class="btn zoom-btn btn-success mr-1"  (click)="onRotate()">Rotate</button>
        <!-- <button class="btn zoom-btn btn-danger"  (click)="onResetTree()">Reset Tree</button> -->
    </div>
    
    
    
<div class="tree">
        <div class="rectangle m-3" >
            <h1>Color Legend</h1>
            <div class ="member">Dealer</div>
            <div class ="consultant">Consultant</div>        
            <div class ="distributor">Director</div>
            <div class ="executive">Executive</div>
            <div class ="sapphire">Sapphire</div>
            <div class ="diamond">Diamond</div>
            <div class ="crown">Crown</div>
        </div>
 
    <ngx-org-chart 
        class="org-chart" 
        *ngIf="treeNodes.length > 0" 
        [nodes]="treeNodes" 
        [direction]="treeMode" 
        (itemClick)="showTree($event)"
        #rendererContainer
        ></ngx-org-chart>

    <div *ngIf="treeNodes.length <= 0" class="text-center">
        <h1>Tree Data is not yet available for your account. Refer someone to generate your tree.</h1>
    </div>
    <!-- <app-tree-diagram [treeModel] = "treeModel"></app-tree-diagram> -->

    
</div>