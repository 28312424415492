<div class="account-upload-portrait-container">
    
    <div class="row">

        <div class="col-lg-3">

        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                          <div class="snapshot" *ngIf="selectedFile">
                            <!-- <h2>Take your image or get another</h2> -->
                            <img [height]="300" [src]="selectedFile.src"/>
                          </div>
                          <label class="image-upload-container btn btn-primary m-2">
                            <span>{{'SELECTPHOTO' | translate}}</span>
                            <input #imageInput
                                   type="file"
                                   [disabled]="isProcessing"
                                   accept="image/*"
                                   (change)="processFile(imageInput)">
                          </label>
                            <button 
                              class="btn btn-success m-2"
                              [disabled]="!selectedFile || isProcessing"
                              (click)="uploadPhoto(selectedFile.src)"
                            >{{'UPLOADPHOTO' | translate}}</button>
                        </div>
                      </div>
                      
                      <hr>
                      <h3>OR</h3>
                      <hr>
                      <h3>{{'TAKEPICTURE' | translate}}</h3>
                      <div class="row" *ngIf="cameraOpened">
                        <div class="col-md-6">
                          <div class="camera-container">
                            <webcam [height]="300" [width]="300"  [allowCameraSwitch]="true" (initError)="handleInitError($event)"  [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
                          </div>
                          <div class="p-2">
                            <button  type="button" class="btn btn-primary align-center" (click)="triggerSnapshot();">{{'CAPTURE' | translate}}</button>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="snapshot" *ngIf="webcamImage">
                            <!-- <h2>Take your image or get another</h2> -->
                            <img [src]="webcamImage.imageAsDataUrl"/>
                          </div>
                          <div class="p-2">
                            <button  type="button" [disabled]="!webcamImage" class="btn btn-success align-center" (click)="uploadPhoto(webcamImage.imageAsDataUrl);">{{'UPLOADPHOTO' | translate}}</button>
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-center" *ngIf="!cameraOpened">
                        <button  type="button" class="btn btn-success m-3" (click)="toggleCamera()">{{ cameraOpened ? ('CLOSECAMERA' | translate) : ('OPENCAMERA' | translate) }}</button>
                      </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            
        </div>
        
    </div>
</div>