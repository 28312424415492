import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ImageSnippet } from '../common/ImageSnippet';
import { Product } from '../common/Products';
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() product : Product;
  public productForm : FormGroup;
  public selectedFile: ImageSnippet;
  private _unsubscribe$ = new Subject<any>();
  public isInactived:boolean= false;
  public update_cart:boolean=false;
  public hasBenefit:boolean=false;
  benefit_descript:string =null;
  public benefits:any=null;
  
  constructor(
    public activeModal : NgbActiveModal, 
    private formBuilder : FormBuilder,
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef,
    public shareinfoService : ShareinfoService,
  ) {

    this.evgService.getBenefits().subscribe(resp => {
      if(resp){
        this.benefits = resp;
        //console.log("this.evgService.getBenefits resp", resp);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

  }

  ngOnInit(): void {
    this.initializeForms();

    if(this.product){
      //console.log(this.product);
      this.productForm.patchValue({
        installFree: this.product.installFree,
        invalid: this.product.invalid,
        pContent: this.product.pContent,
        pId: this.product.pId,
        pPic: this.product.pPic,
        pPrice: this.product.pPrice,
        pTitle: this.product.pTitle,
        pType: this.product.pType,
        luck_days: this.product.luck_days,
        luck_rank : this.product.luck_rank,
        reduceRate : this.product.reduceRate ? this.product.reduceRate : 0,
        sales_name : this.product.sales_name,
        sales_desc : this.product.sales_desc,
        sales_desc_style : this.product.sales_desc_style,
        tax_type : this.product.tax_type,
        gst_share_amt : this.product.gst_share_amt,
        pst_share_amt : this.product.pst_share_amt,
        page_name : this.product.page_name,
        autoship : this.product.autoship,
        autoship_discount : this.product.autoship_discount,
        limited_flag : this.product.limited_flag,
        item_no : this.product.item_no,
        SKU : this.product.SKU,
        has_benefit : this.product.has_benefit,
        benefit_id : this.product.benefit_id,
        sort_no : this.product.sort_no,
        description : this.product.description,
        referral_fee : this.product.referral_fee,
        show_category : this.product.show_category
      });
      this.isInactived = (this.product.autoship==1);
      this.hasBenefit =  (this.product.has_benefit==1);
      this.benefit_descript =  this.product.benefit_name;

    }else{
      this.product = new Product();
    }
  }
  initializeForms(){
    this.productForm = this.formBuilder.group({
      installFree: ['', [Validators.required]],
      invalid: ['', [Validators.required]],
      pContent: ['', [Validators.required]],
      pId: ['', []],
      pPic: ['', []],
      pPrice: ['', [Validators.required]],
      pTitle: ['', [Validators.required]],
      pType: ['', [Validators.required]],
      reduceRate: ['', [Validators.required]],
      luck_days: ['', [Validators.required]],
      luck_rank: ['', [Validators.required]],
      sales_name: ['', [Validators.required]],
      sales_desc: ['', [Validators.required]],
      sales_desc_style : ['', [Validators.required]],
      tax_type : ['', [Validators.required]],
      gst_share_amt : ['', [Validators.required]],
      pst_share_amt : ['', [Validators.required]],
      page_name:[null,[]],
      autoship:[0,[]],
      autoship_discount:[0,[Validators.min(0),Validators.max(1)]],
      limited_flag:[0,[]], // 1: only for qualified distributors
      item_no: ['', []],
      SKU: ['', [Validators.required]],
      has_benefit:[0,[]],
      benefit_id: [0, []],
      benefit_value:[0,[]],
      sort_no : [0,[]],
      description : ['',[Validators.required]],
      referral_fee : [0,[]],
      show_category : [0,[]],
    });
    this.productForm.get('autoship').valueChanges
    .subscribe(value => {
      this.update_cart = true;
    })
    this.productForm.get('limited_flag').valueChanges
    .subscribe(value => {
      this.update_cart = true;
    })

    this.productForm.get('has_benefit').valueChanges
    .subscribe(value => {
      if(value == 1 ) {
        this.productForm.get('benefit_id').setValidators(Validators.required);
        this.productForm.get('benefit_value').setValidators(Validators.required);
        this.productForm.markAsPristine();
      } else {
        this.productForm.get('benefit_id').clearValidators();
        this.productForm.get('benefit_value').clearValidators();
      }
      this.productForm.controls['benefit_id'].updateValueAndValidity();
      this.productForm.controls['benefit_value'].updateValueAndValidity();
    });

  }

  addNewProduct(){
    if(this.productForm.status == "VALID"){
      let data = this.productForm.value;
      if (this.hasBenefit == false && data.has_benefit==0) {
        delete data.benefit_id;
        delete data.benefit_name;
        delete data.benefit_value;
      } else {
        data.benefit_name = this.benefit_descript;
      }
      data['update_cart'] = this.update_cart ? 1 : 0;

      this.evgService.addNewProduct(data)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.activeModal.close();
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  modifyProduct(){
    if(this.productForm.status == "VALID"){
      //console.log('modifyProduct this.productForm.value:',this.productForm.value);
      let data = this.productForm.value;
      data['update_cart'] = this.update_cart ? 1 : 0;
      if (this.hasBenefit == false && data.has_benefit==0) {
        delete data.benefit_id;
        delete data.benefit_name;
        delete data.benefit_value;
      } else {
        data.benefit_name = this.benefit_descript;
      }
     // console.log('modifyProduct this.benefit_descript:',this.benefit_descript);
      //console.log('modifyProduct data:',data);

      this.evgService.modifyProduct(data)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.activeModal.close();
          /*

          if(this.selectedFile){
            this.uploadProductImage(this.selectedFile.src);
          }
          */
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  uploadProductImage(imageAsData){
    this.shareinfoService.setCreditProcess(true);
    this.evgService.uploadProductImage({pId : this.product.pId, image: imageAsData})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          //console.log('uploadProductImage resp:',resp);
          if (resp.success) {
            //console.log('uploadProductImage resp.data[0].results:',resp.data[0].results);
            this.productForm.patchValue({
              pPic: resp.data[0].results,
            });
            //console.log('uploadProductImage this.productForm.value:',this.productForm.value);
          }
          this.shareinfoService.setCreditProcess(false);
          this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        this.shareinfoService.setCreditProcess(false);
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.cdr.detectChanges();
      this.uploadProductImage(this.selectedFile.src);

    });

    reader.readAsDataURL(file);
  }


  autoship_click(event){
    this.update_cart = true;
    this.productForm.patchValue({
      autoship : event.checked === true? 1: 0
    });
    this.isInactived = (event.checked === true);
  }

  hasBenefit_click(event){
    this.productForm.patchValue({
      has_benefit : event.checked === true ? 1: 0
    });
    this.hasBenefit = (event.checked === true);
  }

  onSelecBenefitEvent(event:MatSelectChange){
    //console.log('onSelecBenefitEvent event.source.triggerValue:', event.source.triggerValue);
    //console.log('onSelecBenefitEvent event', event);
    this.benefit_descript = event.source.triggerValue;
  }

}
