<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close(null)" alt="close"></span>
        </div>
    </div>
    <div class="row m-1">
        <form [formGroup]="checkoutForm" (keydown.enter)="$event.preventDefault()" class="checkout-form" autocomplete="c-form" id="checkoutFormId">
            <div class="step-header mb-2 bt-2">{{ 'CREDITCARDONLY' | translate }}</div>
            <div class="row contactarea">
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <mat-radio-group
                        aria-labelledby="address-radio-group-label"
                        class="address-radio-group"
                        [(ngModel)]="preferredCreditCard"
                        (ngModelChange)="preferredCreditCardChanged()"
                        [ngModelOptions]="{standalone: true}">
                        <mat-radio-button color="primary" class="address-radio-button" [value]="0" >
                            {{ 'SELECTACTIVECARD' | translate}}
                        </mat-radio-button>
                        <div class="address-list d-flex" *ngIf="preferredCreditCard == 0">
                            <div class="address-card" *ngFor="let creditCard of creditCards; index as i;" [ngClass]="i == selectedCreditCard ? 'address-card-active' : ''">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <mat-radio-group
                                        aria-labelledby="address-radio-group-label"
                                        class="address-radio-group"
                                        [(ngModel)]="selectedCreditCard"
                                        (ngModelChange)="selectedCreditCardChanged()"
                                        [ngModelOptions]="{standalone: true}">
                                            <mat-radio-button class="address-radio-button" [value]="i" [disabled]="creditCard.expired==1">
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div>
                                        <span>{{showCardNo(creditCard.cardNo)}}</span><br>
                                        <span>{{ creditCard.expireDate }}<p style="color:red; margin-left: 0.2rem;font-size: 0.8;">{{creditCard.expired==1 ? ( 'EXPIRED'  | translate ) : '' }}</p> {{ creditCard.cardHolder }}</span>
                                        <span>{{ creditCard.address }}, {{ creditCard.postCode }}</span><br>
                                        <span>{{ creditCard.firstName }}&nbsp;{{creditCard.lastName}}</span>
                                    </div>
                                    <button class="cart-button mr-1" mat-mini-fab color="warn" aria-label="Delete" (click)="deletecreditCard(creditCard.id)">
                                        <mat-icon class="cart-icon">delete</mat-icon>
                                    </button>
                                    <button class="cart-button mr-1" mat-mini-fab color="accent" aria-label="Edit" (click)="editcreditCard(creditCard)">
                                        <mat-icon class="cart-icon">edit</mat-icon>
                                    </button>
                                </div>
                                
                            </div>
                            <div class="text-center p-4 d-flex justify-content-center" *ngIf="creditCards.length <= 0">
                                <h2>
                                    {{ "NOCREDITCARD" | translate }}
                                </h2>
                            </div>
                        </div>
                        
                        <mat-radio-button color="primary" class="address-radio-button" [value]="1" >
                            {{ in_edit_credit_card ? 'UPDATEBTN' : 'NEWCARD' | translate}}&nbsp;{{ in_edit_credit_card ? 'CREDITCARDINFO' : '' | translate}}
                        </mat-radio-button>

                        <div class="form-group" *ngIf="preferredCreditCard == 1 || preferredCreditCard == 2">
                            <mat-form-field style="width: 100%;">
                                <mat-select formControlName="cardType"  type="text" class="form-control text-box" id="cardType" aria-describedby="cardType"
                                    placeholder="{{'CARDTYPE' | translate}}" name="cardType">
                                    <mat-option *ngFor="let cType of cardTypes, let i = index" [value]="cType.type">
                                        {{ cType.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="checkoutForm.get('cardType').hasError('required')">{{'CARDTYPEREQ' | translate}}</mat-error>
                            </mat-form-field>
        
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="cardNo" type="number" class="form-control" id="cardNo" aria-describedby="cardNo"
                                placeholder="{{ 'CARDNO' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('cardNo').hasError('required')" >{{ "CARDNOREQ" | translate }} </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width: 100%;">
                                <!----
                                <input matInput formControlName="expireDate" type="text" class="form-control" id="expireDate" aria-describedby="expireDate"
                                placeholder="{{ 'EXPDATE' | translate }}&nbsp;MMYY" 
                                autocomplete="off">
                                ((0[1-9]|1[0-2])\/d{2})
                                -->
                                <input matInput formControlName="expireDate" type="text" 
                                        pattern="[0-9]{2}\/[0-9]{2}" 
                                        class="form-control" id="expireDate" 
                                        aria-describedby="expireDate"
                                        placeholder="{{ 'CCEXP' | translate }} (MM/YY)" 
                                        (keydown)="onKeydown($event)"
                                        (keyup)="modifyInput($event)"
                                        [(ngModel)]="expiryDateValue"
                                        autocomplete="off"
                                        maxlength="5"
                                    >
                            <mat-error class="error-message" *ngIf="checkoutForm.get('expireDate').hasError('required')" >{{ "EXPDATEREQ" | translate }} </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="cvCode" type="text" class="form-control" id="cvCode" aria-describedby="cvCode"
                                placeholder="{{ 'CVCODE' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('cvCode').hasError('required')" >{{ "CVCODE" | translate }} </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="firstName" type="text" class="form-control" id="firstName" aria-describedby="firstName"
                                placeholder="{{ 'FIRSTNAME' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('firstName').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="lastName" type="text" class="form-control" id="lastName" aria-describedby="lastName"
                                placeholder="{{ 'LASTNAMELABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('lastName').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="address" type="text" class="form-control" id="address" aria-describedby="address"
                                placeholder="{{ 'STEP2DESC' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('address').hasError('required')" >{{ "STEP2DESC" | translate }}&nbsp;{{ 'REQUIRED' | translate }} </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="postCode" type="text" class="form-control" id="postCode" aria-describedby="postCode"
                                placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="checkoutForm.get('postCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-radio-group>
                
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 form-popup-form">
                <div style="text-align: center; margin: auto;">
                <button mat-raised-button class="btn submit-button mr-3"  (click)="activeModal.close(null)" >{{ "CANCELBTN" | translate}}</button>
                <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="preferredCreditCard == 1"
                [disabled]="checkoutForm.get('cardNo').hasError('required') || !checkoutForm.valid ||
                checkoutForm.get('cvCode').hasError('required') ||
                checkoutForm.get('lastName').hasError('required') ||
                checkoutForm.get('firstName').hasError('required') ||
                checkoutForm.get('address').hasError('required') ||
                checkoutForm.get('postCode').hasError('required')"  
                (click)="todoSubmit()" >{{ "SAVE" | translate}}&nbsp;{{"AND" | translate }}&nbsp;{{"RETURN" | translate }}</button> <!-- NEXTSTEP -> MAKEPAYMENT-->

                <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="preferredCreditCard == 0"
                [disabled]="selectedCreditCard < 0"  
                (click)="saveSelectedCard()" >{{ "SAVE" | translate}}&nbsp;{{"AND" | translate }}&nbsp;{{"RETURN" | translate }}</button> <!-- NEXTSTEP -> MAKEPAYMENT-->
                </div>
            </div>
        </form>
    </div>
</div>
