import { Component, OnInit, Input } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../../login/login.component';
import { RegisterComponent } from '../../register/register.component';

@Component({
  selector: 'app-view-hydrogen-energy',
  templateUrl: './view-hydrogen-energy.component.html',
  styleUrls: ['./view-hydrogen-energy.component.scss']
})
export class ViewHydrogenEnergyComponent implements OnInit {
  @Input() addCloseBut : boolean=false;
  @Input() translate : TranslateConfigService;
  public isMobileMenuOpen:boolean  = false;

  constructor( 
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
    private shareinfoService : ShareinfoService,
    public router : Router) { }


  ngOnInit(): void {
  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }


  openPageWithRedirection(pId, parameters) {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = false;
    }
    
    //console.log('openPageWithRedirection pId, parameters:',pId, parameters);
    if (pId && pId === "login") {
      const modalRef = this.ngDialog.open(LoginComponent, { windowClass: 'login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.parameters = parameters;
    } else if (pId === "register") {
      const modalRef = this.ngDialog.open(RegisterComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
    } else {
      this.router.navigate([pId]);
    }
  }


}
