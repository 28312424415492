import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ProfileUpdatedHistoryComponent } from 'src/app/modules/profile-updated-history/profile-updated-history.component';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserManagerComponent implements OnInit {

  public userManagerForm : FormGroup;
  public userManagerUpdateForm : FormGroup;
  public user : User;
  public userBonus;
  public userOrders;
  public selectedShowMenus:number=0;
  public showMenusModified:number=0;
  public askQuestions:number=0;
  public myAutoship:any=[];
  public inSetProcess:boolean=false;
  private _unsubscribe$ = new Subject<any>();
 // readonly myAutoship: Observable<[]>;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.userManagerForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
    });
    this.userManagerUpdateForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
      first_name : ['', [Validators.required]],
      last_name : ['', [Validators.required]],
      email : ['', [Validators.required, Validators.email]],
      password : ['', []],
    });

  }

  onSearchRequest()
  {
    if(this.userManagerForm.valid){
      this.evgService.getUserProfileDetail(this.userManagerForm.value).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp.data[0].results.length > 0) {
          this.responseHandlerService.handleResponse(resp);
          this.user = resp.data[0].results[0];
          this.userManagerUpdateForm.patchValue({
            first_name : this.user.first_name,
            last_name : this.user.last_name,
            email : this.user.email,
            userId : this.user.id
          });
          this.selectedShowMenus = this.user.showMenus;
          this.askQuestions = this.user.askSecretQ;
          //console.log(this.user);
          this.getUserBonus(this.user.id);
          this.getUserOrders(this.user.id);
          this.getUserAutoshipProducts(this.user.id);
        }else{
          this.responseHandlerService.handleCustomResponse(this.translate.translate.instant('USERNOTFOUND'),'warning')
        }
        //this.cdr.detectChanges();
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }

  getUserBonus(userId){
    this.evgService.getBonusByAdmin({userId : userId}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp.data[0]) {
        this.userBonus = resp.data[0].results;
      }else{
        this.responseHandlerService.handleCustomResponse(this.translate.translate.instant('USERNOTFOUND'),'warning')
      }
      this.cdr.detectChanges();
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getUserOrders(userId){
    this.evgService.getUserOrdersByAdmin({userId : userId}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp.data[0]) {
        this.userOrders = resp.data[0].results;
      }else{
        this.responseHandlerService.handleCustomResponse(this.translate.translate.instant('USERNOTFOUND'),'warning')
      }
      this.cdr.detectChanges();
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onUpdateRequest()
  {
    if(this.userManagerUpdateForm.valid){
      let data = this.userManagerUpdateForm.value;

      var filtered_data

      if(data.password){
        filtered_data = {
          userId : data.userId,
          first_name : data.first_name,
          last_name : data.last_name,
          email : data.email,
          password : data.password
        };
      }else{
        filtered_data = {
          userId : data.userId,
          first_name : data.first_name,
          last_name : data.last_name,
          email : data.email
        };
      }
      
      this.evgService.updateUserProfile(filtered_data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
        }
        this.cdr.detectChanges();
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  openHistorylist(){
    let userId = this.userManagerForm.get('userId').value;
    //console.log('call ProfileUpdatedHistoryComponent openHistorylist userId:',userId);
    
    const modalRef = this.ngDialog.open(ProfileUpdatedHistoryComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.userId = userId;
    modalRef.result.then((result) => {
      //console.log(result);
    });
  }

  changedShowMenu(event) {
    //console.log('changedShowMenu event.target.value: ',event.target.value);
    //console.log('changedShowMenu event: ',event);
    this.selectedShowMenus = event.target.value;

    this.showMenusModified=1;

  }

  changedAskQuestions(event) {
    //console.log('changedAskQuestions event.target.value: ',event.target.value);
    //console.log('changedAskQuestions event: ',event);
    this.askQuestions = event.target.value;

    this.showMenusModified=1;

  }

  setShowMenu()
  {
    if(this.showMenusModified && this.userManagerUpdateForm.get('userId').value){
    //console.log('setShowMenu this.selectedShowMenus:',this.selectedShowMenus);
    //console.log('setShowMenu this.askQuestions:',this.askQuestions);
    this.askQuestions
      
      this.evgService.setShowMenueByUserId({showMenus: this.selectedShowMenus, userId : this.userManagerUpdateForm.get('userId').value, askSecretQ : this.askQuestions}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.showMenusModified=0;
          this.responseHandlerService.handleResponse(resp);
        }
        this.cdr.detectChanges();
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  getUserAutoshipProducts( setUserId,hasDelay:boolean=false){
    this.myAutoship=[];
    this.inSetProcess=true;
    this.evgService.getUserAutoshipProducts({userId : setUserId}).pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.myAutoship = resp.data[0].results;
            //console.log('getUserAutoshipProducts this.myAutoship:',this.myAutoship);
            if ( ! (this.myAutoship && this.myAutoship.length>0)) {
              this.myAutoship=[];
            }
          }
        }
        this.inSetProcess=false;
        this.cdr.detectChanges();
      },(errorResult) => {
        this.inSetProcess=false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  onChangeAutoship(selectedAutoshipItem,selectedIndex) {
    if (selectedAutoshipItem) {
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('CONTINUEDO');
      modalRef.result.then((result) => {
        if(result){
          this.myAutoship=[];
          //selectedAutoshipItem["userId"]= this.user.id;
          this.evgService.setAutoshipByID(selectedAutoshipItem)
          .subscribe((resp) => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if (resp.success) {
                //console.log('onChangeAutoship selectedIndex :  selectedAutoshipItem:',selectedIndex, selectedAutoshipItem);
                //console.log('onChangeAutoship this.myAutoship[selectedIndex]:',this.myAutoship[selectedIndex]);
                selectedAutoshipItem.inactive = 1- selectedAutoshipItem.inactive;

                //this.myAutoship[selectedIndex].inactive = 1- this.myAutoship[selectedIndex].inactive;
              }
              this.getUserAutoshipProducts(this.user.id, true);
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
              
        }
      });
    }

  }

}
