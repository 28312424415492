<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        <div class="banner-detail2 mb-3"><h2>{{ 'RESTORTIT' | translate }}</h2></div>
        <div class="banner-detail1 mb-3"><p>{{ 'RESTORDETAIL' | translate }}</p></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="what-product-detail">
        <div class="tital_line">
            <h1 class="why-acid-head" translate>RESTORSECT1TIT</h1>
        </div>  
        <div class="row content-row">
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>RESTORSECT1ST1 &#45;</span>
                        <span class="why-detail" translate>RESTORSECT1SD1</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>RESTORSECT1ST2 &#45;</span>
                        <span class="why-detail">{{'RESTORSECT1SD2' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>RESTORSECT1ST3 &#45;</span>
                        <span class="why-detail" translate>RESTORSECT1SD3</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>RESTORSECT1ST4 &#45;</span>
                        <span class="why-detail">{{'RESTORSECT1SD4' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>RESTORSECT1ST5 &#45;</span>
                        <span class="why-detail">{{'RESTORSECT1SD5' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-item-benefits">
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-title mb-1" translate>RESTORSECT2TIT</p>
                <p class="card-text" translate>RESTORSECT2SD1</p><br>
                <p class="card-text" translate>RESTORSECT2SD2</p><br>
                <p class="card-text" translate>RESTORSECT2SD3</p><br>
                <p class="card-text" translate>RESTORSECT2SD4</p><br>
                <p class="card-text" translate>RESTORSECT2SD5</p><br>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 item-img">
            <div class="overlay"></div>
        </div>
    </div>
    <app-introduce-part [contensInfo]="introduceInfo"></app-introduce-part>
    
    <!----
    <div class="introduce">
        <div class="introduce-body row">
            <div class="col-lg-6 col-sm-12 col-part">
                <h1 class="introduce-head" translate>RESTORSECTCLSTIT</h1>
                <div class="separator mt-1 mb-3"></div>
                <div class="introduce-text">{{ 'RESTORSECTCLSD' | translate }}</div>
            </div>
            <div class="col-lg-6 col-sm-12 col-part">
                <h1 class="introduce-head" translate>INGREDIENTSTIT</h1>
                <div class="separator mt-1 mb-3"></div>
                <div class="introduce-text">{{ 'RESTORINGRE' | translate }}</div>
            </div>
        </div>
    </div>
    -->
</div>
