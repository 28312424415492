import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferRecord, TransferRecordDetail } from 'src/app/modules/common/Accounting';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-transfer-monthly-record',
  templateUrl: './transfer-monthly-record.component.html',
  styleUrls: ['./transfer-monthly-record.component.scss']
})
export class TransferMonthlyRecordComponent implements OnInit {
  public user: User;
  public transferRecords : TransferRecord[] = [];
  public selectedTransferRecordDetails : TransferRecordDetail[] = null;
  private _unsubscribe$ = new Subject<any>();
  public selectedRecord : string = null;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.userTransferRecords();
  }

  selectRecord(userId){
    this.userTransferDetail(userId);
  }

  unSelectRecord()
  {
    this.selectedRecord = null;
    this.selectedTransferRecordDetails = null;
    this.userTransferRecords();
  }

  userTransferRecords()
  {
    this.evgService.userTransferRecords( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.transferRecords = resp.data[0].results;
          //console.log("userTransferRecords",this.transferRecords );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  userTransferDetail(userId)
  {
    this.evgService.userTransferDetail( {userId : userId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.selectedTransferRecordDetails = resp.data[0].results;
          //console.log("userTransferDetail",this.selectedTransferRecordDetails );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
