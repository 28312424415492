export class InvoicePayment {
    id : number;
    invoice_id: number;
    amount : number;
    card_no : string;
    holder : string;
    expiry_date : string;
    cvcCode : string;
    country: string;
    province: string;
    city: string;
    address: string;
    post_code: string;
    same_address:number;
    shipping_country: string;
    shipping_province: string;
    shipping_city: string;
    shipping_address: string;
    shipping_post_code: string;
    AddTime : String;
}
