
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NightCreamComponent } from './night-cream.component';
import { NightCreamRoutingModule } from './night-cream-routing.module';
import { IntroducePartModule } from "../common/introduce-part/introduce-part.module";


@NgModule({
    declarations: [NightCreamComponent],
    exports: [NightCreamComponent],
    imports: [
        CommonModule,
        NightCreamRoutingModule,
        NgbModule,
        NgbNavModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntroducePartModule
    ]
})
export class NightCreamModule { }
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
