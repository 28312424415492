// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url:'',
  cart_buyer:8888,
  hostname:'http://bytehealthmanagement.com',
  //hostname:'http://localhost:8888',
  localhost: 'http://127.0.0.1:8888',
  lightBox_url : "https://demo.convergepay.com/hosted-payments/PayWithConverge.js",
  //shoppingCart : 'http://cart.bytehealthmanagement.com',
  shoppingCart : 'http://bytehealthmanagement.com',
  recaptcha: {
    siteKey: '6LeczBseAAAAAAwM4SN3Tol9AcmlMfO7QOFQ80_k',
  },
  paypal_client_id : 'AdFxdeWWdS9hJO6Mpnfrgxw-1ZnUUYixUu_hvnYzgtJbVTqV8RdEILPPdcf-nLka38gQQqcxd88KDSTL',
  stripe_client_key : 'pk_test_51OwY1O02v9wcLegeiNYvkZDMMJHA9YV1bWOOCGtuWLQYVrTZCWXrmwH0j3jbWCamuoSShwGhoslr6qkhyq9JeUn900NsPL8gfF',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
