<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title">
        <h3>{{ 'WASHERH1' | translate}}<br>{{ 'WASHERH2' | translate}}<br>{{ 'WASHERH3' | translate}}</h3>
        <div class="line"></div>
        <p class="desc">{{ 'WASHERP1' | translate}}<br>{{ 'WASHERP11' | translate}}<br>{{ 'WASHERP12' | translate}}<br>{{ 'WASHERP2' | translate}}<br>{{ 'WASHERP3' | translate}}</p>
        <a class="btn banner-button" >{{ 'HLEARNM' | translate }} >></a>
    </div>

    <div class="foodborne">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
            <div style="width: 400px;">
                <p class="p-5">
                    {{ 'WASHERP4' | translate }}
                    <button class="mt-2 btn banner-button" >{{ 'HLEARNMN' | translate }}</button>
                </p>
                
                
            </div>
            
            <div style="width: 600px;">
                <iframe title="vimeo-player" src="https://www.youtube.com/embed/E6uQXOqupkA" style="width: 100%;padding: 20px;" height="350" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="food-produce">
        <div class="title">
            <h3>
                {{ 'WASHERH4' | translate }}
            </h3>
            <h2>
                {{ 'WASHERH5' | translate }}
            </h2>
            <p>
                {{ 'WASHERH6' | translate }}
            </p>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/washer_pic2.jpg" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH7' | translate }}
                    </h2>
                    <p>
                        {{ 'WASHERH8' | translate }}
                    </p>
                </div>
            </div>

            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/washer_pic3.jpg" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH9' | translate }}
                    </h2>
                    <p>{{ 'WASHERH10' | translate }}</p>
                </div>
            </div>

            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/washer_pic4.jpg" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH11' | translate }}
                    </h2>
                    <p>{{ 'WASHERH12' | translate }}</p>
                </div>
            </div>
        </div>
    </div>


    <div class="middle-banner d-flex justify-content-center align-items-center">
        <div class="title">
            {{ 'WASHERH13' | translate }}
        </div>
    </div>


    <div class="middle-bottom-banner">
    </div>
    <div class="sanitizer">
        <div class="d-flex justify-content-center flex-wrap">
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/1.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH14' | translate }}
                    </h2>
                    <p>
                        {{ 'WASHERH15' | translate }}
                    </p>
                </div>
            </div>
            
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/2.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH16' | translate }}
                    </h2>
                    <p>
                        {{ 'WASHERH17' | translate }}
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/3.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH18' | translate }}
                    </h2>
                    <p>
                        {{ 'WASHERH19' | translate }}
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/4.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH20' | translate }}
                    </h2>
                    <p>
                        {{ 'WASHERH21' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="box">
                {{ 'WASHERH22' | translate }} <br> {{ 'WASHERH222' | translate }}
            </div>
            
        </div>
        <!-- <div class="d-flex justify-content-center">
            <button class="mt-5 btn banner-button" >{{ 'READMOREFACTS' | translate }}</button>
        </div> -->
        
    </div>

    <div class="how-work">
        <div class="title">
            <h2>
                {{ 'WASHERH23' | translate }}
            </h2>
            <p>
                {{ 'WASHERH24' | translate }}
            </p>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/s1.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH25' | translate }}
                    </h2>

                    <div class="card-line"></div>
                    <p>
                        {{ 'WASHERH26' | translate }}
                    </p>
                </div>
            </div>

            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/s2.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH27' | translate }}
                    </h2>
                    <div class="card-line"></div>
                    <p>{{ 'WASHERH28' | translate }}</p>
                </div>
            </div>

            <div class="card">
                <img class="card-img-top" src="./../../../../assets/images/files/washer/s3.png" alt="Card image cap">
                <div class="card-body">
                    <h2>
                        {{ 'WASHERH29' | translate }}
                    </h2>
                    <div class="card-line"></div>
                    <p>{{ 'WASHERH30' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="title">
            <p>
                {{ 'WASHERH31' | translate }}
            </p>
            <h2>
                {{ 'WASHERH32' | translate }}
            </h2>
           
        </div>
        <div class="row" style="padding: 0;margin: 0;">
            <div class="col-sm-6">
                <div style="max-width: 600px;margin-left: auto;margin-right: 0;text-align: center;">
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/498494735?h=93699b32f9" style="width: 100%;padding: 20px;" height="362" frameborder="0" allowfullscreen></iframe>
                    
                </div>
            </div>
            <div class="col-sm-6">
                <div style="max-width: 600px;margin-left: 0;margin-right: auto;text-align: center;">
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/632481922?h=93699b32f9" style="width: 100%;padding: 20px;" height="362" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        

        


        
    </div>

    <div class="amazon">
        <div class="title">
            <h2>
                {{ 'WASHERH33' | translate }}
            </h2>
           
        </div>
        <div id="carouselExampleFade" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active " data-interval="2000">
                    <div class="d-flex justify-content-center flex-wrap" >
                        <div class="col-md-4">
                            <div class="card shadow rounded" >
                                <div class="card-body">
                                    <h2>I have not gotten sick since using it!</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        Before I brought the food sanitizer, after eating salmon, I got sick and had to go to the hospital. Now all the seafood I eat I clean with the Amazing Water Food Sanitizer. I have not gotten sick. I am a believer In the Amazing Water Food Sanitizer. I recommend that everyone should have one!
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Amanda (West Vancouver, BC)</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>My fruit & vegetables are tastier!</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        I'm always used to eating organic food. Since I brought the food sanitizer, I noticed my fruit and vegetables are tastier.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Ann-Marie (New York)</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>I love it!</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        I have had the food sanitizer for six months. I love it! My fruit and vegetables last longer.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Barbara (Los Angeles, CA)</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                </div>
                <div class="carousel-item " data-interval="2000">
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>The veggies seem to last longer!</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        My favorite part of the machine is that the veggies seem to last longer and does an awesome job on the mushrooms. With the meat, I see some white bubbles in the water.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Sharon (Boston, MA)</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>I used to get stomach cramps...</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        I used to get stomach cramps regularly but since I started to use the food sanitizer, I don’t get them as much as I used to and fruit and raw veggies taste much, much better.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Anna (San Fransisco, CA)</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>I trust what it does.</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        I use it for everything! I love it! I will never cook anything without using the food sanitizer. I trust what it does.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Vivian (Chicago, IL)</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                </div>


                <div class="carousel-item " data-interval="2000">
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>Thumbs up sanitizer!</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        At first I was afraid to use the sanitizer. What an amazing job on the meat! Thumbs up sanitizer!
                                    </p>
                                    <div class="d-flex justify-content-center"><small>John (Miami, FL)</small></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card shadow rounded">
                                <div class="card-body">
                                    <h2>I recommend everyone use it.</h2>
                                    <div class="rating">
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                        <mat-icon>
                                        star
                                        </mat-icon>
                                    </div>
                                    <p>
                                        What a job on lettuce! A more crunchy taste! The fruit has a fruitier taste! I recommend everyone use it.
                                    </p>
                                    <div class="d-flex justify-content-center"><small>Shawn (Seattle, WA)</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                </div>
                
            </div>
        </div>
        <!-- <div class="d-flex justify-content-center">
            <img class="img" src="./../../../../assets/images/files/washer/amazon.png" alt="Card image cap">
        </div> -->
       
    </div>
</div>
