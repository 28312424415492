
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HydratingSerumRoutingModule } from './hydrating-serum-routing.module';

import { NgbModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HydratingSerumComponent } from './hydrating-serum.component';
import { IntroducePartModule } from "../common/introduce-part/introduce-part.module";


@NgModule({
    declarations: [HydratingSerumComponent],
    exports: [HydratingSerumComponent],
    imports: [
        CommonModule,
        HydratingSerumRoutingModule,
        NgbModule,
        NgbNavModule,
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntroducePartModule
    ]
})
export class HydratingSerumModule { }
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
