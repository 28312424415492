import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AySetting, BonusPool } from 'src/app/modules/common/PerformanceGrowthBonusSetting';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-additional-bonus-funds-pool',
  templateUrl: './additional-bonus-funds-pool.component.html',
  styleUrls: ['./additional-bonus-funds-pool.component.scss']
})
export class AdditionalBonusFundsPoolComponent implements OnInit {

  public user: User;
  public aySetting : AySetting;
  public bonusPool : BonusPool;
  public aySettingForm : FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
 
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
  ) { }

  ngOnInit(): void {
    this.aySettingForm = this.formBuilder.group({
      ayb_pool : ['', [Validators.required]],
      ayb_nums : ['', [Validators.required]],
    });
    this.getUser();
    this.getBonusSetting();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getBonusSetting()
  {
    this.evgService.getBonusSetting( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.aySetting = resp.data[0].results[0];
          //console.log("getBonusSetting",this.aySetting );
          this.aySettingForm.patchValue({
            ayb_pool : this.aySetting.ayb_pool,
            ayb_nums : this.aySetting.ayb_nums,
          })
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  setBonusPool(data : any)
  {
    if(this.aySettingForm.status === "VALID"){
      this.isProcessing = true;
      data.ayb_pool = this.aySettingForm.get('ayb_pool').value;
      data.ayb_nums = this.aySettingForm.get('ayb_nums').value;
      this.evgService.setBonusPool( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          if(resp.data[0]){
            //console.log("setBonusPool",resp);
            this.isProcessing = false;
            this.responseHandlerService.handleResponse(resp);
          }else{
            this.isProcessing = false;
          }
        }else{
          this.isProcessing = false;
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }


}
