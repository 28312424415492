import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Message } from '../common/Message';
import { User } from '../common/User';

@Component({
  selector: 'app-receive-coins',
  templateUrl: './receive-coins.component.html',
  styleUrls: ['./receive-coins.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiveCoinsComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() message : Message;
  public user : User;
  public receiveCoinsForm: FormGroup;
  public resetLinkSent : boolean = false;
  public incorrect = 0;

  private _unsubscribe$ = new Subject<any>();

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,

  ) { }


  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    this.receiveCoinsForm = this.formBuilder.group({
        answer: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  receiveCoins() {
    let data ={
      id : this.message.id,
      amount : this.message.amount,
      answer : this.receiveCoinsForm.value.answer,
      times : this.incorrect
    };

    this.evgService.receiveEvCoin( data ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
       
        if(resp.success){
          this.shareinfoService.reloadNewMessages();
          this.shareinfoService.reloadUser();
          this.activeModal.close('Close click');
        }else{
          if(resp.data[0].results.incorrect_answer){
            this.incorrect++;
          }
          if(resp.data[0].results.cancelled){
            this.activeModal.close('Close click');
            this.shareinfoService.reloadNewMessages();
            this.shareinfoService.reloadUser();
          }
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

}
