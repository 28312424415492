
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FirmingCreamComponent } from './firming-cream.component';
import { FirmingCreamRoutingModule } from './firming-cream-routing.module';
import { IntroducePartModule } from "../common/introduce-part/introduce-part.module";


@NgModule({
    declarations: [FirmingCreamComponent],
    exports: [FirmingCreamComponent],
    imports: [
        CommonModule,
        FirmingCreamRoutingModule,
        NgbModule,
        NgbNavModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntroducePartModule
    ]
})
export class FirmingCreamModule { }
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
