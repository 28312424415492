<div class="form-popup-container">
    <div class="card">
        <div class="card-header">
            <div class="row headrow">
                <div class="col-10 form-popup-header">
                  <span></span>
                  <h3> &nbsp;</h3>
                </div>
                <div class="col-2 form-popup-header">
                  <span></span>
                  <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
                      (click)="activeModal.close('Close click')" alt="close"></span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form [formGroup]="receiveCoinsForm" (keydown.enter)="$event.preventDefault()" *ngIf="!message.canceled" class="submitform" autocomplete="rc-form">
                <div ngbAutofocus class="tatlestyle">{{ "RECEIVEBYTETOKENS" | translate}}</div>
                <div class="row contactarea">
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div class="form-group">
                            <mat-form-field style="width: 100%;">
                                <input matInput type="text" class="form-control text-box" id="amount" aria-describedby="amount"
                                placeholder="{{ 'AMOUNT' | translate }}" name="amount" [value]="'$ ' + message.amount" disabled
                                autocomplete="off">
                                
                            </mat-form-field>
                        </div>
    
                        <div class="form-group">
                            <mat-label>{{ 'QUESTION' | translate }}</mat-label>
                            <p style="width: 100%;">
                                
                            {{ message.question }}
                            </p>
                        </div>
                        <div class="form-group">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="answer" type="text" class="form-control text-box" id="InputPW" aria-describedby="passwordHelp"
                                placeholder="{{ 'ANSWER' | translate }}" name="password" maxlength="60"
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="receiveCoinsForm.get('answer').hasError('required')" >{{ "ANSWERISREQ" | translate }} </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div style="text-align: center; margin: auto;">
                      <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!receiveCoinsForm.valid" (click)="receiveCoins()" >{{ "SUBMIT" | translate}}</button>
                    </div>
                </div>
                
            </form>
            <div class="text-center" *ngIf="message.canceled">
                <h2>
                    {{ 'TRANSCANCELLED' | translate }} {{ message.canceled_at }}
                </h2>
                <div style="text-align: center; margin: auto;">
                    <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.close('Close click');" >{{ "CLOSEBTN" | translate}}</button>
                  </div>
            </div>
        </div>
    </div>
    
</div>