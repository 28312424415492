import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { InputDialogComponent } from 'src/app/modules/input-dialog/input-dialog.component';
import { SharedUtilService } from 'src/app/services/shared-util.service';

@Component({
  selector: 'app-user-request-list',
  templateUrl: './user-request-list.component.html',
  styleUrls: ['./user-request-list.component.scss']
})
export class UserRequestListComponent implements OnInit {
  public isProcessing2 = false;
  public user: User;
  public userType:string='user';
  public requests : UserRequest[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    public sharedUtilService:  SharedUtilService,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.requestsForm = this.formBuilder.group({
      infoType: [0, [Validators.required]],
    });
    this.getUser();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.userType = this.sharedUtilService.checkUserPermissions(this.user.id);
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getRequestlist(data : any)
  {
    this.isProcessing = true;
    this.evgService.getRequestlist( {infoType :data.infoType} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.requests = resp.data[0].results;
          
          //console.log("getRequestlist",this.requests );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }



  onGetRequests()
  {
    if(this.requestsForm.status == "VALID"){
        this.getRequestlist(this.requestsForm.value);
    }

  }

  getRequestType(type){
     switch(type){
       case 0 : return "Normal Request";
       case 1 : return "Product Troubleshooting";
       case 2 : return "Cancel Order";
       case 3 : return "Change Schedule to Install Product";
       default : return "Normal Request";
     }
  }

  getAType(type){
    switch(type){
      case 1 : return "Withdrawal Amount";
      case 2 : return "Withdraw From AYB Pool";
      default : return "Not Applicable";
    }
 }

  getStatus(obj:any){
    switch(obj.isClosed){
      case 0 : return "PENDINGLABEL";
      case 1 :
        if (obj.result=='canceled') {
          return "CANCELLED";
        }else {
          return "CLOSED";
        }
      default : return "Not Applicable";
    }
  }

  reopenRequest(obj:UserRequest){
    if (obj.isClosed==1){
      const modalRef = this.ngDialog.open(InputDialogComponent, { windowClass: 'input-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.fieldName = 'REASON';
      modalRef.result.then((data) => {
        if(data){
          this.isProcessing2 = true;
          this.evgService.reopenRequest( {ticketNo : obj.ticketNo, id: obj.id, requestType:1, reopenReason : data} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
            if (resp) {
              this.onGetRequests();
              this.responseHandlerService.handleResponse(resp);
              //this.getRefundRequests();
            }
            this.isProcessing2 = false;
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.isProcessing2 = false;
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }); 
        }
      }, (reason) => {
        //console.log(reason);
      });
  
    }
 }

}
