import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHeaderscroll]'
})
export class HeaderscrollDirective {

  
  constructor(el: ElementRef) {
    
  
  }

}
