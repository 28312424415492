<div class="infor-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <h2 class="title">{{ 'UPHISTORY' | translate }}</h2>
            </div>

            <div id="infocontent">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">{{'ID' | translate}}</th>
                            <th scope="col">{{'USER' | translate}}</th>
                            <th scope="col">{{'ADMINID' | translate}}</th>
                            <th scope="col">{{'CONTENT' | translate}}</th>
                            <th scope="col">{{'ADDTIME' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of historyList; index as i;">
                            <td>{{ item.id }}</td>
                            <td>{{ item.userId }}</td>
                            <td>{{ item.byAdminId }}</td>
                            <td class="content">{{ item.content }}</td>
                            <td>{{ item.creaded_at }}</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
        <div class="col-12 form-popup-form">
            <div style="text-align: center; margin:2rem auto;">
                <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.close('close')" >{{ "CLOSED" | translate}}</button>
            </div>
        </div>

    </div>
</div>
