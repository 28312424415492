<div class="page-container">
    <div class="row" style="margin:1.5rem auto;">
        <div class="row col-12 ml-1" style="padding-top:1rem; margin: 1rem auto;" *ngIf="!inPrinting">
            <button type="button" class="btn btn-default btn-sm ml-1" (click)="onPrintInvoice()">
                <i class="fa fa-print" aria-hidden="true">{{ 'DOWNLOAD' | translate }}</i>
            </button>
            <button type="button" class="btn btn-default btn-sm ml-1" (click)="activeModal.close('close')">
                <i class="fa fa-window-close" aria-hidden="true">{{ 'CLOSEBTN' | translate }}</i>
            </button>
    
        </div>
    </div>
    <div id="pdfTableInvoice" #pdfTableInvoice>
        <div class="page-header">
            <div>
                <div style="min-width: 70px; height: auto;float: left; margin:0.6rem;">
                    <img src="https://bytemanage.com/assets/images/logo/small_logo.png" alt="">
                </div>
                <div style="float: right; margin:0.6rem; font-size: 1.2rem;">
                    <div>
                        <div>INVOICE</div>
                        <div>{{invoiceInfo.invoice_no}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="row col-12">
                <div class="col-6">
                    {{ invoiceInfo.customer }}
                </div>
                <div class="col-3">
                    DATE
                </div>
                <div class="col-3">
                    {{ invoiceInfo.AddTime }}
                </div>
            </div>
            <div class="row col-12">
                <div class="col-6">
                    {{ invoiceInfo.address }}
                </div>
                <div class="col-3">
                    PAYMENT TERMS
                </div>
                <div class="col-3">
                    Payment Before Delivery
                </div>
            </div>
            <div class="row col-12">
                <div class="col-6">
                    {{ invoiceInfo.city }},{{ invoiceInfo.province }},{{ invoiceInfo.country }}
                </div>
                <div class="col-3">
                    &nbsp;
                </div>
                <div class="col-3">
                    &nbsp;
                </div>
            </div>
            <div class="row col-12">
                <div class="col-6">
                    {{ invoiceInfo.post_code }}
                </div>
                <div class="col-3">
                    BALANCE DUE
                </div>
                <div class="col-3">
                    NOW
                </div>
            </div>
            <div class="row">
                <div class="row col-12 m-1" style="background-color: black; color:white;">
                    <div class="col-5">
                        ITEM                    
                    </div>
                    <div class="col-2">
                        QUANTITY                    
                    </div>
                    <div class="col-2">
                        RATE                    
                    </div>
                    <div class="col-3">
                        AMOUNT                    
                    </div>
                </div>
                <div class="row col-12 m-1">
                    <div class="col-5">
                        {{ invoiceInfo.pTitle }}                    
                    </div>
                    <div class="col-2">
                        {{ invoiceInfo.quantity }}                    
                    </div>
                    <div class="col-2">
                        {{ invoiceInfo.unit_price }}                    
                    </div>
                    <div class="col-3">
                        {{ invoiceInfo.unit_price * invoiceInfo.quantity }}                    
                    </div>
                </div>
                <div class="row col-12 m-1">
                    <div class="col-5">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        SUBTOTAL
                    </div>
                    <div class="col-3">
                        {{ invoiceInfo.unit_price * invoiceInfo.quantity }}                    
                    </div>
                </div>
                <div class="row col-12 m-1">
                    <div class="col-5">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        TAX
                    </div>
                    <div class="col-3">
                        {{ invoiceInfo.tax_amt }}                    
                    </div>
                </div>
                <div class="row col-12 m-1">
                    <div class="col-5">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        &nbsp;                    
                    </div>
                    <div class="col-2">
                        AMOUNT
                    </div>
                    <div class="col-3">
                        {{ invoiceInfo.amount }}                    
                    </div>
                </div>
            </div>
            <div class="row footer">
                <div class="col-12">
                    GST#:
                </div>
                <div class="col-12" style="font-weight: bold;">
                    Notes :
                </div>
                <div class="col-12">
                    The US shipping fees will be paid by Byte
                </div>
                <div class="col-12">
                    The shipping fees to VN are not cover on this invoice
                </div>
                <div class="col-12" style="font-weight: bold;">
                    Terms :
                </div>
                <div class="col-12">
                    Payment before delivery
                </div>
            </div>
        </div>
    </div>
</div>