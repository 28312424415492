<div class="withdrawal-request-container">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                        <div class="p-3">
                            <h2 ngbAutofocus #pagetitle>{{ 'WITHDRAWALREQUEST' | translate }}</h2>
                        </div>
                        <div  *ngIf="step_no == 0">
                            <div class="step-header mb-2">{{ 'WITHDRAWAMOUNT' | translate }}</div>
                            <form #WithdrawalRequestF="ngForm" [formGroup]="withdrawalRequestForm" autocomplete="wr-form">
                            <div class="form-group">
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput  type="text" class="form-control text-box" id="available_amount" [value]="'$ ' + user.eWallet"
                                    name="available_amount"  placeholder="{{ 'AVAILAMOUNT' | translate }}" disabled
                                    autocomplete="off"
                                    >
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="withdraw_amount"  type="number" class="form-control text-box" id="withdraw_amount"
                                    name="withdraw_amount"  placeholder="{{ 'WITHDRAWAMOUNT' | translate }}"
                                    autocomplete="off"
                                    >
                                    <p class="text-danger small" *ngIf="withdrawalRequestForm.get('withdraw_amount').hasError('required')">{{'WITHDRAWAMOUNTREQ' | translate}}</p>
                                    <p class="text-danger small" *ngIf="withdrawalRequestForm.get('withdraw_amount').hasError('min')">{{'AMOUNTMIN50' | translate}}</p>
                                    <p class="text-danger small" *ngIf="withdrawalRequestForm.get('withdraw_amount').hasError('maxWithdrawalAmt')">{{'MAXWITHDRAWALAMT' | translate}}{{maxWithdrawalAmt}}</p>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="transferFee" type="number" class="form-control text-box" id="transferFee" 
                                    name="transferFee"  placeholder="{{ 'TRANSFERFEE' | translate }}" 
                                    autocomplete="off"
                                    >
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <mat-hint>{{ 'TRADEPASSWORDHINT' | translate }}</mat-hint>
                                        <input matInput formControlName="tradePassword"  type="password" class="form-control text-box" id="tradePassword"
                                        name="tradePassword"  placeholder="{{ 'TRADEPASSWORD' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('tradePassword').hasError('required')">{{'TRADEPASSWORDREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    <span class="tradePassNotice" *ngIf="tradePass_error">{{ 'TRADEPASSWORDHINT' | translate }}</span>
                                </div>
                            </div>
                            <br>
                            <div class="col-sm-12 col-xs-12 form-popup-form">
                                <div style="text-align: center; margin: auto;">
                                  <button mat-raised-button color="primary" class="btn submit-button" [disabled]="withdrawalRequestForm.get('withdraw_amount').hasError('required') || withdrawalRequestForm.get('tradePassword').hasError('required')" (click)="verifyDealPass()" >{{ "NEXTSTEP" | translate}}</button>
                                </div>
                            </div>
                        </div>
                            </form>
                       </div>
                        <div *ngIf="step_no == 1">
                            <h2 ngbAutofocus class="step-header mb-2">{{ 'BANKINFO' | translate }}</h2>
                            <p class="text-center text-warning">{{'BANKINFOREMINDER' | translate}}</p>
                            <div class="row">
                                <div class="col-sm-2"><button  class="btn btn-link" (click)="setWithDrawalType(0)">SWIFT</button></div>
                                <div class="col-sm-4"><button  class="btn btn-link" (click)="setWithDrawalType(1)">ACH ( for US customers only )</button></div>
                            </div>
                            
                            <div *ngIf="withdrawal_type==0">
    
                            <form #WithdrawalRequestF="ngForm" [formGroup]="withdrawalRequestForm" autocomplete="wr-form">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <mat-form-field style="width: 100%;">
                                        <mat-select name="bankInfoSwift" formControlName="bankInfoSwift" placeholder="SWIFT Info">
                                            <mat-option *ngFor="let option of bankInfoSwiftOptions" [value]="option.value">
                                                {{ option.label }}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="full_name_of_account_holder"  type="text" class="form-control" id="full_name_of_account_holder" aria-describedby="full_name_of_account_holder"
                                        name="full_name_of_account_holder" placeholder="{{ 'FULLNAMEOFACCOUNTHOLDER' | translate }}"
                                        autocomplete="off" 
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('full_name_of_account_holder').hasError('required')">{{'FULLNAMEOFACCOUNTHOLDER' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="swift_aba_code"  type="text" class="form-control" id="swift_aba_code" aria-describedby="swift_aba_code"
                                    name="swift_aba_code" placeholder="{{ 'SWIFTABACODE' | translate }}"
                                    autocomplete="off" 
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('swift_aba_code').hasError('required')">{{'SWIFTABACODEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="institution_number"  type="text" class="form-control" id="institution_number" aria-describedby="institution_number"
                                    name="institution_number" placeholder="{{ 'INSNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('institution_number').hasError('required')">{{'INSNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="branch_number"  type="text" class="form-control text-box" id="branch_number" aria-describedby="branch_number"
                                    name="branch_number" placeholder="{{ 'BRANCHNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('branch_number').hasError('required')">{{'BRANCHNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="account_number"  type="text" class="form-control text-box" id="account_number" aria-describedby="account_number"
                                    name="account_number" placeholder="{{ 'ACCOUNTNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('account_number').hasError('required')">{{'ACCOUNTNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="bank_name"  type="text" class="form-control text-box" id="bank_name" aria-describedby="bank_name"
                                    name="bank_name" placeholder="{{ 'BANKNAME' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('bank_name').hasError('required')">{{'BANKNAMEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="bank_address"  type="text" class="form-control text-box" id="bank_address" aria-describedby="bank_address"
                                    name="bank_address" placeholder="{{ 'BANKADDRESS' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('bank_address').hasError('required')">{{'BANKADDRESSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_name"  type="text" class="form-control text-box" id="receiver_name" aria-describedby="receiver_name"
                                    name="receiver_name" placeholder="{{ 'RECEIVERNAME' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_name').hasError('required')">{{'RECEIVERNAMEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_address"  type="text" class="form-control text-box" id="receiver_address" aria-describedby="receiver_address"
                                    name="receiver_address" placeholder="{{ 'RECEIVERADDRESS' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_address').hasError('required')">{{'RECEIVERADDRESSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <!---- by Victor Sep.30 2024
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_city"  type="text" class="form-control text-box" id="receiver_city" aria-describedby="receiver_city"
                                    name="receiver_city" placeholder="{{ 'RECEIVERCITY' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_city').hasError('required')">{{'RECEIVERCITYREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_province"  type="text" class="form-control text-box" id="receiver_province" aria-describedby="receiver_province"
                                    name="receiver_province" placeholder="{{ 'RECEIVERPROVINCESTATE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_province').hasError('required')">{{'RECEIVERPROVINCEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_postcode"  type="text" class="form-control text-box" id="receiver_postcode" aria-describedby="receiver_postcode"
                                    name="receiver_postcode" placeholder="{{ 'RECEIVERPOSTCODE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_postcode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_country"  type="text" class="form-control text-box" id="receiver_country" aria-describedby="receiver_country"
                                    name="receiver_country" placeholder="{{ 'RECEIVERCOUNTRY' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_country').hasError('required')">{{'RECEIVERCOUNTRYREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                        -->

                            <h3>Account Type</h3>
                            <hr>
                            <mat-radio-group formControlName="account_type">
                            <div class="row">
                                    <div class="col-sm-12">
                                    <div class="form-control">
                                        <mat-radio-button  value="0"><span style="width: 100%;">{{'CHECKING' | translate}}</span></mat-radio-button>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-control">
                                        <mat-radio-button  value="1"><span style="width: 100%;">{{'SAVINGS' | translate}}</span></mat-radio-button>
                                    </div>
                                </div>
                            </div>
                           </mat-radio-group>
                           <br>

                            <div style="text-align: center; margin: auto;">
                                <button mat-raised-button class="btn submit-button mr-3"  (click)="prevStep()" >{{ "PREVSTEP" | translate}}</button>
                                <button mat-stroked-button type="submit" color="primary" class="btn submit-button" [disabled]="!withdrawalRequestForm.valid " (click)="withdrawalRequest(WithdrawalRequestF)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        
                            </div>
                            </form>
                            
                            </div><!--swift-->
                            <div *ngIf="withdrawal_type==1"> <!--ACH-->
                                <form #WithdrawalRequestF="ngForm" [formGroup]="withdrawalRequestForm" autocomplete="wr-form">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mat-form-field style="width: 100%;">
                                            <mat-select name="bankInfoACH" formControlName="bankInfoACH" placeholder="ACH Info">
                                                <mat-option *ngFor="let option of bankInfoACHOptions" [value]="option.value">
                                                    {{ option.label }}
                                                </mat-option>
                                            </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_full_name_of_account_holder"  type="text" class="form-control" id="ach_full_name_of_account_holder" aria-describedby="ach_full_name_of_account_holder"
                                        name="ach_full_name_of_account_holder" placeholder="{{ 'FULLNAMEOFACCOUNTHOLDER' | translate }}"
                                        autocomplete="off" 
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_full_name_of_account_holder').hasError('required')">{{'FULLNAMEOFACCOUNTHOLDER' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_routing_number"  type="text" class="form-control" id="ach_routing_number" aria-describedby="ach_routing_number"
                                        name="ach_routing_number" placeholder="{{ 'ACHROUTINGNUMBER' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_routing_number').hasError('required')">{{'INSNUMBERREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_institution_number"  type="text" class="form-control" id="ach_institution_number" aria-describedby="ach_institution_number"
                                        name="ach_institution_number" placeholder="{{ 'INSNUMBER' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_institution_number').hasError('required')">{{'INSNUMBERREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_branch_number"  type="text" class="form-control text-box" id="ach_branch_number" aria-describedby="ach_branch_number"
                                        name="ach_branch_number" placeholder="{{ 'BRANCHNUMBER' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_branch_number').hasError('required')">{{'BRANCHNUMBERREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_account_number"  type="text" class="form-control text-box" id="ach_account_number" aria-describedby="ach_account_number"
                                        name="ach_account_number" placeholder="{{ 'ACCOUNTNUMBER' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_account_number').hasError('required')">{{'ACCOUNTNUMBERREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_bank_name"  type="text" class="form-control text-box" id="ach_bank_name" aria-describedby="ach_bank_name"
                                        name="ach_bank_name" placeholder="{{ 'BANKNAME' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_bank_name').hasError('required')">{{'BANKNAMEREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_bank_address"  type="text" class="form-control text-box" id="ach_bank_address" aria-describedby="ach_bank_address"
                                        name="ach_bank_address" placeholder="{{ 'BANKADDRESS' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_bank_address').hasError('required')">{{'BANKADDRESSREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_name"  type="text" class="form-control text-box" id="ach_receiver_name" aria-describedby="ach_receiver_name"
                                        name="ach_receiver_name" placeholder="{{ 'RECEIVERNAME' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_name').hasError('required')">{{'RECEIVERNAMEREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_address"  type="text" class="form-control text-box" id="ach_receiver_address" aria-describedby="ach_receiver_address"
                                        name="ach_receiver_address" placeholder="{{ 'RECEIVERADDRESS' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_address').hasError('required')">{{'RECEIVERADDRESSREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>

                                <!---- by Victor Sep.30 2024
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_city"  type="text" class="form-control text-box" id="ach_receiver_city" aria-describedby="ach_receiver_city"
                                        name="ach_receiver_city" placeholder="{{ 'RECEIVERCITY' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_city').hasError('required')">{{'RECEIVERCITYREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_province"  type="text" class="form-control text-box" id="ach_receiver_province" aria-describedby="ach_receiver_province"
                                        name="ach_receiver_province" placeholder="{{ 'RECEIVERPROVINCESTATE' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_province').hasError('required')">{{'RECEIVERPROVINCEREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_postcode"  type="text" class="form-control text-box" id="ach_receiver_postcode" aria-describedby="ach_receiver_postcode"
                                        name="ach_receiver_postcode" placeholder="{{ 'RECEIVERPOSTCODE' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_postcode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="ach_receiver_country"  type="text" class="form-control text-box" id="ach_receiver_country" aria-describedby="ach_receiver_country"
                                        name="ach_receiver_country" placeholder="{{ 'RECEIVERCOUNTRY' | translate }}"
                                        autocomplete="off"
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_receiver_country').hasError('required')">{{'RECEIVERCOUNTRYREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                            -->
    
                                <h3>Account Type</h3>
                                <hr>
                                <mat-radio-group formControlName="ach_account_type">
                                <div class="row">
                                        <div class="col-sm-12">
                                        <div class="form-control">
                                            <mat-radio-button  value="0"><span style="width: 100%;">{{'CHECKING' | translate}}</span></mat-radio-button>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-control">
                                            <mat-radio-button  value="1"><span style="width: 100%;">{{'SAVINGS' | translate}}</span></mat-radio-button>
                                        </div>
                                    </div>
                                </div>
                               </mat-radio-group>
                           
                            <br>
                            
                            <div style="text-align: center; margin: auto;">
                                <button mat-raised-button class="btn submit-button mr-3"  (click)="prevStep()" >{{ "PREVSTEP" | translate}}</button>
                                <button mat-stroked-button type="submit" color="primary" class="btn submit-button" [disabled]="!withdrawalRequestForm.valid " (click)="withdrawalRequest(WithdrawalRequestF)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                            </div> 
                                </form>   
                           
                            </div>
                        <!--
                        <div class="col-sm-12 col-xs-12 form-popup-form" *ngIf="step_no==1">
                            <div style="text-align: center; margin: auto;">
                                <button mat-raised-button class="btn submit-button mr-3"  (click)="prevStep()" >{{ "PREVSTEP" | translate}}</button>
                                <button mat-stroked-button type="submit" color="primary" class="btn submit-button" [disabled]="!withdrawalRequestForm.valid " (click)="withdrawalRequest()" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        -->
                   <!-- big form -->
                    <div><span class="text-warning font-weight-bold">Disclaimer:</span> Please note that the account holder is responsible for the accuracy of the bank information provided. Incorrect bank details could lead to a loss of funds once the money is sent.</div>
                </div>
            </div>
        </div>
        
    </div>
</div>