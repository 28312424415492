import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-run-autoship',
  templateUrl: './run-autoship.component.html',
  styleUrls: ['./run-autoship.component.scss']
})
export class RunAutoshipComponent implements OnInit {
  //public user: User;
  private _unsubscribe$ = new Subject<any>();
  public runAutoshipForm : FormGroup;
  public processResults=[];
  date:Date; 
  public productionMode = environment.production;
  public isProcessing1 = false;
  public error_times=0;
  public order_list=[];
  public orders=[];

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { 
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnInit(): void {
    this.runAutoshipForm = this.formBuilder.group({
      appmode: ['', [Validators.required]],
      days: [0,[]],
    });
  }
 
 toRunAutoship() {
    this.isProcessing1 = true;
    let data= this.runAutoshipForm.value ;
    if (this.productionMode) {
      data.mode = 'production';
    } else {
      data.mode = (data.appmode==0)? 'test' :'production';
    }
    this.processResults=[];
    this.shareinfoService.setCreditProcess(true);
    this.evgService.runAutoshipProcess(data)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          this.shareinfoService.setCreditProcess(false);
          //console.log('toRunAutoship resp:',resp);
          if (resp) {
            this.processResults = resp.data[0].results;
            this.orders = resp.data[0].results.orders;
          }
          this.isProcessing1 = false;
      },(errorResult) => {
        this.isProcessing1 = false;
        this.shareinfoService.setCreditProcess(false);
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

}
