import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerformanceGrowthBullentinRank } from 'src/app/modules/common/PerformanceGrowthBullentinRank';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-performance-growth-bulletin',
  templateUrl: './performance-growth-bulletin.component.html',
  styleUrls: ['./performance-growth-bulletin.component.scss']
})
export class PerformanceGrowthBulletinComponent implements OnInit {

  public user: User;
  public bulletinRanks : PerformanceGrowthBullentinRank[] = [];
  public selectedRank : PerformanceGrowthBullentinRank = null;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getPerformanceGrowthBulletinRanks();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getPerformanceGrowthBulletinRanks()
  {
    this.evgService.getPerformanceGrowthBulletinRanks( {} ).subscribe(resp => {
      if (resp.success) {
        this.bulletinRanks = resp.data[0].results;
        
        //console.log(this.bulletinRanks);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  openBulletinRank(index)
  {
    this.selectedRank = this.bulletinRanks[index];
  }

  resetSelectedRank()
  {
    this.selectedRank = null;
  }

}
