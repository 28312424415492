<div class="sendlink-container  center">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="pagebody  center">
        <div class="row">
            <form  #changeRank="ngForm" [formGroup]="wholesaleFrom" (keydown.enter)="$event.preventDefault()" autocomplete="cr-form" style="width:100%;">
                <div class="p-3" style="margin: 1rem auto;">
                    <h2 class="title" translate>{{ pageTittle }}</h2>
                </div>
                <div class="col-12">
                    <mat-form-field style="width: 100%;">
                        <input matInput formControlName="customer"  type="text" class="form-control text-box" id="customer"
                        name="customer"  placeholder="{{ 'WHOLESALE' | translate }}"  autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="wholesaleFrom.get('customer').hasError('required')">{{'WHOLESALE' | translate}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field style="width: 100%;">
                        <input matInput formControlName="email"  type="email" class="form-control text-box" id="email"
                        name="email"  placeholder="{{ 'EMAIL' | translate }}"  autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="wholesaleFrom.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'PHONENUM' | translate}}</mat-label>
                        <input matInput formControlName="phone"  type="phone" class="form-control text-box" id="phone"
                        name="phone"  placeholder="{{ 'PHONENUM' | translate }}"  autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="wholesaleFrom.get('phone').hasError('required')">{{'TELEPHONEREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field style="width: 100%;">
                        <input matInput formControlName="contact_person"  type="text" class="form-control text-box" id="contact_person" 
                        name="contact_person" placeholder="{{ 'CONTACTPERSON' | translate }}"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="wholesaleFrom.get('contact_person').hasError('required')">{{'CONTACTPERSON' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-10">
                    <h2 translate>{{ 'BILLINGINFO' | translate }}</h2>
                </div>
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <mat-select formControlName="country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                            placeholder="{{'COUNTRYLABEL' | translate}}" name="country" autocomplete="off">
                            <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                {{ country.country }}
                            </mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <mat-select formControlName="province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                            placeholder="{{'PROVINCELABEL' | translate}}"  name="province" autocomplete="off">
                                <ng-container *ngFor="let province of provinces, let i = index">
                                    <mat-option [value]="province.state_name">
                                    {{ province.state_name }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="city"  type="text" class="form-control text-box" id="city" 
                            name="city" placeholder="{{ 'CITYLABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('city').hasError('required')">{{'CITYREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="address"  type="text" class="form-control text-box" id="address" 
                            name="address" placeholder="{{ 'ADDRESSLABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('address').hasError('required')">{{'ADDRESSREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="post_code"  type="text" class="form-control text-box" id="post_code" 
                            name="post_code" placeholder="{{ 'POSTALCODELABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('post_code').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
<!-- shipping address-->
                <div class="row col-12">
                    <h2 translate>{{ 'BILLINGINFO' | translate }}&nbsp;&nbsp;&nbsp;</h2>
                    <mat-checkbox
                        class="checbox-margin"
                        [(ngModel)]="isSameAdr"
                        labelPosition="after"
                        (change)="onChangeSameAddress($event)"
                        [ngModelOptions]="{standalone: true}"
                        >
                        {{ 'SAMEWITHBILLING' | translate }}
                    </mat-checkbox>
                </div>
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <mat-select formControlName="shipping_country"  type="text" class="form-control text-box" id="shipping_country" aria-describedby="shipping_country"
                            placeholder="{{'COUNTRYLABEL' | translate}}" name="shipping_country" autocomplete="off">
                            <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                {{ country.country }}
                            </mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('shipping_country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <mat-select formControlName="shipping_province"  type="text" class="form-control text-box" id="shipping_province" aria-describedby="shipping_province"
                            placeholder="{{'PROVINCELABEL' | translate}}"  name="shipping_province" autocomplete="off">
                                <ng-container *ngFor="let province of shipProvinces, let i = index">
                                    <mat-option [value]="province.state_name">
                                    {{ province.state_name }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('shipping_province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="shipping_city"  type="text" class="form-control text-box" id="shipping_city" 
                            name="shipping_city" placeholder="{{ 'CITYLABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('shipping_city').hasError('required')">{{'CITYREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="shipping_address"  type="text" class="form-control text-box" id="shipping_address" 
                            name="shipping_address" placeholder="{{ 'ADDRESSLABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('shipping_address').hasError('required')">{{'ADDRESSREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;" >
                            <input matInput formControlName="shipping_post_code"  type="text" class="form-control text-box" id="shipping_post_code" 
                            name="shipping_post_code" placeholder="{{ 'POSTALCODELABEL' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="wholesaleFrom.get('shipping_post_code').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-12 cform-popup-form" style="margin:2rem auto">
                    <div style="text-align: center; margin: auto;">
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!wholesaleFrom.valid || isProcessing" (click)="updateWholesale(changeRank)" >{{ "SAVE" | translate}}</button>
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!wholesaleFrom.valid || isProcessing" (click)="updateThenCloseWholesale(changeRank)" style="margin:0.8rem;">{{ "SAVE" | translate}}&{{ "CLOSEBTN" | translate}} </button>
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="isProcessing"  (click)="activeModal.close('close')" title="close" >{{ "CANCELBTN" | translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
