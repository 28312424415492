import { Component, OnInit, Input } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-fulvic-acid',
  templateUrl: './view-fulvic-acid.component.html',
  styleUrls: ['./view-fulvic-acid.component.scss']
})
export class ViewFulvicAcidComponent implements OnInit {
  @Input() addCloseBut : boolean=false;
  @Input() translate : TranslateConfigService;

  keywords:any=[
    {flag:0,keyword:'FA2WHATISHAD3D1'},
    {flag:0,keyword:'FA2WHATISHAD3D2'},
    {flag:0,keyword:'FA2WHATISHAD3D3'},
    {flag:0,keyword:'FA2WHATISHAD3D4'},
    {flag:0,keyword:'FA2WHATISHAD3D5'},
    {flag:0,keyword:'FA2WHATISHAD3D6'},
    {flag:0,keyword:'FA2WHATISHAD3D7'},
    {flag:0,keyword:'FA2WHATISHAD3D8'},
    {flag:0,keyword:'FA2WHATISHAD3D9'},
    {flag:0,keyword:'FA2WHATISHAD3D10'},
    {flag:0,keyword:'FA2WHATISHAD3D11'},
    {flag:0,keyword:'FA2WHATISHAD3D12'},
    {flag:0,keyword:'FA2WHATISHAD3D13'},
    {flag:0,keyword:'FA2WHATISHAD3D14'},
    {flag:0,keyword:'FA2WHATISHAD3D15'},
    {flag:0,keyword:'FA2WHATISHAD3D16'},
    {flag:0,keyword:'FA2WHATISHAD3D17'},
    {flag:0,keyword:'FA2WHATISHAD3D18'},
    {flag:0,keyword:'FA2WHATISHAD3D19'},
    {flag:0,keyword:'FA2WHATISHAD3D20'},
  ];

  constructor(
    public activeModal : NgbActiveModal,
    public router : Router
  ) { }

  ngOnInit(): void {
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
