import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-tinted-pore',
  templateUrl: './tinted-pore.component.html',
  styleUrls: ['./tinted-pore.component.scss']
})
export class TintedPoreComponent implements OnInit {
  private translate: TranslateConfigService;
  public introduceInfo :any;

  constructor(
    translate : TranslateConfigService,
    public router : Router

  ) {

    this.translate= translate;
    this.introduceInfo = 
    {
      IntroduceTitle : this.translate.translate.instant('INTRODUCETIT'),
      introduceContent : this.translate.translate.instant('TINTEDINTRODCE'),
      IngredientTitle : this.translate.translate.instant('INGREDIENTSTIT'),
      IngredientContent : this.translate.translate.instant('TINTEDINGRE'),
    }
    //console.log('RestorativeComponent introduceInfo:', this.introduceInfo);
  
  }

  ngOnInit(): void {
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
