import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { User } from '../common/User';

@Component({
  selector: 'app-secret-question',
  templateUrl: './secret-question.component.html',
  styleUrls: ['./secret-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretQuestionComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() userType : String;
  public user : User;
  public questions = [];
  public secretQuestionForm: FormGroup;
  public random_question : any;

  private _unsubscribe$ = new Subject<any>();

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.secretQuestionForm = this.formBuilder.group({
      secret_answer: ['', Validators.required],
    });

    localStorage.setItem("isAnsweringSecret", "1");

    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.evgService.getSecretQuestions().pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.questions = resp.data[0].results;
          this.random_question = this.questions[Math.floor(Math.random()*this.questions.length)];
          this.cdr.detectChanges();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }



  onSubmit()
  {
    if(this.secretQuestionForm.status === 'VALID'){
      let data = this.secretQuestionForm.value;
      data.secret_question =  this.random_question.id;
      this.evgService.checkSecretAnswer(data)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                localStorage.setItem("isAnsweringSecret", "0");
                this.activeModal.close('Close click');
                this.shareinfoService.setUser(this.user);
                // this.shareinfoService.reloadTaxRate();
                // this.router.navigate([this.shareinfoService.backmainId]);
                if(this.userType == 'admin'){
                  this.router.navigate(['backmain/admin/daily-sales-report']);
                }else if(this.userType == 'accounting'){
                  this.router.navigate(['backmain/admin/daily-sales-report']);
                }else if(this.userType == 'support'){
                  this.router.navigate(['backmain/admin/customer-requests']);
                }else{
                  this.router.navigate(['backmain/dashboard']);
                }
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

}
