import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { VerifyBalanceDetailComponent } from 'src/app/modules/verify-balance-detail/verify-balance-detail.component';

@Component({
  selector: 'app-view-assets',
  templateUrl: './view-assets.component.html',
  styleUrls: ['./view-assets.component.scss']
})
export class ViewAssetsComponent implements OnInit {
  public user: User;
  public dailyAsset : any[] = [];
  public SuspectsAssets : any[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;
  public sortedAssets = [];

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.requestsForm = this.formBuilder.group({
      infoType: [0, [Validators.required]],
    });
    this.getUser();
    this.getAssetsDailyHistory();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getAssetsDailyHistory()
  {
    this.isProcessing = true;
    this.evgService.getAssetsDailyHistory( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          
          this.dailyAsset = resp.data[0].results ;
          this.sortedAssets = this.dailyAsset.slice();
         console.log("getAssetsDailyHistory resp :",resp );
        }
        //console.log("getInvoiceLinks  this.invoicelinks :", this.invoicelinks );
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  sortData(sort: Sort) {
    const data = this.dailyAsset.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedAssets = data;
      return;
    }

    this.sortedAssets = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dealDate': return this.sharedUtilService.compare(a.dealDate, b.dealDate, isAsc);
        case 'MemberNo': return this.sharedUtilService.compare(a.MemberNo, b.MemberNo, isAsc);
        case 'dealName': return this.sharedUtilService.compare(a.dealName, b.dealName, isAsc);
        case 'levels': return this.sharedUtilService.compare(a.levels, b.levels, isAsc);
        case 'amount': return this.sharedUtilService.compare(a.amount, b.amount, isAsc);
        case 'inPending': return this.sharedUtilService.compare(a.inPending, b.inPending, isAsc);
        default: return 0;
      }
    });
  }

  openDetail(history_id) {
    //VerifyBalanceDetailComponent
    //console.log('call onpenInvoiceLink set linkId !',linkId);
    /*
    const modalRef = this.ngDialog.open(VerifyBalanceDetailComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.history_id = historyId;
    modalRef.componentInstance.userId = userId;
    
    modalRef.result.then((result) => {
      console.log(result);
      this.getInvoiceLinks(this.requestsForm.value);
    });
    getSuspectUserBalanceDetail
    */
    this.isProcessing = true;
    this.evgService.getSuspectUserBalanceDetail( {historyId : history_id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          
          this.SuspectsAssets = resp.data[0].results ;
          console.log("openDetail getSuspectUserBalanceDetail resp :",resp );
          const modalRef = this.ngDialog.open(VerifyBalanceDetailComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
          modalRef.componentInstance.translate = this.translate;
          modalRef.componentInstance.history_id = history_id;
          modalRef.componentInstance.balanceDetail = this.SuspectsAssets;
      
        }
        //console.log("getInvoiceLinks  this.invoicelinks :", this.invoicelinks );
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 

  }
}
