import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { User } from '../common/User';

@Component({
  selector: 'app-account-locked',
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLockedComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  public user : User;
  public loginForm: FormGroup;
  public resetLinkSent : boolean = false;

  private _unsubscribe$ = new Subject<any>();

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public formatter: NgbDateParserFormatter,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,

  ) { }


  ngOnInit(): void {

  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
