<div class="lock-unlock-user-account-container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #lock="ngForm"  [formGroup]="lockForm" (keydown.enter)="$event.preventDefault()" autocomplete="l-form">
                        
                        <div class="p-3">
                            <h2>{{ 'LOCKUSERACCOUNT' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="userId"  type="text" class="form-control text-box" id="userId_1"
                                name="userId"  placeholder="{{ 'USERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="lockForm.get('userId').hasError('required')">{{'USERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="reason"  type="text" class="form-control text-box" id="reason_1" 
                                name="reason" placeholder="{{ 'REASON' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="lockForm.get('reason').hasError('required')">{{'REASONREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                       

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!lockForm.valid || isProcessing1" (click)="lockUserAccount(lock)" >{{ "LOCKBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form  #unlock="ngForm" [formGroup]="unlockForm" (keydown.enter)="$event.preventDefault()" autocomplete="ul-form">
                        
                        <div class="p-3">
                            <h2>{{ 'UNLOCKUSERACCOUNT' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="userId"  type="text" class="form-control text-box" id="userId_2"
                                name="userId"  placeholder="{{ 'USERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="unlockForm.get('userId').hasError('required')">{{'USERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="reason"  type="text" class="form-control text-box" id="reason_2" 
                                name="reason" placeholder="{{ 'REASON' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="unlockForm.get('reason').hasError('required')">{{'REASONREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                       

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!unlockForm.valid || isProcessing2" (click)="unlockUserAccount(unlock)" >{{ "UNLOCKBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>