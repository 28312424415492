
<div class="send-batch-email-container">
   

  <div class="row gutters-sm mt-3" *ngIf="maintenanceInfo">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body" >
          <h2 class="title">{{ 'CURRENTMAINTENANCE' | translate }}</h2>
          <h3>{{ 'MFLAG' | translate }} : {{ maintenanceInfo.m_flag == 0 ? ('SHOWLOGIN' | translate) : (maintenanceInfo.m_flag == 1 ? ('SHOWLOGINANDMESSAGE' | translate) : (maintenanceInfo.m_flag == 2 ? ('SHOWMAINTENANCE' | translate) : '') ) }} </h3>
          <h3>{{ 'MESSAGE' | translate }} : </h3>
          <p class="mt-3 text-center">{{ maintenanceInfo.message ? maintenanceInfo.message : "NOMESSAGEAVAIL" }}</p>
        </div>
      </div>
    </div>
  </div>
    <form  [formGroup]="systemMaintenanceForm" #systemMaintain="ngForm" (keydown.enter)="$event.preventDefault()" autocomplete="rm-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <h2 class="title">{{ 'SYSTEMMAINTAN' | translate }}</h2>
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field style="width: 100%;">
                        <textarea matInput formControlName="message"  type="text" class="form-control text-box" id="message" aria-describedby="message"
                        placeholder="{{ 'MESSAGE' | translate }}" name="message" rows="5"
                        autocomplete="off"
                        ></textarea>
                        <mat-error class="error-message" *ngIf="systemMaintenanceForm.get('message').hasError('required')">{{ 'MESSAGEREQ' | translate }}</mat-error>
                      </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'MFLAG' | translate}}</mat-label>
                        <mat-select formControlName="m_flag"  type="text" class="form-control" id="m_flag" aria-describedby="m_flag"
                        autocomplete="off">
                            <mat-option [value]="0">{{ 'SHOWLOGIN' | translate }}</mat-option>
                            <mat-option [value]="1">{{ 'SHOWLOGINANDMESSAGE' | translate }}</mat-option>
                            <mat-option [value]="2">{{ 'SHOWMAINTENANCE' | translate }}</mat-option>
    
                        </mat-select>
                        <mat-error class="error-message" *ngIf="systemMaintenanceForm.get('m_flag').hasError('required')">{{'MFLAGREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'FROMDATE' | translate}}</mat-label>
                    <input matInput formControlName="from_date"  type="date" class="form-control">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;"> 
                    <mat-label>{{'FROMTIME' | translate}}</mat-label>
                    <input matInput formControlName="from_time"  type="time" class="form-control">
                  </mat-form-field>
                </div>
                <div class="col-sm-12">
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{'TODATE' | translate}}</mat-label>
                    <input matInput formControlName="to_date"  type="date" class="form-control">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;"> 
                    <mat-label>{{'TOTIME' | translate}}</mat-label>
                    <input matInput formControlName="to_time"  type="time" class="form-control">
                  </mat-form-field>
                </div>
            </div>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="!systemMaintenanceForm.valid" (click)="postMaintenance(systemMaintain)" class="align-right mr-2">{{  'CREATEBTN' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>