<div class="trade-password-container">
    <div class="row gutters-sm">
        <div class="col-md-3">
        </div>
      <div class="col-md-6 mb-3">
        <div class="card mt-3">
          <div class="card-body">
            <h2 translate>CHANGETPWLABEL</h2>
            <div class="d-flex flex-column align-items-center text-center">
                <form  #password="ngForm" [formGroup]="passwordForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3" autocomplete="p-form">

                    <!-- <mat-form-field style="width: 100%; text-align: center">
                        <input matInput formControlName="existingPassword"  type="password" class="form-control text-box" id="existingPassword" aria-describedby="existingPassword"
                         name="existingPassword" autocomplete="off" [placeholder]="'TPWLABEL' | translate"
                        />
                        <mat-error class="error-message" *ngIf="passwordForm.get('existingPassword').hasError('required')">{{ 'TPWREQ' | translate }}</mat-error>
                      </mat-form-field>
                       -->
                    <mat-label>{{ "TRADEHINT" | translate }}</mat-label>
                    <mat-form-field style="width: 100%; text-align: center">
                      <div class="input-group">
                        <input matInput formControlName="tradePassword" [type]="newPWType ? 'text' : 'password'" 
                            class="form-control text-box" id="tradePassword" aria-describedby="tradePassword"
                            name="tradePassword" autocomplete="off" [placeholder]="'NEWTPWLABEL' | translate"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i
                              class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !newPWType,
                                'fa-eye': newPWType
                              }"
                              (click)="toggleNewPWType()"
                            ></i>
                            </span>
                          </div>
                      </div>
    
                      <mat-error class="error-message" *ngIf="passwordForm.get('tradePassword').hasError('required')">{{ 'NEWTPWREQ' | translate }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field style="width: 100%; text-align: center;">
                      <div class="input-group">
                        <input matInput formControlName="confirmPassword" [type]="confirmPWType ? 'text' : 'password'" 
                            class="form-control text-box" id="confirmPassword" aria-describedby="confirmPassword"
                            name="confirmPassword" autocomplete="off" [placeholder]="'CTPWLABEL' | translate" [errorStateMatcher]="matcher"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i
                              class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !confirmPWType,
                                'fa-eye': confirmPWType
                              }"
                              (click)="toggleConfirmPWType()"
                            ></i>
                            </span>
                        </div>
                      </div>
                      <mat-error class="error-message" *ngIf="passwordForm.hasError('notSame')">{{ 'TPWMATCHERR' | translate }}</mat-error>
                    </mat-form-field>
    
                    <button mat-stroked-button  (click)="onChangePassword(password)"  [disabled]="!passwordForm.valid" color="primary" class="align-right mt-4">{{ 'SUBMIT' | translate }}</button>
                  </form>

              <!-- <form *ngIf="!user.tradePass" [formGroup]="generateTradeForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3">
                <mat-form-field style="width: 100%; text-align: center">
                    <input matInput formControlName="memberId"  type="text" class="form-control text-box" id="memberId" aria-describedby="memberId"
                     name="memberId" autocomplete="off" [placeholder]="'MEMBERIDLAST' | translate"
                    />
                    <mat-error class="error-message" *ngIf="generateTradeForm.get('memberId').hasError('required')">{{ 'MEMBERIDREQ' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-form-field style="width: 100%; text-align: center">
                    <input matInput  type="text" class="form-control text-box" id="generatedPassword" aria-describedby="generatedPassword"
                     name="generatedPassword" autocomplete="off" [placeholder]="'GENERATEPASSLABEL' | translate" disabled="true" [value]="generatedPassword"
                    />
                  </mat-form-field>
                <button mat-stroked-button color="primary" [disabled]="!generateButtonEnabled" (click)="generatePassword()" class="align-right mt-4 mr-2">{{ 'GENERATE' | translate }}</button>
                <button mat-stroked-button color="primary" [disabled]="!generatedPassword" (click)="submitPassword()" class="align-right mt-4">{{ 'SUBMIT' | translate }}</button>
              </form> -->

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
      </div>
      
    </div>
    
</div>