import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as go from 'gojs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import LTT from 'list-to-tree' 
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { User } from 'src/app/modules/common/User';
import { CurrencyPipe } from '@angular/common';
const $ = go.GraphObject.make;

@Component({
  selector: 'app-whole-organizational-chart',
  templateUrl: './whole-organizational-chart.component.html',
  styleUrls: ['./whole-organizational-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholeOrganizationalChartComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public user : User = new User();
  public treeNodes : any = [];
  public treeData = [];
  public treeMode = 'vertical';
  public zoom = 1;

  public mappedArr = {};
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private shareinfoService : ShareinfoService,
    private cdr : ChangeDetectorRef,
    private currencyPipe : CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getTeamData();
    // if(window.innerWidth < 1620){
    //   this.treeMode = "horizontal";
    //   this.cdr.detectChanges();
    // }else{
    //   this.treeMode = "vertical";
    //   this.cdr.detectChanges();
    // }
    
  }

  onZoomIn(){
    var result = Array.from(document.getElementsByTagName('ngx-chart-designer') as HTMLCollectionOf<HTMLElement>);
    var ele = result[0];

    if(this.zoom < 1){

      this.zoom = this.zoom + 0.05;
      ele.style.transform="scale(" + this.zoom +")";
      console.log(this.zoom);
    }

    // var result = Array.from(document.getElementsByClassName('tree') as HTMLCollectionOf<HTMLElement>);
    // var ele = result[0];;
    // ele.scrollIntoView(true);
  }

  onZoomOut(){
    var result = Array.from(document.getElementsByTagName('ngx-chart-designer') as HTMLCollectionOf<HTMLElement>);
    var ele = result[0];

    this.zoom = this.zoom - 0.05;
    console.log(this.zoom);
    ele.style.transform="scale(" + this.zoom +")";

    // ele.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "center",
    // });

    // var result = Array.from(document.getElementsByClassName('tree') as HTMLCollectionOf<HTMLElement>);
    // var ele = result[0];;
    // ele.scrollIntoView(true);
  }

  onRecenter()
  {
    var result = Array.from(document.getElementsByClassName('ngx-org-box') as HTMLCollectionOf<HTMLElement>);
    var ele = result[0];

    // ele.scrollTop = 5000;
    // console.log(ele.);
    // ele.scrollLeft = 1000;

    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  onRotate() {
    if(this.treeMode == "horizontal") {
      this.treeMode = 'vertical';
    }else if(this.treeMode == 'vertical') {
      this.treeMode = "horizontal";
    }
    this.cdr.detectChanges();
  }
      
  showTree(event)
  {
   
    // if(event.id == this.treeNodes[0].id){
    //   if(this.mappedArr[event.parentid]){
    //     this.treeNodes = [];
    //     this.treeNodes.push(this.mappedArr[event.parentid]);
    //     this.cdr.detectChanges();
    //   }
      
    // }else{
    //   this.treeNodes = [];
    //   this.treeNodes.push(this.mappedArr[event.id]);
    //   this.cdr.detectChanges();
    // }
    
  }

  onResetTree()
  {
    this.getTeamData();
  }
  onDrag()
  {
    
    // var ele = document.getElementsByTagName("ngx-chart-designer")[0] ;
    var result = Array.from(document.getElementsByClassName('tree') as HTMLCollectionOf<HTMLElement>);
    var ele;
    if(result.length > 0) ele = result[0];
    console.log(ele);
    ele.style
     ele.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none';

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;


        };

        const mouseUpHandler = function () {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        // Attach the handler
        ele.addEventListener('mousedown', mouseDownHandler);
  }


 unflatten(arr) {
  var tree = [],
      arrElem,
      mappedElem;

      
  
  // First map the nodes of the array to an object -> create a hash table.
  for(var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    this.mappedArr[arrElem.id] = arrElem;
    this.mappedArr[arrElem.id]['childs'] = [];
  }


  for (var id in this.mappedArr) {
    if (this.mappedArr.hasOwnProperty(id)) {
      mappedElem = this.mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
    
      if (mappedElem.parentid) {
        if(this.mappedArr[mappedElem['parentid']]){
          this.mappedArr[mappedElem['parentid']]['childs'].push(mappedElem);
        }
      }
      else {
        tree.push(mappedElem);
      }
    }
  }


  return tree;
}

  test(event)
  {
    console.log(event);

  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getTeamData()
  {

    this.evgService.rootTree([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0]){
            this.treeData = resp.data[0].teamtree;

            if(this.treeData.length > 0){

              const result = this.treeData.filter(data => data.id != this.user.id);
            
              this.treeNodes = this.unflatten(this.mapData(this.treeData));
              this.cdr.detectChanges();
              // this.onDrag();
              this.onRecenter();
            }else{
              this.treeData = [];
              this.treeNodes = [];
            }


          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  mapData(data)
  {
    let mappedData = [];

    // let node = { 
    //   id : this.user.id,
    //   parentid : 0,
    //   name : this.user.id,
    //   cssClass : this.getCssClass(this.user.uLevel),
    //   // image : 'assets/images/icons/user.png',
    //   title: this.user.first_name + ' ' + this.encodeLastName(this.user.last_name)
    // };


    // mappedData.push(node);
    console.log(data);

    data.forEach(element => {
      
      let node = { 
        id : element.id,
        parentid : element.Referrals,
        name : element.id,
        cssClass : this.getCssClass(element.uLevel),
        assetRange : this.shareinfoService.getAssetRange(element.TotalTokens),
        image : data[0]['id'] == element.sponsorId ? 'assets/images/icons/heart.png' : '',
        title: element.first_name + ' ' + this.encodeLastName(element.last_name) + ' - ' + this.currencyPipe.transform(element.teamSales)
      };
      

      mappedData.push(node);
    });

    
    return mappedData;
  }

  getCssClass(uLevel){
    switch (uLevel) {
      case 0: return 'member';
        break;
      case 1: return 'consultant';
        break;
      case 2: return 'distributor';
        break;
      case 3: return 'executive';
        break;
      case 4: return 'sapphire';
        break;
      case 5: return 'diamond';
        break;   
      case 6: return 'crown';
        break;               
      default: return 'member';
        break;
    }
  }

  encodeLastName(lastName){
    if(lastName.length > 0){
       var modifiedLastName = '***';
      //  for (let index = 0; index < (lastName.length - 1); index++) {
      //    modifiedLastName += '*';
      //  }
       modifiedLastName += lastName.substr(lastName.length - 1);
       return modifiedLastName;
    }
    return '';
  }

}
