<div class="user-request-list-container">
    <div class="row sgutters-m">
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'USERREQUESTLIST' | translate}}</h3>
              <div class="text-right mb-2">
                <form [formGroup]="requestsForm" (keydown.enter)="$event.preventDefault()" autocomplete="rf-form">
                    <mat-form-field>
                        <mat-label>{{'INFOTYPE' | translate}}</mat-label>
                        <mat-select formControlName="infoType"  type="text" class="form-control" id="infoType" aria-describedby="infoType"
                        autocomplete="off">
                            <mat-option  [value]="0">{{ 'OPENREQUESTS' | translate}}</mat-option>
                            <mat-option  [value]="1">{{ 'CLOSEDREQUESTS' | translate}}</mat-option>
                            <mat-option  [value]="2">{{ 'ALLREQUESTS' | translate}}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="requestsForm.get('infoType').hasError('required')">{{'INFOTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!requestsForm.valid || isProcessing" (click)="onGetRequests()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'TICKETNO' | translate}}</th>
                          <th scope="col">{{'REQUESTTYPE' | translate}}</th>
                          <th scope="col">{{'ATYPE' | translate}}</th>
                          <th scope="col">{{'SENDER' | translate}}</th>
                          <th scope="col">{{'RECEIVER' | translate}}</th>
                          <th scope="col">{{'TOPIC' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'STATUS' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of requests; index as i;">
                          <td>{{ request.id }}</td>
                          <td>{{ request.ticketNo }}</td>
                          <td>{{ getRequestType(request.requestType) }}</td>
                          <td>{{ getAType(request.aType) }}</td>
                          <td>{{ request.sender }}</td>
                          <td>{{ request.receiver }}</td>
                          <td>{{ request.topic }}</td>
                          <td>{{ request.amount | currency:''}}</td>
                          <td>{{getStatus(request) | translate }}
                            <div *ngIf="request.isClosed==1 && userType=='admin'"><button mat-button color="primary" class="btn b-1" (click)="reopenRequest(request)">{{ "REOPEN" | translate}}</button></div>
                          </td>
                          <td>{{ request.AddTime }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>