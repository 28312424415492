<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close('Close click')" alt="close"></span>
        </div>
    </div>
    <div class="row  m-5">
        <div class="tatlestyle text-center">{{ "ACTIVATEACCOUNT" | translate}}</div>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>