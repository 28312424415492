<div class="form-popup-container center">
    <div class="card">
      <div class="card-header">
        <div class="row headrow">
          <div class="col-10 form-popup-header">
            <span></span>
            <!-- <h3> {{ "USER" | translate}}&nbsp;{{ "REGISTER" | translate}}</h3> -->
          </div>
          <div class="col-2 form-popup-header">
            <span></span>
            <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                (click)="activeModal.close('close')" alt="close"></span>
          </div>
      </div>
  
      </div>
      <div class="card-body" #scrollMe (scroll)="onScroll()">
        <!--Added by Thomas begin-->
        <div class="col-sm-12">
          <div style="text-align: center; margin: auto;">
            <button mat-stroked-button color="warn" class="btn submit-button mr-2"  (click)="activeModal.close('close')">{{ "DECLINEBTN" | translate}}</button>
            <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.dismiss('finished')">{{ "ACCEPTBTN" | translate}}</button>
          </div>
        </div>
        <br>
        <!--Added by Thomas end-->
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Effective date: {{ todayDate }}</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Byte Health Management Corporation (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the https://www.bytemanage.com website (hereinafter referred to as the &quot;Service&quot;).</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://www. bytemanage.com</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>DEFINITIONS</strong></span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Service</span><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span><span style="font-family: Tahoma,Geneva, sans-serif;">Service is the https://www. bytemanage.com website operated by Byte Health Management Corporation.</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Personal Data</span><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span><span style="font-family: Tahoma,Geneva, sans-serif;">Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Usage Data</span><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span><span style="font-family: Tahoma,Geneva, sans-serif;">Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Cookies</span><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span><span style="font-family: Tahoma,Geneva, sans-serif;">Cookies are small files stored on your device (computer or mobile device).</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>INFORMATION COLLECTION AND USE</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We collect several different types of information for various purposes to provide and improve our Service to you.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>TYPES OF DATA COLLECTED</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>PERSONAL DATA</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable information may include, but is not limited to:</span></p>
        <p style="line-height: 1;"><span style="font-family: Tahoma,Geneva, sans-serif;">Email address</span></p>
        <p style="line-height: 1;"><span style="font-family: Tahoma,Geneva, sans-serif;">First name and last name</span></p>
        <p style="line-height: 1;"><span style="font-family: Tahoma,Geneva, sans-serif;">Phone number</span></p>
        <p style="line-height: 1;"><span style="font-family: Tahoma,Geneva, sans-serif;">Address, State, Province, ZIP/Postal code, City</span></p>
        <p style="line-height: 1;"><span style="font-family: Tahoma,Geneva, sans-serif;">Cookies and Usage Data</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We may also collect information on how the Service is accessed and used (&quot;Usage Data&quot;). This Usage Data may include information such as your computer&apos;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>LOCATION DATA</strong></span></p>
        <p>
            <font face="Tahoma, Geneva, sans-serif">We may use and store information about your location if you give us permission to do so (&quot;Location Data&quot;). We use this data to provide features of our Service, to improve and customize&nbsp;our Service.</font>
        </p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">You can enable or disable location services when you use our Service at any time by way of your device settings.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>TRACKING &amp; COOKIES DATA</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</span></p>
        <p>
            <font face="Tahoma, Geneva, sans-serif">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze&nbsp;our Service.</font>
        </p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Examples of Cookies we use:</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Session Cookies. We use Session Cookies to operate our Service.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Security Cookies. We use Security Cookies for security purposes.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">USE OF DATA</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">EV Green Inc. uses the collected data for various purposes:</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To provide and maintain our Service</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To notify you about changes to our Service</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To allow you to participate in interactive features of our Service when you choose to do so</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To provide customer support</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To gather analysis or valuable information so that we can improve our Service</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To monitor the usage of our Service</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To detect, prevent and address technical issues</span></p>
        <p><br></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>TRANSFER OF DATA</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Your information, including Personal Data, may be transferred to &mdash; and maintained on &mdash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">If you are located outside Canada and choose to provide information to us, please note that we transfer the data, including Personal Data, to Canada and process it there.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Byte Health Management Corporation will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>DISCLOSURE OF DATA</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>LEGAL REQUIREMENTS</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Byte Health Management Corporation may disclose your Personal Data in the good faith belief that such action is necessary to:</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To comply with a legal obligation</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To protect and defend the rights or property of Byte Health Management Corporation</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To prevent or investigate possible wrongdoing in connection with the Service</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To protect the personal safety of users of the Service or the public</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">To protect against legal liability</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>SECURITY OF DATA</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>SERVICE PROVIDERS</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We may employ third party companies and individuals to facilitate our Service (&quot;Service Providers&quot;), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>ANALYTICS</strong></span></p>
        <p>
            <font face="Tahoma, Geneva, sans-serif">We may use third-party Service Providers to monitor and analyze&nbsp;the use of our Service.</font>
        </p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>LINKS TO OTHER SITES</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy Policy of every site you visit.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>CHILDREN&apos;S PRIVACY</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>CHANGES TO THIS PRIVACY POLICY</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>CONTACT US</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">If you have any questions about this Privacy Policy, please contact us:</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">By visiting this page on our website under the contact page.</span></p>
        <p><br></p>
        <br>
        <br>
            <div class="col-sm-12">
              <div style="text-align: center; margin: auto;">
                <button mat-stroked-button color="warn" class="btn submit-button mr-2" [disabled]="!complete_read" (click)="activeModal.close('close')">{{ "DECLINEBTN" | translate}}</button>
                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!complete_read" (click)="activeModal.dismiss('finished')">{{ "ACCEPTBTN" | translate}}</button>
              </div>
            </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
    
    
</div>