import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(public translate : TranslateConfigService, public router : Router) { }

  ngOnInit(): void {
  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }
}
