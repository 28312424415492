import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntroducePartRoutingModule } from './introduce-part-routing.module';
import { IntroducePartComponent } from './introduce-part.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [IntroducePartComponent],
  exports:[IntroducePartComponent],
  imports: [
    CommonModule,
    IntroducePartRoutingModule,
    NgbModule,
    NgbNavModule,
]
})
export class IntroducePartModule { }
