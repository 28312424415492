import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-forgot-ewallet-password',
  templateUrl: './forgot-ewallet-password.component.html',
  styleUrls: ['./forgot-ewallet-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotEwalletPasswordComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  public passwordForm: FormGroup;
  public verificationForm : FormGroup;
  public isChangedPassword : boolean = false;
  public matcher = new PasswordErrorMatcherService();
  public verifyCode = '';
  private _unsubscribe$ = new Subject<any>();
  public userdata : any;
  public selectedCode = '';
  constructor(
    private router : Router,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    public activeModal : NgbActiveModal, 
    public cdr : ChangeDetectorRef,
    public sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      veryCode : ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: [''],
    }, { validators: this.checkPasswords });

    this.evgService.getUserVerifyInfo({   })
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            // this.responseHandlerService.handleResponse(resp);
            this.verifyCode = resp.data[0].results;
            this.userdata = resp.data[0].results.result;
            //console.log(this.userdata);
            this.cdr.detectChanges();
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  selectCode(mode){
    this.selectedCode = mode;
  }

  onSendCode()
  {
    if(this.selectedCode == 'mobile'){
      this.sendVerifyCode({isEWallet : 1, mobile: 1});
    }else if(this.selectedCode == 'email'){
      this.sendVerifyCode({isEWallet : 1, email: 1});
    }
    
  }

  sendVerifyCode(data){
    this.evgService.verifyPasswordCode({  data })
    .pipe( takeUntil(this._unsubscribe$) )
    .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.verifyCode = resp.data[0].results;
        }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  onChangePassword(directive)
  {
    if(this.verifyCode != this.passwordForm.get('veryCode').value){
      this.responseHandlerService.handleCustomResponse(this.translate.translate.instant('VERIFYCODENOTMATCH'),'warning')
    }else if(this.passwordForm.status == "VALID"){
      this.evgService.resetEWPassword(this.passwordForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                this.activeModal.close('success');
                this.sharedUtilService.resetForm(this.passwordForm, directive);
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true }     
  }

}
