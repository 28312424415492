<div class="bulletin-board-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'NEWS' | translate}}</h3>
              <div class="table-responsive" *ngIf="!selectedNews">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'TITLE' | translate}}</th>
                          <!-- <th scope="col">{{'CONTENT' | translate}}</th> -->
                          <th scope="col">{{'ADDDATE' | translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let new of news; index as i;">
                          <td><a class="title-link" (click)="openNews(i)">{{ new.title }}</a></td>
                          <td>{{ new.creaded_at }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>

              <div class="board" *ngIf="selectedNews">
                    <div class="col-sm-12 col-xs-12">
                        <div style="text-align: right; margin: auto;">
                            <button mat-stroked-button color="primary" class="btn submit-button" (click)="resetSelectedNews()" >{{ "BACKBTN" | translate}}</button>
                        </div>
                    </div>
                    <h2 class="board-title text-center mb-3 font-weight-bold">{{ selectedNews.title }}</h2>
                    <quill-view [content]="selectedNews.content" format="html" theme="snow"></quill-view>
                    
              </div>
            </div>
          </div>
        </div>
    </div>
</div>