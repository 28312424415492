import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-account-pw-change',
  templateUrl: './account-pw-change.component.html',
  styleUrls: ['./account-pw-change.component.scss']
})
export class AccountPwChangeComponent implements OnInit {
  
  public passwordForm: FormGroup;
  public verificationForm : FormGroup;
  public isChangedPassword : boolean = false;
  private _unsubscribe$ = new Subject<any>();
  public matcher = new PasswordErrorMatcherService();
  private verification_code = "";
  public isProcessing1 = false;
  public isProcessing2 = false;
  currentPWType: boolean=false;
  newPWType: boolean=false;
  confirmPWType: boolean=false;

  passRequirement = {
    passwordMinLowerCase: 1,
    passwordMinNumber: 1,
    passwordMinSymbol: 2,
    passwordMinUpperCase: 1,
    passwordMinCharacters: 8
  };

  pattern = [
    `(?=([^a-z]*[a-z])\{${this.passRequirement.passwordMinLowerCase},\})`,
    `(?=([^A-Z]*[A-Z])\{${this.passRequirement.passwordMinUpperCase},\})`,
    `(?=([^0-9]*[0-9])\{${this.passRequirement.passwordMinNumber},\})`,
    `(?=(\.\*[\$\@\$\!\%\*\?\&])\{${this.passRequirement.passwordMinSymbol},\})`,
    `[A-Za-z\\d\$\@\$\!\%\*\?\&\.]{${
      this.passRequirement.passwordMinCharacters
    },}`
  ]
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      //newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,20}')]],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])|(?=.*[A-Z])|(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,20}')]],
      confirmPassword: [''],
    }, { validators: this.checkPasswords });

    this.verificationForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });
  }

  onChangePassword()
  {
    if(this.passwordForm.status == "VALID"){
      this.isProcessing1 = true;
      this.evgService.verifyPasswordCode(this.verificationForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                this.isChangedPassword = true;
                
                this.verification_code = resp.data[0].results;
                //console.log(this.verification_code);
            }else{
              this.isProcessing1 = false;
            }
          }else{
            this.isProcessing1 = false;
          }
      },(errorResult) => {
        this.isProcessing1 = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      
    }
  }


  onVerify()
  {
    if(this.verificationForm.status == "VALID" && this.verificationForm.value.code == this.verification_code){
      this.isProcessing2 = true;
      this.evgService.updatePassword(this.passwordForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                this.isChangedPassword = false;
                this.isProcessing1 = false;
                this.isProcessing2 = false;
                this.passwordForm.reset();
                this.verificationForm.reset();
                
            }else{
              this.isProcessing1 = false;
              this.isProcessing2 = false;
            }
          }else{
            this.isProcessing1 = false;
            this.isProcessing2 = false;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const newPassword = group.get('newPassword').value;
    const confirmPassword = group.get('confirmPassword').value;

    return newPassword === confirmPassword ? null : { notSame: true }     
  }
/**
  currentPWType: boolean=false;
  newPWType: boolean=false;
  confirmPWType: boolean=false;
 * 
 */
  toggleCurrentPWType() {
    this.currentPWType = !this.currentPWType;
  }

  toggleNewPWType() {
    this.newPWType = !this.newPWType;
  }

  toggleConfirmPWType() {
    this.confirmPWType = !this.confirmPWType;
  }

}
