
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WrinkleSerumComponent } from './wrinkle-serum.component';
import { WrinkleSerumRoutingModule } from './wrinkle-serum-routing.module';
import { IntroducePartModule } from "../common/introduce-part/introduce-part.module";


@NgModule({
    declarations: [WrinkleSerumComponent],
    exports: [WrinkleSerumComponent],
    imports: [
        CommonModule,
        WrinkleSerumRoutingModule,
        NgbModule,
        NgbNavModule,
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntroducePartModule
    ]
})
export class WrinkleSerumModule { }
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
