<div class="invoice-link-list-container" style="background-color: cadetblue;">
  <div class="card-header">
        
    <div class="row">
      <div class="col-10">
        <span></span>
      </div>
      <div class="col-2">
        <span></span>
        <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
            (click)="activeModal.close('close')" alt="close"></span>
      </div>
    </div>
  </div>

    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'DOWNLOADCENTRE' | translate}}</h3>
              <div class="text-right mb-2">
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'TITLELABEL' | translate}}</th>
                          <th scope="col">{{'DESCRIPTION' | translate}}</th>
                          <th scope="col">{{'FILENAME' | translate}}</th>
                          <th scope="col">{{'DOWNLOAD' | translate}} {{'TIMES' | translate}}</th>
                          <th scope="col">{{'DOWNLOAD' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of uploadedDocs; index as i;">
                          <td>{{ request.title }}</td>
                          <td>{{ request.description }}</td>
                          <td>{{ request.file_name }}</td>
                          <td>{{ request.times }}</td>
                          <td><a (click)="onDownloadDoc(request)"><i class="bi bi-arrow-down-square-fill"></i></a></td>
                          <td>{{ request.creaded_at }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 form-popup-form">
          <div style="text-align: center; margin: auto;">
              <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="isProcessing"  (click)="activeModal.close('close')" >{{ "CLOSEBTN" | translate}}</button>
          </div>
      </div>
  </div>
</div>
