export class uploadDoc {
    id: number;
    authorId : number;
    author: string;
    title: string;
    description: string;
    path: string;
    file_name: string;
    saved_file_name: string;
    file_type: string;
    inactive: number=0;
    times: number=0;
}
