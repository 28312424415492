<div class="personal-orders-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'PERSORDER' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'PURCHDATE' | translate}}</th>
                          <th scope="col">{{'ORDERID' | translate}}</th>
                          <th scope="col">{{'GROUPID' | translate}}</th>
                          <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                          <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                          <th scope="col">{{'PRODUCTDESC' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'CANCELDATE' | translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let order of myOrders; index as i;">
                          <td>{{ order.addtime }}</td>
                          <td>{{ order.orderNo }}</td>
                          <td>{{ order.groupID }}</td>
                          <td>{{ order.passedDay }}</td>
                          <td>{{ order.amount | currency:'' }}</td>
                          <td>{{ order.pTitle }}</td>
                          <td>{{ order.pContent }}</td>
                          <td>{{ order.cancelFlag ? ('YES' | translate) : ('') }}</td>
                          <td>{{ order.cancelDate }}</td>
                          
                          
                          
                        </tr>
                      </tbody>
                    </table>
              </div>

            </div>
          </div>
        </div>
    </div>
</div>