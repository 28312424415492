
<div class="login-container">
  <div class="card">
      <div class="card-header">
        
        <div class="row">
          <div class="col-10">
            <span></span>
          </div>
          <div class="col-2">
            <span></span>
            <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                (click)="activeModal.close(false)" alt="close"></span>
          </div>
        </div>
      </div>
      <div class="maintenance" *ngIf="maintenanceInfo">
        <div class="scroll-left" *ngIf="maintenanceInfo.m_flag == 1 || maintenanceInfo.m_flag == 2">
          <p>{{ maintenanceInfo.message }}</p>
        </div>
      </div>
      <div class="card-body"> 
        <form *ngIf="mode == 'login'" [formGroup]="loginForm" class="submitform" autocomplete="log-form">
            
            <div class="d-flex flex-column">
                <div ngbAutofocus class="title mb-3">{{ "LOGINTOACCNT" | translate}}</div>

                <div class="form-group">
                    <mat-form-field style="width: 100%;">
                        <input matInput formControlName="email"  type="text" class="form-control input-box" id="InputEmail" aria-describedby="emailHelp"
                        placeholder="{{ 'EMAILADRORID' | translate }}"  name="email" maxlength="60"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="loginForm.get('email').hasError('required')">{{ "EMAILORIDISREQ" | translate }} </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field style="width: 100%;">
                  <div class="input-group">
                       <input matInput formControlName="password" [type]="fieldTextType ? 'text' : 'password'" class="form-control input-box" id="InputPW" aria-describedby="passwordHelp"
                        placeholder="{{ 'PASSWORD' | translate }}"  name="password" maxlength="60"
                        autocomplete="off">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i
                              class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !fieldTextType,
                                'fa-eye': fieldTextType
                              }"
                              (click)="toggleFieldTextType()"
                            ></i>
                          </span>
                        </div>
                  </div>
                  <mat-error class="error-message" *ngIf="loginForm.get('password').hasError('required')" >{{ "PASSISREQ" | translate }} </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <div style="text-align: center; margin: auto;">
                  <!--
                  <button mat-raised-button color="primary" class="btn login-button" [disabled]="!loginForm.valid || isLoggingIn" (click)="signIn()" >{{ "LOGIN" | translate}}</button>
                  -->
                  <button mat-raised-button color="primary" class="btn login-button" [disabled]="!loginForm.valid || isLoggingIn" (click)="executeAction()" >{{ "LOGIN" | translate}}</button>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="d-flex justify-content-center bd-highlight mt-3">
                  <a style="color: rgb(171, 206, 53);cursor: pointer;" (click)="mode = 'forgot'" >{{ 'FORGOTPASS' | translate }}</a>
                  <a class="ml-2" style="color: pear; cursor: pointer;" (click)="openRegister()" >{{ 'REGISTERACC' | translate }}</a>
                </div>
              </div>
          </div>
            
        </form>

        <form *ngIf="mode == 'forgot'" [formGroup]="forgotPasswordForm" (keydown.enter)="$event.preventDefault()" class="submitform" autocomplete="fp-form">
          
            <div class="d-flex flex-column">
                <div *ngIf="!resetLinkSent">
                    <div ngbAutofocus class="title mb-3">{{ "RESETYOURPASS" | translate}}</div>
                    <div class="form-group">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="email"  type="email" class="form-control text-box" id="InputEmail" aria-describedby="emailHelp"
                            placeholder="{{ 'EMAILADR' | translate }}" name="email" maxlength="60"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="forgotPasswordForm.get('email').hasError('required')" >{{ "EMAILISREQ" | translate }}</mat-error>
                            <mat-error class="error-message" *ngIf="forgotPasswordForm.get('email').hasError('email')" >{{ "EMAILISVALID" | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="resetLinkSent" class="row">
                    <div class="col-sm-12">
                      <div class="d-flex justify-content-center bd-highlight mb-3 text-center">
                            <h3 translate>RESETPASSMSG</h3>
                      </div>
                    </div>
                </div>
               
                <div style="text-align: center; margin: auto;" *ngIf="!resetLinkSent">
                  <button mat-stroked-button color="primary" class="btn login-button" [disabled]="!forgotPasswordForm.valid || isLoggingIn" (click)="resetPassword()" >{{ "SUBMIT" | translate}}</button>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-flex justify-content-center bd-highlight mt-3">
                      <a style="color: rgb(171, 206, 53);cursor: pointer;" (click)="mode = 'login'; resetLinkSent = false"  >{{ 'TAKEMELOGIN' | translate }}</a>
                    </div>
                  </div>
              </div>
                
            </div>
        </form>
      </div>
  </div>
</div>