import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Products,Product } from 'src/app/modules/common/Products';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() selected_product: Product =null;
  //selected_product : Product;
  styleLeft: any;
  styleTop: any;

  constructor(
    public activeModal : NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  addProdToCart(){
    this.activeModal.close(true);

  }

  goBack(){
    this.activeModal.close(false);

  }

}
