import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-bytetoken',
  templateUrl: './show-bytetoken.component.html',
  styleUrls: ['./show-bytetoken.component.scss']
})
export class ShowBytetokenComponent implements OnInit {
  @Input() amount : number=0.00;
  @Input() showAmount : boolean=true;
  @Input() showSize : number=1;


  constructor() { 
    if (this.showAmount) {
      this.amount = this.amount ? (this.amount*100)/100:0.00;
    }
  }

  ngOnInit(): void {
  }

}
