<div class="leadership-bonus-funds-pool-container">
    <div class="row gutters-sm">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
              
            <h3 class="account-title">{{'LEADERSHIPBONUSFUP' | translate}}</h3>
            <div class="table-responsive">
              <form  [formGroup]="glSettingForm" (keydown.enter)="$event.preventDefault()" autocomplete="gl-form">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                          <th scope="col">{{'GLBRATE' | translate}}</th>
                          <th scope="col">{{'GLBPOOL' | translate}}</th>
                          <th scope="col">{{'GLBNUMS' | translate}}</th>
                          <th scope="col">{{'STATUS' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="glSetting">
                          
                          <td>{{ glSetting.glb_rate  }}</td>
                          <td>
                            <mat-form-field style="width: 100%; text-align: center;">
                              <input matInput formControlName="glb_pool"  type="text" class="form-control text-box" id="glb_pool" aria-describedby="glb_pool"
                               name="glb_pool"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="glSettingForm.get('glb_pool').hasError('required')">{{'GLBPOOLREQ' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field style="width: 100%; text-align: center">
                              <input matInput formControlName="glb_nums"  type="text" class="form-control text-box" id="glb_nums" aria-describedby="glb_nums"
                               name="glb_nums"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="glSettingForm.get('glb_nums').hasError('required')">{{'GLBNUMSREQ' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>{{ glSetting.actived ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</td>
                          <td><button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="setBonusPool(glSetting)" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button></td>
                      

                        </tr>
                        <tr>
                          <td colspan="3" class="text-right">
                            {{ 'GLBPOOL' | translate }}
                          </td>
                          <td colspan="2">
                            {{ bonusPool ? (bonusPool.glb_pool | currency:'') : '' }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right">
                            {{ 'TOTALSALES' | translate }}
                          </td>
                          <td colspan="2">
                            {{ bonusPool ? (bonusPool.totalSales | currency:'') : '' }}
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>