<div class="income-flow-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'INCOMEFLOWRECORD' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th mat-sort-header="processed_date" scope="col">{{'DATELABEL' | translate}}</th>
                          <th mat-sort-header="total_balanced" scope="col">{{'TTLBALANCE' | translate}}</th>
                          <th mat-sort-header="total_eWallet" scope="col">{{'TTLEWALLET' | translate}}</th>
                          <th mat-sort-header="total_summary" scope="col">{{'TTLASSET' | translate}}</th>
                          <th mat-sort-header="pending_bonus" scope="col">{{'TTLPENDBONUS' | translate}}</th>
                          <th mat-sort-header="paid_bonus_today" scope="col">{{'TTLPAIDBONUS' | translate}}</th>
                          <th mat-sort-header="new_orders" scope="col">{{'TODAYORDERS' | translate}}</th>
                          <th mat-sort-header="today_sales" scope="col">{{'TODAYSALES' | translate}}</th>
                          <th mat-sort-header="total_sales" scope="col">{{'TTLSALES' | translate}}</th>
                          <th mat-sort-header="problem_users" scope="col">{{'SUSUSERS' | translate}}</th>
                          <th mat-sort-header="for_date" scope="col">{{'FORDATE' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let asset of dailyAsset; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td>{{ asset.processed_date }}</td>
                          <td>{{ asset.total_balanced | currency:''}}</td>
                          <td>{{ asset.total_eWallet | currency:''}}</td>
                          <td>{{ (asset.total_balanced + asset.total_eWallet) | currency:''}}</td>
                          <td>{{ asset.pending_bonus | currency:''}}</td>
                          <td>{{ asset.paid_bonus_today | currency:''}}</td>
                          <td>{{ asset.new_orders}}</td>
                          <td>{{ asset.today_sales | currency:''}}</td>
                          <td>{{ asset.total_sales | currency:''}}</td>
                          <td style="min-width:1.5rem; margin: 3px auto;"><button class="btn btn-link" *ngIf="asset.problem_users>0" (click)="openDetail(asset.id)">{{asset.problem_users}}</button><span *ngIf="asset.problem_users==0">{{asset.problem_users}}</span></td>
                          <td>{{ asset.for_date}}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>