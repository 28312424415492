import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReplyMessage } from 'src/app/modules/common/CustomerSupport';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-withdrawal-requests',
  templateUrl: './withdrawal-requests.component.html',
  styleUrls: ['./withdrawal-requests.component.scss']
})
export class WithdrawalRequestsComponent implements OnInit {

  public user: User;
  public replyMessages : ReplyMessage[] = [];
  public replyMessage : ReplyMessage;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public replyMessageForm : FormGroup;
  panelOpenState = false;
  public isProcessing = false;
  public selectedRequests : any;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
  ) { }

  onCreate(){
    this.isCreating = true;
  }

  onBack(){
    this.replyMessage = null;
    this.isCreating = false;
    this.getRequestByGroupList();
    this.openPage('');
  }

  onReply(index){
    this.replyMessage = this.replyMessages[index];
    this.isCreating = false;
    this.replyMessageForm.patchValue({
      'topic' : this.replyMessage.topic
    });
    this.openPage(this.replyMessage.id);
  }

  openPage(pId) {
    // this.router.navigate(['backmain/admin/customer-requests/' + pId]);
  }

  ngOnInit(): void {
    this.replyMessageForm = this.formBuilder.group({
      topic: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });

    this.replyMessageForm.controls['topic'].disable();

    
    this.getUser();
    // this.getReplyMessages();

    this.getRequestByGroupList();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  /**
   * requestType: 1 (request from accounting)
   */
  getRequestByGroupList()
  {
    this.evgService.getRequestByGroupList({requestType: 1}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.replyMessages = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getRequestByGroupDetail(ticketNo)
  {
    this.evgService.getRequestByGroupDetail({ticketNo : ticketNo}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        //console.log(resp);
        if(resp.success){
          this.selectedRequests = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


  getReplyMessages()
  {
    this.evgService.getReplayMessages({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          // this.replyMessages = resp.data[0].results;
          // console.log("getReplyMessages",resp.data[0]);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postReplyMessage()
  {
    if(this.replyMessageForm.status === "VALID"){
      let data = this.replyMessageForm.value;
      data.sender = this.replyMessage.userId;
      data.topic = this.replyMessageForm.get('topic').value;
      data.receiver = this.user.id;
      data.ticketNo = this.replyMessage.ticketNo;

      this.isProcessing = true;
      this.evgService.replayAskquestion(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          if(resp.data[0]){
            //console.log("postReplyMessage",resp.data[0]);
            this.onBack();
          }
        }
        this.isProcessing = false;
      },(errorResult) => {
        this.isProcessing = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  getRequestDetail(ticketNo)
  {
    if(ticketNo){
      this.getRequestByGroupDetail(ticketNo);
    }else{
      this.selectedRequests = null;
    }
  }

}
