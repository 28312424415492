import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EvgApiService } from './services/evg-api.service';
import { ResponseHandlerService } from './services/response-handler.service';
import { SharedUtilService } from './services/shared-util.service';
import { ShareinfoService } from './services/shareinfo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'evgreen';
  public showOverlay = true;
  public access_token = null;
  public byte_cart_page:boolean = false;
  public paymentProcess = false;
  public loggedInUserId = null;
  private time: number = 0;
  private minuteLimit = 1;
  private interval;
  private userPages = 
  [
      '/backmain/dashboard',
      '/backmain/account-setting',
      '/backmain/account-rank',
      '/backmain/account-pw-change',
      '/backmain/security-questions-setting',
      '/backmain/trade-pw-setting',
      '/backmain/account-upload-portrait',
      '/backmain/pay-monthly-fee',
      '/backmain/personal-profile',
      '/backmain/purchase',
      '/backmain/purchase-history',
      '/backmain/bonus-detail',
      '/backmain/tree',
      '/backmain/renew-ewallet-password',
      '/backmain/balance-record',
      '/backmain/withdrawal-request',
      '/backmain/withdrawal-request-history',
      '/backmain/ewallet-balance',
      '/backmain/trade-password',
      '/backmain/transfer-to-current-balance',
      '/backmain/bonus-overview',
      '/backmain/current-balance',
      '/backmain/income-flow-record',
      '/backmain/monthly-bonus-record',
      '/backmain/bulletin-board',
      '/backmain/performance-growth-bulletin',
      '/backmain/promotion',
      '/backmain/convention-and-events',
      '/backmain/personal-new-orders',
      '/backmain/personal-orders-record',
      '/backmain/team-new-orders',
      '/backmain/team-orders-record',
      '/backmain/company-messages',
      '/backmain/customer-service',
      '/backmain/user-faqs',
      '/backmain/my-inquires',
      '/backmain/application-forms',
      '/backmain/downline-ranks',
      '/backmain/personally-sponsored-chart',
      '/backmain/personally-sponsored-list',
      '/backmain/cart',
      '/backmain/my-shopping-store',
      '/backmain/personal-webpage',
  ];
  private adminPages = 
  [

  ];

  private admins = [];
  private accounting = [];
  private supports = [];
  public currentRoute : string = '';
  public creditProcess = false;
  private _unsubscribe$ = new Subject<any>();

  @HostListener('document:mousemove', ['$event']) 
  onMouseMove(e) {
    localStorage.setItem("lastActionSec",JSON.stringify(0));
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    localStorage.setItem("lastActionSec",JSON.stringify(0));
    // console.log("KEYPRESSED");
  }
  constructor(
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private responseHandlerService : ResponseHandlerService,
    private ngbModal : NgbModal,
    private sharedUtilService : SharedUtilService
  ) { 
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })

    this.shareinfoService.creditProcess
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.creditProcess = successResponse;
            this.showOverlay = this.creditProcess;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    
    this.shareinfoService.currentRoute.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.currentRoute = successResponse;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    this.getAdminUsersConfig();
    this.getLoggedInUser({pageId : localStorage.currentRoute ? localStorage.currentRoute : '', seconds : JSON.parse(localStorage.lastActionSec ? localStorage.lastActionSec : 0)});
    
  }
  ngOnInit(): void {
    this.startTimer();
  }

  openPage(setUrl) {
    console.log('app.component openPage', setUrl)
    this.router.navigate(setUrl);
  }

  startTimer() {
    
    this.interval = setInterval(() => {
      this.time = JSON.parse(localStorage.lastActionSec ? localStorage.lastActionSec : 0);
      if (this.time === 0) this.time++;
      else this.time++;

      localStorage.setItem("lastActionSec",JSON.stringify(this.time));
      // console.log("TIME",this.time);
      
      if(this.userPages.includes(localStorage.currentRoute)){
        // console.log("IN USER PAGE :" + localStorage.currentRoute);
        this.minuteLimit = 10;
      }else if(this.adminPages.includes(localStorage.currentRoute)){
        // console.log("IN ADMIN PAGE :" + localStorage.currentRoute);
        this.minuteLimit = 30;
      }else{
        // console.log("UNKNOWN FIELD :" + localStorage.currentRoute);
        this.minuteLimit = 10;
      }
      // console.log(this.time);
      if(this.time%(60*this.minuteLimit) === 0){
        
        // if (this.shareinfoService.isLoggedIn()) {
        //   console.log("LOGGED EVERY " + this.minuteLimit  + " MINUTES");
        //   console.log("CURRENT ROUTE " + localStorage.currentRoute  + " SEC : " + JSON.parse(localStorage.lastActionSec ? localStorage.lastActionSec : 0));
        // 	this.getLoggedInUser({pageId : localStorage.currentRoute ? localStorage.currentRoute : '', seconds : JSON.parse(localStorage.lastActionSec ? localStorage.lastActionSec : 0)});
        // }
        if (this.shareinfoService.isLoggedIn()) {
          this.shareinfoService.isLoggedIn()
          this.evgService.signOut();
          this.shareinfoService.logout();
          this.ngbModal.dismissAll();
          this.responseHandlerService.handleCustomResponse('Session Expired! Please login again.','warning');
          //this.router.navigate(['/']);
          console.log('NAV 0','/');
          this.openPage(['/']);
        }
      }
      
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }


  getAdminUsersConfig (){
    this.evgService.getAdminUsersConfig({}).subscribe( resp => {
      if (resp.success) {
        if(resp.data[0].results.admin) this.admins = resp.data[0].results.admin;
        if(resp.data[0].results.accounting) this.accounting = resp.data[0].results.accounting;
        if(resp.data[0].results.supporter) this.supports = resp.data[0].results.supporter;
        localStorage.setItem('admins',JSON.stringify(this.admins));
        localStorage.setItem('accounting',JSON.stringify(this.accounting));
        localStorage.setItem('supports',JSON.stringify(this.supports));
        // this.shareinfoService.setAdmins(this.admins);
        // this.shareinfoService.setAccounting(this.accounting);
        // this.shareinfoService.setSupports(this.supports);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getLoggedInUser(obj : any)
  {
    let result = this.evgService.isUserLoggedIn(obj);
    this.evgService.isUserLoggedIn(obj).subscribe(resp => {
        if(typeof resp === 'object'){
          if(resp){
            if(!resp.success){
              this.shareinfoService.setUser(null);
              this.shareinfoService.setEWallet(null);
              this.ngbModal.dismissAll();
              this.cdref.detectChanges();
              if(this.currentRoute.includes('backmain')){
                //this.router.navigate(['/']);
                console.log('NAV 1','/');
                this.openPage(['/']);
              } else {
                console.log('NAV 1-backmain','/');
                //this.openPage([(this.currentRoute ? this.currentRoute : '/')]);
              }
              
            }
          }else{
            this.shareinfoService.setUser(null);
            this.shareinfoService.setEWallet(null);
            this.cdref.detectChanges();
            this.ngbModal.dismissAll();
            this.responseHandlerService.handleCustomResponse("Session Expired. Please login again.","warning");
            //this.router.navigate(['/']);
            console.log('NAV 2','/');
            this.openPage(['/']);
          }
          
        }else{
          this.loggedInUserId = resp;
          if(this.loggedInUserId){
              this.evgService.getUser({id : this.loggedInUserId}).subscribe(resp => {
                if(resp.data[0]){
                  var userType = this.sharedUtilService.checkUserPermissions(resp.data[0].results.id);
                  this.shareinfoService.setMode(userType);
                  if(!resp.data[0].results.askSecretQ){
                    this.shareinfoService.setUser(resp.data[0].results);
                    // this.shareinfoService.reloadTaxRate();
                    if(this.shareinfoService.ewalletToken == 'loggedIn'){
                      this.shareinfoService.setEWallet(resp.data[0].results);
                    }
                    else{
                      this.shareinfoService.setEWallet(null);
                    }
                  }else{
                    if(localStorage.getItem("isAnsweringSecret")){
                      if(localStorage.getItem("isAnsweringSecret") == "0"){
                        this.shareinfoService.setUser(resp.data[0].results);
                        // this.shareinfoService.reloadTaxRate();
                        if(this.shareinfoService.ewalletToken == 'loggedIn'){
                          this.shareinfoService.setEWallet(resp.data[0].results);
                        }
                        else{
                          this.shareinfoService.setEWallet(null);
                        }
                      }else{
                        this.evgService.signOut().subscribe(resp => {
                          if(resp){
                            this.cdref.detectChanges();
                            localStorage.setItem(this.shareinfoService.tokenStoreKey, null);
                            localStorage.setItem("isAnsweringSecret", "0");
                          }
                        },(errorResult) => {
                          console.log("System Error", errorResult);
                          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
                        });
                      }
                    }else{
                      console.log("out1");
                    }
                  }
                  this.cdref.detectChanges();
                }
              });
          }else{
            this.shareinfoService.setUser(null);
            this.shareinfoService.setEWallet(null);
            this.cdref.detectChanges();
            this.ngbModal.dismissAll();
            this.responseHandlerService.handleCustomResponse("Session Expired. Please login again.","warning");
            this.router.navigate(['/']);
            console.log('/ 3');
            this.openPage(['/']);

          }
        }
        if(resp){
          
        }
        
      // }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
      this.cdref.detectChanges();
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
      this.paymentProcess=false;
      const qIndex = event.url.lastIndexOf('?');
      let pageUrl = '';
      if(qIndex > 0){
        let pa = event.url.lastIndexOf('=');
        pageUrl= event.url.substring(0,event.url.lastIndexOf('?'));
        if (pa > qIndex ) {
          //console.log('in getPageId string:',setURL.substring((qIndex+1), pa).toLowerCase());
          if ( event.url.substring((qIndex+1), pa).toLowerCase()=== "payment") {
              this.paymentProcess = true;
              this.access_token = event.url.substring((pa+1));
              console.log('in navigationInterceptor this.access_token :',this.access_token);
              pageUrl='/payment/'+this.access_token;
          }
        }
  
      }else{
        pageUrl = event.url;
        this.byte_cart_page = ((event.url.lastIndexOf('/byteCart'))>=0);
        //console.log('navigationInterceptor byte_cart_page',this.byte_cart_page);
      }
      console.log('navigationInterceptor pageUrl: event.url:',pageUrl,event.url);
      if (this.shareinfoService.isLoggedIn() && !this.byte_cart_page) {
        this.getLoggedInUser({pageId : pageUrl, seconds : JSON.parse(localStorage.lastActionSec ? localStorage.lastActionSec : 0)});
      }
      localStorage.setItem("lastActionSec",JSON.stringify(0));
      localStorage.setItem("currentRoute",pageUrl);
      this.shareinfoService.reloadNewMessages();
      this.shareinfoService.setCurrentRoute(pageUrl);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
      this.cdref.detectChanges();
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
      this.cdref.detectChanges();
    }
    
  }

}
