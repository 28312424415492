import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Card, User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-set-up-monthly-payment',
  templateUrl: './set-up-monthly-payment.component.html',
  styleUrls: ['./set-up-monthly-payment.component.scss']
})
export class SetUpMonthlyPaymentComponent implements OnInit {

  public user = new User();
  public cards : Card[] = [];
  public paymentSettingForm: FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public numberRegEx = /\-?\d*\.?\d{1,2}/;


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    
    this.paymentSettingForm = this.formBuilder.group({
      payFee  : ['', [Validators.required]],
      autoPay : ['', [Validators.required]],
      quitRank : ['', [Validators.required]],
      selectedCardId : [0, [Validators.required]],
      cardType : ['', []],
      holder : ['', []],
      cardNo : ['', []],
      cvCode : ['', []],
      expireDate : ['', []],
    });

    
    this.paymentSettingForm.get('selectedCardId').valueChanges
        .subscribe(value => {
          if(value == 0) {
            this.paymentSettingForm.get('cardType').setValidators(Validators.required);
            this.paymentSettingForm.get('holder').setValidators(Validators.required);
            this.paymentSettingForm.get('cardNo').setValidators([Validators.required,Validators.pattern(this.numberRegEx)]);
            this.paymentSettingForm.get('cvCode').setValidators([Validators.required,Validators.pattern(this.numberRegEx)]);
            this.paymentSettingForm.get('expireDate').setValidators(Validators.required);
            this.paymentSettingForm.markAsPristine();
          } else {
            this.paymentSettingForm.get('cardType').clearValidators();
            this.paymentSettingForm.get('holder').clearValidators();
            this.paymentSettingForm.get('cardNo').clearValidators();
            this.paymentSettingForm.get('cvCode').clearValidators();
            this.paymentSettingForm.get('expireDate').clearValidators();
          }
          this.paymentSettingForm.controls['cardType'].updateValueAndValidity();
          this.paymentSettingForm.controls['holder'].updateValueAndValidity();
          this.paymentSettingForm.controls['cardNo'].updateValueAndValidity();
          this.paymentSettingForm.controls['cvCode'].updateValueAndValidity();
          this.paymentSettingForm.controls['expireDate'].updateValueAndValidity();
        }
    );
    this.getUser();
    this.getCardsInfo();

  }

  public getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              //console.log(this.user);
              this.paymentSettingForm.patchValue({
                payFee  : this.user.payFee,
                autoPay : this.user.autoPay,
                quitRank : this.user.quitRank,
                selectedCardId : 0
              });
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public updatePaymentSettings(formDirective)
  {
    let raw_data = this.paymentSettingForm.value;

    //console.log(raw_data.payFee);

    let data = {
      payFee : '',
      autoPay : '',
      quitRank : '',
      cardInfo : new Card,
    }
    data.payFee = raw_data.payFee;
    data.autoPay = raw_data.autoPay;
    data.quitRank = raw_data.quitRank;
    
    if(raw_data.selectedCardId){
      
      data.cardInfo = this.cards.find(card => {
        return card.id === raw_data.selectedCardId
      })
    }else{

      data.cardInfo.cardNo = raw_data.cardNo;
      data.cardInfo.holder = raw_data.holder;
      data.cardInfo.cardType = raw_data.cardType;
      data.cardInfo.expireDate = raw_data.expireDate;
      data.cardInfo.cvCode = raw_data.cvCode;
    }

    this.evgService.updatePayFeeSetting( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.shareinfoService.reloadUser();
        this.sharedUtilService.resetForm(this.paymentSettingForm,formDirective);
        this.getCardsInfo();
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  public getCardsInfo()
  {
    this.evgService.getCardsInfo( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.cards = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
