import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'src/app/modules/common/Message';
import { User } from 'src/app/modules/common/User';
import { ReceiveCoinsComponent } from 'src/app/modules/receive-coins/receive-coins.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-company-messages',
  templateUrl: './company-messages.component.html',
  styleUrls: ['./company-messages.component.scss']
})
export class CompanyMessagesComponent implements OnInit {

  public user: User;
  public messages : Message[] = [];
  public selectedMessage : Message = null;
  public messageId : string = null;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private router: Router,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private route: ActivatedRoute,
    private ngDialog : NgbModal
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getMessages();

    
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMessages(){
    this.evgService.getCompanyMassages({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              if(successResponse.success){
                this.messages = successResponse.data[0].results;
                if(this.messageId){
                  this.selectMessage(this.messages.findIndex(message => message.id == this.messageId));
                }
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  selectMessage(index){
    if(index >= 0){
      // this.router.navigate(["backmain/company-messages/" + this.messages[index].id]);
      if(this.selectedMessage){
        if(this.messages[index].id == this.selectedMessage.id){
          this.selectedMessage = null;
        }else{
          this.selectedMessage = this.messages[index];
        }
      }else{
        this.selectedMessage = this.messages[index];
      }
      // this.viewMessage(index);
    }
  }

  viewMessage(index){
    if(this.messages[index].msg_type == 2){
      const modalRef = this.ngDialog.open(ReceiveCoinsComponent, { windowClass: 'receive-coins-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.messages[index];
    }else{
      this.evgService.clearReadFlag({id : this.messages[index].id, msg_type : this.messages[index].msg_type}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              if(successResponse.success){
                this.selectMessage(index);
                this.getMessages();
                this.shareinfoService.reloadNewMessages();
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
      );
    }
  }

}
