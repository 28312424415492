<div class="infor-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <h2 class="title">{{ 'INVOICEINFO' | translate }}</h2>
            </div>

            <div id="infocontent">
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TO' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.customer}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'PRODUCT' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.pTitle}}</span></div>
                </div>

                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'QUANTITY' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.quantity}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'UNITPRICE' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.unit_price}}</span></div>
                </div>

                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TAXGST' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.tax_gst}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TAXPST' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.tax_pst}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'TAXAMOUNT' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.tax_amt}}</span></div>
                </div>
                <div class="row col-12">
                    <div class="col-5"><span class="float-right pr-3">{{'AMOUNT' | translate}}</span></div>
                    <div class="col-7"><span class="content">{{invoiceLink.amount}}</span></div>
                </div>
            </div>
        </div>
        <div class="col-12 form-popup-form">
            <div style="text-align: center; margin:2rem auto;">
                <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.close('close')" >{{ "CLOSED" | translate}}</button>
            </div>
        </div>

    </div>
</div>
