<div class="secret-question-container">

    <div class="row p-5">
        <div class="col-lg-12">
            <div class="row" >
                <form [formGroup]="secretQuestionForm" (keydown.enter)="$event.preventDefault()" class="secret-form" style="width: 100%;" autocomplete="sq-form">
                    <div class="row">
                    <div ngbAutofocus class="col-sm-12 col-xs-12 form-popup-form tatlestyle">{{ "SECRETQPROMPT" | translate }}</div>
                    </div>
                    <br>
                    <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <mat-label *ngIf="random_question" style="width: 100%;">{{ random_question.question }}</mat-label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                        <mat-form-field style="width: 100%;">
                            <mat-label>{{'ANSWER' | translate}}</mat-label>
                            <input matInput  formControlName="secret_answer" type="text" class="form-control" id="InputSecretAnswer" 
                            placeholder="{{ 'ANSWER' | translate }}" name="secret_answer" maxlength="60"
                            title = "{{ 'ANSWER' | translate }}" required autocomplete="off" >
                            <mat-error class="error-message" *ngIf="secretQuestionForm.get('secret_answer').hasError('required')">{{'ANSWERREQ' | translate}}</mat-error>
                        </mat-form-field>
                        </div>
                    </div>
                    </div>
                    <hr>
            
            
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div style="text-align: center; margin: auto;">
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!secretQuestionForm.valid " (click)="onSubmit()" >{{ "SUBMIT" | translate}}</button>
                    </div>
                    </div>
                </form>
                 
              </div>
        </div>
    </div>

</div>