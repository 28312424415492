import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { SendInvoiceComponent } from './send-invoice/send-invoice.component';
import { InvoiceLink } from 'src/app/modules/common/InvoiceLink';

@Component({
  selector: 'app-invoice-link',
  templateUrl: './invoice-link.component.html',
  styleUrls: ['./invoice-link.component.scss']
})
export class InvoiceLinkComponent implements OnInit {

  public user: User;
  public invoicelinks : InvoiceLink[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.requestsForm = this.formBuilder.group({
      infoType: [0, [Validators.required]],
    });
    //this.getUser();
    this.onGetRequests();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getInvoiceLinks(data : any)
  {
    this.isProcessing = true;
    this.evgService.getInvoiceLinks( {infoType :data.infoType} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          
          this.invoicelinks = resp.data[0].results ;
         // console.log("getInvoiceLinks resp :",resp );
        }
        //console.log("getInvoiceLinks  this.invoicelinks :", this.invoicelinks );
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onGetRequests()
  {
    if(this.requestsForm.status == "VALID"){
        this.getInvoiceLinks(this.requestsForm.value);
    }

  }

  getAType(type){
    switch(type){
      case 1 : return "PAID";
      default : return "UNPAY";
    }
 }

 onpenInvoiceLink(linkId: number=null) {
    //console.log('call onpenInvoiceLink set linkId !',linkId);
    const modalRef = this.ngDialog.open(SendInvoiceComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.invoiceLinkId = linkId;
    
    modalRef.result.then((result) => {
      console.log(result);
      this.getInvoiceLinks(this.requestsForm.value);
    });
  }
  onSelectEvent(event: any){
    console.log('onSelectEvent value:',event);
    //this.getInvoiceLinks({infoType : event.value});

  }

  isPaid(invObj:InvoiceLink) {

    return invObj.paid==0 && invObj.passed_days>7;

  }
 
}
