import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Products } from 'src/app/modules/common/Products';
import {coupon_products,coupon_header,coupon_detail} from 'src/app/modules/common/Coupon';
import { User } from 'src/app/modules/common/User';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from 'src/app/modules/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-coupon-setup',
  templateUrl: './coupon-setup.component.html',
  styleUrls: ['./coupon-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponSetupComponent implements OnInit {

  public user: User;
  private _unsubscribe$ = new Subject<any>();
  public coupons = [];
  public couponForm : FormGroup;
  public isProcessing1 = false;
  public memberList = [];
  public showMemberList = false;
  public userId = 0;
  public coupon_products: coupon_products[] = [];
  public products: Products[] = [];
  public showProductList = false ;
  public coupon_category = 0;
  public isForCommon:boolean= false;
  public isEdit:boolean=false;
  public editId:number=null;
  public isInactived:boolean= false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private sharedUtilService : SharedUtilService,
    private cdr : ChangeDetectorRef,
    private ngDialog : NgbModal
  ) {
    this.getProducts();

   }

  ngOnInit(): void {
      this.initForm();
      this.getUser();
      this.getCoupons();
      this.generateCoupon();
  }

  initForm()
  {
    this.couponForm = this.formBuilder.group({
      coupon: ['', [Validators.required]],
      coupon_type: [0, [Validators.required]],
      category: [0, [Validators.required]],
      coupon_amt: ['', [Validators.required]],
      special_range : ['*', [Validators.required]],
      from_dt : ['', [Validators.required]],
      to_dt  : ['', [Validators.required]],
      for_common : [0,[]],
      inactive : [0,[]],
    });

    this.couponForm.get('special_range').valueChanges
    .subscribe(value => {
      //console.log(!isNaN(value));
      if(value == ''){
        this.showMemberList = false;
        this.memberList = [];
      }else if(value == '*'){
        this.showMemberList = false;
        this.memberList = [];
      }else if(value == '1') { //!isNaN(value)){
        this.showMemberList = true;
      }else{
        this.showMemberList = false;
        this.memberList = [];
      }
    });
    this.couponForm.get('category').valueChanges
    .subscribe(value => {
      console.log('value :',value, !isNaN(value));
      if(value == 1){
        this.coupon_category=1;
        this.couponForm.get('coupon_amt').clearValidators();
        this.couponForm.get('special_range').clearValidators();
      }else{
        this.coupon_category=0;
        this.couponForm.get('coupon_amt').setValidators(Validators.required);
        this.couponForm.get('special_range').setValidators(Validators.required);
      }
      this.couponForm.markAsPristine();

      this.couponForm.controls['coupon_amt'].updateValueAndValidity();
      this.couponForm.controls['special_range'].updateValueAndValidity();
    });
  }


  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getCoupons()
  {
    this.evgService.getCoupons({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.coupons = resp.data[0].results;
          this.cdr.detectChanges();
          //console.log(this.coupons);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  generateCoupon()
  {
    this.evgService.generateCoupon({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.couponForm.patchValue({
            coupon : resp.data[0].results
          })
          this.cdr.detectChanges();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onEditCoupon(couponObj: coupon_header){
    if (couponObj) {
      //console.log("onEditCoupon couponObj", couponObj);
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('EDITCOUPON') + "?";
      modalRef.result.then((result) => {
        if(result) {
          this.isEdit=true;
          this.isForCommon= couponObj.for_common===1 ? true: false;
          this.editId = couponObj.id;
          this.couponForm.patchValue({
            coupon : couponObj.coupon,
            coupon_type : couponObj.coupon_type,
            category: couponObj.category,
            coupon_amt: couponObj.coupon_amt,
            special_range: couponObj.special_range,
            inactive: couponObj.inactive,
            from_dt: couponObj.from_dt ? (couponObj.from_dt.substring(0, 10)): '',
            to_dt: couponObj.to_dt ? (couponObj.to_dt.substring(0, 10)): '',
            for_common: couponObj.for_common,
          })
         }
      });
    }

  }
  onDeleteCoupon(couponObj:coupon_header) {
    if (couponObj) {
      //console.log("onDeleteCoupon couponObj", couponObj);
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('DELETECOUPON') + "?";
      modalRef.result.then((result) => {
        if(result) {
          this.evgService.deleteCouponTicket({id: couponObj.id}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              this.getCoupons();
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }); 
        }
      });
    }

  }

  addCoupon(formDirective)
  {
    if(this.couponForm.valid){
      let data = this.couponForm.value;
      //if(!isNaN(data.special_range)) data['memberList'] = this.memberList;
      this.isProcessing1 = true;
      //console.log('getProducts this.coupon_products :',this.coupon_products);
      //if (this.coupon_category==1  || (data.special_range !='*' && data.for_common == 1) ) {
      if (data.special_range !='*' ) {
        data.coupon_products = this.coupon_products;
      }
      if (this.isEdit && this.editId) {
        data['id']= this.editId;
      }
      //console.log('addCoupon data :',data);
      this.evgService.postCouponTicket( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.getCoupons();
          this.isEdit=false;
          this.editId=null;
         
          this.sharedUtilService.resetForm(this.couponForm, formDirective);
          this.showMemberList = false;
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
      //console.log(data);
    }
  }

  addUser()
  {
    let value = this.couponForm.get('special_range').value;
    if(!isNaN(value)){
      if(this.memberList.length < value){
        var index = this.memberList.findIndex(x => x.userId == this.userId); 
        if(index === -1 && this.userId ) this.memberList.push({userId : this.userId});
      }else{
        alert('Max Users Already');
      }
    }
    
  }

  getProducts()
  {
    this.evgService.forPurchase([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
            this.coupon_products=[];
            this.products.forEach((element, i) => {
              if (!element.isValid) {
                element.pQty =0;
                let data = {pId : element.pId, pTitle: element.pTitle,pPrice:element.pPrice,pQty:0};
                this.coupon_products.push(data);
              }
            });
            //console.log('getProducts this.coupon_products :',this.coupon_products);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  onchangeForCommon(event:MatCheckboxChange) {

    this.isForCommon = (event.checked === true);

    this.couponForm.patchValue({
      for_common : event.checked === true? 1: 0
    })

    //console.log('onchangeForCommon event: ',event);
  }
  
  onchangeInactived(event:MatCheckboxChange) {

    this.isInactived = (event.checked === true);

    this.couponForm.patchValue({
      inactive : event.checked === true? 1: 0
    })

    //console.log('onchangeForCommon event: ',event);
  }

  onCategoryChange(eventValue) {
    this.coupon_category=eventValue.value;
    //console.log('onCategoryChange eventValue:',eventValue);
  }

  onChangeEvent(index, event){
    //console.log('onChangeEvent i: event:',index,event);
    this.coupon_products.forEach((element, i) => {
      if (i == index) {
        element.pQty= parseInt( event.target.value);
      }
    });

  }
}
