import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { ConfirmWithdrawalRequestComponent } from 'src/app/modules/confirm-withdrawal-request/confirm-withdrawal-request.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { CustomErrorStateMatcher } from './curtom-state-matcher';
import { SharedUtilService } from 'src/app/services/shared-util.service';



@Component({
  selector: 'app-withdrawal-request-history',
  templateUrl: './withdrawal-request-history.component.html',
  styleUrls: ['./withdrawal-request-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers :[
    {provide: ErrorStateMatcher, useClass:CustomErrorStateMatcher}

  ]
})
export class WithdrawalRequestHistoryComponent implements OnInit {
  @ViewChild("pagetitle") pagetitle: ElementRef;

  public customErrorStateMatcher = new CustomErrorStateMatcher();
  public withdrawalRequestForm: FormGroup;
  public questions : Array<any>=[];
  public user = new User();
  public step_no:number =0;
  public tradePass_error=0;
  public isProcessing = false;
  public withdrawal_type = 0;
  public bankInfo : Array<any>;
  public withdrawalRequests : Array<any>=[];
  public bankInfoSwiftOptions : Array<any>=[];

  public bankInfoACHOptions : Array<any>=[];

  public selectedRequest;

  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private sharedUtilService : SharedUtilService,
    private ngDialog: NgbModal,
    private cdRef : ChangeDetectorRef,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    
    this.withdrawalRequestForm = this.formBuilder.group({
      
      withdraw_amount: ['', [Validators.required, Validators.min(50)]],
      swift_aba_code: ['', [Validators.required]],
      //ach_routing_number: ['', [Validators.required]],
      institution_number: ['', [Validators.required]],
      branch_number: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      bank_name: ['', [Validators.required]],
      bank_address: ['', [Validators.required]],
      receiver_name: ['', [Validators.required]],
      receiver_address: ['', [Validators.required]],
        /*  Set.30,2024 By Victor
      receiver_city: ['', [Validators.required]],
      receiver_province: ['', [Validators.required]],
      receiver_country: ['', [Validators.required]],
      receiver_postcode: ['',[Validators.required]],
      */
      account_type:['0', [Validators.required]],
      full_name_of_account_holder: ['',[Validators.required]],
      withdrawal_type:['0'],
      tradePassword:['',[Validators.required]]
      
    });

    
    this.withdrawalRequestForm.get('withdraw_amount').valueChanges
    .subscribe(value => {
        this.withdrawalRequestForm.patchValue({
          transferFee : (value * 0.02) < 5 ? 5 :  ( (value * 0.02) >= 15 ? 15 :  (value * 0.02) )
        });
    });

/*
    this.withdrawalRequestForm.get('bankInfoSwift').valueChanges
    .subscribe(value => {
       
        let selected = this.bankInfo.filter(e =>
          e.id==value
        );

        this.selectedBankInfo = selected;

        this.withdrawalRequestForm.patchValue({
          full_name_of_account_holder: selected[0].fullNameAcctHolder,
          swift_aba_code: selected[0].BankABA,
          institution_number: selected[0].InstitutionNo,
          branch_number: selected[0].BranchNo,
          account_number: selected[0].AccountNo,
          bank_name:selected[0].BankName,
          bank_address: selected[0].BankAddress,
          receiver_name: selected[0].receiverName,
          receiver_address: selected[0].ReceiverAddress,
          account_type:selected[0].acctType //todo: need map to real field
        });
    });
*/
    

   

    this.getUser();
    this.getWithdrawalRequests() 
   
  }

  clearAllValidators() {
    
      this.withdrawalRequestForm.get('withdraw_amount').clearValidators();
      this.withdrawalRequestForm.get('swift_aba_code').clearValidators();
      //this.withdrawalRequestForm.get('ach_routing_number').clearValidators();
      this.withdrawalRequestForm.get('institution_number').clearValidators();
      this.withdrawalRequestForm.get('branch_number').clearValidators();
      this.withdrawalRequestForm.get('account_number').clearValidators();
      this.withdrawalRequestForm.get('bank_name').clearValidators();
      this.withdrawalRequestForm.get('bank_address').clearValidators();
      this.withdrawalRequestForm.get('receiver_name').clearValidators();
      this.withdrawalRequestForm.get('receiver_address').clearValidators();
        /*  Set.30,2024 By Victor
      this.withdrawalRequestForm.get('receiver_city').clearValidators();
      this.withdrawalRequestForm.get('receiver_province').clearValidators();
      this.withdrawalRequestForm.get('receiver_country').clearValidators();
      this.withdrawalRequestForm.get('receiver_postcode').clearValidators();
      */
      this.withdrawalRequestForm.get('full_name_of_account_holder').clearValidators();
            
      //this.withdrawalRequestForm.clearValidators();
      this.withdrawalRequestForm.updateValueAndValidity();
  }


  public getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public getStatusDes(no: number) {
    let desc = '';
    switch (no) {
      case 0:
        desc = 'Init';
        break;
      case 1:
        desc = 'Pending';
        break;
      case 2:
        desc = 'Approved';
        break;  
      case 3:
        desc = 'Cancelled';
        break;  
      default:
        // Code to execute if no case matches
    }

    return desc;
  }

  public getWithdrawalRequests() {
    this.evgService.getWithdrawalRequests().pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        if(resp.success){
          if (resp.data[0].results) {
              let results = resp.data[0].results;
              this.withdrawalRequests.length = 0;
              results.forEach(e => {
                e.statusDes = this.getStatusDes(e.status);
                this.withdrawalRequests.push(e);
              });
              this.cdRef.detectChanges();
          } 
        }else{
          //this.tradePass_error = 1 ;
          this.responseHandlerService.handleResponse(resp);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  public withdrawalRequest(formDirective)
  {
    if(this.withdrawalRequestForm.valid){

      let inPass = this.withdrawalRequestForm.get('tradePassword').value;
      //if(confirm(this.translate.translate.instant('TRANSFERTODISTRIBUTORQ'))) { //"Transfer to Distributor?"
        this.evgService.verifyTradePass({tradePass : inPass}).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
          if (resp) {
            if(resp.success){
              if (resp.data[0].results) {

      let data = this.withdrawalRequestForm.value;
      data.id = this.selectedRequest.id;
      data.transferFee = this.selectedRequest.fee;
      data.transType = 0;

      if(data.withdrawal_type == 1) {
        data.ach_withdraw_amount = data.withdraw_amount;
        data.ach_routing_number = data.swift_aba_code;
        data.ach_institution_number = data.institution_number;
        data.ach_branch_number = data.branch_number;
        data.ach_account_number = data.account_number;
        data.ach_bank_name = data.bank_name;
        data.ach_bank_address = data.bank_address;
        data.ach_receiver_name = data.receiver_name;
        data.ach_receiver_address = data.receiver_address;
        /*  Set.30,2024 By Victor
        data.ach_receiver_city = data.receiver_city;
        data.ach_receiver_province = data.receiver_province;
        data.ach_receiver_postcode = data.receiver_postcode;
        data.ach_receiver_country = data.receiver_country;
        */
        data.ach_account_type =  data.account_type;
        data.ach_full_name_of_account_holder = data.full_name_of_account_holder;
        data.ach_withdrawal_type = data.withdrawal_type;
      }
      
      const modalRef = this.ngDialog.open(ConfirmWithdrawalRequestComponent, { windowClass: 'confirm-withdrawal-request-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.data = data;
      modalRef.result.then((data) => {
        if(data){
          this.sharedUtilService.resetForm(this.withdrawalRequestForm,formDirective);
          //this.step_no=0;
          this.ngOnInit();
        }
      }, (reason) => {
        //console.log(reason);
      });
    }else{this.tradePass_error = 1 ;
      this.cdRef.markForCheck();
    }
  }else{this.tradePass_error = 1 ;
    this.cdRef.markForCheck();
  }
}else{this.tradePass_error = 1 ;
  this.cdRef.markForCheck();
}
});

    }
  }

 
 public handleBuild(id:number) {
      this.evgService.lockWithdrawalRequest(id).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        if(resp.success){

          let selected = this.withdrawalRequests.filter(e => id == e.id);
          //this.withdrawal_type = selected[0].withdrawal_type;
          
          this.withdrawalRequestForm.patchValue({
            withdraw_amount: selected[0].amount,
            swift_aba_code: selected[0].Withdrawal_type==0?selected[0].BankABA:selected[0].ACH_routing,
            institution_number: selected[0].InstitutionNo,
            branch_number: selected[0].BranchNo,
            account_number: selected[0].AccountNo,
            bank_name: selected[0].BankName,
            bank_address: selected[0].BankAddress,
            receiver_name: selected[0].receiverName,
            receiver_address: selected[0].ReceiverAddress,
        /*  Set.30,2024 By Victor
            receiver_city: selected[0].receiver_city,
            receiver_province: selected[0].receiver_province,
            receiver_postcode: selected[0].receiver_postcode,
            receiver_country: selected[0].receiver_country,
            */
            account_type: selected[0].account_type==0?'0':'1',
            full_name_of_account_holder: selected[0].AccountHolder,
            withdrawal_type: selected[0].Withdrawal_type==0?'0':'1'
          });

        this.withdrawalRequestForm.markAsPristine();
        this.selectedRequest = selected[0];
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

  }


  public handleCancel(id:number) {
    let selected = this.withdrawalRequests.filter(e => id == e.id);

    let data = new Object();
    data = {
      action: 1,
      id: id,
      transferFee : selected[0].fee,
      withdraw_amount: selected[0].amount,
      swift_aba_code: selected[0].BankABA,
      institution_number: selected[0].InstitutionNo,
      branch_number: selected[0].BranchNo,
      account_number: selected[0].AccountNo,
      bank_name: selected[0].BankName,
      bank_address: selected[0].BankAddress,
      receiver_name: selected[0].receiverName,
      receiver_address: selected[0].ReceiverAddress,

        /*  Set.30,2024 By Victor
      receiver_city: selected[0].receiver_city,
      receiver_province: selected[0].receiver_province,
      receiver_postcode: selected[0].receiver_postcode,
      receiver_country: selected[0].receiver_country,
      */
      account_type:selected[0].acctType,
      full_name_of_account_holder: selected[0].AccountHolder,
      withdrawal_type:selected[0].Withdrawal_type
    };

    if(selected[0].Withdrawal_type) {

      data = {
        action: 1,
        id: id,
        transferFee : selected[0].fee,
        withdraw_amount: selected[0].amount,
        ach_routing_number: selected[0].ach_routing,
        ach_institution_number: selected[0].InstitutionNo,
        ach_branch_number: selected[0].BranchNo,
        ach_account_number: selected[0].AccountNo,
        ach_bank_name: selected[0].BankName,
        ach_bank_address: selected[0].BankAddress,
        ach_receiver_name: selected[0].receiverName,
        ach_receiver_address: selected[0].ReceiverAddress,
        /*  Set.30,2024 By Victor
        ach_receiver_city: selected[0].receiver_city,
        ach_receiver_province: selected[0].receiver_province,
        ach_receiver_postcode: selected[0].receiver_postcode,
        ach_receiver_country: selected[0].receiver_country,
        */
        ach_account_type:selected[0].account_type,
        ach_full_name_of_account_holder: selected[0].AccountHolder,
        withdrawal_type:selected[0].Withdrawal_type
      };

    }

    const modalRef = this.ngDialog.open(ConfirmWithdrawalRequestComponent, { windowClass: 'confirm-withdrawal-request-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.data = data;
      modalRef.result.then((data) => {
        if(data){
          //this.sharedUtilService.resetForm(this.withdrawalRequestForm,formDirective);
          //this.step_no=0;
          this.ngOnInit();
        }
      }, (reason) => {
        //console.log(reason);
      });

    this.selectedRequest = selected[0];

  }
  


}
