import { Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IPayPalConfig, ICreateOrderRequest,NgxPayPalModule } from 'ngx-paypal';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
/*
@NgModule({
  imports: [
    NgxPayPalModule
  ],
})
*/
export class HomeComponent implements OnInit {
  public amount:string='10.0';
  public currency:string="USD";

  public payPalConfig:IPayPalConfig=null;// any;
  public showPaypalButtons: boolean=true;
  showSuccess: boolean;

  constructor(public translate : TranslateConfigService, public router : Router) { }
  @ViewChild('video') video : ElementRef;
  ngOnInit(): void {
    this.initConfig();
    /*
    this.payPalConfig = {
      currency: this.currency,
      clientId:
        'Aay8f0Gy4_dKP1Pu9WKLLuXQpy9sYUiP71ULS4I5fasI_fhcug8Uik0Oht9nLh7lGsGnrNNZT4uYyUWN',
      createOrder: data =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.currency,
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code:  this.currency,
                    value: this.amount
                  }
                }
              },
              items: [
                {
                  name: 'Byte Health Management',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: this.currency,
                    value: this.amount
                  }
                }
              ]
            }
          ]
        },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then(details => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: data => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      }
    };
    */
  }

  private initConfig(): void {
    //'Aay8f0Gy4_dKP1Pu9WKLLuXQpy9sYUiP71ULS4I5fasI_fhcug8Uik0Oht9nLh7lGsGnrNNZT4uYyUWN',
    // AaRGdBX3u2qdXytTMXX2MAfnwm_msGOfHJXSO9xbXeRqdn1wx2iAdtEHN9HTM0r57rVoOsCxbT9vYZ6g
    this.payPalConfig = {
      currency: 'EUR',
      clientId: 'AdFxdeWWdS9hJO6Mpnfrgxw-1ZnUUYixUu_hvnYzgtJbVTqV8RdEILPPdcf-nLka38gQQqcxd88KDSTL',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '9.99',
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: '9.99'
                }
              }
            },
            items: [
              {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'EUR',
                  value: '9.99',
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }


  ngOnAfterViewInit()
  {
    this.video.nativeElement.play();
  }
  openPage(pageId) {
    this.router.navigate([pageId]);

    
  }

}
