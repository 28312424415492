import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-wrinkle-serum',
  templateUrl: './wrinkle-serum.component.html',
  styleUrls: ['./wrinkle-serum.component.scss']
})
export class WrinkleSerumComponent implements OnInit {
  private translate: TranslateConfigService;
  public introduceInfo :any;

  constructor(
    translate : TranslateConfigService,
    public router : Router

  ) {

    this.translate= translate;
    this.introduceInfo = 
    {
      IntroduceTitle : this.translate.translate.instant('WRINKLESERUM-Instructions-for-use'),
      introduceContent : this.translate.translate.instant('WRINKLESERUM-Instructions-for-use-P1'),
      IngredientTitle : this.translate.translate.instant('INGREDIENTSTIT'),
      IngredientContent : this.translate.translate.instant('WRINKLEINGRE'),
    }
    //console.log('RestorativeComponent introduceInfo:', this.introduceInfo);
  
  }

  ngOnInit(): void {
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
