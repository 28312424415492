import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-lock-unlock-user-account',
  templateUrl: './lock-unlock-user-account.component.html',
  styleUrls: ['./lock-unlock-user-account.component.scss']
})
export class LockUnlockUserAccountComponent implements OnInit {

  public lockForm: FormGroup;
  public unlockForm: FormGroup;
  public questions : Array<any>=[];
  public isProcessing1 = false;
  public isProcessing2 = false;

  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.lockForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

    this.unlockForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

  }

  public lockUserAccount(formDirective)
  {
    this.isProcessing1 = true;
    this.evgService.lockUserAccount( this.lockForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.sharedUtilService.resetForm(this.lockForm,formDirective);
      }
      this.isProcessing1 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  public unlockUserAccount(formDirective)
  {
    this.isProcessing2 = true;
    this.evgService.unlockUserAccount( this.unlockForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.sharedUtilService.resetForm(this.unlockForm,formDirective);
      }
      this.isProcessing2 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing2 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
