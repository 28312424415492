<div class="user-downlines-limit-container">
    <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #downlineLimit="ngForm" [formGroup]="downlineLimitForm" (keydown.enter)="$event.preventDefault()" autocomplete="dl-form">
                        <div class="p-3">
                            <h2>{{ 'SETUSERDOWNLINELIMIT' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="userId"  type="text" class="form-control text-box" id="userId"
                                name="userId"  placeholder="{{ 'USERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="downlineLimitForm.get('userId').hasError('required')">{{'USERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="maxUnders"  type="text" class="form-control text-box" id="maxUnders" 
                                name="maxUnders" placeholder="{{ 'MAXUNDERS' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="downlineLimitForm.get('maxUnders').hasError('required')">{{'MAXUNDERSREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>


                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!downlineLimitForm.valid || isProcessing" (click)="updateDownlines(downlineLimit)" >{{ "UPDATEBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
        
    </div>
</div>