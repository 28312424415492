<div class="withdrawal-monthly-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'WITHDRAWALMONTHLYRECORD' | translate}}</h3>
              <div class="text-right mb-2" *ngIf="selectedWithdrawalRecordDetails">
                <a mat-stroked-button color="primary" (click)="unSelectRecord()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered" *ngIf="!selectedWithdrawalRecordDetails">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'YEARMONTH' | translate}}</th>
                          <th scope="col">{{'TIMES' | translate}}</th>
                          <th scope="col">{{'TOTALAMOUNT' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let withdrawalRecord of withdrawalRecords; index as i;">
                          <td><a (click)="selectRecord(withdrawalRecord.MemberNo,withdrawalRecord.YearMonth)" style="cursor: pointer; color: blue;" >{{ withdrawalRecord.MemberNo}}</a></td>
                          <td>{{ withdrawalRecord.YearMonth}}</td>
                          <td>{{ withdrawalRecord.times }}</td>
                          <td>{{ withdrawalRecord.totalAmount | currency:''}}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="selectedWithdrawalRecordDetails">
                        <thead>
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col">{{'MEMBERNO' | translate}}</th>
                            <th scope="col">{{'COMMENT' | translate}}</th>
                            <th scope="col">{{'SECRETQUESTION' | translate}}</th>
                            <th scope="col">{{'SECRETANSWER' | translate}}</th>
                            <th scope="col">{{'TOUSERID' | translate}}</th>
                            <th scope="col">{{'AMOUNT' | translate}}</th>
                            <th scope="col">{{'ADDTIME' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let selectedWithdrawalRecordDetail of selectedWithdrawalRecordDetails; index as i;">
                            <td>{{ selectedWithdrawalRecordDetail.MemberNo}}</td>
                            <td>{{ selectedWithdrawalRecordDetail.comment }}</td>
                            <td>{{ selectedWithdrawalRecordDetail.secretQ }}</td>
                            <td>{{ selectedWithdrawalRecordDetail.secretA }}</td>
                            <td>{{ selectedWithdrawalRecordDetail.toUserId }}</td>
                            <td>{{ selectedWithdrawalRecordDetail.amount | currency:''}}</td>
                            <td>{{ selectedWithdrawalRecordDetail.AddTime }}</td>

                          </tr>
                        </tbody>
                      </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>