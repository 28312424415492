<div class="container">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

      <!-- <div class="title-block">
        <h1 translate>BHM</h1>
        <p>Providing the highest quality of 3 dimensional products to our customers</p>
        <button class="btn mt-5" (click)="openPage('water-analysis')" translate>HLEARNM</button>
      </div> -->
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      </ol>

      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/slide1.png" alt="First slide">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
       
        <div class="carousel-item" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/slide2.png" alt="Second slide">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/slide3.png" alt="Third slide">
          <div class="carousel-caption d-none d-md-block mt-5">
          </div>
        </div>
        <div class="carousel-item" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/slide4.png" alt="Fourth slide">
            <div class="carousel-caption d-none d-md-block">
            </div>
        </div>
        
      </div>
      
      <!---
      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/slide 1.png" alt="First slide">
          <div class="carousel-caption d-none d-md-block">
            <h1 translate>BHM</h1>
            <p translate>BHMDESC</p>
            <button class="btn mt-5" (click)="openPage('about')" translate>HLEARNM</button>
          </div>
        </div>
        
        <div class="carousel-item" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/image.png" alt="Second slide">
          <div class="carousel-caption d-none d-md-block">
            <h1 translate>DDW</h1>
            <button class="btn mt-5" (click)="openPage('water-analysis')" translate>HLEARNM</button>
          </div>
        </div>
        <div class="carousel-item" data-interval="4000">
          <img class="carousel-image d-block w-100 h-50" src="./../../../../assets/images/files/home/home_pic11.png" alt="Third slide">
          <div class="carousel-caption d-none d-md-block mt-5">
            <h1 translate>AIRPURIFIER</h1>
            <p translate>AIRPURIFIERDESC</p>
            <button class="btn mt-5" (click)="openPage('air-purifier')" translate>HLEARNM</button>
          </div>
        </div>
        <div class="carousel-item" data-interval="18000">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe id="video" src="https://player.vimeo.com/video/446962915?h=7056c3c3fa?autoplay=1&loop=1&autopause=0&background=1:" allow="autoplay; fullscreen; picture-in-picture loop" allowfullscreen autoplay loop></iframe>
            <div class="carousel-caption d-none d-md-block">
              <h1 translate>SANITIZER</h1>
              <button class="btn mt-5" (click)="openPage('washer')" translate>HLEARNM</button>
            </div>
        </div>
        </div>
      </div>
    -->
      
      <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a> -->
    </div>
    <!-- <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title" >
        <h1>
          {{ 'BHM' | translate }}
        </h1>
    </div> -->
    <!-- <div class="banner-title">
      <br><button class="btn banner-button" (click)="openPage('water-analysis')" translate>HLEARNM</button></h1> -->
    <!--<app-byte-paypal [amount]="9.99" currency="CAD" ></app-byte-paypal>-->
    <div class="our-mission">
      <div class="card">
        <div class="row" style="margin: 0;">
          <div class="col-sm-4 d-flex justify-content-center" style="padding-left: 0;padding-right: 0;">
            <!----
            <div class="card-block  p-5">
              <h1 class="card-title" translate>VISION</h1>
              <div class="separator mb-3">
              </div>
              <p class="card-text mb-2" translate>VISIONP1</p>
              <p class="card-text mb-3" translate>VISIONP2</p>
              <a class="btn card-link" (click)="openPage('about')"  translate>OURMISSIONBTN</a>
            </div>
          -->
            <div class="card-block card-background p6" style="padding: 5.2rem 5rem 5rem 5rem;">
              <h1 class="card-title" translate>MISSION</h1>
              <div class="separator mb-3">
              </div>
              <h2 class="card-title-2">BYTEHEALTHMANAGEMENT</h2>
              <p class="card-text mb-2" translate>HM-ABOUTMISSION</p>
              
              <a class="btn card-link" style="margin-top: 2rem;" (click)="openPage('hydrogen-energy')"  translate>OURMISSIONBTN</a>
            </div>
          </div>
          <div class="col-sm-8" style="padding-left: 0;padding-right: 0;">
            <div class="card-block">
              <div class="text-center">
                <img class="block-image" style="width:100%; height: 100%; vertical-align: bottom;" src="./../../../../assets/images/files/home/missionImageM.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <!--<app-carbon60 style="width: 100%; height: 18rem;"></app-carbon60>
  <div style="width: 100%; height: 18rem; display:block;z-index:999;">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>
  -->
  <div style="width: 100%; height: 0.1rem; display:block;z-index:999;">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>

    <div class="find-more d-flex flex-column">
        <h1 class="more-head" translate>
            HM-FIND-MORE
            <br>HM-BYTE-MANAGEMENT
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="more-body">
            <div class="row d-flex justify-content-center"> <!-- </div>justify-content-between"> </div>justify-content-center">-->
                  <div class="card ml-1">
                    <div class="card-body">
                      <img class="card-image mb-3" src="./../../../../assets/images/files/home/about.jpeg" alt="">
                      <h5 class="card-title mb-3" translate>ABOUT</h5>
                      <p class="card-text" translate>ABOUTDESC</p>
                      
                    </div>
                    <div class="card-footer">
                      <a class="card-link" (click)="openPage('about')" translate>ABOUTBTN</a>
                    </div>
                  </div>
                  <div class="card ml-1">
                    <div class="card-body">
                      <img class="card-image mb-3" src="./../../../../assets/images/files/home/hydrogen.jpg" alt="">
                      <h5 class="card-title mb-3" style="text-transform: uppercase;" translate>HENERGYTITLE</h5>
                      <p class="card-text" translate>MINSTRENGTHDESC</p>
                      
                    </div>
                    <div class="card-footer">
                      <a class="card-link" (click)="openPage('hydrogen-energy')" translate>ABOUTBTN</a>
                    </div>
                  </div>

                    <div class="card ml-1">
                      <div class="card-body">
                        <img class="card-image-1 mb-3" src="./../../../../assets/images/files/home/icon-c60.png" alt="">
                        <h5 class="card-title mb-3" translate>HM-ICON-C60</h5>
                        <p class="card-text" translate>HM-ICON-C60-DESC</p>
                        
                      </div>
                      <div class="card-footer">
                        <a class="card-link" (click)="openPage('carbon60')"  translate>ABOUTBTN</a>
                      </div>
                    </div>
                    <div class="card ml-1">
                      <div class="card-body">
                        <img class="card-image-1 mb-3" src="./../../../../assets/images/files/home/icon-vitality-trio.png" alt="">
                        <h5 class="card-title mb-3" translate>HM-VITALITY-TRIO</h5>
                        <p class="card-text" translate>HM-VITALITY-TRIO-DESC</p>
                        
                      </div>
                      <div class="card-footer">
                        <a class="card-link" (click)="openPage('products')"  translate>ABOUTBTN</a>
                      </div>
                    </div>
                    <div class="card ml-1">
                      <div class="card-body">
                        <img class="card-image-1 mb-3" src="./../../../../assets/images/files/home/icon-beauty-trio.png" alt="">
                        <h5 class="card-title mb-3" translate>HM-BEAUTY-TRIO</h5>
                        <p class="card-text" translate>HM-BEAUTY-TRIO-DESC</p>
                        
                      </div>
                      <div class="card-footer">
                        <a class="card-link" (click)="openPage('products')"  translate>ABOUTBTN</a>
                      </div>
                    </div>
                    <div class="card ml-1">
                      <div class="card-body">
                        <img class="card-image-1 mb-3" src="./../../../../assets/images/files/home/icon-fulv.png" alt="">
                        <h5 class="card-title mb-3" translate>HM-FULVIC-MUCUNA</h5>
                        <p class="card-text" translate>HM-FULVIC-MUCUNA-DESC</p>
                        
                      </div>
                      <div class="card-footer">
                        <a class="card-link" (click)="openPage('fulvic-mucuna-pruriens')"  translate>ABOUTBTN</a>
                      </div>
                  </div>
            </div>
        </div>
    </div>

    <div class="new_epa mb-5">
      <!-- <div class="row card flex-row justify-content-center flex-wrap p-5">-->
        <div class="d-flex justify-content-around flex-sm-row flex-column">
        
            
          <div class="flex_padding_1">
            <img style="vertical-align:middle;"   src="./../../../../assets/images/files/home/C60image.png" alt="">
          </div>
        
       
            <div class="flex_padding_2">
                <h1 class="card-title" translate>HM-WT-C60</h1>
                <h1>C60</h1>
                
                <div class="separator mb-5">
                </div>
                
                <p class="card-text mb-5" translate>HM-WT-C60-DESC</p>
                <a class="btn card-link p-3" (click)="openPage('carbon60')"  translate>EPABTN</a>
            </div>
          </div>
        
            <!--
            <div class="w-100"></div>
            -->
        
    </div>


   

    
    <!-- <div class="our-mission">
      <div class="card flex-row justify-content-end flex-wrap p-5">
          
          
          <div class="card-header border-0">
        </div>
        </div>
    </div> -->

    <!-- <div class="our-mission">
      <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body p-5">
                  <h1 class="card-title" translate>OURMISSION</h1>
                  <div class="separator mb-5">
                  </div>
                  <p class="card-text mb-5" translate>OURMISSIONDESC</p>
                  <a class="btn card-link p-3" translate>OURMISSIONBTN</a>
              </div>
            </div>
          </div>
          <div class="col-md-8">
              <div class="image">

              </div>
          </div>
      </div>
        
    </div> -->

    
</div>
