import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-view-air-puifier',
  templateUrl: './view-air-puifier.component.html',
  styleUrls: ['./view-air-puifier.component.scss']
})

export class ViewAirPuifierComponent implements OnInit {
  @Input() addCloseBut : boolean=false;
  @Input() translate : TranslateConfigService;

  constructor(
      public activeModal : NgbActiveModal,
      public router : Router
  ) { }

  ngOnInit(): void {
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
