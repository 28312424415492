import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferRecord, TransferRecordDetail } from 'src/app/modules/common/Accounting';
import { Sale } from 'src/app/modules/common/Reports';
import { RankRecord } from 'src/app/modules/common/Super';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-rank-reports',
  templateUrl: './rank-reports.component.html',
  styleUrls: ['./rank-reports.component.scss']
})
export class RankReportsComponent implements OnInit {

  public user: User;
  public rankRecords : RankRecord[] = [];
  public rankUsers : any;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.rankReport();
  }

  rankReport()
  {
    this.evgService.rankReport( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.rankRecords = resp.data[0].results;
          console.log("userTransferRecords",this.rankRecords );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getUsersByRank(rank)
  {
    this.evgService.getUsersByRank( {rank : rank} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        console.log(resp);
        if(resp.data[0]){
          this.rankUsers = resp.data[0].results;
          console.log("rankUsers",this.rankUsers );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  unselectUser()
  {
    this.rankUsers = null;
  }




}
