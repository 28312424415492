<div class="invoice-link-list-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'WHOLELIST' | translate}}</h3>
              <div class="row buttonRow">
                  <button mat-stroked-button color="primary" class="btn btn-default pull-left mb-3 ml-2" (click)="onpenWholesale(null)">{{ 'CREATEBTN' | translate }}</button>
              </div>
              <div class="table-responsive" style="margin-top: 0.5rem;">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'WHOLESALE' | translate}}</th>
                          <th scope="col">{{'PHONENUM' | translate}}</th>
                          <th scope="col">{{'EMAILADR' | translate}}</th>
                          <th scope="col">{{'CONTACTPERSON' | translate}}</th>
                          <th scope="col">{{'COUNTRYLABEL' | translate}}</th>
                          <th scope="col">{{'PROVINCELABEL' | translate}}</th>
                          <th scope="col">{{'CITYLABEL' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of wholesales; index as i;">
                          <td><button mat-stroked-button color="primary" class="btn btn-default pull-left" (click)="onpenWholesale(request.id)">{{ 'EDITBTN' | translate }}</button></td>
                          <td>{{ request.customer }}</td>
                          <td>{{ request.phone }}</td>
                          <td>{{ request.email }}</td>
                          <td>{{ request.contact_person }}</td>
                          <td>{{ request.country }}</td>
                          <td>{{ request.province }}</td>
                          <td>{{ request.city }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>