import { Component, OnInit, Input } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.scss']
})
export class ViewProductsComponent implements OnInit {
  @Input() addCloseBut : boolean=false;
  @Input() translate : TranslateConfigService;

  constructor(
      public activeModal : NgbActiveModal,
      public router : Router
  ) { }

  ngOnInit(): void {
  }

  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
