import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddProductReviewComponent } from 'src/app/modules/add-product-review/add-product-review.component';
import { Cart } from 'src/app/modules/common/Cart';
import { CartItem } from 'src/app/modules/common/CartItem';
import { Products,ProductInfo } from 'src/app/modules/common/Products';
import { User } from 'src/app/modules/common/User';
import { StarRatingDetailComponent } from 'src/app/modules/star-rating-detail/star-rating-detail.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ProductsComponent } from 'src/app/components/pages/products/products.component';
import { AirPurifierComponent } from 'src/app/components/pages/air-purifier/air-purifier.component';
import { WasherComponent } from 'src/app/components/pages/washer/washer.component';
import { ViewFulvicAcidComponent } from 'src/app/modules/views/view-fulvic-acid/view-fulvic-acid.component';
import { ViewHydrogenEnergyComponent } from 'src/app/modules/views/view-hydrogen-energy/view-hydrogen-energy.component';
import { ViewProductsComponent } from 'src/app/modules/views/view-products/view-products.component';
import { ViewWasherComponent } from 'src/app/modules/views/view-washer/view-washer.component';
import { ViewAirPuifierComponent } from 'src/app/modules/views/view-air-puifier/view-air-puifier.component';
import { ViewPetC60Component } from 'src/app/modules/views/view-pet-c60/view-pet-c60.component';
import { ViewCarbon60Component } from 'src/app/modules/views/view-carbon60/view-carbon60.component';
import { ViewPetTraceComponent } from 'src/app/modules/views/view-pet-trace/view-pet-trace.component';
import { ViewFirmingCreamComponent } from 'src/app/modules/views/view-firming-cream/view-firming-cream.component';
import { ViewHydratingSerumComponent } from 'src/app/modules/views/view-hydrating-serum/view-hydrating-serum.component';
import { ViewNightCreamComponent } from 'src/app/modules/views/view-night-cream/view-night-cream.component';
import { ViewTintedPoreComponent } from 'src/app/modules/views/view-tinted-pore/view-tinted-pore.component';
import { ViewWrinkleSerumComponent } from 'src/app/modules/views/view-wrinkle-serum/view-wrinkle-serum.component';
import { ViewRestorativeComponent } from 'src/app/modules/views/view-restorative/view-restorative.component';


@Component({
  selector: 'app-select-products',
  templateUrl: './select-products.component.html',
  styleUrls: ['./select-products.component.scss']
})
export class SelectProductsComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() cart_items : CartItem[] = [];
  @Input() selectedProducts: Array<ProductInfo> = [];
  //@Output() selectedEvent = new EventEmitter<Product>();
  
  private _unsubscribe$ = new Subject<any>();
  public products: Array<ProductInfo> = [];
  public cart : Cart;
  public quantity : number = 1;
  public user : User;
  public isRefferDisabled : boolean = false;
  public isRecommendDisabled : boolean = false;
  public tax_rateList : any[]=[];
  public tax_rate : any;
  public totalAmount=0;

  //public cart_items : CartItem[] = [];
  /*
*/
number=0
  constructor(
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal
  ) {
   }

  ngOnInit(): void {
    //this.products = this.selectedProducts ;
    //this.products  = Object.assign([], this.selectedProducts);
    this.selectedProducts.forEach(val => this.products.push(Object.assign({}, val)));
    //console.log('in SelectProductsComponent ngOnInit products:',this.products);
    this.qtyChange({},-1);
    //this.getUser();
    //this.getProducts();
    //this.shareinfoService.reloadTaxRate();
    //this.getTaxRate();
    //this.getCart();
    //this.getProductReviews();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  async getProductReview(pId)
  {
    let resp =  await this.evgService.getProductReview({pId : pId}).toPromise();
    return resp.data[0].results[0];
  }

  async getProductReviews()
  {
    let resp =  await this.evgService.getProductReviews({}).toPromise();
    return resp.data[0].results[0];
  }

  addProductReview(product)
  {
      const modalRef = this.ngDialog.open(AddProductReviewComponent, { windowClass: 'add-product-review-modal' ,centered: true, size: 'lg', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.product = product;

      modalRef.result.then((data) => {
        //this.getProducts();
      }, (reason) => {
        //this.getProducts();
      });
  }

  showCustomerReviews(reviews)
  {
    const modalRef = this.ngDialog.open(StarRatingDetailComponent, { windowClass: 'show-star-details-modal' ,centered: true, size: 'sm', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.reviews = reviews;

    modalRef.result.then((data) => {
      
    }, (reason) => {
      
    });
  }
  qtyChange(evtValue,index) {
    let summary=0;
    //console.log('event.target.value :',(evtValue.target ? evtValue.target.value: undefined));
    //console.log('select-product qtyChange index: evtValue:',index,evtValue);
    this.products.forEach( (element,i) => {
      //console.log('select-product qtyChange this.products.forEach element:',element);
      if (i===index) {
        element.pQty= parseInt(evtValue.target.value);
      }
      summary += element.pQty * element.pPrice;
      
    });
    this.totalAmount=summary;
    //console.log('select-product qtyChange this.products:',this.products);

  }

  viewProductDetail(page_name) {
    let pagesList=[
                    {pagename: "fulvic-acid", pageComponent: ViewFulvicAcidComponent },
                    {pagename: "hydrogen-energy", pageComponent: ViewHydrogenEnergyComponent },
                    {pagename: "products", pageComponent: ViewProductsComponent },
                    {pagename: "washer", pageComponent: ViewWasherComponent },
                    {pagename: "air-purifier", pageComponent: ViewAirPuifierComponent },
                    {pagename: "pet-c60", pageComponent: ViewPetC60Component},
                    {pagename: "carbon60", pageComponent: ViewCarbon60Component},
                    {pagename: 'pet-trace', pageComponent: ViewPetTraceComponent},
                    {pagename: 'firming-cream', pageComponent: ViewFirmingCreamComponent},
                    {pagename: 'hydrating-serum', pageComponent: ViewHydratingSerumComponent},
                    {pagename: 'night-cream', pageComponent: ViewNightCreamComponent},
                    {pagename: 'tinted-pore', pageComponent: ViewTintedPoreComponent},
                    {pagename: 'wrinkle-serum', pageComponent: ViewWrinkleSerumComponent},
                    {pagename: 'restorative', pageComponent: ViewRestorativeComponent},
                  ];

    let pageNames = page_name ? page_name.split(';') : null;
    console.log('viewProductDetail pageNames:',pageNames);
    if (pageNames && pageNames.length>0) {
     pageNames.forEach((value, index) =>{
       if (value ) {
         let findPage = pagesList.find(item=> item.pagename==value);
         if (findPage) {
           const modalRef = this.ngDialog.open(findPage.pageComponent, { windowClass: 'cart-modal' ,centered: true, size: 'lg', keyboard: false, backdrop: true });
           modalRef.componentInstance.translate = this.translate;
           modalRef.componentInstance.addCloseBut = true;
         }
       }
     })
    }
   }
 
}
