<div class="renew-ewallet-password-container">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close('Close click')" alt="close"></span>
        </div>
    </div>
    <div class="row gutters-sm">
      <div class="col-md-12 mb-3">
        <div class="card mt-3">
          <div class="card-body">
            <h2 translate>CHANGEPASSEWALLETLABEL</h2>
            <div class="d-flex flex-column align-items-center text-center">
              
              <form [formGroup]="passwordForm" #password="ngForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3" autocomplete="p-form">
                <mat-form-field style="width: 100%; text-align: center" ngbAutofocus>
                    <input matInput formControlName="veryCode"  type="text" class="form-control text-box" id="veryCode" aria-describedby="veryCode"
                     name="veryCode" [placeholder] = "'VERIFYCODE' | translate"
                    autocomplete="off"
                    />
                    <mat-error class="error-message" *ngIf="passwordForm.get('veryCode').hasError('required')">{{ 'VERIFYCODEREQ' | translate }}</mat-error>
                </mat-form-field>
                <ng-container style="text-align: left;" *ngIf="userdata">
                  <div class="row mb-3" style="text-align: left !important;">
                    <div class="col">
                      <div class="form-check" *ngIf="userdata.mobile">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="selectCode('mobile')">
                        <label class="form-check-label" for="flexRadioDefault1">
                          {{ userdata.mobile }}
                        </label>
                      </div>
                      <div class="form-check" *ngIf="userdata.email">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="selectCode('email')">
                        <label class="form-check-label" for="flexRadioDefault2">
                          {{ userdata.email }}
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <button mat-stroked-button [disabled]="!selectedCode" (click)="onSendCode()" color="primary" class="align-right">{{ 'SENDVERIFYCODE' | translate }}</button>
                    </div>
                  </div>
                  
                </ng-container>
                <ng-container *ngIf="!userdata">
                    <h3>No Mobile or Email Found!</h3>
                </ng-container>
                
                <mat-form-field style="width: 100%; text-align: center">
                  <input matInput formControlName="password"  type="password" class="form-control text-box" id="password" aria-describedby="passwordHelp"
                   name="password" autocomplete="off" [placeholder]="'NEWPASSLABEL' | translate"
                  />
                  <mat-error class="error-message" *ngIf="passwordForm.get('password').hasError('required')">{{ 'NEWPASSREQ' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%; text-align: center;">
                  <input matInput formControlName="confirmPassword"  type="password" class="form-control text-box" id="confirmPassword" aria-describedby="confirmPasswordHelp"
                   name="confirmPassword" autocomplete="off" [placeholder]="'CONFIRMPASSLABEL' | translate" [errorStateMatcher]="matcher"
                  />
                  <mat-error class="error-message" *ngIf="passwordForm.hasError('notSame')">{{ 'CONFIRMPASSNOTSAME' | translate }}</mat-error>
                </mat-form-field>

                <button mat-stroked-button  (click)="onChangePassword(password)" color="primary" class="align-right mt-4">{{ 'UPDATEPASSBTN' | translate }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
      </div>
      
    </div>
    

</div>