<div class="invoice-link-list-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'WINVOICELIST' | translate}}</h3>
              <div class="text-right mb-2">
                <form [formGroup]="requestsForm" (keydown.enter)="$event.preventDefault()" autocomplete="rf-form">
                    <mat-form-field>
                        <!--<mat-label>{{'INFOTYPE' | translate}}</mat-label> -->
                        <mat-select formControlName="infoType"  type="text" class="form-control" id="infoType" aria-describedby="infoType" 
                        autocomplete="off">
                            <mat-option  [value]="0">{{ 'ALLINVOICES' | translate}}</mat-option>
                            <mat-option  [value]="1">{{ 'PAIDINVLINK' | translate}}</mat-option>
                            <mat-option  [value]="2">{{ 'UNPAyINVLINK' | translate}}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="requestsForm.get('infoType').hasError('required')">{{'INFOTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!requestsForm.valid || isProcessing" (click)="onGetRequests()" >{{ "SHOWLIST" | translate}}</button>
                    <button mat-stroked-button color="primary" class="btn btn-default pull-left pt-8 mt-4" (click)="onpenInvoiceLink(null)">{{ 'CREATEBTN' | translate }}</button>
                  </form>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'INVOICENO' | translate}}</th>
                          <th scope="col">{{'WHOLESALE' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'SENTTIMES' | translate}}</th>
                          <th scope="col">{{'PAID' | translate}}</th>
                          <th scope="col">{{'PAIDDATE' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of invoicelinks; index as i;">
                          <td><button mat-stroked-button color="primary" class="btn btn-default pull-left" (click)="onpenInvoiceLink(request.id)">{{ 'EDITBTN' | translate }}</button></td>
                          <td [ngClass]="{'over-limited': isPaid(request) }">{{ request.invoice_no }}</td>
                          <td>{{ request.customer }}</td>
                          <td>{{ request.amount }}</td>
                          <td>{{ request.sent_times }}</td>
                          <td translate>{{ getAType(request.paid) }}</td>
                          <td>{{ request.paid_at }}</td>
                          <td>{{ request.AddTime }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>