import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Products,Product } from 'src/app/modules/common/Products';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {
  @Input() picFile; //imageChangedEvent;
  @Input() product: Product =null;
  selected_product : Product;
  styleLeft: any;
  styleTop: any;


  constructor() { }

  ngOnInit(): void {
  }

  public getProductImages(pId) {

  }

}
