import { Component,OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RankHistory } from 'src/app/modules/common/RankHistory';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ShippingAddressesComponent } from 'src/app/modules/shipping-addresses/shipping-addresses.component';
import { CreditCardInfoComponent } from 'src/app/modules/credit-card-info/credit-card-info.component';
import { EWalletLoginComponent } from 'src/app/modules/ewallet-login/ewallet-login.component';

@Component({
  selector: 'app-set-autoship',
  templateUrl: './set-autoship.component.html',
  styleUrls: ['./set-autoship.component.scss']
})
export class SetAutoshipComponent implements OnInit {
  public user: User;
  public myAutoship:any=[];
  public myOffer: any=[];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing:boolean=false;
  public modified:boolean=false;
  public checkoutForm : FormGroup;
  public creditCards : Array<any>=[];
  public creditCardError : boolean=false;
  public cd_error_count:number=0;
  public cd_error_msgs:any =[];
  cdref: ChangeDetectorRef;
  eWalletLogin: boolean;
  public usefulCards=0;
  public selected_autoship:number=0;


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private router: Router,
    private ngDialog : NgbModal
  ) { }

  ngOnInit(): void {

    this.checkoutForm = this.formBuilder.group({
      isDistributor : ['']
    });
    this.getUser();
    this.getMyAutoshipProducts();
    //this.getULevelLogs();
    this.getCardsInfo();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMyAutoshipProducts(){
    this.evgService.getMyAutoshipProducts({})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.myAutoship = resp.data[0].results;
            if (this.myAutoship && this.myAutoship.length>0) {
              this.myAutoship.forEach((e,i)=>{
                if (e.inactive==0 && e.num>0) {
                  this.selected_autoship++;
                }
              });
            } else {
              this.myAutoship=[];
            }
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  autoship_click(event,index){
    //this.cart_items[index].apply_benefit = (event.target.checked ? 1:0);
    this.myAutoship[index].termFlag = (event.checked === true)? 1:0;
    this.myAutoship[index].inactive = (event.checked === true)? 0:1;
    this.modified=true;
  }

  changeAutoshipQTY(event,index){
    //console.log('changeAutoshipQTY :', event.target.value, index);
    this.myAutoship[index].nums = event.target.value;
    this.modified=true;
  }

  changeShipDate(event, index) {

    //console.log('changeShipDate :', event.target.value, index);
    this.modified=true;
    if ( event.target.value) {
      this.myAutoship[index].shipDate= event.target.value;
    }
  }

  onChangeShippingAdr(shippingAddressId,index) {
    //console.log('onChangeShippingAdr :', shippingAddressId, index);
    
    const modalRef = this.ngDialog.open(ShippingAddressesComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.selectedAddress = (shippingAddressId> 0 ? shippingAddressId : -1);
    modalRef.result.then((result) => {
      //console.log("onChangeShippingAdr return result:",result);

      //{{cart_item.Address}}&nbsp;{{cart_item.City}}&nbsp;{{cart_item.Province}}&nbsp;{{cart_item.PostCode}}
      //this.getInvoiceLinks(this.requestsForm.value);
      if (result && result.id != shippingAddressId) {
        this.myAutoship[index].Address=result.Address;
        this.myAutoship[index].City=result.City;
        this.myAutoship[index].Province=result.Province;
        this.myAutoship[index].PostCode=result.PostCode;
        this.myAutoship[index].shippingId = result.id;
        this.modified=true;
      }

    });
  }

  getCardsInfo(){
    this.usefulCards=0;
    this.evgService.getCardsInfo({}).subscribe( resp => {
      if (resp) {
        this.cd_error_count=0;
        //console.log('getCardsInfo resp: ',resp);
        if(resp.success && resp.data[0].results){
          this.creditCards = resp.data[0].results;

          if (this.creditCards.length>0) {
            let curr = new Date();
            this.creditCards.forEach((e,i)=>{
              //console.log('getCardsInfo looping e: ',e, (e.cardNo && e.firstName && e.expireDate && e.cvCode));
              if ( e.cardNo && e.firstName && e.expireDate && e.cvCode) {
                if (e.isValid==1) {
                  if (e.expireDate && e.expireDate.length==4) {
                    if (!this.shareinfoService.validateExpiry(e.expireDate)) {
                    //if (!this.validateExpiry(e.expireDate) ) {
                      this.cd_error_count++;
                      this.cd_error_msgs.push('Credit Card is expired !');
                    } else {
                      this.usefulCards++;
                    }
                  }
                }
              }
            })
          } else {
            this.cd_error_count++;
            this.cd_error_msgs.push("No credit card information yet!");
          }
        } else {
          this.cd_error_count++;
          this.cd_error_msgs.push("No credit card information yet!");
        }
      } else {
        this.cd_error_count++;
        this.cd_error_msgs.push("No credit card information yet!");
      }
      //console.log('this.cd_error_count: this.usefulCards: cards:',this.cd_error_count, this.usefulCards,this.creditCards.length);
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  showCards(){
    //console.log('showCards :',this.cd_error_count, this.cd_error_msgs);
    
    const modalRef = this.ngDialog.open(CreditCardInfoComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    //modalRef.componentInstance.selectedAddress = (shippingAddressId> 0 ? shippingAddressId : -1);
    modalRef.result.then((result) => {
      //console.log("onChangeShippingAdr return result:",result);
      this.getCardsInfo();

      //{{cart_item.Address}}&nbsp;{{cart_item.City}}&nbsp;{{cart_item.Province}}&nbsp;{{cart_item.PostCode}}
      //this.getInvoiceLinks(this.requestsForm.value);
      // if (result && result.id != shippingAddressId) {
      //   this.myAutoship[index].Address=result.Address;
      //   this.myAutoship[index].City=result.City;
      //   this.myAutoship[index].Province=result.Province;
      //   this.myAutoship[index].PostCode=result.PostCode;
      //   this.myAutoship[index].shippingId = result.id;
      //   this.modified=true;
      // }

    });
  }

  buyMoreTokens(){
   // this.router.navigate(["backmain/company-messages/" + 'ewallet-login']);
    if(!this.shareinfoService.IsEwalletLoggedIn()){
      const modalRef = this.ngDialog.open(EWalletLoginComponent, { windowClass: 'ewallet-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.result.then((data) => {
       // this.cdref.detectChanges();
        if(data == 'success') {
          this.eWalletLogin=true;
          setTimeout(()=>{
            this.router.navigate(['backmain/ewallet-overview']);
          },500);
        }
      }, (reason) => {
        //console.log(reason);
        this.cdref.detectChanges();
      });
    } else {
      this.router.navigate(['backmain/ewallet-overview']);
    }

  }

  updateAutoship(){
    if (this.modified) {
      let up_count=0;
      let selected=0;
      let sumary=0;
      let error =0;
      let subData=[];
      this.myAutoship.forEach((e,i)=>{
        if (e.inactive==0 || ( e.inactive==1 && e.id)) {
          if (e.inactive==0) {
            selected++;
          }
          if (e.nums>0) {
            subData.push( e);
            sumary += e.nums * (e.pPrice * (1-e.autoship_discount));
            up_count++;
          } else {
            //console.log('updateAutoship e:',e);
            error++;
          }
        }
      });

      if ( error > 0 && selected>0) {
        alert(this.translate.translate.instant('ENTERCORRECT'));
      } else if (up_count>0 || (selected==0 && this.selected_autoship>0)) {
        if ((sumary *2 < (this.user.totalCash + this.user.eWallet) || this.usefulCards>0) || (selected==0) ) {

          this.shareinfoService.setCreditProcess(true);
          //console.log('updateAutoship this.myAutoship: subData:', this.myAutoship,subData);

          this.evgService.updateMyAutoshipOffer(subData).subscribe( resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.success){
                this.selected_autoship = selected;
                this.modified=false;
                this.getMyAutoshipProducts();
              }
            }
            this.shareinfoService.setCreditProcess(false);
          },(errorResult) => {
            this.shareinfoService.setCreditProcess(false);
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
        } else {
          alert(this.translate.translate.instant('TOKENSANDCARD'));
        }
      } else {
        alert(this.translate.translate.instant('NODATEAVAIL'));
      }
    }
  }
}
