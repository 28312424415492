<div class="account-rank-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-5">
        
        <div class="card mb-3">
          <div class="card-body">
          <h3 class="account-title">{{ 'RANKINFORMATION' | translate}}</h3>
          <br>
            <div class="row">
              <div class="col-sm-4">
                <h4 class="account-label">{{ 'NAME' | translate}}</h4>
              </div>
              <div  class="col-sm-8 text-secondary">
                {{ user.first_name }} {{ user.last_name }}
              </div>
              
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-4">
                <h4 class="account-label">{{ 'ULEVELLABEL' | translate}}</h4>
              </div>
              <div  class="col-sm-8 text-secondary">
                {{ rankName }}
              </div>
              
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-4">
                <h4 class="account-label">{{ 'DEPTREQFULFILLED' | translate}}</h4>
              </div>
              <div  class="col-sm-8 text-secondary">
                {{  (((user.uLevel > 0) || (user.uLevel == 0 && downlines.length > 0)) && user.payFee) ? 'YES' : 'NO'  }}
              </div>
              
            </div>
            <hr>
          </div>
        </div>
      </div>

      <div class="col-md-7">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="account-title">{{'RANKHISTORY' | translate}}</h3>
              <br>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <!-- <th scope="col">{{'MEMBERNO' | translate}}</th> -->
                          <th scope="col">{{'FROMLEVEL' | translate}}</th>
                          <th scope="col">{{'TOLEVEL' | translate}}</th>
                          <!-- <th scope="col">{{'COMMENT' | translate}}</th> -->
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let history of rankHistory; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <!-- <td>{{ history.MemberNo }}</td> -->
                          <td>{{ user.pTimes > 0 ? shareinfoService.getULevelName(history.fromLevel) : "Member" }}</td>
                          <td>{{ user.pTimes > 0 ? shareinfoService.getULevelName(history.toLevel) : "Member" }}</td>
                          <!-- <td>{{ history.comment }}</td> -->
                          <td>{{ history.addTime }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>