import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-renew-ewallet-password',
  templateUrl: './renew-ewallet-password.component.html',
  styleUrls: ['./renew-ewallet-password.component.scss']
})
export class RenewEwalletPasswordComponent implements OnInit {

  public passwordForm: FormGroup;
  public verificationForm : FormGroup;
  public isChangedPassword : boolean = false;
  public matcher = new PasswordErrorMatcherService();
  private _unsubscribe$ = new Subject<any>();
  currentPWType: boolean=false;
  newPWType: boolean=false;
  confirmPWType: boolean=false;


  constructor(
    public translate : TranslateConfigService, 
    private router : Router,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: [''],
    }, { validators: this.checkPasswords });

    this.verificationForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });
  }

  onChangePassword()
  {
    if(this.passwordForm.status == "VALID"){
      this.evgService.updateEwalletPassword(this.passwordForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                this.passwordForm.reset();
                this.passwordForm.controls.oldPassword.setErrors(null);
                this.passwordForm.controls.password.setErrors(null);
                this.passwordForm.controls.confirmPassword.setErrors(null);
                // window.location.reload();
                // this.router.navigate([this.shareinfoService.backmainId]);
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true }     
  }

  toggleCurrentPWType() {
    this.currentPWType = !this.currentPWType;
  }

  toggleNewPWType() {
    this.newPWType = !this.newPWType;
  }

  toggleConfirmPWType() {
    this.confirmPWType = !this.confirmPWType;
  }
}
