import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlRank } from 'src/app/modules/common/PerformanceGrowthBonusSetting';
import { Payout, Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-leadership-bonus-top-winners',
  templateUrl: './leadership-bonus-top-winners.component.html',
  styleUrls: ['./leadership-bonus-top-winners.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class LeadershipBonusTopWinnersComponent implements OnInit {

  public user: User;
  public glRanks : GlRank[] = [];
  private _unsubscribe$ = new Subject<any>();
  public months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];

  public dateRangeForm : FormGroup;
  public fromDate = new FormControl(moment());
  public toDate = new FormControl(moment());
  public isProcessing = false;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dateRangeForm = this.formBuilder.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
    });
    this.getUser();
    this.getGLBrank();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  generateRankBulletin()
  {
    this.isProcessing = true;
    this.evgService.generateRankBulletin( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp.success) {
        this.getGLBrank();
        if(resp.data[0]){
          //console.log(resp);
        }
      }else{
        this.responseHandlerService.handleCustomResponse(resp.data[0].results.error,"warning");
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getGLBrank()
  {
    this.evgService.getGLBrank( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.glRanks = resp.data[0].results;
          this.cdr.detectChanges();
          //console.log('getGLBrank',this.glRanks);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


  chosenYearHandlerFrom(normalizedYear: Moment) {
    const ctrlValue = this.fromDate.value;
    ctrlValue.year(normalizedYear.year());
    this.dateRangeForm.get('fromDate').setValue(ctrlValue);
  }

  chosenMonthHandlerFrom(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.fromDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateRangeForm.get('fromDate').setValue(ctrlValue);
    datepicker.close();
  }

  chosenYearHandlerTo(normalizedYear: Moment) {
    const ctrlValue = this.toDate.value;
    ctrlValue.year(normalizedYear.year());
    this.dateRangeForm.get('toDate').setValue(ctrlValue);
  }

  chosenMonthHandlerTo(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.toDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateRangeForm.get('toDate').setValue(ctrlValue);
    datepicker.close();
  }


  // onGenerateReport(){
  //   if(this.dateRangeForm.status == "VALID"){
  //     let data = this.dateRangeForm.value;
  //     data.group = 'monthly';
  //     data.fromDate = this.dateRangeForm.get('fromDate').value.format('YYYY-MM-01');
  //     data.toDate = this.dateRangeForm.get('toDate').value.format('YYYY-MM-01');
  //     this.getPayoutReport(data);
  //   }
  // }

}
