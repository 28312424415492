<div class="account-pw-change-container">
    <div class="row gutters-sm">
        <div class="col-md-3">
        </div>
      <div class="col-md-6 mb-3">
        <div class="card mt-3">
          <div class="card-body">
            <h2 translate>CHANGEPASSLABEL</h2>
            <div class="d-flex flex-column align-items-center text-center">
              
              <form *ngIf="!isChangedPassword"  [formGroup]="passwordForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3" autocomplete="pass-form">
                <mat-form-field style="width: 100%; text-align: center">
                  <div class="input-group">
                    <input matInput formControlName="currentPassword" [type]="currentPWType ? 'text' : 'password'"  class="form-control text-box" id="currentPassword" aria-describedby="currentPasswordHelp"
                          name="currentPassword" [placeholder] = "'CURRENTPASSLABEL' | translate" maxlength="60"
                          autocomplete="off"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !currentPWType,
                            'fa-eye': currentPWType
                          }"
                          (click)="toggleCurrentPWType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <mat-error class="error-message" *ngIf="passwordForm.get('currentPassword').hasError('required')">{{ 'CURRENTPASSREQ' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%; text-align: center">
                  <div class="input-group">
                    <input matInput formControlName="newPassword" [type]="newPWType ? 'text' : 'password'" class="form-control text-box" id="newPassword" aria-describedby="newPasswordHelp"
                        name="newPassword" autocomplete="off" [placeholder]="'NEWPASSLABEL' | translate" maxlength="60"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !newPWType,
                            'fa-eye': newPWType
                          }"
                          (click)="toggleNewPWType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <mat-error class="error-message" *ngIf="passwordForm.get('newPassword').hasError('required')">{{ 'NEWPASSREQ' | translate }}</mat-error>
                  <mat-error class="error-message" *ngIf="passwordForm.get('newPassword').hasError('pattern')">{{  "PASSPATTERNREQ" | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%; text-align: center;">
                  <div class="input-group">
                    <input matInput formControlName="confirmPassword" [type]="confirmPWType ? 'text' : 'password'" class="form-control text-box" id="confirmPassword" aria-describedby="confirmPasswordHelp"
                          name="confirmPassword" autocomplete="off" [placeholder]="'CONFIRMPASSLABEL' | translate" [errorStateMatcher]="matcher"  maxlength="60"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !confirmPWType,
                            'fa-eye': confirmPWType
                          }"
                          (click)="toggleConfirmPWType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <!-- <mat-error class="error-message" *ngIf="passwordForm.get('confirmPassword').hasError('required')">{{ 'CONFIRMPASSREQ' | translate }}</mat-error> -->
                  <!-- <br> -->
                  <mat-error class="error-message" *ngIf="passwordForm.hasError('notSame')">{{ 'CONFIRMPASSNOTSAME' | translate }}</mat-error>
                </mat-form-field>

                <button mat-stroked-button [disabled]="isProcessing1" (click)="onChangePassword()" color="primary" class="align-right mt-4">{{ 'UPDATEPASSBTN' | translate }}</button>
              </form>

              <form *ngIf="isChangedPassword"  [formGroup]="verificationForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3" autocomplete="ver-form">
                <h3>
                  {{ 'VERIFYINFO' | translate }}
                </h3>
                <mat-form-field style="width: 100%; text-align: center">
                  <input matInput formControlName="code"  type="text" class="form-control text-box" id="code" aria-describedby="codeHelp"
                   name="code" [placeholder] = "'CODELABEL' | translate"
                  autocomplete="off"
                  />
                  <mat-error class="error-message" *ngIf="verificationForm.get('code').hasError('required')">{{ 'CODEREQ' | translate }}</mat-error>
                </mat-form-field>

                <button mat-stroked-button [disabled]="isProcessing2" (click)="onVerify()" color="primary" class="align-right mt-2">{{ 'VERIFYBTN' | translate }}</button>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
      </div>
      
    </div>
    

</div>