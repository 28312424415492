<div class="user-faqs-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'USERFAQ' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'AUTHOR' | translate}}</th>
                          <th scope="col">{{'QUESTION' | translate}}</th>
                          <!-- <th scope="col">{{'CONTENT' | translate}}</th> -->
                          <th scope="col">{{'ANSWER' | translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let faq of faqs; index as i;">
                          <td>{{ faq.author }}</td>
                          <td>{{ faq.question }}</td>
                          <td>{{ faq.answer }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>