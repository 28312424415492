import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerReviewsComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() pId : any;
  public starCount: number = 5;
  public totalReviews = 0;
  private _unsubscribe$ = new Subject<any>();

  public productReviews = [];
  public ratingArr = [];
  constructor(
    public evgService : EvgApiService,
    public activeModal : NgbActiveModal, 
    public cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // for (let index = 0; index < this.starCount; index++) {
    //   this.ratingArr.push(index);
    // }
    

    // for (const [key, value] of Object.entries(this.reviews.stars)) {
    //   this.totalReviews += parseInt(String(value)) ;
    // }

    // console.log(this.totalReviews);
    this.getReviews();
  }

  getReviews()
  {
    this.evgService.getReviews({pId : this.pId})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          let reviews = resp.data[0].results;
          this.productReviews = reviews.filter(review => {
            return review.pId == this.pId
          })
          this.cdr.detectChanges();
          //console.log(this.productReviews);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        // this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  // showIcon(index:number) {
  //   let dif = this.reviews.Score - index;
  //   if( dif >= 0.5 && dif < 1){
  //     return 'star_half'
  //   }else if (this.reviews.Score >= index + 1) {
  //     return 'star';
  //   }else {
  //     return 'star_border';
  //   }
  // }

}
