import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-current-balance',
  templateUrl: './current-balance.component.html',
  styleUrls: ['./current-balance.component.scss']
})
export class CurrentBalanceComponent implements OnInit {

  public transferDistributorFrom: FormGroup;
  public transferEwalletForm: FormGroup;
  public questions : Array<any>=[];
  public user = new User();
 
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            //console.log(this.user);
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.transferDistributorFrom = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      toUserId: ['', [Validators.required]],
      stringId: ['', [Validators.required]],
      //amount: ['', [Validators.required, Validators.min(50)]], // templar disable 50
      amount: ['', [Validators.required, Validators.min(0)]],
      email: ['', [Validators.required]],
      tradePassword: ['', [Validators.required]],
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    });

    this.transferEwalletForm = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      amount: ['', [Validators.required]],
    });

  }

  public transferToDistributor(formDirective)
  {
    if(confirm(this.translate.translate.instant('TRANSFERTODISTRIB') + "?")) {
      this.evgService.sentCoinsToUser(this.transferDistributorFrom.value).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
        if (resp) {
          if(resp.success){
            this.responseHandlerService.handleCustomResponse("Transferred Successfully","success")
            this.shareinfoService.reloadUser();
            this.sharedUtilService.resetForm(this.transferEwalletForm,formDirective);
          }else{
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  public transferToEwallet(formDirective)
  {
    if(confirm(this.translate.translate.instant('TRANSFERTOEWALLET') + "?")) {
      if(this.transferEwalletForm.get('amount').value <= this.user.totalCash){
        this.evgService.sentCoinsToEWallet(this.transferEwalletForm.value).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
          if (resp) {
            if(resp.success){
              this.responseHandlerService.handleCustomResponse("Transferred Successfully","success")
              this.shareinfoService.reloadUser();
              this.sharedUtilService.resetForm(this.transferEwalletForm,formDirective);
            }else{
              this.responseHandlerService.handleResponse(resp);
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }else{
        this.responseHandlerService.handleCustomResponse("Transferred Successfully","success")
      }
      
    }
  }

}
