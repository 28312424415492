<button mat-icon-button disableRipple style="color:  #fea314;" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
    <mat-icon>
      {{showIcon(i)}}
    </mat-icon>
  </button>
  <br>
  <p>{{ rating }} {{ 'OUTOF' | translate }}  {{ starCount }}</p> 
  <mat-error *ngIf="starCount == null || starCount == 0">
    Star count is <strong>required</strong> and cannot be zero
  </mat-error>
  