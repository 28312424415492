import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ShareinfoService } from './shareinfo.service';

@Injectable({
  providedIn: 'root'
})
export class AuthchecService implements CanActivate {

  constructor() { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let isAuthenticated =  localStorage.getItem('userToken');

    if(isAuthenticated == 'null'){
      return false;
    }

    return true;
  }

}
