<div class="cancelled-orders-monthly-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'CANCELLEDORDERSMONTHLYRECORD' | translate}}</h3>
              <div class="text-right mb-2" *ngIf="selectedCancelledOrderRecordDetails">
                <a mat-stroked-button color="primary" (click)="unSelectRecord()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered" *ngIf="!selectedCancelledOrderRecordDetails">
                      <thead>
                        <tr>
                          <th scope="col">{{'YEARMONTH' | translate}}</th>
                          <th scope="col">{{'ORDERS' | translate}}</th>
                          <th scope="col">{{'TOTALAMOUNT' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let cancelledOrderRecord of cancelledOrderRecords; index as i;">
                          <td><a (click)="selectRecord(cancelledOrderRecord.YearMonth)" style="cursor: pointer; color: blue;">{{ cancelledOrderRecord.YearMonth}}</a></td>
                          <td>{{ cancelledOrderRecord.orders }}</td>
                          <td>$ {{ cancelledOrderRecord.totalAmount }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="selectedCancelledOrderRecordDetails">
                        <thead>
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col">{{'MEMBERNO' | translate}}</th>
                            <th scope="col">{{'AMOUNT' | translate}}</th>
                            <th scope="col">{{'DISCOUNTS' | translate}}</th>
                            <th scope="col">{{'TAXES' | translate}}</th>
                            <th scope="col">{{'PAYMENTTYPE' | translate}}</th>
                            <th scope="col">{{'ADDTIME' | translate}}</th>
                            <th scope="col">{{'CANCELDATE' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let selectedCancelledOrderRecordDetail of selectedCancelledOrderRecordDetails; index as i;">
                            <td>{{ selectedCancelledOrderRecordDetail.MemberNo}}</td>
                            <td>$ {{ selectedCancelledOrderRecordDetail.amount}}</td>
                            <td>$ {{ selectedCancelledOrderRecordDetail.discount}}</td>
                            <td>$ {{ selectedCancelledOrderRecordDetail.taxes }}</td>
                            <td>{{ selectedCancelledOrderRecordDetail.payType == 0 ? ("CREDITCARDONLY" | translate) : ("EVCOINSLABEL" | translate) }}</td>
                            <td>{{ selectedCancelledOrderRecordDetail.addtime }}</td>
                            <td>{{ selectedCancelledOrderRecordDetail.cancelDate }}</td>
                          </tr>
                        </tbody>
                      </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>