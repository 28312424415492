import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountRankDialogComponent } from 'src/app/modules/account-rank-dialog/account-rank-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Wholesale } from 'src/app/modules/common/Wholesale';
import { InvoiceLink } from 'src/app/modules/common/InvoiceLink';
import { Products } from 'src/app/modules/common/Products';
 
@Component({
  selector: 'app-send-invoice',
  templateUrl: './send-invoice.component.html',
  styleUrls: ['./send-invoice.component.scss']
})
export class SendInvoiceComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() invoiceLinkId : number=null;

  public invoiceLinkSet : InvoiceLink = new InvoiceLink();
  public invoiceLinkFrom: FormGroup;
  public questions : Array<any>=[];
  public isProcessing = false;
  public wholesales : Wholesale[]=[];
  public products: Products[] = [];
  private _unsubscribe$ = new Subject<any>();
  public current_gst_rate=0.0;
  public current_pst_rate=0.0;
  public current_taxType=0;
  public totalAmt =0;
  public isPaid = false;


  constructor(
    public activeModal : NgbActiveModal, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    public router : Router,
    public ngDialog : NgbModal
  ) { }

  ngOnInit(): void {
      //console.log('ngOnInit ! invoiceLinkId :', this.invoiceLinkId);
      this.initializeForms();
      this.getProducts();
      this.getWholesales();
      if (this.invoiceLinkId) {
        this.getInvoiceLink(this.invoiceLinkId);
      }
    }
    /*
    ngAfterViewInit() {
      setTimeout(()=>{
        if (this.invoiceLinkId) {
          this.getInvoiceLink(this.invoiceLinkId);
        } 
      },0);
  
    }
    */

    initializeForms(){
      this.invoiceLinkFrom = this.formBuilder.group({
        customer_id : ['', [Validators.required]],
        email: ['', [Validators.required]],
        pId: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        unit_price: ['', [Validators.required]],
        tax_gst: ['', [Validators.required]],
        tax_pst: ['', [Validators.required]],
        tax_amt: [''],
        amount: new FormControl({value: '', disabled: true}),// ['', [Validators.required]],
      });

    }

    ngOnDestroy() {
      this._unsubscribe$.next();
      this._unsubscribe$.complete();
    }
  
    openPage(pageId) {
      this.activeModal.close('Close click');
      this.router.navigate([pageId]);
    }
  
    onSelecWholesaletEvent(value: any, doSet:boolean=true) {
      //console.log('onSelectEvent value:',value);
      let wholesaleId = value;
      this.getWholesaleInfo(wholesaleId);
  
    }

    onSelecProductEvent(value: any) {
      //console.log('onSelecProductEvent value:',value);
      this.getProduct(value);
  
    }

  getProduct(productId:number, doSet:boolean=true){
    //console.log("getProduct productId: ", productId);
    this.isProcessing = true;
    
    this.evgService.getProduct({pId: productId})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log("getProduct resp: ", resp);
        if (resp) {
          if(resp.data[0] && resp.data[0].results[0]){
            let product = resp.data[0].results[0];
            this.current_taxType = product.tax_type;
            if (doSet) {
              this.invoiceLinkFrom.patchValue({
                unit_price : product.pPrice,
              });
            }
            if (doSet) {
              this.reCalculate();
            }
          }
        }
        this.isProcessing = false;
      },(errorResult) => {
        this.isProcessing = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });

  }

  getProducts()
  {
    this.isProcessing = true;
    this.evgService.forPurchase([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log("getProducts resp: ", resp);
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
          }
        }
        this.isProcessing = false;
      },(errorResult) => {
        this.isProcessing = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  getWholesales()
  {
    this.isProcessing = true;
    this.evgService.getWholesales( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.wholesales = resp.data[0].results;
          
          //console.log("getWholesales",this.wholesales );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getInvoiceLink(linkId: number) {
    this.isProcessing = true;
    this.evgService.getInvoiceLink( {id : linkId}).pipe(takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        //console.log('call onpenInvoiceLink get getInvoiceLink resp:', resp);
        if(resp.data[0] && resp.data[0].results){
          let linkSet = resp.data[0].results;

          this.invoiceLinkFrom.patchValue({
            customer_id : linkSet.customer_id,
            email : linkSet.email,
            pId : linkSet.pId,
            quantity : linkSet.quantity,
            unit_price : linkSet.unit_price,
            tax_gst : linkSet.tax_gst,
            tax_pst : linkSet.tax_pst,
            tax_amt : linkSet.tax_amt,
            amount : linkSet.amount
          });
          this.isPaid = linkSet.paid==1;
          this.getWholesaleInfo(linkSet.customer_id, false);
          this.getProduct(linkSet.pId, false);
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getWholesaleInfo(wholesaleId : any, doSet:boolean=true)
  {
    this.isProcessing = true;

    //console.log(" getWholesaleInfo id ", wholesaleId );
    this.evgService.getWholeTaxInfo( {id : wholesaleId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          let wholesale = resp.data[0].results;

          this.current_gst_rate = wholesale.tax_rate;
          this.current_pst_rate = wholesale.tax_rate2;
          if (doSet) {
            this.invoiceLinkFrom.patchValue({
              email : wholesale.email,
            });
          }
          //console.log(" getWholesaleInfo wholesale ", wholesale );
        }
      }
      if (doSet) {
        this.reCalculate();
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
  priceChange(value) {
    console.log(" priceChange value ", value );
    this.reCalculate();
  }

  quantityChange(value) {
    console.log(" quantityChange value ", value );
    this.reCalculate();
  }

  /**
   * 
   * @param fieldId 1:for gst cahnged, 2: for pst changed, 3 for all
   */
  reCalculate() {
    let data = this.invoiceLinkFrom.value;
    let setAmount =0;
    
    //console.log('reCalculate  data:',data);
    /*
    console.log('reCalculate  this.current_gst_rate:',this.current_gst_rate);
    console.log('reCalculate  this.current_pst_rate:',this.current_pst_rate);
    console.log('reCalculate  this.current_taxType:',this.current_taxType);
    */
    if ( data.unit_price && data.quantity) {
      if (
          this.sharedUtilService.isNumber(data.unit_price) &&
          this.sharedUtilService.isNumber(data.quantity) 
      ){
          setAmount = parseFloat(data.unit_price) * parseInt(data.quantity) ;
          let taxGST = 0 ;
          let taxPST = 0 ;
          if ( this.current_taxType ==1 ||  this.current_taxType ==3) {
            taxGST = setAmount * (this.current_gst_rate) ;
          }
          if ( this.current_taxType ==2 ||  this.current_taxType ==3) {
            taxPST = setAmount * this.current_pst_rate  ;
          }
          //console.log('reCalculate  this.current_taxType:',this.current_taxType);
          //console.log('reCalculate  setAmount   taxGST:',setAmount, taxGST);
          this.totalAmt =  (setAmount + taxGST + taxPST);
          this.invoiceLinkFrom.patchValue({
            tax_gst : taxGST,
            tax_pst : taxPST,
            tax_amt : (taxGST + taxPST),
            amount : (setAmount + taxGST + taxPST),
          });
      }
    }
  }


  public updateInvoiceLink(formDirective)
  {
      this.isProcessing = true;
      this.reCalculate();
      let data = this.invoiceLinkFrom.value;
      data.amount = this.totalAmt;
      data.sendEmail = 1; // send email to wholesale

      if (this.invoiceLinkId) {
        data.id = this.invoiceLinkId;
      }
      this.evgService.postInvoiceLink( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.sharedUtilService.resetForm(this.invoiceLinkFrom,formDirective);
        }
        this.isProcessing = false
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing = false
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
  }


}
