<div class="rank-reports-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'RANKREPORTS' | translate}}</h3>
              <div class="text-right mb-2" *ngIf="rankUsers">
                <a mat-stroked-button color="primary" (click)="unselectUser()" class="align-right mr-2">{{ 'BACKBTN' | translate }}</a>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered" *ngIf="!rankUsers">
                      <thead>
                        <tr>
                          <th scope="col">{{'NAME' | translate}}</th>
                          <th scope="col">{{'RANK' | translate}}</th>
                          <th scope="col">{{'MEMBERS' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rankRecord of rankRecords; index as i;">
                          <td><a (click)="getUsersByRank(rankRecord.rank)" style="cursor: pointer; color: blue;" >{{ rankRecord.name }}</a></td>
                          <td>{{ rankRecord.rank }}</td>
                          <td>{{ rankRecord.members }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered" *ngIf="rankUsers">
                      <thead>
                        <tr>
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'EMAIL' | translate}}</th>
                          <th scope="col">{{'FIRSTNAME' | translate}}</th>
                          <th scope="col">{{'LASTNAME' | translate}}</th>
                          <th scope="col">{{'MONTHSALES' | translate}}</th>
                          <th scope="col">{{'TEAMSALES' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rankUser of rankUsers; index as i;">
                          <td>{{ rankUser.id}}</td>
                          <td>{{ rankUser.email }}</td>
                          <td>{{ rankUser.first_name }}</td>
                          <td>{{ rankUser.last_name }}</td>
                          <td>{{ rankUser.monthSales | currency:''}}</td>
                          <td>{{ rankUser.teamSales | currency:'' }}</td>
                        </tr>
                        <!-- <tr *ngIf="selectedInvoices.length == 0">
                            <td colspan="5"></td>
                        </tr> -->
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>