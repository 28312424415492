
<div class="monthly-bonus-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'MONTHLYBONUSRECORD' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th mat-sort-header="dealDate" scope="col">{{'DATELABEL' | translate}}</th>
                          <th mat-sort-header="MemberNo" scope="col">{{'MEMBERID' | translate}}</th>
                          <th mat-sort-header="dealName" scope="col">{{'BONUSTYPE' | translate}}</th>
                          <th mat-sort-header="levels" scope="col">{{'LEVELS' | translate}}</th>
                          <th mat-sort-header="amount" scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th mat-sort-header="inPending" scope="col">{{'PENDINGLABEL' | translate}}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let bonus of sortedBonuses; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td>{{ bonus.dealDate }}</td>
                          <td>{{ bonus.MemberNo }}</td>
                          <!--td>{{ bonus.dealName }}</td-->
                          <td>{{ bonus.dealName }} {{ bonus.amount < 0 ? ('(' + ('BONUSREDUCED' | translate) +')') : ''}}</td>
                          <td >{{ bonus.MsgInfo ? bonus.MsgInfo : bonus.levels }}</td>
                          <td>{{ bonus.amount | currency:''}}</td>
                          <td>{{ bonus.inPending ?  "YES" : "NO"}}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>