import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WithdrawalRecord, WithdrawalRecordDetail } from 'src/app/modules/common/Accounting';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-withdrawal-monthly-record',
  templateUrl: './withdrawal-monthly-record.component.html',
  styleUrls: ['./withdrawal-monthly-record.component.scss']
})
export class WithdrawalMonthlyRecordComponent implements OnInit {

  public user: User;
  public withdrawalRecords : WithdrawalRecord[] = [];
  public selectedWithdrawalRecordDetails : WithdrawalRecordDetail[] = null;
  public selectedRecord : string = null;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
   
     this.withdrawReport();

  }

  selectRecord(userId,YearMonth){
    this.userWithdrawDetail(userId,YearMonth);
  }

  unSelectRecord()
  {
    this.selectedRecord = null;
    this.selectedWithdrawalRecordDetails = null;
    this.withdrawReport();
  }

  withdrawReport()
  {
    this.evgService.withdrawReport( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.withdrawalRecords = resp.data[0].results;
          //console.log("withdrawReport",this.withdrawalRecords );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  userWithdrawDetail(userId, YearMonth)
  {
    this.evgService.userWithdrawDetail( {userId : userId, YearMonth : YearMonth} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.selectedWithdrawalRecordDetails = resp.data[0].results;
          //console.log("userWithdrawDetail",this.selectedWithdrawalRecordDetails );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
