import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-byte-token-trx-history',
  templateUrl: './byte-token-trx-history.component.html',
  styleUrls: ['./byte-token-trx-history.component.scss']
})
export class ByteTokenTrxHistoryComponent implements OnInit {

  public user = new User();
  public byteTokenTrxHistory: Array<any> = [];

  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            //console.log(this.user);
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.getByteTokenTrxHistory();

  }

 
  public getByteTokenTrxHistory() {
    let hist = {date:"2000-01-01", description:"no record", inORout:"in", funds:"0", balance:0};
    this.evgService.getByteTokenTrxHistory().subscribe( resp => {
    if (resp.success) {
        let balance = this.user.totalCash;
        resp.data.forEach(e => {
            hist = {date:e.dealDate, description:e.dealName, inORout:e.inORout, funds:e.amount, balance:balance};
            this.byteTokenTrxHistory.push(hist);
            balance -= e.amount; 
        });
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

    //this.byteTokenTrxHistory.push(hist);
  }

}
