<div class="refund-byte-tokens-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'REFUNDBYTETOKENS' | translate}}</h3>
              <!-- <div class="text-right mb-2">
                <form [formGroup]="applicationsForm" (keydown.enter)="$event.preventDefault()" autocomplete="rf-form">
                    <mat-form-field>
                        <mat-label>{{'INFOTYPE' | translate}}</mat-label>
                        <mat-select formControlName="isClosed"  type="text" class="form-control" id="infoType" aria-describedby="infoType"
                        autocomplete="off">
                            <mat-option  [value]="-1">{{ 'ALL' | translate}}</mat-option>
                            <mat-option  [value]="0">{{ 'OPEN' | translate}}</mat-option>
                            <mat-option  [value]="1">{{ 'APPROVED' | translate}}</mat-option>
                            <mat-option  [value]="2">{{ 'REJECTED' | translate}}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="applicationsForm.get('isClosed').hasError('required')">{{'INFOTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!applicationsForm.valid || isProcessing" (click)="onGetApplications()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div> -->
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'DATELABEL' | translate}}</th>
                          <th scope="col">{{'USERID' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'PURCHDATE' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let refundRequest of refundRequests; index as i;">
                          <td>{{ refundRequest.AddTime }}</td>
                          <td>{{ refundRequest.sender }}</td>
                          <td>{{ refundRequest.refundAmt | currency }}</td>
                          <td>{{ refundRequest.purchased_at }}</td>
                          <td>
                            <ng-container>
                              <button mat-stroked-button color="primary" class="btn submit-button mr-2" [disabled]="isProcessing1" (click)="onApproveRefund(refundRequest)" >{{ "APPROVEBTN" | translate}}</button>
                              <button mat-stroked-button color="warn" class="btn submit-button " [disabled]="isProcessing2" (click)="onRejectRefund(refundRequest)" >{{ "REJECTBTN" | translate}}</button>
                            </ng-container>
                            <!-- <ng-container *ngIf="refundRequest.isClosed != 0">
                              <p>{{ 'CLOSED' | translate }}</p>
                            </ng-container> -->
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>

          </div>
        </div>
    </div>
</div>