
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { WrinkleSerumComponent } from './wrinkle-serum.component';

const routes: Routes = [
  { path: '', component: WrinkleSerumComponent }
]

@NgModule({
  declarations: [],
  imports: [CommonModule,RouterModule.forChild(routes)]
  //exports: [RouterModule]
})
export class WrinkleSerumRoutingModule { }
