import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-ewallet-balance',
  templateUrl: './ewallet-balance.component.html',
  styleUrls: ['./ewallet-balance.component.scss']
})
export class EwalletBalanceComponent implements OnInit {

  public transferDistributorFrom: FormGroup;
  public transferEwalletForm: FormGroup;
  public questions : Array<any>=[];
  public user = new User();

  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.transferDistributorFrom = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      toUserId: ['', [Validators.required]],
      stringId: ['', [Validators.required]],
      amount: [1, [Validators.required, Validators.min(1)]],
      email: ['', [Validators.required]],
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    });

    this.transferEwalletForm = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      amount: [1, [Validators.required, Validators.min(1)]],
    });

  
    this.getUser();
   

  }

  public getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.transferDistributorFrom.get('amount').setValidators([Validators.required, Validators.min(1),Validators.max(this.user.totalCash)]);
            this.transferEwalletForm.get('amount').setValidators([Validators.required, Validators.min(1),Validators.max(this.user.totalCash)]);
            this.transferDistributorFrom.updateValueAndValidity();
            this.transferEwalletForm.updateValueAndValidity();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public transferToDistributor(formDirective)
  {
    if(confirm(this.translate.translate.instant('TRANSFERTODISTRIBUTORQ'))) { //"Transfer to Distributor?"
      this.evgService.sentCoinsToUser(this.transferDistributorFrom.value).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
        if (resp) {
          if(resp.success){
            this.responseHandlerService.handleCustomResponse("Transferred Successfully","success")
            this.shareinfoService.reloadUser();
            this.sharedUtilService.resetForm(this.transferEwalletForm,formDirective);
          }else{
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  public transferToEwallet(formDirective)
  {
    if(confirm(this.translate.translate.instant('TRANSFERTOEWALLETQ'))) {
      if(this.transferEwalletForm.get('amount').value <= this.user.totalCash){
        this.evgService.sentCoinsToEWallet(this.transferEwalletForm.value).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
          if (resp) {
            if(resp.success){
              this.responseHandlerService.handleCustomResponse("Transferred Successfully","success")
              this.shareinfoService.reloadUser();
              this.sharedUtilService.resetForm(this.transferEwalletForm,formDirective);
            }else{
              this.responseHandlerService.handleResponse(resp);
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }else{
        this.responseHandlerService.handleCustomResponse("Not enough cash","warning")
      }
      
    }
  }

}
