import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-trade-password',
  templateUrl: './trade-password.component.html',
  styleUrls: ['./trade-password.component.scss']
})
export class TradePasswordComponent implements OnInit {

  public user: User;
  public generateTradeForm: FormGroup;
  public generateButtonEnabled : boolean = false;
  public passwordForm: FormGroup;
  public generatedPassword : string = null;
  public isChangedPassword : boolean = false;
  private _unsubscribe$ = new Subject<any>();
  public matcher = new PasswordErrorMatcherService();
  newPWType: boolean=false;
  confirmPWType: boolean=false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

    this.generateTradeForm = this.formBuilder.group({
      memberId: ['', [Validators.required]],
    },);

    this.generateTradeForm.get('memberId').valueChanges
    .subscribe(value => {
      if(value === this.user.userStrId) {
          this.generateButtonEnabled = true;
      }else{
        this.generateButtonEnabled = false;
        this.generatedPassword = null;
      }
    });

    this.passwordForm = this.formBuilder.group({
      // existingPassword: ['', [Validators.required]],
      tradePassword: ['', [Validators.required]],
      confirmPassword: [''],
    }, { validators: this.checkPasswords });
  }


  onChangePassword(formDirective)
  {
    if(this.passwordForm.status == "VALID"){
      this.evgService.updateTradePassword(this.passwordForm.value)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
                this.isChangedPassword = true;
                this.shareinfoService.reloadUser();
                this.sharedUtilService.resetForm(this.passwordForm,formDirective);
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const tradePassword = group.get('tradePassword').value;
    const confirmPassword = group.get('confirmPassword').value;

    return tradePassword === confirmPassword ? null : { notSame: true }     
  }

  generatePassword()
  {
    this.generatedPassword = this.makeid(10);
  }

  

 submitPassword()
 {
  if(this.generateTradeForm.status == "VALID"){
    let data = this.generateTradeForm.value;
    data.newPassword = this.generatedPassword;

    this.evgService.updateTradePassword(data)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
              this.isChangedPassword = true;
              this.shareinfoService.reloadUser();
              window.location.reload();
          }
        }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }
 }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  

  toggleNewPWType() {
    this.newPWType = !this.newPWType;
  }

  toggleConfirmPWType() {
    this.confirmPWType = !this.confirmPWType;
  }

}
