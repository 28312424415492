<div class="balance-record-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">    
              <h3 class="account-title">{{'BALANCERECORD' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'DATE' | translate}}</th>
                          <th scope="col">{{'TRANSACTIONS' | translate}}</th>
                          <th scope="col">{{'FUNDSIN' | translate}}</th>
                          <th scope="col">{{'FUNDSOUT' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'DATELABEL' | translate}}</th>
                          <!-- <th scope="col">{{'RUNNINGBALANCE' | translate}}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let transaction of transactions; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td>{{ transaction.AddTime }}</td>
                          <td>{{ transaction.comment }}</td>
                          <td>{{ (transaction.transFlag == 2 || transaction.transFlag == 5) ? (transaction.amount | currency:'') : '' }}</td>
                          <td>{{ transaction.transFlag == 1 ||  transaction.transFlag == 0 ? (transaction.amount | currency:'') : '' }}</td>
                          <td>{{ transaction.canceled == 1 ? ('YES' | translate) : '' }}</td>
                          <td>{{ transaction.canceled_at }}</td>
                          <!-- <td>{{ transaction.runningBalance ? (transaction.runningBalance | currency:'') : '' }}</td> -->
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>