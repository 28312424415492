<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        <div class="banner-detail2 mb-3"><h2>{{ 'NIGHTTIT' | translate }}</h2></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" translate>
            NIGHT1TIT
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>NIGHT1TITD1</p><br>
            <p class="mb-5 text-center" translate>NIGHT1TITD2</p><br>
        </div>
    </div>

    <div class="why-item">
        <div class="why-item-body">
            <h1 class="why-item-head" style="text-transform: uppercase;" translate>
                NIGHT2TIT
            </h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3">
                </div>
            </div>
            <div class="row">
                <div class="more-body mb-3" translate>NIGHT2TITD</div>
            </div>
        </div>  
    </div>


    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-6 col-md-12" style="background-color: rgb(247, 247, 247);">
                <div class="each-secion">
                    <div class="item_title" translate>NIGHT3TIT1</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-detail" translate>NIGHT3TIT1D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12" style="background-color: rgb(247, 247, 247);">
                <div class="each-secion">
                    <div class="item_title" translate>NIGHT3TIT2</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12" style="background-color: white;margin: 2rem auto;">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-detail" translate>NIGHT3TIT2D</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="item-closure d-flex flex-column">
        <h1 class="item-closure-head mt-5" translate>
            NIGHT4TIT
        </h1>
        <div class="separator mb-3"></div>
    </div>
    <app-introduce-part [min_height]="640" [contensInfo]="introduceInfo"></app-introduce-part>
<!----
    <div class="introduce">
        <div class="introduce-body">
            <h1 class="introduce-head" translate>NIGHT5TIT</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{ 'NIGHT5TITD' | translate }}</div>
        </div>
    </div>
-->
</div>

