import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CancelledOrderRecord, CancelledOrderRecordDetail } from 'src/app/modules/common/Accounting';
import { BonusHistory } from 'src/app/modules/common/Bonus';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-publish-additional-bonus-history',
  templateUrl: './publish-additional-bonus-history.component.html',
  styleUrls: ['./publish-additional-bonus-history.component.scss']
})
export class PublishAdditionalBonusHistoryComponent implements OnInit {

  public user: User;
  public bonusHistories : BonusHistory[] = [];
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
   
    this.AYBpublished();
  }

  AYBpublished()
  {
    this.evgService.AYBpublished( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.bonusHistories = resp.data[0].results;
          //console.log("AYBpublished",resp.data[0].results );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
}
