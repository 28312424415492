import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-verify-dialog',
  templateUrl: './verify-dialog.component.html',
  styleUrls: ['./verify-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyDialogComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() message : String;
  @Input() message2 : String;
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    
  }

}
