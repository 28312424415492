<div class="renew-ewallet-password-container">
    <div class="row gutters-sm">
        <div class="col-md-3">
        </div>
      <div class="col-md-6 mb-3">
        <div class="card mt-3">
          <div class="card-body">
            <h2 translate>CHANGEPASSEWALLETLABEL</h2>
            <div class="d-flex flex-column align-items-center text-center">
              
              <form [formGroup]="passwordForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3" autocomplete="p-form">
                <mat-form-field style="width: 100%; text-align: center">
                  <div class="input-group">
                    <input matInput formControlName="oldPassword"  [type]="currentPWType ? 'text' : 'password'" 
                      class="form-control text-box" id="oldPassword" aria-describedby="oldPasswordHelp"
                      name="oldPassword" [placeholder] = "'OLDPASSLABEL' | translate"
                      autocomplete="off"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !currentPWType,
                            'fa-eye': currentPWType
                          }"
                          (click)="toggleCurrentPWType()"
                        ></i>
                      </span>
                    </div>
                </div>
                <mat-error class="error-message" *ngIf="passwordForm.get('oldPassword').hasError('required')">{{ 'OLDPASSREQ' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field style="width: 100%; text-align: center">
                  <div class="input-group">
                    <input matInput formControlName="password" [type]="newPWType ? 'text' : 'password'" 
                        class="form-control text-box" id="password" aria-describedby="passwordHelp"
                        name="password" autocomplete="off" [placeholder]="'NEWPASSLABEL' | translate"
                      />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !newPWType,
                            'fa-eye': newPWType
                          }"
                          (click)="toggleNewPWType()"
                        ></i>
                        </span>
                      </div>
                  </div>

                  <mat-error class="error-message" *ngIf="passwordForm.get('password').hasError('required')">{{ 'NEWPASSREQ' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%; text-align: center;">
                  <div class="input-group">
                    <input matInput formControlName="confirmPassword" [type]="confirmPWType ? 'text' : 'password'" 
                      class="form-control text-box" id="confirmPassword" aria-describedby="confirmPasswordHelp"
                      name="confirmPassword" autocomplete="off" [placeholder]="'CONFIRMPASSLABEL' | translate" [errorStateMatcher]="matcher"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !confirmPWType,
                            'fa-eye': confirmPWType
                          }"
                          (click)="toggleConfirmPWType()"
                        ></i>
                        </span>
                      </div>
                  </div>
                  <mat-error class="error-message" *ngIf="passwordForm.hasError('notSame')">{{ 'CONFIRMPASSNOTSAME' | translate }}</mat-error>
                </mat-form-field>

                <button mat-stroked-button  (click)="onChangePassword()" color="primary" class="align-right mt-4">{{ 'UPDATEPASSBTN' | translate }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
      </div>
      
    </div>
    

</div>