import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-hydrating-serum',
  templateUrl: './view-hydrating-serum.component.html',
  styleUrls: ['./view-hydrating-serum.component.scss']
})
export class ViewHydratingSerumComponent implements OnInit {
  public isMobileMenuOpen:boolean  = false;

  constructor( 
    public activeModal : NgbActiveModal, 
    public router : Router,
  ) { }


  ngOnInit(): void {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = true;
    }

  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }

}
