<div class="customer-service-container">
    <div class="row gutters-sm">
        <div class="col-md-3">
        </div>
      <div class="col-md-6 mb-3">
        <div class="card mt-3">
          <div class="card-body">
            <h2 translate>CSLABEL</h2>
            <div class="d-flex flex-column align-items-center text-center">
              
              <form [formGroup]="requestForm" #request="ngForm" class="pb-3" autocomplete="r-form">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="topic"  type="text" class="form-control text-box" id="topic" aria-describedby="topic"
                   name="topic" [placeholder] = "'TOPICLABEL' | translate" maxlength="200"
                  autocomplete="off"
                  />
                  <mat-error class="error-message" *ngIf="requestForm.get('topic').hasError('required')">{{ 'TOPICREQ' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                  <mat-label>{{'REQUESTTYPE' | translate}}</mat-label>
                  <mat-select formControlName="requestType"  type="text" class="form-control" id="requestType" aria-describedby="requestType"
                  autocomplete="off">
                      <mat-option  [value]="0">{{ 'NORMREQ' | translate }}</mat-option>
                      <mat-option  [value]="1">{{ 'PRODTROUB' | translate }}</mat-option>
                      <mat-option  [value]="2">{{ 'CANCELORDER' | translate }}</mat-option>
                      <mat-option  [value]="3">{{ 'CHANGESCHED' | translate }}</mat-option>
                      <mat-option  [value]="4">{{ 'CANCELPURCHASETOKENS' | translate }}</mat-option>
                  </mat-select>
                  <mat-error class="error-message" *ngIf="requestForm.get('requestType').hasError('required')">{{'REQUESTTYPEREQ' | translate}}</mat-error>
              </mat-form-field>

                <!-- <mat-form-field style="width: 100%;" *ngIf="requestForm.get('requestType').value != 0">
                  <input matInput formControlName="groupId"  type="text" class="form-control text-box" id="groupId" aria-describedby="groupId"
                  name="groupId" [placeholder] = "'GROUPID' | translate" maxlength="200"
                  autocomplete="off"
                  />
                  <mat-error class="error-message" *ngIf="requestForm.get('groupId').hasError('required')">{{ 'GROUPIDREQ' | translate }}</mat-error>
                </mat-form-field> -->

                <mat-form-field style="width: 100%;" *ngIf="requestForm.get('requestType').value > 0 && requestForm.get('requestType').value < 4 ">
                    <mat-label>{{'GROUPID' | translate}}</mat-label>
                    <mat-select formControlName="groupId"  type="text" class="form-control" id="groupId" aria-describedby="groupId"
                    autocomplete="off">
                        <mat-option  *ngFor="let groupID of groupIDs; index as i; " [value]="groupID">{{ groupID }}</mat-option>
                    </mat-select>
                    <mat-error class="error-message" *ngIf="requestForm.get('groupId').hasError('required')">{{'GROUPIDREQ' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%;" *ngIf="requestForm.get('requestType').value == 4">
                  <mat-label>{{'PBTID' | translate}}</mat-label>
                  <mat-select formControlName="id"  type="text" class="form-control" id="id" aria-describedby="id"
                  autocomplete="off">
                      <mat-option  *ngFor="let cancelID of cancelIDs; index as i;" [value]="cancelID.id">{{ cancelID.id + ' : ' + (cancelID.amount | currency) + ' - ' + cancelID.addtime }}</mat-option>
                  </mat-select>
                  <mat-error class="error-message" *ngIf="requestForm.get('id').hasError('required')">{{'PBTIDREQ' | translate}}</mat-error>
              </mat-form-field>
                


                <!-- <mat-form-field style="width: 100%"> -->
                  <!-- <textarea matInput formControlName="content"  class="form-control text-box" id="message" aria-describedby="message"
                   name="message" autocomplete="off" [placeholder]="'MESSAGELABEL' | translate" rows="7"
                  ></textarea> -->
                  <quill-editor formControlName="content">
                  </quill-editor>
                  <!-- <mat-error class="error-message" *ngIf="requestForm.get('content').hasError('required')">{{ 'MESSAGEREQ' | translate }}</mat-error> -->
                <!-- </mat-form-field> -->

                <button mat-stroked-button [disabled]="!requestForm.valid"  (click)="onSendRequest(request)" color="primary" class="align-right mt-4">{{ 'SENDMSGLABEL' | translate }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
      </div>
      
    </div>
    

</div>