import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Promotion } from 'src/app/modules/common/CustomerSupport';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-post-edit-promotions',
  templateUrl: './post-edit-promotions.component.html',
  styleUrls: ['./post-edit-promotions.component.scss']
})
export class PostEditPromotionsComponent implements OnInit {

  public user: User;
  public promotions : Promotion[] = [];
  public promotion : Promotion;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public promotionsForm : FormGroup;
  public toUserId : number;
  panelOpenState = false;
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
    private sharedUtilService : SharedUtilService
  ) { }

  onCreate(){
    this.isCreating = true;
  }

  onBack(){
    this.promotion = null;
    this.isCreating = false;
    this.getPromotions();
    this.openPage('');
  }

  onEdit(index){
    this.promotion = this.promotions[index];
    this.isCreating = false;
    this.openPage(this.promotion.id);
  }

  openPage(pId) {
    this.router.navigate(['backmain/admin/post-edit-promotions/' + pId]);
  }

  ngOnInit(): void {
    this.promotionsForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
      startDate : ['', [Validators.required]],
      endDate : ['', [Validators.required]],
      priority : ['', [Validators.required]],
      catType : ['', [Validators.required]],
    });

    
    this.getUser();
    this.getPromotions();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }


  getPromotions()
  {
    this.evgService.getPromotions({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.promotions = resp.data[0].results;
          this.route.params.subscribe((params) => {
            
            if(params.id) {
              var promotion = this.promotions.filter(promotion => {
                return promotion.id == params.id
              })

              if(promotion){
                this.promotion = promotion[0];
                this.promotionsForm.patchValue({
                  title : this.promotion.title,
                  content : this.promotion.content,
                  startDate : this.promotion.start_dt ? this.promotion.start_dt.substring(0,10) : this.promotion.start_dt,
                  endDate : this.promotion.end_dt ?  this.promotion.end_dt.substring(0,10) : this.promotion.end_dt,
                  priority : this.promotion.priority,
                  catType : this.promotion.catType
               });
              };
            }
          });
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postPromotion(promotionDirective)
  {
    if(this.promotionsForm.status === "VALID"){
      let data = this.promotionsForm.value;
      this.isProcessing1 = true;
      this.evgService.postPromotion(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("postPromotion",resp.data[0]);
            this.sharedUtilService.resetForm(this.promotionsForm,promotionDirective);
            this.onBack();
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }

   
  }

  modifyPromotion(promotionDirective)
  {
    if(this.promotionsForm.status === "VALID"){
      let data = this.promotionsForm.value;
      data.id = this.promotion.id;
      this.isProcessing2 = true;
      this.evgService.modifyPromotion(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("modifyPromotion",resp.data[0]);
            this.sharedUtilService.resetForm(this.promotionsForm,promotionDirective);
            this.onBack();
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }

}
