<div class="monthly-bonus-payout-report-container">
    <div class="row gutters-sm">
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'MONTHLYBONUSPAYOUTREPORT' | translate}}</h3>
              <div class="text-right mb-2">
                <form [formGroup]="dateRangeForm" (keydown.enter)="$event.preventDefault()" autocomplete="dr-form">
                    <mat-form-field class="mr-2">
                        <mat-label>{{ 'FROMDATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandlerFrom($event)"
                                        (monthSelected)="chosenMonthHandlerFrom($event, dp1)"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error class="error-message" *ngIf="dateRangeForm.get('fromDate').hasError('required')">{{'FROMDATEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="mr-2" >
                        <mat-label>{{ 'TODATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="toDate">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandlerTo($event)"
                                        (monthSelected)="chosenMonthHandlerTo($event, dp2)"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error class="error-message" *ngIf="dateRangeForm.get('toDate').hasError('required')">{{'TODATEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!dateRangeForm.valid || isProcessing" (click)="onGenerateReport()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">{{'YEAR' | translate}}</th>
                          <th scope="col">{{'MONTH' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'TRANSFERLABEL' | translate}}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let payout of payouts; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td>{{ payout.year}}</td>
                          <td>{{ months[payout.month - 1] }}</td>
                          <td>{{ payout.amount  | currency:''}}</td>
                          <td>{{ payout.inORout }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>