<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        <div class="banner-detail2 mb-3"><h2>{{ 'HYDRSERUMTIT' | translate }}</h2></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" translate>
            HYDRPRT1TIT
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>
                HYDRPRT1TITD
            </p>
        </div>
    </div>

    <div class="why-acid">
        <div class="why-acid-body">
            <h1 class="why-acid-head" translate>HYDRPRT2TIT</h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3"></div>
            </div>
        </div>  
    </div>

    <div class="why-product">
        <div class="row">
            <div class="top-title"><h1 translate>HYDRPRT2TITD</h1></div>
        </div>
        <div class="row first-row">
            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                    <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/icon-1.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPRT3TIT1</span>
                        <span class="why-detail" translate>HYDRPRT3TITD1</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/icon-2.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPRT3TIT2</span>
                        <span class="why-detail" translate>HYDRPRT3TITD2</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                    <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/icon-3.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPRT3TIT3</span>
                        <span class="why-detail" translate>HYDRPRT3TITD3</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row second-row">
            <div class="col-lg-6 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/icon-4.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPRT3TIT4</span>
                        <span class="why-detail" translate>HYDRPRT3TITD4</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row each-secion">
                    <div class="icon_img">
                        <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/icon-5.png">
                    </div>
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPRT3TIT5</span>
                        <span class="why-detail">{{'HYDRPRT3TITD5' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-product">
        <div class="col-lg-6 col-md-12">
            <div class="left-section">{{'HYDRPR41TITB' | translate }}</div>
            <div class="separator"></div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="right-section">{{ 'HYDRPR41TIT' | translate }}</div>
        </div>
    </div>

    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT1</span>
                        <span class="why-detail" translate>HYDRPR41TIT1D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT2</span>
                        <span class="why-detail">{{'HYDRPR41TIT2D' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT3</span>
                        <span class="why-detail" translate>HYDRPR41TIT3D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT4</span>
                        <span class="why-detail">{{'HYDRPR41TIT4D' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-item-benefits">
        <div class="col-lg-6 col-sm-12 item-img">
            <div class="overlay"></div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-title mb-1" translate>HYDRPR41TIT5</p>
                <div class="separator mt-1 mb-3"></div>
                <p class="card-text" translate>HYDRPR41TIT5D1</p><br>
                <p class="card-text" translate>HYDRPR41TIT5D2</p><br>
                <p class="card-text mb-1" translate>HYDRPR41TIT5D3</p><br>
            </div>
        </div>
    </div>

    <div class="row what-product-detail pt-5">
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title" translate>HYDRPR41TIT6T</div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT6T1</span>
                        <span class="why-detail">{{'HYDRPR41TIT6D1' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT6T2</span>
                        <span class="why-detail" translate>HYDRPR41TIT6D2</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT6T3</span>
                        <span class="why-detail">{{'HYDRPR41TIT6D3' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT6T4</span>
                        <span class="why-detail" translate>HYDRPR41TIT6D4</span>
                    </div>
                </div>
                <div class="img_section">
                    <img src="./../../../../../assets/images/files/skin-care/hydrating-serum/design-element.png">
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title" translate>HYDRPR41TIT7T</div>
                </div>

                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT7T1</span>
                        <span class="why-detail">{{'HYDRPR41TIT7D1' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT7T2</span>
                        <span class="why-detail">{{'HYDRPR41TIT7D2' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT7T3</span>
                        <span class="why-detail">{{'HYDRPR41TIT7D3' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>HYDRPR41TIT7T4</span>
                        <span class="why-detail">{{'HYDRPR41TIT7D4' | translate }}</span>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="item_detail" translate>HYDRPR41TIT7CL</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-product-detail2">
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title" translate>HYDRPR41TIT8T</div>
                    <div class="separator mt-1 mb-3"></div>
                </div>
                <div class="each-secion">
                    <div class="item_left_detail">
                        <p class="why-detail" translate>HYDRPR41TIT8D1</p><br>
                        <p class="why-detail" translate>HYDRPR41TIT8D2</p><br>
                        <p class="why-detail" translate>HYDRPR41TIT8D3</p><br>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title" translate>HYDRPR41TIT9T</div>
                </div>

                <div class="each-secion">
                    <div class="item_detail">
                        <p class="why-detail">{{'HYDRPR41TIT9D' | translate }}</p><br>
                    </div>
                </div>
                <div class="each-secion">
                    <div class="each-secion">
                        <div class="item_title" translate>HYDRPR41TITAT</div>
                        <div class="separator mt-1 mb-3"></div>
                    </div>
    
                    <div class="item_detail">
                        <p class="why-detail">{{'HYDRPR41TITAD' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-introduce-part [contensInfo]="introduceInfo"></app-introduce-part>
<!---- at July 8,2024 by Victor
    <div class="introduce">
        <div class="introduce-body">
            <h1 class="introduce-head" translate>HYDRPR41TITBT</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{ 'HYDRPR41TITBD' | translate }}</div>
        </div>
    </div>
-->
</div>


