import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-select-input-dialog',
  templateUrl: './select-input-dialog.component.html',
  styleUrls: ['./select-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectInputDialogComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() fieldName : String;
  @Input() options : String;
  public selectForm : FormGroup;
  public selectedOptions : any[] = [];
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
    private formBuilder : FormBuilder,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.selectForm = this.formBuilder.group({
    //   fieldValue : ['', [Validators.required]],
    // });
  }

  add(option){
    this.selectedOptions.push(option);
    this.cdr.detectChanges();
  }

}
