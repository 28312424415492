import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Wholesale } from 'src/app/modules/common/Wholesale';
import { InvoiceLink } from 'src/app/modules/common/InvoiceLink';
import { Products } from 'src/app/modules/common/Products';

@Component({
  selector: 'app-edit-wholesale',
  templateUrl: './edit-wholesale.component.html',
  styleUrls: ['./edit-wholesale.component.scss']
})
export class EditWholesaleComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() wholesaleId : number=null;
  @Input() pageTittle:string="ADDWHOLE";

  public wholesalekSet : Wholesale;
  public wholesaleFrom: FormGroup;
  public questions : Array<any>=[];
  public isProcessing = false;
  public products: Products[] = [];
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public shipProvinces : Array<any>=[];
  public isSameAdr: boolean=true;
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public activeModal : NgbActiveModal, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    public router : Router,
    public ngDialog : NgbModal
  ) { }

  ngOnInit(): void {
      console.log('ngOnInit ! wholesaleId :', this.wholesaleId);
      this.initializeForms();
      this.getCountryList();
      
      if (this.wholesaleId) {
        this.getWholesale(this.wholesaleId);
      } else {
        this.wholesalekSet = new Wholesale();
      }
      
  }
/*
  ngAfterViewInit(){
    setTimeout(()=>{
      if (this.wholesaleId) {
        this.getWholesale(this.wholesaleId);
      } else {
        this.wholesalekSet = new Wholesale();
      }
    },10);

  }
*/

  initializeForms(){
    this.wholesaleFrom = this.formBuilder.group({
      customer : ['', [Validators.required]],
      phone: [''],
      email: ['', [Validators.required]],
      contact_person: ['', [Validators.required]],
      country: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: [''],
      address: ['', [Validators.required]],
      post_code: [''],
      same_address: [''],
      shipping_country: [''],
      shipping_province: [''],
      shipping_city: [''],
      shipping_address: [''],
      shipping_post_code: [''],
    });

    this.wholesaleFrom.get('country').valueChanges
    .subscribe(value => {
      if(value){
        this.getProvinceList(value,1);
        this.wholesaleFrom.patchValue({
          province : ''
        });
      }
    });

    this.wholesaleFrom.get('shipping_country').valueChanges
    .subscribe(value => {
      if(value){
        this.getProvinceList(value,2);
        this.wholesaleFrom.patchValue({
          shipping_province : ''
        });
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onChangeSameAddress(event: MatCheckboxChange) {
    //console.log('onChangeSameAddress event:',event.checked);
    if (event.checked){
      this.wholesaleFrom.get('shipping_country').clearValidators();
      this.wholesaleFrom.get('shipping_province').clearValidators();
      this.wholesaleFrom.get('shipping_city').clearValidators();
      this.wholesaleFrom.get('shipping_address').clearValidators();
      this.wholesaleFrom.get('shipping_post_code').clearValidators();
    } else {
      this.wholesaleFrom.get('shipping_country').setValidators(Validators.required);
      this.wholesaleFrom.get('shipping_province').setValidators(Validators.required);
      this.wholesaleFrom.get('shipping_city').setValidators(Validators.required);
      this.wholesaleFrom.get('shipping_address').setValidators(Validators.required);
      this.wholesaleFrom.get('shipping_post_code').setValidators(Validators.required);
      this.wholesaleFrom.markAsPristine();
    }
    this.wholesaleFrom.get('shipping_country').updateValueAndValidity();
    this.wholesaleFrom.get('shipping_province').updateValueAndValidity();
    this.wholesaleFrom.get('shipping_city').updateValueAndValidity();
    this.wholesaleFrom.get('shipping_address').updateValueAndValidity();
    this.wholesaleFrom.get('shipping_post_code').updateValueAndValidity();

  }

  getWholesale(id : any)
  {
    this.isProcessing = true;
    this.evgService.getWholesale( {id : id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          let wholesale = resp.data[0].results;
          this.isSameAdr = wholesale.same_address;

          this.wholesaleFrom.patchValue({
            customer : wholesale.customer,
            email : wholesale.email,
            phone : wholesale.phone,
            contact_person : wholesale.contact_person,
            country : wholesale.country,
            province : wholesale.province,
            city : wholesale.city,
            address : wholesale.address,
            post_code : wholesale.post_code,
            same_address : wholesale.same_address,
            shipping_country : wholesale.shipping_country,
            shipping_province : wholesale.shipping_province,
            shipping_city : wholesale.shipping_city,
            shipping_address : wholesale.shipping_address,
            shipping_post_code : wholesale.shipping_post_code,
          });
          
          //console.log(" getWholesale wholesale ", wholesale );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  public updateThenCloseWholesale(formDirective) {
    this.updateWholesale(formDirective, true);
  }
  public updateWholesale(formDirective, doClose:boolean=false)
  {
      this.isProcessing = true;
      let data = this.wholesaleFrom.value;
      if (this.wholesaleId) {
        data.id = this.wholesaleId ;
      }
      data.same_address = this.isSameAdr ? 1 : 0;
      //console.log('updateWholesale  this.isSameAdr:', this.isSameAdr);
      //console.log('updateWholesale data:',data);
      this.evgService.postWholesale( data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.sharedUtilService.resetForm(this.wholesaleFrom,formDirective);
        }
        this.isProcessing = false
        if (doClose) {
          this.activeModal.close(true);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing = false
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
  }

  getCountryList(){
    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }


  getProvinceList(country, addressType:number)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        console.log(resp);
        if (addressType ==1) {
          this.provinces = resp.data[0].results;
        } else {
          this.shipProvinces = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

}
