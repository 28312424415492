import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BonusPool, PgSetting } from 'src/app/modules/common/PerformanceGrowthBonusSetting';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-growth-bonus-funds-pool',
  templateUrl: './growth-bonus-funds-pool.component.html',
  styleUrls: ['./growth-bonus-funds-pool.component.scss']
})
export class GrowthBonusFundsPoolComponent implements OnInit {

  public user: User;
  public pgSetting : PgSetting;
  public bonusPool : BonusPool;
  public pgSettingForm : FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
  ) { }

  ngOnInit(): void {
    this.pgSettingForm = this.formBuilder.group({
      // glb_pool: ['', [Validators.required]],
      // glb_nums: ['', [Validators.required]],
      pgb_pool : ['', [Validators.required]],
      pgb_nums : ['', [Validators.required]],
    });
    this.getUser();
    this.getBonusSetting();
    this.getBonusPool();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getBonusSetting()
  {
    this.evgService.getBonusSetting( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.pgSetting = resp.data[0].results[0];
          //console.log("getBonusSetting",this.pgSetting );
          this.pgSettingForm.patchValue({
            pgb_pool : this.pgSetting.pgb_pool,
            pgb_nums : this.pgSetting.pgb_nums,
          })
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getBonusPool()
  {
    this.evgService.getBonusPool( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.bonusPool = resp.data[0].results;
          this.pgSettingForm.get('pgb_pool').setValidators([Validators.required, Validators.max(this.bonusPool.pgb_pool)]);
          this.pgSettingForm.updateValueAndValidity();
          //console.log("getBonusPool",this.bonusPool);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  setBonusPool(data : any)
  {
    if(this.pgSettingForm.status === "VALID"){
      this.isProcessing = true;
      data.pgb_pool = this.pgSettingForm.get('pgb_pool').value;
      data.pgb_nums = this.pgSettingForm.get('pgb_nums').value;
      this.evgService.setBonusPool( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          if(resp.data[0]){
            //console.log("setBonusPool",resp);
            this.responseHandlerService.handleResponse(resp);
          }
        }
        this.isProcessing = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }


}
