<div class="withdraw-for-user-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'WITHDRAWFORUSER' | translate}}</h3>
              <h4 class="title" *ngIf="request && request.locked==1" style="color:red;">({{'LOCKEDREQ' | translate}})</h4>
              <div class="text-center mb-2 mt-2">
                <form [formGroup]="searchRequestForm" (keydown.enter)="$event.preventDefault()" autocomplete="sr-form">
                    <!-- <mat-form-field style="text-align: center">
                        <input matInput formControlName="id"  type="text" class="form-control text-box" id="id" aria-describedby="id"
                         name="id" [placeholder] = "'ORDERID' | translate"
                        autocomplete="off"
                        />
                        <mat-error class="error-message" *ngIf="searchRequestForm.get('id').hasError('required')">{{ 'ORDERIDREQ' | translate }}</mat-error>
                      </mat-form-field> -->

                      <mat-form-field style="text-align: center;width: 100%;">
                          <mat-label>{{'ORDERID' | translate}}</mat-label>
                          <mat-select formControlName="id"  type="text" class="form-control" id="id" aria-describedby="id"
                          autocomplete="off">
                              <mat-option  *ngFor="let selectRequest of requests; index as i;" [value]="selectRequest.id">{{ 'Order Number: ' + selectRequest.id + ' - User : ' + selectRequest.sender }}</mat-option>
                          </mat-select>
                          <mat-error class="error-message" *ngIf="searchRequestForm.get('id').hasError('required')">{{'ORDERIDREQ' | translate}}</mat-error>
                      </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!searchRequestForm.valid || isProcessing1" (click)="onSearchRequest()" >{{ "SEARCHBTN" | translate}}</button>
                </form>
              </div>
              <div class="table-responsive" *ngIf="request">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <!-- <th scope="col">{{'ORDERID' | translate}}</th> -->
                        <th scope="col">{{'SENDER' | translate}}</th>
                        <th scope="col">{{'TOPIC' | translate}}</th>
                        <th scope="col">{{'DETAILS' | translate}}</th>
                        <th scope="col" *ngIf="bankInfo">{{'BANK' | translate}}</th>
                        <!-- <th scope="col">{{'REQUESTTYPE' | translate}}</th> -->
                        <!-- <th scope="col">{{'ATYPE' | translate}}</th> -->
                        <!-- <th scope="col">{{'RECEIVER' | translate}}</th> -->
                        <th scope="col">{{'AMOUNT' | translate}}</th>
                        <th scope="col">{{'CLOSED' | translate}}</th>
                        <th scope="col">{{'ADDTIME' | translate}}</th>
                        <th scope="col">{{'ACTION' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- <td>{{ request.id }}</td> -->
                        <td>{{ request.sender }}</td>
                        <td>{{ request.topic }}</td>
                        <td>
                          <tr>
                            <th>{{'ORDERID' | translate}}</th>
                            <td style="width: 100%;">{{ request.id }}</td>
                          </tr>
                          <tr>
                            <th>{{'GROUPID' | translate}}</th>
                            <td style="width: 100%;">{{ request.groupId ? request.groupId : 'Not Applicable'  }}</td>
                          </tr>
                          <tr>
                            <th>{{'ATYPE' | translate}}</th>
                            <td style="width: 100%;">
                              {{ getAType(request.aType) }}&nbsp;({{request.withdrawal_type==0? " SWIFT " : " ACH " }})
                            </td>
                          </tr>
                        </td>
                        <td *ngIf="request.aType==1">
                          <tr *ngIf="request.withdrawal_type==1">
                            <th>{{'ACHROUTINGNUMBER' | translate}}</th>
                            <td style="width: 100%;">{{ request.ACH_routing }}</td>
                          </tr>
                          <tr *ngIf="!(request.withdrawal_type==1)">
                            <th>{{'SWIFTABACODE' | translate}}</th>
                            <td style="width: 100%;">{{ request.BankABA }}</td>
                          </tr>
                          <tr>
                            <th>{{'INSNUMBER' | translate}}</th>
                            <td style="width: 100%;">{{ request.InstitutionNo }}</td>
                          </tr>
                          <tr>
                            <th>{{'BRANCHNUMBER' | translate}}</th>
                            <td style="width: 100%;">{{ request.BranchNo }}</td>
                          </tr>
                          <tr>
                            <th>{{'ACCOUNTNUMBER' | translate}}</th>
                            <td style="width: 100%;">{{ request.AccountNo }}</td>
                          </tr>
                          <tr>
                            <th>{{'BANKNAME' | translate}}</th>
                            <td style="width: 100%;">{{ request.BankName }}</td>
                          </tr>
                          <tr>
                            <th>{{'BANKADDRESS' | translate}}</th>
                            <td style="width: 100%;">{{ request.BankAddress }}</td>
                          </tr>
                          <tr>
                            <th>{{'RECEIVERNAME' | translate}}</th>
                            <td style="width: 100%;">{{ request.receiverName }}</td>
                          </tr>
                          <tr>
                            <th>{{'RECEIVERADDRESS' | translate}}</th>
                            <td style="width: 100%;text-wrap:wrap ;">
                              {{ request.ReceiverAddress }}&nbsp;,
                              {{ request.receiver_city }}&nbsp;,
                              {{ request.receiver_province }}&nbsp;,
                              {{ request.receiver_postcode }}&nbsp;,
                              {{ request.receiver_country }}
                            </td>
                          </tr>
                        </td>
                        <!-- <td>{{ getRequestType(request.requestType) }}</td> -->
                        <!-- <td>{{ getAType(request.aType) }}</td> -->
                        <!-- <td>{{ request.receiver }}</td> -->
                        <td>{{ request.amount | currency:''}}</td>
                        <td>{{ request.isClosed ? ('YES' | translate) : ('NO' | translate)  }}<br>{{request.reopen==1 && request.isClosed ? ('(' + ('REOPEN' | translate) + ')'): ''}}</td>
                        <td>{{ request.AddTime }}</td>
                        <td>
                            <ng-container *ngIf="!request.isClosed">
                                <div class="row p-3">
                                  <button mat-stroked-button color="primary" class="btn submit-button mr-2" [disabled]="!searchRequestForm.valid || isProcessing2 || request.locked==1" (click)="onApproveRequest()" >{{ "APPROVEBTN" | translate}}</button>
                                </div>
                                <div class="row p-3">
                                  <mat-checkbox class="p-2" [(ngModel)]="returnFee">{{ "REFUNDTF" | translate}}</mat-checkbox>
                                  <button mat-stroked-button color="warn" class="btn submit-button" [disabled]="!searchRequestForm.valid || isProcessing3 || request.locked==1" (click)="onCancelRequest()" >{{ "CANCELBTN" | translate}}</button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="request.isClosed">
                                {{ request.result }}
                            </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            </div>
          </div>
        </div>
    </div>
</div>