import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' }) // Shared service for entire application
export class EventService {
  private subject = new BehaviorSubject<any>(null); // Hold broadcast data

  emit<T>(data: T) {
    this.subject.next(data);
  }

  on<T>(): Observable<T> {
    return this.subject.asObservable();
  }
}