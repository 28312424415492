<div class="card p-3">
    <div class="card-body text-center">
        <p mat-dialog-title class="pl-5 pr-5 pb-1 pt-1">{{ message }}</p>
        <p *ngIf="message2" mat-dialog-title class="pl-5 pr-5 pb-3 pt-1">{{ message2 }}</p>
        <div class="d-flex justify-content-center">
          <button mat-raised-button color="primary" class="mr-2 orange-button" (click)="activeModal.close(true)">{{ "ACCEPT" | translate }}</button>
          <button mat-raised-button (click)="activeModal.close(false)">{{ "REFUSE" | translate }}</button>
        </div>
    </div>
</div>
