<div class="sendlink-container  center">
    <div class="card-header">
        <div class="row" style="margin-top:-0.8rem">
            <div class="p-0" style="margin: 1rem auto;">
                <h1 class="title" translate>WPGATEWAY</h1>
            </div>
        </div>
    </div>
    <div class="pagebody center mt-0">
        <div class="row">
            <form  #changeRank="ngForm" [formGroup]="paymentFrom" (keydown.enter)="$event.preventDefault()" autocomplete="cr-form" style="width:100%;">
                <div class="row col-12 ml-1" style="padding-top:-1rem;">
                    <div class="col-10">
                        <h2 translate>PAYCREDITCARD</h2>
                    </div>
                    <div class="col-2 float-right">
                        <!--
                        <button type="button" class="btn btn-default btn-sm ml-2" (click)="onPrReceipt()" >
                            <span class="glyphicon glyphicon-print"></span>
                        </button>
                        -->
                        <button type="button" class="btn btn-default btn-sm ml-1" (click)="onPrintInvoice()" title="{{'SHOWDOWNLOAD' | translate }} {{ 'INVOICE' | translate}}">
                            <i class="fa fa-eye" aria-hidden="true">invoice</i>
                        </button>
                    </div>
                </div>
                <div class="row col-12 ml-1">
                    <div>{{'PLEASEPAY' | translate}}&nbsp;$&nbsp;{{invoiceLink.amount}}&nbsp;{{ 'FORINVOICE' | translate }}&nbsp;&nbsp;<a class="btn  btn-light" role="button" (click)="showInvoice()" translate>CONFIRM</a></div>
                </div>
                <div class="row" style="margin-top:-0.8rem">
                    <div class="p-0" style="margin: 1rem auto 0 auto;">
                        <h2 class="title2" translate>CREDITCARDINFO</h2>
                    </div>
                </div>
        
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="holder"  type="text" class="form-control text-box" id="holder" 
                            name="holder" placeholder="{{ 'NAMEONCARD' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="paymentFrom.get('holder').hasError('required')">{{'HOLDERREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="card_no"  type="number" class="form-control text-box" id="card_no" 
                            name="card_no" placeholder="{{ 'CARDNO' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="paymentFrom.get('card_no').hasError('required')">{{'CARDNOREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row col-12 from-group">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="expiry_date" type="month" pattern="[0-9]{4}-[0-9]{2}" class="form-control" id="expiry_date" aria-describedby="expiry_date"
                            placeholder="{{ 'EXPDATE' | translate }}" 
                            autocomplete="off">
                            <mat-error class="error-message" *ngIf="paymentFrom.get('expiry_date').hasError('required')" >{{ "EXPDATEREQ" | translate }} </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="cvcCode"  type="number" class="form-control text-box" id="cvcCode" 
                            name="cvcCode" placeholder="{{ 'CVCODE' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="paymentFrom.get('cvcCode').hasError('required')">{{'CVCODEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12 cform-popup-form" style="margin:2rem auto" *ngIf="isPaid">
                    <div style="text-align: center; margin: auto; color: red;">
                        This invoice already paid at {{invoiceLink.paid_at}} !
                    </div>
                </div>

                <div class="col-12 cform-popup-form" style="margin:2rem auto">
                    <div style="text-align: center; margin: auto;">
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!paymentFrom.valid || isProcessing || isPaid" (click)="postInvoicePayment(changeRank)" >{{ "PAYAMOUNT" | translate}}</button>
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="isProcessing"  (click)="activeModal.close('close')" title="close" >{{ "CANCELBTN" | translate}}</button>
                    </div>
                </div>

                <div class="row" style="margin-top: 0.5rem">
                    <div class="p-0" style="margin: 0 auto;">
                        <h2 class="title2" translate>BILLINGINFO</h2>
                    </div>
                </div>
                <div class="infocontent">
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'COUNTRYLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.country}}</span></div>
                    </div>
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'PROVINCELABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.province}}</span></div>
                    </div>
    
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'CITYLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.city}}</span></div>
                    </div>
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'ADDRESSLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.address}}</span></div>
                    </div>
    
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'POSTALCODELABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.post_code}}</span></div>
                    </div>
                </div> 
                <div class="row" style="margin-top: 0.5rem">
                    <div class="p-0" style="margin: 0 auto;">
                        <h2 class="title2" translate>SHIPPINGINFO</h2>
                    </div>
                </div>
                <div class="infocontent" *ngIf="isSameAdr">
                    <div class="row col-12">
                        <h3>Shipping address is same with Billing address!</h3>
                    </div>
                </div>
                <div class="infocontent" *ngIf="!isSameAdr">
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'COUNTRYLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.shipping_country}}</span></div>
                    </div>
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'PROVINCELABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.shipping_province}}</span></div>
                    </div>
    
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'CITYLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.shipping_city}}</span></div>
                    </div>
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'ADDRESSLABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.shipping_address}}</span></div>
                    </div>
    
                    <div class="row col-12">
                        <div class="col-5"><span class="float-right pr-3">{{'POSTALCODELABEL' | translate}}</span></div>
                        <div class="col-7"><span class="content">{{invoiceLink.shipping_post_code}}</span></div>
                    </div>
                </div> 
            </form>
        </div>
    </div>
</div>
