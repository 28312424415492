<div class="send-link-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form #changeRank="ngForm" [formGroup]="uploadedFrom" (keydown.enter)="$event.preventDefault()" autocomplete="cr-form" enctype="multipart/form-data">
                        
                        <div class="p-3">
                            <h2>{{ 'CUSTSUPPORTSUB10' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="title"  type="text" class="form-control text-box" id="title"
                                    name="title"  placeholder="{{ 'TITLELABEL' | translate }}"  autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="uploadedFrom.get('title').hasError('required')">{{'TITLEISREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="description"  type="text" class="form-control text-box" id="description" 
                                    name="description" placeholder="{{ 'DESCRIPTION' | translate }}" 
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="uploadedFrom.get('description').hasError('required')">{{'DESCRIPTION' | translate}}  {{'REQUIRED' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 m-3">
                                <div style="width: 96%;">
                                    <label class="custom-file-label" for="fileUpload">Choose file</label>
                                    <input #fileUpload  
                                        (change)="onFileChange($event)" type="file" 
                                        class="custom-file-input" name="fileUpload" 
                                        id="fileUpload"
                                        accept="pdf/*"  
                                        aria-describedby="fileUpload">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="file_name"  type="text" class="form-control text-box" id="file_name" 
                                    name="file_name" placeholder="{{ 'FILENAME' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="uploadedFrom.get('file_name').hasError('required')">{{'FILENAME' | translate}} {{'REQUIRED' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="file_type"  type="text" class="form-control text-box" id="file_type" 
                                    name="file_type" placeholder="{{ 'FILETYPE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="uploadedFrom.get('file_type').hasError('required')">{{'FILETYPE' | translate}} {{'REQUIRED' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="author"  type="text" class="form-control text-box" id="author" 
                                    name="author" placeholder="{{ 'AUTHOR' | translate }}" 
                                    autocomplete="off" 
                                    >
                                    <mat-error class="error-message" *ngIf="uploadedFrom.get('author').hasError('required')">{{'AUTHOR' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngFor="uploadedId">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="inactive"  type="checkbox" class="form-check-input" id="inactive" 
                                    name="inactive" placeholder="{{ 'INACTIVE' | translate }}" [checked]="uploadedObj.inactive==1" [(ngModel)]="uploadedObj.inactive"
                                    autocomplete="off"
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngFor="uploadedId">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <mat-label>{{'DOWNLOAD' | translate}} {{'TIMES' | translate}}</mat-label>{{uploadedObj.times}}
                                </mat-form-field>
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="isProcessing"  (click)="activeModal.close('close')" >{{ "CLOSEBTN" | translate}}</button>
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!uploadedFrom.valid || isProcessing || !(selectedUploadFile || uploadedId)" (click)="submitUploadDoc(changeRank)" >{{ "SUBMIT" | translate}}</button>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
    </div>
</div>
