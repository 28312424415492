import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order, OrderDetail } from 'src/app/modules/common/Order';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { OrderDetailComponent } from 'src/app/modules/order-detail/order-detail.component';
import { OrderInfor,OrderGroup } from 'src/app/modules/common/Order';

@Component({
  selector: 'app-purchased-orders',
  templateUrl: './sold-orders.component.html',
  styleUrls: ['./sold-orders.component.scss']
})
export class SoldOrdersComponent implements OnInit {


  public user: User;
  public newOrders : OrderGroup[] = [];
  public selectedOrderDetails : OrderDetail[] = null;
  public selectedOrder : string = null;
  private _unsubscribe$ = new Subject<any>();
  public users_ord =[];
  public bonus_list=[];
  public orderType:string=null;
  public selectedUserId:number=null;
  public isProcessing : boolean=false;
  public dateRangeForm : FormGroup;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private ngDialog : NgbModal,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.dateRangeForm = this.formBuilder.group({
      selectedMemberNo: [0,[]],
      orderType: [0, []],
    });
     this.getUser();
     this.getAllOrders(null);
     this.getUsersFromOrders();
    }

  onUserIdSelected(userId_index){
    this.selectedUserId = userId_index;//<0 ? 0 : this.users_ord[userId_index].MemberNo;
    //console.log("findPayoutBonusByOrderId bonus_list:",this.bonus_list );

    //this.getAllOrders(userId);
  }

  unSelectOrder()
  {
    this.selectedOrder = null;
    this.selectedOrderDetails = null;
    this.getAllOrders(null);
    this.getUsersFromOrders();
  }

  getOrderDetail(groupId,groupIndex)
  {
    //console.log(groupId);
    this.evgService.getOrderInfo( {groupId : groupId} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.selectedOrderDetails = resp.data[0].results;
          if  (this.selectedOrderDetails &&  this.selectedOrderDetails.length>0 && groupIndex>=0 ) {
            let params='';

            this.selectedOrderDetails.forEach( (eOrder, index) => {
              params += (index >0 ? ',':'') + eOrder.orderNo;
            });
            this.evgService.findPayoutBonusByOrderId( {orderIds : params} ).subscribe(respp => {
              if (respp) {
                if(respp.data[0]){
                  this.bonus_list = respp.data[0].results;

                  //console.log("getOrderDetail selectedOrderDetails:",this.selectedOrderDetails );
                  //console.log("findPayoutBonusByOrderId bonus_list:",this.bonus_list );
                  //console.log("selected groupIndex, orderHeader:",groupIndex, this.newOrders[groupIndex] );

                  const modalRef = this.ngDialog.open(OrderDetailComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                  modalRef.componentInstance.translate = this.translate;
                  modalRef.componentInstance.orderHeader = this.newOrders[groupIndex];
                  modalRef.componentInstance.orderInfor = this.selectedOrderDetails;
                  modalRef.componentInstance.paidOutBounses = this.bonus_list;
                  
                  modalRef.result.then((result) => {
                    console.log(result);
                    //this.getInvoiceLinks(this.requestsForm.value);
                  });
      
                }
              }
            });
          } else {
            console.log('getOrderInfo selectedOrderDetails:',this.selectedOrderDetails);
            alert('to run getOrderInfo function got empty result !');
          }
        } else {
          alert('to run getOrderInfo function got empty result !');
        }
      } else {
        alert('Failed for to run getOrderInfo function');
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getUsersFromOrders()
  {
    this.evgService.findAllUserIdFromOrders({})
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log(successResponse);
              this.users_ord = successResponse.data[0].results;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getSelectedOrderGroups() {

    this.getAllOrders(this.selectedUserId);

  }

  getAllOrders(selectedUserId)
  {
    //let obj = {userId : selectedUserId,orderType : this.orderType};
    let obj = {  userId : this.dateRangeForm.get('selectedMemberNo').value, 
                  orderType : this.dateRangeForm.get('orderType').value};

    //console.log('call getAllOrders  obj:',obj);
    this.evgService.findAllOrderGroups( obj ).subscribe(resp => {
      if(resp.success){
        this.newOrders = resp.data[0].results;
        //console.log("getAllOrders",this.newOrders );
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
}
