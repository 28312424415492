import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { InputDialogComponent } from 'src/app/modules/input-dialog/input-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-refund-byte-tokens',
  templateUrl: './refund-byte-tokens.component.html',
  styleUrls: ['./refund-byte-tokens.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefundByteTokensComponent implements OnInit {

  public user: User;
  public refundRequests : any[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public isProcessing1 = false;
  public isProcessing2 = false;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    public cdr : ChangeDetectorRef,
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getRefundRequests();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getRefundRequests()
  {
    this.isProcessing = true;
    this.evgService.getRefundBTRequest( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.refundRequests = resp.data[0].results;
          this.cdr.detectChanges();
          //console.log("get Refunds",this.refundRequests );
        }
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
    
  }

  


 

  onApproveRefund(request)
  {
    this.isProcessing1 = true;
    this.evgService.approvalRefundByteTokens( {id : request.id, refundId : request.refundId, bank_id : request.bank_id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.getRefundRequests();
      }
      this.isProcessing1 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onRejectRefund(request)
  {
    const modalRef = this.ngDialog.open(InputDialogComponent, { windowClass: 'input-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.fieldName = 'REASON';
    modalRef.result.then((data) => {
      if(data){
        this.isProcessing2 = true;
        this.evgService.rejectRefundByteTokens( {id : request.id, rejectReason : data} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.getRefundRequests();
          }
          this.isProcessing2 = false;
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.isProcessing2 = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
    }, (reason) => {
      //console.log(reason);
    });
  }

}
