import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageSnippet } from 'src/app/modules/common/ImageSnippet';
import { User } from 'src/app/modules/common/User';
import { DistributorTermsComponent } from 'src/app/modules/distributor-terms/distributor-terms.component';
import { SecretQuestionSettingComponent } from 'src/app/modules/secret-question-setting/secret-question-setting.component';
import { SecretQuestionComponent } from 'src/app/modules/secret-question/secret-question.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {


  public user: User = new User();
  public isEditMode : boolean = false;
  public profileForm: FormGroup;
  public languages : Array<any>=[];
  public education_levels : Array<any>=[];
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public webcamImage: WebcamImage = null;
  public selectedFile: ImageSnippet;
  public matcher = new PasswordErrorMatcherService();
  public selectedCountry : {
    name : '',
    code : ''
  };
  private _unsubscribe$ = new Subject<any>();
  public isInactived:boolean= false;



  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private ngDialog : NgbModal,
    private responseHandlerService : ResponseHandlerService,
    public sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getUser();
    this.getOptions();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.profileForm.patchValue({
                Sex: this.user.Sex,
                Birthday: this.user.Birthday ? this.user.Birthday.substring(0,10) : this.user.Birthday,
                mobile: this.user.mobile,
                Tel: this.user.Tel,
                language: this.user.language,
                Address: this.user.Address,
                City: this.user.City,
                Province: this.user.Province,
                Country: this.user.Country,
                PostCode: this.user.PostCode,
                education_level : this.user.education_level,
                annual_income : this.user.annual_income,
                askSecretQ : this.user.askSecretQ,
                isDistributor : this.user.isDistributor,
                autoship : this.user.autoship,
             });
             this.isInactived = (this.user.autoship==1)
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getOptions(){
    this.evgService.getEducationLevels().subscribe( resp => {
      if (resp) {
        this.education_levels = resp;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

    this.evgService.getLanguages().subscribe( resp => {
      if (resp) {
        this.languages = resp;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });


  }

  getProvinceList(country)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        this.provinces = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  initForm(){
    this.profileForm = this.formBuilder.group({
      Sex: [],
      Birthday: [],
      mobile: [],
      Tel: [],
      language: [],
      Address: [],
      City: [],
      Province: ['',[Validators.required]],
      Country: ['',[Validators.required]],
      PostCode: [],
      education_level : [],
      annual_income : [],
      askSecretQ : ['', [Validators.required]],
      isDistributor  : ['',[]],
      autoship: [1,[]],
    });

    this.profileForm.get('Country').valueChanges
    .subscribe(value => {
      if(value){
        this.getProvinceList(value);
      }
    });
    
  }
  uploadPhoto(imageAsData)
  {
    if(imageAsData){
      this.responseHandlerService.handleCustomResponse("Uploading Photo","info");
      this.evgService.uploadUserPhoto({id : this.user.id, image : imageAsData })
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else{
      this.responseHandlerService.handleCustomResponse("Please select a photo!","error");
    }
  }

  askSecretQClick(){
    if(this.profileForm.get('askSecretQ').value == 1){
      const modalRef = this.ngDialog.open(SecretQuestionSettingComponent, { windowClass: 'secret-question-setting-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;

      modalRef.result.then((data) => {
        this.profileForm.get('askSecretQ').setValue(0);
      }, (reason) => {
        this.evgService.updateUser({askSecretQ : 1})
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.shareinfoService.reloadUser();
            // this.shareinfoService.reloadTaxRate();
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      });
    }
  }

  distrib_click(){
    this.profileForm.patchValue({
      isDistributor : 0
    })
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.distributor = this.user.first_name + ' ' + this.user.last_name;

    modalRef.result.then((data) => {
    }, (reason) => {
      this.profileForm.patchValue({
        isDistributor : 1
      })
    });
  }

  autoship_click(event){
    this.profileForm.patchValue({
      autoship : event.checked === true? 1: 0
    });
    this.isInactived = (event.checked === true);
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
    });

    reader.readAsDataURL(file);
  }

  updateProfileData()
  {
    if(this.profileForm.status == 'VALID'){
      this.evgService.updateUser(this.profileForm.value)
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.shareinfoService.reloadUser();
          // this.shareinfoService.reloadTaxRate();
          this.responseHandlerService.handleResponse(resp);
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      
    }
    
  }

  onProfileEdit()
  {
    this.isEditMode = true;
  }

  onProfileSave()
  {
    this.updateProfileData();
    this.isEditMode = false;
  }

  onProfileDiscard()
  {
    this.isEditMode = false;
  }


  onOpenCamera(content)
  {
    this.modalService.open(content, { windowClass: 'camera-modal', size: 'lg' });
  }

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  triggerSnapshot(): void {
   this.trigger.next();
  }
  
  handleImage(webcamImage: WebcamImage): void {
   console.info('received webcam image', webcamImage);
   this.webcamImage = webcamImage;
  }
 
  public get triggerObservable(): Observable<void> {
   return this.trigger.asObservable();
  }

  public handleInitError(error: WebcamInitError): void {
    this.responseHandlerService.handleCameraResponseError(error);
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }

}
