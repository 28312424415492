import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/app/modules/common/User';

@Component({
  selector: 'app-userid-webpage',
  templateUrl: './userid-webpage.component.html',
  styleUrls: ['./userid-webpage.component.scss']
})
export class UseridWebpageComponent implements OnInit {
  public personalWebpageForm: FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public user;
  public link = this.translate.translate.instant('PPAGELINK');//"Fill up form to build your personal webpage link.";
  constructor(
    private router : Router,
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService,
    private formBuilder : FormBuilder,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    //console.log(this.router.url);

    this.initForms();
    this.getUser();
  }

  initForms()
  {
    this.personalWebpageForm = this.formBuilder.group({
      email  : ['', []],
      contactNumber  : ['', []],
      linkType  : ['/home', []],
    });
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.getLink();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getLink()
  {
    this.link = environment.hostname + "/home?page=" +
     String(this.sharedUtilService.encryptData(this.user.id + ",ByteHealth,,")).replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l');
    console.log('getLink:',this.link);
    return this.link;
          
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.responseHandlerService.handleCustomResponse('Link copied to clipboard!','success');
  }
}
