import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-confirm-withdrawal-request',
  templateUrl: './confirm-withdrawal-request.component.html',
  styleUrls: ['./confirm-withdrawal-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmWithdrawalRequestComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  @Input() data : any;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private shareinfoService : ShareinfoService
  ) { }

  ngOnInit(): void {
    
  }

  onConfirm()
  {
    if(this.data.action==1) {
      this.evgService.cancelWithdrawalRequest(this.data.id).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            this.shareinfoService.reloadUser();
            this.activeModal.close(true);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });

    }else{
      this.evgService.doEWTransfers(this.data).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.success){
            this.shareinfoService.reloadUser();
            this.activeModal.close(true);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
    
  }

}
