<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <div class="p-3">
                        <h2>{{ 'BYTETOKENTRXHISTORY' | translate }}</h2>
                    </div>
                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col" class="col-sm-3">{{'DATE' | translate}}</th>
                            <th scope="col" class="col-sm-3">{{'DESCRIPTION' | translate}}</th>
                            <th scope="col" class="col-sm-3">{{'FUNDSINOROUT' | translate}}</th>
                            <th scope="col" class="col-sm-3">{{'BALANCE' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of byteTokenTrxHistory">
                                <td scope="col" class="col-sm-3 text-sm">{{item.date}}</td>
                                <td scope="col" class="col-sm-3 text-sm">{{item.description}}</td>
                                <td scope="col" class="col-sm-3 text-sm">{{item.inORout+' '+(item.funds | currency)}}</td>
                                <td scope="col" class="col-sm-3 text-sm">{{item.balance | currency}}</td>
                            </tr>
                        </tbody>
                      </table>      
                                        
                </div>
            </div>
        </div>
    </div>
     
</div>