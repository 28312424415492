<div class="change-user-rank-container">
    <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form  #changeRank="ngForm" [formGroup]="changeRankForm" (keydown.enter)="$event.preventDefault()" autocomplete="cr-form">
                        
                        <div class="p-3">
                            <h2>{{ 'CHANGEUSERRANK' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="userId"  type="text" class="form-control text-box" id="userId"
                                name="userId"  placeholder="{{ 'USERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeRankForm.get('userId').hasError('required')">{{'USERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <!-- <mat-form-field style="width: 100%;">
                                <input matInput formControlName="setRank"  type="text" class="form-control text-box" id="setRank" 
                                name="setRank" placeholder="{{ 'RANK' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeRankForm.get('setRank').hasError('required')">{{'RANKREQ' | translate}}</mat-error>
                            </mat-form-field> -->
                            <mat-form-field style="width: 100%;">
                                <mat-label>{{'RANK' | translate}}</mat-label>
                                <mat-select formControlName="setRank"  type="text" class="form-control" id="setRank" aria-describedby="setRank"
                                autocomplete="off">
                                    <mat-option  [value]="0">{{ 'QDEALER' | translate}}</mat-option>
                                    <mat-option  [value]="1">{{ 'QCONSULTANT' | translate}}</mat-option>
                                    <mat-option  [value]="2">{{ 'QDISTRIBUTOR' | translate}}</mat-option>
                                    <mat-option  [value]="3">{{ 'QEXECUTIVE' | translate}}</mat-option>
                                    <mat-option  [value]="4">{{ 'QSAPPHIREDISTRIBUTOR' | translate}}</mat-option>
                                    <mat-option  [value]="5">{{ 'QDIAMONDDISTRIBUTOR' | translate}}</mat-option>
                                    <mat-option  [value]="6">{{ 'QCROWNDISTRIBUTOR' | translate}}</mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="changeRankForm.get('setRank').hasError('required')">{{'RANKREQ' | translate}}</mat-error>
                            </mat-form-field>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="keepDays"  type="number" class="form-control text-box" id="keepDays"
                                    name="keepDays"  placeholder="{{ 'KEEPRANKDAYS' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="changeRankForm.get('keepDays').hasError('required')">{{'KEEPRANKDAYSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="setFixed"  type="text" class="form-control text-box" id="setFixed" 
                                name="setFixed" placeholder="{{ 'FIXED' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="changeRankForm.get('setFixed').hasError('required')">{{'FIXEDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!changeRankForm.valid || isProcessing" (click)="updateUserRank(changeRank)" >{{ "UPDATEBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
        
    </div>
</div>