import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRequest } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { uploadDoc } from 'src/app/modules/common/uploadDoc';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import {saveAs} from 'file-saver';


@Component({
  selector: 'app-download-centre',
  templateUrl: './download-centre.component.html',
  styleUrls: ['./download-centre.component.scss']
})
export class DownloadCentreComponent implements OnInit {

  public user: User;
  public uploadedDocs : uploadDoc[] = [];
  private _unsubscribe$ = new Subject<any>();
  public isProcessing = false;
  public requestsForm : FormGroup;

  constructor(
    public activeModal : NgbActiveModal, 
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.getuploadedDocs({infoType:0});
  }

  getuploadedDocs(data : any)
  {
    this.isProcessing = true;
    this.evgService.getUploadedDocs( {infoType :data.infoType} ).subscribe(resp => {
      //console.log("getuploadedDocs resp :", resp );
      if (resp) {
        if(resp.data[0] && resp.data[0].results){
          
          this.uploadedDocs = resp.data[0].results ;
         // console.log("getuploadedDocs resp :",resp );
        }
       // console.log("getuploadedDocs  this.uploadedDocs :", this.uploadedDocs );
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


  onDownloadDoc(docObj : uploadDoc ) {
    //console.log('onDownloadDoc  dicId: fileName:',docObj);
    this.shareinfoService.setCreditProcess(true);

    this.evgService.downloadDocuments({id: docObj.id }).subscribe(
        data => {
          this.shareinfoService.setCreditProcess(false);
          saveAs(data, docObj.file_name);
        },
        error => {
          this.shareinfoService.setCreditProcess(false);
          console.error(error);
        }
    );
  }
  
  closeReturn(){
    this.activeModal.close(true);
  }

}
