import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-publish-additional-bonus',
  templateUrl: './publish-additional-bonus.component.html',
  styleUrls: ['./publish-additional-bonus.component.scss']
})
export class PublishAdditionalBonusComponent implements OnInit {

  public sendTokenForm: FormGroup;
  public withdrawTokenForm: FormGroup;
  public questions : Array<any>=[];

  private _unsubscribe$ = new Subject<any>();
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.sendTokenForm = this.formBuilder.group({
      amount : ['', [Validators.required]],
      toUserId: ['', [Validators.required]],
      stringId: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });

    this.withdrawTokenForm = this.formBuilder.group({
      amount : ['', [Validators.required]],
    });
  }

  public sendTokensToUserFromAYB(formDirective)
  {
    if(confirm("Send Tokens to User?")) {
      this.isProcessing1 = true;
      this.evgService.sendTokensToUserFromAYB( this.sendTokenForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.sharedUtilService.resetForm(this.withdrawTokenForm,formDirective);
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

  public withdrawFromAYB(formDirective)
  {
    if(confirm("Withdraw Tokens?")) {
      this.isProcessing2 = true;
      this.evgService.withdrawFromAYB( this.withdrawTokenForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.sharedUtilService.resetForm(this.withdrawTokenForm,formDirective);
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
  }

}
