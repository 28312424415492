<form class="col checkout-form" [formGroup]="paymentForm">
  <input type="text" formControlName="name" placeholder="Name" />
  <input type="text" formControlName="email" placeholder="Email" />
  <input type="text" placeholder="Amount" value="{{ amount | currency:'' }}" readonly/>
  <div id="card-element"></div>
  <div class="stripe-card" ngxStripeCardGroup [elementsOptions]="elementsOptions">
    <h4 style="margin: 0.5rem auto;">{{ 'EPAYMENTDETAIL' | translate }}</h4>
    <ng-container style="width: 98%;margin: 5px;z-index: 99;" class="form-group w-full stripe-element">
      <ngx-stripe-card
      [options]="cardOptions"
      (change)="onChange($event)"
      [elementsOptions]="elementsOptions"
      ></ngx-stripe-card>
    </ng-container>
  </div>
  <button type="submit" class="btn btn-success" [disabled]="!validForm" (click)="pay()">{{ 'SUBMITPAY' | translate }}</button>
</form>
