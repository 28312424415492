import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TintedPoreComponent } from './tinted-pore.component';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: '', component: TintedPoreComponent }
];

@NgModule({
  declarations: [],
  imports: [CommonModule,RouterModule.forChild(routes)]
  //imports: [RouterModule.forChild(routes)],
  //exports: [RouterModule]
})
export class TintedPoreRoutingModule { }
