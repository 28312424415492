<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title">
        <h2 class="mb-3">{{ 'AMAZING' | translate}}</h2> 
        <h2 class="mb-3">{{ 'PPURIFIER' | translate}} </h2>
        <p class="desc">{{ 'AIRP1' | translate}}</p>
        <a class="btn banner-button" href="https://www.amazingwaterinc.com/shop/p/amazingair" target="_blank">{{ 'SHOPNOW' | translate }}</a>
    </div>

    <div class="use-reasons d-flex flex-column">
       
        <div class="use-reasons-body">
            <h1 class="use-reasons-head" translate>
                USEREASONS
            </h1>
            <div class="d-flex justify-content-center">
                <div class="separator mb-5">
        
                </div>
            </div>
            <div class="more-body">
                <div class="row d-flex justify-content-around" > 
                        <div class="card m-2">
                        <div class="card-body">
                            <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/air-purifier_pic2.JPG" alt="">
                            <h5 class="card-title m-3" translate>USEREASONS1</h5>
                            <p class="m-4" translate>USEREASONS1DESC</p>
                        </div>
                    </div>
                        <div class="card m-2">
                            <div class="card-body">
                            <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/air-purifier_pic3.jpg" alt="">
                            <h5 class="card-title m-3" translate>USEREASONS2</h5>
                            <p class="m-4" translate>USEREASONS2DESC</p>
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                            <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/air-purifier_pic4.jpg" alt="">
                            <h5 class="card-title m-3" translate>USEREASONS3</h5>
                            <p class="m-4" translate>USEREASONS3DESC</p>
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                            <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/air-purifier_pic5.jpg" alt="">
                            <h5 class="card-title m-3" translate >USEREASONS4</h5>
                            <p class="m-4" translate>USEREASONS4DESC</p>
                            </div>
                        </div>
                </div>
            </div>
            
        </div>  
    </div>

    <div class="byte-air">
       
        <div class="byte-air-body">
            <h1 class="byte-air-head" translate>
                BYTEAIRPURIFIER
            </h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3">
        
                </div>
            </div>
            <div class="more-body mb-3">
                <p class="" translate>BYTEAIRPURIFIERDESC</p>
            </div>
            <a class="btn banner-button" (click)="openPage('blog/12')" target="_blank">{{ 'READMORE' | translate }}</a>
        </div>  
    </div>

    <div class="nine-stages d-flex flex-column">
       
        <div class="nine-stages-body">
            <h1 class="nine-stages-head" translate>
                NINESTAGES
            </h1>
            <div class="more-body">
                <div class="row d-flex justify-content-around" style="margin: 0;">
                    <div class="middle-banner d-flex justify-content-center align-items-center">
                        <img class="img" src="./../../../../assets/images/files/air-purifier/air-purifier_pic7.jpg" alt="">
                    </div>
                </div>
            </div>
            
        </div>  
    </div>

    <div class="nine-stages-details d-flex flex-column">
       
        <div class="nine-stages-details-body">
            <h1 class="nine-stages-details-head" translate>
                PROCESSDETAILS
            </h1>
            <div class="more-body">
                <div class="row d-flex justify-content-around" style="margin: 0;">
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES1</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/1.png" alt="">
                                    <p class="m-4" translate>NINESTAGES1DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES2</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/2.png" alt="">
                                    <p class="m-4" translate>NINESTAGES2DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES3</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/3.png" alt="">
                                    <p class="m-4" translate>NINESTAGES3DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES4</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/4.png" alt="">
                                    <p class="m-4" translate>NINESTAGES4DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES5</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/5.png" alt="">
                                    <p class="m-4" translate>NINESTAGES5DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES6</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/6.png" alt="">
                                    <p class="m-4" translate>NINESTAGES6DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES7</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/7.png" alt="">
                                    <p class="m-4" translate>NINESTAGES7DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES8</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/8.png" alt="">
                                    <p class="m-4" translate>NINESTAGES8DESC</p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="card m-2">
                            <div class="card-body">
                               
                                <h5 class="card-title m-1" translate>NINESTAGES9</h5>
                                <div class="d-flex justify-content-center">
                                    <img class="card-image mb-2" src="./../../../../assets/images/files/air-purifier/9.png" alt="">
                                    <p class="m-4" translate>NINESTAGES9DESC</p>
                                </div>
                               
                            </div>
                        </div>
                </div>
            </div>
            
        </div>  
    </div>

    <div class="not-convinced d-flex flex-column">
       
        <div class="not-convinced-body mt-5">
            <h1 class="not-convinced-head" translate>
                NOTCONVINCED
            </h1>
            <div class="d-flex justify-content-center">
                <div class="separator mb-5">
        
                </div>
            </div>
            <div class="more-body mb-5 text-center">
                <iframe src="https://player.vimeo.com/video/641755751?h=7056c3c3fa" style="width: 100% !important;height: 300px;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            
        </div>  
    </div>
    
</div>

