<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        
        <div class="d-flex justify-content-center flex-wrap mt-7">
        </div>
        <div class="banner-detail1">{{ 'PETC60NAME' | translate }}</div>
        <div class="separator mt-1 mb-3">
        </div>

        <div class="banner-detail2 mb-3"><h1>{{ 'PETC60TIT' | translate }}</h1></div>
        <div class="banner-detail3 mb-3">{{ 'PETC60DETAIL' | translate }}</div>

    
    </div>
    <div class="row what-c60">
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-text mb-1" translate>PETC60WHAT</p>
                <div class="separator mt-1 mb-3">
                </div>
                <h2 class="card-title" translate>PETC60TIT1</h2>
                <p class="card-text mb-1" translate>PETC60DETAIL11</p><br>
                <p class="card-text mb-1" translate>PETC60DETAIL12</p><br>
                <p class="card-text mb-1" translate>PETC60DETAIL13</p><br>
                <p class="card-text mb-1" translate>PETC60DETAIL14</p><br>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 c60-img">
            <div class="overlay">
            </div>
        </div>
    </div>

    <div class="row what-c60-benefits">
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-text mb-1" translate>PETC60ADVAN</p>
                <div class="separator mt-1 mb-3">
                </div>

                <h2 class="card-title" translate>PETC60TIT2</h2>
                <p class="card-text" translate>PETC60TITSUB21</p>
                <p class="card-text" translate>PETC60TITSUB22</p>
                <p class="card-text" translate>PETC60TITSUB23</p>
                <p class="card-text" translate>PETC60TITSUB24</p><br>

                <h2 class="card-title" translate>PETC60TIT3</h2>
                <p class="card-text" translate>PETC60TITSUB31</p>
                <p class="card-text" translate>PETC60TITSUB32</p>
                <p class="card-text" translate>PETC60TITSUB33</p><br>
                <p class="card-text-2 mb-1" translate>PETC60TIT4</p>
                <p class="card-text-2 mb-1" translate>PETC60TIT4D41</p><br>
                <p class="card-text-2 mb-1" translate>PETC60TIT5</p><br>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 c60-img">
            <div class="overlay">
            </div>
        </div>
    </div>
</div>
