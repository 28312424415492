<div class="complete-info-container">

    <div class="row p-5">
        <div class="col-lg-12">
            <div class="row">
                <h2 class="text-center">{{ "PLEASECOMPLETE" | translate }}</h2>
                <div class="m-3">
                    <p *ngFor="let field of forCompletion; index as i;">{{ field }}</p>
                </div>
            </div>
            <div class="text-center">
                <button mat-stroked-button color="warning" class="btn submit-button mr-3" (click)="onDismiss()" >{{ "DISMISS" | translate}}</button>
                <button mat-stroked-button color="primary" class="btn submit-button" (click)="onUpdateAccount()" >{{ "UPDATEACCNT" | translate}}</button>
            </div>
        </div>
    </div>

</div>