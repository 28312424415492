<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close('Close click')" alt="close"></span>
        </div>
    </div>
    <div class="row m-5">
        <form  [formGroup]="productForm" (keydown.enter)="$event.preventDefault()"  style="width: 100%;" autocomplete="p-form">
            <div class="tatlestyle text-center" ngbAutofocus *ngIf="!product.pId">{{ "ADDNEWPRODUCT" | translate}}</div>
            <div class="tatlestyle text-center" ngbAutofocus *ngIf="product.pId">{{ "MODIFYPRODUCT" | translate}}</div>
            <div class="product-image text-center" *ngIf="product">
                <label class="image-upload-container" *ngIf="product.pId">
                    <div *ngIf="!selectedFile">
                        <img *ngIf="!product.pPic" class="pic-1" src="./../../../../assets/images/logo/no-image.jpg">
                        <img *ngIf="product.pPic" class="pic-1" [src]="product.pPic">
                    </div>
                    <div *ngIf="selectedFile">
                        <img class="pic-1" [src]="selectedFile.src">
                    </div>
                    <input #imageInput
                            type="file"
                            accept="image/*"
                            (change)="processFile(imageInput)">
                </label>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field  style="width: 100%;">
                    <input matInput formControlName="pTitle"  type="text" class="form-control text-box" id="pTitle"
                    name="pTitle"  placeholder="{{ 'TITLE' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('pTitle').hasError('required')">{{'TITLEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field  style="width: 100%;">
                    <input matInput formControlName="item_no"  type="text" class="form-control text-box" id="item_no"
                    name="item_no"  placeholder="{{ 'ITEMNO' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('item_no').hasError('required')">{{'ITEMNO' | translate}}&nbsp;{{ REQUIRED | translate}} !</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field  style="width: 100%;">
                    <input matInput formControlName="SKU"  type="text" class="form-control text-box" id="SKU"
                    name="SKU"  placeholder="{{ 'SKUNUMBER' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('SKU').hasError('required')">{{'SKUNUMBER' | translate}}&nbsp;{{ REQUIRED | translate}} !</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="pContent"  type="text" class="form-control text-box" id="pContent"
                    name="pContent"  placeholder="{{ 'CONTENT' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('pContent').hasError('required')">{{'CONTENTREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="pPrice"  type="number" class="form-control text-box" id="pPrice"
                    name="pPrice"  placeholder="{{ 'PRICE' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('pPrice').hasError('required')">{{'PRICEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'LUCKRANK' | translate}}</mat-label>
                    <mat-select formControlName="luck_rank" class="form-control" id="luck_rank" aria-describedby="luck_rank"
                    autocomplete="off">
                        <mat-option  [value]="0">{{ "DEALER" | translate }}</mat-option>
                        <mat-option  [value]="1">{{ "CONSULTANT" | translate }}</mat-option>
                        <mat-option  [value]="2">{{ "DIRECTOR" | translate }}</mat-option>
                        <mat-option  [value]="3">{{ "EXECUTIVE" | translate }}</mat-option>
                        <mat-option  [value]="4">{{ "SAPPHIREDISTRIB" | translate }}</mat-option>
                        <mat-option  [value]="5">{{ "DIAMONDDISTRIB" | translate }}</mat-option>
                        <mat-option  [value]="6">{{ "CROWNDISTRIB" | translate }}</mat-option>
                    </mat-select>
                    <mat-error class="error-message" *ngIf="productForm.get('luck_rank').hasError('required')">{{'LUCKRANKREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <!-- <mat-form-field style="width: 100%;">
                    <input matInput formControlName="pType"  type="number" class="form-control text-box" id="pType"
                    name="pType"  placeholder="{{ 'LUCKPRODUCT' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('pType').hasError('required')">{{'LUCKPRODUCTREQ' | translate}}</mat-error>
                </mat-form-field> -->
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'LUCKPRODUCT' | translate}}</mat-label>
                    <mat-select formControlName="pType" class="form-control" id="pType" aria-describedby="pType"
                    autocomplete="off">
                        <mat-option  [value]="1">{{ "YES" | translate }}</mat-option>
                        <mat-option  [value]="0">{{ "NO" | translate }}</mat-option>
                    </mat-select>
                    <mat-error class="error-message" *ngIf="productForm.get('pType').hasError('required')">{{'LUCKPRODUCTREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="luck_days"  type="number" class="form-control text-box" id="luck_days"
                    name="luck_days"  placeholder="{{ 'LUCKDAYS' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('luck_days').hasError('required')">{{'LUCKDAYSREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="installFree"  type="number" class="form-control text-box" id="installFree"
                    name="installFree"  placeholder="{{ 'INSTALLFEE' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('installFree').hasError('required')">{{'INSTALLFEEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="referral_fee"  type="number" class="form-control text-box" id="referral_fee"
                    name="referral_fee"  placeholder="{{ 'REFERRALFEE' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('referral_fee').hasError('required')">{{'INSTALLFEEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="invalid"  type="number" class="form-control text-box" id="invalid"
                    name="invalid"  placeholder="{{ 'INVALID' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('invalid').hasError('required')">{{'INVALIDREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="reduceRate"  type="number" class="form-control text-box" id="reduceRate"
                    name="reduceRate"  placeholder="{{ 'REDUCERATE' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('reduceRate').hasError('required')">{{'REDUCERATEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'TAXTYPE' | translate}}</mat-label>
                        <mat-select formControlName="tax_type" class="form-control" id="tax_type" aria-describedby="tax_type"
                        autocomplete="off">
                            <mat-option  [value]="1">{{ "CHARGEFIRSTTAX" | translate }}</mat-option>
                            <mat-option  [value]="2">{{ "CHARGESECONDTAX" | translate }}</mat-option>
                            <mat-option  [value]="3">{{ "CHARGEBOTHTAX" | translate }}</mat-option>
                            <mat-option  [value]="4">{{ "SHAREAMTTAXES" | translate }}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="productForm.get('sales_desc_style').hasError('required')">{{'TAXTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="sales_name"  type="text" class="form-control text-box" id="sales_name"
                    name="sales_name"  placeholder="{{ 'SALESNAME' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('sales_name').hasError('required')">{{'SALESNAMEREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="sales_desc"  type="text" class="form-control text-box" id="sales_desc"
                    name="sales_desc"  placeholder="{{ 'SALESDESC' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('sales_desc').hasError('required')">{{'SALESDESCREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <mat-form-field style="width: 100%;">
                            <mat-label>{{'SALESDESCSTYLE' | translate}}</mat-label>
                            <mat-select formControlName="sales_desc_style"  type="text" class="form-control" id="sales_desc_style" aria-describedby="sales_desc_style"
                            autocomplete="off">
                                <mat-option  [value]="0">{{ "REDBACKGROUND" | translate }}</mat-option>
                                <mat-option  [value]="1">{{ "CROSSEDOUTPRICE" | translate }}</mat-option>
                                <mat-option  [value]="2">{{ "REDGROUNDANDCROSSED" | translate }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="productForm.get('sales_desc_style').hasError('required')">{{'SALESDESCSTYLEREQ' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="gst_share_amt"  type="number" class="form-control text-box" id="gst_share_amt"
                    name="gst_share_amt"  placeholder="{{ 'GSTSHAREAMT' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('gst_share_amt').hasError('required')">{{'GSTSHAREAMTREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="pst_share_amt"  type="number" class="form-control text-box" id="pst_share_amt"
                    name="pst_share_amt"  placeholder="{{ 'PSTSHAREAMT' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('pst_share_amt').hasError('required')">{{'PSTSHAREAMTREQ' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="page_name"  type="text" class="form-control text-box" id="page_name"
                    name="page_name"  placeholder="{{ 'PAGENAME' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('page_name').hasError('required')">{{'PAGENAME' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-12">
                    <div style="width: 100%;">
                        <mat-checkbox
                            class="checbox-margin"
                            [(ngModel)]="isInactived"
                            labelPosition="after"
                            (change)="autoship_click($event)"
                            [ngModelOptions]="{standalone: true}"
                            >
                            {{ 'AUTOSHIPTITLE2' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isInactived">
                <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="autoship_discount"  type="number" class="form-control text-box" id="autoship_discount"
                    name="autoship_discount"  placeholder="{{ 'AUTOSHIPDIS' | translate }}"
                    autocomplete="off"
                    >
                    <mat-error class="error-message" *ngIf="productForm.get('autoship_discount').value>=1 || productForm.get('autoship_discount').value <0 ">{{'INCORRECTPARAMS' | translate}}</mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'LIMITEDFLAG' | translate}}</mat-label>
                        <mat-select formControlName="limited_flag"  type="text" class="form-control" 
                        id="limited_flag" 
                        aria-describedby="limited_flag"
                        autocomplete="off">
                            <mat-option  [value]="0">{{ "NONELIMITED" | translate }}</mat-option>
                            <mat-option  [value]="1">{{ "FORQUALIFIEDDIS" | translate }}</mat-option>
                            <mat-option  [value]="2">{{ "FORNEWUSER" | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'SHOWONTITL' | translate}}</mat-label>
                        <mat-select formControlName="show_category"  type="text" class="form-control" 
                        id="show_category" 
                        aria-describedby="show_category"
                        autocomplete="off">
                            <mat-option  [value]="0">{{ "SHOWON0" | translate }}</mat-option>
                            <mat-option  [value]="1">{{ "SHOWON1" | translate }}</mat-option>
                            <mat-option  [value]="2">{{ "SHOWON2" | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12">
                    <div style="width: 100%;">
                        <mat-checkbox
                            class="checbox-margin"
                            [(ngModel)]="hasBenefit"
                            labelPosition="after"
                            (change)="hasBenefit_click($event)"
                            [ngModelOptions]="{standalone: true}"
                            >
                            {{ 'HASBENEFITS' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="hasBenefit">
                    <mat-form-field style="width: 100%;">
                        <mat-label>{{'BENEFITNAME' | translate}}</mat-label>
                        <mat-select formControlName="benefit_id"  type="text" class="form-control" id="benefit_id" 
                            aria-describedby="benefit_id"
                            (selectionChange)="onSelecBenefitEvent($event)"
                            autocomplete="off">
                            <mat-option  [value]="null" disabled>{{ "SELECTONEOF" | translate }}</mat-option>
                            <mat-option  [value]="1">{{ "BENEFITSDETAIL1" | translate }}</mat-option>
                            <mat-option  [value]="2">{{ "BENEFITSDETAIL2" | translate }}</mat-option>
                            <mat-option  [value]="3">{{ "BENEFITSDETAIL3" | translate }}</mat-option>
                            <mat-option  [value]="4">{{ "BENEFITSDETAIL4" | translate }}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="productForm.get('benefit_id').hasError('required')">{{'BENEFITNAME' | translate}}&nbsp;{{'REQUIRED' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12" *ngIf="hasBenefit">
                    <mat-form-field style="width: 100%;">
                        <input matInput formControlName="benefit_value"  type="member" class="form-control text-box" id="benefit_value"
                        name="benefit_value"  placeholder="{{ 'BENEFITVALUE' | translate }}"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="productForm.get('benefit_value').hasError('required')">{{'BENEFITVALUE' | translate}}&nbsp;{{'REQUIRED' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="sort_no"  type="member" class="form-control text-box" id="sort_no"
                            name="sort_no"  placeholder="{{ 'SORTNUMBER' | translate }}"
                            autocomplete="off"
                            >
                            <mat-error class="error-message" *ngIf="productForm.get('sort_no').hasError('required')">{{'SORTNUMBER' | translate}}&nbsp;{{'REQUIRED' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <div class="col-sm-12 detail-content">
                        <h2>{{'PRODUCTDESC' | translate }}</h2>
                        <quill-editor [placeholder]="'COMPOSEMESSAGEDESC' | translate" formControlName="description">
                        </quill-editor>
                     </div>
                </div>
            </div>


            <div class="col-sm-12 col-xs-12 form-popup-form">
                <div style="text-align: center; margin: auto;">
                    <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!productForm.valid " *ngIf="product.pId"(click)="modifyProduct()" >{{ "UPDATEBTN" | translate}}</button>
                    <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!productForm.valid " *ngIf="!product.pId" (click)="addNewProduct()" >{{ "ADDBTN" | translate}}</button>
                </div>
            </div> 
        </form>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>
