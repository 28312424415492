import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReplyMessage } from 'src/app/modules/common/CustomerSupport';
import { Message } from 'src/app/modules/common/Message';
import { User } from 'src/app/modules/common/User';
import { ReceiveCoinsComponent } from 'src/app/modules/receive-coins/receive-coins.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss']
})
export class MyRequestsComponent implements OnInit {

  public user: User;
  public messages : Message[] = [];
  public selectedMessageDetail : ReplyMessage;
  public selectedMessage : Message = null;
  public topicBox : string = null;
  public contentBox : string = null;
  public messageId : string = null;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {

    this.getUser();
    this.getMessages();

    
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMessages(){
    this.evgService.getReplayMessages({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              if(successResponse.success){
                this.messages = successResponse.data[0].results;
                this.messages.sort()

                this.messages = this.messages.slice().sort((a, b) => {
                  return this.sharedUtilService.compare(a.AddTime, b.AddTime, false);
                });

                //console.log(this.messages);
              }
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMessageDetail(id, type){
    this.evgService.getMessageDetail({id : id, msg_type : type}).pipe( takeUntil(this._unsubscribe$) )
      .subscribe(
        (successResponse: any) => {
            if(successResponse.success){
              this.selectedMessageDetail = successResponse.data[0].results;
            }
        },
        (errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }
  );
  }

  replyMessage(){
    let data : any = {};
    data.topic = this.topicBox;
    data.content = this.contentBox;
    data.sender = this.selectedMessageDetail.sender;
    data.receiver = this.user.id;;
    data.ticketNo = this.selectedMessageDetail.ticketNo;
    data.aType = this.selectedMessageDetail.aType;
    data.rootId = this.selectedMessageDetail.rootId;

    this.evgService.replayAskquestion(data).pipe( takeUntil(this._unsubscribe$) )
      .subscribe(
        (successResponse: any) => {
            if(successResponse.success){
                this.topicBox = null;
                this.contentBox = null;
                this.getMessages();
            }
        },
        (errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }
  );
  }

  selectMessage(index){
    if(index >= 0){
      // this.router.navigate(["backmain/my-requests/" + this.messages[index].id]);
      if(this.selectedMessage){
        if(this.messages[index].id == this.selectedMessage.id){
          this.selectedMessage = null;
          this.selectedMessageDetail = null;
        }else{
          this.selectedMessage = this.messages[index];
        }
      }else{
        this.selectedMessage = this.messages[index];
        
      }
      this.topicBox = null;
        this.contentBox = null;
      // this.viewMessage(index);
    }
  }

  viewMessage(index){
  
    this.getMessageDetail(this.messages[index].id,this.messages[index].msg_type);

    this.evgService.clearReadFlag({id : this.messages[index].id, msg_type : this.messages[index].msg_type}).pipe( takeUntil(this._unsubscribe$) )
      .subscribe(
        (successResponse: any) => {
            if(successResponse.success){
              this.selectMessage(index);
              this.shareinfoService.reloadNewMessages();
            }
        },
        (errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }
    );
  }

  
  

}
