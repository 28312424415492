import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Product } from '../common/Products';

@Component({
  selector: 'app-add-product-review',
  templateUrl: './add-product-review.component.html',
  styleUrls: ['./add-product-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProductReviewComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() product : Product;
  

  public addProductReviewForm : FormGroup;
  public rating = 0;
  constructor(
    public activeModal : NgbActiveModal, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.addProductReviewForm = this.formBuilder.group({
      review_content : ['', [Validators.required]]
    });
  }

  submit(){
    let data = this.addProductReviewForm.value;

    data.pId = this.product.pId;
    data.stars = this.rating;

    this.evgService.addProductReview(data).subscribe( resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.activeModal.close('close');
        //console.log(resp);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  updateRating(rating)
  {
    this.rating = rating;
  }

}
