
<div class="promotions-container">
    <div class="row text-right">
      <button *ngIf="!promotion && !isCreating" class="ml-auto mb-3 mr-3" mat-stroked-button color="primary" (click)="onCreate()">{{ 'CREATEBTN' | translate }}</button>
    </div>
    <mat-accordion *ngIf="!promotion && !isCreating">
      <h2>{{ 'PROMOTIONS' | translate }}</h2>
      <mat-expansion-panel hideToggle *ngFor="let promotion of promotions; index as i;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ promotion.title }}
          </mat-panel-title>
          <mat-panel-description>
            <!-- <quill-view [content]="news.content" format="text" theme="snow"></quill-view> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="col-sm-12  promotion-preview-container">
          <quill-view [content]="promotion.content" format="html" theme="snow"></quill-view>
        </div>
        <mat-action-row>
          <!-- <button mat-stroked-button color="error">{{ 'DELETEBTN' | translate }}</button> -->
          <button mat-stroked-button color="primary" (click)="onEdit(i)">{{ 'EDITBTN' | translate }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="promotion || isCreating" class="row text-right">
      <button class="ml-auto mr-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
    </div>

    <form #promotionDirective="ngForm" *ngIf="promotion || isCreating"  [formGroup]="promotionsForm" (keydown.enter)="$promotion.prpromotionDefault()" autocomplete="e-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="title"  type="text" class="form-control text-box" id="title" aria-describedby="title"
                  placeholder="{{ 'TITLELABEL' | translate }}" name="title" maxlength="60"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="promotionsForm.get('title').hasError('required')">{{ 'TITLEISREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="startDate"  type="date" class="form-control text-box" id="startDate" aria-describedby="startDate"
                  placeholder="{{ 'STARTDATE' | translate }}" name="startDate"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="promotionsForm.get('startDate').hasError('required')">{{ 'STARTDATEREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="endDate"  type="date" class="form-control text-box" id="endDate" aria-describedby="endDate"
                  placeholder="{{ 'ENDDATE' | translate }}" name="endDate"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="promotionsForm.get('endDate').hasError('required')">{{ 'ENDDATEREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="priority"  type="number" class="form-control text-box" id="priority" aria-describedby="priority"
                  placeholder="{{ 'PRIORITY' | translate }}" name="priority"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="promotionsForm.get('priority').hasError('required')">{{ 'PRIORITYREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="catType"  type="number" class="form-control text-box" id="catType" aria-describedby="catType"
                  placeholder="{{ 'CATTYPE' | translate }}" name="catType"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="promotionsForm.get('catType').hasError('required')">{{ 'CATTYPEREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-sm-12">
                <h4 class="mb-0">{{ 'CONTENTLABEL' | translate }}</h4>
              </div>
              
              <div class="col-sm-12">
                <quill-editor [placeholder]="'COMPOSEMESSAGEDESC' |translate" formControlName="content">
                </quill-editor>
              </div>
            </div>
            <br>
            <hr>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="isProcessing1"   *ngIf="!promotion" (click)="postPromotion(promotionDirective)" class="align-right mr-2">{{ 'CREATEBTN' | translate }}</button>
            <button mat-stroked-button color="primary" [disabled]="isProcessing2"  *ngIf="promotion" (click)="modifyPromotion(promotionDirective)" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>