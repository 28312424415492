import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Event } from 'src/app/modules/common/CustomerSupport';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-post-edit-conventions-and-events',
  templateUrl: './post-edit-conventions-and-events.component.html',
  styleUrls: ['./post-edit-conventions-and-events.component.scss']
})
export class PostEditConventionsAndEventsComponent implements OnInit {

  public user: User;
  public events : Event[] = [];
  public event : Event;
  private _unsubscribe$ = new Subject<any>();
  public isCreating : boolean = false;
  public eventsForm : FormGroup;
  public toUserId : number;
  panelOpenState = false;
  public isProcessing1 = false;
  public isProcessing2 = false;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private router : Router,
    private route: ActivatedRoute,
    private sharedUtilService : SharedUtilService
  ) { }

  onCreate(){
    this.isCreating = true;
  }

  onBack(){
    this.event = null;
    this.isCreating = false;
    this.getEvents();
    this.openPage('');
  }

  onEdit(index){
    this.event = this.events[index];
    this.isCreating = false;
    this.openPage(this.event.id);
  }

  openPage(pId) {
    this.router.navigate(['backmain/admin/post-edit-conventions-and-events/' + pId]);
  }

  ngOnInit(): void {
    this.eventsForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
      startDate : ['', [Validators.required]],
      endDate : ['', [Validators.required]],
      priority : ['', [Validators.required]],
      catType : ['', [Validators.required]],
    });

    
    this.getUser();
    this.getEvents();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }


  getEvents()
  {
    this.evgService.getEvents({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.events = resp.data[0].results;
          this.route.params.subscribe((params) => {
            
            if(params.id) {
              var event = this.events.filter(event => {
                return event.id == params.id
              })

              if(event){
                this.event = event[0];
                this.eventsForm.patchValue({
                  title : this.event.title,
                  content : this.event.content,
                  startDate : this.event.start_dt ? this.event.start_dt.substring(0,10) : this.event.start_dt,
                  endDate : this.event.end_dt ?  this.event.end_dt.substring(0,10) : this.event.end_dt,
                  priority : this.event.priority,
                  catType : this.event.catType
               });
              };
            }
          });
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postEvent(eventsDirective)
  {
    if(this.eventsForm.status === "VALID"){
      let data = this.eventsForm.value;
      this.isProcessing1 = true;
      this.evgService.postEvent(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("postEvent",resp.data[0]);
            this.sharedUtilService.resetForm(this.eventsForm,eventsDirective);
            this.onBack();
          }
        }
        this.isProcessing1 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing1 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }

   
  }

  modifyEvent(eventsDirective)
  {
    if(this.eventsForm.status === "VALID"){
      let data = this.eventsForm.value;
      data.id = this.event.id;
      this.isProcessing2 = true;
      this.evgService.modifyEvent(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          if(resp.data[0]){
            //console.log("modifyEvent",resp.data[0]);
            this.sharedUtilService.resetForm(this.eventsForm,eventsDirective);
            this.onBack();
          }
        }
        this.isProcessing2 = false;
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.isProcessing2 = false;
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }); 
    }
    
  }

}
