<div class="add-product-review-container">


    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-10">
                    <span></span>
                </div>
                <div class="col-2">
                    <span></span>
                    <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                        (click)="activeModal.close('close')" alt="close"></span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="product-content text-center">
                <a class="title" title="{{ product.pContent }}">{{ product.pTitle }}</a>

                <div class="price">{{ product.pPrice | currency:''}}&nbsp;{{ 'CURRENCYUNIT' | translate }}</div>
                
                <mat-star-rating [rating]="rating" (ratingUpdated)="updateRating($event)"></mat-star-rating>
            </div>
            <form [formGroup]="addProductReviewForm" autocomplete="add-review-form">
                <textarea formControlName="review_content" class="content"></textarea>


                <div class="d-flex justify-content-center">
                    <button mat-raised-button color="primary" class="mr-2 orange-button" (click)="submit()">{{ "SUBMIT" | translate }}</button>
                </div>
                
            </form>
        </div>
    </div>
</div>