import { PercentPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute} from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AlertDialogComponent } from 'src/app/modules/alert-dialog/alert-dialog.component';
import { CartItem } from 'src/app/modules/common/CartItem';
import { User } from 'src/app/modules/common/User';
import { Cart, Product } from 'src/app/modules/common/Cart';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { DistributorTermsComponent } from 'src/app/modules/distributor-terms/distributor-terms.component';
import { VerifyDialogComponent } from 'src/app/modules/verify-dialog/verify-dialog.component';
import { SelectProductsComponent } from 'src/app/components/pages/byte-cart/select-products/select-products.component';
import { Products,ProductInfo } from 'src/app/modules/common/Products';
import { element } from 'protractor';
import { ViewProductsComponent } from 'src/app/modules/views/view-products/view-products.component';
import { ViewAirPuifierComponent } from 'src/app/modules/views/view-air-puifier/view-air-puifier.component';
import { ViewWasherComponent } from 'src/app/modules/views/view-washer/view-washer.component';
import { ViewFulvicAcidComponent } from 'src/app/modules/views/view-fulvic-acid/view-fulvic-acid.component';
import { ViewHydrogenEnergyComponent } from 'src/app/modules/views/view-hydrogen-energy/view-hydrogen-energy.component';
import { ViewPetC60Component } from 'src/app/modules/views/view-pet-c60/view-pet-c60.component';
import { ViewCarbon60Component } from 'src/app/modules/views/view-carbon60/view-carbon60.component';
import { config } from 'process';
import { environment } from 'src/environments/environment';
import { ViewPetTraceComponent } from 'src/app/modules/views/view-pet-trace/view-pet-trace.component';


@Component({
  selector: 'app-byte-cart',
  templateUrl: './byte-cart.component.html',
  styleUrls: ['./byte-cart.component.scss']
})
export class ByteCartComponent implements OnInit {
  public selectedProducts : Array<any>=[];
  public recommanded:any=null;
  public offered_pId:any=null;
  public user : User = new User();
  public resetLinkSent : boolean = false;
  public checkoutForm : FormGroup;
  public distrib_form : FormGroup;
  public step : number = 0;
  public countries : Array<any>=[];
  public provinces : Array<any>=[];
  public contact_countries : Array<any>=[];
  public contact_provinces : Array<any>=[];
  public cart_items : CartItem[] = [];
  public cart_total : number = 0;
  public cart_tax : number = 0;
  public cart_tax_1 : number = 0;
  public cart_tax_2 : number = 0;
  public cart_tax_rate : any = null;
  public cart_subtotal : number = 0;
  public cart_discounts : number = 0;
  public cart_item_selected : number = 0;
  public cart_discount_rate : number =  0;
  public tax_rates : any = [];
  public tax_rate : any = [];
  private _unsubscribe$ = new Subject<any>();
  public selectedCountry : {
    name : '',
    code : ''
  };
  public isMobileMenuOpen:boolean  = false;
  public preferredShipping : number = 2;//new address
  public shippingAddresses : any[] = [];
  public selectedAddress : number = -1;
  public coupon = '';
  public appliedCoupon : any;
  public verified = false;
  public myStoreInfo:any={};
  public storeAD_size=0;
  public background_color : any = "#FFFFFF";
  public setHeaderImg =null;
  public show_header_type =2;
  public slideIndex =0;
  public enteredEmail=0;
  public expiryDateValue : string='';
  public sameasbillingadr : boolean=true;
  public shippingInfo: any=null;
  /*
  public picFiles=['/assets/public/cart_waterfilter_image.jpg',
                  '/assets/public/cart_airfilter_image.jpg',
                  '/assets/public/cart_washer_image.jpg',
                  '/assets/public/cart_waterfilter_image.jpg',
                ];
                */
  public picFiles=[ '/assets/public/byte_cart_s1.png',
                    '/assets/public/byte_cart_s2.png',
                    '/assets/public/byte_cart_s3.png',
                  ];

  public slidesCount = this.picFiles.length;
  public slide_picture =null;
  public slideShow:Array<boolean>=[true,false,false,true];
  public slide_interval_time:number=4000;
  public pre_ofer_products:number=0;
  public select_product_title = 'SELECTPRODCT';
  public creditCardNumber : string='';
  public shipping_fee:number=0.0;
  public payby_currency:"USD";
  public pay_type:number=null; // 0: credit card, 1, Tokens, 2: paypal
  public payPal_amount=0;
  validRegexForEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  free_shipping_limit: number=500;
  public selectedShippingOption:any =null;
  public shippingOptions : any = [];


  constructor(
    public translate : TranslateConfigService,
    //public activeModal : NgbActiveModal, 
    private router: Router,
    public route: ActivatedRoute,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private sharedUtilService : SharedUtilService,
    private percentPipe : PercentPipe
  ) { 
    this.recommanded = (this.route.snapshot.paramMap.get('recommanded'));
    this.offered_pId = (this.route.snapshot.paramMap.get('pId'));
    this.getTaxRates();
    //console.log('at ByteCartComponent parameters (recommanded): pId',this.recommanded, this.offered_pId);
  }


  ngOnInit(): void {
    let index = this.randomNumber((this.picFiles.length));
    this.setHeaderImg = this.picFiles[index];
    this.slide_picture = this.picFiles[index];
    //console.log('ngOnInit index, setHeaderImg:',index,this.setHeaderImg);
    this.getMySoreInfo();
    this.initializeForms();
    //this.getUser();
    this.getCountryList();
    this.showSlide();
    this.getProducts();
    
    //this.getOferProducts();
    //this.getCart();
    //this.getUserShippingAddresses();
  }

  randomNumber(max) {
    return Math.floor(Math.random() * max);
  }

  switchLang(lang: string) {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = false;
    }
    this.translate.switchLang(lang);
    
  }

  onKeydown(ele) {
    //console.log('onKeydown  ele:  expiryDateValue:',ele,this.expiryDateValue);
    //console.log('onKeydown  ele.key:  expiryDateValue.length:',ele.key,this.expiryDateValue.length);
    if (! ( ele.key>='0' && ele.key<='9' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  modifyInput(ele) {
    //console.log('modifyInput  ele:  expiryDateValue:',ele,this.expiryDateValue);
    //console.log('modifyInput  ele.key:  expiryDateValue.length:',ele.key,this.expiryDateValue.length);
    if (this.expiryDateValue.length==2) {
      if (! (ele.key =='Backspace' || ele.key =='Delete')) {
        this.expiryDateValue += '/';
      }
    }
  }

  onKeydownForTel(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key<='-' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  cc_format(ele) {
    //console.log('cc_format value', value);
    let value = this.creditCardNumber;
    if (value) {
      const v = value.toString().replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      //console.log('cc_format match: v: matches:', match,v, matches);
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      //console.log('cc_format parts:', parts);
      if (parts.length > 0) {
        this.creditCardNumber = parts.join(' ');
      } else {
        this.creditCardNumber = value;
      }
      //console.log('cc_format creditCardNumber:', this.creditCardNumber);
    }
  }

  preferredPayTypeChanged() {
    if (this.pay_type>=0) {
      this.checkoutForm.patchValue({
        payType: this.pay_type
      });      
    }
    if(this.pay_type == 0){//pay by Elavon crdditcard 
    } else if (this.pay_type == 1) { // pay by tokens

    } else if (this.pay_type == 2) { //pay by palpal

    } else if (this.pay_type == 3) { //pay by Stripe

    }
    //console.log('preferredPayTypeChanged pay_type:',this.pay_type);
  }

  getProducts()
  {
   
    //this.evgService.geMyOferedProductsForByteCart([])
    this.evgService.searchSortProducts({sortType: 0})
    //this.evgService.getProductsForshoppingCart([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log('getProducts getProductsForshoppingCart resp:',resp);
        //console.log('this.offered_pId :',this.offered_pId);
        if (resp) {
          if(resp.data[0]){
            this.selectedProducts = resp.data[0].results;
            if (this.offered_pId && this.selectedProducts) {
              this.selectedProducts.forEach(element=>{
                //console.log(' element.pId : element.pTitle',element.pId, element.pTitle);
                if (element.pId == this.offered_pId) {
                  element.pQty =1;
                }
              })
              this.reloadCart(this.selectedProducts);
            }
            //console.log('getProducts getProductsForshoppingCart this.products:',this.selectedProducts);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      
      
  }

  initializeForms(){
    this.checkoutForm = this.formBuilder.group({
      
      contact_first_name:['', Validators.required],
      contact_last_name:['', Validators.required],
      contact_address : ['', Validators.required],
      contact_city : ['', Validators.required],
      contact_email : ['',[Validators.required, Validators.email]],
      contact_phone : ['', Validators.required],
      contact_province : ['', Validators.required],
      contact_country : ['', Validators.required],
      contact_postCode : ['', Validators.required],
      //email: ['', [Validators.required, Validators.email]],
      Tel : [''],
      Address : [''],
      City : [''],
      Province : [''],
      Country : [''],
      PostCode : [''],
      payType : [0, Validators.required],
      cardNo : ['',Validators.required],
      expireDate : ['',Validators.required],
      cvCode : ['',Validators.required],
      firstName : ['',Validators.required],
      lastName : ['',Validators.required],
      first_name : [''],
      last_name : [''],
      //postCode : ['',Validators.required],
      //address : [''],
      Referrals : [''],
      Recommended : [''],
      
    });

    this.distrib_form = this.formBuilder.group({
      isDistributor : ['']
    });


    this.checkoutForm.get('payType').valueChanges
    .subscribe(value => {
      if(value == 0 ) {
        this.checkoutForm.get('cardNo').setValidators(Validators.required);
        this.checkoutForm.get('expireDate').setValidators(Validators.required);
        this.checkoutForm.get('cvCode').setValidators(Validators.required);
        this.checkoutForm.get('firstName').setValidators(Validators.required);
        this.checkoutForm.get('lastName').setValidators(Validators.required);
        //this.checkoutForm.get('postCode').setValidators(Validators.required);
       // this.checkoutForm.get('address').setValidators(Validators.required);
        // this.checkoutForm.get('firstName').setValue(this.user.first_name);
        // this.checkoutForm.get('lastName').setValue(this.user.last_name);
        // this.checkoutForm.get('postCode').setValue(this.checkoutForm.get('PostCode').value);
        // this.checkoutForm.get('address').setValue(this.checkoutForm.get('Address').value);

        this.checkoutForm.markAsPristine();
      } else {
        this.checkoutForm.get('cardNo').clearValidators();
        this.checkoutForm.get('expireDate').clearValidators();
        this.checkoutForm.get('cvCode').clearValidators();
        this.checkoutForm.get('firstName').clearValidators();
        this.checkoutForm.get('lastName').clearValidators();
        //this.checkoutForm.get('postCode').clearValidators();
        //this.checkoutForm.get('address').clearValidators();
      }
      this.checkoutForm.controls['cardNo'].updateValueAndValidity();
      this.checkoutForm.controls['expireDate'].updateValueAndValidity();
      this.checkoutForm.controls['cvCode'].updateValueAndValidity();
      this.checkoutForm.controls['firstName'].updateValueAndValidity();
      this.checkoutForm.controls['lastName'].updateValueAndValidity();
      //this.checkoutForm.controls['postCode'].updateValueAndValidity();
      //this.checkoutForm.controls['address'].updateValueAndValidity();
    });

    this.checkoutForm.get('Country').valueChanges
    .subscribe(value => {
      if(value){
        this.getProvinceList(value);
        if(this.shippingAddresses.length > 0){
          this.checkoutForm.patchValue({
            Province : ''
          });
        }
      }
    });

    this.checkoutForm.get('Province').valueChanges
    .subscribe(value => {
      if (this.sameasbillingadr==false) {
        if(value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return rate.state_name.toUpperCase() === value.toUpperCase()
          });
          this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee =0;
          this.getCart();
        }
      }
    });


    this.checkoutForm.get('contact_country').valueChanges
    .subscribe(value => {
      if(value){
        this.getProvinceList(value,1);
        if(this.shippingAddresses.length > 0){
          this.checkoutForm.patchValue({
            contact_province : ''
          });
        }
      }
    });

    this.checkoutForm.get('contact_province').valueChanges
    .subscribe(value => {
      if (this.sameasbillingadr) {
        if(value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return rate.state_name.toUpperCase() === value.toUpperCase()
          })
          this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee =0;
          this.getCart();
        }
      }
    });

    this.checkoutForm.get('Referrals').valueChanges
    .subscribe(value => {
        this.verified = false;
      
    });

    this.checkoutForm.get('Recommended').valueChanges
    .subscribe(value => {

      this.verified = false;
    });

    this.checkoutForm.get('contact_email').valueChanges
    .subscribe(value => {
      if (value.match(this.emailReg)){ //this.validRegexForEmail)) {
        this.getUserShippingAddresses(value);
      }
    //this.enteredEmail= value.match(this.validRegexForEmail)? 1 :0;
    });
  }

  showNoticePassword(){
    const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.responseHandlerService.translateMessage("WHATISCVVCD") ;
    modalRef.componentInstance.imagePass ='../../../../assets/images/icons/cvvnumber.png'

  }


  viewProductDetail(sel_pId) {
    let product = this.selectedProducts.find(item=>(item.pId == sel_pId));
    if (product) {
      let page_name = product.page_name;
      let pagesList=[
                    {pagename: "fulvic-acid", pageComponent: ViewFulvicAcidComponent },
                    {pagename: "hydrogen-energy", pageComponent: ViewHydrogenEnergyComponent },
                    {pagename: "products", pageComponent: ViewProductsComponent },
                    {pagename: "washer", pageComponent: ViewWasherComponent },
                    {pagename: "air-purifier", pageComponent: ViewAirPuifierComponent },
                    {pagename: "pet-c60", pageComponent: ViewPetC60Component},
                    {pagename: "carbon60", pageComponent: ViewCarbon60Component},
                    {pagename: 'pet-trace', pageComponent: ViewPetTraceComponent},
                  ];
      let pageNames = page_name ? page_name.split(';') : null;
      //console.log('viewProductDetail pageNames:',pageNames);
      if (pageNames && pageNames.length>0) {
      pageNames.forEach((value, index) =>{
        if (value ) {
          let findPage = pagesList.find(item=> item.pagename==value);
          if (findPage) {
            const modalRef = this.ngDialog.open(findPage.pageComponent, { windowClass: 'cart-modal' ,centered: true, size: 'lg', keyboard: false, backdrop: true });
            modalRef.componentInstance.translate = this.translate;
            modalRef.componentInstance.addCloseBut = true;
          }
        }
      })
      }
    }
   }

  getOferProducts() {
    this.evgService.getOfferedProducts({MemberNo : this.recommanded}).subscribe( resp => {
      if (resp) {
        let oferedProd = resp.data[0].results;
        if ( oferedProd && oferedProd.length()>0) {
          this.pre_ofer_products= oferedProd.length();
          this.getCart();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
    });

  }

  preferredShippingChanged()
  {

    if(this.preferredShipping == 1){
      if(this.shippingAddresses.length > 0){
        this.sameasbillingadr=false;
        this.checkoutForm.patchValue({
          Tel : null,
          Address : null,
          City : null,
          Province : null,
          Country : null,
          PostCode : null,
          first_name : null,
          last_name : null,
        });
      }
      
      this.checkoutForm.get('Tel').setValidators(Validators.required);
      //this.checkoutForm.get('email').setValidators(Validators.required);
      this.checkoutForm.get('Address').setValidators(Validators.required);
      this.checkoutForm.get('City').setValidators(Validators.required);
      this.checkoutForm.get('Province').setValidators(Validators.required);
      this.checkoutForm.get('Country').setValidators(Validators.required);
      this.checkoutForm.get('PostCode').setValidators(Validators.required);
      this.checkoutForm.get('first_name').setValidators(Validators.required);
      this.checkoutForm.get('last_name').setValidators(Validators.required);

  
      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.selectedAddress = -1;
      this.selectedAddressChanged();

      this.cdref.detectChanges();
      
    }else if (this.preferredShipping == 0){
      this.sameasbillingadr=false;
      this.checkoutForm.get('Tel').clearValidators();
      //this.checkoutForm.get('email').clearValidators();
      this.checkoutForm.get('Address').clearValidators();
      this.checkoutForm.get('City').clearValidators();
      this.checkoutForm.get('Province').clearValidators();
      this.checkoutForm.get('Country').clearValidators();
      this.checkoutForm.get('PostCode').clearValidators();
      this.checkoutForm.get('first_name').clearValidators();
      this.checkoutForm.get('last_name').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.cdref.detectChanges();
      this.selectedAddress = -1;
      this.selectedAddressChanged();

    } else {
      this.sameasbillingadr=true;
      this.checkoutForm.get('Tel').clearValidators();
      //this.checkoutForm.get('email').clearValidators();
      this.checkoutForm.get('Address').clearValidators();
      this.checkoutForm.get('City').clearValidators();
      this.checkoutForm.get('Province').clearValidators();
      this.checkoutForm.get('Country').clearValidators();
      this.checkoutForm.get('PostCode').clearValidators();
      this.checkoutForm.get('first_name').clearValidators();
      this.checkoutForm.get('last_name').clearValidators();

      this.checkoutForm.markAsPristine();

      this.checkoutForm.controls['Tel'].updateValueAndValidity();
      //this.checkoutForm.controls['email'].updateValueAndValidity();
      this.checkoutForm.controls['Address'].updateValueAndValidity();
      this.checkoutForm.controls['City'].updateValueAndValidity();
      this.checkoutForm.controls['Province'].updateValueAndValidity();
      this.checkoutForm.controls['Country'].updateValueAndValidity();
      this.checkoutForm.controls['PostCode'].updateValueAndValidity();
      this.checkoutForm.controls['first_name'].updateValueAndValidity();
      this.checkoutForm.controls['last_name'].updateValueAndValidity();

      this.cdref.detectChanges();
      this.selectedAddress = -1;
      this.selectedAddressChanged();

        if(this.checkoutForm.get('contact_country').value && this.checkoutForm.get('contact_province').value){
          this.cart_tax_rate = this.tax_rates.find(rate => {
            return (rate.country.toUpperCase() === (this.checkoutForm.get('contact_country').value).toUpperCase() && rate.state_name.toUpperCase() === (this.checkoutForm.get('contact_province').value).toUpperCase())
          })
          this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
          this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';
          
          this.getCart();
        }else{
          this.cart_tax_rate = null;
          this.shipping_fee =0;
          this.getCart();
        }
    }
  }

  selectedAddressChanged()
  {
    if(this.shippingAddresses[this.selectedAddress]){
      this.cart_tax_rate = this.tax_rates.find(rate => {
        return rate.state_name.toUpperCase() === this.shippingAddresses[this.selectedAddress].Province.toUpperCase() 
              && rate.country.toUpperCase() === this.shippingAddresses[this.selectedAddress].Country.toUpperCase()
      })
      this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
    }else{
      this.cart_tax_rate = null;
      this.shipping_fee = 0;
    }
    this.getCart();
  }


  getTaxRates(){
    this.evgService.getTaxRates({}).subscribe( resp => {
      if (resp) {
        this.tax_rates = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
    });
  }

  applyCoupon(){
    if(this.coupon){
      this.evgService.submitCoupon({coupon : this.coupon,category: 1}).subscribe( resp => {
        if (resp) {
          //console.log('applyCoupon resp:', resp);
          this.responseHandlerService.handleResponse(resp);
          this.appliedCoupon = resp.data[0].results;
          this.getCart();

          this.cdref.detectChanges();
  
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
      });
    }
  }

  showSlide(){
    if (this.picFiles.length >1 ) {
      this.slideIndex++;
      if (this.slideIndex >= this.slidesCount) {
        this.slideIndex = 0;
      }
      /*
      for(let i =0 ; i < this.slidesCount ; i++) {
        this.slideShow[i] = (i===this.slideIndex) ;
      }
      */
      this.slide_picture=this.picFiles[this.slideIndex];

      setTimeout(() => {
        this.showSlide();
      }, 20000); 
    }
  }

  getShowClass(indexId) {
    let setVal = false;
    if ( indexId >= 0 && indexId < this.slideShow.length ) {
      setVal = this.slideShow[indexId];
    }
    let retClass = {
      'show' : setVal,
      'hide' : !setVal,
    }
    return retClass
  }

  getDiscountAmt() {
    if (this.appliedCoupon.category==1 && this.appliedCoupon.detail) {
      let discount=0;
      this.appliedCoupon.detail.forEach((element,i)=>{
        let cartList = this.cart_items.filter(item => item.pId == element.MemberNo && item.selected);
        if (cartList && cartList.length>0) {
          cartList.forEach((e,index)=>{
            if (element.coupon_type==0) {
              discount += e.nums * element.amount;
            } else {
              discount += (e.nums * e.unitPrice) * element.amount/100;
            }
          });
        }
      });
      this.cart_discounts = discount;
      //console.log('getDiscountAmt discount:',discount);
    }

  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.checkoutForm.patchValue({
                Referrals : this.user.Referrals,
                Recommended : this.user.Recommended,
                
                contact_email : this.user.email,
                contact_first_name : this.user.first_name,
                contact_last_name : this.user.last_name,
                contact_postCode : this.user.PostCode,
                contact_country : this.user.Country,
                contact_province : this.user.Province,
                contact_city : this.user.City,
                contact_address : this.user.Address,
                contact_phone :  this.user.Tel,

                firstName : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                country : this.user.Country,
                province : this.user.Province,
                city : this.user.City,
                Address : this.user.Address,
                //email : this.user.email,
              });

              if(!this.user.isDistributor){
                this.checkoutForm.controls['Referrals'].disable();
                this.checkoutForm.controls['Recommended'].disable();
              }else{
                this.checkoutForm.controls['Referrals'].enable();
                this.checkoutForm.controls['Recommended'].enable();

                if(this.user.Referrals){
                  this.checkoutForm.controls['Referrals'].disable();
                }

                if(this.user.Recommended){
                  this.checkoutForm.controls['Recommended'].disable();
                }
                
                if(this.user.Recommended && this.user.Referrals){
                  this.verified = true;
                }
              }

              // if(this.user.pTimes > 0 && (this.user.Referrals != null || this.user.Recommended != null)){
              //   this.step = 1;
              // }

              this.cdref.detectChanges();

          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  openPage(pageId) {
    //this.activeModal.close('Close click');
    this.router.navigate([pageId]);
  }

  distrib_click(){
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.distributor = this.user.first_name + ' ' + this.user.last_name;

    modalRef.result.then((data) => {
    }, (reason) => {
        this.evgService.updateUserDistributorFlag({isDistributor : 1}).subscribe( resp => {
          this.responseHandlerService.handleResponse(resp);
          this.shareinfoService.reloadUser();
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    });
  }

  getMySoreInfo() {
    this.evgService.getMyCartInfo({MemberNo : this.recommanded})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log("getMySoreInfo  resp:", resp);
        if (resp) {
          if(resp.data[0]){
            let myStoreInfo = resp.data[0].results;
            if (! (Object.keys(myStoreInfo).length === 0)) {
              if (myStoreInfo.cart_backgroud && (/^#([0-9A-F]{3}){1,2}$/i.test(myStoreInfo.cart_backgroud))){
                this.background_color = myStoreInfo.cart_backgroud;
              }
              if (myStoreInfo.slide_interval_seconds && myStoreInfo.slide_interval_seconds) {
                if (myStoreInfo.slide_interval_seconds > 0) {
                  this.slide_interval_time = (myStoreInfo.slide_interval_seconds) * 1000;
                }
              }

              if (myStoreInfo.imgfiles && myStoreInfo.imgfiles.length>0) {
                  if (myStoreInfo.imgfiles.length==1) {
                    this.setHeaderImg = '/uPhotos/users/' + this.recommanded +'/' + myStoreInfo.imgfiles[0];
                    myStoreInfo.show_img_type = 1;
                  } else {
                    this.picFiles=[];
                    myStoreInfo.imgfiles.forEach((e,i)=>{
                      this.picFiles.push(('/uPhotos/users/' + this.recommanded +'/' + e));
                    });
                    if (myStoreInfo.show_img_type && myStoreInfo.show_img_type == 1) {
                      let i = this.randomNumber((myStoreInfo.imgfiles.length));
                      this.setHeaderImg = this.picFiles[i];
                    }
                  }
                  //this.showSlide();
              }
              
              this.myStoreInfo = myStoreInfo;
              //this.show_header_type = myStoreInfo.show_img_type ? myStoreInfo.show_img_type : 1;

            }
              
            this.storeAD_size = this.myStoreInfo.cart_adv ? this.myStoreInfo.cart_adv.length : 0;
            //console.log("this.storeAD_size : this.show_header_type:",this.storeAD_size, this.show_header_type);
            //console.log("this.myStoreInfo:",this.myStoreInfo);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
      
  }

  get_data_interval(){
    return this.slide_interval_time;
  }
  
  onSelected(i,event){
    this.cart_items[i].selected = this.cart_items[i].selected == 1 ? 0 : 1;
    this.getCart();
  }
  
  deleteItem(index){
    if (this.cart_items.length>index) {
      // this.cart_items.slice(index,1);
      let cartItems: CartItem[]=[];
      let temp_index=0;
      let del_pId = null;
      this.cart_items.forEach( (element,i) => {
        if (index !== i ) {
            cartItems.push(element);
        } else {
          del_pId = element.pId;
        }
        temp_index++;
      });
      this.cart_items = cartItems;
      let selected=0;
      //console.log('deleteItem index: del_pId:  cartItems :',index,del_pId, cartItems);
      if (del_pId) {
        //this.selectedProducts = this.selectedProducts.filter();
        //this.selectedProducts = this.selectedProducts.filter(item => item.pId !== del_pId);
        this.selectedProducts.forEach((element, i) => {
          if (element.pId == del_pId) {
            element.pQty =0;
          }
          if (element.pQty >0 ) {
            selected++;
          }
        });
        this.select_product_title = (selected > 10 ? 'MOREPRODUCTS' : 'SELECTPRODCT');
      }
      this.getCart();
    }

  }

  addItem(index){
    
    this.cart_items[index].nums++
    this.getCart();

  }

  removeItem(index){
    if(this.cart_items[index].nums >= 2){
      this.cart_items[index].nums--;
      this.getCart();
    }
  }

  reloadCart(selectProds:ProductInfo[]) {
    this.cart_items=[];
    let temp_index=1;
    selectProds.forEach( (element,i) => {
      if (element.pQty>0) {
        this.cart_items.push({
          id: temp_index,
          MemberNo: 8888,
          pId: element.pId,
          pTitle: element.pTitle,
          nums: element.pQty,
          taxAmt: 0,
          unitPrice: element.pPrice,
          amount: (element.pQty * element.pPrice),
          selected: 1,
          installFee: element.installFree,
          tax_type: element.tax_type,
          AddTime: undefined,
          gst_share_amt: element.gst_share_amt,
          pst_share_amt: element.pst_share_amt,
          autoship: element.autoship,
          termFlag: element.termFlag,
          item_no: element.item_no,
          SKU: element.SKU,
          apply_benefit: 0,
          has_benefit: element.has_benefit,
          benefit_name: element.benefit_name,
          benefit_value: element.benefit_value,
          autoship_discount: element.autoship_discount,
          page_name : element.page_name,
        });
        //this.cart_items.push(data);
        temp_index++;
      }
    });
    //console.log('reloadCart this.cart_items :',this.cart_items);
    //console.log('reloadCart selectProds :',selectProds);
    if (temp_index > 1) {
      this.getCart();
    }
    this.select_product_title = (temp_index > 1) ? 'MOREPRODUCTS' : 'SELECTPRODCT';
  }

  clearCart(){
    this.cart_items=[];
    this.selectedProducts.forEach((element, i) => {
        element.pQty =0;
    });
    this.appliedCoupon = null;
    this.cart_discounts = 0;
    this.cart_discount_rate = 0;
    this.step=0;
    this.getCart();
  }

  getCountryList(){
    this.evgService.getCountryList({}).subscribe( resp => {
      if (resp) {
        this.countries = resp.data[0].results;
        this.contact_countries = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getProvinceList(country,for_contact=0)
  {
    this.evgService.getProvinceList({country : country}).subscribe( resp => {
      if (resp) {
        //console.log(resp);
        if (for_contact==0) {
          this.provinces = resp.data[0].results;
        } else {
          this.contact_provinces = resp.data[0].results;

        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserShippingAddresses(inEmail)
  {
    //this.checkoutForm.get('email').value
    this.evgService.getShippingAddressesByEmail({email: inEmail}).pipe( takeUntil(this._unsubscribe$) )
      .subscribe(
        (successResponse: any) => {
            //console.log("getShippingAddressesByEmail",successResponse);
            this.shippingAddresses = successResponse.data[0].results;

            if(this.shippingAddresses.length <= 0){
              //this.preferredShipping = 1;
            }else{
              //this.preferredShipping = 0;
              this.selectedAddress = 0;
            }
            this.enteredEmail=1;
            this.preferredShippingChanged();
        },
        (errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }
    );
  }

  deleteShippingAddress(id)
  {
    this.evgService.deleteShippingAddress8888({id : id}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.shippingAddresses = successResponse.data[0].results;

              if(this.shippingAddresses.length <= 0){
                this.preferredShipping = 1;
              }else{
                this.preferredShipping = 0;
                this.selectedAddress = 0;
              }
              this.preferredShippingChanged();
        },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }
    );
  }

  getCart(){
    this.cart_subtotal = 0;
    this.cart_tax = 0;
    this.cart_tax_1 = 0;
    this.cart_tax_2 = 0;
    this.cart_total = 0;
    this.cart_item_selected = 0;
    this.cart_discount_rate = 0;
    this.cart_items.forEach(cart_item => {
        if(cart_item.selected == 1){
          this.cart_subtotal += cart_item.unitPrice * cart_item.nums;
          this.cart_total += cart_item.amount;
          this.cart_item_selected++;
        }
    });
    
    if(this.appliedCoupon){
      if (this.appliedCoupon.category==0 ){
        if(this.appliedCoupon.coupon_type == '0'){
          this.cart_discounts = this.appliedCoupon.coupon_amt;

          this.cart_discount_rate = this.cart_discounts / this.cart_subtotal;
          
        }else if(this.appliedCoupon.coupon_type == '1'){
          this.cart_discounts = (this.cart_subtotal * (this.appliedCoupon.coupon_amt/100));

          this.cart_discount_rate = this.appliedCoupon.coupon_amt/100;
        }else{
          this.cart_discounts = 0;
          this.cart_discount_rate = 0;
        }
      } else {
        this.getDiscountAmt();
        this.cart_discount_rate = 0;
      }
    }else{
      this.appliedCoupon = null;
      this.cart_discounts = 0;
      this.cart_discount_rate = 0;
    }

    this.cart_items.forEach(cart_item => {
        if(cart_item.selected == 1){
          this.cart_tax += this.calculateProductTax(cart_item);
          this.cart_tax_1 += this.calculateProductTax1(cart_item);
          this.cart_tax_2 += this.calculateProductTax2(cart_item);
          
        }
    });

    // if(this.cart_tax_rate){
    //   this.cart_tax = ( this.cart_subtotal - this.cart_discounts ) * this.cart_tax_rate.tax_rate;
    // }else{
    //   this.cart_tax = 0;
    // }
    this.cart_total = this.cart_subtotal - this.cart_discounts + this.cart_tax + this.shipping_fee;
    this.payPal_amount = Math.round(this.cart_total*100)/100;
    
    
    this.cdref.detectChanges();

  }


  calculateProductTaxold(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return (cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return (cart_item.unitPrice * (this.cart_tax_rate.tax_rate2 ? this.cart_tax_rate.tax_rate2 : this.cart_tax_rate.tax_rate))  * cart_item.nums * (1 - this.cart_discount_rate);
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.unitPrice * this.cart_tax_rate.tax_rate) + (cart_item.unitPrice * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.unitPrice * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_rate2 ? (((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate) + (cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2))  * cart_item.nums * (1 - this.cart_discount_rate)) : ((cart_item.gst_share_amt * this.cart_tax_rate.tax_rate)  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return  (cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }else if(cart_item.tax_type == 4 ){
        return  (cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate) + cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate))
      }
    }
    return 0;
  }

  calculateProductTax1(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 2 ){
        return 0;
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.gst_share_amt * this.cart_tax_rate.tax_rate  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }

  calculateProductTax2(cart_item){
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return 0;
      }else if(cart_item.tax_type == 2 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 3 ){
        return cart_item.unitPrice * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }else if(cart_item.tax_type == 4 ){
        return cart_item.pst_share_amt * this.cart_tax_rate.tax_rate2  * cart_item.nums * (1 - this.cart_discount_rate)
      }
    }
    return 0;
  }

  nextStep(){
    //this.step++;
    //if (this.step==2) {
    if (this.step==1) {
      //this.step=3;
      this.showUp();
      this.shipping_fee = this.cart_tax_rate && this.cart_tax_rate.shipping_fee ? this.cart_tax_rate.shipping_fee : 0;
      this.payby_currency = this.cart_tax_rate && this.cart_tax_rate.currency_code ? this.cart_tax_rate.currency_code : 'USD';

      let validateData = this.collectValidationData();
      if (validateData) {
        this.setShippingInfo();
        if (this.pay_type==null) {
          validateData.payType=2;
        }

        this.evgService.validatePurchaseOnly8888(validateData).subscribe( resp => {
          if (resp && resp.success==true) {
            //this.step=3;
            let countryCode= this.cart_tax_rate && this.cart_tax_rate.country_code? this.cart_tax_rate.country_code : null;
            let postCode= validateData.shippingAddress.PostCode;
      
              
            if(this.preferredShipping ==0 && this.selectedAddress>=0 && this.selectedAddress < this.shippingAddresses.length){//[this.selectedAddress]){
                postCode = this.shippingAddresses[this.selectedAddress].PostCode;
            }
            let minPid = validateData.minPid;
            console.log('nextStep CountryCode : postCode: minPid:',countryCode,postCode,minPid);
      
            this.shippingOptions=null;
            this.evgService.getShippingFeeByCountryCode({PostCode : postCode,CountryCode : countryCode, parcelChar: (minPid<= 7? 1:0)})
            .subscribe( respp => {
              if (respp && respp.success==true) {
                console.log('nextStep getShippingFeeByCountryCode : respp: results:',respp,respp.data[0].results);
                if (this.cart_subtotal>= this.free_shipping_limit ){
                  this.shipping_fee=0;
                } else {
                  this.shippingOptions = respp.data[0].results;
                  this.selectedShippingOption= respp.data[0].defaultIndex;
                  this.shipping_fee=respp.data[0].lowestFee;
                }
                this.step=3;
                this.getCart();
              } else {
                const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                  modalRef.componentInstance.translate = this.translate;
                  modalRef.componentInstance.message = this.translate.translate.instant('VERIFYZIPCODE');
                  modalRef.result.then((result) => {
                    if(result){
                      this.shippingOptions=null;
                    }
                  });
              }
            },(errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured,Please verify your ZIP/POST Code! ", "error");
            });
          } else {
            this.step=1;
            this.responseHandlerService.handleResponse(resp);
          }
          //console.log('nextStep validatePurchaseOnly resp: step: ',resp, this.step);
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
        alert ('Cannot collect correct your information!');
        //this.step--;        
      }
    }
  }

  showUp() {
    const element = document.querySelector('#myStoreID');
    element.scrollIntoView();
  }

  skipStep(){
    // this.checkoutForm.patchValue({
    //   Referrals : '',
    //   Recommended : '',
    // });
    this.step++;
    if (this.step==2) {
      this.step=3;
    }
      this.showUp();
  }

  prevStep(){
    this.step--;
    if (this.step<1) {
      this.step=0;
    }
    if (this.step==2) {
      this.step=1;
    }
    this.showUp();
    //this.show_contact_info_isNG();
  }


  collectValidationData(forcePass=0) {
    let hasErrors=0;
    let errors=[];
    let return_data:any=null;
    let hasError:boolean = false;
    let minPid=200;
    if(this.checkoutForm.valid || (this.pay_type != 0) || forcePass==1){
      let hasError:boolean = false;

      if(this.cart_item_selected > 0){
            if (this.pay_type == 0) {
              hasError = !this.shareinfoService.validateExpiryMMYY(this.checkoutForm.get('expireDate').value);
              if (hasError == false) {
                hasErrors++;
                errors.push('Your credit card is expired!');
              }
            }
            if (hasErrors==0 || forcePass==1) {
              return_data = {
                payType : this.checkoutForm.get('payType').value,
                //discount_rate : this.cart_discount_rate,
                products :[],
                subtotal : this.cart_subtotal,
                discount_amount : this.cart_discounts,
                taxes : this.cart_tax,
                amount : this.cart_total,
                gstAmt  : this.cart_tax_1,
                pstAmt : this.cart_tax_2,
                shipping_fee : this.shipping_fee,
                shippingId : -1,
                referral : null,
                recommend : this.recommanded,
                contact_info :{
                  MemberNo : environment.cart_buyer,
                  first_name : this.checkoutForm.get('contact_first_name').value,
                  last_name : this.checkoutForm.get('contact_last_name').value,
                  email : this.checkoutForm.get('contact_email').value,
                  phone : this.checkoutForm.get('contact_phone').value,
                  country : this.checkoutForm.get('contact_country').value,
                  province : this.checkoutForm.get('contact_province').value,
                  city : this.checkoutForm.get('contact_city').value,
                  address : this.checkoutForm.get('contact_address').value,
                  postCode : this.checkoutForm.get('contact_postCode').value,
                },
                use_billing_adr : this.sameasbillingadr? 1: 0
              }
      
              this.cart_items.forEach(cart_item => {
                  if(cart_item.selected == 1){
                    // data.subtotal += (cart_item.unitPrice) * cart_item.nums;
                    return_data.products.push({
                      pId : cart_item.pId,
                      nums : cart_item.nums,
                      price : cart_item.unitPrice,
                    });
                    minPid= (minPid > cart_item.pId) ? cart_item.pId : minPid;
                  }
              });
              let shippingData = {
                  first_name: this.sameasbillingadr ? this.checkoutForm.get('contact_first_name').value : this.checkoutForm.get('first_name').value,
                  last_name: this.sameasbillingadr ? this.checkoutForm.get('contact_last_name').value : this.checkoutForm.get('last_name').value,
                  Tel : this.sameasbillingadr ? this.checkoutForm.get('contact_phone').value : this.checkoutForm.get('Tel').value,
                  Address : this.sameasbillingadr ? this.checkoutForm.get('contact_address').value : this.checkoutForm.get('Address').value,
                  City : this.sameasbillingadr ? this.checkoutForm.get('contact_city').value : this.checkoutForm.get('City').value,
                  Province : this.sameasbillingadr ? this.checkoutForm.get('contact_province').value : this.checkoutForm.get('Province').value,
                  Country : this.sameasbillingadr ? this.checkoutForm.get('contact_country').value : this.checkoutForm.get('Country').value,
                  PostCode : this.sameasbillingadr ? this.checkoutForm.get('contact_postCode').value : this.checkoutForm.get('PostCode').value,
                  Email : this.checkoutForm.get('contact_email').value,
                  as_billing : this.sameasbillingadr ? 1 :0,
              }
              return_data['shippingAddress']=shippingData;
              return_data['minPid'] = minPid;

              //if(this.checkoutForm.get('payType').value == 0){
                if(this.pay_type == 0){
                    return_data["credit_card"] =  {
                    cardNo : (this.checkoutForm.get('cardNo').value).toString().replace(/\s+/g, ''),
                    expireDate : this.checkoutForm.get('expireDate').value,
                    cvCode : this.checkoutForm.get('cvCode').value,
                    firstName : this.checkoutForm.get('firstName').value,
                    lastName : this.checkoutForm.get('lastName').value,
                    postCode : this.checkoutForm.get('contact_postCode').value,
                    address : this.checkoutForm.get('contact_address').value,
                    email :  this.checkoutForm.get('contact_email').value,
                  };
                  //console.log('call purchase(data) data:',return_data);
              }
            }
      } else {
        hasErrors++;
        errors.push('No products selected!');
      }
    } else {
      hasErrors++;
      errors.push('The data has errors!');
    }
    if (hasErrors>0) {
      alert("There are errors,"+errors[0]);
    }
    return return_data;
  }

  renderTaxName(cart_item)
  { 
    if(this.cart_tax_rate){
      if(cart_item.tax_type == 0 || cart_item.tax_type == 1 || cart_item.tax_type == null){
        return this.cart_tax_rate.tax_name + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + ")";
      }else if(cart_item.tax_type == 2 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name2 + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : this.translate.translate.instant("NOTAX");
      }else if(cart_item.tax_type == 3 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }else if(cart_item.tax_type == 4 ){
        return this.cart_tax_rate.tax_name2 ? (this.cart_tax_rate.tax_name + ' + ' + this.cart_tax_rate.tax_name2  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate + this.cart_tax_rate.tax_rate2,'0.0-3') + ")") : (this.cart_tax_rate.tax_name  + " (" + this.percentPipe.transform(this.cart_tax_rate.tax_rate,'0.0-3') + "%)");
      }
    }
    return this.translate.translate.instant('PROCEEDBILLING');
  }

  getTaxRate(){
    this.shareinfoService.tax_rate.subscribe( resp => {
      if (resp) {
        this.cart_tax_rate = resp
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }
  

  finishSteps()
  {
    if(this.checkoutForm.valid){

      if(this.cart_item_selected > 0){
  
          // data.discount_amount = data.subtotal * data.discount_rate;
          // data.taxes = ( data.subtotal - data.discount_amount ) * this.cart_tax_rate ? this.cart_tax_rate.tax_rate : 0;
          // data.amount = data.subtotal - data.discount_amount + data.taxes;
  
          const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
          modalRef.componentInstance.translate = this.translate;
          modalRef.componentInstance.message = this.translate.translate.instant('CONTPURCH');
          modalRef.result.then((result) => {
            if(result){
                var data = {
                  payType : this.checkoutForm.get('payType').value,
                  //discount_rate : this.cart_discount_rate,
                  products :[],
                  subtotal : this.cart_subtotal,
                  discount_amount : this.cart_discounts,
                  taxes : this.cart_tax,
                  amount : this.cart_total,
                  gstAmt  : this.cart_tax_1,
                  pstAmt : this.cart_tax_2,
                  shipping_fee : this.shipping_fee,
                  shippingId : -1,
                  referral : null,
                  recommend : this.recommanded,
                  contact_info :{
                    MemberNo : environment.cart_buyer,
                    first_name : this.checkoutForm.get('contact_first_name').value,
                    last_name : this.checkoutForm.get('contact_last_name').value,
                    email : this.checkoutForm.get('contact_email').value,
                    phone : this.checkoutForm.get('contact_phone').value,
                    country : this.checkoutForm.get('contact_country').value,
                    province : this.checkoutForm.get('contact_province').value,
                    city : this.checkoutForm.get('contact_city').value,
                    address : this.checkoutForm.get('contact_address').value,
                    postCode : this.checkoutForm.get('contact_postCode').value,
                  },
                  use_billing_adr : this.sameasbillingadr? 1: 0
                }
        
                this.cart_items.forEach(cart_item => {
                    if(cart_item.selected == 1){
                      // data.subtotal += (cart_item.unitPrice) * cart_item.nums;
                      data.products.push({
                        pId : cart_item.pId,
                        nums : cart_item.nums,
                        price : cart_item.unitPrice,
                        
                      });
                    }
                });
    
                /*
                let shippingData = {
                  //contact_person: this.checkoutForm.get('name').value,
                  first_name : this.preferredShipping == 1 && this.checkoutForm.get('first_name').value ?  this.checkoutForm.get('first_name').value : this.checkoutForm.get('contact_first_name').value,
                  last_name : this.preferredShipping == 1 && this.checkoutForm.get('last_name').value ?  this.checkoutForm.get('last_name').value : this.checkoutForm.get('contact_last_name').value,
                  Email: this.checkoutForm.get('contact_email').value,
                  Tel : this.checkoutForm.get('Tel').value,
                  Address : this.checkoutForm.get('Address').value,
                  City : this.checkoutForm.get('City').value,
                  Province : this.checkoutForm.get('Province').value,
                  Country : this.checkoutForm.get('Country').value,
                  PostCode : this.checkoutForm.get('PostCode').value,
                }
                */
                let shippingData = {
                  first_name : this.preferredShipping == 1 && this.checkoutForm.get('first_name').value ?  this.checkoutForm.get('first_name').value : this.checkoutForm.get('contact_first_name').value,
                  last_name : this.preferredShipping == 1 && this.checkoutForm.get('last_name').value ?  this.checkoutForm.get('last_name').value : this.checkoutForm.get('contact_last_name').value,
                  Tel : this.sameasbillingadr ? this.checkoutForm.get('contact_phone').value : this.checkoutForm.get('Tel').value,
                  Address : this.sameasbillingadr ? this.checkoutForm.get('contact_address').value : this.checkoutForm.get('Address').value,
                  City : this.sameasbillingadr ? this.checkoutForm.get('contact_city').value : this.checkoutForm.get('City').value,
                  Province : this.sameasbillingadr ? this.checkoutForm.get('contact_province').value : this.checkoutForm.get('Province').value,
                  Country : this.sameasbillingadr ? this.checkoutForm.get('contact_country').value : this.checkoutForm.get('Country').value,
                  PostCode : this.sameasbillingadr ? this.checkoutForm.get('contact_postCode').value : this.checkoutForm.get('PostCode').value,
                  Email : this.checkoutForm.get('contact_email').value,
                  as_billing : this.sameasbillingadr ? 1 :0,
                }

                data['shippingAddress']=shippingData;
                //this.purchase(data);
                if(this.preferredShipping == 1 || this.preferredShipping == 2){
                  this.evgService.postShippingAddress8888(shippingData).subscribe( resp => {
                    if (resp) {
                      if(resp.success){
                        data.shippingId = resp.data[0].shippingId;
                        this.purchase(data);
                      } else {
                        this.responseHandlerService.handleResponse(resp);
                      }
                    }
                  },(errorResult) => {
                    console.log("System Error", errorResult);
                    this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
                  });
                }else{
                  if(this.selectedAddress >= 0){
                    data.shippingId = this.shippingAddresses[this.selectedAddress].id;
                    this.purchase(data);
                  }
                }
            }
          });
      }else{
        this.responseHandlerService.handleCustomResponse("Please select/add items","warning");
      }
    }
  }

  purchase(data)
  {
    if(this.checkoutForm.get('payType').value == 0 ){
      if (data.credit_card==undefined || !data.credit_card) {
        data.credit_card =  {
          cardNo : (this.checkoutForm.get('cardNo').value).toString().replace(/\s+/g, ''),
          expireDate : this.checkoutForm.get('expireDate').value,
          cvCode : this.checkoutForm.get('cvCode').value,
          firstName : this.checkoutForm.get('firstName').value,
          lastName : this.checkoutForm.get('lastName').value,
          postCode : this.checkoutForm.get('contact_postCode').value,
          address : this.checkoutForm.get('contact_address').value,
          //postCode : this.checkoutForm.get('postCode').value,
          //address : this.checkoutForm.get('address').value,
        };
      }
      if ((this.checkoutForm.get('payType').value == 0 && this.shareinfoService.validateExpiryMMYY(this.checkoutForm.get('expireDate').value)) ) {
        this.shareinfoService.setCreditProcess(true);
        this.evgService.placeOrder(data).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.setCreditProcess(false);
            if(resp.success){
              this.clearCart();
              this.enteredEmail=0;
              this.shipping_fee=0;
              //this.shippingAddresses=[];
              //this.shareinfoService.reloadUser();
              //this.activeModal.close('Close click');
              this.step=0;
              this.cart_tax_rate=null;
              this.select_product_title = 'SELECTPRODCT';
            }
          }
        },(errorResult) => {
          this.shareinfoService.setCreditProcess(false);
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
        this.responseHandlerService.handleResponse({ success : false, data : [{error : "expired date"}],  message : "UNUSECARD" });
      }
    }else{//never to this
      if(this.user.totalCash >= this.cart_total || this.pay_type>=2){
        this.shareinfoService.setCreditProcess(true);
        this.evgService.placeOrder(data).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.setCreditProcess(false);
            if(resp.success){
              this.clearCart();
              this.enteredEmail=0;
              this.shipping_fee=0;
              //this.shippingAddresses=[];
              //this.shareinfoService.reloadUser();
              //this.activeModal.close('Close click');
              this.step=0;
              this.cart_tax_rate=null;
              this.select_product_title = 'SELECTPRODCT';

              this.shareinfoService.reloadUser();
              //this.activeModal.close('Close click');
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.shareinfoService.setCreditProcess(false);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }else{
        this.responseHandlerService.handleCustomResponse("Not enough funds!","warning");
      }
    }
  }

  newFinishStep(payPalPost,forcePass=1){
    this.shareinfoService.setCreditProcess(true);
    let postData = this.collectValidationData(forcePass);
    //postData["payPalID"] = payPalID;
    postData["payPalPost"] = payPalPost;
    if(this.preferredShipping == 1 || this.preferredShipping == 2){
      let shippingData = {
        first_name : this.preferredShipping == 1 && this.checkoutForm.get('first_name').value ?  this.checkoutForm.get('first_name').value : this.checkoutForm.get('contact_first_name').value,
        last_name : this.preferredShipping == 1 && this.checkoutForm.get('last_name').value ?  this.checkoutForm.get('last_name').value : this.checkoutForm.get('contact_last_name').value,
        Tel : this.sameasbillingadr ? this.checkoutForm.get('contact_phone').value : this.checkoutForm.get('Tel').value,
        Address : this.sameasbillingadr ? this.checkoutForm.get('contact_address').value : this.checkoutForm.get('Address').value,
        City : this.sameasbillingadr ? this.checkoutForm.get('contact_city').value : this.checkoutForm.get('City').value,
        Province : this.sameasbillingadr ? this.checkoutForm.get('contact_province').value : this.checkoutForm.get('Province').value,
        Country : this.sameasbillingadr ? this.checkoutForm.get('contact_country').value : this.checkoutForm.get('Country').value,
        PostCode : this.sameasbillingadr ? this.checkoutForm.get('contact_postCode').value : this.checkoutForm.get('PostCode').value,
        Email : this.checkoutForm.get('contact_email').value,
        as_billing : this.sameasbillingadr ? 1 :0,
      }
      this.evgService.postShippingAddress8888(shippingData).subscribe( resp => {
        if (resp) {
          if(resp.success){
            postData.shippingId = resp.data[0].shippingId;
            this.purchase(postData);
          } else {
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else if (this.preferredShipping == 0) {
      if(this.selectedAddress >= 0){
        postData.shippingId = this.shippingAddresses[this.selectedAddress].id;
        this.purchase(postData);
      } else {
        let errmsg = { success : false, data : [{error : "No shipping address selected!"}],  message : "NOSHIPADR" };
        this.responseHandlerService.handleResponse(errmsg);
      }
    } else {
      this.purchase(postData);
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  verify()
  {
    if(this.checkoutForm.get('Referrals').value && this.checkoutForm.get('Recommended').value){

        this.evgService.verifyUser({userId : this.checkoutForm.get('Recommended').value}).subscribe(resp => {
          if(resp){
            if(resp.data[0].results.length > 0){
              var sponsor = resp.data[0].results[0];
              this.evgService.verifyUser({userId : this.checkoutForm.get('Referrals').value}).subscribe(resp => {
                if(resp){
                  if(resp.data[0].results.length > 0){
                    var placer = resp.data[0].results[0];
                    const modalRef = this.ngDialog.open(VerifyDialogComponent, { windowClass: 'verify-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                      modalRef.componentInstance.translate = this.translate;
                      modalRef.componentInstance.message = "Sponsor : " + this.redactName(sponsor);
                      modalRef.componentInstance.message2 = "Placer : " + this.redactName(placer);
                      modalRef.result.then((result) => {
                        if(result){
                           this.verified = true;
                           this.cdref.detectChanges();
                        }
                      });
                    this.cdref.detectChanges();
                  }else{
                    const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'alert-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                    modalRef.componentInstance.translate = this.translate;
                    modalRef.componentInstance.message = "Sponsor and/or Placer not found.";
                  }
                  
                }
              });
              this.cdref.detectChanges();
            }else{
              const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'alert-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
              modalRef.componentInstance.translate = this.translate;
              modalRef.componentInstance.message = "Sponsor and/or Placer not found.";
            }
            
          }
        });
    }

    
  }

  redactName(user){
    if(user){
      var modifiedFirstName = '';
      var modifiedLastName = '';
      if(user.first_name.length > 2){
        modifiedFirstName = user.first_name.substr(0,1);
        for (let index = 0; index < (user.first_name.length - 2); index++) {
          modifiedFirstName += '*';
        }
        modifiedFirstName += user.first_name.substr(user.first_name.length - 1);
      }else{
        modifiedFirstName = user.first_name.last_name;
      }

     if(user.last_name.length > 2){
          modifiedLastName = user.last_name.substr(0,1);
          for (let index = 0; index < (user.last_name.length - 2); index++) {
            modifiedLastName += '*';
          }
          modifiedLastName += user.last_name.substr(user.last_name.length - 1);
      }else{
        modifiedLastName = user.last_name;
      }
     return modifiedFirstName + ' ' + modifiedLastName;
    }
  }

  selectProducts() {
    //this.selectedEvent.emit(value);
    //this.items.push(value);
    const modalRef = this.ngDialog.open(SelectProductsComponent, { windowClass: 'cart-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.selectedProducts = this.selectedProducts;

    modalRef.result.then((result) => {
      //console.log('selectProducts return result:',result);
      if(result && result.length>0){
        this.selectedProducts = result;
        this.reloadCart(result);

        //console.log('openPaymentWinsdow return after result:',result);
      }
    });

  }
  getPaypalProcess(ev_value){

    //console.log('getPaypalProcess event:',JSON.parse(ev_value));
    let resp =JSON.parse(ev_value);
    //console.log('getPaypalProcess event:',resp);
    if (resp.status=='success') {
      this.newFinishStep(resp.info);
    } else if (resp.status=='OnError') {
      alert("error:"+ JSON.stringify(resp.error));
    }

  }

  setShippingInfo(){
    this.shippingInfo=
    {
      MemberNo : this.user.id,
      first_name : this.checkoutForm.get('contact_first_name').value,
      last_name : this.checkoutForm.get('contact_last_name').value,
      email : this.checkoutForm.get('contact_email').value,
      phone : this.checkoutForm.get('contact_phone').value,
      country : this.cart_tax_rate? this.cart_tax_rate.country_code : this.checkoutForm.get('contact_country').value,
      province : this.cart_tax_rate? this.cart_tax_rate.state_code : this.checkoutForm.get('contact_province').value,
      city : this.checkoutForm.get('contact_city').value,
      address : this.checkoutForm.get('contact_address').value,
      postCode : this.checkoutForm.get('contact_postCode').value,
    };
    //console.log('setShippingInfo : ',this.shippingInfo);

  }

  get checkCanSubmit(){
    return (this.checkoutForm.valid && this.pay_type==0)
  }

  preferredShippingOptionChanged() {
    console.log('preferredShippingOptionChanged selectedShippingOption:',this.selectedShippingOption);
    if (this.shippingOptions && this.shippingOptions.length>0 && this.selectedShippingOption>=0 && this.selectedShippingOption <this.shippingOptions.length){
      this.shipping_fee = this.shippingOptions[this.selectedShippingOption].shipppingFee;
      this.getCart();
    }

  }
}
