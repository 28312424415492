<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        <div class="banner-detail2 mb-3"><h2>{{ 'FIRMINGTIT' | translate }}</h2></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" translate>
            FIRMING1TIT
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>FIRMING1TITD1</p><br>
            <p class="mb-5 text-center" translate>FIRMING1TITD2</p><br>
        </div>
    </div>


    <div class="row what-product-detail2">
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title" translate>FIRMING2TIT</div>
                    <div class="separator mt-1 mb-3"></div>
                </div>
                <div class="each-secion">
                    <div class="item_left_detail">
                        <p class="why-detail" translate>FIRMING2TITD1</p><br>
                        <p class="why-detail" translate>FIRMING2TITD2</p><br>
                        <p class="why-detail" translate>FIRMING2TITD3</p><br>
                        <p class="why-detail" translate>FIRMING2TITD4</p><br>
                        <p class="why-detail" translate>FIRMING2TITD5</p><br>
                        <p class="why-detail" translate>FIRMING2TITD6</p><br>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="content-row">
                <div class="each-secion">
                    <div class="item_title">&nbsp;</div>
                </div>

                <div class="each-secion">
                    <div class="item_detail">
                        <p class="why-detail">{{'FIRMING2TITD7' | translate }}</p><br>
                        <p class="why-detail">{{'FIRMING2TITD8' | translate }}</p><br>
                        <p class="why-detail">{{'FIRMING2TITD9' | translate }}</p><br>
                        <p class="why-detail">{{'FIRMING2TITDA' | translate }}</p><br>
                        <p class="why-detail">{{'FIRMING2TITDB' | translate }}</p><br>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row what-product">
        <div class="col-lg-6 col-md-12">
            <div class="left-section">{{'FIRMING3TIT' | translate }}</div>
            <div class="separator"></div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="right-section"></div>
        </div>
    </div>

    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>FIRMING3TITT1</span>
                        <span class="why-detail" translate>FIRMING3TITD1</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>FIRMING3TITT2</span>
                        <span class="why-detail" translate>FIRMING3TITD2</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>FIRMING3TITT3</span>
                        <span class="why-detail" translate>FIRMING3TITD3</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>FIRMING3TITT4</span>
                        <span class="why-detail" translate>FIRMING3TITD4</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>FIRMING3TITT5</span>
                        <span class="why-detail" translate>FIRMING3TITD5</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_title" translate>FIRMING3CLS</div>
                </div>
            </div>
        </div>
    </div>
    <app-introduce-part [min_height]="640" [contensInfo]="introduceInfo"></app-introduce-part>
<!---- at July 8,2024 by Victor

    <div class="introduce">
        <div class="introduce-body">
            <h1 class="introduce-head" translate>FIRMING4TIT</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{ 'FIRMING4TITD' | translate }}</div>
        </div>
    </div>
-->
</div>

