<div class="winners-list-record-by-month-gl-container">
    <div class="row gutters-sm">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
              
            <h3 class="account-title">{{'GROWTHLEVELRANK' | translate}}</h3>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'BONUSAMOUNT' | translate}}</th>
                          <th scope="col">{{'YEARMONTH' | translate}}</th>
                          <th scope="col">{{'PAIDDATE' | translate}}</th>
                          <th scope="col">{{'RANK' | translate}}</th>
                          <th scope="col">{{'SALESAMOUNT' | translate}}</th>
                          <th scope="col">{{'ULEVEL' | translate}}</th>
                          <th scope="col">{{'WEIGHT' | translate}}</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let glRank of glRanks; index as i;">
                          <td>{{ glRank.MemberNo }}</td>
                          <td>{{ glRank.bonusAmt | currency:'' }}</td>
                          <td>{{ glRank.forYYYYMM }}</td>
                          <td>{{ glRank.paid_dt ? glRank.paid_dt.substring(0,10) : glRank.paid_dt }}</td>
                          <td>{{ glRank.rank }}</td>
                          <td>{{ glRank.salesAmt | currency:'' }}</td>
                          <td>{{ glRank.uLevel }}</td>
                          <td>{{ glRank.weighted }}</td>
                        </tr>
                      </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>