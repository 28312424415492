<div class="set-up-monthly-payment-container">
    <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #setupMonthly="ngForm" [formGroup]="paymentSettingForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                        
                        <div class="p-3">
                            <h2>{{ 'SETUPMONTHLY' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'PAYFEE' | translate}}</mat-label>
                                    <mat-select formControlName="payFee"  type="text" class="form-control" id="payFee" aria-describedby="payFee"
                                    autocomplete="off">
                                        <mat-option  [value]="1">{{ 'YES' | translate }}</mat-option>
                                        <mat-option  [value]="0">{{ 'NO' | translate }}</mat-option>
                                    
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="paymentSettingForm.get('payFee').hasError('required')">{{'PAYFEEREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'AUTOPAY' | translate}}</mat-label>
                                    <mat-select formControlName="autoPay"  type="text" class="form-control" id="autoPay" aria-describedby="autoPay"
                                    autocomplete="off">
                                        <mat-option  [value]="1">{{ 'YES' | translate }}</mat-option>
                                        <mat-option  [value]="0">{{ 'NO' | translate }}</mat-option>
                                    
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="paymentSettingForm.get('autoPay').hasError('required')">{{'AUTOPAYREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'QUITRANK' | translate}}</mat-label>
                                    <mat-select formControlName="quitRank"  type="text" class="form-control" id="quitRank" aria-describedby="quitRank"
                                    autocomplete="off">
                                        <mat-option  [value]="1">{{ 'YES' | translate }}</mat-option>
                                        <mat-option  [value]="0">{{ 'NO' | translate }}</mat-option>
                                    
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="paymentSettingForm.get('quitRank').hasError('required')">{{'QUITRANK' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <mat-label class="m-3">{{'SELECTACTIVECARD' | translate}}</mat-label>
                            <div class="col-sm-12">
                                <mat-radio-group
                                    aria-labelledby="cards-radio-group-label"
                                    class="cards-radio-group"
                                    formControlName="selectedCardId">
                                    <mat-radio-button class="cards-radio-button" [value]="0">
                                        {{ 'NEWCARD' | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button class="cards-radio-button" *ngFor="let card of cards" [value]="card.id">
                                        {{ card.cardType == 0 ? ("VISA" | translate) : ("MASTERCARD" | translate) }} : {{ card.cardNo }} - {{ card.holder }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row m-3" *ngIf="paymentSettingForm.get('selectedCardId').value == 0">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mat-form-field style="width: 100%;">
                                                <mat-label>{{'CARDTYPE' | translate}}</mat-label>
                                                <mat-select formControlName="cardType"  type="text" class="form-control" id="cardType" aria-describedby="cardType"
                                                autocomplete="off">
                                                    <mat-option  [value]="1">{{ 'MASTERCARD' | translate }} </mat-option>
                                                    <mat-option  [value]="0" translate>{{ 'VISA' | translate }}</mat-option>
                                                    <mat-option  [value]="2">{{ 'AMERICANEXPRESS' | translate }}</mat-option>                                               
                                                </mat-select>
                                                <mat-error class="error-message" *ngIf="paymentSettingForm.get('cardType').hasError('required')">{{'CARDTYPEREQ' | translate}}</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="holder"  type="text" class="form-control text-box" id="holder" 
                                            name="holder" placeholder="{{ 'HOLDER' | translate }}"
                                            autocomplete="off"
                                            >
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('holder').hasError('required')">{{'HOLDERREQ' | translate}}</mat-error>
                                        </mat-form-field>
                                        </div>
                                    </div>
            
                                    <div class="row">
                                        <div class="col-sm-12">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="cardNo"  type="text" class="form-control text-box" id="cardNo" 
                                            name="cardNo" placeholder="{{ 'CARDNO' | translate }}"
                                            autocomplete="off"
                                            >
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cardNo').hasError('required')">{{'CARDNOREQ' | translate}}</mat-error>
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cardNo').hasError('pattern')">{{'ONLYNUM' | translate}}</mat-error>
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cardNo').hasError('minLength')">{{'MINLENGTH12' | translate}}</mat-error>
                                            
                                        </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="expireDate"  type="month" class="form-control text-box" id="expireDate" 
                                            name="expireDate" placeholder="{{ 'EXPDATE' | translate }}"
                                            autocomplete="off"
                                            >
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('expireDate').hasError('required')">{{'EXPDATEREQ' | translate}}</mat-error>
                                        </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="cvCode"  type="number" class="form-control text-box" id="cvCode" 
                                            name="cvCode" placeholder="{{ 'CVCODE' | translate }}"
                                            autocomplete="off"
                                            >
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cvCode').hasError('required')">{{'CVCODEREQ' | translate}}</mat-error>
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cvCode').hasError('pattern')">{{'ONLYNUM' | translate}}</mat-error>
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cvCode').hasError('minLength')">{{'MUSTBE3' | translate}}</mat-error>
                                            <mat-error class="error-message" *ngIf="paymentSettingForm.get('cvCode').hasError('maxLength')">{{'MUSTBE3' | translate}}</mat-error>
                                        </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!paymentSettingForm.valid " (click)="updatePaymentSettings(setupMonthly)" >{{ "UPDATEBTN" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
        
    </div>
</div>