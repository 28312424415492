<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="card-body">
        <h3 class="text-center">{{ 'CUSTREVIEWS' | translate }}</h3>
        <div *ngFor="let productReview of productReviews; index as i;"  class="customer-review">
            <div class="text-center"><mat-star-rating [rating]="productReview.stars" ></mat-star-rating></div>
            <small style="font-size: x-small;">{{ productReview.AddTime + ' - ' + productReview.first_name + ' ' + productReview.last_name}}</small>
            <p class="text-center">{{ productReview.review_content ? ('\"' + productReview.review_content + '\"') : ('NOREVIEWCONTENT' | translate) }}</p>
            <hr>
        </div>
    </div>
</div>


<!-- 
AddTime: "2021-09-08 09:46:33"
MemberNo: 10026
first_name: "Lenmo"
id: 1
last_name: "LenmoLAst"
last_updated: "2021-09-09 23:53:02"
pId: 3
review_content: "" -->

