<div class="byte-token-transfers-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'BYTETOKENTRANS' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'TOPIC' | translate}}</th>
                          <th scope="col">{{'CONTENT' | translate}}</th>
                          <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'CANCELDATE' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let transfer of transfers; index as i;">
                          <td>{{ transfer.topic }}</td>
                          <td>{{ transfer.content }}</td>
                          <td>{{ transfer.amount | currency}}</td>
                          <!--td>{{ transfer.canceled ? ('YES' | translate) : ('NO' | translate)}}</td>
                          <td>{{ transfer.canceled_at ? transfer.canceled_at : ('NODATEAVAIL' | translate) }}</td-->
                          <td>{{ transfer.canceled ? ('YES' | translate) : ('')}}</td>
                          <td>{{ transfer.canceled_at ? transfer.canceled_at : ('') }}</td>
                          <td>{{ transfer.AddTime }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>