import { Component, OnInit, Input, ViewEncapsulation, Renderer2, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareinfoService } from 'src/app/services/shareinfo.service'
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { SlideControlComponent } from 'ng-spc';
import { ControlInput, VertifyQuery} from 'ng-spc';
import { ActivateAccountComponent } from '../activate-account/activate-account.component';
import { PrivacyTermsComponent } from '../privacy-terms/privacy-terms.component';
import { UserTermsComponent } from '../user-terms/user-terms.component';
import { DistributorTermsComponent } from '../distributor-terms/distributor-terms.component';
import { LoginComponent } from '../login/login.component';
import { OnExecuteData, OnExecuteErrorData, ReCaptchaV3Service } from "ng-recaptcha";
import { environment } from 'src/environments/environment';
import { AlertDialogComponent } from "src/app/modules/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Input() translate : TranslateConfigService;
  @Input() openLogin : boolean=true;
  confirmPW : string;
  passwordMatched : boolean = false;
  responseType:string ='';
  responseMsg:string='';
  questions = [];
  siteKey = environment.recaptcha.siteKey;//"6LcMpTUaAAAAAP0XIwJTL3XS4otHPdhi-eUVV9ml";
  captchaValidated = false;
  registerForm: FormGroup;
  selectedQuestion1 = 0;
  selectedQuestion2 = 0;
  isProcessing = false;
  private _unsubscribe$ = new Subject<any>();

  passRequirement = {
    passwordMinLowerCase: 1,
    passwordMinNumber: 1,
    passwordMinSymbol: 2,
    passwordMinUpperCase: 1,
    passwordMinCharacters: 8
  };

  pattern = [
    `(?=([^a-z]*[a-z])\{${this.passRequirement.passwordMinLowerCase},\})`,
    `(?=([^A-Z]*[A-Z])\{${this.passRequirement.passwordMinUpperCase},\})`,
    `(?=([^0-9]*[0-9])\{${this.passRequirement.passwordMinNumber},\})`,
    `(?=(\.\*[\$\@\$\!\%\*\?\&])\{${this.passRequirement.passwordMinSymbol},\})`,
    `[A-Za-z\\d\$\@\$\!\%\*\?\&\.]{${
      this.passRequirement.passwordMinCharacters
    },}`
  ]

  @ViewChild(SlideControlComponent, {static: true})
  slide: SlideControlComponent;
  public controlInput: ControlInput;
  private query: VertifyQuery;
  // for reCaptcha start
  public recentToken = "";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recentError?: { error: any };
  public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];

  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;
  fieldTextType: boolean = false;
  fieldTextTypeConfirm: boolean=false;
  preferred_customer : boolean=false;
  // end for reCaptcha

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public formatter: NgbDateParserFormatter,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private recaptchaV3Service: ReCaptchaV3Service,
    private cdr : ChangeDetectorRef
  ) { 
   
  }
  
  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  setAutoFillOff(event: any) {
    if (event) {
      event.target.attributes['autocomplete'].value = 'chrome-off';
    }
  }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      
      email: ['', [Validators.required , Validators.email]],
      //(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])
      //password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,20}')]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])|(?=.*[A-Z])|(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,20}')]],
      confirm_password: ['', Validators.required],
      privacy_terms : ['',Validators.requiredTrue],
      user_terms : ['',Validators.requiredTrue],
      distributor_terms : ['',[]],
      sponsorId : [this.getPreSponsor(),[]]
    });

    this.evgService.getSecretQuestions().subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.questions = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });

    this.registerForm.valueChanges.subscribe(val => {
          this.comparePassword();
      });
  
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action).subscribe(
      (token) => {
        this.recentToken = token;
        this.recentError = undefined;
        this.register();
      },
      (error) => {
        this.recentToken = "";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.recentError = { error };
      },
    );
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }

  togglePreferredCustomer(){
    this.preferred_customer = !this.preferred_customer;
  }

  showNoticePassword(isConfirm){
    const modalRef = this.ngDialog.open(AlertDialogComponent, { windowClass: 'register-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.responseHandlerService.translateMessage("PASSPATTERNREQ") + (isConfirm==0 ? '' :  '  ' + this.responseHandlerService.translateMessage("PASSWORDMATCH"));

  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }
  
  resolved(captchaResponse: string) {
    this.captchaValidated = true;
  }

  privacy_click(){
    this.registerForm.patchValue({
      privacy_terms : false
    })
    const modalRef = this.ngDialog.open(PrivacyTermsComponent, { windowClass: 'privacy-terms-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;

    modalRef.result.then((data) => {
      
    }, (reason) => {
      this.registerForm.patchValue({
        privacy_terms : true
      })
    });
  }

  user_click(){
    this.registerForm.patchValue({
      user_terms : false
    })
    const modalRef = this.ngDialog.open(UserTermsComponent, { windowClass: 'user-terms-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;

    modalRef.result.then((data) => {
    }, (reason) => {
      this.registerForm.patchValue({
        user_terms : true
      })
    });
  }

  distrib_click(){
    this.registerForm.patchValue({
      distributor_terms : false
    })
    const modalRef = this.ngDialog.open(DistributorTermsComponent, { windowClass: 'distributor-terms-modal' ,centered: true, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;

    modalRef.result.then((data) => {
    }, (reason) => {
      this.registerForm.patchValue({
        distributor_terms : true
      })
    });
  }

  preferred_click(){

  }

  comparePassword()
  {
    this.passwordMatched = this.registerForm.value.password === this.registerForm.value.confirm_password ? true : false;
  }


  public send(): void {
    if (this.registerForm.invalid) {
      for (const control of Object.keys(this.registerForm.controls)) {
        this.registerForm.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
      this.register();
      console.debug(`Token [${token}] generated`);
    });
  }


  register() {
    let pre_sponsor=this.getPreSponsor();

      if(this.registerForm.valid){
        this.isProcessing = true;
        let data ={
          last_name: this.registerForm.value.last_name,
          first_name: this.registerForm.value.first_name,
          email: this.registerForm.value.email,
          password: this.registerForm.value.password,
          password_confirmation: this.registerForm.value.confirm_password,
          isDistributor : this.registerForm.value.distributor_terms ? 1 : 0,
          pre_sponsor : pre_sponsor
        };
        let sponsorId=this.registerForm.value.sponsorId;

        if (pre_sponsor===null || pre_sponsor=='' ) {
          delete data.pre_sponsor;
        }
        let isemail=false;
        let isUserId=false;
        if (sponsorId && sponsorId.length>4) {
          let reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          isUserId= /^\d+$/.test(sponsorId);
          isemail = reg.test(sponsorId);
          //console.log('register  isUserId: isemail:',isUserId,isemail);
          if (isUserId || isemail) {
            this.evgService.getSponsorInfo( {sponsorId : sponsorId} ).subscribe(resp => {
              //console.log('register getSponsorInfo resp:',resp);
              if (resp && resp.success) {
                let sponsor = resp.data[0].results;
                data['Recommended'] = sponsor.id;
                data['sponsorEmail'] = sponsor.email;
                data['sponsorName'] = sponsor.first_name + ' ' + sponsor.last_name;
                this.todoRegister(data);
              } else {
                const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                modalRef.componentInstance.translate = this.translate;
                modalRef.componentInstance.message = this.translate.translate.instant('SPONSORNOT');
                modalRef.result.then((result) => {
                  if(result){
                    this.todoRegister(data);
                  }
                });
              }
            });
          } else{
            alert(this.translate.translate.instant('SPONSORERR'));
          }
        } else {
          if (sponsorId && sponsorId.length>0) {
            alert(this.translate.translate.instant('SPONSORERR'));
          } else {
            this.todoRegister(data);
          }
        }
      }
      
    }

    private todoRegister(data) {
      this.evgService.userRegister( data ).subscribe(resp => {
        if (resp) {
           this.responseHandlerService.handleResponse(resp);
           if(resp.success){
             this.isProcessing = false;
             this.activeModal.close('Close click');
             //this.router.navigate([this.shareinfoService.backmainId]);
             //const modalRef = this.ngDialog.open(ActivateAccountComponent, { windowClass: 'activate-account-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
             //modalRef.componentInstance.translate = this.translate;
             //this.router.navigate([this.shareinfoService.loginId]);
             if (this.openLogin==true) {

                 const modalRef = this.ngDialog.open(LoginComponent, { windowClass: 'activate-account-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
                 modalRef.componentInstance.translate = this.translate;
             }
           }else{
             this.isProcessing = false;
           }
        }else{
         this.isProcessing = false;
        }
      });

    }

    private reset() {
      this.query.move = undefined;
      this.query.action = [];
      this.slide.reset();
      this.captchaValidated = false;
    }

    getPreSponsor() {
      let pre_sponsor='';
      if(localStorage.getItem('guest')){
        let personal_webpage_data = localStorage.getItem('guest').toString().split(',');
        pre_sponsor= personal_webpage_data.length>0 ? personal_webpage_data[0] : null;
        //console.log("getPreSponsor personal_webpage_data:", personal_webpage_data);
      }
      //console.log("getPreSponsor pre_sponsor:", pre_sponsor);
      return pre_sponsor;
    }
  
}

