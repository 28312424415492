<div class="run-autoship-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'RUNAUTOSHIPTIT' | translate}}</h3>
              <div class="text-center mb-2">
                <div class="row timzone m-2">
                    <div class="m-2">{{'CURRENTTIME' | translate}}</div>
                    <div class="m-2"><p>{{ date |   date: "MM-dd-yyyy"}}&nbsp;{{ date |   date: "HH:mm:ss"}}</p></div>
                </div>
                <form [formGroup]="runAutoshipForm" (keydown.enter)="$event.preventDefault()" autocomplete="so-form">
                    <!-- 
                      <mat-form-field style="text-align: center">
                        <input matInput formControlName="groupId"  type="text" class="form-control text-box" id="groupId" aria-describedby="groupId"
                         name="groupId" [placeholder] = "'GROUPID' | translate"
                        autocomplete="off"
                        />
                        <mat-error class="error-message" *ngIf="runAutoshipForm.get('groupId').hasError('required')">{{ 'GROUPIDREQ' | translate }}</mat-error>
                      </mat-form-field> 
                    -->
                      <div class="appmode" *ngIf="!productionMode">
                        <div class="row">
                            <mat-form-field style="width: 100%;">
                                <mat-label>{{'RUNMODE' | translate}}</mat-label>
                                <mat-select formControlName="appmode"  type="text" class="form-control" id="appmode" aria-describedby="appmode"
                                autocomplete="off">
                                <mat-option [value]="0" translate>TESTMODE</mat-option>
                                <mat-option [value]="1" translate>PRODMODE</mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="runAutoshipForm.get('appmode').hasError('required')">{{'REQUESTFIELD' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field style="width: 100%; text-align: center;">
                                <mat-label>{{'CYCLEDAYS' | translate}}</mat-label>
                                <input matInput formControlName="days"  type="number" class="form-control text-box" id="days" aria-describedby="days"
                                name="ayb_pool"
                                autocomplete="off"
                                >
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!runAutoshipForm.valid || isProcessing1" (click)="toRunAutoship()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="autoship-result" *ngIf="processResults && !isProcessing1 && orders.length>0">
                <div class="row resultHeader m-2">
                    <h3>{{'ACCOUNTINGSUBA'  | translate }}&nbsp;{{ 'RESULTS' | translate}}</h3>
                </div>
                <div class="row m-2">
                    <div class="col-6" [ngClass]="processResults.errors>0 ? 'error-result' : ''">{{'ERRORTIMES' | translate}}&nbsp;:&nbsp;{{processResults.errors ? processResults.errors : 0}}</div>
                    <div class="col-6">{{'ORDERS' | translate}}&nbsp;:&nbsp;{{processResults.orders ? processResults.orders.length : 0}}</div>
                </div>
                <div class="table-responsive"  *ngIf="orders && orders.length>0">
                    <h3 class="title">{{'ORDERDETAILS' | translate}}</h3>
                   <table class="table table-bordered">
                       <thead>
                         <tr>
                           <th scope="col">{{'GROUPID' | translate}}</th>
                           <th scope="col">{{'PAYTYPE' | translate}}</th>
                           <th scope="col">{{'TAXAMOUNT' | translate}}</th>
                           <th scope="col">{{'AMOUNT' | translate}}</th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr *ngFor="let order of orders; index as i;">
                           <td title="{{ 'GROUPID' | translate }}">{{ order.newGroupId }}</td>
                           <td title="{{ 'PAYTYPE' | translate }}">{{ (order.payType==1 ? 'EVCOINSLABEL' : 'PAYCREDITCARD') | translate}}</td>
                           <td title="{{ 'TAXAMOUNT' | translate }}">{{ order.tax_amt | currency:''}}</td>
                           <td title="{{ 'AMOUNT' | translate }}">{{ order.billAmt | currency:''}}</td>
                         </tr>
                       </tbody>
                     </table>
                </div>
              </div>
              <!--
              <div class="table-responsive mb-3" *ngIf="orderHead">
                  
                 <h3 class="title">{{'ORDERHEAD' | translate}}</h3>
                  <table class="table table-bordered">
                    <thead>
                        <tr>
                          
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'MEMBERNO' | translate}}</th>
                          <th scope="col">{{'GROUPID' | translate}}</th>
                          <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'DISCOUNTS' | translate}}</th>
                          <th scope="col">{{'TAXES' | translate}}</th>
                          <th scope="col">{{'PAYMENTTYPE' | translate}}</th>
                          <th scope="col">{{'CANCELDATE' | translate}}</th>
                          <th scope="col">{{'CANCELLED' | translate}}</th>
                          <th scope="col">{{'PURCHDATE' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                         
                          <td>{{ orderHead.id }}</td>
                          <td>{{ orderHead.MemberNo }}</td>
                          <td>{{ orderHead.groupID }}</td>
                          <td>{{ orderHead.passedDay }}</td>
                          <td>{{ orderHead.amount }}</td>
                          <td>{{ orderHead.discount }}</td>
                          <td>{{ orderHead.taxes }}</td>
                          <td>{{ orderHead.payType ? ('EVCOINLABEL' | translate) : ('CREDITCARDONLY' | translate)  }}</td>
                          <td>{{ orderHead.cancelDate }}</td>
                          <td>{{ orderHead.cancelFlag }}</td>
                          <td>{{ orderHead.addtime }}</td>
                          <td>
                              <button mat-stroked-button color="primary" class="btn submit-button" *ngIf="orderHead.cancelFlag != 1" [disabled]="isProcessing2" (click)="onCancelOrder()" >
                                  {{ "CANCELBTN" | translate}}
                              </button>
                              <span *ngIf="orderHead.cancelFlag == 1">
                                {{ "CANCELLED" | translate }}
                              </span>
                            </td>
                        </tr>
                      </tbody>
                    </table>
              </div>

              <div class="table-responsive"  *ngIf="orderHead">
                  
                 <h3 class="title">{{'ORDERDETAILS' | translate}}</h3>
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">{{'PURCHDATE' | translate}}</th>
                        <th scope="col">{{'ORDERID' | translate}}</th>
                        <th scope="col">{{'PASSEDDAYS' | translate}}</th>
                        <th scope="col">{{'AMOUNT' | translate}}</th>
                        <th scope="col">{{'PRODUCTITLE' | translate}}</th>
                        <th scope="col">{{'PRODUCTDESC' | translate}}</th>
                        
                        
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let orderDetail of orderDetails; index as i;">
                        <td>{{ orderDetail.addtime }}</td>
                        <td>{{ orderDetail.orderNo }}</td>
                        <td>{{ orderDetail.passedDay }}</td>
                        <td>{{ orderDetail.amount }}</td>
                        <td>{{ orderDetail.pTitle }}</td>
                        <td>{{ orderDetail.pContent }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            -->
            </div>
          </div>
        </div>
    </div>
</div>