//import { Component, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeElementsOptions, StripeCardElementOptions, PaymentIntent, 
  StripeCardElementChangeEvent, StripeAddressElement,Stripe} from '@stripe/stripe-js';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { environment } from 'src/environments/environment';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

//import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';


@Component({
  selector: 'app-byte-stripe',
  templateUrl: './byte-stripe.component.html',
  styleUrls: ['./byte-stripe.component.scss']
})
export class ByteStripeComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Input() amount:number=0.0;
  @Input() currency:string="usd";
  @Input() shippingInfo:any=null;
  @Input() translate : TranslateConfigService;
  @Output() paypalProcessEvent = new EventEmitter<any>();
  paymentElement: StripeCardComponent;//StripePaymentElementComponent;
  paying=false;
  private _unsubscribe$ = new Subject<any>();
// Create the Address Element in shipping mode
   addressElement: StripeAddressElement
   

// Create the Address Element in billing mode

  cardOptions: StripeCardElementOptions = {
    hidePostalCode : true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'auto',

    appearance: {
      theme: 'stripe'
    },  
  };

  paymentForm: FormGroup;
  stripeCardValid: boolean=false;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    //private stripe: Stripe,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
    //this.stripe.elements
    
    this.paymentForm = this.fb.group({
      name: [(this.shippingInfo? (this.shippingInfo.first_name + ' ' + this.shippingInfo.last_name) : 'Victor Guo'), [Validators.required]],
      email: [(this.shippingInfo && this.shippingInfo.email? this.shippingInfo.email : ''),[Validators.required,Validators.email]],
      //amount: [this.amount],
    });

    this.elementsOptions = {
      locale: 'auto',

    };
    //this.pay();
  }

  pay(): void {
    //console.log('pay name:',this.paymentForm.get('name').value);
    //console.log('pay email:',this.paymentForm.get('email').value);
    if (this.paymentForm.valid) {
      this.shareinfoService.setCreditProcess(true);
      this.createPaymentIntent(this.amount)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: this.paymentForm.get('name').value,
                  email: this.paymentForm.get('email').value,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.processEvent({status:'OnError',error : result.error.message});
            console.log(result.error.message);
            this.shareinfoService.setCreditProcess(false);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              //console.log('Successfull result.paymentIntent:',result.paymentIntent);
              this.processEvent({status:'success',info : result.paymentIntent});
              // Show a success message to your customer
            } else {
              this.processEvent({status:'onCancel',info : result.paymentIntent});
              this.shareinfoService.setCreditProcess(false);
            }
          }
          
        },(errorResult) => {
          this.shareinfoService.setCreditProcess(false);
          console.log("System Error",JSON.stringify(errorResult));
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
    } else {
      console.log(this.paymentForm);
    }
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${ environment.url}/create-payment-intent`,
      { amount }
    );
  }

  public processEvent(postData){
    console.log('processEvent postData:',postData)
    this.paypalProcessEvent.emit(JSON.stringify(postData));
  }
  
  onChange(ev: StripeCardElementChangeEvent) {
    this.stripeCardValid=ev.complete;
  }


  get validForm(){
    return this.paymentForm.valid && this.stripeCardValid;
  }

}
