<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="card-body">
        <h3 class="text-center">{{ 'CUSTREVIEWS' | translate }}</h3>
        <div class="d-flex justify-content-center">
            <!-- <button mat-icon-button disableRipple> -->
                <div class="p-2">
                    <mat-icon  class="ml-2 mr-2" style="color:  #fea314;" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"  [matTooltip]="ratingId+1" matTooltipPosition="above">
                        {{showIcon(i)}}
                      </mat-icon>
                </div>
                
              <!-- </button> -->
        </div>
        <p class="text-center">{{ reviews.Score }} {{ 'OUTOF' | translate }} {{ starCount }}</p> 
        <p class="total-review">{{ totalReviews }} {{ 'TOTALREVIEWS' | translate}}</p>
       
       
        <div class="row">
            <div class="col-sm-3 pr-0">
                <p class="star-label">5 {{ 'STARS' | translate }}</p>
            </div>
            <div class="col-sm-7 pl-0">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="(reviews.stars[5] / totalReviews) * 100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-sm-2 pl-0">
                <p class="star-label">{{reviews.stars[5]}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 pr-0">
                <p class="star-label">4 {{ 'STARS' | translate }}</p>
            </div>
            <div class="col-sm-7 pl-0">
                <div class="progress">
                    <div class="progress-bar" role="progressbar"  [style.width.%]="(reviews.stars[4] / totalReviews) * 100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-sm-2 pl-0">
                <p class="star-label">{{reviews.stars[4]}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 pr-0">
                <p class="star-label">3 {{ 'STARS' | translate }}</p>
            </div>
            <div class="col-sm-7 pl-0">
                <div class="progress">
                    <div class="progress-bar" role="progressbar"  [style.width.%]="(reviews.stars[3] / totalReviews) * 100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-sm-2 pl-0">
                <p class="star-label">{{reviews.stars[3]}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 pr-0">
                <p class="star-label">2 {{ 'STARS' | translate }}</p>
            </div>
            <div class="col-sm-7 pl-0">
                <div class="progress">
                    <div class="progress-bar" role="progressbar"  [style.width.%]="(reviews.stars[2] / totalReviews) * 100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-sm-2 pl-0">
                <p class="star-label">{{reviews.stars[2]}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 pr-0">
                <p class="star-label">1 {{ 'STARS' | translate }}</p>
            </div>
            <div class="col-sm-7 pl-0">
                <div class="progress">
                    <div class="progress-bar" role="progressbar"  [style.width.%]="(reviews.stars[1] / totalReviews) * 100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-sm-2 pl-0">
                <p class="star-label">{{reviews.stars[1]}}</p>
            </div>
        </div>
        
        <p class="text-center"><a style="color: blue;text-decoration: underline;cursor: pointer;" (click)="openCustomerReviews()">{{ 'SEECUSTREVIEWS' | translate}}</a></p> 
        
    </div>
</div>



  