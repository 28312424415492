import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlRank, PgRank } from 'src/app/modules/common/PerformanceGrowthBonusSetting';
import { Transaction } from 'src/app/modules/common/Transaction';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-winners-list-record-by-month-pg',
  templateUrl: './winners-list-record-by-month-pg.component.html',
  styleUrls: ['./winners-list-record-by-month-pg.component.scss']
})
export class WinnersListRecordByMonthPgComponent implements OnInit {

  public user: User;
  public pgRanks : PgRank[] = [];
  // public glRanks : GlRank[] = [];
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getPGBrank();
    // this.getGLBrank();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getPGBrank()
  {
    this.evgService.getPGBrank( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.pgRanks = resp.data[0].results;
          //console.log('getPGBrank',this.pgRanks);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  // getGLBrank()
  // {
  //   this.evgService.getGLBrank( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
  //     if (resp) {
  //       if(resp.data[0]){
  //         this.glRanks = resp.data[0].results;
  //         console.log('getGLBrank',this.glRanks);
  //       }
  //     }
  //   },(errorResult) => {
  //     console.log("System Error", errorResult);
  //     this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
  //   }); 
  // }


}
