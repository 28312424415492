<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        
        <div class="d-flex justify-content-center flex-wrap mt-7">
        </div>
        <div class="banner-detail1">{{ 'PETTRACESHTIT' | translate }}</div>
        <div class="separator mt-1 mb-3">
        </div>

        <div class="banner-detail2 mb-3"><h2>{{ 'PETTRACEHTIT' | translate }}</h2></div>
        <div class="banner-detail3 mb-3">{{ 'PETTRACEHTITD' | translate }}</div>
    </div>
    <div class="banner-img">
        <img src="./../../../../assets/images/files/pet-trace/13933-v2_ms.png" alt="">
    </div>

    <div class="row pet-trace-is">
        <div class="col-lg-1 free_area">&nbsp;</div>
        <div #petTraceId class="col-lg-11 pet-detail">
            <div class="first_section">
                <div class="small-title">
                    {{ 'PETTRACEWHATSTIT' | translate }}
                </div>
                <div class="separator mt-1 mb-3"></div>
                <div class="title mb-3"><h2>{{ 'PETTRACEWHATTIT' | translate }}</h2></div>
                <div class="small-title mb-3">{{ 'PETTRACEWHATD' | translate }}</div>
            </div>
        </div>
    </div>

    <div class="row why-c60">
        <div class="col-lg-1">&nbsp;</div>
        <div class="row col-lg-11">
            <div class="col-md-6 col-sm-12">
                <div class="row each-secion">
                    <p class=" topsmall-title" translate>PETTRACEBENEFSTIT</p>
                    <div class="separator mt-1 mb-3"></div>
                    <div class="main-title"><h2>{{ 'PETTRACEBENEFTIT' | translate }}</h2>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">&nbsp;</div>

            <div class="col-md-6 col-sm-12">
                <div class="row each-secion">
                    <p class="why-title" translate>PETTRACEBENEFST1</p>
                    <p class="why-detail" translate>PETTRACEBENEFD1</p>
                    <p class="why-title" translate>PETTRACEBENEFST2</p>
                    <p class="why-detail" translate>PETTRACEBENEFD2</p>
                    <p class="why-title" translate>PETTRACEBENEFST3</p>
                    <p class="why-detail" translate>PETTRACEBENEFD3</p>
                    <p class="why-title" translate>PETTRACEBENEFST4</p>
                    <p class="why-detail" translate>PETTRACEBENEFD4</p>
                    <p class="why-title" translate>PETTRACEBENEFST5</p>
                    <p class="why-detail" translate>PETTRACEBENEFD5</p>

                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="row each-secion">
                    <p class="why-title" translate>PETTRACEBENEFST6</p>
                    <p class="why-detail" translate>PETTRACEBENEFD6</p>
                    <p class="why-title" translate>PETTRACEBENEFST7</p>
                    <p class="why-detail" translate>PETTRACEBENEFD7</p>
                    <p class="why-title" translate>PETTRACEBENEFST8</p>
                    <p class="why-detail" translate>PETTRACEBENEFD8</p>
                    <p class="why-title" translate>PETTRACEBENEFST9</p>
                    <p class="why-detail" translate>PETTRACEBENEFD9</p>
                    <p class="why-title" translate>PETTRACEBENEFST10</p>
                    <p class="why-detail" translate>PETTRACEBENEFD10</p><br>
                    <p class="why-detail mb-1" translate>PETTRACEBENEFCLS</p>
                </div>
            </div>
        </div>
    </div>
</div>

