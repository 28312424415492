import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { User } from '../common/User';

@Component({
  selector: 'app-complete-info',
  templateUrl: './complete-info.component.html',
  styleUrls: ['./complete-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteInfoComponent implements OnInit {

  @Input() translate : TranslateConfigService;
  public user : User;
  private _unsubscribe$ = new Subject<any>();
  public forCompletion = [];

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              this.buildMessages();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  buildMessages(){
    if(this.user){
      if(!this.user.Sex) this.forCompletion.push("Gender");
      if(!this.user.Birthday) this.forCompletion.push("Birthday");
      if(!this.user.City) this.forCompletion.push("City");
      if(!this.user.Province) this.forCompletion.push("Province");
      if(!this.user.Country) this.forCompletion.push("Country");
      if(!this.user.PostCode) this.forCompletion.push("Postal Code");
      if(!this.user.Tel) this.forCompletion.push("Telephone Number");
    }
  }

  onDismiss()
  {
    this.activeModal.close('Close click');
  }

  onUpdateAccount()
  {
    this.router.navigate(["backmain/account-setting"]);
    this.activeModal.close('Close click');
  }
}
