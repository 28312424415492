import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { TranslateConfigService } from './translate-config.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {

  public domain  = 'ByteManage.com'
  constructor(private notifyService : NotificationService, public translate : TranslateConfigService ) { }


  public handleResponse(resp : any)
  {
    if(resp.success){
      if (resp.extramsg) {
        this.notifyService.showSuccess(this.translateMessage(resp.message) + resp.extramsg,this.domain);
      } else {
        this.notifyService.showSuccess(this.translateMessage(resp.message),this.domain);
      }
    }else{
      let errors= resp.data ? resp.data : '';
      let messges='';
      if (errors != '') {
        if (Array.isArray(errors)) {
          if (errors.length==1) {
            errors = errors[0].results ? errors[0].results : errors[0];
          }
        }
        if( typeof(errors) === 'string' ) {
          messges = errors;
        } else {
          if (errors.constructor===({}).constructor) {
            messges= JSON.stringify(errors);
          } else if (Array.isArray(errors) && errors.length>0) {
            messges= JSON.stringify(errors[0]);
          }
        }
      } 
      this.notifyService.showWarning(this.translateMessage(resp.message) + (messges==''? '' : '(' + messges +')'),this.domain);
    } 
  }

  public handleCameraResponseError(error)
  {
    this.notifyService.showError(this.translateMessage(error.message),this.domain);
  }

  public handleCustomResponse(msg, type)
  {
    switch (type) {
      case 'success':
        this.notifyService.showSuccess(this.translateMessage(msg), this.domain);
      break;
      case 'error':
        this.notifyService.showError(this.translateMessage(msg), this.domain);
      break;
      case 'info':
        this.notifyService.showInfo(this.translateMessage(msg), this.domain);
      break;
      case 'warning':
        this.notifyService.showWarning(this.translateMessage(msg), this.domain);
      break;
      default:
        break;
    }
  }

  public translateMessage(key)
  {
    return this.translate.translate.instant(key);
  }
}
