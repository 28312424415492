
<div class="customer-requests-container">
    <div class="row text-right">
      <!-- <button *ngIf="!news && !isCreating" class="ml-auto mb-3 mr-3" mat-stroked-button color="primary" (click)="onCreate()">{{ 'CREATEBTN' | translate }}</button> -->
    </div>
    <mat-accordion *ngIf="!replyMessage && !isCreating">
      <h2>{{ 'CUSTREQUESTS' | translate }}</h2>
      <mat-expansion-panel [ngClass]="!replyMessage.ticketNo ? 'no-ticket' : ''" class="p-3 mb-3" hideToggle *ngFor="let replyMessage of replyMessages; index as i;"  (click)="getRequestDetail(replyMessage.ticketNo)">
        <mat-expansion-panel-header>
          <mat-panel-title class="font-weight-bold">
            {{ replyMessage.topic }}
          </mat-panel-title>
          <mat-panel-description class="m-auto">
              <p>
                {{ 'TICKETNO' | translate }} : <span class="font-weight-bold">{{ replyMessage.ticketNo ?  replyMessage.ticketNo : 'Unavailable' }}</span> <br> {{ 'USER' | translate }} : <span class="font-weight-bold">{{ replyMessage.userId }} </span>
              </p>
              
            <!-- <quill-view [content]="news.content" format="text" theme="snow"></quill-view> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="col-sm-12  customer-requests-preview-container" *ngIf="replyMessage.ticketNo">
          <div class="table-responsive" *ngIf="selectedRequests">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{'FROM' | translate}}</th>
                  <th scope="col">{{'TO' | translate}}</th>
                  <!-- <th scope="col">{{'TITLE' | translate}}</th> -->
                  <th scope="col">{{'CONTENT' | translate}}</th>
                  <th scope="col">{{'ADDTIME' | translate}}</th>
                  <th scope="col">{{'TYPE' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let request of selectedRequests; index as i;" [ngClass]="request.sender == user.id ? 'sent' : 'received'">
                  <td>{{ request.sender == user.id ? 'YOU' : request.sender }}</td>
                  <td>{{ request.receiver == user.id ? 'YOU' : request.receiver }}</td>
                  <!-- <td>{{ request.topic }}</td> -->
                  <td style="width: 60%;"><quill-view [content]="request.content" format="html" theme="snow"></quill-view></td>
                  <td>{{ request.AddTime }}</td>
                  <td>{{ request.sender == user.id ? 'SENT' : 'RECEIVED' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
        <mat-action-row >
          <button mat-stroked-button color="primary" (click)="onReply(i)"  *ngIf="replyMessage.ticketNo">{{ 'REPLYBTN' | translate }}</button>
          <span *ngIf="!replyMessage.ticketNo">Reply is not available for this request.</span>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="replyMessage || isCreating" class="row text-right">
      <button class="ml-auto mr-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
    </div>

    <form *ngIf="replyMessage || isCreating"  [formGroup]="replyMessageForm" (keydown.enter)="$event.preventDefault()" autocomplete="rm-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="topic"  type="text" class="form-control text-box" id="topic" aria-describedby="topic"
                  placeholder="{{ 'TOPICLABEL' | translate }}" name="topic" maxlength="60"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="replyMessageForm.get('topic').hasError('required')">{{ 'TOPICREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-sm-12">
                <h4 class="mb-0">{{ 'CONTENTLABEL' | translate }}</h4>
              </div>
              
              <div class="col-sm-12">
                <quill-editor [placeholder]="'COMPOSENEWSDESC' |translate" formControlName="content">
                </quill-editor>
              </div>
            </div>
            <br>
            <hr>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="postReplyMessage()" class="align-right mr-2">{{ 'SENDBTN' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>
