import { Component, OnInit, Input } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-washer',
  templateUrl: './view-washer.component.html',
  styleUrls: ['./view-washer.component.scss']
})

export class ViewWasherComponent implements OnInit {
  @Input() addCloseBut : boolean=false;
  @Input() translate : TranslateConfigService;

  constructor(
      public activeModal : NgbActiveModal
    ) { }

  ngOnInit(): void {
  }

}
