import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Transaction } from 'src/app/modules/common/Transaction';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-balance-record',
  templateUrl: './balance-record.component.html',
  styleUrls: ['./balance-record.component.scss']
})
export class BalanceRecordComponent implements OnInit {

  public user: User;
  public transactions : Transaction[] = [];
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getEwalletLog();
    this.getRunningBalance();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getBalance()
  {
    this.evgService.getBalance( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          // this.transactions = resp.data[0].results;
          //console.log(resp);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getEwalletLog()
  {
    this.evgService.eWalletlog( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          //console.log("getEwalletLog",resp);
          this.transactions = resp.data[0].results;
          // this.transactions = resp.data[0].results;
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getRunningBalance()
  {
    this.evgService.getRunningBalance( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          console.log(resp.data[0].results);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

}
