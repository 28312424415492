import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { User } from '../common/User';
@Component({
  selector: 'app-secret-question-setting',
  templateUrl: './secret-question-setting.component.html',
  styleUrls: ['./secret-question-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretQuestionSettingComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  public user : User;
  public questions = [];
  public secretQuestionForm: FormGroup;
  public selectedQuestion1 = 0;
  public selectedQuestion2 = 0;
  
  private _unsubscribe$ = new Subject<any>();

  constructor(
    public activeModal : NgbActiveModal, 
    private router: Router,
    public shareinfoService : ShareinfoService,
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    private responseHandlerService : ResponseHandlerService,
    private ngDialog : NgbModal,
    private cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.secretQuestionForm = this.formBuilder.group({
      // secret_question_1: ['', Validators.required],
    });

    this.getUser();
    this.getSecretQuestions();
   
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getSecretQuestions(){
    this.evgService.getSecretQuestions().pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        if(resp.success){
          this.questions = resp.data[0].results;
          //console.log("getSecretQuestions", this.questions);
          this.questions.forEach(question => {
            this.secretQuestionForm.controls[question.id] =  new FormControl('', Validators.required);
          });
          this.secretQuestionForm.markAsPristine();
          this.secretQuestionForm.updateValueAndValidity();
          this.cdr.detectChanges();
          
          this.getUserSecretQuestions();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserSecretQuestions(){
    this.evgService.getUserSecretQuestions({}).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        if(resp.success){
          let user_questions = resp.data[0].results;
          user_questions.forEach(question => {
            this.secretQuestionForm.get(question.sq_id + '').setValue(question.sq_answer);
          });
          this.secretQuestionForm.markAsPristine();
          this.secretQuestionForm.updateValueAndValidity();
          this.cdr.detectChanges();
          
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }



  onSubmit()
  {
    this.secretQuestionForm.updateValueAndValidity();
    if(this.secretQuestionForm.status === "VALID"){
      let data = {
        memberNo : this.user.id,
        secretQA : [
        ]
      }

      this.questions.forEach(question => {
          data.secretQA.push({
            question : question.id,
            answer : this.secretQuestionForm.get(question.id + '').value,
            userId : this.user.id,
          },);
      });
      
      

      this.evgService.secretQuestionUpdate(data)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            //console.log(resp);
            if(resp.success){
                this.activeModal.dismiss('success');
            }
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }
  }

}
