<div class="invoice-link-list-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'UPLOADEDFILES' | translate}}</h3>
              <div class="text-right mb-2">
                <form [formGroup]="requestsForm" (keydown.enter)="$event.preventDefault()" autocomplete="rf-form">
                    <mat-form-field>
                        <!--<mat-label>{{'INFOTYPE' | translate}}</mat-label> -->
                        <mat-select formControlName="infoType"  type="text" class="form-control" id="infoType" name="infoType" aria-describedby="infoType" 
                        autocomplete="off">
                            <mat-option  value="0" selected>{{ 'ACTIVEFILES' | translate}}</mat-option>
                            <mat-option  value="1">{{ 'INACTIVEFILES' | translate}}</mat-option>
                            <mat-option  value="2">{{ 'ALLFILES' | translate}}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="requestsForm.get('infoType').hasError('required')">{{'INFOTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2"  (click)="onGetUploadedDocs()" >{{ "SHOWLIST" | translate}}</button>
                    <button mat-stroked-button color="primary" class="btn btn-default pull-left pt-8 mt-4" (click)="openUploadDoc(null)">{{ 'CREATEBTN' | translate }}</button>
                  </form>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'ID' | translate}}</th>
                          <th scope="col">{{'TITLELABEL' | translate}}</th>
                          <th scope="col">{{'DESCRIPTION' | translate}}</th>
                          <th scope="col">{{'FILENAME' | translate}}</th>
                          <th scope="col">{{'AUTHOR' | translate}}</th>
                          <th scope="col">{{'DOWNLOAD' | translate}} {{'TIMES' | translate}}</th>
                          <th scope="col">{{'DOWNLOAD' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th>
                          <th scope="col">{{'DELETEBTN' | translate}}</th>
                          <th scope="col">{{'COPYLINK' | translate}}</th>
                          <!--
                          <th scope="col">{{'COPYSHARELINK' | translate}}</th>
                        -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of uploadedDocs; index as i;">
                          <td><button mat-stroked-button color="primary" class="btn btn-default pull-left" (click)="openUploadDoc(request)">{{ 'EDITBTN' | translate }}</button></td>
                          <td>{{ request.title }}</td>
                          <td>{{ request.description }}</td>
                          <td>{{ request.file_name }}</td>
                          <td>{{ request.author }}</td>
                          <td>{{ request.times }}</td>
                          <td><a (click)="onDownloadDoc(request)"><i class="bi bi-arrow-down-square-fill"></i></a></td>
                          <td>{{ request.creaded_at }}</td>
                          <td><a (click)="deleteUploadedDoc($event,request)"><i class="bi bi-trash"></i></a></td>
                          <td><a (click)="copyDownloadLink(request)"><i class="bi bi-link"></i></a></td>
                          <!--
                          <td><a (click)="copyDownloadShareLink(request)"><i class="bi bi-share"></i></a></td>
                          -->
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
