<div class="additional-bonus-funds-pool-container">
    <div class="row gutters-sm">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <h3 class="account-title">{{'ADDITIONALBONUSFUP' | translate}}</h3>
            <div class="table-responsive">
              <form  [formGroup]="aySettingForm" (keydown.enter)="$event.preventDefault()" autocomplete="ay-form">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                          <th scope="col">{{'AYBPOOL' | translate}}</th>
                          <th scope="col">{{'AYBNUMS' | translate}}</th>
                          <th scope="col">{{'STATUS' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="aySetting">
                          <td>
                            <mat-form-field style="width: 100%; text-align: center;">
                              <input matInput formControlName="ayb_pool"  type="text" class="form-control text-box" id="ayb_pool" aria-describedby="ayb_pool"
                               name="ayb_pool"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="aySettingForm.get('ayb_pool').hasError('required')">{{'AYBPOOLREQ' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field style="width: 100%; text-align: center">
                              <input matInput formControlName="ayb_nums"  type="text" class="form-control text-box" id="ayb_nums" aria-describedby="ayb_nums"
                               name="ayb_nums"
                              autocomplete="off"
                              >
                              <mat-error class="error-message" *ngIf="aySettingForm.get('ayb_nums').hasError('required')">{{'AYBNUMSREQ' | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>{{ aySetting.actived ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</td>
                          <td><button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="setBonusPool(aySetting)" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button></td>
                      

                        </tr>
                      </tbody>
                  </table>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>