import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/modules/common/Order';
import { TeamOrder } from 'src/app/modules/common/TeamOrder';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-team-new-orders',
  templateUrl: './team-new-orders.component.html',
  styleUrls: ['./team-new-orders.component.scss']
})
export class TeamNewOrdersComponent implements OnInit {

  public user: User;
  public teamNewOrders : TeamOrder[] =  [];
  public sortedTeamNewOrders = [];
  private _unsubscribe$ = new Subject<any>();
  public downlineRanks = [];

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getTeamData();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }


  getTeamNewOrders()
  {
    this.evgService.getTeamNewOrders( {} ).subscribe(resp => {
      if(resp.success){
        this.teamNewOrders = resp.data[0].results;
        this.sortedTeamNewOrders = this.teamNewOrders.slice();
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  sortData(sort: Sort) {
    const data = this.sortedTeamNewOrders.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTeamNewOrders = data;
      return;
    }

    this.sortedTeamNewOrders = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.sharedUtilService.compare(a.id, b.id, isAsc);
        case 'first_name': return this.sharedUtilService.compare(a.first_name, b.first_name, isAsc);
        case 'last_name': return this.sharedUtilService.compare(a.last_name, b.last_name, isAsc);
        case 'orders': return this.sharedUtilService.compare(a.orders, b.orders, isAsc);
        case 'orderAmt': return this.sharedUtilService.compare(a.orderAmt, b.orderAmt, isAsc);
        default: return 0;
      }
    });
  }

  getTeamData()
  {

    this.evgService.getTeam([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0]){
            if(resp.data[0].teamtree){
              this.downlineRanks = resp.data[0].teamtree;
              if(resp.data[0].teamtree.length > 0){
                const result = this.downlineRanks.filter(data => data.id != this.user.id);
                var tree = this.sharedUtilService.unflatten(this.sharedUtilService.mapTeamData(this.user,result));
                this.downlineRanks = [];
                this.getTreeChildren(tree[0]);
                this.getTeamNewOrders();
              }else{
                this.downlineRanks = [];
              }
            }
    

          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  getTreeChildren(node)
  {
    if(node['childs'].length > 0){
      for (const child of node.childs) {
        this.downlineRanks.push(child.id)
        this.getTreeChildren(child);
      }
    }else{
      return true;
    }
  }

}
