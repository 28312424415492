import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order, OrderDetail } from 'src/app/modules/common/Order';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-personal-new-orders',
  templateUrl: './personal-new-orders.component.html',
  styleUrls: ['./personal-new-orders.component.scss']
})
export class PersonalNewOrdersComponent implements OnInit {


  public user: User;
  public newOrders : Order[] = [];
  public selectedOrderDetails : OrderDetail[] = null;
  public selectedOrder : string = null;
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
     this.getUser();
     this.getNewOrders();

  }

  selectOrder(groupId){
    this.getOrderDetail(groupId);
  }

  unSelectOrder()
  {
    this.selectedOrder = null;
    this.selectedOrderDetails = null;
    this.getNewOrders();
  }



  getOrderDetail(groupId)
  {
    //console.log(groupId);
    this.evgService.getOrderDetail( {groupId : groupId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.selectedOrderDetails = resp.data[0].results;
          //console.log("getOrderDetail",this.selectedOrderDetails );
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getNewOrders()
  {
    this.evgService.findNewOrderGroups( {} ).subscribe(resp => {
      if(resp.success){
        this.newOrders = resp.data[0].results;
        //console.log("getNewOrders",this.newOrders );
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

 

}
