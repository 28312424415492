<div class="current-balance-container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form  #transferDistrib="ngForm" [formGroup]="transferDistributorFrom" (keydown.enter)="$event.preventDefault()" autocomplete="td-form">
                        
                        <div class="p-3">
                            <h2>{{ 'TRANSFERTODISTRIB' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div style="font-size: 1rem;background-color: lightslategray;">{{ 'CURRENTBALANCE' | translate }}</div>
                                <app-show-bytetoken [amount]="user.totalCash"></app-show-bytetoken>
                                <!--
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="current_balance_1" [value]="'$ ' + user.totalCash"
                                name="current_balance"  placeholder="{{ 'CURRENTBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
                                -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="toUserId"  type="text" class="form-control text-box" id="toUserId"
                                name="toUserId"  placeholder="{{ 'TOUSERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('toUserId').hasError('required')">{{'TOUSERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 my-1">
                            <mat-form-field style="width: 100%;">
                                <mat-hint>{{ 'IDSTRINGCODEHINT' | translate }}</mat-hint>
                                <input matInput formControlName="stringId"  type="text" class="form-control text-box" id="stringId" 
                                name="stringId" placeholder="{{ 'IDSTRINGCODE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('stringId').hasError('required')">{{'IDSTRINGCODEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount"
                                name="amount" placeholder="{{ 'AMOUNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('amount').hasError('min')">{{'AMOUNTMIN50' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="email"  type="text" class="form-control text-box" id="email"
                                name="email" placeholder="{{ 'REMAIL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 my-1">
                            <mat-form-field style="width: 100%; height: fit-content;">
                                <mat-hint>{{ 'TRADEPASSWORDHINT' | translate }}</mat-hint>
                                <input matInput formControlName="tradePassword"  type="password" class="form-control text-box" id="tradePassword"
                                name="tradePassword" placeholder="{{ 'TRADEPASSWORD' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('tradePassword').hasError('required')">{{'TRADEPASSWORDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group my-1">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'TRANSFERQUESTION' | translate}}&nbsp;({{'MINLENGTHLONG' | translate}}3&nbsp;{{'CHARACTERS' | translate}})</mat-label>
                                    <mat-hint>{{ 'TRANSFERQUESHINT' | translate }}&nbsp;({{'MINLENGTHLONG' | translate}}3&nbsp;{{'CHARACTERS' | translate}})</mat-hint>
                                    <input matInput  formControlName="question" type="text" class="form-control" id="question" 
                                    placeholder="{{ 'TRANSFERQUESTION' | translate }}" name="question" maxlength="60" minlength="3"
                                    title = "{{ 'TRANSFERQUESTION' | translate }}" required autocomplete="off" >
                                    <mat-error class="error-message" *ngIf="transferDistributorFrom.get('question').hasError('required')">{{'SECURITYQUESREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'TRANSFERANSWER' | translate}}&nbsp;({{'MINLENGTHLONG' | translate}}3&nbsp;{{'CHARACTERS' | translate}})</mat-label>
                                    <mat-hint>{{ 'TRANSFERANSHINT' | translate }}&nbsp;({{'MINLENGTHLONG' | translate}}3&nbsp;{{'CHARACTERS' | translate}})</mat-hint>
                                    <input matInput  formControlName="answer" type="text" class="form-control" id="answer" 
                                    placeholder="{{ 'TRANSFERANSWER' | translate }}" name="answer" maxlength="60" minlength="3"
                                    title = "{{ 'TRANSFERANSWER' | translate }}" required autocomplete="off" >
                                    <mat-error class="error-message" *ngIf="transferDistributorFrom.get('answer').hasError('required')">{{'SECURITYANSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!transferDistributorFrom.valid " (click)="transferToDistributor(transferDistrib)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form #transferEwallet="ngForm"  [formGroup]="transferEwalletForm" (keydown.enter)="$event.preventDefault()" autocomplete="tw-form">
                        
                        <div class="p-3">
                            <h2>{{ 'TRANSFERTOEWALLET' | translate}}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div style="font-size: 1rem;background-color: lightslategray;">{{ 'CURRENTBALANCE' | translate }}</div>
                                <app-show-bytetoken [amount]="user.totalCash"></app-show-bytetoken>
<!--
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="current_balance_2" [value]="'$ ' + user.totalCash"
                                name="current_balance"  placeholder="{{ 'CURRENTBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
-->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div style="font-size: 1rem;background-color: lightslategray;">{{ 'EWALLETBALANCE' | translate }}</div>
                                <app-show-bytetoken [amount]="user.eWallet"></app-show-bytetoken>
<!----
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="ewallet_balance" [value]="'$ ' + user.eWallet"
                                name="ewallet_balance"  placeholder="{{ 'EWALLETBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
-->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="text" class="form-control text-box" id="transfer_amount"
                                name="transfer_amount"  placeholder="{{ 'TRANSFERAMNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferEwalletForm.get('amount').hasError('required')">{{'TRANSFERAMNTREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!transferEwalletForm.valid " (click)="transferToEwallet(transferEwallet)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
              
            </div>
        </div>
    </div>
     
</div>