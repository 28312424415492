import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { SelectInputDialogComponent } from 'src/app/modules/select-input-dialog/select-input-dialog.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-system-maintenance',
  templateUrl: './system-maintenance.component.html',
  styleUrls: ['./system-maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMaintenanceComponent implements OnInit {

  public user: User;
  private _unsubscribe$ = new Subject<any>();
  public systemMaintenanceForm : FormGroup;
  public isProcessing = false;
  public maintenanceInfo : any;

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private cdr : ChangeDetectorRef,
    private sharedUtilService : SharedUtilService

  ) { }

  ngOnInit(): void {
    this.initForms();
    this.getUser();
    this.getMaintenanceInfo();

  }

  initForms()
  {
    this.systemMaintenanceForm = this.formBuilder.group({
      m_flag: ['', [Validators.required]],
      message: ['', [Validators.required]],
      from_date: ['', [Validators.required]],
      from_time : ['', [Validators.required]],
      to_date: ['', [Validators.required]],
      to_time : ['', [Validators.required]],
    });
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMaintenanceInfo()
  {
    this.evgService.getMaintainInfo({}).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.maintenanceInfo = resp.data[0].results;
          this.cdr.detectChanges();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  postMaintenance(systemMaintain)
  {
    if(this.systemMaintenanceForm.valid){
        var data;
        data = {
          m_flag : this.systemMaintenanceForm.value.m_flag,
          message : this.systemMaintenanceForm.value.message,
          from_date : this.systemMaintenanceForm.value.from_date + " " + this.systemMaintenanceForm.value.from_time,
          to_date : this.systemMaintenanceForm.value.to_date + " " + this.systemMaintenanceForm.value.to_time,
          actived : 1,
        }
        this.evgService.postMaintainInfo(data).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.success){
                this.sharedUtilService.resetForm(this.systemMaintenanceForm,systemMaintain);
              }
              this.getMaintenanceInfo();
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }); 
      
     }
       
  }

}
