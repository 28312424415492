import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  fm_lang_key:string = "fm_lang_key"; 
  langus = ['en','chJ','sp','ab','pe'];
  langList = [
    {"code" : "en", "name" : "English"},
    {"code" : "chJ" , "name" : "中文(简)"},
    {"code" : "chT" , "name" : "中文(繁)"},
    {"code" : "sp", "name" : "Español"},
    {"code" : "ab", "name" : "عربى"},
    {"code" : "pe" , "name" : "فارسی"},
  ]
  langset:string;

  constructor(public translate : TranslateService) {
    this.langset = localStorage.getItem(this.fm_lang_key);
    if (this.langset === undefined || this.langset === null || this.langset === '') {
      this.langset='en';
    }
    if ( this.langus.indexOf(this.langset) < 0) {
      //console.log('in this.langus in TranslateConfigService ! ');
      translate.setDefaultLang(this.langset);
    }
    translate.use(this.langset);
  }
  switchLang(lang: string) {
    this.translate.use(lang);
    this.langset = lang;
    localStorage.setItem(this.fm_lang_key,lang);
  }
  getLangs() {
    //console.log('getLangs from translate');
    return this.translate.getLangs();
  }
  getById(id) : string {
    return this.translate.get(id) +'';
  }
  getCurLang() {
    return this.langset;
  }
  getLangList() {
    return this.langList;
  }
}
