<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close('Close click')" alt="close"></span>
        </div>
    </div>
    <div class="row m-1">
        <form [formGroup]="checkoutForm" (keydown.enter)="$event.preventDefault()" class="checkout-form" autocomplete="c-form" id="checkoutFormId">
            <h2 ngbAutofocus class="text-center">{{ "CART" | translate }} </h2>

            <div class="cart-items">
                <div class="table-responsive" id="no-more-tables">
                    <table class="table bg-white">
                        <thead class="bg-secondary text-light">
                          <tr class="font-weight-bold">
                            <th scope="col">#</th>
                            <th scope="col">{{ 'PRODUCTNAME' | translate }}</th>
                            <th scope="col">{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})</th>
                            <th scope="col">{{ 'QTY' | translate }}</th>
                            <th scope="col">{{ 'PRODUCTAWARD' | translate }}</th> <!-- AUTOSHIP added in May.14,2023-->
                            <th scope="col">{{ 'SKUNUMBER' | translate }}</th>
                            <th scope="col">{{ 'ACTION' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let cart_item of cart_items; index as i;">
                            <td title="#" class="d-flex justify-content-center text-center"><input class="form-group form-check-input" type="checkbox" value=""  [checked]="cart_item.selected" (change)="onSelected(i,$event)"></td>
                            <td title="{{ 'PRODUCTNAME' | translate }}">{{ cart_item.pTitle }}</td>
                            <td title="{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})">{{ (cart_item.unitPrice + (cart_item.apply_benefit==1 ? cart_item.benefit_value : 0)) | currency:'' }}</td>
                            <td title="{{ 'QTY' | translate }}">{{ cart_item.nums }}</td>
                            <td title="{{ 'PRODUCTAWARD' | translate }}">
                                <div *ngIf="cart_item.has_benefit==1">
                                    <div class="text-wrap">
                                        <mat-checkbox
                                            class="checbox-margin"
                                            [(ngModel)]="cart_item.apply_benefit"
                                            labelPosition="after"
                                            (change)="applyBenefit_click($event,i)"
                                            [ngModelOptions]="{standalone: true}"
                                            >
                                            {{cart_item.benefit_name}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div *ngIf="cart_item.autoship==1">
                                      <div style="width: 98%;">
                                        <mat-checkbox
                                            class="checbox-margin"
                                            [(ngModel)]="cart_item.termFlag"
                                            labelPosition="after"
                                            (change)="changeTermFlagEvent($event,i)"
                                            [ngModelOptions]="{standalone: true}"
                                            >
                                            {{ 'AUTOSHIP' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <div style="width:98%;overflow:visible; text-wrap:wrap;font-size: 0.6rem;">
                                        {{'ATNOTICE' | translate}}
                                    </div>

                                </div>
                                <div *ngIf="!(cart_item.autoship==1 || cart_item.has_benefit==1)" translate>UNAVAILABLE</div>
                            </td>
                            <td title="{{ 'SKUNUMBER' | translate }}">{{ cart_item.SKU }}</td>
                            <td title="{{ 'ACTION' | translate }}">
                                
                                <button class="cart-button mr-1"  mat-mini-fab color="accent" aria-label="Plus" (click)="addItem(i)" >
                                    <mat-icon class="cart-icon">add</mat-icon>
                                </button>
                                <button class="cart-button mr-1" mat-mini-fab color="warn" aria-label="Minus" (click)="removeItem(i)" [disabled]="cart_item.nums <= 1 ? true : false">
                                    <mat-icon class="cart-icon">remove</mat-icon>
                                </button>
                                <button class="cart-button mr-1" mat-mini-fab color="primary" aria-label="Delete" (click)="deleteItem(i)">
                                    <mat-icon class="cart-icon">delete</mat-icon>
                                </button>
                            </td>
                          </tr>
                          <tr *ngIf="cart_items.length == 0">
                              <td colspan="5" class="text-center">
                                  <h3>
                                    {{ 'PLEASEADDITEM' | translate }} <br><a class="link" style="cursor: pointer;" (click)="openPage('backmain/purchase')">{{ 'GOTOPRODUCT' | translate }}</a>
                                  </h3>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive" id="more-tables">
                    <table class="table bg-white">
                        <tbody>
                            <tr>
                                <td class="font-weight-bold align-items-center" colspan="4"><div>{{ 'COUPON' | translate }}</div></td>
                                <td class="text-right font-weight-bold" >
                                        <input type="text" class="form-control" id="coupon" aria-describedby="coupon" [(ngModel)]="coupon" [ngModelOptions]="{standalone: true}"
                                        autocomplete="off">
                                </td>
                                <td class="d-flex justify-content-end align-items-center" colspan="2">
                                    <div>
                                        <a mat-stroked-button color="primary" class="btn" (click)="applyCoupon()">{{ "APPLY" | translate}}</a>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                              <td class="font-weight-bold" colspan="4">{{ 'SELECTEDITEMS' | translate }} : {{ cart_item_selected }} </td>
                              <td class="text-right font-weight-bold" >{{ 'SUBTOTAL' | translate }}</td>
                              <td class="font-weight-bold  text-right" colspan="2">{{ cart_subtotal | currency:'' }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold" colspan="4"></td>
                            <td class="text-right font-weight-bold" >{{ 'DISCOUNTS' | translate }} {{ appliedCoupon ? (appliedCoupon.coupon_type == 0 ? ('AMOUNT' | translate) : '%') : '' }}</td>
                            <td class="font-weight-bold  text-right" colspan="2">- {{ (cart_discounts + common_discount + autoship_discount) | currency:'' }}</td>
                           
                          </tr>
                          <tr *ngIf="cart_tax_rate">
                            <td class="font-weight-bold" colspan="4"></td>
                            <td class="text-right font-weight-bold" >{{ 'TAX' | translate }} {{ '(' + cart_tax_rate.tax_name + ' ' + (cart_tax_rate.tax_rate | percent:'0.0-3') +')'}}</td>
                            <td class="font-weight-bold  text-right" colspan="2">+ {{ cart_tax_1 | currency:'' }}</td>
                          </tr>
                          <ng-container *ngIf="cart_tax_rate">
                            <tr *ngIf="cart_tax_rate.tax_name2">
                                <td class="font-weight-bold" colspan="4"></td>
                                <td class="text-right font-weight-bold" >{{ 'TAX' | translate }} {{ '(' + cart_tax_rate.tax_name2 + ' ' + (cart_tax_rate.tax_rate2 | percent:'0.0-3') +')'}}</td>
                                <td class="font-weight-bold  text-right" colspan="2">+ {{ cart_tax_2 | currency:'' }}</td>
                             
                              </tr>
                          </ng-container>
                          <tr>
                            <td class="font-weight-bold" colspan="4"></td>
                            <td class="text-right font-weight-bold" >{{ 'TOTAL' | translate }}&nbsp;({{'CURRENCYUNIT' | translate}})</td>
                            <td class="font-weight-bold text-right" colspan="2">{{ cart_total | currency:'' }}</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 form-popup-form">
                <div style="text-align: center; margin: auto;">
                  <button mat-raised-button color="warn" class="btn submit-button mr-3"  (click)="closeView()" >{{ "CLOSEBTN" | translate}}</button>
                  <button mat-raised-button color="primary" class="btn submit-button"  (click)="finishSteps()" [disabled]="!checkoutForm.valid" >{{ "SUBMIT" | translate}}</button>
                </div>
            </div>
        </form>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>
