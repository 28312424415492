<div class="ewallet-balance-container">
    <div class="row">
        <!-- <div class="col-lg-6">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #transferDistrib="ngForm"  [formGroup]="transferDistributorFrom" (keydown.enter)="$event.preventDefault()" autocomplete="td-form">
                        
                        <div class="p-3">
                            <h2 class="title">{{ 'TRANSFERTODISTRIB' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="current_balance_1" [value]="'$ ' + user.totalCash"
                                name="current_balance"  placeholder="{{ 'CURRENTBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="toUserId"  type="text" class="form-control text-box" id="toUserId"
                                name="toUserId"  placeholder="{{ 'TOUSERID' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('toUserId').hasError('required')">{{'TOUSERIDREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="stringId"  type="text" class="form-control text-box" id="stringId" 
                                name="stringId" placeholder="{{ 'IDSTRINGCODE' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('stringId').hasError('required')">{{'IDSTRINGCODEREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount"
                                name="amount" placeholder="{{ 'AMOUNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('amount').hasError('min')">{{'AMOUNTMIN1' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('amount').hasError('max')">{{'AMOUNTMAX' | translate}} {{ user.totalCash | currency}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="email"  type="text" class="form-control text-box" id="email"
                                name="email" placeholder="{{ 'EMAIL' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferDistributorFrom.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'SECURITYQUES' | translate}}</mat-label>
                                    <input matInput  formControlName="question" type="text" class="form-control" id="question" 
                                    placeholder="{{ 'SECURITYQUES' | translate }}" name="question" maxlength="60"
                                    title = "{{ 'SECURITYQUES' | translate }}" required autocomplete="off" >
                                    <mat-error class="error-message" *ngIf="transferDistributorFrom.get('question').hasError('required')">{{'SECURITYQUESREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'SECURITYANS' | translate}}</mat-label>
                                    <input matInput  formControlName="answer" type="text" class="form-control" id="answer" 
                                    placeholder="{{ 'SECURITYANS' | translate }}" name="answer" maxlength="60"
                                    title = "{{ 'SECURITYANS' | translate }}" required autocomplete="off" >
                                    <mat-error class="error-message" *ngIf="transferDistributorFrom.get('answer').hasError('required')">{{'SECURITYANSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!transferDistributorFrom.valid " (click)="transferToDistributor(transferDistrib)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div> -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form #transferEwallet="ngForm" [formGroup]="transferEwalletForm" (keydown.enter)="$event.preventDefault()" autocomplete="te-form">
                        
                        <div class="p-3">
                            <h2 class="title">{{ 'TRANSFERTOEWALLET' | translate}}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="current_balance_2" [value]="'$ ' + user.totalCash"
                                name="current_balance"  placeholder="{{ 'CURRENTBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput  type="text" class="form-control text-box" id="ewallet_balance" [value]="'$ ' + user.eWallet"
                                name="ewallet_balance"  placeholder="{{ 'EWALLETBALANCE' | translate }}" disabled
                                autocomplete="off"
                                >
                            </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="transfer_amount"
                                name="transfer_amount"  placeholder="{{ 'TRANSFERAMNT' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="transferEwalletForm.get('amount').hasError('required')">{{'TRANSFERAMNTREQ' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferEwalletForm.get('amount').hasError('min')">{{'AMOUNTMIN1' | translate}}</mat-error>
                                <mat-error class="error-message" *ngIf="transferEwalletForm.get('amount').hasError('max')">{{'AMOUNTMAX' | translate}} {{ user.totalCash | currency}}</mat-error>
                            </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!transferEwalletForm.valid " (click)="transferToEwallet(transferEwallet)" >{{ "TRANSFERLABEL" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
              
            </div>
        </div>
    </div>
</div>