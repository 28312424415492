<div class="send-link-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <form  #changeRank="ngForm" [formGroup]="invoiceLinkFrom" (keydown.enter)="$event.preventDefault()" autocomplete="cr-form">
                        
                        <div class="p-3">
                            <h2>{{ 'WPGATEWAY' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <mat-label>{{'TO' | translate}}</mat-label>
                                    <mat-select 
                                    formControlName="customer_id"  type="text" class="form-control" id="customer_id"  placeholder="{{ 'TO' | translate }}" 
                                    aria-describedby="customer_id" 
                                    (selectionChange)="onSelecWholesaletEvent($event.value)"
                                    [disabled] = "isPaid"
                                     autocomplete="off">
                                        <mat-option *ngFor="let ws of wholesales; index as i;" [value]="ws.id">{{ws.customer}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('customer_id').hasError('required')">{{'TO' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="email"  type="email" class="form-control text-box" id="email"
                                    name="email"  placeholder="{{ 'EMAIL' | translate }}"  autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('email').hasError('required')">{{'EMAILREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <mat-label>{{'PRODUCT' | translate}}</mat-label>
                                    <mat-select formControlName="pId"  type="text" class="form-control" id="pId"  placeholder="{{ 'PRODUCT' | translate }}" aria-describedby="pId"
                                    (selectionChange)="onSelecProductEvent($event.value)" 
                                    [disabled] = "isPaid"
                                    autocomplete="off">
                                        <mat-option *ngFor="let pd of products; index as i;" [value]="pd.pId">{{pd.pTitle}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('pId').hasError('required')">{{'PRODUCT' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="quantity"  type="text" class="form-control text-box" id="quantity" 
                                    name="quantity" placeholder="{{ 'QUANTITY' | translate }}" 
                                    (ngModelChange)="quantityChange($event)"
                                    [disabled] = "isPaid"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('quantity').hasError('required')">{{'QUANTITY' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="unit_price"  type="text" class="form-control text-box" id="unit_price" 
                                    name="unit_price" placeholder="{{ 'UNITPRICE' | translate }}"
                                    (ngModelChange)="priceChange($event)" 
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('unit_price').hasError('required')">{{'UNITPRICE' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="tax_gst"  type="text" class="form-control text-box" id="tax_gst" 
                                    name="tax_gst" placeholder="{{ 'TAXGST' | translate }}" 
                                    autocomplete="off" 
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('tax_gst').hasError('required')">{{'TAXGST' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="tax_pst"  type="text" class="form-control text-box" id="tax_pst" 
                                    name="tax_pst" placeholder="{{ 'TAXPST' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('tax_gst').hasError('required')">{{'TAXPST' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field >
                                    <input matInput formControlName="amount"  type="text" class="form-control text-box" id="amount" 
                                    name="amount" placeholder="{{ 'AMOUNT' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="invoiceLinkFrom.get('tax_gst').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="isProcessing"  (click)="activeModal.close('close')" >{{ "CLOSEBTN" | translate}}</button>
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!invoiceLinkFrom.valid || isProcessing || isPaid" (click)="updateInvoiceLink(changeRank)" >{{ "SENDINVOICE" | translate}}</button>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3"></div>
    </div>
</div>