import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-terms',
  templateUrl: './user-terms.component.html',
  styleUrls: ['./user-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTermsComponent implements OnInit {

  public complete_read : boolean = false;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  
  constructor(
    public activeModal : NgbActiveModal, 
    private ngDialog : NgbModal,
  ) { 

  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.scrollToTop();
  }

  public onScroll() {
    let element = this.myScrollContainer.nativeElement;
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
    if (this.complete_read && atBottom) {
        this.complete_read = false
    } else {
        this.complete_read = true
    }
  }

  public scrollToTop(): void {
    if (this.complete_read) {
        return
    }
    try {
        this.myScrollContainer.nativeElement.scrollTop = 0;
    } catch(err) { }
  }

}
