import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-transfer-to-current-balance',
  templateUrl: './transfer-to-current-balance.component.html',
  styleUrls: ['./transfer-to-current-balance.component.scss']
})
export class TransferToCurrentBalanceComponent implements OnInit {

  public transferCurrentBalanceForm: FormGroup;
  public user = new User();
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.transferCurrentBalanceForm = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      withdraw_amount: ['', [Validators.required, Validators.min(1)]],
    });

    this.getUser();
    
  }

  public getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.transferCurrentBalanceForm.get('withdraw_amount').setValidators([Validators.required, Validators.min(1),Validators.max(this.user.eWallet)]);
            this.transferCurrentBalanceForm.updateValueAndValidity();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public transferToCurrentBalance(formDirective)
  {
    if(confirm(this.translate.translate.instant('EWALLETSUB5') + '?')) {
      
      if(this.user.eWallet > 0){
        let data = this.transferCurrentBalanceForm.value;
        data.transType = 1;
        this.evgService.transferToCurrentBalance(data).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              // this.questions = resp.data[0].results;
              this.shareinfoService.reloadUser();
              this.sharedUtilService.resetForm(this.transferCurrentBalanceForm,formDirective);
            }
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }
      
    }
  }

}
