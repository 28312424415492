import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/modules/common/Order';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-cancel-user-order',
  templateUrl: './cancel-user-order.component.html',
  styleUrls: ['./cancel-user-order.component.scss']
})
export class CancelUserOrderComponent implements OnInit {

  public user: User;
  private _unsubscribe$ = new Subject<any>();
  public searchOrderForm : FormGroup;
  public orderHead : any;
  public orderDetails : any;
  public isProcessing1 = false;
  public isProcessing2 = false;
  public groupIDs : any[] = [];
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.searchOrderForm = this.formBuilder.group({
      groupId: ['', [Validators.required]],
    });
    this.getUser();
    this.getCancelGroupIds();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getCancelGroupIds(){
    this.evgService.getCancelGroupIds({})
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            let orders = resp.data[0].results

            orders.forEach(order => {
                this.groupIDs.push({user : 'Ticket : ' + order.ticketNo ,groupID : order.groupId});
            });
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }


  onSearchOrder()
  {
    if(this.searchOrderForm.status == "VALID"){
        
        this.getOrderHead(this.searchOrderForm.get('groupId').value);
        this.getOrderDetail(this.searchOrderForm.get('groupId').value);
        
      
    }
  }

  getOrderHead(groupId)
  {
    this.isProcessing1 = true;
    this.evgService.getOrderHead( {groupId : groupId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.orderHead = resp.data[0].results[0];
          this.isProcessing1 = false;
        }else{
          this.isProcessing1 = false;
        }
      }else{
        this.isProcessing1 = false;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getOrderDetail(groupId)
  {
    this.evgService.getOrderDetail({groupId : groupId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.orderDetails = resp.data[0].results;
          this.isProcessing1 = false;
        }else{
          this.isProcessing1 = false;
        }
      }else{
        this.isProcessing1 = false;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  onCancelOrder(){
    if(confirm("Are you sure you want to cancel this order?")){
      if(this.orderHead){
        this.isProcessing2 = true;
        this.shareinfoService.setCreditProcess(true);
        this.evgService.cancelOrder({groupId : this.orderHead.groupID} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            if(resp.success){
              this.onSearchOrder();
              this.isProcessing2 = false;
            }else{
              this.isProcessing2 = false;
            }
          }else{
            this.isProcessing2 = false;
          }
          this.shareinfoService.setCreditProcess(false);
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.shareinfoService.setCreditProcess(false);
          this.isProcessing2 = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
      
    }
  }
  

}
