
<div class="message-to-customer-container">
    <div class="row text-right">
      <button *ngIf="!adminMessage && !isCreatingMessage" class="ml-auto mb-3 mr-3" mat-stroked-button color="primary" (click)="onCreate()">{{ 'SENDMSGUSER' | translate }}</button>
    </div>
    <mat-accordion *ngIf="!adminMessage && !isCreatingMessage">
      <h2>{{ 'MSGSFROMUSERS' | translate }}</h2>
      <mat-expansion-panel hideToggle *ngFor="let message of adminMessages; index as i;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ message.topic }}
          </mat-panel-title>
          <mat-panel-description>
            <!-- <quill-view [content]="news.content" format="text" theme="snow"></quill-view> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="col-sm-12  admin-message-preview-container">
          <quill-view [content]="message.content" format="html" theme="snow"></quill-view>
        </div>
        <mat-action-row>
          <!-- <button mat-stroked-button color="error">{{ 'DELETEBTN' | translate }}</button> -->
          <button mat-stroked-button color="primary" (click)="onEdit(i)">{{ 'EDITBTN' | translate }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="adminMessage || isCreatingMessage" class="row text-right">
      <button class="ml-auto mr-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
    </div>

    <form *ngIf="adminMessage || isCreatingMessage"  [formGroup]="adminMessageForm" (keydown.enter)="$event.preventDefault()" autocomplete="am-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="topic"  type="text" class="form-control text-box" id="topic" aria-describedby="topic"
                  placeholder="{{ 'TOPICLABEL' | translate }}" name="topic" maxlength="60"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="adminMessageForm.get('topic').hasError('required')">{{ 'TOPICREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12" *ngIf="!adminMessage">
                <mat-form-field style="width: 100%;">
                  <input matInput  type="number" class="form-control text-box" id="toUserId" aria-describedby="toUserId"
                  placeholder="{{ 'TOUSERID' | translate }}" name="toUserId" [(ngModel)]="toUserId" [ngModelOptions]="{standalone: true}"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="!toUserId">{{ 'TOUSERIDREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-sm-12">
                <h4 class="mb-0">{{ 'CONTENTLABEL' | translate }}</h4>
              </div>
              
              <div class="col-sm-12">
                <quill-editor [placeholder]="'COMPOSEMESSAGEDESC' |translate" formControlName="content">
                </quill-editor>
              </div>
            </div>
            <br>
            <hr>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="isProcessing1" *ngIf="!adminMessage" (click)="postAdminMessage()" class="align-right mr-2">{{ 'SENDBTN' | translate }}</button>
            <button mat-stroked-button color="primary" [disabled]="isProcessing2" *ngIf="adminMessage" (click)="modifyAdminMessage()" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>