<div class="card p-3">
    <div class="card-body text-center">
        <h1 mat-dialog-title class="p-2 font-weight-bold">{{ 'CONFIRMBANKDETAILS' | translate }}</h1>

        <table class="table table-sm table-borderless text-left">
            <thead>
              <tr>
                <th scope="col" translate>FIELD</th>
                <th scope="col" translate>VALUE</th>
              </tr>
            </thead>
            <tbody *ngIf="data.withdrawal_type==0">
              <tr>
                <th scope="row" translate>WITHDRAWAMOUNT</th>
                <td>${{ data.withdraw_amount }}</td>
              </tr>
              <tr>
                <th scope="row" translate>TRANSFERFEE</th>
                <td>${{ data.transferFee }}</td>
              </tr>
              <tr>
                <th scope="row" translate>WITHDRAWALTYPE</th>
                <td>SWIFT</td>
              </tr>
              <tr>
                <th scope="row" translate>FULLNAMEOFACCOUNTHOLDER</th>
                <td>{{ data.full_name_of_account_holder }}</td>
              </tr>
              <tr>
                <th scope="row" translate>SWIFTABACODE</th>
                <td>{{ data.swift_aba_code }}</td>
              </tr>
              <tr>
                <th scope="row" translate>INSNUMBER</th>
                <td>{{ data.institution_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BRANCHNUMBER</th>
                <td>{{ data.branch_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>ACCOUNTNUMBER</th>
                <td>{{ data.account_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BANKNAME</th>
                <td>{{ data.bank_name }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BANKADDRESS</th>
                <td>{{ data.bank_address }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERNAME</th>
                <td>{{ data.receiver_name }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERADDRESS</th>
                <td>{{ data.receiver_address }}</td>
              </tr>
              <!----
              <tr>
                <th scope="row" translate>RECEIVERCITY</th>
                <td>{{ data.receiver_city }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERPROVINCESTATE</th>
                <td>{{ data.receiver_province }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERCOUNTRY</th>
                <td>{{ data.receiver_country }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERPOSTCODE</th>
                <td>{{ data.receiver_postcode }}</td>
              </tr>
            -->
              <tr *ngIf="data.account_type==0">
                <th scope="row" translate>ACCOUNTTYPE</th>
                <td translate>CHECKING</td>
              </tr>
              <tr *ngIf="data.account_type==1">
                <th scope="row" translate>ACCOUNTTYPE</th>
                <td translate>SAVINGS</td>
              </tr>
            </tbody>
            <tbody *ngIf="data.withdrawal_type==1">
              <tr>
                <th scope="row" translate>WITHDRAWAMOUNT</th>
                <td>${{ data.withdraw_amount }}</td>
              </tr>
              <tr>
                <th scope="row" translate>TRANSFERFEE</th>
                <td>${{ data.transferFee }}</td>
              </tr>
              <tr>
                <th scope="row" translate>WITHDRAWALTYPE</th>
                <td>ACH</td>
              </tr>
              <tr>
                <th scope="row" translate>FULLNAMEOFACCOUNTHOLDER</th>
                <td>{{ data.ach_full_name_of_account_holder }}</td>
              </tr>
              <tr>
                <th scope="row" translate>ACHROUTINGNUMBER</th>
                <td>{{ data.ach_routing_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>INSNUMBER</th>
                <td>{{ data.ach_institution_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BRANCHNUMBER</th>
                <td>{{ data.ach_branch_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>ACCOUNTNUMBER</th>
                <td>{{ data.ach_account_number }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BANKNAME</th>
                <td>{{ data.ach_bank_name }}</td>
              </tr>
              <tr>
                <th scope="row" translate>BANKADDRESS</th>
                <td>{{ data.ach_bank_address }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERNAME</th>
                <td>{{ data.ach_receiver_name }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERADDRESS</th>
                <td>{{ data.ach_receiver_address }}</td>
              </tr>
              <!----
              <tr>
                <th scope="row" translate>RECEIVERCITY</th>
                <td>{{ data.ach_receiver_city }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERPROVINCE</th>
                <td>{{ data.ach_receiver_province }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERCOUNTRY</th>
                <td>{{ data.ach_receiver_country }}</td>
              </tr>
              <tr>
                <th scope="row" translate>RECEIVERPOSTCODE</th>
                <td>{{ data.ach_receiver_postcode }}</td>
              </tr>
            -->
              <tr *ngIf="data.ach_account_type==0">
                <th scope="row" translate>ACCOUNTTYPE</th>
                <td translate>CHECKING</td>
              </tr>
              <tr *ngIf="data.ach_account_type==1">
                <th scope="row" translate>ACCOUNTTYPE</th>
                <td translate>SAVINGS</td>
              </tr>
            </tbody>

        </table>
        <div class="d-flex justify-content-center">
          <button mat-raised-button color="primary" class="mr-2" (click)="onConfirm()">{{ "PROCESSBTN" | translate }}</button>
          <button mat-raised-button (click)="activeModal.close(false)">{{ "CANCELBTN" | translate }}</button>
        </div>
        <br>
        <div><span class="text-warning font-weight-bold">Disclaimer:</span> Please note that the account holder is responsible for the accuracy of the bank information provided. Incorrect bank details could lead to a loss of funds once the money is sent.</div>
    </div>
</div>
