<div class="list-container">
    <div class="row image-section" *ngFor="let imgFile of imageList; index as i;">
        <div class="col-12">
            <img src="{{folderName + imgFile}}" class="image" style="max-height: 40px; width: auto;">
        </div>
        <div class="col-12">
            <button color="primary" class="btn align-items-center" (click)="deleteImage(imgFile)">{{"DELETEBTN" | translate}}</button>
        </div>
    </div>
    <div class="row m-5">
        <div class="col-12" style="text-align: center; margin: auto;">
            <button mat-raised-button color="primary" class="btn submit-button" (click)="closeReturn()">{{ "CLOSED" | translate}}</button>
        </div>
    </div>
</div>