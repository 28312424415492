import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { ConfirmWithdrawalRequestComponent } from 'src/app/modules/confirm-withdrawal-request/confirm-withdrawal-request.component';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { CustomErrorStateMatcher } from './curtom-state-matcher';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { max } from 'd3';



@Component({
  selector: 'app-withdrawal-request',
  templateUrl: './withdrawal-request.component.html',
  styleUrls: ['./withdrawal-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers :[
    {provide: ErrorStateMatcher, useClass:CustomErrorStateMatcher}

  ]
})
export class WithdrawalRequestComponent implements OnInit {
  @ViewChild("pagetitle") pagetitle: ElementRef;

  public customErrorStateMatcher = new CustomErrorStateMatcher();
  public withdrawalRequestForm: FormGroup;
  public questions : Array<any>=[];
  public user = new User();
  public step_no:number =0;
  public tradePass_error=0;
  public isProcessing = false;
  public withdrawal_type = 0;
  public bankInfo : Array<any>;
  public bankInfoSwiftOptions : Array<any>=[];

  public bankInfoACHOptions : Array<any>=[];

  public selectedBankInfo : Array<any> = [];

  private _unsubscribe$ = new Subject<any>();

  public maxWithdrawalAmt : number;
/**
export class AppComponent {
  form: FormGroup;
  customErrorStateMatcher = new CustomErrorStateMatcher();
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      name: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
    });
  }
}
 */

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private sharedUtilService : SharedUtilService,
    private ngDialog: NgbModal,
    private cdref : ChangeDetectorRef,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    
    this.withdrawalRequestForm = this.formBuilder.group({
      tradePassword: ['', [Validators.required]],
      withdraw_amount: [50, [Validators.required, Validators.min(50)]],
      transferFee: [{value: 5, disabled : true}, [Validators.required]],
      swift_aba_code: ['', [Validators.required]],
      institution_number: ['', [Validators.required]],
      branch_number: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      bank_name: ['', [Validators.required]],
      bank_address: ['', [Validators.required]],
      receiver_name: ['', [Validators.required]],
      receiver_address: ['', [Validators.required]],
      account_type:['0', [Validators.required]],
      full_name_of_account_holder: ['',[Validators.required]],
      /* Set.30,2024 By Victor
      receiver_city: ['', [Validators.required]],
      receiver_province: ['', [Validators.required]],
      receiver_country: ['', [Validators.required]],
      receiver_postcode: ['',[Validators.required]],
      */
      ach_full_name_of_account_holder: ['',[Validators.required]],
      ach_routing_number: ['',[Validators.required]],
      ach_institution_number: ['', [Validators.required]],
      ach_branch_number: ['', [Validators.required]],
      ach_account_number: ['',[Validators.required]],
      ach_bank_name: ['', [Validators.required]],
      ach_account_type:['0', [Validators.required]],
      ach_bank_address: ['', [Validators.required]],
      ach_receiver_name: ['', [Validators.required]],
      ach_receiver_address: ['', [Validators.required]],
      /* Set.30,2024 By Victor
      ach_receiver_city: ['', [Validators.required]],
      ach_receiver_province: ['', [Validators.required]],
      ach_receiver_country: ['', [Validators.required]],
      ach_receiver_postcode: ['',[Validators.required]],
      */
      bankInfoSwift:[''],
      bankInfoACH:['']


    });

    
    /*
    this.withdrawalRequestForm = this.formBuilder.group({
      tradePassword: ['', [Validators.required]],
      withdraw_amount: [50, [Validators.required, Validators.min(50)]],
      transferFee: [{value: 5, disabled : true}, [Validators.required]],
      swift_aba_code: ['',Validators.required],
      institution_number: ['',Validators.required],
      branch_number: ['',Validators.required],
      account_number: ['',Validators.required],
      bank_name: ['',Validators.required],
      bank_address: ['',Validators.required],
      receiver_name: ['',Validators.required],
      receiver_address: ['',Validators.required],
    });
*/
    this.setValidators(this.step_no);

    this.withdrawalRequestForm.get('withdraw_amount').valueChanges
    .subscribe(value => {
        let fee = (value * 0.02) < 5 ? 5 :  ( (value * 0.02) >= 15 ? 15 :  (value * 0.02) );
        this.withdrawalRequestForm.patchValue({
          transferFee : fee
        });
    });


    this.withdrawalRequestForm.get('bankInfoSwift').valueChanges
    .subscribe(value => {
       
        let selected = this.bankInfo.filter(e =>
          e.id==value
        );

        this.selectedBankInfo = selected;

        this.withdrawalRequestForm.patchValue({
          full_name_of_account_holder: selected[0].AccountHolder,
          swift_aba_code: selected[0].BankABA,
          institution_number: selected[0].InstitutionNo,
          branch_number: selected[0].BranchNo,
          account_number: selected[0].AccountNo,
          bank_name:selected[0].BankName,
          bank_address: selected[0].BankAddress,
          account_type:String(selected[0].account_type), //todo: need map to real field
          receiver_name: selected[0].receiverName,
          receiver_address: selected[0].ReceiverAddress,
          /* Set.30,2024 By Victor
          receiver_city:selected[0].ReceiverCity,
          receiver_province:selected[0].ReceiverProvince,
          receiver_country:selected[0].ReceiverCountry,
          receiver_postcode: selected[0].ReceiverPostcode,
          */
          
        });
    });

    this.withdrawalRequestForm.get('bankInfoACH').valueChanges
    .subscribe(value => {
      
        let selected = this.bankInfo.filter(e =>
          e.id==value
        );

        this.selectedBankInfo = selected;

        this.withdrawalRequestForm.patchValue({
          ach_full_name_of_account_holder: selected[0].AccountHolder,
          ach_routing_number: selected[0].ACH_routing,
          ach_institution_number: selected[0].InstitutionNo,
          ach_branch_number: selected[0].BranchNo,
          ach_account_number: selected[0].AccountNo,
          ach_bank_name:selected[0].BankName,
          ach_bank_address: selected[0].BankAddress,
          ach_account_type:String(selected[0].account_type), //todo: need map to real field
          ach_receiver_name: selected[0].receiverName,
          ach_receiver_address: selected[0].ReceiverAddress,
          /* Set.30,2024 By Victor
          ach_receiver_city:selected[0].ReceiverCity,
          ach_receiver_province:selected[0].ReceiverProvince,
          ach_receiver_country:selected[0].ReceiverCountry,
          ach_receiver_postcode: selected[0].ReceiverPostcode
          */
        });
    });

   

    this.getUser();
    this.getBankInfo();
    
  }

  clearAllValidators() {
    
      this.withdrawalRequestForm.get('withdraw_amount').clearValidators();
      this.withdrawalRequestForm.get('transferFee').clearValidators();
      this.withdrawalRequestForm.get('tradePassword').clearValidators();
      this.withdrawalRequestForm.get('swift_aba_code').clearValidators();
      this.withdrawalRequestForm.get('institution_number').clearValidators();
      this.withdrawalRequestForm.get('branch_number').clearValidators();
      this.withdrawalRequestForm.get('account_number').clearValidators();
      this.withdrawalRequestForm.get('bank_name').clearValidators();
      this.withdrawalRequestForm.get('bank_address').clearValidators();
      this.withdrawalRequestForm.get('receiver_name').clearValidators();
      this.withdrawalRequestForm.get('receiver_address').clearValidators();
      /* Set.30,2024 By Victor
      this.withdrawalRequestForm.get('receiver_city').clearValidators();
      this.withdrawalRequestForm.get('receiver_province').clearValidators();
      this.withdrawalRequestForm.get('receiver_country').clearValidators();
      this.withdrawalRequestForm.get('receiver_postcode').clearValidators();
      */
      this.withdrawalRequestForm.get('full_name_of_account_holder').clearValidators();
      this.withdrawalRequestForm.get('ach_routing_number').clearValidators();
      this.withdrawalRequestForm.get('ach_institution_number').clearValidators();
      this.withdrawalRequestForm.get('ach_branch_number').clearValidators();
      this.withdrawalRequestForm.get('ach_account_number').clearValidators();
      this.withdrawalRequestForm.get('ach_bank_name').clearValidators();
      this.withdrawalRequestForm.get('ach_bank_address').clearValidators();
      this.withdrawalRequestForm.get('ach_receiver_name').clearValidators();
      this.withdrawalRequestForm.get('ach_receiver_address').clearValidators();
      /* Set.30,2024 By Victor
      this.withdrawalRequestForm.get('ach_receiver_city').clearValidators();
      this.withdrawalRequestForm.get('ach_receiver_province').clearValidators();
      this.withdrawalRequestForm.get('ach_receiver_country').clearValidators();
      this.withdrawalRequestForm.get('ach_receiver_postcode').clearValidators();
      */
      this.withdrawalRequestForm.get('ach_full_name_of_account_holder').clearValidators();
      
      //this.withdrawalRequestForm.clearValidators();
      this.withdrawalRequestForm.updateValueAndValidity();
  }


  setValidators(stepNo:number){

    this.clearAllValidators();
    if (stepNo==0) {
      this.withdrawalRequestForm.get('withdraw_amount').setValidators([Validators.required, Validators.min(50)]);
      this.withdrawalRequestForm.get('transferFee').setValidators(Validators.required);
      this.withdrawalRequestForm.get('tradePassword').setValidators(Validators.required);  
    } else if(stepNo==1) {
      if(this.withdrawal_type==0) {//swift
        this.withdrawalRequestForm.get('swift_aba_code').setValidators(Validators.required);
        this.withdrawalRequestForm.get('institution_number').setValidators(Validators.required);
        this.withdrawalRequestForm.get('branch_number').setValidators(Validators.required);
        this.withdrawalRequestForm.get('account_number').setValidators(Validators.required);
        this.withdrawalRequestForm.get('bank_name').setValidators(Validators.required);
        this.withdrawalRequestForm.get('bank_address').setValidators(Validators.required);
        this.withdrawalRequestForm.get('receiver_name').setValidators(Validators.required);
        this.withdrawalRequestForm.get('receiver_address').setValidators(Validators.required);
        this.withdrawalRequestForm.get('full_name_of_account_holder').setValidators(Validators.required);
      /*  Set.30,2024 By Victor
        this.withdrawalRequestForm.get('receiver_city').setValidators(Validators.required);
        this.withdrawalRequestForm.get('receiver_province').setValidators(Validators.required);
        this.withdrawalRequestForm.get('receiver_country').setValidators(Validators.required);
        this.withdrawalRequestForm.get('receiver_postcode').setValidators(Validators.required);
        */
      }else if(this.withdrawal_type==1) {//ach
       this.withdrawalRequestForm.get('ach_full_name_of_account_holder').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_routing_number').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_institution_number').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_branch_number').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_account_number').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_bank_name').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_bank_address').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_receiver_name').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_receiver_address').setValidators(Validators.required);
      /*  Set.30,2024 By Victor
       this.withdrawalRequestForm.get('ach_receiver_city').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_receiver_province').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_receiver_country').setValidators(Validators.required);
       this.withdrawalRequestForm.get('ach_receiver_postcode').setValidators(Validators.required);
       */
      }
      
    }

    this.withdrawalRequestForm.markAsPristine();

    this.cdref.detectChanges();
  }

  public getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public getBankInfo() {
    this.evgService.getBankInfo({}).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
      if (resp) {
        if(resp.success){
          if (resp.data[0].results) {
            this.bankInfo = resp.data[0].results;
            this.bankInfo.forEach(e => {
              if(e.Withdrawal_type==0) {//swift
                this.bankInfoSwiftOptions.push({value:e.id, label:e.BankName+' '+e.AccountNo}); 
              }else if(e.Withdrawal_type==1) {//ACH
                this.bankInfoACHOptions.push({value:e.id, label:e.BankName+' '+e.AccountNo});
              }
            });
           
          } 
        }else{
          //this.tradePass_error = 1 ;
          this.responseHandlerService.handleResponse(resp);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  public withdrawalRequest(formDirective)
  {
    if(this.withdrawalRequestForm.valid){
      let data = this.withdrawalRequestForm.value;
      data.transType = 0;
      data.withdrawal_type = this.withdrawal_type;
      data.transferFee = this.withdrawalRequestForm.get('transferFee').value;
      data.selectedBankInfo = this.selectedBankInfo;
  
      const modalRef = this.ngDialog.open(ConfirmWithdrawalRequestComponent, { windowClass: 'confirm-withdrawal-request-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.data = data;
      modalRef.result.then((data) => {
        if(data){
          this.sharedUtilService.resetForm(this.withdrawalRequestForm,formDirective);
          this.step_no=0;
          this.ngOnInit();
        }
      }, (reason) => {
        //console.log(reason);
      });
    }
  }

  public verifyDealPass()
  {
    let withdraw_amount = this.withdrawalRequestForm.get('withdraw_amount').value;
    let fee = this.withdrawalRequestForm.get('transferFee').value;

    this.maxWithdrawalAmt = this.user.eWallet - fee;

    if(withdraw_amount > this.maxWithdrawalAmt) {
        this.withdrawalRequestForm.get('withdraw_amount').setErrors({maxWithdrawalAmt:true});
        this.cdref.markForCheck();
    }

    let inPass = this.withdrawalRequestForm.get('tradePassword').value;
    //if(confirm(this.translate.translate.instant('TRANSFERTODISTRIBUTORQ'))) { //"Transfer to Distributor?"
      this.evgService.verifyTradePass({tradePass : inPass}).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
        if (resp) {
          if(resp.success){
            if (resp.data[0].results) {
              // go next page
              //this.pagetitle.nativeElement.focus();
              let withdraw_amount = this.withdrawalRequestForm.get('withdraw_amount').value;
              let fee = this.withdrawalRequestForm.get('transferFee').value;

              this.maxWithdrawalAmt = this.user.eWallet - fee;

              if(withdraw_amount > this.maxWithdrawalAmt) {
                  this.withdrawalRequestForm.get('withdraw_amount').setErrors({maxWithdrawalAmt:true});
                  //this.withdrawalRequestForm.get('withdraw_amount').setValue(this.maxWithdrawalAmt);
                  this.cdref.markForCheck();
                  //this.withdrawalRequestForm.get('withdraw_amount').setValidators(Validators.max(this.maxWithdrawalAmt));
                  //this.withdrawalRequestForm.updateValueAndValidity();
              }else{
                
                  this.step_no=1;
                  this.tradePass_error = 0 ;
                  this.setValidators(this.step_no);
              }
              //document.getElementById('swift_aba_code').focus();
              /*
              setTimeout(() => {
                this.setValidators(this.step_no);
              }, 500);
              */
            } else {
              this.tradePass_error = 1 ;
            }
          }else{
            this.tradePass_error = 1 ;
            this.responseHandlerService.handleResponse(resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  prevStep(){
    if (this.step_no>0 ) {
      this.step_no--;
      this.setValidators(this.step_no);
    }
  }

  setWithDrawalType(wType:number) {
    this.withdrawal_type = wType;
    this.setValidators(this.step_no);
    
  }


}
