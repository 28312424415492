import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Bonus } from 'src/app/modules/common/Bonus';
import { BulletinBoard } from 'src/app/modules/common/BulletinBoard';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Sort } from '@angular/material/sort';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { Transaction } from 'src/app/modules/common/Transaction';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-ewallet-overview',
  templateUrl: './ewallet-overview.component.html',
  styleUrls: ['./ewallet-overview.component.scss']
})
export class EwalletOverviewComponent implements OnInit {

  public user: User;
  public transactions : Transaction[] = [];
  public moveTokensForm : FormGroup;
  public transferDistributorFrom : FormGroup;
  public selectedFromBalance = 0;
  public isConverting = false;
  private _unsubscribe$ = new Subject<any>();
  public purchaseTokenForm: FormGroup;
  public expiryDateValue:string='';
  public creditCardNumber:string='';

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService,
    private formBuilder : FormBuilder,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.getUser();
    this.getEwalletLog();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  initForms()
  {
    this.moveTokensForm = this.formBuilder.group({
        withdraw_amount: [1, [Validators.required, Validators.min(1)]],
    });

    this.transferDistributorFrom = this.formBuilder.group({
      // email: ['', [Validators.required,Validators.email]],
      toUserId: ['', [Validators.required]],
      stringId: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.min(50)]],
      email: ['', [Validators.required, Validators.email]],
      tradePassword: ['', [Validators.required]],
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    });

    this.purchaseTokenForm = this.formBuilder.group({
      firstName  : ['', [Validators.required]],
      lastName  : ['', [Validators.required]],

      postCode  : ['', [Validators.required]],
      address  : ['', [Validators.required]],
      amount  : ['', [Validators.required]],
      final_amount  : ['', [Validators.required]],
      exchange_rate : [1, [Validators.required]],
      cardType :  [0, [Validators.required]],
      cardNo :  ['', [Validators.required]],
      cvCode :  ['', [Validators.required]],
      expireDate :  ['', [Validators.required]],
    });

    this.purchaseTokenForm.get('amount').valueChanges.subscribe(value => {
      this.purchaseTokenForm.get('final_amount').setValue(value);
    });
  }

  cc_format(ele) {
    //console.log('cc_format value', value);
    let value = this.creditCardNumber;
    if (value) {
      const v = value.toString().replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      //console.log('cc_format match: v: matches:', match,v, matches);
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      //console.log('cc_format parts:', parts);
      if (parts.length > 0) {
        this.creditCardNumber = parts.join(' ');
      } else {
        this.creditCardNumber = value;
      }
      //console.log('cc_format creditCardNumber:', this.creditCardNumber);
    }
  }  

  onKeydown(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  modifyInput(ele) {
    if (this.expiryDateValue.length==2) {
      if (! (ele.key =='Backspace' || ele.key =='Delete')) {
        this.expiryDateValue += '/';
      }
    }
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            this.selectedBalanceChange();
            this.purchaseTokenForm.patchValue({
              firstName  : this.user.first_name,
              lastName : this.user.last_name,
              postCode : this.user.PostCode,
              address : this.user.Address
            });
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public purchaseToken(formDirective)
  {
    if(this.purchaseTokenForm.valid)
    {
      if (this.shareinfoService.validateExpiryMMYY(this.purchaseTokenForm.get('expireDate').value)) {

        this.shareinfoService.setCreditProcess(true);
        this.evgService.purchaseByteTokens( this.purchaseTokenForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.reloadUser();
            this.sharedUtilService.resetForm(this.purchaseTokenForm,formDirective);
            this.shareinfoService.setCreditProcess(false);
            this.getEwalletLog();
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
        this.responseHandlerService.handleResponse({ success : false, data : [{error : "expired date"}],  message : "UNUSECARD" });
      }
    }
  }

  selectedBalanceChange()
  {
    if(this.selectedFromBalance == 0) this.moveTokensForm.get('withdraw_amount').setValidators([Validators.required, Validators.min(1),Validators.max(this.user.eWallet)]);
    if(this.selectedFromBalance == 1) this.moveTokensForm.get('withdraw_amount').setValidators([Validators.required, Validators.min(1),Validators.max(this.user.totalCash)]);
  }

  isInvalidField(form ,field, validation){
      return this[form].get(field).dirty && this[form].get(field).hasError(validation) && this[form].get(field).invalid;
  }

  getEwalletLog()
  {
    this.evgService.eWalletlog( {} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.transactions = resp.data[0].results.slice(0,10);
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  moveTokens(formDirective)
  {
    if(this.moveTokensForm.valid){
      if(this.selectedFromBalance == 0) this.moveToCurrentBalance(this.moveTokensForm.get('withdraw_amount').value, formDirective);
      else if(this.selectedFromBalance == 1) this.moveToEwalletBalance(this.moveTokensForm.get('withdraw_amount').value, formDirective);
    }else{
      // this.sharedUtilService.markFormGroupDirty(this.moveTokensForm);
    }
  }
  

  moveToCurrentBalance(amount, formDirective)
  {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('EWALLETSUB5') + "?";
    modalRef.result.then((result) => {
      if(result) {
        if(this.user.eWallet > 0 && amount <= this.user.eWallet){
          let data = {
            withdraw_amount : amount,
            transType : 1
          }
          this.isConverting = true;
          this.evgService.transferToCurrentBalance(data).pipe( takeUntil(this._unsubscribe$), finalize(() => this.isConverting = false)).subscribe( resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.success){
                this.shareinfoService.reloadUser();
                this.sharedUtilService.resetForm(this.moveTokensForm,formDirective);
                this.getEwalletLog();
              }
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
        }
      }
    });
  }

  moveToEwalletBalance(amount, formDirective)
  {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('TRANSFERTOEWALLETQ') + "?";
    modalRef.result.then((result) => {
      if(result) {
        if(amount <= this.user.totalCash){
          this.isConverting = true;
          this.evgService.sentCoinsToEWallet({amount : amount}).pipe( takeUntil(this._unsubscribe$), finalize(() => this.isConverting = false) ).subscribe( resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.success){
                this.shareinfoService.reloadUser();
                this.sharedUtilService.resetForm(this.moveTokensForm, formDirective);
                this.getEwalletLog();
              }
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
        }
      }
    });
  }

  public transferToDistributor(formDirective)
  {
    if(this.transferDistributorFrom.valid){
      const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: false, size: 'md', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
      modalRef.componentInstance.message = this.translate.translate.instant('TRANSFERTODISTRIB') + "?"
      modalRef.result.then((result) => {
        if(result) {
          this.evgService.sentCoinsToUser(this.transferDistributorFrom.value).pipe( takeUntil(this._unsubscribe$) ).subscribe( resp => {
            if (resp) {
              this.responseHandlerService.handleResponse(resp);
              if(resp.success){
                this.shareinfoService.reloadUser();
                this.sharedUtilService.resetForm(this.transferDistributorFrom,formDirective);
              }
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          });
        }
      });
    }else{
      this.sharedUtilService.markFormGroupDirty(this.transferDistributorFrom);
    }
    
  }

}
