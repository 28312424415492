export class Products {
    pId: number;
    pPic : string;
    pTitle:string;
    pGroup:string;
    pPrice:number;
    isValid:boolean;
    pQty : number = 1;
    installFree: number;
    limited_flag : number;
    item_no : string;
    SKU : string;
    has_benefit : number;
    benefit_id : number;
    benefit_name : string;
    benefit_value : number;
    reviews : any;
    sort_no : number;
    description : string;
    page_name : string;
}

export class Product{
    addtime: string;
    tax_type : number;
    currency: string;
    installFree: number;
    invalid: number;
    pContent: string;
    pId: number;
    pPic: string;
    pPrice: number;
    pTitle: string;
    pType: number;
    payDownRate: number;
    reduceRate : number;
    sales_name : string;
    sales_desc : string;
    sales_desc_style : string;
    reviews : any;
    luck_days : number;
    luck_rank : number;
    gst_share_amt : number;
    pst_share_amt : number;
    page_name:string;
    autoship : number;
    autoship_discount: number;    
    limited_flag : number;
    item_no : string;
    SKU : string;
    has_benefit : number;
    benefit_id : number;
    benefit_name : string;
    benefit_value : number;
    sort_no : number;
    description : string;
    referral_fee : number;
    show_category : number;
 }
 
export class ProductInfo{
    addtime: string;
    tax_type : number;
    currency: string;
    installFree: number;
    invalid: number;
    pContent: string;
    pId: number;
    pPic: string;
    pPrice: number;
    pTitle: string;
    pType: number;
    payDownRate: number;
    reduceRate : number;
    sales_name : string;
    sales_desc : string;
    sales_desc_style : number;
    pQty : number = 0;
    reviews : any;
    luck_days : number;
    luck_rank : number;
    gst_share_amt : number;
    pst_share_amt : number;
    page_name : string;
    autoship : number=0;
    termFlag: number=0;
    autoship_discount : number=0;
    item_no : string;
    SKU : string;
    has_benefit : number;
    benefit_id : number;
    benefit_name : string;
    benefit_value : number;
    sort_no : number;
    description : string;
 }