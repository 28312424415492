import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { User } from 'src/app/modules/common/User';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EWalletLoginComponent } from 'src/app/modules/ewallet-login/ewallet-login.component';
import { SecretQuestionComponent } from 'src/app/modules/secret-question/secret-question.component';
import { CompleteInfoComponent } from 'src/app/modules/complete-info/complete-info.component';
import { CartComponent } from 'src/app/modules/cart/cart.component';
import { CartItem } from 'src/app/modules/common/CartItem';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
import { SharedUtilService } from 'src/app/services/shared-util.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public menus:Array<any>=[];
  public userBackMenus :Array<any>=[];
  public adminBackMenus :Array<any>=[];
  public menu_count:number=0;
  public isMobileMenu:boolean = window.innerWidth < 768 ? true : false;
  public isMobileMenuOpen:boolean  = false;
  public activeRoute : string = '';
  public navStack = [];
  public user : User;
  public mode : String = 'user';
  private _unsubscribe$ = new Subject<any>();
  public cart_items : CartItem[] = [];
  public cart_quantity : number = 0;
  private admins = [];
  private accounting = [];
  private supports = [];
  public userType = "user";
  public isPrinting = false;
  public eWalletLogin:boolean=false;
  constructor(
    public translate : TranslateConfigService,
    private router: Router,
    private ngDialog : NgbModal,
    public shareinfoService : ShareinfoService,
    private evgApiService: EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private cdref : ChangeDetectorRef,
    private sharedUtilService : SharedUtilService,
    public cdr : ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.getUser();
    this.getUserMode();
    this.getActiveRoute();
    this.getMainMenu();
    this.getCart();
    this.getPrintingStatus();
    
   
  }

  getActiveRoute(){
    this.shareinfoService.currentRoute
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.activeRoute =  successResponse;
            this.getMode();
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getPrintingStatus()
  {
    this.shareinfoService.isPrinting
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.isPrinting = successResponse;
              this.cdr.detectChanges();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }
  
  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              let isLoggedIn = successResponse ? true : false;
              this.user = successResponse ? successResponse : new User();
              if (!isLoggedIn && !localStorage.getItem(this.shareinfoService.userTokenKey)) {
                this.router.navigate([this.shareinfoService.mainHomeId]);
              }
              
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );

  }

  getMode(){
    this.shareinfoService.mode
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
             this.mode = successResponse;
             if(this.mode == 'user'){
              this.getUserBackMenu();
            }else{
              this.getAdminBackMenu();
              
            }
          },(errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMainMenu(){
    this.evgApiService.getMainMenu().subscribe( resp => {
      if (resp) {
        this.menus = resp;
        this.menu_count = resp.length;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserBackMenu(){
    this.evgApiService.getUserBackMenu().subscribe( resp => {
      if (resp) {
        this.userBackMenus = resp;
        this.buildUserNav(this.activeRoute);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getAdminBackMenu(){
    this.evgApiService.getAdminBackMenu().subscribe( resp => {
      if (resp) {
        this.adminBackMenus = resp;
        this.buildAdminNav(this.activeRoute);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
  }

  getUserMode (){
    this.userType = this.sharedUtilService.checkUserPermissions(this.user.id);
    this.shareinfoService.setMode(this.userType);
    this.cdref.detectChanges();
  }

  getCart(){

    var userType = this.sharedUtilService.checkUserPermissions(this.user.id);
    if(userType == 'user'){
      this.shareinfoService.refreshCart();
      this.shareinfoService.cart.pipe( takeUntil(this._unsubscribe$) )
          .subscribe(
            (successResponse: any) => {
              this.cart_items = successResponse;
              this.cart_quantity = 0;
              this.cart_items.forEach(item => {
                this.cart_quantity += item.nums;
              });
                
            },
            (errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
      );
    }
    

  }

  
  openPage(pId) {
    
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = false;
    }

    if (pId === "ewallet-login") {
      
      if(!this.shareinfoService.IsEwalletLoggedIn()){
        const modalRef = this.ngDialog.open(EWalletLoginComponent, { windowClass: 'ewallet-login-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
        modalRef.componentInstance.translate = this.translate;
        modalRef.result.then((data) => {
          this.cdref.detectChanges();
          if(data == 'success') {
            this.eWalletLogin=true;
            this.router.navigate(['backmain/ewallet-overview']);
          }
        }, (reason) => {
          //console.log(reason);
          this.cdref.detectChanges();
        });
        
      }
    }else if (pId === "cart") {
      const modalRef = this.ngDialog.open(CartComponent, { windowClass: 'cart-modal' ,centered: true, size: 'xl', keyboard: false, backdrop: "static" });
      modalRef.componentInstance.translate = this.translate;
    } else {
      this.router.navigate([pId]);
    }
  }

  isActiveRoute(route, parent = ""){
    
    let currentRoute = /[^/]*$/.exec(this.activeRoute)[0];
   
    if(currentRoute == route){
      return 'active'
    };
    return '';
  }

  buildUserNav(activeRoute){
    let currentRoute = /[^/]*$/.exec(activeRoute)[0];
    this.navStack = [];
    let selectedMenu = {
      name : "",
      slug : "",
      submenus : []
    }
    this.userBackMenus.forEach((menu , index)=> {
        if(menu.slug == currentRoute){
          selectedMenu = menu;
        } 
        menu.submenus.forEach(submenu => {
            if(submenu.slug == currentRoute){
              selectedMenu = menu;
            } 
        });
    });

    if(selectedMenu){
        this.navStack.push(selectedMenu.name);
        if(selectedMenu.submenus.length > 0){
            let nav = selectedMenu.submenus.find(submenu => {
              return submenu.slug === currentRoute
            })
            this.navStack.push(nav.name);
        }
    }


  }

  buildAdminNav(activeRoute){
    let currentRoute = /[^/]*$/.exec(activeRoute)[0];
    this.navStack = [];
    let selectedMenu = {
      name : "",
      slug : "",
      submenus : []
    }
    this.adminBackMenus.forEach((menu , index)=> {
        if(menu.slug == currentRoute){
          selectedMenu = menu;
        } 
        menu.submenus.forEach(submenu => {
            if(submenu.slug == currentRoute){
              selectedMenu = menu;
            } 
        });
    });

    if(selectedMenu){
        this.navStack.push(selectedMenu.name);
        if(selectedMenu.submenus.length > 0){
            let nav = selectedMenu.submenus.find(submenu => {
              return submenu.slug === currentRoute
            })
            this.navStack.push(nav.name);
        }
    }


  }

  
  
  logout() {
    const modalRef = this.ngDialog.open(ConfirmDialogComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.message = this.translate.translate.instant('LOGOUTINF');
    modalRef.result.then((result) => {
      if(result) {
        this.evgApiService.signOut().subscribe(resp => {
          if(resp){
            this.shareinfoService.logout();
            this.responseHandlerService.handleResponse(resp);
            this.cdref.detectChanges();
            localStorage.setItem(this.shareinfoService.tokenStoreKey, null);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
        
        this.router.navigate([this.shareinfoService.mainHomeId]);
      }
    });

   
  }

  eWalletLogout(){
    this.eWalletLogin=false;
    this.shareinfoService.eWalletLogout();
    this.responseHandlerService.handleCustomResponse("EWALLETLOGOUTSUCCESS", "success");
    this.router.navigate(['backmain/dashboard']);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

}
