import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Card, User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-purchase-byte-tokens',
  templateUrl: './purchase-byte-tokens.component.html',
  styleUrls: ['./purchase-byte-tokens.component.scss']
})
export class PurchaseByteTokensComponent implements OnInit {

  public user = new User();
  public cards : Card[] = [];
  public purchaseTokenForm: FormGroup;
  public purchaseTokenLogs : any[] = [];
  private _unsubscribe$ = new Subject<any>();
  public numberRegEx = /\-?\d*\.?\d{1,2}/;
  public expiryDateValue:string='';
  public creditCardNumber:string='';


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
    private cdr : ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    
    this.purchaseTokenForm = this.formBuilder.group({
      firstName  : ['', [Validators.required]],
      lastName  : ['', [Validators.required]],
      postCode  : ['', [Validators.required]],
      address  : ['', [Validators.required]],
      amount  : ['', [Validators.required]],
      final_amount  : ['', [Validators.required]],
      exchange_rate : [1, [Validators.required]],
      cardType :  [0, [Validators.required]],
      cardNo :  ['', [Validators.required]],
      cvCode :  ['', [Validators.required]],
      expireDate :  ['', [Validators.required]],
    });

    this.purchaseTokenForm.get('amount').valueChanges.subscribe(value => {
      this.purchaseTokenForm.get('final_amount').setValue(value);
    });

    
    // this.purchaseTokenForm.get('card_id').valueChanges
    //     .subscribe(value => {
    //       if(value == 0) {
    //         this.purchaseTokenForm.get('cardType').setValidators(Validators.required);
    //         this.purchaseTokenForm.get('holder').setValidators(Validators.required);
    //         this.purchaseTokenForm.get('cardNo').setValidators([Validators.required,Validators.pattern(this.numberRegEx)]);
    //         this.purchaseTokenForm.get('cvCode').setValidators([Validators.required,Validators.pattern(this.numberRegEx)]);
    //         this.purchaseTokenForm.get('expireDate').setValidators(Validators.required);
    //         this.purchaseTokenForm.markAsPristine();
    //       } else {
    //         this.purchaseTokenForm.get('cardType').clearValidators();
    //         this.purchaseTokenForm.get('holder').clearValidators();
    //         this.purchaseTokenForm.get('cardNo').clearValidators();
    //         this.purchaseTokenForm.get('cvCode').clearValidators();
    //         this.purchaseTokenForm.get('expireDate').clearValidators();
    //       }
    //       this.purchaseTokenForm.controls['cardType'].updateValueAndValidity();
    //       this.purchaseTokenForm.controls['holder'].updateValueAndValidity();
    //       this.purchaseTokenForm.controls['cardNo'].updateValueAndValidity();
    //       this.purchaseTokenForm.controls['cvCode'].updateValueAndValidity();
    //       this.purchaseTokenForm.controls['expireDate'].updateValueAndValidity();
    //     }
    // );
    this.getUser();
    this.getCardsInfo();
    this.getPurchaseByteTokensLog();
  }

  cc_format(ele) {
    //console.log('cc_format value', value);
    let value = this.creditCardNumber;
    if (value) {
      const v = value.toString().replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      //console.log('cc_format match: v: matches:', match,v, matches);
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      //console.log('cc_format parts:', parts);
      if (parts.length > 0) {
        this.creditCardNumber = parts.join(' ');
      } else {
        this.creditCardNumber = value;
      }
      //console.log('cc_format creditCardNumber:', this.creditCardNumber);
    }
  }  

  onKeydown(ele) {
    if (! ( ele.key>='0' && ele.key<='9' || ele.key=='Backspace' || ele.key=='Delete' || ele.key=='Enter' || ele.key=='ArrowLeft' || ele.key=='ArrowRight' || ele.key=='End' || ele.key=='Home')) {
      ele.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  modifyInput(ele) {
    if (this.expiryDateValue.length==2) {
      if (! (ele.key =='Backspace' || ele.key =='Delete')) {
        this.expiryDateValue += '/';
      }
    }
  }

  public getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              //console.log(this.user);
              this.purchaseTokenForm.patchValue({
                firstName  : this.user.first_name,
                lastName : this.user.last_name,
                postCode : this.user.PostCode,
                address : this.user.Address
              });
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  public updatePaymentSettings(formDirective)
  {
    let raw_data = this.purchaseTokenForm.value;

    let data = {
      payFee : '',
      autoPay : '',
      quitRank : '',
      cardInfo : new Card,
    }
    data.payFee = raw_data.payFee;
    data.autoPay = raw_data.autoPay;
    data.quitRank = raw_data.quitRank;
    
    if(raw_data.selectedCardId){
      
      data.cardInfo = this.cards.find(card => {
        return card.id === raw_data.selectedCardId
      })
    }else{

      data.cardInfo.cardNo = raw_data.cardNo;
      data.cardInfo.holder = raw_data.holder;
      data.cardInfo.cardType = raw_data.cardType;
      data.cardInfo.expireDate = raw_data.expireDate;
      data.cardInfo.cvCode = raw_data.cvCode;
    }

    this.evgService.updatePayFeeSetting( data ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.shareinfoService.reloadUser();
        this.sharedUtilService.resetForm(this.purchaseTokenForm,formDirective);
        this.getCardsInfo();
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  public getCardsInfo()
  {
    this.evgService.getCardsInfo( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.cards = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  public getPurchaseByteTokensLog()
  {
    this.evgService.getPurchaseByteTokensLog( {} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.purchaseTokenLogs = resp.data[0].results;
        //console.log(this.purchaseTokenLogs);
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }
  public purchaseToken(formDirective)
  {
    if(this.purchaseTokenForm.valid)
    {
      if (this.shareinfoService.validateExpiryMMYY(this.purchaseTokenForm.get('expireDate').value)) {
        this.shareinfoService.setCreditProcess(true);
        this.evgService.purchaseByteTokens( this.purchaseTokenForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
            this.shareinfoService.reloadUser();
            this.sharedUtilService.resetForm(this.purchaseTokenForm,formDirective);
            this.getCardsInfo();
            this.getPurchaseByteTokensLog();
            this.shareinfoService.setCreditProcess(false);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      } else {
        this.responseHandlerService.handleResponse({ success : false, data : [{error : "expired date"}],  message : "UNUSECARD" });
      }
    }
  }

}
