import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-user-downlines-limit',
  templateUrl: './user-downlines-limit.component.html',
  styleUrls: ['./user-downlines-limit.component.scss']
})
export class UserDownlinesLimitComponent implements OnInit {

  
  public downlineLimitForm: FormGroup;
  public questions : Array<any>=[];
  public isProcessing = false;
  private _unsubscribe$ = new Subject<any>();


  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private modalService: NgbModal,
    private sharedUtilService : SharedUtilService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.downlineLimitForm = this.formBuilder.group({
      userId : ['', [Validators.required]],
      maxUnders : ['', [Validators.required]],
    });

  }

  public updateDownlines(formDirective)
  {
    this.isProcessing = true;
    this.evgService.updateDownlines( this.downlineLimitForm.value ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        this.responseHandlerService.handleResponse(resp);
        this.sharedUtilService.resetForm(this.downlineLimitForm,formDirective);
      }
      this.isProcessing = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


}
