import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Bonus } from 'src/app/modules/common/Bonus';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-monthly-bonus-record',
  templateUrl: './monthly-bonus-record.component.html',
  styleUrls: ['./monthly-bonus-record.component.scss']
})
export class MonthlyBonusRecordComponent implements OnInit {

  public user: User;
  public bonuses : Bonus[] = [];
  public sortedBonuses = [];
  private _unsubscribe$ = new Subject<any>();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getMonthlyBonus();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMonthlyBonus()
  {
    this.evgService.getBonus( {dateId : 3} ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.bonuses = resp.data[0].results;
          this.sortedBonuses = this.bonuses.slice();
        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  sortData(sort: Sort) {
    const data = this.bonuses.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedBonuses = data;
      return;
    }

    this.sortedBonuses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dealDate': return this.sharedUtilService.compare(a.dealDate, b.dealDate, isAsc);
        case 'MemberNo': return this.sharedUtilService.compare(a.MemberNo, b.MemberNo, isAsc);
        case 'dealName': return this.sharedUtilService.compare(a.dealName, b.dealName, isAsc);
        case 'levels': return this.sharedUtilService.compare(a.levels, b.levels, isAsc);
        case 'amount': return this.sharedUtilService.compare(a.amount, b.amount, isAsc);
        case 'inPending': return this.sharedUtilService.compare(a.inPending, b.inPending, isAsc);
        default: return 0;
      }
    });
  }

}
