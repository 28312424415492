<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"></span>
        </div>
    </div>
    <div class="row m-1">
        <form [formGroup]="checkoutForm" (keydown.enter)="$event.preventDefault()" class="checkout-form" autocomplete="c-form" id="checkoutFormId">
            <h2 ngbAutofocus class="text-center pagetitle">{{ "ACCOUNTSUB9" | translate }} </h2>
            <div class="cart-items">
                <div class="table-responsive" id="no-more-tables">
                    <table class="table bg-white">
                        <thead class="bg-secondary text-light">
                        <tr class="font-weight-bold">
                            <th scope="col">#</th>
                            <th scope="col">{{ 'PRODUCTNAME' | translate }}</th>
                            <th scope="col">{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})</th>
                            <th scope="col">{{ 'QTY' | translate }}</th>
                            <th scope="col">{{ 'PRODUCTAWARD' | translate }}</th> <!--  AUTOSHIP added in May.14,2023-->
                            <th scope="col">{{ 'DISCOUNTS' | translate }}</th>
                            <th scope="col">{{ 'ASPRICE' | translate }}</th>
                            <th scope="col">{{ 'SFFDATE' | translate }}</th>
                            <th scope="col">{{ 'SHIPPADDR' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let cart_item of myAutoship; index as i;">
                            <td title="#" class="d-flex justify-content-center text-center">{{cart_item.id}}</td>
                            <td title="{{ 'PRODUCTNAME' | translate }}">{{ cart_item.pTitle }}</td>
                            <td title="{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})">{{ (cart_item.pPrice) | currency:'' }}</td>
                            <td title="{{ 'QTY' | translate }}">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{ 'QTY' | translate }}</mat-label>
                                    <input matInput placeholder="{{ 'QTY' | translate }}" [value]="cart_item.nums" (change)="changeAutoshipQTY($event,i)">
                                </mat-form-field>
                            </td>
                            <td title="{{ 'PRODUCTAWARD' | translate }}">
                                <div class="text-wrap">
                                    <mat-checkbox
                                        class="checbox-margin"
                                        [checked]= "cart_item.inactive==0"
                                        labelPosition="after"
                                        (change)="autoship_click($event,i)"
                                        [ngModelOptions]="{standalone: true}"
                                        >
                                        {{ (cart_item.inactive==0? 'AUTOSHIP' : 'CALAUTOSHIP') | translate}}
                                    </mat-checkbox>
                                </div>
                            </td>
                            <td title="{{ 'DISCOUNTS' | translate }}">{{ (cart_item.autoship_discount * 100).toFixed(2) }}%</td>
                            <td title="{{ 'ASPRICE' | translate }}">{{ ((1 - cart_item.autoship_discount) * cart_item.pPrice).toFixed(2) | currency:''}}</td>
                            <td title="{{ 'SFFDATE' | translate }}">
                                <div class="text-wrap">
                                    <input matInput 
                                    placeholder="{{ 'PURCHDATE' | translate }}" 
                                    type="date"
                                    [value]="cart_item.shipDate" 
                                    (change)="changeShipDate($event,i)"
                                    >
                                </div>
                            </td>
                            <td title="{{ 'SHIPPADDR' | translate }}">
                                <button class="btn btn-success btn-sm rounded-0 mx-2" type="button" data-toggle="tooltip" data-placement="top" title="Edit" (click)="onChangeShippingAdr(cart_item.shippingId,i)"><i class="fa fa-edit"></i></button>
                                <div class="address">
                                    {{cart_item.Address}}&nbsp;{{cart_item.City}}&nbsp;{{cart_item.Province}}&nbsp;{{cart_item.PostCode}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="myAutoship.length == 0">
                            <td colspan="8" class="text-center">
                                <h3>
                                    {{ 'NODATA' | translate }} <br><a class="link" style="cursor: pointer;" (click)="openPage('backmain/purchase')">{{ 'GOTOPRODUCT' | translate }}</a>
                                </h3>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="col-sm-12 col-xs-12 form-popup-form tokensection">
                    <h2 class="text-center pagetitle2">{{'TOTAL' | translate}}&nbsp;{{'EVCOINSLABEL' | translate}}</h2>
                    <div class="table-responsive" id="no-more-tables">
                        <table class="table bg-white">
                            <thead class="bg-secondary text-light">
                            <tr class="font-weight-bold">
                                <th scope="col">{{ 'TOTALCASHLABEL' | translate }}</th>
                                <th scope="col">{{ 'EWALLETSUB7' | translate }}</th>
                                <th scope="col">{{ 'TOTAL' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td title="{{ 'TOTALCASHLABEL' | translate }}" class="d-flex justify-content-center text-center">{{user.totalCash}}</td>
                                    <td title="{{ 'PRODUCTNAME' | translate }}">{{ user.eWallet }}</td>
                                    <td title="{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})">{{ (user.eWallet + user.totalCash) | currency:'' }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="text-align: center; margin: auto;">
                            <button mat-stroked-button color="primary" class="btn submit-button"  (click)="buyMoreTokens()" >{{ "BUYMORETK" | translate}}</button>
                        </div>
                    </div>
                    <div class="button_section">
                        <button mat-stroked-button color="primary" class="btn submit-button"  (click)="showCards()" ><i class="fa fa-credit-card" style="font-size : 1.5rem; position : relative;color: black;" aria-hidden="true"><span class="badge" *ngIf="cd_error_count > 0">{{ cd_error_count }}</span></i>&nbsp;{{ "MMYCARDS" | translate}}</button>
                    </div>
                </div>
                
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div style="text-align: center; margin: auto;">
                        <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!modified || myAutoship.length==0"   (click)="updateAutoship()">{{ "SAVE" | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>