<div class="user-applications-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body" *ngIf="!selectedApplication">
                
              <h3 class="title">{{'USERAPPLICATIONS' | translate}}</h3>
              <div class="text-right mb-2">
                <form [formGroup]="applicationsForm" (keydown.enter)="$event.preventDefault()" autocomplete="rf-form">
                    <mat-form-field>
                        <mat-label>{{'INFOTYPE' | translate}}</mat-label>
                        <mat-select formControlName="isClosed"  type="text" class="form-control" id="infoType" aria-describedby="infoType"
                        autocomplete="off">
                            <mat-option  [value]="-1">{{ 'ALL' | translate}}</mat-option>
                            <mat-option  [value]="0">{{ 'OPEN' | translate}}</mat-option>
                            <mat-option  [value]="1">{{ 'APPROVED' | translate}}</mat-option>
                            <mat-option  [value]="2">{{ 'REJECTED' | translate}}</mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="applicationsForm.get('isClosed').hasError('required')">{{'INFOTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!applicationsForm.valid || isProcessing" (click)="onGetApplications()" >{{ "SUBMIT" | translate}}</button>
                </form>
              </div>
              <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'DETAILS' | translate}}</th>
                          <th scope="col">{{'APPTYPE' | translate}}</th>
                          <th scope="col">{{'USERID' | translate}}</th>
                          <th scope="col">{{'FROM' | translate}}</th>
                          <th scope="col">{{'TO' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                          <!-- <th scope="col">{{'REQUESTTYPE' | translate}}</th>
                          <th scope="col">{{'ATYPE' | translate}}</th>
                          <th scope="col">{{'SENDER' | translate}}</th>
                          <th scope="col">{{'RECEIVER' | translate}}</th>
                          <th scope="col">{{'TOPIC' | translate}}</th>
                          <th scope="col">{{'AMOUNT' | translate}}</th>
                          <th scope="col">{{'CLOSED' | translate}}</th>
                          <th scope="col">{{'ADDTIME' | translate}}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let application of applications; index as i;">
                          <td><a (click)="selectApplication(application.id )" style="cursor: pointer; color: blue;">{{ 'OPEN' | translate}}</a></td>
                          <td>{{ application.applyType == 1 ? ('CHANGEEMAIL' | translate) : ('CHANGEFIRSTLAST' | translate) }}</td>
                          <td>{{ application.userId }}</td>
                          <td *ngIf="application.applyType == 0">{{ application.pre_first_name + ' ' + application.pre_last_name }}</td>
                          <td *ngIf="application.applyType == 0">{{ application.chang_to_firstName + ' ' + application.change_to_lastName }}</td>
                          <td *ngIf="application.applyType == 1">{{ application.pre_email}}</td>
                          <td *ngIf="application.applyType == 1">{{ application.change_to_email}}</td>
                          <td>
                            <ng-container *ngIf="application.isClosed == 0">
                              <button mat-stroked-button color="primary" class="btn submit-button mr-2" [disabled]="isProcessing1" (click)="onApproveApplication(application.id)" >{{ "APPROVEBTN" | translate}}</button>
                              <button mat-stroked-button color="warn" class="btn submit-button " [disabled]="isProcessing2" (click)="onRejectApplication(application.id)" >{{ "REJECTBTN" | translate}}</button>
                            </ng-container>
                            <ng-container *ngIf="application.isClosed != 0">
                              <p>{{ 'CLOSED' | translate }}</p>
                            </ng-container>
                            
                          </td>
                          <!-- <td>{{ getRequestType(application.applicationType) }}</td>
                          <td>{{ getAType(application.aType) }}</td>
                          <td>{{ application.sender }}</td>
                          <td>{{ application.receiver }}</td>
                          <td>{{ application.topic }}</td>
                          <td>{{ application.amount | currency:''}}</td>
                          <td>{{ application.isClosed ? ('YES' | translate) : ('NO' | translate)  }}</td>
                          <td>{{ application.AddTime }}</td> -->
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>

            <div class="card-body"  *ngIf="selectedApplication">
              <h3 class="title">{{'USERAPPLICATIONS' | translate}}</h3>
              <div class="row text-right">
                <button class="ml-auto mr-3 mb-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                      <!-- <tr>
                        <th scope="col">{{'ID' | translate}}</th>
                        <th scope="col"></th>
                        <th scope="col">{{'USERID' | translate}}</th>
                        <th scope="col">{{'FROM' | translate}}</th>
                        <th scope="col">{{'TO' | translate}}</th>
                        <th scope="col">{{'ACTION' | translate}}</th>
                      </tr> -->
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{'ID' | translate}}</th>
                        <td>{{ selectedApplication.id}}</td>
                      </tr>
                      <tr>
                        <th>{{'APPTYPE' | translate}}</th>
                        <td>{{ selectedApplication.applyType == 1 ? ('CHANGEEMAIL' | translate) : ('CHANGEFIRSTLAST' | translate) }}</td>
                      </tr>
                      <tr>
                        <th>{{'USERID' | translate}}</th>
                        <td>{{ selectedApplication.userId }}</td>
                        
                      </tr>
                      <tr>
                        <th>{{'FROM' | translate}} </th>
                        <td *ngIf="selectedApplication.applyType == 0">{{ selectedApplication.pre_first_name + ' ' + selectedApplication.pre_last_name }}</td>
                        <td *ngIf="selectedApplication.applyType == 1">{{ selectedApplication.pre_email}}</td>
                      </tr>
                      <tr>
                        <th>{{'TO' | translate}} </th>
                        <td *ngIf="selectedApplication.applyType == 0">{{ selectedApplication.chang_to_firstName + ' ' + selectedApplication.change_to_lastName }}</td>
                        <td *ngIf="selectedApplication.applyType == 1">{{ selectedApplication.change_to_email}}</td>
                      </tr>
                      <tr>
                        <th>{{'ADDRESSLABEL' | translate}} </th>
                        <td>{{ selectedApplication.Address}}</td>
                      </tr>
                      <tr>
                        <th>{{'CITYLABEL' | translate}} </th>
                        <td>{{ selectedApplication.City}}</td>
                      </tr>
                      <tr>
                        <th>{{'PROVINCELABEL' | translate}} </th>
                        <td>{{ selectedApplication.Province}}</td>
                      </tr>
                      <tr>
                        <th>{{'COUNTRYLABEL' | translate}} </th>
                        <td>{{ selectedApplication.Country}}</td>
                      </tr>
                      <tr>
                        <th>{{'POSTALCODELABEL' | translate}} </th>
                        <td>{{ selectedApplication.PostCode}}</td>
                      </tr>
                      <ng-container *ngIf="selectedApplication.applyType == 1">
                        <tr>
                          <th>{{ 'IDTYPE' | translate }} </th>
                          <td>{{ selectedApplication.personal_id_type }}</td>
                        </tr>
                        <tr>
                          <th>{{ 'IDNUMBER' | translate }} </th>
                          <td>{{ selectedApplication.personal_id }}</td>
                        </tr>
                        <tr>
                          <th>{{ 'ISSUEDPLACE' | translate }} </th>
                          <td>{{ selectedApplication.issued_place }}</td>
                        </tr>
                      </ng-container>
                      <tr>
                        <th>{{'CLOSED' | translate}} </th>
                        <td>{{ selectedApplication.isClosed ? 'YES' : 'NO'}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="d-flex justify-content-center">
                            <ng-container *ngIf="selectedApplication.isClosed == 0">
                              <button mat-stroked-button color="primary" class="btn submit-button mr-2" [disabled]="isProcessing1" (click)="onApproveApplication(selectedApplication.id)" >{{ "APPROVEBTN" | translate}}</button>
                              <button mat-stroked-button color="warn" class="btn submit-button " [disabled]="isProcessing2" (click)="onRejectApplication(selectedApplication.id)" >{{ "REJECTBTN" | translate}}</button>
                            </ng-container>
                            <ng-container *ngIf="selectedApplication.isClosed != 0">
                                <p>{{ 'CLOSED' | translate }}</p>
                            </ng-container>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            </div>
          </div>
        </div>
    </div>
</div>