<div class="account-container">
    
      <div class="row gutters-sm">
        
        <div class="col-md-12">
          
          <div class="card mb-3">
            <div class="card-body">
            <h3 class="account-title">{{ 'ACCOUNTINFOLABEL' | translate}}</h3>
            <div class="row">
              <div class="col-sm">
                <h3 class="account-title">{{ 'PERSONAL' | translate}}</h3>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'ACCOUNTNAMELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.first_name }} {{ user.last_name }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'SEXLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ getGender(user.Sex)  }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'BIRTHDATELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.Birthday ? user.Birthday.substring(0,10) : user.Birthday }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'TELEPHONELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.Tel }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'LANGUAGELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.language }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'EDUCLEVEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.education_level }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'ANNUALINCOME' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.annual_income }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'DISTRIBUTOR' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.isDistributor ? "YES" : "NO" }}
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-sm">
                <h3 class="account-title">{{ 'LOCATION' | translate}}</h3>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'ADDRESSLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.Address }} , {{ user.City }} , {{ user.Province }} , {{ user.Country }} , {{ user.PostCode }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'CITYLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                   {{ user.City }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'PROVINCELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.Province }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'COUNTRYLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                   {{ user.Country }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'POSTALCODELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.PostCode }}
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-sm">
                <h3 class="account-title">{{ 'FINANCE' | translate}}</h3>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'ULEVELLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ rankName }}
                  </div>
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'TOTALCASHLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.totalCash | currency:'' }}
                  </div>
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'EWALLET' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.eWallet | currency:'' }}
                  </div>
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'TOTALSPENTLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.totalSpent | currency:''}} 
                  </div>
                  
                </div>
                <hr>
                <!--
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'PENDINGLAYERLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.pendingLayer | currency:''}}
                  </div>
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="account-label">{{ 'PENDINGHITLABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.pendinghit | currency:'' }} 
                  </div>
                  
                </div>
                <hr>
                -->
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
</div>