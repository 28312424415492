<div class="pruchase-byte-tokens-container">
    <div class="row">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    
                    <form #purchaseTokenF="ngForm" [formGroup]="purchaseTokenForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                        
                        <div class="p-3">
                            <h2>{{ 'PURCHASEBYTETOKEN' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount" 
                                    name="amount" placeholder="{{ 'AMOUNTUSD' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>{{'CARDTYPE' | translate}}</mat-label>
                                    <mat-select formControlName="cardType"  type="text" class="form-control" id="cardType" aria-describedby="autoPay"
                                    autocomplete="off">
                                        <mat-option  [value]="0">{{ 'VISA' | translate }}</mat-option>
                                        <mat-option  [value]="1">{{ 'MASTERCARD' | translate }}</mat-option>
                                        <mat-option  [value]="2">{{ 'AMERICANEXPRESS' | translate }}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardType').hasError('required')">{{'CARDTYPEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="firstName" type="text" class="form-control" id="firstName" aria-describedby="firstName"
                                    placeholder="{{ 'FIRSTNAME' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('firstName').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="lastName" type="text" class="form-control" id="lastName" aria-describedby="lastName"
                                    placeholder="{{ 'LASTNAME' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('lastName').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="postCode" type="text" class="form-control" id="postCode" aria-describedby="postCode"
                                    placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('postCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                                </mat-form-field> <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="address" type="text" class="form-control" id="address" aria-describedby="address"
                                    placeholder="{{ 'ADDRESSLABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="cardNo" type="text"  pattern="[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}" class="form-control" id="cardNo" aria-describedby="cardNo"
                                    placeholder="{{ 'CCNUMBER' | translate }}" 
                                    (keyup)="cc_format($event)"
                                    [(ngModel)]="creditCardNumber"
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardNo').hasError('required')" >{{ "CCNUMBERREQ" | translate }} </mat-error>
                                </mat-form-field>
                                <div class="row">
                                    <div class="col-sm-7">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="expireDate" type="text" pattern="[0-9]{2}\/[0-9]{2}" class="form-control" id="expireDate" aria-describedby="expireDate"
                                            placeholder="{{ 'CCEXP' | translate }} (MM/YY)" 
                                            (keydown)="onKeydown($event)"
                                            (keyup)="modifyInput($event)"
                                            [(ngModel)]="expiryDateValue"
                                            maxlength="5"
                                            autocomplete="off">
                                            <mat-error class="error-message" *ngIf="purchaseTokenForm.get('expireDate').hasError('required')" >{{ "CCEXPREQ" | translate }} </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-5">
                                        <mat-form-field style="width: 100%;">
                                            <input matInput formControlName="cvCode" type="number" class="form-control" id="cvCode" aria-describedby="cvCode"
                                            placeholder="{{ 'CCCVC' | translate }}" 
                                            autocomplete="off">
                                            <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cvCode').hasError('required')" >{{ "CCCVCREQ" | translate }} </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                        <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!purchaseTokenForm.valid " (click)="purchaseToken(purchaseTokenF)" >{{ "PURCHASE" | translate}}</button>
                            </div>
                        </div>
                        
                      
                      </form>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="card mb-3">
                <div class="card-body">    
                  <h3 class="account-title">{{'PURCHASETOKENLOG' | translate}}</h3>
                  <div class="table-responsive">
                      <table class="table table-bordered">
                          <thead>
                            <tr>
                              <!-- <th scope="col">#</th> -->
                              <th scope="col">{{'DATE' | translate}}</th>
                              <th scope="col" nowrap="nowrap" >{{'MEMBERNO' | translate}}</th>
                              <!-- <th scope="col">{{'CARDTYPE' | translate}}</th> -->
                              <th scope="col">{{'AMOUNT' | translate}}</th>
                              <th scope="col">{{'FINALAMOUNT' | translate}}</th>
                              <th scope="col">{{'EXCHANGERATE' | translate}}</th>
                              <th scope="col">{{'EXCHANGEFEE' | translate}}</th>
                              <th scope="col">{{'CANCELLED' | translate}}</th>
                              <th scope="col">{{'CANCELDATE' | translate}}</th>
                              <!-- <th scope="col">{{'RUNNINGBALANCE' | translate}}</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let log of purchaseTokenLogs; index as i;">
                              <td>{{ log.addtime }}</td>
                              <td>{{ log.MemberNo }}</td>
                              <!-- <td>{{ log.cardType == 0 ? ('VISA' | translate) : ( log.cartType == 1 ? ('MASTERCARD' | translate) : (log.cartType == 2 ? ('AMERICANEXPRESS' | translate) : ('NOCARDTYPEAVAIL' | translate)  ))}}</td> -->
                              <td>{{ log.amount }}</td>
                              <td>{{ log.final_amount }}</td>
                              <td>{{ log.exchange_rate }}</td>
                              <td>{{ log.exchange_fee }}</td>
                              <td>{{ log.canceled ? ('YES' | translate) : ('') }}</td>
                              <td>{{ log.canceled_at }}</td>
                            </tr>
                          </tbody>
                        </table>
                  </div>
                </div>
              </div>
        </div>
        
    </div>
</div>