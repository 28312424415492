<div class="card">
    <div class="card-body text-center">
        <h1 mat-dialog-title class="p-3">{{ message }}</h1>
        <div *ngIf="imagePass">
          <img class="img_section" src="{{imagePass}}" alt="">
        </div>

        <div class="d-flex justify-content-center">
          <button mat-raised-button (click)="activeModal.close(false)">{{ "CLOSE" | translate }}</button>
        </div>
    </div>
</div>
