<div class="downline-ranks-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'DOWNLINERANKS' | translate}}</h3>
              <div class="table-responsive">
                  <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
                      <thead>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th mat-sort-header="id" scope="col">{{'MEMBERID' | translate}}</th>
                          <th mat-sort-header="name" scope="col">{{'NAME' | translate}}</th>
                          <th mat-sort-header="cssClass" scope="col">{{'RANK' | translate}}</th>
                          <th mat-sort-header="parentid" scope="col">{{'PLACER' | translate}}</th>
                          <th mat-sort-header="sponsorid" scope="col">{{'SPONSOR' | translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let downlineRank of sortedDownlineRanks; index as i;">
                          <!-- <th scope="row">{{ transaction.id }}</th> -->
                          <td>{{ downlineRank.id }}</td>
                          <td>{{ downlineRank.name }}</td>
                          <td class="text-uppercase">{{ downlineRank.rank }}</td>
                          <td>{{ downlineRank.parentid }}</td>
                          <td>{{ downlineRank.sponsorid }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>

            </div>
          </div>
        </div>
    </div>
</div>