import { Component, OnInit } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageSnippet } from 'src/app/modules/common/ImageSnippet';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-account-upload-portrait',
  templateUrl: './account-upload-portrait.component.html',
  styleUrls: ['./account-upload-portrait.component.scss']
})
export class AccountUploadPortraitComponent implements OnInit {

  public webcamImage: WebcamImage = null;
  public selectedFile: ImageSnippet;
  private _unsubscribe$ = new Subject<any>();
  public user: User;
  public isProcessing = false;
  public cameraOpened = false;
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
  ) { }

  ngOnInit(): void {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
    
  }

  uploadPhoto(imageAsData)
  {
    if(imageAsData){
      

      this.responseHandlerService.handleCustomResponse("Uploading Photo","info");
      this.isProcessing = true;
      this.evgService.uploadUserPhoto({id : this.user.id, image : this.resizeImage(imageAsData) })
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }else{
            this.isProcessing = false;
          }
          //console.log(resp);
      },(errorResult) => {
        this.isProcessing = false;
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    }else{
      this.responseHandlerService.handleCustomResponse("Please select a photo!","error");
    }
  }

  toggleCamera(){
    this.cameraOpened = !this.cameraOpened;
  }
  resizeImage(base64Str) {

    var img = new Image();
    img.src = base64Str;
    var canvas = document.createElement('canvas');
    var MAX_WIDTH = 400;
    var MAX_HEIGHT = 350;
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
  }
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
    });

    reader.readAsDataURL(file);
  }



  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  triggerSnapshot(): void {
   this.trigger.next();
  }
  
  handleImage(webcamImage: WebcamImage): void {
   //console.info('received webcam image', webcamImage);
   this.webcamImage = webcamImage;
  }
 
  public get triggerObservable(): Observable<void> {
   return this.trigger.asObservable();
  }

  public handleInitError(error: WebcamInitError): void {
    this.responseHandlerService.handleCameraResponseError(error);
    this.cameraOpened = false;
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }

}
