import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutocompleteOff]'
})
export class AutocompleteOffDirective {

  constructor(private _el: ElementRef) {
    let w: any = window;
    let isChrome = w.chrome;
    if (isChrome) {
      this._el.nativeElement.setAttribute('autocomplete', 'off');
      this._el.nativeElement.setAttribute('autocorrect', 'off');
      this._el.nativeElement.setAttribute('autocapitalize', 'none');
      this._el.nativeElement.setAttribute('spellcheck', 'false');
    }
  }

  // private _chrome = navigator.userAgent.indexOf('Chrome') > -1;
  // constructor(private _el: ElementRef) {}
  // ngOnInit() {
  //   if (this._chrome) {
  //     if (this._el.nativeElement.getAttribute('autocomplete') === 'off') {
  //       setTimeout(() => {
  //         this._el.nativeElement.setAttribute('autocomplete', 'offoff');
  //       });
  //     }
  //   }
  // }

  // constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) { }

  // ngAfterViewInit() {
  //   const randomString = Math.random().toString(36).slice(-6);
  //   this.renderer.setAttribute(this.el.nativeElement, 'name', randomString);
  //   this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', randomString);
  // }
}
