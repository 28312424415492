<div class="user-manager-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3 class="title">{{'USERMANAGER' | translate}}</h3>
              <div class="text-center mb-2 mt-2">
                <form [formGroup]="userManagerForm" (keydown.enter)="$event.preventDefault()" autocomplete="um-form">
                    <!-- <mat-form-field style="text-align: center">
                        <input matInput formControlName="id"  type="text" class="form-control text-box" id="id" aria-describedby="id"
                         name="id" [placeholder] = "'ORDERID' | translate"
                        autocomplete="off"
                        />
                        <mat-error class="error-message" *ngIf="searchRequestForm.get('id').hasError('required')">{{ 'ORDERIDREQ' | translate }}</mat-error>
                      </mat-form-field> -->

                      <mat-form-field style="width: 100%;">
                        <input matInput formControlName="userId"  type="text" class="form-control text-box" id="userId"
                        name="userId"  placeholder="{{ 'USERID' | translate }}"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="userManagerForm.get('userId').hasError('required')">{{'USERIDREQ' | translate}}</mat-error>
                      </mat-form-field>
                    <div style="text-align: center; margin: 0 auto;">
                      <button mat-stroked-button color="primary" class="btn submit-button mr-2" [disabled]="!userManagerForm.valid" (click)="onSearchRequest()" >{{ "SEARCHBTN" | translate}}</button>
                      <button mat-stroked-button color="primary" class="btn submit-button ml-2" (click)="openHistorylist()" >{{ "HISTORY" | translate}}</button>
                    </div>
                </form>
              </div>
              <div *ngIf="user">
                <form [formGroup]="userManagerUpdateForm" (keydown.enter)="$event.preventDefault()" autocomplete="um-form">
                  <div class="table-responsive">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{'USERID' | translate}}</th>
                          <th scope="col">{{'SPONSOR' | translate}}</th>
                          <th scope="col">{{'PLACER' | translate}}</th>
                          <th scope="col">{{'BONUSCOMMISIONSUB2' | translate}}</th>
                          <th scope="col">{{'TTLEWALLET' | translate}}</th>
                          <th scope="col">{{'FIRSTNAME' | translate}}</th>
                          <th scope="col">{{'LASTNAME' | translate}}</th>
                          <th scope="col">{{'EMAIL' | translate}}</th>
                          <th scope="col">{{'PASSWORD' | translate}}</th>
                          <th scope="col">{{'ACTION' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td title="{{'USERID' | translate}}">
                                  {{ user.id }}
                              </td>
                              <td title="{{'SPONSOR' | translate}}">
                                  {{ user.Recommended }}
                              </td>
                              <td title="{{'PLACER' | translate}}">
                                  {{ user.Referrals }}
                              </td>
                              <td title="{{'BONUSCOMMISIONSUB2' | translate}}">
                                {{ user.totalCash }}
                              </td>
                              <td title="{{'TTLEWALLET' | translate}}">
                                  {{ user.eWallet }}
                              </td>
                              <td title="{{'FIRSTNAME' | translate}}">
                                  <input type="text" class="form-control text-box" formControlName="first_name" id="first_name" name="first_name" autocomplete="off">
                              </td>
                              <td title="{{'LASTNAME' | translate}}">
                                  <input type="text" class="form-control text-box" formControlName="last_name" id="last_name" name="last_name" autocomplete="off">
                              </td>
                              <td title="{{'EMAIL' | translate}}">
                                  <input type="email" class="form-control text-box" formControlName="email" id="email" name="email" autocomplete="off">
                              </td>
                              <td title="{{'PASSWORD' | translate}}">
                                  <input type="text" class="form-control text-box" formControlName="password" id="password" name="password" autocomplete="off">
                              </td>
                              <td title="{{'ACTION' | translate}}">
                                  <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="!userManagerUpdateForm.valid" (click)="onUpdateRequest()" >{{ "UPDATEBTN" | translate}}</button>
                              </td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">{{'PHONENUM' | translate}}</th>
                              <th scope="col">{{'MOBILELABEL' | translate}}</th>
                              <th scope="col">{{'COUNTRYLABEL' | translate}}</th>
                              <th scope="col">{{'PROVINCELABEL' | translate}}</th>
                              <th scope="col">{{'CITYLABEL' | translate}}</th>
                              <th scope="col">{{'ADDRESSLABEL' | translate}}</th>
                              <th scope="col">{{'POSTALCODELABEL' | translate}}</th>
                              <th scope="col">{{'DISTRIBUTOR' | translate}}</th>
                              <th scope="col">{{'RANKSTATUS' | translate}}</th>
                              <th scope="col">{{'SHOWMENUS' | translate}}</th>
                              <th scope="col">{{'ASKSECRETQ' | translate}}</th>
                              <th scope="col">{{'ACTION' | translate}}</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td title="{{'PHONENUM' | translate}}">
                                      {{ user.Tel }}
                                  </td>
                                  <td title="{{'MOBILELABEL' | translate}}">
                                    {{ user.mobile }}
                                </td>
                                <td title="{{'COUNTRYLABEL' | translate}}">
                                      {{ user.Country }}
                                  </td>
                                  <td title="{{'PROVINCELABEL' | translate}}">
                                      {{ user.Province }}
                                  </td>
                                  <td title="{{'CITYLABEL' | translate}}">
                                    {{ user.City }}
                                  </td>
                                  <td title="{{'ADDRESSLABEL' | translate}}">
                                      {{ user.Address }}
                                  </td>
                                  <td title="{{'POSTALCODELABEL' | translate}}">
                                    {{ user.PostCode }}
                                  </td>
                                  <td title="{{'DISTRIBUTOR' | translate}}">
                                    {{ user.isDistributor==1 ? 'YES' : 'NO' }}
                                  </td>
                                  <td title="{{'RANKSTATUS' | translate}}">
                                    {{shareinfoService.getRankKey(user.uLevel) | translate}}
                                  </td>
                                  <td title="{{'SHOWMENUS' | translate}}">
                                    <select class="form-control cart-input" #showMenus [value]="selectedShowMenus" name="showMenus" (change)="changedShowMenu($event)" >
                                      <option value='0'>{{ 'ASNORMAL' | translate}}</option>
                                      <option value='1'>{{ 'SHOWALLMENU' | translate}}</option>
                                  </select>
                                  </td>
                                  <td title="{{'ASKSECRETQ' | translate}}">
                                    <select class="form-control cart-input" #askSecretQuestions [value]="askQuestions" name="askSecretQuestions" (change)="changedAskQuestions($event)" >
                                      <option value='0'>{{ 'NO' | translate}}</option>
                                      <option value='1'>{{ 'YES' | translate}}</option>
                                  </select>
                                  </td>
                                  <td title="{{'ACTION' | translate}}">
                                      <button mat-stroked-button color="primary" class="btn submit-button ml-2" [disabled]="showMenusModified==0" (click)="setShowMenu()" >{{ "UPDATEBTN" | translate}}</button>
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                        </div>
    
                </form>
            </div>
            <h3 class="title mb-2" *ngIf="user ">{{'BONUS' | translate}}</h3>
            <div class="table-responsive" *ngIf="user ">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{{'DATELABEL' | translate}}</th>
                      <th scope="col">{{'MEMBERID' | translate}}</th>
                      <th scope="col">{{'DEALDESCLABEL' | translate}}</th>
                      <th scope="col">{{'LEVELS' | translate}}</th>
                      <th scope="col">{{'AMOUNTUSD' | translate}}</th>
                      <th scope="col">{{'PENDINGLABEL' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bonus of userBonus; index as i;">
                      <td title="{{'DATELABEL' | translate}}">{{ bonus.dealDate }}</td>
                      <td title="{{'MEMBERID' | translate}}">{{ bonus.MemberNo }}</td>
                      <td title="{{'DEALDESCLABEL' | translate}}">{{ bonus.dealName }} {{ bonus.amount < 0 ? ('(' + ('BONUSREDUCED' | translate) +')') : ''}}</td>
                      <td title="{{'LEVELS' | translate}}">{{ bonus.levels }}</td>
                      <td title="{{'AMOUNTUSD' | translate}}">{{ bonus.amount | currency:'' }}</td>
                      <td title="{{'PENDINGLABEL' | translate}}">{{ bonus.inPending ?  "YES" : "NO"}}</td>
                    </tr>
                  </tbody>
                </table>
            </div>

            <h3 class="title mb-2" *ngIf="user ">{{'ORDERS' | translate}}</h3>
            <div class="table-responsive" *ngIf="user ">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">{{'GROUPID' | translate}}</th>
                        <th scope="col">{{'ORDERTYPE' | translate}}</th>
                        <th scope="col">{{'PAYTYPE' | translate}}</th>
                        <th scope="col">{{'SHIPPINGID' | translate}}</th>
                        <th scope="col">{{'QTY' | translate}}</th>
                        <th scope="col">{{'AMOUNT' | translate}}</th>
                        <th scope="col">{{'DISCOUNT' | translate}}</th>
                        <th scope="col">{{'CANCELLED' | translate}}</th>
                        <th scope="col">{{'CANCELDATE' | translate}}</th>
                        <th scope="col">{{'ADDTIME' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let userOrder of userOrders; index as i;" >
                            <td title="{{'GROUPID' | translate}}">{{ userOrder.groupID }}</td>
                            <td title="{{'ORDERTYPE' | translate}}">{{ userOrder.orderType }}</td>
                            <td title="{{'PAYTYPE' | translate}}">{{ userOrder.payType }}</td>
                            <td title="{{'SHIPPINGID' | translate}}">{{ userOrder.shippingId }}</td>
                            <td title="{{'QTY' | translate}}">{{ userOrder.pNums }}</td>
                            <td title="{{'AMOUNT' | translate}}">{{ userOrder.amount | currency }}</td>
                            <td title="{{'DISCOUNT' | translate}}">
                              {{ userOrder.discount | currency }}
                            </td>
                            <td title="{{'CANCELLED' | translate}}">{{ userOrder.cancelFlag ? ('YES' | translate) : ('') }}</td>
                            <td title="{{'CANCELDATE' | translate}}">{{ userOrder.cancelDate ?  userOrder.cancelDate : ('')}}</td>
                            <td title="{{'ADDTIME' | translate}}">
                              {{ userOrder.addtime }}
                            </td>
                          
                        </tr>
                    </tbody>
                  </table>
              </div>

              <h3 class="title mb-2" *ngIf="user ">{{'AUTOSHIP' | translate}}</h3>
              <div class="table-responsive" *ngIf="user">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{{ 'PRODUCTNAME' | translate }}</th>
                          <th scope="col">{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})</th>
                          <th scope="col">{{ 'QTY' | translate }}</th>
                          <th scope="col">{{ 'PRODUCTAWARD' | translate }}</th> <!--  AUTOSHIP added in May.14,2023-->
                          <th scope="col">{{ 'DISCOUNTS' | translate }}</th>
                          <th scope="col">{{ 'ASPRICE' | translate }}</th>
                          <th scope="col">{{ 'SFFDATE' | translate }}</th>
                          <th scope="col">{{ 'ACTION' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let cart_item of myAutoship; index as i;">
                              <td title="#" class="d-flex justify-content-center text-center">{{cart_item.id}}</td>
                              <td title="{{ 'PRODUCTNAME' | translate }}">{{ cart_item.pTitle }}</td>
                              <td title="{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})">{{ (cart_item.pPrice) | currency:'' }}</td>
                              <td title="{{ 'QTY' | translate }}">{{ 'QTY' | translate }}</td>
                              <td title="{{ 'PRODUCTAWARD' | translate }}">
                                  <div class="text-wrap">
                                      <mat-checkbox
                                          class="checbox-margin"
                                          [checked]= "cart_item.inactive==0"
                                          labelPosition="after"
                                          [disabled]="true"
                                          (change)="autoship_click($event,i)"
                                          [ngModelOptions]="{standalone: true}"
                                          >
                                          {{ (cart_item.inactive==0? 'AUTOSHIP' : 'CALAUTOSHIP') | translate}}
                                      </mat-checkbox>
                                  </div>
                              </td>
                              <td title="{{ 'DISCOUNTS' | translate }}">{{ (cart_item.autoship_discount * 100).toFixed(2) }}%</td>
                              <td title="{{ 'ASPRICE' | translate }}">{{ ((1 - cart_item.autoship_discount) * cart_item.pPrice).toFixed(2) | currency:''}}</td>
                              <td title="{{ 'SFFDATE' | translate }}">{{ cart_item.shipDate}}</td>
                              <td title="{{ 'ACTION' | translate }}">
                                  <button class="btn btn-success btn-sm rounded-0 mx-2" type="button" data-toggle="tooltip" data-placement="top" title="Edit" (click)="onChangeAutoship(cart_item,i)" 
                                  *ngIf="cart_item.inactive==0" >{{'STOPAUTOSHIP' | translate }}</button>
                                  <button class="btn btn-danger btn-sm rounded-0 mx-2" type="button" data-toggle="tooltip" data-placement="top" title="Edit" (click)="onChangeAutoship(cart_item,i)" 
                                  *ngIf="cart_item.inactive==1" >{{ 'ACTAUTOSHIP' | translate }}</button>
                              </td>
                          </tr>
                          <tr *ngIf="myAutoship.length == 0">
                            <td colspan="8" class="text-center">
                                <h3>
                                    {{ 'NODATA' | translate }}
                                </h3>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>