import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MatDatepicker} from '@angular/material/datepicker';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/modules/common/User';
import { PasswordErrorMatcherService } from 'src/app/services/password-error-matcher.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SharedUtilService } from 'src/app/services/shared-util.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYYMM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-pay-monthly-fee',
  templateUrl: './pay-monthly-fee.component.html',
  styleUrls: ['./pay-monthly-fee.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PayMonthlyFeeComponent implements OnInit {
  public user: User = new User();
  date = new FormControl(moment());
  public payMonthlyForm: FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public minDate: Moment;
  public rankName : string = "Member";
  public paidMonthlyFeeHistory : any[] = [];

  public matcher = new PasswordErrorMatcherService();
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private formBuilder : FormBuilder,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    const currentYear = moment().year();
    this.minDate = moment([currentYear - 1, 0, 1]);
    
    this.payMonthlyForm = this.formBuilder.group({
      for_month: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      payType: ['', [Validators.required]],
    });

    this.payMonthlyForm.controls['amount'].disable();

    this.getUser();
    this.getMyRankName();
    this.getPaidMonthlyFeeHistory();

  }


  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
              let default_amount_fee = 0;
              
              switch(this.user.uLevel){
                case 0 : default_amount_fee = this.user.pTimes > 0 ? 14.97 : 0;
                break;
                case 1 : default_amount_fee = 14.97;
                break;
                case 2 : default_amount_fee = 19.97;
                break;
                case 3 : default_amount_fee = 19.97;
                break;
                case 4 : default_amount_fee = 19.97;
                break;
                case 5 : default_amount_fee = 24.96;
                break;
                case 6 : default_amount_fee = 24.96;
                break;
                default : default_amount_fee = 0;
                break;
              }
              this.payMonthlyForm.patchValue({
                amount : default_amount_fee,
             });
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }
  getMyRankName(){
    this.shareinfoService.rankName.pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (rankName: any) => {
            this.rankName = rankName;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getPaidMonthlyFeeHistory()
  {
    this.evgService.getPaidMonthlyFeeHistory({}).pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.paidMonthlyFeeHistory = successResponse.data[0].results;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.payMonthlyForm.get('for_month').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    
    this.payMonthlyForm.get('for_month').setValue(ctrlValue);
    datepicker.close();
  }

  payMonthlyFee(formDirective){
    if(this.payMonthlyForm.status == 'VALID'){
      if(this.user.totalCash >= this.payMonthlyForm.get('amount').value){
        let data = {
          for_month : this.payMonthlyForm.get('for_month').value.format('YYYYMM'),
          amount: this.payMonthlyForm.get('amount').value,
          payType: this.payMonthlyForm.get('payType').value
        }
        this.evgService.payMonthlyFee(data)
          .pipe( takeUntil(this._unsubscribe$) )
          .subscribe((resp) => {
            if (resp) {
              if(resp.success){
                this.sharedUtilService.resetForm(this.payMonthlyForm, formDirective);
              }
              this.responseHandlerService.handleResponse(resp);
              this.shareinfoService.reloadUser();
              this.getPaidMonthlyFeeHistory();
            }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      }else{
        this.responseHandlerService.handleCustomResponse("Not Enough Balance", "warning");
      }
      
    }
  }


 

}
