export class InvoiceLink {
    id: number;
    customer_id: number;
    invoice_no : string='';
    phone : string='';
    email: string='';
    pId : number = 0;
    unit_price : number = 0;
    quantity : number = 0;
    tax_gst: number=0;
    tax_pst: number=0;
    tax_amt : number = 0;
    amount : number = 0;
    paid : number = 0;
    paid_at : string='';
    sent_times : number =0;
    AddTime : string='';
    customer: string='';
    passed_days:number=0;
    sent_days:number=0;
}
export class InvoiceLinkInfo {
    id: number;
    customer_id: number;
    invoice_no : string='';
    contact_person : string='';
    phone : string="";
    email: string="";
    pId : number = 0;
    unit_price : number = 0;
    quantity : number = 0;
    tax_gst: number=0;
    tax_pst: number=0;
    tax_amt : number = 0;
    amount : number = 0;
    paid : number = 0;
    paid_at : string="";
    sent_times : number =0;
    AddTime : string;
    customer:string='';
    country: string='';
    province: string='';
    city: string='';
    address: string='';
    post_code: string='';
    same_address:boolean=true;
    shipping_country: string='';
    shipping_province: string='';
    shipping_city: string='';
    shipping_address: string='';
    shipping_post_code: string='';
    pTitle:string='';
}
