<div class="infor-container">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
                <span></span>
            </div>
            <div class="col-2">
                <span></span>
                <span><img src="././../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                    (click)="activeModal.close('close')" alt="close"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <h2 class="title">{{ 'ASSETDETAIL' | translate }}</h2>
            </div>

            <div id="infocontent">
                <div  *ngFor="let detail of detailInfo;index as i">
                    <div class="row col-12 m-2" style="border-bottom: 2px solid;margin: 5px auto;">
                        <div class="col-4">
                            <div class="row">
                                <span class="float-right pr-3">{{'USERID' | translate}}</span>
                                <span class="content">{{detail.userId}}</span>
                            </div>
                            <div class="row">
                                <span class="float-right pr-3">{{'DIFFVALUE' | translate}}</span>
                                <span class="content">{{detail.diff_balance}}</span>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'TOTALCASHLABEL' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{detail.detail_info.totalCash  | currency:''}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'EWALLETBALANCE' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{detail.detail_info.eWallet  | currency:''}}</span></div>
                            </div>

                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'TTLASSET' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{(detail.detail_info.totalCash + detail.detail_info.totalCash) | currency:''}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'TOTALBONUS' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{detail.detail_info.totalBonus}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'TTLFUNDIN' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{detail.detail_info.transferIn}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-5"><span class="float-right pr-3">{{'TTLFUNDOUT' | translate}}</span></div>
                                <div class="col-7"><span class="content">{{detail.detail_info.transferOut}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 form-popup-form">
            <div style="text-align: center; margin:2rem auto;">
                <button mat-stroked-button color="primary" class="btn submit-button"  (click)="activeModal.close('close')" >{{ "CLOSED" | translate}}</button>
            </div>
        </div>

    </div>
</div>
