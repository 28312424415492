<div class="container">
    <div id="upperBannerId" class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    
    <div class="banner-title">
        
        <div class="banner-detail2 mb-3"><h2>{{ 'WRINKLESERUMTIT' | translate }}</h2></div>
        <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" translate>
            WRINKLESERUM-Age-Defying
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>
                WRINKLESERUM-Age-Defying-P1
            </p>
            <p class="mb-5 text-center" translate>
                WRINKLESERUM-Age-Defying-P2
            </p>
        </div>
    </div>

    <div class="row what-item-benefits">
        <div class="col-lg-6 col-sm-12 item-img-1">
            <div class="overlay">
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-title mb-1" translate>WRINKLESERUM-Potent-Blend</p>
                <div class="separator mt-1 mb-3"></div>
                <p class="card-text" translate>WRINKLESERUM-Potent-Blend-P1</p><br>
                <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'WATERABTN' | translate }}</a><br>
            </div>
        </div>
    </div>
    <div class="row what-item-benefits-2">
        <div class="col-lg-6 col-sm-12">
            <div class="card-block-1">
                <p class="card-title mb-1" translate>WRINKLESERUM-Understanding-the-Serum</p>
                <div class="separator mt-1 mb-3"></div>
                <p class="card-text" translate>WRINKLESERUM-Understanding-the-Serum-P1</p><br>
                <a class="btn banner-button" (click)="openPage('byte-shop')">{{ 'WATERABTN' | translate }}</a><br>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 item-img-2">
            <div class="overlay">
            </div>
        </div>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" translate>
            WRINKLESERUM-Key-Ingredients
        </h1>
        <div class="separator mb-5"></div>
    </div>

    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P1-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Key-Ingredients-P1-D <br> WRINKLESERUM-Key-Ingredients-P1-D1 </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P3-T</span>
                        <span class="why-detail">{{'WRINKLESERUM-Key-Ingredients-P3-D' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P5-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Key-Ingredients-P5-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P2-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Key-Ingredients-P2-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P4-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Key-Ingredients-P4-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Key-Ingredients-P6-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Key-Ingredients-P6-D</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row what-product">
        <div class="col-lg-6 col-md-12">
            <div class="left-section">{{'WRINKLESERUM-Overall-Benefits' | translate }}</div>
            <div class="separator"></div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="right-section">{{ 'WRINKLESERUM-Overall-Benefits-P1' | translate }}</div>
        </div>
    </div>

    <div class="what-product-detail">
        <div class="row content-row">
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P2-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Overall-Benefits-P2-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P5-T</span>
                        <span class="why-detail">{{'WRINKLESERUM-Overall-Benefits-P5-D' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P3-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Overall-Benefits-P3-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P6-T</span>
                        <span class="why-detail">{{'WRINKLESERUM-Overall-Benefits-P6-D' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P4-T</span>
                        <span class="why-detail" translate>WRINKLESERUM-Overall-Benefits-P4-D</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="each-secion">
                    <div class="item_detail">
                        <span class="why-title" translate>WRINKLESERUM-Overall-Benefits-P7-T</span>
                        <span class="why-detail">{{'WRINKLESERUM-Overall-Benefits-P7-D' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-introduce-part [min_height]="640" [contensInfo]="introduceInfo"></app-introduce-part>
<!---- at July 8,2024 by Victor
    <div class="introduce">
        <div class="introduce-body">
            <h1 class="introduce-head" translate>WRINKLESERUM-Instructions-for-use</h1>
            <div class="separator mt-1 mb-3"></div>
            <div class="introduce-text">{{ 'WRINKLESERUM-Instructions-for-use-P1' | translate }}</div>
        </div>
    </div>
-->
</div>   