<div class="form-popup-container center">
    <div class="card">
      <div class="card-header">
        <div class="row headrow">
          <div class="col-10 form-popup-header">
            <span></span>
            <!-- <h3> {{ "USER" | translate}}&nbsp;{{ "REGISTER" | translate}}</h3> -->
          </div>
          <div class="col-2 form-popup-header">
            <span></span>
            <span><img src="./../../../assets/images/icons/close.svg" class="float-right pointer" style="height: 1rem; width: 1rem;"
                (click)="activeModal.close('close')" alt="close"></span>
          </div>
      </div>
  
      </div>
      <div class="card-body" #scrollMe (scroll)="onScroll()">
            <!-- Added by thomas for test start -->
            <div class="col-sm-12">
              <div style="text-align: center; margin: auto;">
                <button mat-stroked-button color="warn" class="btn submit-button mr-2"  (click)="activeModal.close('close')">{{ "DECLINEBTN" | translate}}</button>
                <button mat-stroked-button color="primary" class="btn submit-button"    (click)="activeModal.dismiss('finished')">{{ "ACCEPTBTN" | translate}}</button>
              </div>
            </div>
            <br>
            <!-- Added by thomas for test end -->
            <p style="text-align: center;"><strong>INDEPENDENT DISTRIBUTOR AGREEMENT</strong></p>
            <p><br></p>
            <p>THIS AGREEMENT is made today, between Byte Health Management Corporation with its principal place of business located at #116-1772 Broadway St, Port Coquitlam, BC (hereinafter called &ldquo;Vendor&rdquo;) and {{ distributor ? distributor : 'the registered member'}} (hereinafter called &ldquo;Distributor&rdquo;).</p>
            <p><br></p>
            <p>WHEREAS, the Vendor is engaged in the business of selling at wholesale, EV Green water filtration systems. (hereinafter referred to as the &ldquo;Products&rdquo;); and WHEREAS, the Distributor desires to contract with Vendor to engage in the marketing and direct sale of said Products through demonstrations to residential customers in the convenience of the consumers home.</p>
            <p><br></p>
            <p>NOW, THEREFORE, in consideration of the mutual benefits to be derived from entering this agreement and other good and valuable consideration, the receipt and sufficiency of which are acknowledged, the parties agree to as follows:</p>
            <p><br></p>
            <p><strong>1. Distributor</strong> HEREBY AGREES TO USE HIS/HER BEST EFFORTS TO Market and sell Products in keeping with the terms hereof.</p>
            <p><br></p>
            <p><strong>2. BUY-SELL.</strong> In accordance with 1 above, Vendor shall sell to Distributor, and Distributor agrees to buy from Vendor for resale, the Products at Vendor&rsquo;s current listed distributor prices in effect at the time of delivery of such Products to Distributor. The retail selling price of the products is determined at the sole discretion of the Vendor.</p>
            <p><br></p>
            <p><strong>3. PAYMENT TERMS.</strong> The Distributor may get Products from Distributor for resale hereunder;</p>
            <p style="margin-left: 20px;">&nbsp; &nbsp;(1) By paying the then current wholesale price in cash at the time he/she receives such Products; or, at the option of Vendor,</p>
            <p style="margin-left: 20px;">&nbsp; &nbsp;(2) By receiving such Products on consignment for resale in such quantities as the Vendor shall authorize; or</p>
            <p style="margin-left: 20px;">&nbsp; &nbsp;(3) By receiving such Products on open account.</p>
            <p><br></p>
            <p><strong>4. CONSUMER FINANCED CONTRACTS.</strong> Distributor may make payment to Vendor by selling and assigning to Distributor, any conditional sales contract taken by Distributor upon resale of the Products in which event Distributor will receive profits to the extent of the balance upon such conditional or time sales contract over the wholesale Product price paid by the Distributor. It is understood, however, that Vendor&rsquo;s finance sources shall have the right to reject any conditional sales contract not in conformity with that financial sources&rsquo; payment schedules or when, in its judgment, the credit risk is undesirable.</p>
            <p><br></p>
            <p><strong>5. RELATIONSHIP OF THE PARTIES.</strong> The relationship between Vendor and Distributor is that of vendor and vendee. Distributor is, and at all times will operate as, an INDEPENDENT CONTRACTOR and not as an employee subject to the direction and control of Vendor. Distributor is not an agent or employee of Vendor and shall have no authority to pledge, bind or obligate Vendor in any manner or for any purpose whatsoever.</p>
            <p>Vendor hereby asserts and affirms that it shall not treat the Distributor as an employee for tax purposes or for workers&rsquo; compensation purposes in accordance with all provincial and municipal acts including WorkSafe. Distributor&rsquo;s income is derived through profits generated from the sale of the Products. Distributor shall use business cards showing distributor name and contact information. Distributor is responsible for providing transportation and paying all expenses incidental to Distributor&rsquo;s business, including, but not limited to, all tools required for product demonstration, taxes, license fees, cost of insurance, including all work related insurances as may be required under WorkSafe, transportation, and other charges incurred in the resale of the Products. Distributor also agrees to conduct Distributor&rsquo;s activities in an ethical manner and to comply with all other applicable British Columbia, Canadian laws and U.S. laws, permit requirements and ordinances in the conduct of Distributor&rsquo;s independent business.</p>
            <p><br></p>
            <p><strong>6. SELLING AIDS.</strong> Vendor may make available to Distributor certain selling aids, including, but not limited to: information as to selling techniques; periodic unpaid seminars regarding the same; voluntary unpaid sales promotion and motivational meetings; and other such aids. The Distributor is free to accept such assistance and aid as Distributor sees fit.&nbsp;</p>
            <p><br></p>
            <p><strong>7. WARRANTY.</strong> The only limited warranty applicable to the Products is that warranty provided by the manufacturer as same may be extended by EV Green. Distributor shall not make any warranties or representations of warranty which extend beyond the statements and descriptions on the face of said limited warranty.</p>
            <p><br></p>
            <p><strong>8. REPORTS</strong>. Distributor shall keep such records as will show the name and address of the purchaser, the date of sale, and any other information with respect to the Products and the warranty thereon, as may be reasonably requested by Vendor, and shall submit to Vendor such information with regard thereto as may be requested at any time and from time to time by Vendor.</p>
            <p><br></p>
            <p><strong>9. TRADEMARKS.&nbsp;</strong>Distributor acknowledges that the Product trademarks, trade names, logos and emblems, including &ldquo;EV Green&rdquo;, are the property of EV Green Inc. Distributor agrees that all methods of sale and advertising which use or mention said marks will not be used in any manner which would deceive the public or infringe upon any mark, or which would otherwise violate any law, regulation or ordinance.</p>
            <p><br></p>
            <p><strong>10. GENERAL TERMS.</strong></p>
            <p style="margin-left: 20px;">(A) In entering into this Agreement, neither Vendor nor Distributor is relying on any representations other than those contained in this Agreement itself. It is understood that this Agreement supersedes all prior agreements, understandings, negotiations, and discussions, if any, between the parties.</p>
            <p style="margin-left: 20px;">(B) This Agreement is made solely for the benefit of the parties hereto, and no third person shall have or acquire any right whatsoever hereto, or resulting therefrom.</p>
            <p style="margin-left: 20px;">(C) No waiver by the Vendor of any term or provision hereof shall constitute a waiver of the performance of any other term or provision nor a waiver of subsequent performance of the terms or provision hereof first waived.</p>
            <p style="margin-left: 20px;">(D) If any portion of this Agreement is unenforceable or invalid, that portion of this Agreement is to be considered severed from the remainder of the Agreement, and the remainder of the Agreement shall be fully enforceable.</p>
            <p style="margin-left: 20px;">(E) Verbal statements or promises by either party subsequent to the execution of this Agreement shall have no force or effect unless reduced to writing and signed by the Vendor and the Distributor.</p>
            <p style="margin-left: 20px;">(F) Distributor shall be responsible for Distributor&rsquo;s errors or omissions associated with Distributor&rsquo;s retail sales of the Product(s) and shall indemnify, defend and hold harmless Vendor, and/or EV Green Inc. against any claims, actions, demands or suits arising out of or claimed to have arisen out of said Distributor&rsquo;s acts of omissions.</p>
            <p style="margin-left: 20px;">(G) Distributor is free to hire helpers at Distributor&rsquo;s own cost and expense in support of Distributor&rsquo;s activities hereunder.</p>
            <p style="margin-left: 20px;">(H) As an independent contractor, Distributor shall be responsible for Distributor&rsquo;s own worker&rsquo;s related insurances as may be required by WorkSafe.</p>
            <p><strong>11.Technical and Sales Literature: </strong>&nbsp;Vendor hereby grants to Distributor a non-exclusive, non-transferable, revocable, royalty-free license, to use the sales and technical literature and materials provided by Vendor (the &ldquo;Sales Material&rdquo;) during the Term to promote, market, sell and distribute the Products in the Territory.</p>
            <p><strong>12. Restrictions:</strong> &nbsp;Except as specifically permitted in this Agreement or in writing by Vendor, Distributor hereby represents, warrants and agrees that it will not:&nbsp;</p>
            <ul>
                <li style="list-style-type: none;">
                    <ul>
                        <li>copy, reproduce or manufacture any Product, or any component parts thereof, including without limitation, any consumable parts such as filters or Ultra Violet (UV) lamp bulbs;</li>
                        <li>translate, modify, adapt, enhance, extend, decompile, disassemble or reverse engineer any of the Products;&nbsp;</li>
                        <li>market, sell or distribute the Products, or any component parts thereof, including without limitation, any consumable parts such as filters or UV lamp bulbs, or solicit potential customers, outside the territory. Distributor may fill orders received from outside the Territory (to the extent such order is unsolicited) only upon obtaining prior written consent of Vendor;</li>
                        <li>utilize Social media or online advertising of any type using the Vendor&apos;s name, (EV Green) or photos, drawings or other images of the Products; (Without written permission by the Vendor).</li>
                        <li>market or sell the Products to any Customer other than for residential, in-home installation and use, unless otherwise authorized by the Vendor in writing;</li>
                        <li>hire, employ, contract or otherwise use anyone other than a qualified duly licensed and fully insured plumber, electrician or HVAC technician for the installation of the Products; and,</li>
                        <li>while this Agreement is in effect, directly or indirectly, including &nbsp;as an employee, agent, independent contractor, owner, or otherwise, market, sell or promote the sale of, or otherwise commercially deal in, or with, any Products, or any component parts thereof, including without limitation, any consumable parts such as filters or Ultra Violet (UV) lamp bulbs, within or without the Territory, that will then be, directly or indirectly, in competition with, or similar to, the Products, or component parts of the Vendor.</li>
                        <li>comply with all applicable laws, regulations and ordinance, and maintain in effect all the licenses, permissions, authorizations, consents and permits that it needs to carry out its obligations under this Agreement;</li>
                        <li>promptly report to Vendor all Product defects and keep Vendor informed of any Customer complaints that relate to the Products; and only utilize the official Customer sales agreement and invoice documentation provided by the Vendor, as amended from time to time, for the sale and installation of all Products and replacement Parts. The Distributor&apos;s failure to use the Vendor&apos;s Customer sales agreement and invoice documentation is a material breach of the terms of this Agreement and the Distributor shall therefore indemnify the Vendor for any damages, injuries or losses suffered, in accordance with the Indemnification Section of this Agreement.</li>
                    </ul>
                </li>
            </ul>
            <p><br></p>
            <p><strong>13.Distributor Expenses:</strong>&nbsp; Distributor assumes full responsibility for all its own costs and expenses incurred in carrying out its obligations under this Agreement, including but not limited to all rents, salaries, commissions, advertising, translations of documents and materials, demonstration, travel and accommodation for the employees, agents, representatives or other personnel of Distributor.</p>
            <p><br></p>
            <p><strong>14.<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Marketing Materials:</strong>&nbsp; Distributor is prohibited from using any marketing materials other than the Sales Material provided by the Vendor, unless such materials are approved in writing by Vendor prior to use by Distributor.</p>
            <p><br></p>
            <p><strong>15. Exclusive Residential In-Home End-User Sales:</strong> Products purchased from the Vendor by the Distributor for sale are purchased solely and exclusively by Distributor for residential in-home demonstration and resale to Customers for residential in-home use only, unless the Vendor otherwise expressly authorizes in writing. Distributor further agrees to use its best efforts to conduct the personal demonstration in the residence of the Customer End-User. Distributor agrees that any other resale is strictly prohibited by this Agreement and may constitute a material breach hereof by Distributor. Distributor further agrees that it is a direct violation of this Agreement for Distributor or its executives, management, sales persons, employees, agents and affiliates to sell any Products to any person or entity for the purpose of resale by that person or entity.</p>
            <p><br></p>
            <p><strong>16.PURCHASE ORDERS AND SHIPMENTS</strong></p>
            <p><strong>16.1 Purchase Orders:</strong></p>
            <p style="margin-left: 40px;">(a)This Agreement shall not obligate Distributor to purchase any Products except as specifically set forth in a written purchase order. &nbsp;In the event of any conflict between any provision of a purchase order and any provision of this Agreement, the terms of this Agreement shall prevail. &nbsp;</p>
            <p style="margin-left: 40px;">(b)Purchase orders from Distributor may be submitted electronically, and any such submission shall constitute an &ldquo;Order&rdquo; for the purposes of this Agreement. &nbsp;Vendor has the unilateral right to accept or decline any Order placed by Distributor. &nbsp;Should Vendor reject an Order placed by Distributor, it will do so in writing and within 5 business days of receiving said Order. &nbsp;Within 48 hours of accepting an Order from Distributor, Vendor will send to Distributor a confirmation of Order receipt with expected shipping dates of the Products.</p>
            <p style="margin-left: 40px;">(c)At any time prior to the shipment date of the Products, Vendor may, at its option, accept alterations to an Order, such as (i) change of location for delivery, (ii) modification of the quantity or type of Products to be delivered, or (iii) correction of typographical or clerical errors. &nbsp;If any alteration to an Order delays Vendor&rsquo;s quoted shipment date, Vendor shall promptly send to Distributor a revised expected shipping date of the Products. &nbsp;</p>
            <p><strong>16.2 Shipments:</strong></p>
            <p style="margin-left: 40px;">(a)<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>All Orders and shipments are F.O.B. Vendor&rsquo;s authorized place of shipment. &nbsp;Distributor shall have the right to select the common carrier for transport of the Products. &nbsp;Distributor shall pay all such freight charges.</p>
            <p style="margin-left: 40px;">(b)<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>In the event of any shortage, damage or discrepancy in or to a shipment of Products, Distributor shall promptly report the same to Vendor and furnish such written evidence or other documentation as Vendor may deem appropriate. &nbsp;Vendor shall not be liable for any such shortage, damage or discrepancy unless Vendor has received notice and evidence thereof from Distributor (i) within 10 business days after delivery of the Products at the place of shipment, in the case of apparent shortages, damages or discrepancies, or (c) within 10 business days after identification of any latent shortage, damage, or discrepancy. If such evidence demonstrates that Vendor is responsible for such shortage, damage or discrepancy, Vendor shall promptly deliver additional or substitute Products to Distributor at no additional cost to Distributor. Vendor may require the Distributor to return any damaged Products at the Vendor&apos;s expense.</p>
            <p><br></p>
            <p><strong>17.PRICING AND TERMS OF PAYMENT</strong></p>
            <p><strong>17.1 Prices:</strong> &nbsp;Distributor may purchase Products at the prices specified in the Vendor&apos;s Product pricing sheet, which may be amended from time to time in accordance with Sections 7.3 and 7.4 below. &nbsp; &nbsp;</p>
            <p><strong>17.2 Taxes:</strong> &nbsp;All payments due to Vendor hereunder are exclusive of any tax, tariff or custom duties, as may be applicable, and which are the responsibility of the Distributor and not that of the Vendor.</p>
            <p><strong>17.3 Price Increases: </strong>&nbsp;Vendor shall have the right to increase prices from time to time upon publishing prices on the website or by emailing to Distributor. &nbsp;All Orders placed prior to the effective date of the price increase shall be invoiced by Vendor at the price in effect at the time of Order.</p>
            <p><strong>17.4 Price Decreases and Price Protection:</strong> &nbsp;Vendor shall have the right to decrease prices from time to time upon publishing prices on the website or by emailing to Distributor. &nbsp;In the event Vendor decreases the price to Distributor of any Product, Vendor will immediately grant a corresponding price reduction on all Orders placed prior to receipt of the notice of price reduction but not yet shipped. &nbsp;</p>
            <p><strong>17.5 Terms of Payment:</strong> &nbsp;Terms of payment are PIA (payment in advance) of shipment. Unless otherwise agreed upon in advance by the Parties, all invoice amounts and payments will be made in U.S. Dollars in immediately available funds.&nbsp;</p>
            <p><strong>17.6 Invoices: </strong>All invoices to Distributor shall contain (i) Vendor&rsquo;s name and invoice date, (ii) a reference to the Order or other authorizing document, (iii) separate descriptions, unit prices and quantities of the Products being invoiced, (iv) shipping charges (if applicable), (v) tariffs, custom duties and taxes (if applicable), and (vi) mailing address for payment. &nbsp;Notwithstanding any pre-printed terms or conditions on Vendor&rsquo;s invoices, the terms and conditions of this Agreement shall apply to and govern all invoices issued by Vendor and payment hereunder.</p>
            <p><br></p>
            <p><strong>18.LIMITED PRODUCT WARRANTY</strong></p>
            <p style="margin-left: 40px;">(a)Vendor warrants that all products will be free from material defects in materials and workmanship for a period equal to the warranty specified in Vendor&rsquo;s standard product warranty included with the Product sale (the &ldquo;Warranty Period&rdquo;). &nbsp;Except for the limited warranties provided in this Section, and to the maximum extent permitted by applicable law, Vendor makes no other warranties with respect to the Products, the Sales Material, or any other services, materials or information provided under this Agreement, and Vendor disclaims all warranties, express, implied, statutory or otherwise, with respect thereto including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose and non-infringement. &nbsp;</p>
            <p style="margin-left: 40px;">(b) During the Warranty Period, Vendor&rsquo;s liability and Distributor&rsquo;s remedy under the foregoing limited warranty shall be limited to the exchange of any Products, or individual Product parts, which are determined by Distributor to be defective, with no charge to Distributor when returned to Vendor with dated proof of purchase. Vendor shall pay for all freight charges for the return of all Warranty eligible Products or Product parts under this Section. This warranty does not apply to any component which has been misused, defaced, altered or modified by Distributor or the Customer End-User, or which has been serviced or repaired by any entity other than Vendor or one of Vendor&rsquo;s authorized service partners.</p>
            <p style="margin-left: 40px;">(c) Before returning any defective Product to Vendor, Distributor will contact Vendor for a RMA number and other appropriate instructions. &nbsp;The RMA number will be displayed prominently on the outside of the shipping container. &nbsp;Freight from Distributor to Vendor will be sent pre-paid on the Vendor&rsquo;s account. Items without a proper RMA number will be returned to Distributor freight collect. &nbsp;Vendor will pay two-day freight charges for any shipment of replaced Products back to Distributor or to the End-User. &nbsp;Vendor&rsquo;s maximum liability for warranty claims is limited to the invoice price of the defective Product.</p>
            <p><br></p>
            <p><strong>19.TRADEMARKS</strong></p>
            <p>Vendor authorizes and grants to Distributor a non-exclusive, revocable license to use the brand names, trademarks, trade names of, and any other commercial symbol designating, the Products and the Vendor company name, EV Green (collectively, the &ldquo;Trademarks&rdquo;) to fulfill its obligations under this Agreement. &nbsp;Distributor&rsquo;s use of the Trademarks shall at all times comply with applicable federal and state laws and reasonable written guidelines provided by Vendor. &nbsp;Distributor acknowledges and agrees that it has no right, title or interest in or to any of the Trademarks, and that all use of the Trademarks inures to the benefit of Vendor. &nbsp;Distributor shall make no contrary representations and will not in any way contest Vendor&rsquo;s rights to the Trademarks. &nbsp;Distributor will notify Vendor of any infringement of which Distributor has actual knowledge. &nbsp;Distributor shall discontinue use of Vendor&rsquo;s Trademarks upon termination of this Agreement pursuant to Section 19 hereof, except as may be necessary to sell or liquidate any Product remaining in Distributor&rsquo;s inventory. &nbsp;Distributor shall at no time register any trade names or trademarks or any mark or name confusingly similar to the Trademarks.</p>
            <p><br></p>
            <p><strong>20.INDEMNIFICATION</strong></p>
            <p>The Distributor (the &ldquo;Indemnifying Party&rdquo;) shall indemnify, defend and hold harmless the Vendor (the &ldquo;Indemnified Party&rdquo;), its successors and assigns from and against any and all claims, suits, demands, actions, proceedings (whether legal or administrative), awards, judgments, penalties, damages, losses, liabilities, costs and expenses (including but not limited to attorney&rsquo;s fees) resulting, directly or indirectly, from any and all claims arising from any actions or failure to act by the Indemnifying Party, its employees or agents, including but not limited to, negligence, breach of this Agreement, misrepresentation and misconduct on the part of the Indemnifying Party, its employees or agents; provided, however, that the foregoing indemnification by Distributor shall not apply to the extent to which any claims, actions or proceedings against Distributor arise out of the design or manufacture of any of the Products.</p>
            <p><br></p>
            <p><strong>21. CONFIDENTIAL INFORMATION</strong></p>
            <p>Both Parties agree to keep confidential and not disclose to a third party or use for any purpose other than as contemplated by this Agreement, all confidential and/or proprietary information of the other Party (&ldquo;Confidential Information&rdquo;) including, but not limited to, pricing, sales, marketing plans, and customer information. &nbsp;Confidential Information shall not include:</p>
            <ul>
                <li style="list-style-type: none;">
                    <ul>
                        <li>Information generally available to the public without any breach of this Agreement or any other agreement between the Parties;</li>
                        <li>Information previously known, without restriction, by the Party the Confidential Information is disclosed to;</li>
                        <li>Information independently developed by a Party outside the scope of this Agreement without use of any of the other Party&rsquo;s Confidential Information; or</li>
                        <li>Information lawfully disclosed without restriction to a third party.</li>
                    </ul>
                </li>
            </ul>
            <p>Each Party shall restrict disclosure of the other Party&rsquo;s Confidential Information to those employees who have a need to know such Information to perform such Party&rsquo;s obligations under this Agreement. &nbsp;Each Party shall not, during the term of this Agreement and thereafter, in perpetuity, disclose the other Party&rsquo;s Confidential Information to any third party without the express written consent of the other Party, unless as required by law. &nbsp;Each Party shall use at least the same degree of care to avoid disclosure of such Confidential Information as it uses with respect to its own Confidential Information.</p>
            <p><br></p>
            <p><br></p>
            <p><strong>22. NON-SOLICITATION OF EMPLOYEES AND CONTRACTORS</strong></p>
            <p>Absent their mutual agreement as evidenced by a writing signed by both Parties, neither Party to this Agreement will, directly or indirectly, engage or assist others to engage in the following activities while this Agreement remains in effect and for eighteen (18) months after this Agreement is terminated: (a) recruit, solicit, hire, employ, engage or retain the services of any Employee or Contractor of the other Party anywhere in the United States of America or Canada; or (b) encourage, induce or convince any Employee or Contractor to end, reduce or detrimentally change the Employee&rsquo;s or Contractor&rsquo;s relationship with the other Party. &nbsp;For purposes of this Section, &ldquo;Employee or Contractor&rdquo; means anyone employed or engaged by a Party to furnish services at any time while this Agreement remains in effect, as well as for a period of twelve (12) months after his or her employment/contractor relationship with such Party ends for any reason.&nbsp;</p>
            <p><br></p>
            <p><strong>23. LIMITATION OF LIABILITY</strong></p>
            <p>Except with respect to a Party&apos;s indemnity obligations under this agreement, or liability arising as a result of a breach of confidentiality, neither Party shall have any liability to the other for any indirect, special, incidental, consequential, punitive, or exemplary damages, including, without limitation, lost profits, arising from any aspect of the relationship provided for herein, even if that Party has been advised of the possibility of such damages.</p>
            <p><br></p>
            <p><strong>24. RELATIONSHIP OF PARTIES</strong></p>
            <p><strong>24.1<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>No Agency:</strong>&nbsp; The relationship of the Parties is that of independent contractors. &nbsp;Nothing set forth in this Agreement shall be construed to create a partnership, joint venture, franchise or agency relationship between Distributor and Vendor, and neither Party shall be liable for the debts, obligations, acts, omissions or responsibilities of the other. &nbsp;Vendor shall not be entitled to share in the profits of Distributor or be required to share in the Distributor&rsquo;s losses or have any ownership or equity interest in Distributor. &nbsp;Neither Party shall act or represent itself, directly or by implication, as an agent of the other Party and shall have no right to enter into contracts or commitments in the name of or on behalf of the other Party or to bind the other Party in any manner, including, but not limited to, any warranties of the Products.</p>
            <p><strong>24.2<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>No Right of Control:</strong>&nbsp; Nothing contained in this Agreement shall grant Vendor any right to exercise any control or discretion over the operation or activities of Distributor, except to the extent necessary to protect Vendor&rsquo;s Trademarks and the goodwill associated therewith. &nbsp;Vendor shall not be responsible for any act, obligation or default Distributor, its employees or agents shall commit. &nbsp;</p>
            <p><br></p>
            <p><strong>25. NOTICES</strong></p>
            <p>Any and all notices required or permitted to be given to a Party pursuant to the provisions of this Agreement will be in writing and will be effective and deemed to provide such Party notice under this Agreement on the earliest of the following: &nbsp;(i) at the time of personal delivery; (ii) at the time of e-mail notification addressed to the other Party at its contact email, specified herein (or hereafter modified by subsequent notice to the Parties hereto), with confirmation of receipt made by printed confirmation sheet verifying successful receipt of the email notification; (iii) 1 business day after deposit with an express overnight courier for United States deliveries, or 2 business days after such deposit for deliveries outside of the United States specifying next business day delivery; or (iv) 3 business days after deposit in the United States mail by certified mail (return receipt requested) for United States deliveries. All notices for delivery outside the United States will be sent by email or by express courier. &nbsp;</p>
            <p><br></p>
            <p><strong>26. FORCE MAJEURE</strong></p>
            <p>Neither Party will be liable to the other Party for any delay, error, failure in performance or interruption of performance resulting from causes beyond its reasonable control, including, but not limited to, work stoppages, fires, civil disobedience, earthquakes, floods, acts of God and similar occurrences. &nbsp;If a force majeure condition occurs, the Party delayed or unable to perform shall give immediate notice to the other Party of the happening of such condition and the expected duration of the force majeure condition and use its best efforts to mitigate the condition&rsquo;s effects and perform its obligations hereunder.</p>
            <p><br></p>
            <p><strong>27. TERMINATION</strong></p>
            <p><strong>27.1<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Termination With or Without Cause:</strong>&nbsp; Either Party may terminate this Agreement with or without cause upon thirty (30) days&rsquo; advance written notice to the other Party.</p>
            <p><strong>27.2<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Effect of Termination:</strong>&nbsp; Upon termination of this Agreement for any reason:</p>
            <p style="margin-left: 40px;">(a)<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Return of Vendor Products Upon Vendor&apos;s Termination: &nbsp;Any Products remaining in Distributor&rsquo;s inventory upon the Vendor&apos;s termination of this Agreement, may at Distributor&rsquo;s option, be repurchased by Vendor from Distributor at the price paid by Distributor for such Products, excluding applicable customs, duties, taxes and freight charges. The Products for repurchase must be in new, undamaged and saleable condition. Products must be received by the Vendor Prior to payment for same. For the avoidance of any doubt, in the event that the Distributor elects to terminate this Agreement, the Distributor shall not be eligible to return any Products remaining in Distributor&rsquo;s inventory, or receive any refund or other payment for such inventory, without the written consent of the Vendor.&nbsp;</p>
            <p style="margin-left: 40px;">(b)<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Outstanding Orders: &nbsp;All Orders from Distributor or portions thereof remaining unshipped as of the effective date of termination may be cancelled or shipped within 10 days of the effective date of termination, at Distributor&rsquo;s option.</p>
            <p style="margin-left: 40px;">(c)<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Trademarks: &nbsp;Distributor shall immediately cease all representations that it is a distributor of Vendor and discontinue use of the Trademarks as of the effective date of termination of this Agreement, except as may be necessary to sell or liquidate any Product remaining in Distributor&rsquo;s inventory.</p>
            <p><br></p>
            <p><strong>28.<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>NON-DISPARAGEMENT</strong></p>
            <p>The Vendor and the Distributor agree that during the term of this Agreement, and after the Agreement has terminated, without limitation, neither Party shall not disparage or defame the other Party in any respect, in any format, manner or media, including &quot;Social Media&quot;, written or oral, or make any derogatory comments, regarding the other Party or its current or former officers, directors, employees, attorneys, agents, or contracting Parties, or its business or operations.</p>
            <p><br></p>
            <p><strong>29.<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>MISCELLANEOUS</strong></p>
            <p><strong>29.1<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Assignment:&nbsp;</strong> Neither Party shall delegate any duties, nor assign any rights or claims under this Agreement without the prior written consent of the other Party, and any such attempted delegation or assignment shall be void; provided, however, either Party may assign this Agreement (inclusive of its rights, obligations and duties hereunder) through a sale, merger or consolidation or in connection with any sale of substantially all of its business. &nbsp;This Agreement shall be binding upon and inure to the benefit of the Parties and their respective successors and assigns.</p>
            <p><br></p>
            <p><strong>29.2<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>No Waiver: </strong>&nbsp;A failure by either Party to exercise, or delay in exercising, any right or power &nbsp; conferred upon it by this Agreement shall not operate as a waiver of any such right or power.</p>
            <p><br></p>
            <p><strong>29.3<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Severability: </strong>&nbsp;This Agreement is divisible, and any provisions herein held to be in violation of any applicable treaties, statutes, or regulations of any governmental agency having jurisdiction shall affect only that portion held to be invalid or inoperative, and the remaining portions of this Agreement shall remain in full force and effect.</p>
            <p><strong>29.4<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Entire Agreement: </strong>This Agreement and the accompanying Exhibits set forth the entire agreement and understanding between Vendor and Distributor as to the subject matter hereof and supersedes any and all prior discussions, agreements, and understandings of every kind and nature. &nbsp;No modification, amendment, rescission, waiver, or other change in these terms and conditions shall be binding upon either Party unless agreed in writing by authorized representatives of each Party. &nbsp;The attached Exhibits are incorporated herein by reference.</p>
            <p><strong>29.5 &nbsp; &nbsp; &nbsp;Governing Law: </strong>This Agreement will be governed by and construed in accordance with the laws of the Province of British Columbia and federal laws of Canada applicable therein.</p>
            <p><br></p>
            <p><strong>29.6 &nbsp; &nbsp; &nbsp;Headings:</strong> The section headings appearing in this Agreement are inserted only as a matter of convenience and in no way define, limit, construe, or describe the scope or extent of such section or in any way affect this Agreement.</p>
            <p><br></p>
            <p><strong>29.7<span style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span>Rule of Construction:</strong> The Parties have been represented by counsel in the negotiation and drafting of this Agreement; therefore, no rule of construction requiring interpretation against the draftsman hereof shall apply in the interpretation of this Agreement.</p>
            <p><strong>29.8 &nbsp; &nbsp;Cooperation: </strong>&nbsp;The Parties agree to cooperate with each other and to execute any and all further or necessary documents as may be reasonably requested by either Party in order to implement and carry out the purposes and intent of this Agreement.</p>
            <p><br></p>
            <p>IN WITNESS WHEREOF, the Parties have executed this Distribution Agreement as of the Effective Date. &nbsp;</p>
            <p><strong>30. TERM.</strong> The term of this Agreement is for a period of one (1) year from the date hereof and will be automatically renewed unless terminated at least 30 days prior to its expiration date. This Agreement may be terminated immediately by facsimile, email or telegram upon a breach of its terms and conditions by the non-breaching party.&nbsp;</p>
            <p>Distributor shall immediately terminate and shall return all Product(s) to Vendor within five (5) days from the date of termination. Upon termination, Distributor shall immediately cease to represent himself/herself as a Distributor of Products supplied by Vendor and shall not thereafter advertise or hold himself/herself out to any person as a Distributor affiliated with Vendor. Neither party shall be liable to the other for any damages or losses resulting from such cessation of business dealings, nor shall it operate as a cancellation of any indebtedness owing to one party by the other at the time.</p>
            <p><br></p>
            <p><strong>NOTICE TO DISTRIBUTOR</strong></p>
            <p><strong>DO NOT SIGN THIS AGREEMENT</strong></p>
            <p><strong>WITHOUT READING THE FOLLOWING</strong></p>
            <p>The foregoing agreement defines your relationship to the Vendor as that of an independent contractor and not as that of an employee of the Vendor. As such, you alone are solely responsible for:</p>
            <p style="margin-left: 40px;">1. Payment of any self-employment taxes which may be due by virtue of earnings you make as an independent contractor; and</p>
            <p style="margin-left: 40px;">2. Payment of any income taxes which may be due as a result of compensation you earn as an independent contractor under the terms of this Agreement.</p>
            <p style="margin-left: 40px;">3.Payment of any EI, CPP, WCB or other premiums, taxes or assessments which may be due as a result of compensation you earn as an independent contractor under the terms of the referred contract.</p>
            <p><br></p>
            <p>The undersigned acknowledges reading the forgoing notice today as checking &quot;I Agree&quot; and signing electronically. &nbsp;</p>
            <p><br></p>
            <p><strong>31.ELECTRONIC SIGNATURE</strong></p>
            <p>All information communicated on the Website is considered an electronic communication. &nbsp;When you communicate with Byte Health Management Corporation through or on the Website or via other forms of electronic media, such as e-mail, you are communicating with the company electronically. &nbsp;You agree that we may communicate electronically with you and that such communications, as well as notices, disclosures, agreements, and other communications that we provide to you electronically, are equivalent to communications in writing and shall have the same force and effect as if they were in writing and signed by the party sending the communication.</p>
            <p><br></p>
            <p><strong>32. SECTION 24 &ndash; TERMINATION</strong></p>
            <p>This Agreement will take effect (or shall re-take effect) at the time you click &ldquo;ACTIVATE MY ACCOUNT NOW,&rdquo; &ldquo;PAY NOW,&rdquo; &ldquo;ORDER NOW&rdquo;, &ldquo;SUBMIT&rdquo;, &ldquo;BUY NOW&rdquo;, &ldquo;PURCHASE&rdquo;, &ldquo;I ACCEPT&rdquo;, &ldquo;I AGREE&rdquo; or similar links or buttons, otherwise submit information through the Website, respond to a request for information, begin installing, accessing, or using the Website, complete a purchase, select a method of payment, and/or enter in payment method information, whichever is earliest. &nbsp;If, in our sole discretion, you fail, or we suspect that you have failed, to comply with any term or provision of the Agreement or violated any law, whether in connection with your use of bytemanage.com or otherwise, we may terminate the Agreement or suspend your access to the Website at any time without notice to you. Any representations, warranties, and other obligations made or undertaken by you, shall survive the termination of this Agreement and/or your account or relationship with Byte Health Management. Upon termination, you remain responsible for any outstanding payments to Byte Health Management Corporation.</p>
            <br>
            <br>
            <div class="col-sm-12">
              <div style="text-align: center; margin: auto;">
                <button mat-stroked-button color="warn" class="btn submit-button mr-2" [disabled]="!complete_read" (click)="activeModal.close('close')">{{ "DECLINEBTN" | translate}}</button>
                <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!complete_read" (click)="activeModal.dismiss('finished')">{{ "ACCEPTBTN" | translate}}</button>
              </div>
            </div>
            <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
    
    
</div>