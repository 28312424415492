<div class="form-popup-container center">
    <div class="row headrow">
        <div class="col-10 form-popup-header">
          <span></span>
          <h3> &nbsp;</h3>
        </div>
        <div class="col-2 form-popup-header">
          <span></span>
          <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
              (click)="activeModal.close('Close click')" alt="close"></span>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()" class="submitform" autocomplete="l-form">
            <div ngbAutofocus class="tatlestyle">{{ "LOGINTOEWALLET" | translate}}</div>
            <div class="row contactarea">
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div class="form-group">
                        <mat-form-field style="width: 100%;">
                            <div class="input-group">
                                <input matInput formControlName="password" [type]="fieldTextType ? 'text' : 'password'" 
                                    class="form-control text-box" id="InputPW" aria-describedby="passwordHelp"
                                    placeholder="{{ 'PASSWORD' | translate }}" name="password" maxlength="60"
                                    autocomplete="off"
                                >
                                 <div class="input-group-append">
                                   <span class="input-group-text">
                                     <i
                                       class="fa"
                                       [ngClass]="{
                                         'fa-eye-slash': !fieldTextType,
                                         'fa-eye': fieldTextType
                                       }"
                                       (click)="toggleFieldTextType()"
                                     ></i>
                                   </span>
                                 </div>
                            </div>
                            <mat-error class="error-message" *ngIf="loginForm.get('password').hasError('required')" >{{ "PASSISREQ" | translate }} </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 form-popup-form mb-2">
                <div style="text-align: center; margin: auto;">
                  <button mat-stroked-button color="primary" class="btn submit-button" [disabled]="!loginForm.valid" (click)="ewalletSignIn()" >{{ "SUBMIT" | translate}}</button>
                </div>
            </div>
            <div class="d-flex justify-content-center">

                <a style="color: skyblue;cursor: pointer;" (click)="openEwalletForgot()" translate>FORGOTPASS</a>
            </div>
        </form>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>