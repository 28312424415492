<div class="print-delivery-ticket-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
                
              <h3 class="title">{{'PRINTDELIVERYTICKET' | translate}}</h3>
              <div class="text-center mb-2">

                      <mat-form-field style="width: 100%;">
                          <mat-label>{{'GROUPID' | translate}}</mat-label>
                          <mat-select type="text" class="form-control" id="groupId" aria-describedby="groupId"
                          autocomplete="off">
                              <mat-option  *ngFor="let order of orders; index as i;" [value]="order.orderGroupId" (click)="onOrderSelect(i)">{{ order.orderGroupId }}</mat-option>
                          </mat-select>
                          
                      </mat-form-field>
              </div>
              <div class="table-responsive mb-3" *ngIf="selectedOrder">
                
                  <table class="table table-bordered">
                    <thead>
                        <tr>
                          
                          <th scope="col">{{'ORDERDETAILS' | translate}}</th>
                          <th scope="col">{{'ADDITIONALDATA' | translate}}</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                            <td>
                                <tr>
                                    <th style="min-width: 150px;">
                                        {{ 'GROUPID' | translate }}
                                    </th>
                                    <td style="width: 90%;">
                                        {{ selectedOrder.orderGroupId }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'CONTACTPERSON' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.contactPerson }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'CONTACTPHONE' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.contactPhone }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'EMAILADR' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.contactEmail }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'POSTALCODELABEL' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.PostCode }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'COUNTRY' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.Country }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'PROVINCE' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.Province }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'CITYLABEL' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.City }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'ADDRESS' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.deliveryAddress }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{ 'PURCHDATE' | translate }}
                                    </th>
                                    <td>
                                        {{ selectedOrder.AddTime }}
                                    </td>
                                </tr>
                                <br>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>{{ 'PRODUCTITLE' | translate }}</th>
                                                <th>{{ 'PRODUCTDESC' | translate }}</th>
                                                <th>{{ 'SKUNUMBER' | translate }}</th>
                                                <th>{{ 'QUANTITY' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let selectedProduct of selectedProducts; index as i;">
                                                <td>{{ selectedProduct.pTitle }} </td>
                                                <td>{{ selectedProduct.pContent }}<br>{{ selectedProduct.benefit_name }}&nbsp;{{ selectedProduct.benefit_value }} </td>
                                                <td>{{ selectedProduct.SKU }} </td>
                                                <td>{{ selectedProduct.pNums }} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td>
                                <form [formGroup]="orderForDeliveryForm" (keydown.enter)="$event.preventDefault()" autocomplete="ofd-form">
                                <tr>
                                    <th style="min-width: 150px;">
                                        {{'DELIVERYDATE' | translate}}
                                    </th>
                                    <td style="width: 90%;">
                                        <input required formControlName="deliveryDate"  type="date" class="form-control">
                                        <!-- <mat-error class="error-message" *ngIf="orderForDeliveryForm.get('deliveryDate').hasError('required')">{{ 'DELIVERYDATEREQ' | translate }}</mat-error> -->
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{'FROMTIME' | translate}}
                                    </th>
                                    <td>
                                        <input formControlName="fromTime"  type="time" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {{'TOTIME' | translate}}
                                    </th>
                                    <td>
                                        <input formControlName="toTime"  type="time" class="form-control">
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <th>
                                        {{'NOTECOMMENT' | translate}}
                                    </th>
                                    <td>
                                        <textarea formControlName="noteComment"  rows="5" class="form-control"></textarea>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <th>
                                        {{'DELIVERED' | translate}}
                                    </th>
                                    <td style="padding-left: 30px">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" formControlName="delivered" >
                                        <!-- <select formControlName="delivered"  class="form-select form-control">
                                            <option value="0">NO</option>
                                            <option value="1">YES</option>
                                        </select> -->
                                    </td>
                                </tr>
                                <tr *ngIf="!isPrinting || !selectedOrder.delivered">
                                    <th>
                                        {{'ACTION' | translate}}
                                    </th>
                                    <td>
                                        <button class="btn btn-info mr-1" (click)="onSave()" *ngIf="!selectedOrder.delivered">{{'SAVE' | translate}}</button>
                                        <button class="btn btn-primary"  (click)="onPrint()" *ngIf="!selectedOrder.delivered">{{'PRINT' | translate}}</button>
                                    </td>
                                </tr>

                                </form>
                            </td>
                        </tr>
                      </tbody>
                    </table>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="!isPrinting">
            <div class="card mb-3">
                <div class="card-body">
                    <h3 class="title">{{'DELIVERYTICKETS' | translate}}</h3>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">{{'GROUPID' | translate}}</th>
                                <th>{{'CONTACTPERSON' | translate}}</th>
                                <th>{{'CONTACTPHONE' | translate}}</th>
                                <!-- <th>{{'POSTALCODELABEL' | translate}}</th>
                                <th>{{'COUNTRYLABEL' | translate}}</th>
                                <th>{{'PROVINCELABEL' | translate}}</th>
                                <th>{{'CITYLABEL' | translate}}</th> -->
                                <th>{{'ADDRESSLABEL' | translate}}</th>
                                <th>{{'DELIVERYDATE' | translate}}</th>
                                <th>{{'FROMTIME' | translate}}</th>
                                <th>{{'TOTIME' | translate}}</th>
                                <th>{{'DELIVERED' | translate}}</th>
                                <th>{{'PRINTED' | translate}}</th>
                                <th>{{'NOTECOMMENT' | translate}}</th>
                                <th>{{'ADDTIME' | translate}}</th>
                                <th>{{'ACTION' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let ticket of tickets; index as i;">
                                  <td style="min-width: 150px;">{{ ticket.orderGroupId }}</td>
                                  <td>{{ ticket.contactPerson }}</td>
                                  <td>{{ ticket.contactPhone }}</td>
                                  <!-- <td>{{ ticket.PostCode }}</td>
                                  <td>{{ ticket.Country }}</td>
                                  <td>{{ ticket.Province }}</td>
                                  <td>{{ ticket.City }}</td> -->
                                  <td>{{ ticket.deliveryAddress }}</td>
                                  <td>{{ ticket.deliveryDate }}</td>
                                  <td>{{ transform(ticket.fromTime) }}</td>
                                  <td>{{ transform(ticket.toTime) }}</td>
                                  <td>{{ ticket.delivered }}</td>
                                  <td>{{ ticket.printed }}</td>
                                  <td>{{ ticket.noteComment }}</td>
                                  <td style="min-width: 200px;">{{ ticket.AddTime }}</td>
                                  <td> <button class="btn btn-info" (click)="onView(i)" >{{'VIEW' | translate}}</button></td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                  </div>
            </div>
          </div>
    </div>
</div>