<div class="user-profile-container">
        
        
        
        <div class="row gutters-sm">
          <div class="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img [src]=" user.photoPath ? user.photoPath : './../../../../assets/images/icons/user.png' " alt="Admin" class="rounded-circle user-image" width="150" height="150">
                  
                  <div class="mt-3">
                    <h4>{{ user.first_name }} {{ user.last_name }}</h4>
                    <!-- <p class="text-secondary mb-1">Full Stack Developer</p>
                    <p class="text-muted font-size-sm">Bay Area, San Francisco, CA</p> -->
                   
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-3">
              <div class="card-body">
                <h2 translate>ACCOUNTINFO</h2>
                <br>
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'MEMBERID' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.id }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'EMAIL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.email }}
                  </div>
                </div>
                <hr>
                <div class="row" *ngIf="!(user.id >= 10002 && user.id <= 10005)">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'SPONSOR' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.Recommended }}
                  </div>
                  
                </div>
                <hr *ngIf="!(user.id >= 10002 && user.id <= 10005)">
                <div class="row" *ngIf="!(user.id >= 10002 && user.id <= 10005)">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'PLACER' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.Referrals }}
                  </div>
                </div>
                <hr *ngIf="!(user.id >= 10002 && user.id <= 10005)">
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'LASTLOGINDT' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.lastLogDate ? sharedUtilService.showDateInClientSideFormat(user.lastLogDate) : '' }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'LASTLOGINIP' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.lastLogIP }}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'REGISTERDT' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.created_at ? sharedUtilService.showDateInClientSideFormat(user.created_at) : '' }}
                  </div>
                </div>
                <hr>
                <!-- <div class="row">
                  <div class="col-sm-4">
                    <h4 class="mb-0 label">{{'LOCKED' | translate}}</h4>
                  </div>
                  <div  class="col-sm-8 text-secondary">
                    {{ user.isLocked ? 'LOCKED' : 'UNLOCKED' }}
                  </div>
                </div>
                <hr> -->
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <form  [formGroup]="profileForm" (keydown.enter)="$event.preventDefault()" autocomplete="p-form">
            <div class="card mb-3">
              <div class="p-3">
                <div class="row">
                  <div class="col-sm-6">
                    <h2 class="label">{{'PROFILEINFOLABEL' | translate}}</h2>
                  </div>
                  <div class="col-sm-6">
                    <div class="d-flex flex-row-reverse mb-2">
                      <button mat-stroked-button *ngIf="!isEditMode"  (click)="onProfileEdit()" color="primary" class="align-right">{{ 'EDITPROFILEBTN' | translate}}</button>
                      <button mat-stroked-button *ngIf="isEditMode" (click)="onProfileSave()" [disabled]="!profileForm.valid" color="access" class="align-right">{{ 'SAVEPROFILEBTN' | translate}}</button>
                      <button mat-stroked-button *ngIf="isEditMode" (click)="onProfileDiscard()"  color="warn" class="align-right mr-2">{{ 'DISCARDPROFILEBTN' | translate}}</button>
                    </div>
                  </div>
                </div>
               
                
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'USERSTRID' | translate}}</h4>
                  </div>
                  <div class="col-sm-9 text-secondary">
                     {{ user.userStrId }}
                  </div>
                  <!-- <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="last_name"  type="text" class="form-control text-box" id="last_name" aria-describedby="last_name"
                      [(ngModel)]="user.last_name" value="{{user.last_name}}" name="last_name" maxlength="60"
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('last_name').hasError('required')">{{'LASTNAMEREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div> -->
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'FIRSTNAMELABEL' | translate}}</h4>
                  </div>
                  <div  class="col-sm-9 text-secondary">
                    {{ user.first_name }}
                  </div>
                  <!-- <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="first_name"  type="text" class="form-control text-box" id="first_name" aria-describedby="firstNameHelp"
                      [(ngModel)]="user.first_name" name="first_name" maxlength="60" 
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('first_name').hasError('required')">{{'FIRSTNAMEREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div> -->
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'LASTNAMELABEL' | translate}}</h4>
                  </div>
                  <div class="col-sm-9 text-secondary">
                     {{ user.last_name }}
                  </div>
                  <!-- <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="last_name"  type="text" class="form-control text-box" id="last_name" aria-describedby="last_name"
                      [(ngModel)]="user.last_name" value="{{user.last_name}}" name="last_name" maxlength="60"
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('last_name').hasError('required')">{{'LASTNAMEREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div> -->
                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'SEXLABEL' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                    {{ user.Sex == 1 ? "Male" : (user.Sex == 2 ? "Female" : "Others")   }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <mat-select formControlName="Sex"  type="text" class="form-control text-box" id="sex" aria-describedby="sex"
                     name="sex" 
                      autocomplete="off">
                        <mat-option [value]="1">
                          Male
                        </mat-option>
                        <mat-option [value]="2">
                          Female
                        </mat-option>
                        <mat-option [value]="3">
                          Others
                        </mat-option>
                      </mat-select>
                      <mat-error class="error-message" *ngIf="profileForm.get('Sex').hasError('required')">{{'SEXREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                      <h4 class="mb-0 label">{{'BIRTHDATELABEL' | translate}}</h4>
                    </div>
                    <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                      {{ user.Birthday ? user.Birthday.substring(0,10) : user.Birthday }}
                    </div>
                    <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-form-field style="width: 100%;">
                        <input matInput formControlName="Birthday"  type="date" class="form-control text-box" id="birthday" aria-describedby="birthday"
                        name="birthday" maxlength="60"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="profileForm.get('Birthday').hasError('required')">{{'BIRTHDATEREQ' | translate}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'TELEPHONELABEL' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode"  class="col-sm-9 text-secondary">
                    {{ user.Tel }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="Tel"  type="text" class="form-control text-box" id="tel" aria-describedby="tel"
                       name="tel" maxlength="60"
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('Tel').hasError('required')">{{'TELEPHONEREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'MOBILELABEL' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode"  class="col-sm-9 text-secondary">
                    {{ user.mobile }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="mobile"  type="text" class="form-control text-box" id="mobile" aria-describedby="mobile"
                       name="mobile" maxlength="60"
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('mobile').hasError('required')">{{'MOBILEREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                      <h4 class="mb-0 label">{{'LANGUAGELABEL' | translate}}</h4>
                    </div>
                    <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                      {{ user.language }}
                    </div>
                    <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-form-field style="width: 100%;">
                        <mat-select formControlName="language"  type="text" class="form-control text-box" id="language" aria-describedby="language"
                       name="language" 
                        autocomplete="off">
                          <mat-option *ngFor="let language of languages, let i = index" [value]="language.name">
                            {{ language.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="profileForm.get('language').hasError('required')">{{'LANGUAGEREQ' | translate}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <hr>

                  <div class="row">
                    <div class="col-sm-3">
                      <h4 class="mb-0 label">{{'EDUCLEVEL' | translate}}</h4>
                    </div>
                    <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                      {{ user.education_level }}
                    </div>
                    <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-form-field style="width: 100%;">
                        <mat-select formControlName="education_level"  class="form-control text-box" id="education_level" aria-describedby="education_level"
                       name="education_level" 
                        autocomplete="off">
                          <mat-option *ngFor="let education_level of education_levels, let i = index" [value]="education_level.value">
                            {{ education_level.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <h4 class="mb-0 label">{{'ANNUALINCOME' | translate}}</h4>
                    </div>
                    <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                      {{ user.annual_income }}
                    </div>
                    <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-form-field style="width: 100%;">
                        <input matInput formControlName="annual_income"  type="number" class="form-control text-box" id="annual_income" aria-describedby="annual_income"
                        name="annual_income" maxlength="60"
                        autocomplete="off"
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'ADDRESSLABEL' | translate}}</h4>
                  </div>
                  <div  *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                    {{ user.Address }} , {{ user.City }} , {{ user.Province }} , {{ user.Country }} , {{ user.PostCode }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <input matInput formControlName="Address"  type="text" class="form-control text-box" id="address" aria-describedby="address"
                      placeholder="{{'ADDRESSLABEL' | translate}}"  name="address"
                      autocomplete="off"
                      >
                      <mat-error class="error-message" *ngIf="profileForm.get('Address').hasError('required')">{{'ADDRESSREQ' | translate}}</mat-error>
                    </mat-form-field>
                    <div class="row">
                      <div class="col-sm-3">
                        <mat-form-field>
                          <input matInput formControlName="City"  type="text" class="form-control text-box" id="city" aria-describedby="city"
                          placeholder="{{'CITYLABEL' | translate}}"  name="city" maxlength="60"
                          autocomplete="off"
                          >
                          <mat-error class="error-message" *ngIf="profileForm.get('City').hasError('required')">{{'CITYREQ' | translate}}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field>
                          <mat-select formControlName="Country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                          placeholder="{{'COUNTRYLABEL' | translate}}" name="country">
                            <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                              {{ country.country }}
                            </mat-option>
                          </mat-select>
                          <mat-error class="error-message" *ngIf="profileForm.get('Country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field>
                          <mat-select formControlName="Province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                          placeholder="{{'PROVINCELABEL' | translate}}"  name="province">
                          <ng-container *ngFor="let province of provinces, let i = index">
                            <mat-option [value]="province.state_name">
                              {{ province.state_name }}
                            </mat-option>
                          </ng-container>
                           
                          </mat-select>
                          <mat-error class="error-message" *ngIf="profileForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3">
                        
                        <mat-form-field>
                          <input matInput formControlName="PostCode"  type="text" class="form-control text-box" id="postcode" aria-describedby="postcode"
                          placeholder="{{'POSTALCODELABEL' | translate}}" name="postcode" maxlength="60"
                          autocomplete="off"
                          >
                          <mat-error class="error-message" *ngIf="profileForm.get('PostCode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                        </mat-form-field>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'ASKSECRETQ' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                    {{ user.askSecretQ ? 'YES' : 'NO' }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                    <mat-form-field style="width: 100%;">
                      <mat-select formControlName="askSecretQ" (selectionChange)="askSecretQClick()"  type="text" class="form-control text-box" id="askSecretQ" aria-describedby="askSecretQ"
                     name="askSecretQ" 
                      autocomplete="off">
                        <mat-option [value]="1">
                          YES
                        </mat-option>
                        <mat-option [value]="0">
                          NO
                        </mat-option>
                      </mat-select>
                      <mat-error class="error-message" *ngIf="profileForm.get('askSecretQ').hasError('required')">{{'ASKSECRETQREQ' | translate}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <hr>
                <div class="row mb-1">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'DISTRIBUTOR' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                    {{ user.isDistributor ? 'YES' : 'NO' }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-checkbox formControlName="isDistributor" (change)="distrib_click()" *ngIf="!user.isDistributor">{{ "DISTRIBTERMS" | translate}}</mat-checkbox>
                      <ng-container *ngIf="user.isDistributor">
                        {{ user.isDistributor ? 'YES' : 'NO' }}
                      </ng-container>
                  </div>
                </div>
                <hr>
                <!--
                <div class="row mt-1">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'AUTOSHIP' | translate}}</h4>
                  </div>
                  <div class="col-sm-9">
                      <mat-checkbox
                          class="checbox-margin"
                          [(ngModel)]="isInactived"
                          labelPosition="after"
                          (change)="autoship_click($event)"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="!isEditMode"
                          >
                          {{ 'AUTOSHIPTITLE' | translate }}
                      </mat-checkbox>
                  </div>
                </div>
                -->
                              
<!----
                <div class="row">
                  <div class="col-sm-3">
                    <h4 class="mb-0 label">{{'AUTOSHIP' | translate}}</h4>
                  </div>
                  <div *ngIf="!isEditMode" class="col-sm-9 text-secondary">
                    {{ user.autoship ? 'YES' : 'NO' }}
                  </div>
                  <div *ngIf="isEditMode" class="col-sm-9">
                      <mat-checkbox formControlName="autoship" (change)="distrib_click()" *ngIf="!user.autoship">{{ "AUTOSHIPTITLE" | translate}}</mat-checkbox>
                      <ng-container *ngIf="user.autoship">
                        {{ user.autoship ? 'YES' : 'NO' }}
                      </ng-container>
                  </div>
                </div>
              -->

              </div>
            </div>
          
          </form>
          </div>
          
        </div>
        

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Camera</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row">
          <div class="col-md-12">
            <div class="snapshot" *ngIf="selectedFile">
              <!-- <h2>Take your image or get another</h2> -->
              <img [width]="300" [height]="300" [src]="selectedFile.src"/>
            </div>
            <label class="image-upload-container btn btn-primary m-2">
              <span>SELECT PHOTO</span>
              <input #imageInput
                     type="file"
                     accept="image/*"
                     (change)="processFile(imageInput)">
            </label>
              <button 
                class="btn btn-success m-2"
                [disabled]="!selectedFile"
                (click)="uploadPhoto(selectedFile.src)"
              >UPLOAD PHOTO</button>
          </div>
        </div>
        
        <hr>
        <h3>OR</h3>
        <hr>
        <h3>TAKE PICTURE FROM CAMERA</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="camera-container">
              <webcam [height]="300" [width]="300"  [allowCameraSwitch]="true" (initError)="handleInitError($event)"  [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
            </div>
            <div class="p-2">
              <button  type="button" class="btn btn-primary align-center" (click)="triggerSnapshot();">CAPTURE</button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="snapshot" *ngIf="webcamImage">
              <!-- <h2>Take your image or get another</h2> -->
              <img [src]="webcamImage.imageAsDataUrl"/>
            </div>
            <div class="p-2">
              <button  type="button" [disabled]="!webcamImage" class="btn btn-success align-center" (click)="uploadPhoto(webcamImage.imageAsDataUrl);">UPLOAD PHOTO</button>
            </div>
          </div>
        </div>
        
        
        <!-- Snapshot Div where image will be shown -->
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
</div>