import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { InvoiceLinkInfo } from 'src/app/modules/common/InvoiceLink';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @Input() translate : TranslateConfigService;
  @Input() invoiceLink : InvoiceLinkInfo=null;

  constructor(
    public activeModal : NgbActiveModal,
    private modalService: NgbModal,
    public router : Router,
    public ngDialog : NgbModal,
    public route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    if (! this.invoiceLink) {
      this.invoiceLink = new InvoiceLinkInfo();
      this.invoiceLink.pTitle='test';
    }
  }

}
