import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-print-delivery-ticket',
  templateUrl: './print-delivery-ticket.component.html',
  styleUrls: ['./print-delivery-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintDeliveryTicketComponent implements OnInit {

  private _unsubscribe$ = new Subject<any>();
  public orderForDeliveryForm : FormGroup;
  public selectedOrder;
  public orders = [];
  public tickets = [];
  public isPrinting = false;
  public selectedProducts = [];
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
    private cdr : ChangeDetectorRef,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.getOrders();
    this.getDeliveryTickets();
    this.getPrintingStatus();
  }

  initForms()
  {
    this.orderForDeliveryForm = this.formBuilder.group({
      noteComment: ['', []],
      deliveryDate : [(new Date()).toISOString().substring(0,10), [Validators.required]],
      fromTime: ['', []],
      toTime: ['', []],
      delivered: [0, []],
    });

    let _this = this;
    window.onafterprint = function(){
      _this.shareinfoService.setIsPrinting(false);
   }
  }

  getPrintingStatus()
  {
    this.shareinfoService.isPrinting
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.isPrinting = successResponse;
              this.cdr.detectChanges();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  onView(index)
  {
    this.selectedOrder = this.tickets[index];

    this.getDeliveryProducts(this.selectedOrder.orderGroupId);
    this.orderForDeliveryForm.patchValue({
      noteComment: this.tickets[index].noteComment,
      deliveryDate : this.tickets[index].deliveryDate,
      fromTime:  this.tickets[index].fromTime,
      toTime:  this.tickets[index].toTime,
      delivered: this.tickets[index].delivered,
    })
    this.cdr.detectChanges();

    if(this.selectedOrder.delivered){
      this.orderForDeliveryForm.disable();
    }else{
      this.orderForDeliveryForm.enable();
    }


  }

  onOrderSelect(index)
  {
    this.selectedOrder = this.orders[index];

    this.getDeliveryProducts(this.selectedOrder.orderGroupId);

    if(this.selectedOrder.delivered){
      this.orderForDeliveryForm.disable();
    }else{
      this.orderForDeliveryForm.enable();
      this.orderForDeliveryForm.patchValue({
        noteComment: '',
        deliveryDate : '',
        fromTime: '',
        toTime: '',
        delivered: '',
      });
    }

    //console.log(index);
  }

  getOrders()
  {
    this.evgService.pickOrderForDelivery({})
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log(successResponse);
              this.orders = successResponse.data[0].results;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getDeliveryProducts(orderGroupId)
  {
    this.evgService.getDeliveryProducts({orderGroupId : orderGroupId})
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log(successResponse);
              this.selectedProducts = successResponse.data[0].results;
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }
  
  transform(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am';
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  onSave(){
    //console.log(this.orderForDeliveryForm.value);
    if(this.selectedOrder){
      let data = {
        City: this.selectedOrder.City,
        Country: this.selectedOrder.Country,
        PostCode: this.selectedOrder.PostCode,
        Province: this.selectedOrder.Province,
        contactPerson: this.selectedOrder.contactPerson,
        contactPhone: this.selectedOrder.contactPhone,
        contactEmail: this.selectedOrder.contactEmail,
        deliveryAddress: this.selectedOrder.deliveryAddress,
        orderGroupId : this.selectedOrder.orderGroupId,
        noteComment: this.orderForDeliveryForm.value.noteComment,
        deliveryDate :this.orderForDeliveryForm.value.deliveryDate,
        fromTime: this.orderForDeliveryForm.value.fromTime,
        toTime: this.orderForDeliveryForm.value.toTime,
        delivered: this.orderForDeliveryForm.value.delivered,
      };
      this.evgService.saveDeliveryTicket(data)
      .pipe( takeUntil(this._unsubscribe$) )
          .subscribe(
            (successResponse: any) => {
              if(successResponse){
                //console.log(successResponse);

                this.responseHandlerService.handleResponse(successResponse);
                this.selectedOrder = null;
                this.getOrders();
                this.getDeliveryTickets();
              }
            },
            (errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
      );
    }
    
  }


  onPrint(){
    this.shareinfoService.setIsPrinting(true);
    this.cdr.detectChanges();
    window.print();
    if(this.selectedOrder){
      let data = {
        City: this.selectedOrder.City,
        Country: this.selectedOrder.Country,
        PostCode: this.selectedOrder.PostCode,
        Province: this.selectedOrder.Province,
        contactPerson: this.selectedOrder.contactPerson,
        contactPhone: this.selectedOrder.contactPhone,
        contactEmail: this.selectedOrder.contactEmail,
        deliveryAddress: this.selectedOrder.deliveryAddress,
        orderGroupId : this.selectedOrder.orderGroupId,
        noteComment: this.orderForDeliveryForm.value.noteComment,
        deliveryDate :this.orderForDeliveryForm.value.deliveryDate,
        fromTime: this.orderForDeliveryForm.value.fromTime,
        toTime: this.orderForDeliveryForm.value.toTime,
        delivered: this.orderForDeliveryForm.value.delivered,
      };
      this.evgService.printDeliveryTicket(data)
      .pipe( takeUntil(this._unsubscribe$) )
          .subscribe(
            (successResponse: any) => {
                if(successResponse){
                  this.responseHandlerService.handleResponse(successResponse);
                  this.getOrders();
                  this.getDeliveryTickets();
                }
            },
            (errorResult) => {
              console.log("System Error", errorResult);
              this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
            }
      );
    }

    
  }
  getDeliveryTickets()
  {
    this.evgService.getDeliveryTickets({})
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              //console.log("DT",successResponse);
              this.tickets = successResponse.data[0].results;
              this.cdr.detectChanges();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

}
