<div class="secret-question-setting-container">

    <div class="card">
        <div class="card-header">
            <div class="row headrow">
                <div class="col-10 form-popup-header">
                  <span></span>
                  <h3> &nbsp;</h3>
                </div>
                <div class="col-2 form-popup-header">
                  <span></span>
                  <span><img src="./../../../assets/images/icons/close.svg" class="float-right form-popup-close" style="height: 1rem; width: 1rem; margin: 0.5rem;"
                      (click)="activeModal.close('Close click')" alt="close"></span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row p-5">
                <div class="col-lg-12">
                    <div class="row">
                        <form [formGroup]="secretQuestionForm" (keydown.enter)="$event.preventDefault()" class="secret-form" autocomplete="sq-form">
                            <div class="row">
                            <div ngbAutofocus class="col-sm-12 col-xs-12 form-popup-form tatlestyle">{{ "SECRETQUESTIONS" | translate }}</div>
                            </div>
                            <div class="row">
                            <div class="col-lg-12" *ngFor="let question of questions; index as i;">
                                <div class="form-group">
                                    <mat-label>{{ (i+1) + '. ' + question.question }}</mat-label>
                                </div>

                                <div class="form-group">
                                    <mat-form-field style="width: 100%;">
                                        <mat-label>{{'ANSWER' | translate}}</mat-label>
                                        <input matInput  [formControlName]="question.id" type="text" class="form-control" [id]="question.id" 
                                        placeholder="{{ 'ANSWER' | translate }}" [name]="question.id" maxlength="60"
                                        title = "{{ 'ANSWER' | translate }}" required autocomplete="off" >
                                        <mat-error class="error-message" *ngIf="secretQuestionForm.get(question.id + '').hasError('required')">{{'ANSWERREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            </div>
                            <hr>
                    
                    
                            <div class="col-sm-12 col-xs-12 form-popup-form">
                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button color="primary" type="submit" class="btn submit-button" (click)="onSubmit()" >{{ "SUBMIT" | translate}}</button>
                            </div>
                            </div>
                        </form>
                         
                      </div>
                </div>
            </div>
        </div>
    </div>
    

</div>