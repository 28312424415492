import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Sale } from 'src/app/modules/common/Reports';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
@Component({
  selector: 'app-withdraw-for-user',
  templateUrl: './withdraw-for-user.component.html',
  styleUrls: ['./withdraw-for-user.component.scss']
})
export class WithdrawForUserComponent implements OnInit {

  public user: User;
  private _unsubscribe$ = new Subject<any>();
  public searchRequestForm : FormGroup;
  public request : any;
  public bankInfo : any;
  public isProcessing1 = false;
  public isProcessing2 = false;
  public isProcessing3 = false;
  public returnFee = 0;
  public requests = [];
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private formBuilder : FormBuilder,
  ) { }

  ngOnInit(): void {
    this.searchRequestForm = this.formBuilder.group({
      id: ['', [Validators.required]],
    });
    this.getUser();
    this.getRequestlist();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getRequestlist()
  {
    this.evgService.getRequestlist( {infoType :0} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.data[0]){
          this.requests = resp.data[0].results;
          this.requests = this.requests.filter(request => {
            return request.isClosed == 0 && request.aType == 1
          })

        }
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getBankInfo(id)
  {
    this.evgService.getBankInfoById( {bank_id :id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        //console.log("BANK",resp);
        this.bankInfo = resp.data[0].results;
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


  onSearchRequest()
  {
    if(this.searchRequestForm.status == "VALID"){
        this.getRequestInfo(this.searchRequestForm.get('id').value);
    }
  }

  getRequestInfo(id)
  {
    this.isProcessing1 = true;
    this.evgService.getRequestInfo( {id : id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
      if (resp) {
        if(resp.success){
          this.request = resp.data[0].results;

          this.getBankInfo(this.request.bank_id);
        }else{
          this.responseHandlerService.handleResponse(resp);
        }
      }
      this.isProcessing1 = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isProcessing1 = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }


  onCancelRequest(){
    if(confirm("Are you sure you want to cancel this request?")){
      if(this.request){
        this.isProcessing3 = true;
        this.evgService.cancelWithdraw({id : this.request.id, returnFee : this.returnFee ? 1 : 0 ,withdrawId: this.request.withdrawalId} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
          if(resp.success){
            this.onSearchRequest();
          }
          this.isProcessing3 = false;
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.isProcessing3 = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
      
    }
  }

  onApproveRequest(){
    if(confirm("Are you sure you want to approve this request?")){
      if(this.request){
        this.isProcessing2 = true;
        this.evgService.doneWithdraw({id : this.request.id} ).pipe( takeUntil(this._unsubscribe$) ).subscribe(resp => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
          if(resp.success){
            this.onSearchRequest();
          }
          this.isProcessing2 = false;
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.isProcessing2 = false;
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        }); 
      }
      
    }
  }

  getRequestType(type){
    switch(type){
      case 0 : return "Normal Request";
      case 1 : return "Product Troubleshooting";
      case 2 : return "Cancel Order";
      case 3 : return "Change Schedule to Install Product";
      default : return "Normal Request";
    }
 }

 getAType(type){
   switch(type){
     case 1 : return "Withdrawal Amount";
     case 2 : return "Withdraw From AYB Pool";
     default : return "Not Applicable";
   }
}

}
