<div class="my-requests-container">
    <div class="container p-0">

		
		<div class="card">
            <div class="card-header">
                <h1 class="text-center">{{ "MYREQUESTS" | translate}}</h1>
            </div>
			<div class="row g-0">
				<div class="col-12 col-lg-5 col-xl-3 border-right">

					<div class="px-4 d-none d-md-block">
						<div class="d-flex align-items-center">
							<div class="flex-grow-1">
								<!-- <input type="text" class="form-control my-3" placeholder="Search..."> -->
							</div>
						</div>
					</div>

					<a (click)="viewMessage(i)" *ngFor="let message of messages; index as i;" class="list-group-item list-group-item-action p-1 m-1" [ngClass]="(selectedMessage ? selectedMessage.id : -1) == message.id ? 'active' : ''">

						<ng-container>
							<!-- <div class="badge bg-success float-right">5</div> -->
							<div class="d-flex align-items-center">
								<!-- <img src="https://bootdey.com/img/Content/avatar/avatar5.png" class="rounded-circle mr-1" alt="Vanessa Tucker" width="40" height="40"> -->
								<div class="flex-grow-1 ml-3" >
									{{ message.sender == user.id && message.IsReplay == 0 ? ('RECEIVER' | translate) : ('SENDER' | translate)}} : {{ message.sender == user.id ? message.receiver : message.sender }} - {{ message.topic }}
									<div class="small">{{ sharedUtilService.showDateInClientSideFormat(message.AddTime) }}</div>
								</div>
								<div [ngClass]="message.sender == user.id && message.IsReplay == 0 ? 'sent' : 'received'">
									{{ message.sender == user.id && message.IsReplay == 0? ('SENT' | translate) : ('RECEIVED' | translate)}}
								</div>
							</div>
						</ng-container>
					</a>
					

					<hr class="d-block d-lg-none mt-1 mb-0">
				</div>
				<div class="col-12 col-lg-7 col-xl-9">
					<div class="py-2 px-4 border-bottom d-none d-lg-block">
						<div class="d-flex align-items-center py-1">
							<!-- <div class="position-relative">
								<img src="https://bootdey.com/img/Content/avatar/avatar3.png" class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40">
							</div> -->
							<div class="flex-grow-1 pl-3">
								<strong *ngIf="!selectedMessageDetail">{{ "TOPICLABEL" | translate}}</strong>
                                <h2 class="font-weight-bold" *ngIf="selectedMessageDetail">{{ selectedMessageDetail.topic }}</h2>
								<!-- <div class="text-muted small"><em>Typing...</em></div> -->
							</div>
							<div>
								<!-- <button class="btn btn-primary btn-lg mr-1 px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></button>
								<button class="btn btn-info btn-lg mr-1 px-3 d-none d-md-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video feather-lg"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg></button> -->
								<button class="btn btn-light border btn-lg px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal feather-lg"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></button>
							</div>
						</div>
					</div>

					<div class="position-relative">
						<div class="chat-messages p-4">
							<!-- <div class="chat-message-right pb-4">
								<div>
									<img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
									<div class="text-muted small text-nowrap mt-2">2:33 am</div>
								</div>
								<div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
									<div class="font-weight-bold mb-1">You</div>
									Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
								</div>
							</div> -->
                            <h1 class="text-center" *ngIf="!selectedMessage">{{ 'CLICKMSG' | translate}}</h1>
							<quill-view *ngIf="selectedMessage" [content]="selectedMessage.content" format="html" theme="snow"></quill-view>
                            <!-- <h1 class="text-center" *ngIf="selectedMessage">{{ selectedMessage.content }}</h1> -->
						</div>
					</div>

					<ng-container *ngIf="selectedMessageDetail">
						<div class="flex-grow-0 py-3 px-4 border-top" *ngIf="selectedMessageDetail.sender != user.id">
							<div class="input-group mb-2">
								<input type="text" class="form-control" placeholder="{{ 'TOPIC' | translate }}" [(ngModel)]="topicBox">
							</div>
							<div class="input-group">
								<textarea type="text" class="form-control" placeholder="{{ 'CONTENT' | translate }}" [(ngModel)]="contentBox"></textarea>
								<button class="btn btn-primary ml-2" (click)="replyMessage()" [disabled]="!contentBox || !topicBox">{{'SENDBTN' | translate}}</button>
							</div>
						</div>
					</ng-container>
					

				</div>
			</div>
		</div>
	</div>
</div>