<div class="container">
    <div class="close-button" *ngIf="addCloseBut">
        <span><img src="./../../../assets/images/icons/close.svg" class="close-but-img" (click)="activeModal.close('Close click')" alt="close"></span>
    </div>
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title">
        
        <div class="d-flex justify-content-center flex-wrap mt-5">
        </div>
        <h2 class="mb-5" style="text-transform: uppercase;">{{ 'HENERGYTITLE' | translate}}</h2> 
        <a class="btn banner-button" (click)="openPageWithRedirection('login', 'redirection=purchase')">{{ 'BUYNOW' | translate }}</a>
    </div>

    <div class="vision d-flex flex-column">
        <h1 class="vision-head mt-5" style="text-transform: uppercase;" translate>
            MAXSTRENGTH
        </h1>
        <div class="separator mb-5">

        </div>
        <div class="vision-body">
            <p class="mb-5 text-center" translate>
                MAXSTRENGTHDESC
            </p>
        </div>
    </div>

    <div class="we-care">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title">{{'WHATHYDROGENENG' | translate}} ?</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-1" translate>WHATHYDROGENENGDESC</p>
                <br>
                <p class="card-text mb-1" translate>MAXSTRENGTHDESC</p>
            </div>
            
        </div>
        <div class="item-right">
            <img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/hydrogen-energy/glass-of-water.png" alt="">
        </div>
    </div>

    <div class="byte-air">
        <div class="byte-air-body">
            <h1 class="byte-air-head" style="text-transform: uppercase;" translate>
                HEBENEFITS
            </h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3">
        
                </div>
            </div>
            <div class="row">
                <div class="more-body mb-3">
                    <ul>
                        <li class="" translate>HEBENEFIT1</li>
                        <li class="" translate>HEBENEFIT2</li>
                        <li class="" translate>HEBENEFIT3</li>
                        <li class="" translate>HEBENEFIT4</li>
                        <li class="" translate>HEBENEFIT5</li>
                    </ul>
                </div>
                <div class="more-body mb-3 ml-5">
                    <ul>
                        <li class="" translate>HEBENEFIT6</li>
                        <li class="" translate>HEBENEFIT7</li>
                        <li class="" translate>HEBENEFIT8</li>
                        <li class="" translate>HEBENEFIT9</li>
                    </ul>
                </div>
            </div>
        </div>  
    </div>

    <div class="why-need">
        <div class="item-left">
            <div class="card-block-1">
                <h2 class="card-title" style="text-transform: uppercase;" translate>WHYHED</h2>
                <p class="card-text mb-1 mx-1" translate>WHYHEDDEC1</p><br>
                <p class="card-text mb-1 mx-1" translate>WHYHEDDEC2</p><br>
                <p class="card-text mb-1 mx-1" translate>WHYHEDDEC3</p><br>
                <p class="card-text mb-1 mx-1" translate>WHYHEDDEC4</p><br>
            </div>
            
        </div>
        <div class="item-right">
            <div class="card-block-1">
                <h2 class="card-title" style="text-transform: uppercase;">&nbsp;&nbsp; </h2>
                <p class="card-text mb-1 mx-1" translate>WHYHEDDEC5</p><br>
                <h2 class="card-title" style="text-transform: uppercase;" translate>WHOHED</h2><br>
                <p class="card-text mb-1 mx-1" translate>WHOHEDDEC1</p><br>
                <p class="card-text mb-1 mx-1" translate>WHOHEDDEC2</p><br>
                <p class="card-text mb-1 mx-1" translate>WHOHEDDEC3</p><br>
            </div>
        </div>
    </div>
    <div class="how-hed">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title" translate>HOWTAKE</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-1" translate>HOWRAKEDEC</p>
            </div>
        </div>
        <div class="item-right">
            <img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/hydrogen-energy/pexels-ivan-samkov.jpg" alt="">
        </div>
    </div>

    <div class="cert d-flex flex-column">
        <h1 class="cert-head my-5" style="text-transform: uppercase;" translate>
            CERTIFICATES
        </h1>
        <div class="cert-body">
            <div class="row my-5">
                <div class="col-2">
                    <img style="object-fit:cover; width: 100%;height: auto;" src="./../../../../assets/images/files/hydrogen-energy/CGMP.png" alt="">
                </div>
                <div class="col-10 d-flex align-items-center">
                    <p class="mb-5 text-left" translate>
                        CERTCGMP
                    </p>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-2">
                    <img style="object-fit:cover; width: 100%;height: auto;" src="./../../../../assets/images/files/hydrogen-energy/IHSA.png" alt="">
                </div>
                <div class="col-10 d-flex align-items-center">
                    <p class="mb-5 text-left">
                        {{ 'CERTIHSA' | translate }}
                        <a>{{'HLEARNM' | translate}} >></a>
                    </p>
                    <!--
                    <p style="color:lightblue">{{'HLEARNM' | translate}} >></p>
                    -->
                </div>
            </div>
            <div class="row my-5">
                <div class="col-2">
                    <img style="object-fit:cover; width: 100%;height: auto;" src="./../../../../assets/images/files/hydrogen-energy/INFORMED sport.png" alt="">
                </div>
                <div class="col-10 d-flex align-items-center">
                    <p class="mb-5 text-left">
                        {{'CERTSPORT' | translate}}<br>
                        <a>{{'HLEARNM' | translate}} >></a>
                    </p>
                    <!--
                    <p style="color:lightblue">{{'HLEARNM' | translate}} >></p>
                    -->
                </div>
            </div>
            <div class="row my-5 mb-5">
                <div class="col-2">
                    <img style="object-fit:cover; width: 100%;height: auto;" src="./../../../../assets/images/files/hydrogen-energy/USA.png" alt="">
                </div>
                <div class="col-10 d-flex align-items-center">
                    <p class="mb-5 text-left" translate>
                        CERTUSA
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="what-say">
        <div class="what-say-body">
            <h1 class="what-say-head" style="text-transform: uppercase;" translate>
                WHATCLIENTSSAY
            </h1>
            <div class="wat-say-center">
                <a class="btn banner-button" (click)="openPage('testimonials')">{{ 'CLICKHERE' | translate }}</a>
            </div>
        </div>
    </div>
<!--
<div class="hydrogen">
    <div class="centered-text">
        <p class="align-middle" translate>AUCOAO</p><br>
        <p class="align-middle" translate>AUAPADIC</p>
    </div>
</div>
-->
</div>

