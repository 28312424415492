import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-tinted-pore',
  templateUrl: './view-tinted-pore.component.html',
  styleUrls: ['./view-tinted-pore.component.scss']
})
export class ViewTintedPoreComponent implements OnInit {
  public isMobileMenuOpen:boolean  = false;

  constructor( 
    public activeModal : NgbActiveModal, 
    public router : Router,
  ) { }


  ngOnInit(): void {
    if (window.innerWidth <= 869 ) {
      this.isMobileMenuOpen = true;
    }

  }
  openPage(pageId) {
    this.router.navigate([pageId]);
  }
}

