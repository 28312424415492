import { Injectable } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShareinfoService } from './shareinfo.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class SharedUtilService {
  private _unsubscribe$ = new Subject<any>();
  constructor(private sharedInfoService : ShareinfoService) { }

  resetForm(form : FormGroup, formDirective: FormGroupDirective = null){
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
    form.markAsPristine();
    form.markAllAsTouched();
    form.updateValueAndValidity();
    if(formDirective){
      formDirective.resetForm();
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  unflatten(arr) {
    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;
  
        
    // First map the nodes of the array to an object -> create a hash table.
    for(var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['childs'] = [];
    }
  
    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {

        
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          
          if(mappedArr[mappedElem['parentid']]){
            mappedArr[mappedElem['parentid']]['childs'].push(mappedElem);
          }
        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    
    return tree;
  }

  mapTeamData(user, data)
  {
    let mappedData = [];

    let node = { 
      id : user.id,
      parentid : 0,
      name : user.first_name + ' ' + user.last_name,
      cssClass : this.getCssClass(user.uLevel),
      rank : this.getRankByULevel(user.uLevel),
      sponsorid :0
      // image : 'assets/images/icons/user.png',
      // title: this.user.id + ' - uLevel : ' + this.user.uLevel,
    };

    mappedData.push(node);

    data.forEach(element => {
      let node = { 
        id : element.id,
        parentid : element.Referrals,
        name : element.first_name + ' ' + element.last_name,
        cssClass : this.getCssClass(element.uLevel),
        rank : this.getRankByULevel(user.uLevel),
        sponsorid : element.sponsorId,
        // image : 'assets/images/icons/user.png',
        // title: element.id + ' - uLevel : ' + element.uLevel,
      };

      mappedData.push(node);
    });
    return mappedData;
  }

  getCssClass(uLevel){
    switch (uLevel) {
      case 0: return 'member';
        break;
      case 1: return 'consultant';
        break;
      case 2: return 'director';
        break;
      case 3: return 'executive';
        break;
      case 4: return 'sapphire';
        break;
      case 5: return 'diamond';
        break;   
      case 6: return 'crown';
        break;               
      default: return 'member';
        break;
    }
  }
  getRankByULevel(uLevel){
    switch (uLevel) {
      case 0: return 'Qualified Member';
        break;
      case 1: return 'Qualified Consultant';
        break;
      case 2: return 'Qualified Director';
        break;
      case 3: return 'Qualified Executive';
        break;
      case 4: return 'Qualified Sapphire';
        break;
      case 5: return 'Qualified Diamond';
        break;   
      case 6: return 'Qualified Crown';
        break;               
      default: return 'Qualified Member';
        break;
    }
  }

  showDateInClientSideFormat(dValue)
  {
      var dt = new Date(dValue);
      return dt.toLocaleString("en-US");
  }

  checkUserPermissions(user_id) : string
  {
    var admins = JSON.parse(localStorage.getItem('admins'));
    var accounting = JSON.parse(localStorage.getItem('accounting'));
    var supports = JSON.parse(localStorage.getItem('supports'));

    for (let index = 0; admins && index < admins.length; index++) {
      if(admins[index].id == user_id) return 'admin';
    }
    for (let index = 0; accounting && index < accounting.length; index++) {
      if(accounting[index].id == user_id) return 'accounting';
    }

    for (let index = 0;supports && index < supports.length; index++) {
      if(supports[index].id == user_id) return 'support';
    }

    return 'user';
  }

  public markFormGroupDirty(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  crypt(salt, text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  };
  
  decrypt(salt, encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };

  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), 'evg12345').toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, 'evg12345');
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


}
