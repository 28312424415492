<div class="pay-monthly-fee-setting-container">
    <div class="row gutters-sm">
      
      <div class="col-md-6">
        <div class="card mt-3">
          <div class="card-body">
            <h2 class="title">{{ 'PAYMONTHLYFEE' | translate}} - {{ 'RANK' | translate}} : {{  rankName }}</h2>
            
            <div class="d-flex flex-column align-items-center text-center">
              
              <form #payMonthly="ngForm" [formGroup]="payMonthlyForm" (keydown.enter)="$event.preventDefault()" class="pb-3 pl-3 pr-3"a utocomplete="pm-form">

                <div class="form-group">
                    <div class="row">
                        <h3 class="font-weight-bold">{{ 'BALANCELABEL' | translate }} : {{ user.totalCash | currency:'' }}</h3>
                    </div>
                    <mat-form-field style="width: 100%;" >
                        <mat-label>{{ 'YEARMONTH' | translate}}</mat-label>
                        <input matInput [min]="minDate" [matDatepicker]="dp" formControlName="for_month">
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp
                                
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event)"
                                        (monthSelected)="chosenMonthHandler($event, dp)"
                                        panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error class="er
                        ror-message" *ngIf="payMonthlyForm.get('for_month').hasError('required')">{{'YEARMONTHREQ' | translate}}</mat-error>
                      </mat-form-field>
                     
                      <mat-form-field style="width: 100%;">
                        <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount" aria-describedby="amount"
                        placeholder="{{'AMOUNT' | translate}}"  name="amount"
                        autocomplete="off"
                        >
                        <mat-error class="error-message" *ngIf="payMonthlyForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                      </mat-form-field>
                      <mat-form-field style="width: 100%;">
                        <mat-select formControlName="payType"  type="text" class="form-control text-box" id="payType" aria-describedby="payType"
                        placeholder="{{'PAYMENTTYPELABEL' | translate}}" name="payType">
                        <mat-option  [value]="0">
                            {{ 'CREDITCARDONLY' | translate }}
                          </mat-option>
                          <mat-option [value]="1">
                            {{ 'EVCOINSLABEL' | translate }}
                          </mat-option>
                        </mat-select>
                        <mat-error class="error-message" *ngIf="payMonthlyForm.get('payType').hasError('required')" >{{'PAYMENTTYPEREQ' | translate}}</mat-error>
                    </mat-form-field>
                </div>
               
                <button mat-stroked-button color="primary" class="align-right mt-4" [disabled]="!this.payMonthlyForm.valid" (click)="payMonthlyFee(payMonthly)">{{ 'SUBMIT' | translate }}</button>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mt-3">
          <div class="card-body">
            <h2 class="title">{{ 'PAYMENTHISTORY' | translate }} </h2>
            <div class="table-responsive">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{{'MEMBERNO' | translate}}</th>
                      <th scope="col">{{'FORMONTH' | translate}}</th>
                      <th scope="col">{{'AMOUNT' | translate}}</th>
                      <th scope="col">{{'PAYTYPE' | translate}}</th>
                      <th scope="col">{{'ULEVEL' | translate}}</th>
                      <th scope="col">{{'PAIDDATE' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let paidMonthlyFee of paidMonthlyFeeHistory; index as i;">
                      <td>{{ paidMonthlyFee.MemberNo }}</td>
                      <td>{{ paidMonthlyFee.for_month }}</td>
                      <td>{{ paidMonthlyFee.feeAmt | currency }}</td>
                      <td>{{ paidMonthlyFee.payType == 0 ? ("CREDITCARDONLY" | translate) : ("EVCOINSLABEL" | translate) }}</td>
                      <td>{{ paidMonthlyFee.uLevel }}</td>
                      <td>{{ paidMonthlyFee.AddTime }}</td>
                    </tr>
                  </tbody>
                </table>
          </div>
          </div>
        </div>
        
      </div>
      
    </div>
    
</div>