<div class="form-popup-container center" [ngStyle]="{'background-color': background_color}">
    <div class="top-container" [ngClass]="{'withoutSet': show_header_type==0}">
        <div class ="imgsection">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  *ngIf="show_header_type==2">
                <div class="carousel-inner">
                    <div class="carousel-item" data-interval="get_data_interval()" *ngFor="let picfile of picFiles; index as i;" [ngClass]="{'active':i==0}">
                        <img class="carousel-image d-block w-100 h-50" [src]="picfile" >
                    </div>
                </div>
            </div>
            <!--<img class="background" [src]="slide_picture" *ngIf="show_header_type==2" > -->
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  *ngIf="show_header_type==1">
                <div class="carousel-inner">
                    <img class="carousel-image d-block w-100 h-50" [src]="setHeaderImg" >
                </div>
            </div>
        </div>
        <div class="cart-header">
            <div class="row col-12 justify-content: space-between;">
                <div class="col-6">
                    <!---
                    <img class="logo ml-3" src="../../../../assets/images/logo/small_logo.png" >
                -->
                    <img class="logo" src="../../../../assets/images/logo/byte_logo_new_txt.png" alt="forecastr logo">
                </div>
                <div class="col-6 text-right">
                    <div class="row float-right">
                        <div class="text-right d-flex">
                            <div class="mr-1 mt-1">
                                <select
                                    class="form-control m-auto" style="background-color: #FFA500;"
                                    #selectedLang 
                                    (change)="switchLang(selectedLang.value)">
                                    <option *ngFor="let language of translate.getLangList()" 
                                        [value]="language.code"
                                        [selected]="language.code === translate.getCurLang()">
                                        {{ language.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="mr-1">
                                <button mat-raised-button color="primary" class="btn submit-button m-1"  (click)="selectProducts()" *ngIf="step == 0">{{ select_product_title | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  class="maintenance" *ngIf="storeAD_size>5">
            <div class="scroll-left">
                <!-- <p [innerHTML]=myStoreInfo.cart_adv></p>-->
                <p><quill-view [content]="myStoreInfo.cart_adv" format="html" theme="snow" style="border-width: 0;overflow:hidden;border:none;"></quill-view></p>
            </div>
        </div>
    </div>
    <div class="row m-1" id="myStoreID" style="margin-top : 1rem;">
        <form [formGroup]="checkoutForm" (keydown.enter)="$event.preventDefault()" class="checkout-form p-2" autocomplete="c-form">
            <h2 class="text-center m-2 font-weight-bold">{{myStoreInfo.cart_name || 'Byte Shipping Cart'}}</h2>
            <div class="step-0" *ngIf="step == 0 && cart_items.length > 0">
                <div class="row contactarea">
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div style="text-align: center; margin: auto;">
                          <button mat-raised-button class="btn submit-button mr-3" color="primary" (click)="skipStep()">{{ "BUYNOW" | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-1" *ngIf="step == 1 && cart_items.length > 0">
                <div class="step-header mb-2" ngbAutofocus>{{ 'STEP1DESC' | translate }}</div>
                <div class="row contactarea">
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div class="row form-group">
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_first_name" type="text" class="form-control" id="contact_first_name" aria-describedby="contact_first_name"
                                    placeholder="{{ 'FIRSTNAME' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_first_name').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_last_name" type="text" class="form-control" id="contact_last_name" aria-describedby="contact_last_name"
                                    placeholder="{{ 'LASTNAME' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_last_name').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_phone" type="text" class="form-control" id="contact_phone" aria-describedby="contact_phone"
                                    placeholder="{{ 'TELEPHONELABEL' | translate }}" 
                                    (keydown)="onKeydownForTel($event)"
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_phone').hasError('required')" >{{ "TELEPHONEREQ" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_email" type="email" class="form-control" id="contact_email" aria-describedby="contact_email"
                                    placeholder="{{ 'EMAIL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_email').hasError('required')" >{{ "EMAILISREQ" | translate }} </mat-error>
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_email').hasError('email')" >{{ "EMAILISVALID" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-select formControlName="contact_country"  type="text" class="form-control text-box" id="contact_country" aria-describedby="contact_country"
                                        placeholder="{{'COUNTRYLABEL' | translate}}" name="contact_country">
                                      <mat-option *ngFor="let country of contact_countries, let i = index" [value]="country.country">
                                        {{ country.country }}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                <mat-select formControlName="contact_province"  type="text" class="form-control text-box" id="contact_province" aria-describedby="contact_province"
                                    placeholder="{{'PROVINCELABEL' | translate}}"  name="contact_province">
                                    <ng-container *ngFor="let province of contact_provinces, let i = index">
                                        <mat-option [value]="province.state_name">
                                        {{ province.state_name }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="checkoutForm.get('contact_province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                  <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_city" type="text" class="form-control" id="contact_city" aria-describedby="contact_city"
                                    placeholder="{{ 'CITYLABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_city').hasError('required')" >{{ "CITYREQ" | translate }} </mat-error>
                                  </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_address" type="text" class="form-control" id="contact_address" aria-describedby="contact_address"
                                    placeholder="{{ 'STREETADR' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="contact_postCode" type="text" class="form-control" id="contact_postCode" aria-describedby="contact_postCode"
                                    placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('contact_postCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                            <!-- end-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-2" *ngIf="step==1">
                <div class="step-header mb-2">{{ 'SHIPPINGINFO' | translate }}</div>
                <!--<div class="step-header mb-2"><strong>{{ 'STEP2' | translate}}</strong> {{ 'STEP2DESC' | translate }}</div>-->
                <div class="row contactarea">
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                        <mat-radio-group
                            aria-labelledby="address-radio-group-label"
                            class="address-radio-group"
                            [(ngModel)]="preferredShipping"
                            (ngModelChange)="preferredShippingChanged()"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button color="primary" class="address-radio-button" [value]="0" >
                                {{ 'USEEXISTINGADDR' | translate}}
                            </mat-radio-button>
                            <div class="address-list d-flex" *ngIf="preferredShipping == 0">
                                <div class="address-card" *ngFor="let shippingAddress of shippingAddresses; index as i;" [ngClass]="i == selectedAddress ? 'address-card-active' : ''">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <mat-radio-group
                                            aria-labelledby="address-radio-group-label"
                                            class="address-radio-group"
                                            [(ngModel)]="selectedAddress"
                                            (ngModelChange)="selectedAddressChanged()"
                                            [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button class="address-radio-button" [value]="i" >
                                                </mat-radio-button>
                                             </mat-radio-group>
                                        </div>
                                        <div>
                                            <span>{{ shippingAddress.first_name }}&nbsp;{{ shippingAddress.last_name }}</span><br>
                                            <span>{{ shippingAddress.Address }}</span><br>
                                            <span>{{ shippingAddress.City }}, {{ shippingAddress.Province }}</span><br>
                                            <span>{{ shippingAddress.Country }}, {{ shippingAddress.PostCode }}</span><br>
                                            <span>{{ shippingAddress.Tel }}</span>
                                        </div>
                                        <button class="cart-button mr-1" mat-mini-fab color="warn" aria-label="Delete" (click)="deleteShippingAddress(shippingAddress.id)">
                                            <mat-icon class="cart-icon">delete</mat-icon>
                                        </button>
                                    </div>
                                    
                                </div>
                                <div class="text-center p-4 d-flex justify-content-center" *ngIf="shippingAddresses.length <= 0">
                                    <h2>
                                        {{ "NOSHIPPINGADDR" | translate }}
                                    </h2>
                                </div>
                            </div>
                            
                            <mat-radio-button color="primary" class="address-radio-button" [value]="1" >
                                {{ 'ADDNEWADDR' | translate}}
                            </mat-radio-button>
                            <div class="row form-group" *ngIf="preferredShipping == 1">
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="first_name" type="text" class="form-control" id="first_name" aria-describedby="first_name"
                                        placeholder="{{ 'FIRSTNAME' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('first_name').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="last_name" type="text" class="form-control" id="last_name" aria-describedby="last_name"
                                        placeholder="{{ 'LASTNAME' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('last_name').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="Tel" type="text" class="form-control" id="Tel" aria-describedby="Tel"
                                    placeholder="{{ 'TELEPHONELABEL' | translate }}" 
                                    (keydown)="onKeydownForTel($event)"
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('Tel').hasError('required')" >{{ "TELEPHONEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="email" type="email" class="form-control" id="email" aria-describedby="email"
                                    placeholder="{{ 'EMAIL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('email').hasError('required')" >{{ "EMAILISREQ" | translate }} </mat-error>
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('email').hasError('email')" >{{ "EMAILISVALID" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                -->
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="Address" type="text" class="form-control" id="Address" aria-describedby="Address"
                                    placeholder="{{ 'ADDRESSLABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('Address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="City" type="text" class="form-control" id="City" aria-describedby="City"
                                    placeholder="{{ 'CITYLABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('City').hasError('required')" >{{ "CITYREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                   <mat-form-field style="width: 100%;">
                                    <mat-select formControlName="Country"  type="text" class="form-control text-box" id="country" aria-describedby="country"
                                    placeholder="{{'COUNTRYLABEL' | translate}}" name="country">
                                      <mat-option *ngFor="let country of countries, let i = index" [value]="country.country">
                                        {{ country.country }}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('Country').hasError('required')">{{'COUNTRYREQ' | translate}}</mat-error>
                                  </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <mat-select formControlName="Province"  type="text" class="form-control text-box" id="province" aria-describedby="province"
                                        placeholder="{{'PROVINCELABEL' | translate}}"  name="province">
                                            <ng-container *ngFor="let province of provinces, let i = index">
                                                <mat-option [value]="province.state_name">
                                                {{ province.state_name }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('Province').hasError('required')">{{'PROVINCEREQ' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                  <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="PostCode" type="text" class="form-control" id="PostCode" aria-describedby="PostCode"
                                    placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                    autocomplete="off">
                                    <mat-error class="error-message" *ngIf="checkoutForm.get('PostCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <mat-radio-button color="primary" class="address-radio-button" [value]="2" >
                                {{ 'SAMEWITHADR' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>

            <div class="step-3" *ngIf="step == 3">
                <div class="step-header mb-2">{{ 'SELECTPAYTYPE' | translate }}</div>
                <div class="row contactarea">
                    <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div class="row form-group"  style="width: 100%;margin: auto;">
                                <mat-radio-group
                                    aria-labelledby="payment-radio-group-label"
                                    class="address-radio-group"
                                    [(ngModel)]="pay_type"
                                    (ngModelChange)="preferredPayTypeChanged()"
                                    [ngModelOptions]="{standalone: true}" style="width:100%;margin: auto;">
                                    <mat-radio-button color="primary" class="address-radio-button" [value]="3" >
                                        {{ 'BYSTRIPE' | translate}}
                                    </mat-radio-button>
                                    <mat-radio-button color="primary" class="address-radio-button" [value]="0" >
                                        {{ 'CREDITCARDONLY' | translate}}
                                    </mat-radio-button>
                                    <mat-radio-button color="primary" class="address-radio-button" [value]="2" >
                                        {{ 'BYPAYPAL' | translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="form-group" *ngIf="pay_type== 0" style="width: 100%;"  class="row readonly-wrapper">
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="firstName" type="text" class="form-control" id="firstName" aria-describedby="firstName"
                                        placeholder="{{ 'FIRSTNAME' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('firstName').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="lastName" type="text" class="form-control" id="lastName" aria-describedby="lastName"
                                        placeholder="{{ 'LASTNAME' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('lastName').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="cardNo" type="text" class="form-control" id="cardNo" aria-describedby="cardNo"
                                        placeholder="{{ 'CCNUMBER' | translate }}" 
                                        (keyup)="cc_format($event)"
                                        [(ngModel)]="creditCardNumber"
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('cardNo').hasError('required')" >{{ "CCNUMBERREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="expireDate" type="text" pattern="[0-9]{2}\/[0-9]{2}" class="form-control" id="expireDate" aria-describedby="expireDate"
                                        placeholder="{{ 'CCEXP' | translate }} (MM/YY)" 
                                        (keydown)="onKeydown($event)"
                                        (keyup)="modifyInput($event)"
                                        [(ngModel)]="expiryDateValue"
                                        maxlength="5"
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('expireDate').hasError('required')" >{{ "CCEXPREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <div class="eye-control">
                                            <input matInput formControlName="cvCode" type="text" pattern="[0-9]{3,4}"  maxlength="4" class="form-control" id="cvCode" aria-describedby="cvCode"
                                            placeholder="{{ 'CCCVC' | translate }}" 
                                            autocomplete="off">
                                            <span class="eye-display">
                                                <i class="bi bi-question-circle mx-3" (click)="showNoticePassword(1)"></i>
                                            </span>
                                        </div>
                                        <mat-error class="error-message" *ngIf="checkoutForm.get('cvCode').hasError('required')" >{{ "CCCVCREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="text-center">
                                    <div class="stripe-logo"></div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="checkoutForm.get('payType').value == 2" style="width: 100%;margin: auto;" >
                                <app-byte-paypal [currency]="payby_currency" [amount]="payPal_amount" [shippingInfo]="shippingInfo" (paypalProcessEvent)="getPaypalProcess($event)"></app-byte-paypal>
                            </div>
                            <div class="form-group" *ngIf="pay_type == 3" style="width: 100%;margin: auto;">
                                <app-byte-stripe class="stripe-payment" [currency]="payby_currency" [amount]= "payPal_amount" [shippingInfo]="shippingInfo" [translate]="translate" (paypalProcessEvent)="getPaypalProcess($event)"></app-byte-stripe>
                            </div>
                            <!--</div>-->
                    </div>
                </div>
            </div>

            <div class="cart-items">
                <div class="table-responsive" id="no-more-tables">
                    <table class="table" style="border-bottom: 1px solid;">
                        <thead>
                          <tr class="font-weight-bold">
                            <th scope="col">#</th>
                            <th scope="col">{{ 'PRODUCTNAME' | translate }}</th>
                            <th scope="col">{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})</th>
                            <th scope="col">{{ 'QTY' | translate }}</th>
                            <th scope="col">{{ 'SUBTOTAL' | translate }}</th>
                            <!--<th scope="col">{{ 'TAXNAME' | translate }}</th>-->
                            <th scope="col">{{ 'TAXAMOUNT' | translate }}</th>
                            <th scope="col">{{ 'ACTION' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let cart_item of cart_items; index as i;">
                            <tr>
                                <td title="#" class="d-flex justify-content-center text-center td-checkbox"><input class="form-group form-check-input" type="checkbox" value=""  [checked]="cart_item.selected" (change)="onSelected(i,$event)"></td>
                                <td title="{{ 'PRODUCTNAME' | translate }}">{{ cart_item.pTitle }}</td>
                                <td title="{{ 'PRICE' | translate }}({{'CURRENCYUNIT' | translate}})">{{ cart_item.unitPrice | currency:'' }}</td>
                                <td title="{{ 'QTY' | translate }}">{{ cart_item.nums }}</td>
                                <td title="{{ 'SUBTOTAL' | translate }}">{{ (cart_item.unitPrice * cart_item.nums) | currency:'' }}</td>
                                <!--<td>{{ renderTaxName(cart_item) }}</td>-->
                                <td title="{{ 'TAXAMOUNT' | translate }}">{{ (calculateProductTax(cart_item)) | currency:'' }}</td>
                                <td title="{{ 'ACTION' | translate }}">
                                    <button class="cart-button mr-1"  mat-mini-fab color="accent" aria-label="Plus" (click)="addItem(i)" >
                                        <mat-icon class="cart-icon">add</mat-icon>
                                    </button>
                                    <button class="cart-button mr-1" mat-mini-fab color="warn" aria-label="Minus" (click)="removeItem(i)" [disabled]="cart_item.nums <= 1 ? true : false">
                                        <mat-icon class="cart-icon">remove</mat-icon>
                                    </button>
                                    <button class="cart-button mr-1" mat-mini-fab color="primary" aria-label="Delete" (click)="deleteItem(i)">
                                        <mat-icon class="cart-icon">delete</mat-icon>
                                    </button>
                                    <!--
                                    <button class="cart-button mr-1" mat-mini-fab color="primary" aria-label="Info" (click)="viewProductDetail(cart_item.pId)" title="{{ 'PRODBENEFIT' | translate }}">
                                        <mat-icon class="cart-icon">info</mat-icon>
                                    </button>
                                    -->
                                </td>
                            </tr>
                            <tr *ngIf="cart_item.page_name">
                                    <td colspan="7" style="border-bottom: 3px solid; margin-bottom: 0.2rem;">
                                        <div class="benefit-notice" mat-mini-fab color="primary">
                                            {{ 'CLICKICON' | translate }}&nbsp;
                                            <button class="cart-button mr-1" mat-mini-fab color="primary" aria-label="Info" (click)="viewProductDetail(cart_item.pId)" title="{{ 'PRODBENEFIT' | translate }}"><mat-icon class="cart-icon">info</mat-icon></button>&nbsp;{{ 'FORBENEFIT' | translate }}
                                        </div>
                                    </td>
                            </tr>
                            </ng-container>
                            <tr *ngIf="cart_items.length == 0">
                              <td colspan="6" class="text-center">
                                  <h3>
                                      <!--
                                    {{ 'PLEASEADDITEM' | translate }} <br><a class="link" style="cursor: pointer;" (click)="openPage('backmain/purchase')">{{ 'GOTOPRODUCT' | translate }}</a>
                                    -->
                                    {{ 'PLEASEADDITEM' | translate }} <br><a class="link" style="cursor: pointer;" (click)="selectProducts()">{{ 'SELECTPRODCT' | translate }}</a>
                                  </h3>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive" id="more-tables">
                    <table class="table bg-white">
                        <tbody>
                          <tr *ngIf="step > 2">
                                <td class="font-weight-bold align-items-center" colspan="5"><div>{{ 'COUPON' | translate }}</div></td>
                                <td class="text-right font-weight-bold" >
                                        <input type="text" class="form-control" id="coupon" aria-describedby="coupon" [(ngModel)]="coupon" [ngModelOptions]="{standalone: true}"
                                        autocomplete="off">
                                </td>
                                <td class="d-flex justify-content-end align-items-center" colspan="2">
                                    <div>
                                        <a mat-stroked-button color="primary" class="btn" (click)="applyCoupon()">{{ "APPLY" | translate}}</a>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                              <td class="font-weight-bold" colspan="5">{{ 'SELECTEDITEMS' | translate }} : {{ cart_item_selected }} </td>
                              <td class="text-right font-weight-bold" >{{ 'SUBTOTAL' | translate }}</td>
                              <td class="font-weight-bold  text-right" colspan="2">{{ cart_subtotal | currency:'' }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold" colspan="5"></td>
                            <td class="text-right font-weight-bold" >{{ 'DISCOUNTS' | translate }} {{ appliedCoupon ? ((appliedCoupon.coupon_type == 0 || appliedCoupon.category == 1) ?  ('AMOUNT' | translate) : '%') : '' }}</td>
                            <td class="font-weight-bold  text-right" colspan="2">- {{ cart_discounts | currency:'' }}</td>
                           
                          </tr>
                          <tr *ngIf="cart_tax_rate">
                            <td class="font-weight-bold" colspan="5"></td>
                            <td class="text-right font-weight-bold" >{{ 'TAX' | translate }} {{ '(' + cart_tax_rate.tax_name + ' ' + (cart_tax_rate.tax_rate | percent:'0.0-3') +')'}}</td>
                            <td class="font-weight-bold  text-right" colspan="2">+ {{ cart_tax_1 | currency:'' }}</td>
                         
                          </tr>
                          <ng-container *ngIf="cart_tax_rate">
                            <tr *ngIf="cart_tax_rate.tax_name2">
                                <td class="font-weight-bold" colspan="5"></td>
                                <td class="text-right font-weight-bold" >{{ 'TAX' | translate }} {{ '(' + cart_tax_rate.tax_name2 + ' ' + (cart_tax_rate.tax_rate2 | percent:'0.0-3') +')'}}</td>
                                <td class="font-weight-bold  text-right" colspan="2">+ {{ cart_tax_2 | currency:'' }}</td>
                              </tr>
                          </ng-container>
                                                 
                          <tr *ngIf="cart_tax_rate && shippingOptions && shippingOptions.length>0">
                            <td class="font-weight-bold" colspan="5">
                                <mat-radio-group
                                    aria-labelledby="payment-radio-group-label"
                                    class="address-radio-group"
                                    [(ngModel)]="selectedShippingOption"
                                    (ngModelChange)="preferredShippingOptionChanged()"
                                    [ngModelOptions]="{standalone: true}" style="width:100%;margin: auto;" *ngIf="shippingOptions && shippingOptions.length>0">
                                    <ng-container *ngFor="let item of shippingOptions; index as i;"  style="display: inline-flex;" >
                                        <mat-radio-button color="primary" class="address-radio-button"  [value]="i" >
                                            {{item.serviceName}}&nbsp;
                                        </mat-radio-button>
                                        <span style="border-bottom: 2px solid rgb(90, 89, 89);"> Expected Delivery Date:{{item.expectedDeliveryDate}}&nbsp; {{ item.shipppingFee | currency:'' }}</span>
                                    </ng-container>
                            </mat-radio-group>
                            </td>
                            <td class="text-right font-weight-bold" >{{ 'SHIPPINGFEE' | translate }}</td>
                            <td class="font-weight-bold  text-right" colspan="2">+ {{ shipping_fee | currency:'' }}</td>
                          </tr>
                        

                          <tr>
                            <td class="font-weight-bold" colspan="5"></td>
                            <td class="text-right font-weight-bold" >{{ 'TOTAL' | translate }}&nbsp;({{'CURRENCYUNIT' | translate}})</td>
                            <td class="font-weight-bold text-right" colspan="2">{{ cart_total | currency:'' }}</td>
                           
                          </tr>
                        
                        </tbody>
                      </table>
                </div>
            </div>
            <div class="step-2" *ngIf="step == 1">
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div style="text-align: center; margin: auto;">
                        <button mat-raised-button class="btn submit-button mr-3"  (click)="prevStep()" >{{ "PREVSTEP" | translate}}</button>
                        <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="preferredShipping == 1"
                        [disabled]="checkoutForm.get('Tel').hasError('required') || checkoutForm.get('Address').hasError('required') || checkoutForm.get('City').hasError('required') || checkoutForm.get('Province').hasError('required') 
                        ||checkoutForm.get('Country').hasError('required') ||checkoutForm.get('PostCode').hasError('required')"  
                        (click)="nextStep()" >{{ "MAKEPAYMENT" | translate}}</button>

                        <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="(preferredShipping == 0)"
                        [disabled]="selectedAddress < 0"  
                        (click)="nextStep()" >{{ "MAKEPAYMENT" | translate}}</button>
                        <button mat-raised-button color="primary" class="btn submit-button"  *ngIf="(preferredShipping == 2)"
                        [disabled]="checkoutForm.get('contact_first_name').hasError('required') || checkoutForm.get('contact_last_name').hasError('required') || checkoutForm.get('contact_email').hasError('required') || checkoutForm.get('contact_phone').hasError('required') || checkoutForm.get('contact_country').hasError('required') || checkoutForm.get('contact_province').hasError('required') || checkoutForm.get('contact_city').hasError('required') || checkoutForm.get('contact_address').hasError('required') "
                        (click)="nextStep()" >{{ "MAKEPAYMENT" | translate}}</button>
                    </div>
                </div>
            </div>
            <div class="step-3" *ngIf="step == 3">
                <div class="col-sm-12 col-xs-12 form-popup-form">
                    <div style="text-align: center; margin: auto;">
                        <button mat-raised-button color="warn" class="btn submit-button mr-3"  (click)="prevStep()" >{{ "PREVSTEP" | translate}}</button>
                        <button mat-raised-button color="primary" class="btn submit-button"  (click)="finishSteps()" [disabled]="!checkCanSubmit" >{{ "SUBMIT" | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
        <div style="height: 1rem; ">&nbsp;</div>
    </div>
</div>