<div class="personal-webpage-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-header">
                
            </div>
            <div class="card-body">
                <form class="formStyle" #personalWebpage="ngForm" [formGroup]="personalWebpageForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                    <div class="row mt-5" style="max-width: 700px;">
                        <label class="mb-2" translate>YPWEBPAGEUSERID</label>
                        <div class="link-box" >{{ link }}</div>
                        <div class="mt-2 copyButton">
                            <button class="btn orange-button" (click)="copyMessage(link)">{{ 'COPYLINK' | translate}}</button>
                        </div>
                        <div class="link-notice">{{ 'LINKNOTICE' | translate }}<a href="https://free-url-shortener.rb.gy/" target="_blank">https://free-url-shortener.rb.gy/</a>
                        </div>
                    </div>
                  </form>
            </div>
          </div>
        </div>
</div>
